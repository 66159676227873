import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';

import {Subject} from 'rxjs';
import { ApiModulesService } from '../../api-modules.service';
import { AllModulesService } from '../../all-modules.service';
import {ToastrService} from 'ngx-toastr';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { Table } from 'primeng/table';
import { SpinnerService } from 'src/app/snipper/spinner.service';

@Component({
    selector: 'app-employment-details',
    templateUrl: './employment-details.component.html',
    styleUrls: ['./employment-details.component.css']
})
export class EmploymentDetailsComponent implements OnInit, AfterViewInit, OnDestroy {


    public ShowForm: any = false;
    statuses=[];
    loading: boolean = false;
    addEmploymentdetailsForm: FormGroup;
    @Input() authEmployeeData: any = {};
    employmentData = [];
    departmentDropDown = [];
    designationDropDown = [];
    designationDropDownFilter = [];
    searchKeyword: string = '';
    gradeDropDown = [];
    groupDropDown = [];
    jobDropDown = [];
    jobPositionDropDown = [];
    revisionTypeDropDown = [];
    teamDropDown = [];
    teamDropDownFilter = [];
    employeeDropDownFilter = [];
    employeeDropDown = [];
    locationDropDown = [];
    employeeStatusButton: any;
    chooseButton:any;
    tabToGrid=false;
    @Output() parenFun: EventEmitter<any> = new EventEmitter();
    nameBgColors: any = ['bg-blue-dim', 'bg-blue-dim', 'bg-azure-dim', 'bg-indigo-dim', 'bg-info-dim', 'bg-purple-dim', 'bg-pink-dim', 'bg-orange-dim', 'bg-teal-dim', 'bg-primary-dim', 'bg-success-dim', 'bg-warning-dim', 'bg-danger-dim', 'bg-secondary-dim', 'bg-dark-dim', 'bg-gray-dim', 'bg-blue-dim', 'bg-azure-dim', 'bg-indigo-dim', 'bg-info-dim', 'bg-purple-dim', 'bg-pink-dim', 'bg-orange-dim', 'bg-teal-dim', 'bg-primary-dim', 'bg-success-dim', 'bg-warning-dim', 'bg-danger-dim', 'bg-secondary-dim', 'bg-dark-dim', 'bg-gray-dim'];
    nameBorderColors: any = ['bg-outline-primary', 'bg-outline-success', 'bg-outline-info', 'bg-outline-gray', 'bg-outline-warning', 'bg-outline-dark', 'bg-outline-light', 'bg-outline-secondary', 'bg-outline-danger'];
  paygroupDropDown: any;

    constructor(private apiModuleService: ApiModulesService,
                private allModulesService: AllModulesService,
                private toastr: ToastrService,
                private formBuilder: FormBuilder,
                private spinnerService: SpinnerService
    ) {
    }

    ngOnInit(): void {
      this.spinnerService.raiseDataEmitterEvent('on');
        this.addEmploymentdetailsForm = this.formBuilder.group({
            employment_id: ['', []],
            department_id: ['', [Validators.required]],
            team_id: ['', [Validators.required]],
            designation_id: ['', [Validators.required]],
            job_grade_id: [''],
            job_group_id: [''],
            job_id: [''],
            probation_period: ['', [Validators.required]],
            notice_period: ['', [Validators.required]],
            effective_from: ['', [Validators.required]],
            effective_to: ['', []],
            ctc_amount: ['', [Validators.required]],
            location_id: ['', []],
            effective_input_date: ['', [Validators.required]],
            approved_by_id: ['', [Validators.required]],
            job_position_id: [''],
            pay_group_id: [''],
        });
        this.getEmploymentData();
        this.dropDownEmployment();
        this.statuses = [
          { label: 'Hire', value: 'Hire' },
          { label: 'Confirm', value: 'Confirm' },
          { label: 'Revised', value: 'Revised' },
          { label: 'Terminate', value: 'Terminate' }
      ];
    }

    ngAfterViewInit() {

    }

    ngOnDestroy() {
    }

    public resetForm() {
        this.addEmploymentdetailsForm.reset();
    }

    showAddForm() {
        this.ShowForm = !this.ShowForm;
    }
    parentFun(){

        this.parenFun.emit(this.tabToGrid)
    }
    getEmploymentData() {
      this.spinnerService.raiseDataEmitterEvent('on');
        const params: any = {};
        params.client_id = this.authEmployeeData.client_id;
        params.company_id = this.authEmployeeData.company_id;
        params.employee_id = this.authEmployeeData.employee_id;
        this.apiModuleService.list('admin/employment/list', params).subscribe((data) => {

            this.spinnerService.raiseDataEmitterEvent('off');
                this.employeeStatusButton = (data.data.employment.emp_button != undefined) ? data.data.employment.emp_button : [];
                this.employmentData = (data.data.employment.emp_grid_data != undefined) ? data.data.employment.emp_grid_data : [];


        },(err:any)=>{
          this.spinnerService.toasterError(err);
        });
    }

    getAcronym(str: any) {
        if (str != null) {
            let matches = str.match(/\b(\w)/g); // ['J','S','O','N']
            matches = matches.slice(0, 2);
            const acronym = matches.join(''); // JSON
            return acronym;
        }
    }

    getBgColors(str: any) {
        if (str != null) {
            const min = 0;
            const max = 15;
            const index = this.findAlphapositions(str, 1);
            return this.nameBgColors[index];
        }
    }

    findAlphapositions(str: any, n: any) {
        const NUM = 31;

        return str[0].charCodeAt(0) & NUM;
    }

    editValue(employment) {
        this.resetForm();
        const params: any = {};
        params.client_id = this.authEmployeeData.client_id;
        params.company_id = this.authEmployeeData.company_id;
        params.employee_id = this.authEmployeeData.employee_id;
        params.employment_id = employment.employment_id;
        this.apiModuleService.list('admin/employment/edit', params).subscribe((data) => {
            const employmentData = data.data.employment[0][0];
            this.addEmploymentdetailsForm.setValue({
                employment_id: employmentData.employment_id,
                department_id: employmentData.department_id,
                team_id: employmentData.team_id,
                designation_id: employmentData.designation_id,
                job_grade_id: employmentData.job_grade_id,
                job_group_id: employmentData.job_group_id,
                job_id: employmentData.job_id,
                approved_by_id: employmentData.employee_report_id,
                probation_period: employmentData.probation_period,
                notice_period: employmentData.notice_period,
                effective_from: employmentData.effective_from,
                effective_to: employmentData.effective_to,
                ctc_amount: employmentData.ctc_amount,
                job_position_id: data.data.employment[1].map((val:any)=>val.job_position_id),
                pay_group_id: employmentData.pay_group_id,
                location_id: employmentData.location_id,
                effective_input_date: employmentData.effective_input_date,
            });
            this.ShowForm=true;
        }, (err: any) => {
          this.spinnerService.toasterError(err);
          });
    }

    changeEmploymentStatus(actionFlag: any) {
        const params: any = {};
        params.client_id = this.authEmployeeData.client_id;
        params.company_id = this.authEmployeeData.company_id;
        params.employee_id = this.authEmployeeData.employee_id;
        params.action_flag = actionFlag;
        this.apiModuleService.list('admin/employment/buttonStatus', params).subscribe((data) => {
            this.getEmploymentData();
            ($('#confirm_button_popup')as any).modal('hide');
            this.toastr.success('Employment Status Changed SuccessFully....!', 'Success')
        }, (err: any) => {
          this.spinnerService.toasterError(err);
        });
    }

    departmentBasedFilter(){
        this.addEmploymentdetailsForm.patchValue({
            team_id:'',
            designation_id:''
        });
        this.dropDownFilter('designationDropDown','designationDropDownFilter','department_id',this.addEmploymentdetailsForm.get('department_id').value)
        this.dropDownFilter('teamDropDown','teamDropDownFilter','department_id',this.addEmploymentdetailsForm.get('department_id').value)
    }
    dropDownFilter(filteredArray, mainArray, keyFilter, filterBasedValue) {
        this[filteredArray] = this[mainArray].filter((val: any) => val[keyFilter] === filterBasedValue);
    }

    dropDownEmployment() {
        const params: any = {};
        params.client_id = this.authEmployeeData.client_id;
        params.company_id = this.authEmployeeData.company_id;
        params.employee_id = this.authEmployeeData.employee_id;
        this.apiModuleService.list('admin/employment/dropDown', params).subscribe((data) => {
            const dropDownData = data.data.employment;

            this.departmentDropDown = dropDownData[0] !== undefined ? dropDownData[0] : [];
            this.designationDropDown = dropDownData[1] !== undefined ? dropDownData[1] : [];
            this.designationDropDownFilter = dropDownData[1] !== undefined ? dropDownData[1] : [];
            this.gradeDropDown = dropDownData[2] !== undefined ? dropDownData[2] : [];
            this.groupDropDown = dropDownData[3] !== undefined ? dropDownData[3] : [];
            this.jobDropDown = dropDownData[4] !== undefined ? dropDownData[4] : [];
            this.jobPositionDropDown = dropDownData[5] !== undefined ? dropDownData[5] : [];
            this.revisionTypeDropDown = dropDownData[6] !== undefined ? dropDownData[6] : [];
            this.teamDropDown = dropDownData[7] !== undefined ? dropDownData[7] : [];
            this.teamDropDownFilter = dropDownData[7] !== undefined ? dropDownData[7] : [];
            this.employeeDropDownFilter = dropDownData[8] !== undefined ? dropDownData[8] : [];
            this.employeeDropDown = dropDownData[8] !== undefined ? dropDownData[8] : [];
            this.paygroupDropDown = dropDownData[9] !== undefined ? dropDownData[9] : [];
            this.locationDropDown = dropDownData[10] !== undefined ? dropDownData[10] : [];
        }, (err: any) => {
          this.spinnerService.toasterError(err);
        });
    }

    onAddEmploymentDetails() {
        if (!this.addEmploymentdetailsForm.valid) {

            this.addEmploymentdetailsForm.markAllAsTouched();
            return;
        }
        const params: any = {
            client_id: this.authEmployeeData.client_id,
            company_id: this.authEmployeeData.company_id,
            employee_id: this.authEmployeeData.employee_id,
            employment_id: this.addEmploymentdetailsForm.value.employment_id,
            department_id: this.addEmploymentdetailsForm.value.department_id,
            designation_id: this.addEmploymentdetailsForm.value.designation_id,
            job_grade_id: this.addEmploymentdetailsForm.value.job_grade_id,
            job_group_id: this.addEmploymentdetailsForm.value.job_group_id,
            job_id: this.addEmploymentdetailsForm.value.job_id,
            team_id: this.addEmploymentdetailsForm.value.team_id,
            probation_period: this.addEmploymentdetailsForm.value.probation_period,
            notice_period: this.addEmploymentdetailsForm.value.notice_period,
            effective_from: this.addEmploymentdetailsForm.value.effective_from,
            effective_to: this.addEmploymentdetailsForm.value.effective_to,
            ctc_amount: this.addEmploymentdetailsForm.value.ctc_amount,
            approved_by_id: this.addEmploymentdetailsForm.value.approved_by_id,
            job_position_id: this.addEmploymentdetailsForm.value.job_position_id.toString(),
            pay_group_id: this.addEmploymentdetailsForm.value.pay_group_id,
            location_id: this.addEmploymentdetailsForm.value.location_id,
            effective_input_date: this.addEmploymentdetailsForm.value.effective_input_date,
        };
        this.apiModuleService.list('admin/employment/update', params).subscribe((data) => {
            this.getEmploymentData();
            this.ShowForm=false;
            this.toastr.success('Employment Status Changed SuccessFully....!', 'Success')
        }, (err: any) => {
          this.spinnerService.toasterError(err);
                });
    }
    clear(table: Table) {
      table.clear();
  }

  getSeverity(status: string) {
      switch (status.toLowerCase()) {
          case 'Terminate':
              return 'danger';

          case 'Hire':
              return 'success';
          case 'Confirm':
              return 'info';
          case 'Revised':
              return 'primary';
      }
  }
}
