import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import { ApiModulesService } from '../../api-modules.service';
import {ToastrService} from "ngx-toastr";
import {FormBuilder, FormGroup, NgForm, Validators} from "@angular/forms";
import { SpinnerService } from 'src/app/snipper/spinner.service';
import {Table} from "primeng/table";

@Component({
    selector: 'app-employee-deductions',
    templateUrl: './employee-deductions.component.html',
    styleUrls: ['./employee-deductions.component.css']
  })
export class EmployeeDeductionsComponent implements OnInit, OnDestroy, AfterViewInit {
    // @ViewChild(PaycomponentimportComponent) payComponentImport: PaycomponentimportComponent;

    componentDropDown = [];
    componentData = [];
    salaryComponentData = [];
    checkArray = [];
    salaryComponentDataEdit = [];
    importComponent = [];
    searchKeyword: string = '';
    salaryComponentForm: FormGroup;
    statuses=[];
    loading: boolean = false;
    @Input() authEmployeeData: any = {};
    component_type_id: any;
    componentIdChoose: any;
    statusApi:any;
    statusValues:any;
    ShowForm = false;
    selectAllChecked = false;
    acceptedAll = false;
    submitted = false;
    tabToGrid=false;
    ctcAmount:number;
    @Output() parenFun: EventEmitter<any> = new EventEmitter();
    nameBgColors: any = ['bg-blue-dim', 'bg-blue-dim', 'bg-azure-dim', 'bg-indigo-dim', 'bg-info-dim', 'bg-purple-dim', 'bg-pink-dim', 'bg-orange-dim', 'bg-teal-dim', 'bg-primary-dim', 'bg-success-dim', 'bg-warning-dim', 'bg-danger-dim', 'bg-secondary-dim', 'bg-dark-dim', 'bg-gray-dim', 'bg-blue-dim', 'bg-azure-dim', 'bg-indigo-dim', 'bg-info-dim', 'bg-purple-dim', 'bg-pink-dim', 'bg-orange-dim', 'bg-teal-dim', 'bg-primary-dim', 'bg-success-dim', 'bg-warning-dim', 'bg-danger-dim', 'bg-secondary-dim', 'bg-dark-dim', 'bg-gray-dim'];
    nameBorderColors: any = ['bg-outline-primary', 'bg-outline-success', 'bg-outline-info', 'bg-outline-gray', 'bg-outline-warning', 'bg-outline-dark', 'bg-outline-light', 'bg-outline-secondary', 'bg-outline-danger'];
    editForm=false;
    searchKeyword1: string = '';
    constructor(private apiModuleService: ApiModulesService,
                private toastr: ToastrService,
                private formBuilder: FormBuilder, private spinnerService: SpinnerService) {
    }

    ngOnInit(): void {
      this.spinnerService.raiseDataEmitterEvent('on');
        this.salaryComponentForm = this.formBuilder.group({
            component_type: [''],
            frequency: [''],
            component_name: [''],
            component_value: ['', [Validators.required]],
            compensation_id: ['', [Validators.required]]
        });
        // this.DropDown();
        this.employeeGridData();
        this.importGrid();
        this.statuses = [
            { label: 'Active', value: 'active' },
            { label: 'Inactive', value: 'inactive' },
        ];
        // Add


    }

    ngAfterViewInit(): void {

    }

    DropDown() {
        const params: any = {};
        params.client_id = this.authEmployeeData.client_id;
        params.company_id =  this.authEmployeeData.company_id;
        params.employee_id = this.authEmployeeData.employee_id;
        this.apiModuleService.list('admin/salary-compensation/drop-down', params).subscribe((data: any) => {
            this.componentDropDown = data.data.salary_compensation;
            this.component_type_id = this.componentDropDown[0].component_type_id;
            this.employeeGridData();
        });
    }

    employeeGridData() {
      this.spinnerService.raiseDataEmitterEvent('on');
        this.submitted=false;
        const params: any = {};
        params.client_id = this.authEmployeeData.client_id;
        params.company_id =  this.authEmployeeData.company_id;
        params.employee_id = this.authEmployeeData.employee_id;
        params.input_key = 'deductions';
        // params.component_type_id = this.component_type_id;
        this.apiModuleService.list('admin/salary-compensation/grid-list', params).subscribe((data: any) => {


                this.salaryComponentData = (data.data.salary_compensation[0] != undefined) ? data.data.salary_compensation[0] : [];
                this.ctcAmount = (data.data.salary_compensation[1][0].ctc_amount != undefined) ? data.data.salary_compensation[1][0].ctc_amount : 0;

                this.salaryComponentDataEdit = (data.data.salary_compensation[0] != undefined) ? data.data.salary_compensation[0] : [];
                    this.spinnerService.raiseDataEmitterEvent('off');
            //     this.componentIdChoose = this.salaryComponentDataEdit.map((val:any)=>val.componentid)
          },(err: any) => {
            this.spinnerService.toasterError(err);
            });
    }


    parentFun(){

        this.parenFun.emit(this.tabToGrid)
    }
    importGrid() {
      this.spinnerService.raiseDataEmitterEvent('on');
        this.acceptedAll = false;
        this.selectAllChecked = false;
        this.checkArray = [];
        const params: any = {};
        params.client_id = this.authEmployeeData.client_id;
        params.company_id =  this.authEmployeeData.company_id;
        params.employee_id = this.authEmployeeData.employee_id;
        params.input_key = 'deductions';
        this.apiModuleService.list('admin/salary-compensation/import-list', params).subscribe((data: any) => {
           // this.importComponent = (data.data.salary_compensation[0] != undefined) ? data.data.salary_compensation[0] : [];
           this.importComponent = (data.data.salary_compensation[0] != undefined) ? data.data.salary_compensation[0] : [];
           this.componentData = (data.data.salary_compensation[0] != undefined) ? data.data.salary_compensation[0] : [];
            this.componentDropDown = (data.data.salary_compensation[1] != undefined) ? data.data.salary_compensation[1] : [];
            this.component_type_id = this.componentDropDown[0].component_type_id;
            this.spinnerService.raiseDataEmitterEvent('off');
        },(err: any) => {
          this.spinnerService.toasterError(err);

          });
    }
importFilter(){
    this.acceptedAll = false;
    this.selectAllChecked = false;
    this.checkArray = [];
}
    showList() {
        this.ShowForm = false;
    }

    updateComponent(salayForm:NgForm) {

        // this.editForm=false;
        //
        if (!salayForm.valid) {

            this.submitted=true;
            return;
        }

        const params: any = {};
        params.client_id = this.authEmployeeData.client_id;
        params.company_id =this.authEmployeeData.company_id;
        params.employee_id = this.authEmployeeData.employee_id;
        params.compensation_id = [salayForm.value];
       this.apiModuleService.list('admin/salary-compensation/update-component', params).subscribe((data: any) => {
            this.employeeGridData();
           this.editForm=false;
            this.toastr.success('Updated SuccessFully!...', 'Success');

        }, (err: any) => {
          this.spinnerService.toasterError(err);
        });
    }

    ngOnDestroy() {
        // Do not forget to unsubscribe the event

    }

    editComponent() {
       this.editForm=!this.editForm;
        this.employeeGridData();
    }

    getAcronym(str: any) {
        let matches = str.match(/\b(\w)/g); // ['J','S','O','N']
        matches = matches.slice(0, 2);
        const acronym = matches.join(''); // JSON
        return acronym;
    }

    getBgColors(str: any) {
        const min = 0;
        const max = 15;
        const index = this.findAlphapositions(str, 1);
        return this.nameBgColors[index];
    }

    findAlphapositions(str: any, n: any) {
        const NUM = 31;

        return str[0].charCodeAt(0) & NUM;
    }

    showAddForm() {
        this.ShowForm = true;
    }

    /* Check Box Selection */
    private onChange(e) {
        if (e.target.checked) {
            this.checkArray.push(e.target.value);
            this.acceptedAll = this.checkArray.length === this.componentData.length ? true : false;
        } else if (!e.target.checked) {
            // this.acceptedAll = false;
            this.checkArray = this.checkArray.filter((data: any) => data !== e.target.value);
            this.acceptedAll = this.checkArray.length === this.componentData.length ? true : false;
        }
    }

    /* Check Box For Select All*/
    private isAllSelected(event) {
        this.selectAllChecked = !this.selectAllChecked;
        this.acceptedAll = !this.acceptedAll;
        this.checkArray = [];
        if (event.target.checked === true) {
            this.checkArray = this.componentData.map((data: any) => data.component_id);
        } else {
            this.checkArray = [];
        }
    }
// status change
    changeStatus(status: any, compensationId: any) {
        const params: any = {};
        params.client_id = this.authEmployeeData.client_id;
        params.company_id = this.authEmployeeData.company_id;
        params.employee_id = this.authEmployeeData.employee_id;
        params.compensation_id = compensationId;
        //
        this.apiModuleService.edit(params, 'admin/salary-compensation/' + status).subscribe((data) => {
               this.employeeGridData();
               ($('#employeesalary-status') as any).modal('hide');
                this.toastr.success('Status Changed Sucessfully...!', 'Success');
            }, (err) => {
              this.spinnerService.toasterError(err);
            }
        );

    }
    clear(table: Table) {
        table.clear();
    }

    getSeverity(status: string) {
        switch (status.toLowerCase()) {
            case 'inactive':
                return 'danger';

            case 'active':
                return 'success';
        }
    }
    // fun(value){
    //     this.component_type_id=
    // }
    callUpdateFunction() {
        if (this.checkArray.length !== 0) {
            const params: any = {};
            params.client_id = this.authEmployeeData.client_id;
            params.company_id = this.authEmployeeData.company_id;
            params.employee_id = this.authEmployeeData.employee_id;
            params.component_id = this.checkArray.toString();
            this.apiModuleService.list('admin/salary-compensation/import-component', params).subscribe((data: any) => {

                this.toastr.success('Imported SuccessFully!...', 'Success');
                this.employeeGridData();
            }, (err: any) => {
              this.spinnerService.toasterError(err);

            });
        }
    }
    callStatusModel(url_name:any,data:any,actionName:string){
        this.statusApi=url_name;
        this.statusValues=data;
    }
}
