import { Component, OnInit } from '@angular/core';
import { SpinnerService } from 'src/app/snipper/spinner.service';
import { ApiModulesService } from 'src/app/all-modules/api-modules.service';
// import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { documentservice } from 'src/app/all-modules/documents.sevice';
import { Table } from 'primeng/table';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-previous-tax-document',
  templateUrl: './previous-tax-document.component.html',
  styleUrls: ['./previous-tax-document.component.css']
})
export class PreviousTaxDocumentComponent implements OnInit {
  ClientDropDown = [];
  CompanyDropDown = [];
  ClientID = localStorage.getItem('client_id');
  CompanyID = localStorage.getItem('company_id');
  employeeID = localStorage.getItem('employee_id');
  folderNameData: any;
  pre_document_id: any;
  filePath: any;
  // url: string = '';
  // urlSafe: SafeResourceUrl;
  nameBgColors: any = ["bg-blue-dim", "bg-blue-dim", "bg-azure-dim", "bg-indigo-dim", "bg-info-dim", "bg-purple-dim", "bg-pink-dim", "bg-orange-dim", "bg-teal-dim", "bg-primary-dim", "bg-success-dim", "bg-warning-dim", "bg-danger-dim", "bg-secondary-dim", "bg-dark-dim", "bg-gray-dim", "bg-blue-dim", "bg-azure-dim", "bg-indigo-dim", "bg-info-dim", "bg-purple-dim", "bg-pink-dim", "bg-orange-dim", "bg-teal-dim", "bg-primary-dim", "bg-success-dim", "bg-warning-dim", "bg-danger-dim", "bg-secondary-dim", "bg-dark-dim", "bg-gray-dim"];
  nameBorderColors: any = ["bg-outline-primary", "bg-outline-success", "bg-outline-info", "bg-outline-gray", "bg-outline-warning", "bg-outline-dark", "bg-outline-light", "bg-outline-secondary", "bg-outline-danger"];
  file_data: any;
  constructor(private spinnerService: SpinnerService, private apiModulesService: ApiModulesService,
    public docService: documentservice, private toastr: ToastrService) { }
  ngOnInit() {
    this.spinnerService.raiseDataEmitterEvent('off')
    this.GetClientDropDown();
  }


  // dropdown() {
  //   this.spinnerService.raiseDataEmitterEvent('on');
  //   const params: any = {};
  //   params.client_id = this.clientID;
  //   params.company_id = this.companyID;
  //   params.employee_id = this.employeeID;
  //   params.document_type = 'tax_sheet';
  //   this.apiModulesService.list('employee-tabs/IND-employee-payroll/previous-document/dropdown', params).subscribe((data) => {
  //     this.folderNameData = data.data.previous_file != undefined ? data.data.previous_file : [];
  //     setTimeout(() => {
  //       this.pre_document_id = data.data.previous_file?.[0]?.pre_document_id ?? null;
  //       if (this.pre_document_id != null) {
  //         this.getPreviousDocument();
  //       } else {
  //         this.spinnerService.raiseDataEmitterEvent('off');

  //       }
  //     }, 100)
  //   }, (err) => {
  //     this.spinnerService.toasterError(err);
  //   });
  // }
  /* client DropDown codes*/
  public GetClientDropDown() {
    this.apiModulesService.get('DropDown/client').subscribe((data) => {
      this.ClientDropDown = data.data.client;
      this.ClientID = data.data.client[0].client_id;
      this.GetCompanyDropDown();
    })
  }

  /* company DropDown codes */
  public GetCompanyDropDown() {
    const params: any = {};
    params.client_id = this.ClientID;
    this.apiModulesService.list('DropDown/company', params).subscribe((data) => {
      this.CompanyDropDown = data.data.company != undefined ? data.data.company : [];
      if (this.CompanyDropDown.length !== 0) {
        setTimeout(() => {
          this.CompanyID = this.CompanyDropDown[0].company_id;
        }, 100)
        this.getPreviousDocument();
      }
      else {
        this.CompanyID = null;
      }
    })
  }
  getPreviousDocument() {

    this.spinnerService.raiseDataEmitterEvent('on');
    const params: any = {};
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    params.employee_id = this.employeeID;
    params.document_type = 'tax_sheet';
    this.apiModulesService.list('employee-tabs/IND-employee-payroll/previous-document/list', params).subscribe((data) => {
      this.file_data = data.data.previous_file != undefined ? data.data.previous_file : [];

      this.spinnerService.raiseDataEmitterEvent('off');
    },
      (err) => {
        this.spinnerService.toasterError(err);
        this.spinnerService.raiseDataEmitterEvent('off');
      }
    );
  }
  public DownloadFile(id: any) {
    this.spinnerService.raiseDataEmitterEvent('on');
    const params: any = {};
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    params.employee_id = this.employeeID;
    params.pre_document_id = id;
    params.document_type = 'tax_sheet';
    this.apiModulesService.add(params, 'employee-tabs/IND-employee-payroll/previous-document/download').subscribe((data) => {
      const filePath = data.data.previous_download;
      this.docService.DownloadExcel('Tax.pdf', filePath);
      this.toastr.success('Download Sucessfully...!', 'Success');
      this.spinnerService.raiseDataEmitterEvent('off');
    }, (err) => {
      this.spinnerService.toasterError(err);
    }
    );


  }

  clear(table: Table) {
    table.clear();
  }
  getSeverity(status: string) {
    switch (status.toLowerCase()) {
      case 'inactive':
        return 'danger';

      case 'active':
        return 'success';
    }
  }
  getAcronym(str: any) {
    var matches = str.match(/\b(\w)/g); // ['J','S','O','N']
    matches = matches.slice(0, 2);
    var acronym = matches.join(''); // JSON
    return acronym
  }
  getBgColors(str: any) {
    var min = 0;
    var max = 15;
    var index = this.findAlphapositions(str, 1);
    return this.nameBgColors[index];
  }
  findAlphapositions(str: any, n: any) {
    var NUM = 31;

    return str[0].charCodeAt(0) & NUM;
  }
  // uploadViewFile(filepath) {
  //   this.url = filepath != null ? 'data:application/pdf;base64,' + filepath : null;
  //   this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
  // }
}
