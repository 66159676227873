// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pf_margin {
  padding: 8px;
}

.input_width {
  width: 15%;
}

.current_month_input {
  width: 30%;
  margin-left: 38%;
  margin-top: -24px;
  margin-bottom: 10px;

}

.page-wrapper {
  margin-left: 3px;
  margin-right: -2px;
}

.amount_per_month {
  margin-left: 20px;
  margin-bottom: 5px;
}

.radio_input {
  width: 14px;
}

.effective_month_align {
  padding-left: 10px;
}
.submit-btn {
	border-radius:5px;
	font-size: 14px;
	font-weight: normal;
	width: 68px !important;
	color: black;
  margin-left: 43%;
}
.vpf_dropdown{
  position: relative;
  right: -117px;
  bottom: 28px;
}
.start-btn {
background-color:#08bd3f;
color: #fff;

}
.stop-btn {
background-color:#eb3838;
color: #fff;
}
.vpf_alignment{
  margin-left: 20px;
}
.align-right{
  padding-left:10px;
}
.date_format{
text-transform: lowercase;
}
.fixed-card-height{
  height: 346px;
}
.current-vpf-height{
  height: 162px;
}
.stop-vpf-height{
  height: 155px;
}
`, "",{"version":3,"sources":["webpack://./src/app/all-modules/ess-tabs/IND-ess/IND-employee-payroll/provident-fund/vpf/vpf.component.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,UAAU;EACV,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;;AAErB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,kBAAkB;AACpB;AACA;CACC,iBAAiB;CACjB,eAAe;CACf,mBAAmB;CACnB,sBAAsB;CACtB,YAAY;EACX,gBAAgB;AAClB;AACA;EACE,kBAAkB;EAClB,aAAa;EACb,YAAY;AACd;AACA;AACA,wBAAwB;AACxB,WAAW;;AAEX;AACA;AACA,wBAAwB;AACxB,WAAW;AACX;AACA;EACE,iBAAiB;AACnB;AACA;EACE,iBAAiB;AACnB;AACA;AACA,yBAAyB;AACzB;AACA;EACE,aAAa;AACf;AACA;EACE,aAAa;AACf;AACA;EACE,aAAa;AACf","sourcesContent":[".pf_margin {\r\n  padding: 8px;\r\n}\r\n\r\n.input_width {\r\n  width: 15%;\r\n}\r\n\r\n.current_month_input {\r\n  width: 30%;\r\n  margin-left: 38%;\r\n  margin-top: -24px;\r\n  margin-bottom: 10px;\r\n\r\n}\r\n\r\n.page-wrapper {\r\n  margin-left: 3px;\r\n  margin-right: -2px;\r\n}\r\n\r\n.amount_per_month {\r\n  margin-left: 20px;\r\n  margin-bottom: 5px;\r\n}\r\n\r\n.radio_input {\r\n  width: 14px;\r\n}\r\n\r\n.effective_month_align {\r\n  padding-left: 10px;\r\n}\r\n.submit-btn {\r\n\tborder-radius:5px;\r\n\tfont-size: 14px;\r\n\tfont-weight: normal;\r\n\twidth: 68px !important;\r\n\tcolor: black;\r\n  margin-left: 43%;\r\n}\r\n.vpf_dropdown{\r\n  position: relative;\r\n  right: -117px;\r\n  bottom: 28px;\r\n}\r\n.start-btn {\r\nbackground-color:#08bd3f;\r\ncolor: #fff;\r\n\r\n}\r\n.stop-btn {\r\nbackground-color:#eb3838;\r\ncolor: #fff;\r\n}\r\n.vpf_alignment{\r\n  margin-left: 20px;\r\n}\r\n.align-right{\r\n  padding-left:10px;\r\n}\r\n.date_format{\r\ntext-transform: lowercase;\r\n}\r\n.fixed-card-height{\r\n  height: 346px;\r\n}\r\n.current-vpf-height{\r\n  height: 162px;\r\n}\r\n.stop-vpf-height{\r\n  height: 155px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
