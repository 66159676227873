// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.client-company-name{
border: 1px  solid rgba(226, 221, 221, 0.562);
margin-right: 5px;
padding:5px;
}
.regenerate-reupload{
    display: flex;
    flex: auto;
    margin: 20px;
    gap: 12px;
    position: absolute;
    right: 20px;
    top: 0px;


}`, "",{"version":3,"sources":["webpack://./src/app/all-modules/admin-tabs/payroll-admin-tabs/payrollfile/payrollfile.component.css"],"names":[],"mappings":"AAAA;AACA,6CAA6C;AAC7C,iBAAiB;AACjB,WAAW;AACX;AACA;IACI,aAAa;IACb,UAAU;IACV,YAAY;IACZ,SAAS;IACT,kBAAkB;IAClB,WAAW;IACX,QAAQ;;;AAGZ","sourcesContent":[".client-company-name{\r\nborder: 1px  solid rgba(226, 221, 221, 0.562);\r\nmargin-right: 5px;\r\npadding:5px;\r\n}\r\n.regenerate-reupload{\r\n    display: flex;\r\n    flex: auto;\r\n    margin: 20px;\r\n    gap: 12px;\r\n    position: absolute;\r\n    right: 20px;\r\n    top: 0px;\r\n\r\n\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
