// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `label {
    display: block;
    font:
      1rem 'Fira Sans',
      sans-serif;
  }
  
  input,
  label {
    margin: 0.4rem 0;
  }
  `, "",{"version":3,"sources":["webpack://./src/app/all-modules/company/shiftassignment/shiftassignment.component.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd;;gBAEY;EACd;;EAEA;;IAEE,gBAAgB;EAClB","sourcesContent":["label {\r\n    display: block;\r\n    font:\r\n      1rem 'Fira Sans',\r\n      sans-serif;\r\n  }\r\n  \r\n  input,\r\n  label {\r\n    margin: 0.4rem 0;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
