import { Component, ViewChild } from '@angular/core';
import {SpinnerService} from '../../../../../snipper/spinner.service';
import { ApiModulesService } from 'src/app/all-modules/api-modules.service';
import { ClaimReimbursementComponent } from './claim-reimbursement/claim-reimbursement.component';
import { jsPDF } from "jspdf";

@Component({
  selector: 'app-reimbursement',
  templateUrl: './reimbursement.component.html',
  styleUrls: ['./reimbursement.component.css']
})
export class ReimbursementComponent {
  activeTab="claim_reimbursement";
  @ViewChild(ClaimReimbursementComponent)claimReImburstment:ClaimReimbursementComponent

  constructor( private spinnerService: SpinnerService,private apiModulesService:ApiModulesService){}


  gridListView :boolean=true;
  downloadingPDF= true;
  public ShowForm: any = false;

  showParentButtons: boolean = true;


  ngOnInit()
  {
    this.spinnerService.raiseDataEmitterEvent('off');
   }
   toggleParentVisibility(state: boolean): void {
    this.showParentButtons = state;
  }
   changeTabs(activeTab){

    this.activeTab = activeTab;

   }
   activeGridOrCard(){
    this.gridListView=this.claimReImburstment.gridListView

   }
   Convert_HTML_To_PDF() {
    this.downloadingPDF=false;
    setTimeout(()=>{
    const doc = new jsPDF();
    // Source HTMLElement or a string containing HTML.
    const elementHTML: any = document.querySelector('#reimbursementToPrint');
    doc.html(elementHTML, {
      callback: function (doc) {
        // Save the PDF
        // doc.addPage('1','p')
        for (let i = doc.getNumberOfPages(); i != 1; i--) {
          doc.deletePage(i);
        }

        doc.setLineHeightFactor(10);
        doc.setFontSize(20);
        doc.setLineHeightFactor(10);
        doc.save(`reimbursement.pdf`);
        setTimeout(()=>{
        this.downloadingPDF=true;
      },500);
      },

      margin: [10, 10, 10, 10],
      autoPaging: 'slice',
      x: 0,
      y: 10,
      width: 190, // target width in the PDF document
      windowWidth: 1100 // window width in CSS pixels

    });
  },1000);
  }


}
