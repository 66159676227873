import { Component, OnInit } from '@angular/core';
import { ApiModulesService } from 'src/app/all-modules/api-modules.service';
import { SpinnerService } from 'src/app/snipper/spinner.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { documentservice } from "../../../documents.sevice";
@Component({
  selector: 'app-payrollfile',
  templateUrl: './payrollfile.component.html',
  styleUrls: ['./payrollfile.component.css']
})
export class PayrollfileComponent implements OnInit {

  ClientID: any;
  CompanyID: any;
  public Show = true;
  searchKeyword: string = '';
  statusApi: any;
  statusValues: any;
  dateOrFileKey: any;
  CompanyDropDown = [];
  ClientDropDown = [];
  searchByColumn: any = '';
  searchValue: any = '';
  isFileShow = false;
  fileDateId: any;
  viewedFileDate: any;
  ChoosedFile: any;
  fileStatus: any = [
    { 'label': 'Draft', 'value': 'draft' },
    { 'label': 'Final', 'value': 'final' },
    { 'label': 'Revised Draft', 'value': 'revised_draft' },
  ]
  fileGenerationType: any = [
    { 'label': 'Generation', 'value': 'generated' },
    { 'label': 'Upload', 'value': 'upload' }
  ]
  filedata: any;
  fileReports: any;
  nameBgColors: any = ['bg-blue-dim', 'bg-blue-dim', 'bg-azure-dim', 'bg-indigo-dim', 'bg-info-dim', 'bg-purple-dim', 'bg-pink-dim', 'bg-orange-dim', 'bg-teal-dim', 'bg-primary-dim', 'bg-success-dim', 'bg-warning-dim', 'bg-danger-dim', 'bg-secondary-dim', 'bg-dark-dim', 'bg-gray-dim', 'bg-blue-dim', 'bg-azure-dim', 'bg-indigo-dim', 'bg-info-dim', 'bg-purple-dim', 'bg-pink-dim', 'bg-orange-dim', 'bg-teal-dim', 'bg-primary-dim', 'bg-success-dim', 'bg-warning-dim', 'bg-danger-dim', 'bg-secondary-dim', 'bg-dark-dim', 'bg-gray-dim'];
  nameBorderColors: any = ['bg-outline-primary', 'bg-outline-success', 'bg-outline-info', 'bg-outline-gray', 'bg-outline-warning', 'bg-outline-dark', 'bg-outline-light', 'bg-outline-secondary', 'bg-outline-danger'];
  statuses = [];
  loading: boolean = false;
  // separatorExp: RegExp = /,| /;
  separatorExp: string[] = [',', ';'];
  public addFinancialDateForm: FormGroup;
  public addFinancialFileForm: FormGroup;
  public addTimelineForm: FormGroup;
  financialDates: any;
  financialFiles: any;
  financial_file_id: any;
  financial_file_history_id: any;
  file_name: any;
  password: any;
  submitted: boolean;
  viewedFileHistory = [];
  financialData: any;
  file
  paymonth_dropdown: any;
  constructor(private apiModulesService: ApiModulesService, private documentService: documentservice, private toastr: ToastrService, private spinnerService: SpinnerService, private formBuilder: FormBuilder,) {
  }

  ngOnInit() {
    this.spinnerService.raiseDataEmitterEvent('off');
    this.dropDown();
    this.GetClientDropDown();
    this.addFinancialDateForm = this.formBuilder.group({
      from_date: ['', [Validators.required]],
      to_date: ['', [Validators.required]],
      financial_date_id: ['', []],
      month_status: ['', []]


    });
    this.addFinancialFileForm = this.formBuilder.group({
      file_status: ['', [Validators.required]],
      file_path: ['', []],
      file_name: ['', [Validators.required]],
      password: ['', [Validators.required]],
      email_notification: ['', [Validators.required]],
      report_type: ['', []],
      generation_type: ['', []],
      cc_email_notification: [{ value: '', disabled: true }],
      old_password: ['', []],
      old_path: ['', []],
      file_name_extension: ['', []],
      financial_file_id: ['', []]

    });
    this.addFinancialFileForm.get('email_notification').disable();

    this.addTimelineForm = this.formBuilder.group({
      tax_input_from_date: ["", [Validators.required]],
      tax_input_to_date: ["", [Validators.required]],

    });

  }


  public GetClientDropDown() {
    this.apiModulesService.get('DropDown/client').subscribe((data) => {
      this.ClientDropDown = data.data.client;
      this.ClientID = data.data.client[0].client_id;
      this.GetCompanyDropDown();
    }, (err) => {
      this.spinnerService.toasterError(err);

    });
  }
  /* company DropDown codes */
  public GetCompanyDropDown() {
    const params: any = {};
    params.client_id = this.ClientID;
    this.apiModulesService.list('DropDown/company', params).subscribe((data) => {
      this.CompanyDropDown = data.data.company != undefined ? data.data.company : [];
      if (this.CompanyDropDown.length !== 0) {
        this.CompanyID = this.CompanyDropDown[0].company_id;
        setTimeout(() => {
          this.getfileReports();
          this.getPayrollDates();

        }, 100)
      }
      else {
        this.CompanyID = null;
      }
    }, (err) => {
      this.spinnerService.toasterError(err);
    });
  }
  getPayrollDates() {
    this.spinnerService.raiseDataEmitterEvent('on');
    const params: any = {};
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    this.apiModulesService.list('admin/payroll-admin/dates/list', params).subscribe((data) => {
      this.financialDates = data.data.payroll_admin != undefined ? data.data.payroll_admin : [];

      this.spinnerService.raiseDataEmitterEvent('off');

    }, (err) => {
      this.spinnerService.toasterError(err);

    });

  }
  getfileReports() {
    this.spinnerService.raiseDataEmitterEvent('on');
    const params: any = {};
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    this.apiModulesService.get('DropDown/reports').subscribe((data) => {
      this.fileReports = data.data.reports != undefined ? data.data.reports : [];

      this.spinnerService.raiseDataEmitterEvent('off');

    }, (err) => {
      this.spinnerService.toasterError(err);

    });

  }

  getPayrollFiles() {
    this.spinnerService.raiseDataEmitterEvent('on');
    const params: any = {};
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    params.financial_date_id = this.fileDateId;
    this.apiModulesService.list('admin/payroll-admin/files/list', params).subscribe((data) => {
      this.viewedFileDate = data.data.payroll_admin[0][0] != undefined ? data.data.payroll_admin[0][0] : [];
      this.financialFiles = data.data.payroll_admin[1] != undefined ? data.data.payroll_admin[1] : [];
      this.spinnerService.raiseDataEmitterEvent('off');
      this.password = null;
      this.filedata = null;
    }, (err) => {
      this.spinnerService.toasterError(err);

    });
  }
  dropDown() {
    const params: any = {};
    this.apiModulesService.list('admin/payroll-admin/dates/dropdown', params).subscribe((data) => {
      this.paymonth_dropdown = data.data.payroll_admin!= undefined ? data.data.payroll_admin : [];
    }, (err) => {
      this.spinnerService.toasterError(err);
    });
  }
  getFilesHistory(financialData) {
    this.financialData = financialData
    const params: any = {};
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    params.financial_date_id = this.fileDateId;
    params.financial_file_id = financialData.financial_file_id;
    this.apiModulesService.list('admin/payroll-admin/files/history', params).subscribe((data) => {
      this.viewedFileHistory = data.data.payroll_admin != undefined ? data.data.payroll_admin : [];

    }, (err) => {
      this.spinnerService.toasterError(err);

    });
  }
  updatePayrollDates() {
    if (this.addFinancialDateForm.invalid) {
      this.addFinancialDateForm.markAllAsTouched();
      return;
    }
    this.spinnerService.raiseDataEmitterEvent('on');
    const params: any = {};
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    params.from_date = this.addFinancialDateForm.value.from_date;
    params.to_date = this.addFinancialDateForm.value.to_date;
    params.month_status = this.addFinancialDateForm.value.month_status;
    params.financial_date_id = this.addFinancialDateForm.value.financial_date_id;
    this.apiModulesService.list('admin/payroll-admin/dates/update', params).subscribe((data) => {
      // this.financialDates = data.data.payroll_admin != undefined ? data.data.payroll_admin : [];
      this.getPayrollDates();
      ($('#add_financial_dates') as any).modal('hide');
    }, (err) => {
      this.spinnerService.toasterError(err);

    });
  }
  regenerateFile(fileData) {
    if (fileData.generation_type != 'generated') {
      this.toastr.error('Unable To Regenerate File')
      return
    }

    this.spinnerService.raiseDataEmitterEvent('on');
    const params: any = {};
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    params.password = this.password;
    params.report_type = fileData.report_type;
    params.file_name = fileData.file_name;
    params.financial_file_id = fileData.financial_file_id;
    params.financial_date_id = this.fileDateId;
    params.financial_dates = this.viewedFileDate.financial_dates;
    params.month_year = this.viewedFileDate.payroll_period;
    this.apiModulesService.list('admin/payroll-admin/files/regenerate', params).subscribe((data) => {
      // this.financialDates = data.data.payroll_admin != undefined ? data.data.payroll_admin : [];
      this.getPayrollFiles();
      this.getFilesHistory(fileData);
    }, (err) => {
      this.spinnerService.toasterError(err);

    });

  }
  upload(fileData) {
    if (fileData.generation_type == 'generated' || this.filedata == null) {
      this.toastr.error('Unable To Regenerate File')
      return
    }
    this.spinnerService.raiseDataEmitterEvent('on');

    var myFormData = new FormData();
    myFormData.append('client_id', this.ClientID);
    myFormData.append('company_id', this.CompanyID);
    myFormData.append('image_path', this.filedata);
    myFormData.append('password', this.password);
    myFormData.append('report_type', fileData.report_type);
    myFormData.append('financial_file_id', fileData.financial_file_id);
    myFormData.append('financial_date_id', this.fileDateId);
    myFormData.append('financial_dates', this.viewedFileDate.financial_dates);
    myFormData.append('month_year', this.viewedFileDate.payroll_period);
    myFormData.append('file_name', fileData.file_name);
    this.documentService.post('api/admin/payroll-admin/files/upload', myFormData).subscribe((data) => {
      this.file = null;
      this.getPayrollFiles();
      this.getFilesHistory(fileData);

    }, (err) => {
      this.spinnerService.toasterError(err);

    });
  }
  setFileChoosed(filedata) {
    this.ChoosedFile = filedata;
  }
  sendMailAndFile(fileData) {
    const inputString = this.viewedFileDate.email_notification;
    const resultArray = inputString.split(',').map(item => item.trim());
    let ccEmailArray = [];
    if (this.viewedFileDate && this.viewedFileDate.cc_email_notification) {
      const ccEmailInputString = this.viewedFileDate.cc_email_notification;
      ccEmailArray = ccEmailInputString.split(',').map(val => val.trim());
    }
    this.spinnerService.raiseDataEmitterEvent('on');
    const params: any = {};
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    params.password = fileData.password;
    params.report_type = fileData.report_type;
    params.financial_file_id = fileData.financial_file_id;
    params.financial_date_id = this.fileDateId;
    params.financial_file_history_id = fileData.financial_file_history_id;
    params.file_name = fileData.file_name;
    params.email_notification = resultArray,
      params.cc_email_notification = ccEmailArray,

      this.apiModulesService.list('admin/payroll-admin/files/mail', params).subscribe((data) => {
        // this.financialDates = data.data.payroll_admin != undefined ? data.data.payroll_admin : [];
        ($('#mail-send-financial-files') as any).modal('hide');
        this.ChoosedFile = null;

        this.getPayrollFiles();
      }, (err) => {
        this.spinnerService.toasterError(err);

      });
  }
  updatePayrollFiles() {
    if (!this.addFinancialFileForm.valid) {
      this.addFinancialFileForm.markAllAsTouched();
      return;
    }

    var myFormData = new FormData();
    myFormData.append('client_id', this.ClientID);
    myFormData.append('company_id', this.CompanyID);
    myFormData.append('image_path', this.filedata);
    myFormData.append('password', this.addFinancialFileForm.get('password').value);
    myFormData.append('file_name', this.addFinancialFileForm.get('file_name').value);
    myFormData.append('generation_type', this.addFinancialFileForm.get('generation_type').value);
    myFormData.append('report_type', this.addFinancialFileForm.get('report_type').value);
    myFormData.append('file_status', this.addFinancialFileForm.get('file_status').value);
    myFormData.append('financial_date_id', this.viewedFileDate.financial_date_id);
    myFormData.append('financial_file_id', this.addFinancialFileForm.get('financial_file_id').value);
    myFormData.append('file_name_extension', this.addFinancialFileForm.get('file_name_extension').value);
    myFormData.append('email_notification', this.addFinancialFileForm.get('email_notification').value);
    myFormData.append('cc_email_notification', this.addFinancialFileForm.get('cc_email_notification').value);
    myFormData.append('old_path', this.addFinancialFileForm.get('old_path').value);
    myFormData.append('old_password', this.addFinancialFileForm.get('old_password').value);
    myFormData.append('financial_dates', this.viewedFileDate.financial_dates);
    myFormData.append('month_year', this.viewedFileDate.payroll_period);
    if ((this.addFinancialFileForm.get('financial_file_id').value != null && this.addFinancialFileForm.get('financial_file_id').value != '')) {
      this.spinnerService.raiseDataEmitterEvent('on');

      this.documentService.post('api/admin/payroll-admin/files/update', myFormData).subscribe((data) => {
        this.getPayrollFiles();

        this.addFinancialFileForm.reset();
        this.filedata = null;
        ($("#add_financial_files") as any).modal("hide");
        this.toastr.success('Successfully !', 'Success');
        /* Image Post Request */
        this.filedata = [];
        // this.url = []
        this.spinnerService.raiseDataEmitterEvent('off');

      }, (err) => {
        this.spinnerService.toasterError(err);


      });
    }

    else if ((
      (this.addFinancialFileForm.get('generation_type').value == 'upload') &&
      (this.filedata != null && this.filedata != '' && (this.addFinancialFileForm.get('financial_file_id').value == null || this.addFinancialFileForm.get('financial_file_id').value == '')
      )) ||
      ((this.addFinancialFileForm.get('generation_type').value == 'generated')
        && (this.addFinancialFileForm.get('financial_file_id').value == null || this.addFinancialFileForm.get('financial_file_id').value == ''))) {
      this.spinnerService.raiseDataEmitterEvent('on');

      this.documentService.post('api/admin/payroll-admin/files/insert', myFormData).subscribe((data) => {
        this.getPayrollFiles();

        this.addFinancialFileForm.reset();
        this.filedata = null;
        ($("#add_financial_files") as any).modal("hide");
        this.toastr.success('Successfully !', 'Success');
        /* Image Post Request */
        this.filedata = [];
        // this.url = []
        this.spinnerService.raiseDataEmitterEvent('off');

      }, (err) => {
        this.spinnerService.toasterError(err);


      });
    }




  }
  updatetTaxTimelineDates() {
    if (this.addTimelineForm.invalid) {
      this.addTimelineForm.markAllAsTouched();
      return;
    }
    this.spinnerService.raiseDataEmitterEvent('on');
    const params: any = {};
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    params.tax_input_from_date = this.convertToLocalDate(this.addTimelineForm.value.tax_input_from_date);
    params.tax_input_to_date = this.convertToLocalDate(this.addTimelineForm.value.tax_input_to_date);
    this.apiModulesService.list('admin/payroll-admin/dates/update_timeline', params).subscribe((data) => {

      // this.financialDates = data.data.payroll_admin != undefined ? data.data.payroll_admin : [];
      ($('#tax-timeline') as any).modal('hide');
      this.toastr.success('Tax Timeline Updated Successfully !', 'Success');
      this.spinnerService.raiseDataEmitterEvent('off');

    }, (err) => {
      this.spinnerService.raiseDataEmitterEvent('off');
      let errors = err.error.data;
      errors = errors.replace('SQLSTATE[42000]: [Microsoft][ODBC Driver 17 for SQL Server][SQL Server]', 'Error: ')
      this.toastr.error(errors, 'TryAgain');
    });
  }
  editValue(value) {
    this.resetForm();
    this.addFinancialDateForm.setValue({
      from_date: value.from_date,
      to_date: value.to_date,
      month_status: value.month_status,
      financial_date_id: value.financial_date_id
    })
  }
  convertToLocalDate(date: Date): Date {
    const localDate = new Date(date);
    const offset = localDate.getTimezoneOffset() * 60000; // offset in milliseconds
    return new Date(localDate.getTime() - offset);
  }
  editFinancialFile(value) {
    this.resetForm();
    const inputString = this.viewedFileDate.email_notification;
    const resultArray = inputString.split(',').map(item => item.trim());
    let ccEmailArray = [];
    if (this.viewedFileDate && this.viewedFileDate.cc_email_notification) {
      const ccEmailInputString = this.viewedFileDate.cc_email_notification;
      ccEmailArray = ccEmailInputString.split(',').map(val => val.trim());
    }
    this.addFinancialFileForm.setValue({
      file_status: value.file_status,
      file_path: null,
      old_path: value.file_path,
      report_type: value.report_type,
      generation_type: value.generation_type,
      old_password: value.password,
      file_name: value.file_name,
      file_name_extension: value.file_name_extension,
      password: value.password,
      email_notification: resultArray,
      cc_email_notification: ccEmailArray,
      financial_file_id: value.financial_file_id
    })
  }
  setTimelineForm() {
    const params: any = {};
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    this.apiModulesService.list('admin/payroll-admin/dates/edit_timeline', params).subscribe((data) => {
      const editedData = data.data.tax_timeline[0]
      this.addTimelineForm.setValue({
        tax_input_from_date: editedData.tax_input_from_date,
        tax_input_to_date: editedData.tax_input_to_date,
      })
      this.spinnerService.raiseDataEmitterEvent('off');

    }, (err) => {
      this.spinnerService.raiseDataEmitterEvent('off');
      let errors = err.error.data;
      errors = errors.replace('SQLSTATE[42000]: [Microsoft][ODBC Driver 17 for SQL Server][SQL Server]', 'Error: ')
      this.toastr.error(errors, 'TryAgain');
    });

  }
  resetFile(){
    this.filedata=null;
  }
  resetForm() {
    this.addFinancialDateForm.reset();
    this.addFinancialFileForm.reset();
    this.password=null;
    this.ChoosedFile=null;
    this.file=null;
    if (this.isFileShow) {
      const inputString = this.viewedFileDate.email_notification;
      const resultArray = inputString.split(',').map(item => item.trim());
      let ccEmailArray = [];
      if (this.viewedFileDate && this.viewedFileDate.cc_email_notification) {
        const ccEmailInputString = this.viewedFileDate.cc_email_notification;
        ccEmailArray = ccEmailInputString.split(',').map(val => val.trim());
      }
      setTimeout(() => {
        this.addFinancialFileForm.patchValue({

          email_notification: resultArray,
          cc_email_notification: ccEmailArray,
        })
      }, 100)
    }

  }
  resetTimelineForm() {
    this.addTimelineForm.reset();
  }
  /* File onchange event */
  fileEvent(e) {
    this.filedata = e.target.files[0];
  }
  showFilePage(id) {
    this.fileDateId = id;
    this.isFileShow = true;
    this.getPayrollFiles();
  }
  showDatePage() {
    this.isFileShow = false;
    this.getPayrollDates();
  }
  changeStatus(status: any, id: any, key: any) {
    let url = 'dates'
    if (key == 'financial_date_id') {
      url = 'dates'
    } else {
      url = 'files'
    }
    const params: any = {};
    params[key] = id;

    params.company_id = this.CompanyID;
    params.client_id = this.ClientID;
    this.apiModulesService.edit(params, 'admin/payroll-admin/' + url + '/' + status).subscribe((data) => {
      if (key == 'financial_date_id') {
        this.getPayrollDates();
      } else {
        this.getPayrollFiles();
      }
      ($('#financial-paydate-status') as any).modal('hide');
      this.toastr.success('Status  updated sucessfully...!', 'Success');
    }, (err) => {
      let errors = err.error.data.employee;
      errors = errors.replace('SQLSTATE[42000]: [Microsoft][ODBC Driver 17 for SQL Server][SQL Server]', 'Error: ')
      this.toastr.error(errors, 'TryAgain')
    });
  }


  fileForDownload(financial_file_id, financial_file_history_id, file_name) {
    this.financial_file_id = financial_file_id
    this.financial_file_history_id = financial_file_history_id
    this.file_name = file_name
  }
  public DownloadFile(): void {
    this.submitted = true;
    if (!this.password) {
      return; // Password is empty, do nothing
    }

    const params: any = {};
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    params.financial_file_id = this.financial_file_id;
    params.financial_file_history_id = this.financial_file_history_id;
    params.password = this.password;

    this.apiModulesService.add(params, 'admin/payroll-admin/files/download').subscribe(
      (data) => {
        const docDetails = data.data.payroll_final;
        this.documentService.DownloadExcel(docDetails.file_name, docDetails.doc_url);
        this.resetForm();
        ($('#payroll_file_password') as any).modal('hide');
      },
      (err) => {
        if (err.status === 437) {
          this.toastr.error(err.error.message + '...!', 'Failed');
        }
      }
    );
  }
  callStatusModel(url_name: any, data: any, key: string) {
    this.statusApi = url_name;
    this.statusValues = data;
    this.dateOrFileKey = key;
  }

  getSeverity(status: string) {
    switch (status.toLowerCase()) {
      case 'inactive':
        return 'danger';

      case 'active':
        return 'success';
    }
  }
  getAcronym(str: any) {
    let matches = str.match(/\b(\w)/g); // ['J','S','O','N']
    matches = matches.slice(0, 2);
    const acronym = matches.join(''); // JSON
    return acronym;
  }

  getBgColors(str: any) {
    const min = 0;
    const max = 15;
    const index = this.findAlphapositions(str, 1);
    return this.nameBgColors[index];
  }

  findAlphapositions(str: any, n: any) {
    const NUM = 31;

    return str[0].charCodeAt(0) & NUM;
  }
  DownloadExcel() {

    this.spinnerService.raiseDataEmitterEvent('on')
    const params: any = {};
    params.client_id = this.ClientID;
    this.apiModulesService.list('admin/input/export', params).subscribe((data) => {
      const file_name = data.data.excelExport.file_name;
      const linkSource =
        'data:application/octet-stream;base64,' + data.data.excelExport.excel;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = file_name;
      downloadLink.click();
      this.spinnerService.raiseDataEmitterEvent('off')
    }, (err: any) => {
      this.spinnerService.raiseDataEmitterEvent('off')
    });

  }
}
