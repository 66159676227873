// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .template{
	width: 140px;
    color: #838697;
} */

/* .form-focus.select-focus .focus-label{
	margin-top: -2px;
} */

.form-focus .form-control {
	margin-top: 8px;
}

/* .lable{
    padding: 10px;
} */

table.dataTable{
	max-width: 100% !important;
}
.modal-title{
	font-size: 14px;
}
.inactive{
	display: none !important;
}
.formContainer{
	padding-left: 20px;
	padding-right: 20px;
}

header-bg-color{
	background-color:#8C919D;
	color:white;
}
.clear-search{
	border-radius: 10px;
	border:1px lightgrey solid;
	background-color: white;
	height:30px;
	cursor: pointer;
	position: absolute;
	right: -6px;
}

:host ::ng-deep .p-inputnumber-input {
	min-width: 255px ;
	max-width: 100%;
}

:host ::ng-deep .p-link{
  color: #fff;
  background-color: #3e75b3;
}
:host ::ng-deep .p-datatable .p-datatable-thead > tr > th {
  color: #fff;
  background-color: #3e75b3;

}
:host ::ng-deep .p-button.p-button-outlined {
height:30px;

}
`, "",{"version":3,"sources":["webpack://./src/app/all-modules/admin-tabs/states/states-main/states-main.component.css"],"names":[],"mappings":"AAAA;;;GAGG;;AAEH;;GAEG;;AAEH;CACC,eAAe;AAChB;;AAEA;;GAEG;;AAEH;CACC,0BAA0B;AAC3B;AACA;CACC,eAAe;AAChB;AACA;CACC,wBAAwB;AACzB;AACA;CACC,kBAAkB;CAClB,mBAAmB;AACpB;;AAEA;CACC,wBAAwB;CACxB,WAAW;AACZ;AACA;CACC,mBAAmB;CACnB,0BAA0B;CAC1B,uBAAuB;CACvB,WAAW;CACX,eAAe;CACf,kBAAkB;CAClB,WAAW;AACZ;;AAEA;CACC,iBAAiB;CACjB,eAAe;AAChB;;AAEA;EACE,WAAW;EACX,yBAAyB;AAC3B;AACA;EACE,WAAW;EACX,yBAAyB;;AAE3B;AACA;AACA,WAAW;;AAEX","sourcesContent":["/* .template{\r\n\twidth: 140px;\r\n    color: #838697;\r\n} */\r\n\r\n/* .form-focus.select-focus .focus-label{\r\n\tmargin-top: -2px;\r\n} */\r\n\r\n.form-focus .form-control {\r\n\tmargin-top: 8px;\r\n}\r\n\r\n/* .lable{\r\n    padding: 10px;\r\n} */\r\n\r\ntable.dataTable{\r\n\tmax-width: 100% !important;\r\n}\r\n.modal-title{\r\n\tfont-size: 14px;\r\n}\r\n.inactive{\r\n\tdisplay: none !important;\r\n}\r\n.formContainer{\r\n\tpadding-left: 20px;\r\n\tpadding-right: 20px;\r\n}\r\n\r\nheader-bg-color{\r\n\tbackground-color:#8C919D;\r\n\tcolor:white;\r\n}\r\n.clear-search{\r\n\tborder-radius: 10px;\r\n\tborder:1px lightgrey solid;\r\n\tbackground-color: white;\r\n\theight:30px;\r\n\tcursor: pointer;\r\n\tposition: absolute;\r\n\tright: -6px;\r\n}\r\n\r\n:host ::ng-deep .p-inputnumber-input {\r\n\tmin-width: 255px ;\r\n\tmax-width: 100%;\r\n}\r\n\r\n:host ::ng-deep .p-link{\r\n  color: #fff;\r\n  background-color: #3e75b3;\r\n}\r\n:host ::ng-deep .p-datatable .p-datatable-thead > tr > th {\r\n  color: #fff;\r\n  background-color: #3e75b3;\r\n\r\n}\r\n:host ::ng-deep .p-button.p-button-outlined {\r\nheight:30px;\r\n\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
