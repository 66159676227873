import {Component, OnInit, ViewChild, AfterViewInit, OnDestroy, Input, Output, EventEmitter} from '@angular/core';
import { ApiModulesService } from '../../../api-modules.service';
import { AllModulesService } from "../../../all-modules.service";

import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription, Subject, } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { SpinnerService } from 'src/app/snipper/spinner.service';
import { Table } from 'primeng/table';

declare const $: any;
@Component({
  selector: 'app-custompolicy',
  templateUrl: './custompolicy.component.html',
  styleUrls: ['./custompolicy.component.css']
})
export class CustompolicyComponent implements OnInit, OnDestroy, AfterViewInit {
  public custompolicyData = [];
  public addcustompolicyForm: FormGroup
  public clientname = [];
  isDtInitialized: boolean = false;
  public totalRecords: any = 0;
  public ShowForm: any = false;
  loading=false;
    tabToGrid=false;
    @Input() authLeaveData: any = {};
    @Output() parenFun: EventEmitter<any> = new EventEmitter();
  nameBgColors:any = ["bg-blue-dim","bg-blue-dim","bg-azure-dim","bg-indigo-dim","bg-info-dim","bg-purple-dim","bg-pink-dim","bg-orange-dim","bg-teal-dim","bg-primary-dim","bg-success-dim","bg-warning-dim","bg-danger-dim","bg-secondary-dim","bg-dark-dim","bg-gray-dim","bg-blue-dim","bg-azure-dim","bg-indigo-dim","bg-info-dim","bg-purple-dim","bg-pink-dim","bg-orange-dim","bg-teal-dim","bg-primary-dim","bg-success-dim","bg-warning-dim","bg-danger-dim","bg-secondary-dim","bg-dark-dim","bg-gray-dim"];
  nameBorderColors:any = ["bg-outline-primary","bg-outline-success","bg-outline-info","bg-outline-gray","bg-outline-warning","bg-outline-dark","bg-outline-light","bg-outline-secondary","bg-outline-danger"];
  dropdownname= [];
  leave_plantype_dropdown: any;
  statuses: { label: string; value: string; }[];

  constructor(private apiModulesService: ApiModulesService, private AllModulesService: AllModulesService, private toaster: ToastrService, private toastr: ToastrService, private formBuilder: FormBuilder,private spinnerService: SpinnerService) { }

  ngOnInit(): void {


      this.getCustomPolicy();
      this.getDropDown();
    this.addcustompolicyForm = this.formBuilder.group({
      leave_policy_name: ["", [Validators.required]],
      employee_id: ["", [Validators.required]],
      leave_plan_type_id: ["", [Validators.required]],
      no_of_days: ["", [Validators.required]],
      leave_employee_custom_policy_id: ["", []]

    });
    this.statuses = [
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ];
  }
  clear(table: Table) {
    table.clear();
  }
    parentFun(){

        this.parenFun.emit(this.tabToGrid)
    }
  getSeverity(status: string) {
    switch (status.toLowerCase()) {
      case 'inactive':
        return 'danger';

      case 'active':
        return 'success';
    }
  }
  showAddForm() {
    this.ShowForm = true;
    this.resetForm();
  }
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
  }

  //Get all paycomponent data
  public getCustomPolicy() {
    this.spinnerService.raiseDataEmitterEvent('on');
    var params:any={};
      params.client_id = this.authLeaveData.client_id;
      params.company_id = this.authLeaveData.company_id;
      params.leave_plan_id = this.authLeaveData.leave_plan_id;
          this.apiModulesService.list("admin/custompolicy/get", params).subscribe((data) => {
              this.custompolicyData = (data.data.leaves[0] != undefined) ? data.data.leaves[0] : [];
              this.leave_plantype_dropdown = (data.data.leaves[1] != undefined) ? data.data.leaves[1] : [];
              this.loading = false;
              var totalRecords = (this.custompolicyData[0] != undefined) ? this.custompolicyData[0].total_records : 0;
              this.totalRecords = totalRecords;
              this.resetForm();
              setTimeout(() => {
                this.spinnerService.raiseDataEmitterEvent('off');
            }, 100);
          }, (err) => {
            this.custompolicyData = [];
            this.spinnerService.raiseDataEmitterEvent('off');

          });
  }
  changeStatus(Status: any, finance) {
    let params: any = {};
      params.client_id = this.authLeaveData.client_id;
      params.company_id = this.authLeaveData.company_id;
    params.leave_employee_custom_policy_id = finance.leave_employee_custom_policy_id,
    this.apiModulesService.edit(params, 'admin/custompolicy/' + Status).subscribe((data) => {
      this.getCustomPolicy() ;
      this.toaster.success('Status Changed Successfully!', 'Success')
    }, (err) => {
      this.toastr.error("Something went wrong!", "Try Again");
    }
    );
  }
  public onAddLeavePolicy() {
    if (!this.addcustompolicyForm.valid) {
      this.addcustompolicyForm.markAllAsTouched();
      return;
    }
    var params: any = {};
      params.client_id = this.authLeaveData.client_id;
      params.company_id = this.authLeaveData.company_id;
    params.leave_policy_name = this.addcustompolicyForm.get('leave_policy_name').value;
      params.leave_plan_id = this.authLeaveData.leave_plan_id;
    params.leave_plan_type_id = this.addcustompolicyForm.get('leave_plan_type_id').value;
    params.employee_id = this.addcustompolicyForm.get('employee_id').value;
    params.no_of_days = this.addcustompolicyForm.get('no_of_days').value;
    var leave_employee_custom_policy_id = this.addcustompolicyForm.get('leave_employee_custom_policy_id').value;

    if (leave_employee_custom_policy_id != '' && leave_employee_custom_policy_id != undefined) {
      params.leave_employee_custom_policy_id = this.addcustompolicyForm.get('leave_employee_custom_policy_id').value;
      this.apiModulesService.edit(params, "admin/custompolicy/update",).subscribe((data) => {

        this.getCustomPolicy() ;
        this.toastr.success('Financial Year Modified Sucessfully...!', 'Success');
        ($('#add_companypolicy') as any).modal('hide');
      }, err => {
        if (err.status === 437) {
          this.toastr.error('Financial Year Already Exists...!', 'Failed');
        } else {
          this.toastr.error('Something Went Wrong Please Try Again...!', 'Error');
        }
      });

    } else {
      this.apiModulesService.add(params, "admin/custompolicy/add",).subscribe((data) => {
        this.getCustomPolicy() ;
        this.toastr.success('Financial Year Added Sucessfully...!', 'Success');
        this.addcustompolicyForm.reset();
        ($('#add_companypolicy') as any).modal('hide');
      }, err => {
        if (err.status === 437) {
          this.toastr.error('Financial Year Already Exists...!', 'Failed');
        } else {
          this.toastr.error('Something Went Wrong Please Try Again...!', 'Error');
        }
      });
    }
  }
  //Reset form
  public resetForm() {
    this.addcustompolicyForm.reset();
  }
  public edit(leaves: any) {
    this.resetForm();
    const params: any = {};
      params.client_id = this.authLeaveData.client_id;
      params.company_id = this.authLeaveData.company_id;
    params.leave_employee_custom_policy_id = leaves.leave_employee_custom_policy_id;

    this.apiModulesService.edit(params, 'admin/custompolicy/edit').subscribe((data) => {

            const leaveDetail = (data.data.leaves !== undefined) ? data.data.leaves : [];
            this.addcustompolicyForm.setValue({

                leave_policy_name: leaveDetail.leave_policy_name,
                leave_plan_type_id: leaveDetail.leave_plan_type_id,
                employee_id: leaveDetail.employee_id,
                no_of_days: leaveDetail.no_of_days,
                leave_employee_custom_policy_id: leaveDetail.leave_employee_custom_policy_id,

            });

            this.ShowForm = true;
        }, (err) => {
            this.toastr.error('Something went wrong!', 'Try Again');
        }
    );
}

  ngAfterViewInit(): void {

  }






  getDropDown() {
    const params: any = {};
      params.client_id = this.authLeaveData.client_id;
      params.company_id = this.authLeaveData.company_id;

    this.apiModulesService.list('DropDown/employee', params).subscribe((data) => {

      this.dropdownname = data.data.dropdown;
    });

  }

    getAcronym(str: any) {
      if (str != null) {
          let matches = str.match(/\b(\w)/g); // ['J','S','O','N']
          matches = matches.slice(0, 2);
          const acronym = matches.join(''); // JSON
          return acronym;
      }
    }

    getBgColors(str: any) {
      if (str != null) {
          const min = 0;
          const max = 15;
          const index = this.findAlphapositions(str, 1);
          return this.nameBgColors[index];
      }
    }
  findAlphapositions(str:any, n:any)
  {
    var NUM = 31;

    return str[0].charCodeAt(0) & NUM;
  }
}
