import { Component, OnInit, ViewChild,AfterViewInit,OnDestroy } from '@angular/core';
import { ApiModulesService } from '../../api-modules.service';
import { AllModulesService } from "../../all-modules.service";

import { ToastrService } from 'ngx-toastr';
import {FormGroup, FormBuilder, Validators, AbstractControl, FormControl, FormArray} from '@angular/forms';
import { Subscription, Subject, } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { SpinnerService } from 'src/app/snipper/spinner.service';
import { Table } from 'primeng/table';

declare const $: any;
@Component({
  selector: 'app-shifts',
  templateUrl: './shifts.component.html',
  styleUrls: ['./shifts.component.css']
})
export class ShiftsComponent implements OnInit, OnDestroy, AfterViewInit {


  public shiftdata = [];
  public listHeaders = [];
  selectedStartTime: any = '';
  selectedEndTime: any = '';
  rows: any;
  timeRegex = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/ ;
  shiftNameRegex= '^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$';

  public activeIndex = 0;
  public srch = [];
  search: any[];
  ClientDropDown = [];
  CompanyDropDown = [];


  public addShiftForm: FormGroup
  public clientname = [];
  isDtInitialized: boolean = false;
  public totalRecords: any = 0;
  public ShowForm: any = false;
  ClientID :any;
  CompanyID :any;
  loading=false;
  nameBgColors:any = ["bg-blue-dim","bg-blue-dim","bg-azure-dim","bg-indigo-dim","bg-info-dim","bg-purple-dim","bg-pink-dim","bg-orange-dim","bg-teal-dim","bg-primary-dim","bg-success-dim","bg-warning-dim","bg-danger-dim","bg-secondary-dim","bg-dark-dim","bg-gray-dim","bg-blue-dim","bg-azure-dim","bg-indigo-dim","bg-info-dim","bg-purple-dim","bg-pink-dim","bg-orange-dim","bg-teal-dim","bg-primary-dim","bg-success-dim","bg-warning-dim","bg-danger-dim","bg-secondary-dim","bg-dark-dim","bg-gray-dim"];
  nameBorderColors:any = ["bg-outline-primary","bg-outline-success","bg-outline-info","bg-outline-gray","bg-outline-warning","bg-outline-dark","bg-outline-light","bg-outline-secondary","bg-outline-danger"];
  statuses: { label: string; value: string; }[];

  statusApi: any;
  statusValues: any;
  public dropdown = []
  constructor(private apiModulesService: ApiModulesService,
     private AllModulesService: AllModulesService,
     private toaster: ToastrService,
     private toastr: ToastrService,
     private formBuilder: FormBuilder,
     private spinnerService: SpinnerService) { }

  ngOnInit(): void {
    this.ShowForm = false;
    this.GetClientDropDown();

    this.addShiftForm = this.formBuilder.group({

      shift_name: ["", [Validators.required,Validators.pattern(this.shiftNameRegex), Validators.maxLength(40)]],
      shift_desc: ["", [Validators.maxLength(100)]],
      start_time: ["", []],
      end_time: ["", []],
      time_format:["", [Validators.required]],
      shift_duration:["", [Validators.required]],
      break_duration:["", []],
        break_details: this.formBuilder.array([]),
        shift_id: ["", []],

    });
    this.statuses = [
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ];

  }
    get itemsList(): FormArray {

        return this.addShiftForm.get('break_details') as FormArray;

    }
    addItems(value: any) {
        this.itemsList.push(this.newItem(value));
    }
    newItem(value): FormGroup {
        return this.formBuilder.group({
            breaks_in_minutes: [value.breaks_in_minutes,[Validators.required]],
            break_start_time: [value.break_start_time, []],
            shift_details_id: [value.shift_details_id, []],
            break_end_time: [value.break_end_time, []],
            breaks_label: [value.breaks_label,  [Validators.required]],
        });
    }
endTimeValidator(controlName: string) {
  return (control: FormControl): { [key: string]: boolean } | null => {
    if (control.value != null && control.value !== ' ') {
      const addShiftForm = control.parent; // Get the form group

      const controlValue = control.value;
      const startControl = addShiftForm?.get(`${controlName}`);
      const startValue = startControl?.value;

      if (controlValue <= (startValue == undefined ? null : startValue) ) {
        addShiftForm?.get(`${controlName}`).setErrors({ 'TimeInvalid': true })
        return { 'TimeInvalid': true };
      }
    }
    control.parent?.get(`${controlName}`).setErrors(null)
    return null; // Return null if validation passes
  };
}
startTimeValidator(controlName: string) {
  return (control: FormControl): { [key: string]: boolean } | null => {
    if (control.value != null && control.value !== ' ') {
      const addShiftForm = control.parent; // Get the form group
      const controlValue = control.value;
      const endControl = addShiftForm?.get(`${controlName}`);
      const endValue = endControl?.value;

      if ( controlValue >= (endValue == undefined ? null : endValue)) {
        addShiftForm?.get(`${controlName}`).setErrors({ 'TimeInvalid': true })
        return { 'TimeInvalid': true };
      }


    }
    control.parent?.get(`${controlName}`).setErrors(null)
    return null; // Return null if validation passes
  };
}
AddNewItems() {
    let breaks:any = {};
        breaks.breaks_in_minutes= null;
        breaks.break_start_time= null,
        breaks. shift_details_id= null,
        breaks.  break_end_time= null,
        breaks. breaks_label= null,

    this.addItems(breaks);
}

    removeItems(index) {

        this.itemsList.removeAt(index);

    }
  fromTimeToTime(control:FormControl):{[key:string]:boolean}|null{
    if (control.value != null && control.value !== '') {
      const addShiftForm =control.parent;
      if(control.value<=addShiftForm.get('start_time').value || control.value>=addShiftForm.get('end_time').value){
        return {'shiftTimeInvalid':true}
      }
  }
  return null;
}
  showAddForm() {
    this.ShowForm = true;
    this.resetForm();
  }
  showList() {
    this.ShowForm = false;
}
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event

  }

  //Get all shift data
  public getShift() {
    this.spinnerService.raiseDataEmitterEvent('on');
    if (this.CompanyDropDown.length !== 0 && this.CompanyID != null) {
    var params: any = {};
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    this.apiModulesService.list("admin/shift/get", params).subscribe((data) => {
      this.ShowForm = false;
      this.shiftdata = (data.data.shifts != undefined) ? data.data.shifts : [];
      this.loading = false;
      var totalRecords = this.shiftdata.length;
      this.totalRecords = totalRecords;
      this.resetForm();
      setTimeout(() => {
        this.spinnerService.raiseDataEmitterEvent('off');
    }, 100);
  }, (err) => {
    this.shiftdata = [];
    this.spinnerService.raiseDataEmitterEvent('off');
      // this.rerender();
  });
  } else {
    this.rerender();
  }

}

clear(table: Table) {
  table.clear();
}

getSeverity(status: string) {
  switch (status.toLowerCase()) {
    case 'inactive':
      return 'danger';

    case 'active':
      return 'success';
  }
}
  changeStatus(Status: any, data) {
    let params: any = {};
    params.client_id = this.ClientID;
    params.shift_id = data.shift_id,
      params.company_id = this.CompanyID;
    this.apiModulesService.edit(params, 'admin/shift/' + Status).subscribe((data) => {
      this.getShift();
      ($('#shift-status') as any).modal('hide');
      this.toaster.success('Status Changed Successfully!', 'Success')
    }, (err) => {
      this.toastr.error("Something went wrong!", "Try Again");
    }
    );
  }
  public onAddTeam() {

    if (!this.addShiftForm.valid) {
      this.addShiftForm.markAllAsTouched();
      return;
    }
    var params: any = {};
    params.company_id         = this.CompanyID;
    params.client_id          = this.ClientID;
    params.shift_name         = this.addShiftForm.get('shift_name').value;
    params.shift_desc         = this.addShiftForm.get('shift_desc').value;
    params.start_time         = this.addShiftForm.get('start_time').value;
    params.end_time           = this.addShiftForm.get('end_time').value;
    params.time_format       =this.addShiftForm.get('time_format').value;
    params.shift_id          =this.addShiftForm.get('shift_id').value;
    params.break_duration    =this.addShiftForm.get('break_duration').value;
    params.shift_duration    =this.addShiftForm.get('shift_duration').value;
    params.break_details =this.addShiftForm.get('break_details').value;
    var shift_id = this.addShiftForm.get('shift_id').value;
    if (shift_id != '' && shift_id != undefined) {
      params.shift_id = this.addShiftForm.get('shift_id').value;
      this.apiModulesService.edit(params, "admin/shift/update",).subscribe((data) => {

        this.getShift();
        this.toastr.success('Shift Modified Sucessfully...!', 'Success');
        ($('#add_shift') as any).modal('hide');
      }, err => {
        if (err.status === 437) {
          this.toastr.error('Shift Already Exists...!', 'Failed');
        } else {
          this.toastr.error('Something Went Wrong Please Try Again...!', 'Error');
        }
      });

    } else {
      this.apiModulesService.add(params, "admin/shift/add",).subscribe((data) => {
        this.getShift();
        this.toastr.success('Shift Added Sucessfully...!', 'Success');
        this.addShiftForm.reset();
        ($('#add_shift') as any).modal('hide');
      }, err => {
        if (err.status === 437) {
          this.toastr.error('Shift Already Exists...!', 'Failed');
        } else {
          this.toastr.error('Something Went Wrong Please Try Again...!', 'Error');
        }
      });
    }
  }
  //Reset form
  public resetForm() {
    this.addShiftForm.reset();
      const breakDetailsArray = this.addShiftForm.get('break_details') as FormArray;
      breakDetailsArray.clear();
      this.addShiftForm.patchValue({
          time_format:'duration',
      });
      setTimeout(()=>{
          this.AddNewItems();
      },100)

  }

  public shiftEdit(shift_id) {
      this.resetForm();

      const params:any={};
     params.client_id=this.ClientID;
     params.company_id=this.CompanyID;
     params.shift_id=shift_id;
     this.apiModulesService.edit(params,"admin/shift/edit").subscribe((data)=>{
     let shiftData:any=data.data.shifts[0][0];
     let breakData:any=data.data.shifts[1];
         shiftData.start_time = new Date(shiftData.start_time);
         shiftData.end_time = new Date(shiftData.end_time);
         this.addShiftForm.patchValue({
        time_format:shiftData.time_format,
      });  this.addShiftForm.patchValue({
        shift_id:shiftData.shift_id,
        shift_name:shiftData.shift_name,
        shift_desc:shiftData.shift_desc,
        start_time:shiftData.start_time,
        end_time: shiftData.end_time,
        break_duration:shiftData.break_duration,
        shift_duration:shiftData.shift_duration,
          break_details:null

      });
         const breakDetailsArray = this.addShiftForm.get('break_details') as FormArray;
         breakDetailsArray.clear();
         setTimeout(()=>{
             breakData.forEach((value:any,key:any)=>{
                 value.break_start_time=new Date( value.break_start_time)
                 value.break_end_time=new Date( value.break_end_time)
                 this.addItems(value);
             })
         })
     })

  }

  public valuesReset(){
    this.addShiftForm.patchValue({
        start_time:null,
        end_time:null,
        break_duration:null,
    })
      const breakDetailsArray = this.addShiftForm.get('break_details') as FormArray;
      breakDetailsArray.controls.forEach((breakGroup: FormGroup) => {
          breakGroup.get('break_start_time').setValue(null);
          breakGroup.get('break_end_time').setValue(null);
          breakGroup.get('breaks_in_minutes').setValue(null);
      });
  }

  ngAfterViewInit(): void {

  }
  rerender() {

  }


 /* client DropDown codes*/
 public GetClientDropDown() {
  this.apiModulesService.get('DropDown/client').subscribe((data) => {
    this.ClientDropDown = data.data.client;
    this.ClientID = data.data.client[0].client_id;
    this.GetCompanyDropDown();

  })
}

  /* company DropDown codes */
  public GetCompanyDropDown() {
    const params: any = {};
    params.client_id = this.ClientID;
    this.apiModulesService.list('DropDown/company', params).subscribe((data) => {
      this.CompanyDropDown = data.data.company != undefined ? data.data.company : [];
      if (this.CompanyDropDown.length !== 0) {
        setTimeout(() => {
          this.CompanyID = this.CompanyDropDown[0].company_id;
          this.getShift();
        }, 100)
      }
      else {
        this.CompanyID = null;
        this.rerender();
      }

    }
    )
  }
    getAcronym(str: any) {
        if (str != null) {
            let matches = str.match(/\b(\w)/g); // ['J','S','O','N']
            matches = matches.slice(0, 2);
            const acronym = matches.join(''); // JSON
            return acronym;
        }
    }

    getBgColors(str: any) {
        if (str != null) {
            const min = 0;
            const max = 15;
            const index = this.findAlphapositions(str, 1);
            return this.nameBgColors[index];
        }
    }
  findAlphapositions(str:any, n:any)
  {
    var NUM = 31;

    return str[0].charCodeAt(0) & NUM;
  }

  callStatusModel(url_name:any,data:any){
    this.statusApi=url_name;
    this.statusValues=data;
}
SetShiftDuration(){
    const value=this.calculateDurationIgnoringDate(this.addShiftForm.get('start_time').value,this.addShiftForm.get('end_time').value);
    this.addShiftForm.patchValue({
        shift_duration:value
    })
}
SetBreakDuration(){
    const breakDetailsArray = this.addShiftForm.get('break_details') as FormArray;
    breakDetailsArray.controls.forEach((breakGroup: FormGroup) => {
        breakGroup.get('breaks_in_minutes').setValue(this.calculateDurationIgnoringDate(breakGroup.get('break_start_time').value,breakGroup.get('break_end_time').value));
    });
    this.addShiftForm.patchValue({
        break_duration:this.sumBreakMinutes()
    })
}
    sumBreakMinutes(): string {
        const breakDetailsArray = this.addShiftForm.get('break_details') as FormArray;
        let totalBreakMinutes = 0;

        breakDetailsArray.controls.forEach((breakGroup: FormGroup) => {
            const breakTime = breakGroup.get('breaks_in_minutes').value;
            if (breakTime) {
                const [hours, minutes] = breakTime.split(':').map(Number);
                totalBreakMinutes += hours * 60 + minutes;
            }
        });

        const diffHours = Math.floor(totalBreakMinutes / 60);
        const diffMinutesRemainder = totalBreakMinutes % 60;

        const formattedHours = diffHours.toString().padStart(2, '0');
        const formattedMinutes = diffMinutesRemainder.toString().padStart(2, '0');

        return `${formattedHours}:${formattedMinutes}`;
    }
    calculateDurationIgnoringDate(startDateTime: Date, endDateTime: Date): string {
if(startDateTime!=endDateTime){
    const startHours = startDateTime.getHours();
    const startMinutes = startDateTime.getMinutes();
    const startSeconds = startDateTime.getSeconds();
    const endHours = endDateTime.getHours();
    const endMinutes = endDateTime.getMinutes();
    const endSeconds = endDateTime.getSeconds();

    // Convert the time components into total minutes since the start of the day
    const startTotalMinutes = startHours * 60 + startMinutes + startSeconds / 60;
    const endTotalMinutes = endHours * 60 + endMinutes + endSeconds / 60;
    // Calculate the difference in minutes
    let diffMinutes = endTotalMinutes - startTotalMinutes;
    // If the end time is before the start time, adjust by adding 24 hours worth of minutes
    if (diffMinutes < 0) {
        diffMinutes += 24 * 60;
    }
    // Convert the difference back into hours and minutes
    const diffHours = Math.floor(diffMinutes / 60);
    const diffMinutesRemainder = Math.floor(diffMinutes % 60);
    // Format the result as HH:mm
    const formattedHours = diffHours.toString().padStart(2, '0');
    const formattedMinutes = diffMinutesRemainder.toString().padStart(2, '0');
    return `${formattedHours}:${formattedMinutes}`;
}else{
    return `00:00`;
}
    }
}
