import {Component} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ToastrService} from 'ngx-toastr';
import {ApiModulesService} from 'src/app/all-modules/api-modules.service';
import {documentservice} from "../../../../documents.sevice";
import { SpinnerService } from 'src/app/snipper/spinner.service';
import {DatePipe} from "@angular/common";
@Component({
    selector: 'app-employee-info',
    templateUrl: './employee-info.component.html',
    styleUrls: ['./employee-info.component.css']
})
export class EmployeeInfoComponent {
    showForm: boolean = true;
    gridListView: boolean = true;
    showPersonalDetails: boolean = true;
    showProfileDetails: boolean = true;
    showEmergencyDetails: boolean = true;
    showEmailDetails: boolean = true;
    showBankDetails: boolean = true;
    showFamilyDetails: boolean = true;
    showFamilyDetailsList: boolean = true;
    personalGrid: any;
    bankGrid: any;
    documentGrid: any;
    familyGrid: any;
    fatherList = [];
    motherList = [];
    maritalCodeTrue = false;
    primaryList = [];
    secondaryList = [];
    spouseList = [];
    pancard = [];
    passport = [];
    filedata: any;
    oldPath: any;
    awsimages: any;
    public countriesList = [];
    public statesList = [];
    public citiesList = [];
    public dropdownname = [];
    public dropdownGender = [];
    public dropdownRelation = [];
    public dropdownNationality = [];
    public dropdownNationalityType = [];
    public physicallyDropdown = [];
    public physicalKeyDropdown = [];
    public contactStatusDropDown = [];
    public dropdownMarital = [];
    public dropdownAddressType = [];
    public employeeCategory = [];
    public dropdownTitle = [];
    activeTab="profile";
    clientID = localStorage.getItem('client_id');
    companyID = localStorage.getItem('company_id');
    employeeID = localStorage.getItem('employee_id');

    addFamilyForm: FormGroup;
    addPersonalForm: FormGroup;
    addBankForm: FormGroup;
    addEmailForm: FormGroup;
    addFamilyListForm: FormGroup;
    addProfileForm: FormGroup;
    showPhysicalChallange = true;
    editedEmployeeData: any = {};

    emailRegex = '^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$';
    mobileRegex = '^((\\+91-?)|0)?[0-9]{10,13}$';
    numberRegex = '^[a-zA-Z0-9]{6,10}$';
    employeeIdRegex = '^[0-9\\-a-zA-Z\\-]{1,10}$';
    employeeName = '^[a-zA-Z][a-zA-Z\\s]*$';

    field = true;
    percentage_value: any;
    physicalvalue: any = '0';
    public pipe = new DatePipe('en-US');
    constructor(private formBuilder: FormBuilder,
       private toastr: ToastrService,
       private apiModulesService: ApiModulesService,
        private documentService: documentservice,
        private spinnerService: SpinnerService

        ) {

    }
    ngOnInit() {

        this.basicGrid();
        this.getDropDown();
        this.getCountries();


        this.addProfileForm = this.formBuilder.group({
            employee_no: ['', [Validators.required, Validators.pattern(this.employeeIdRegex)]],
            title: ['', [Validators.required]],
            first_name: ['', [Validators.required, Validators.pattern(this.employeeName)]],
            middle_name: ['', []],
            sur_name: ['', []],
            sex: ['', [Validators.required]],
            dob: ['', [Validators.required]],
            mobile: ['', [Validators.required, Validators.pattern(this.mobileRegex)]],
            address_type: ['', [Validators.required]],
            employee_category: ['', [Validators.required]],
            email: ['', [Validators.required, Validators.pattern(this.emailRegex)]],
            country_id: ['', [Validators.required]],
            address_1: ['', [Validators.required]],
            address_2: ['', []],
            pincode: ['', [Validators.required, Validators.pattern(this.numberRegex), Validators.maxLength(10)]],
            state_id: ['', [Validators.required]],
            city_id: ['', [Validators.required]],
            nationality: ['', [Validators.required]],
            nationality_type: ['', [Validators.required]],
            marital_status: ['', [Validators.required]],
            physically_challanged: ['', [Validators.required]],
            physically_challanged_percentage: ['', []],
            personal_email_id: ['', []],
            profile_img: ['', []]
        })

        this.addPersonalForm = this.formBuilder.group({
            nationality: ['', [Validators.required]],
            religion: ['', []],
            passport_no: ['', []],
            passport_expiry: ['', []],
            marital_status_id: ['', [Validators.required]],
            tel_phone: ['', []],
        });

        this.addBankForm = this.formBuilder.group({
            bank_name: ['', [Validators.required]],
            account_number: ['', [Validators.required]],
            bank_code: ['', [Validators.required]],
            pan_no: ['', [Validators.required]]
        })

        this.addEmailForm = this.formBuilder.group({
            // client_id: ['', [Validators.required]],
            // company_id: ['', [Validators.required]],
            // employee_id:['', [Validators.required]],
            email: ['', [Validators.required, Validators.pattern(this.emailRegex)]],
            personal_email_id: ['', [Validators.pattern(this.emailRegex)]],
        })

        this.addFamilyListForm = this.formBuilder.group({
            father_name: ['', [Validators.required]],
            father_dob: ['', []],
            mother_name: ['', [Validators.required]],
            mother_dob: ['', []],
            spouse_name: ['', [Validators.required]],
            spouse_dob: ['', []],
        })
        this.addFamilyForm = this.formBuilder.group({

            relation_type_id: ['', [Validators.required]],
            first_name: ['', [Validators.required, Validators.pattern(this.employeeName)]],
            sur_name: ['', [Validators.required, Validators.pattern(this.employeeName)]],
            email: ['', [Validators.pattern(this.emailRegex)]],
            mobile: ['', [Validators.pattern(this.mobileRegex)]],
            occupation: ['', []],
            is_physically_challenged: ['', [Validators.required]],
            physically_challenged_percentage: ['', []],
            dob: ['', []],
            gender: ['', [Validators.required]],
            nationality_id: ['', []],
            nationality_type: ['', []],
            contact_status: ['', [Validators.required]],
            employee_family_id: ['', []]
        });

    }

    showGrid() {
        this.gridListView = true;
    }

    showListview() {
        this.gridListView = false;
    }

    public percentageChange(value) {

        if (this.addFamilyForm.get('is_physically_challenged').value == 'Yes') {

            this.field = false;
        } else {
            this.addFamilyForm.patchValue({physically_challenged_percentage: null});
            this.field = true;
        }
    }
    public personalPercentageChange(value) {

        if (this.addProfileForm.get('physically_challanged').value == '1') {

            this.field = false;
        } else {
            this.addProfileForm.patchValue({physically_challenged_percentage: null});
            this.field = true;
        }
    }

    basicGrid() {
      this.spinnerService.raiseDataEmitterEvent('on');
        const params: any = {};
        params.client_id = this.clientID;
        params.company_id = this.companyID;
        params.employee_id = this.employeeID;

        this.apiModulesService.list("employee-tabs/employee-hrm/employee-info/list", params).subscribe((data) => {


            this.personalGrid = data.data.employee_info[0][0];

            this.awsimages =data.data.employee_info[0][0].img!=null?'data:image/png;base64,' +  data.data.employee_info[0][0].img:null;
            this.bankGrid = data.data.employee_info[1][0];


            this.documentGrid = data.data.employee_info[2];


            this.familyGrid = data.data.employee_info[3];


            this.pancard = this.documentGrid.filter((data: any) => data.document_type == 'PAN_Card');


            this.passport = this.documentGrid.filter((data: any) => data.document_type == 'Passport_No');


            this.fatherList = this.familyGrid.filter((data: any) => data.relation_type == 'Father');

            this.motherList = this.familyGrid.filter((data: any) => data.relation_type == 'Mother');

            this.spouseList = this.familyGrid.filter((data: any) => data.relation_type == 'Spouse');


            this.primaryList = this.familyGrid.filter((data: any) => data.contact_status == 'primary');


            this.secondaryList = this.familyGrid.filter((data: any) => data.contact_status == 'secondary');


            this.spinnerService.raiseDataEmitterEvent('off');
        },(err:any)=>{
            this.spinnerService.toasterError(err);
        })

    }

// profile page edit and update

    profileEdit() {

        this.oldPath = this.personalGrid.profile_img;
        this.filedata = null;
        this.addProfileForm.setValue({
            employee_category: this.personalGrid.employee_category,
            employee_no: this.personalGrid.employee_no,
            title: this.personalGrid.title,
            first_name: this.personalGrid.first_name,
            middle_name: this.personalGrid.middle_name,
            sur_name: this.personalGrid.sur_name,
            dob: this.pipe.transform(this.personalGrid.dob,'yyyy-MM-dd'),
            sex: this.personalGrid.sex,
            mobile: this.personalGrid.mobile,
            email: this.personalGrid.email,
            personal_email_id: this.personalGrid.personal_email_id,
            address_type: this.personalGrid.address_type,
            address_1: this.personalGrid.address_1,
            address_2: this.personalGrid.address_2,
            pincode: this.personalGrid.pincode,
            state_id: this.personalGrid.state_id,
            city_id: this.personalGrid.city_id,
            country_id: this.personalGrid.country_id,
            nationality: this.personalGrid.nationality_id,
            nationality_type: this.personalGrid.nationality_type_id,
            marital_status: this.personalGrid.marital_status_id,
            physically_challanged: this.personalGrid.is_physically_challenged,
            physically_challanged_percentage: this.personalGrid.physically_challenged_percentage,
            profile_img: null,

        });
        this.getDropDown();
        this.getCities();
        this.getCountries();
        this.getStates();

    }
    changeTabs(activeTab) {
        this.editedEmployeeData.client_id = this.clientID;
        this.editedEmployeeData.company_id = this.companyID;
        this.editedEmployeeData.employee_id = this.employeeID;
        this.editedEmployeeData.employee_no = this.personalGrid.employee_no;
        this.editedEmployeeData.employee_name =this.personalGrid.employee_name;
        this.activeTab = activeTab;
    }
    changeTabsViewToGrid(value) {
        this.basicGrid();
        this.resetForm();
    }
    profileUpdate() {
        if (!this.addProfileForm.valid) {

            this.addProfileForm.markAllAsTouched();
            return;

        }

        const myFormData = new FormData();

        myFormData.append('profile_img', this.filedata);
        myFormData.append('client_id', this.clientID);
        myFormData.append('company_id', this.companyID);
        myFormData.append('employee_id', this.employeeID);
        myFormData.append('employee_no', this.addProfileForm.get('employee_no').value);
        myFormData.append('employee_category', this.addProfileForm.get('employee_category').value);
        myFormData.append('title', this.addProfileForm.get('title').value);
        myFormData.append('first_name', this.addProfileForm.get('first_name').value);
        myFormData.append('middle_name', this.addProfileForm.get('middle_name').value);
        myFormData.append('sur_name', this.addProfileForm.get('sur_name').value);
        myFormData.append('dob', this.addProfileForm.get('dob').value);
        myFormData.append('sex', this.addProfileForm.get('sex').value);
        myFormData.append('mobile', this.addProfileForm.get('mobile').value);
        myFormData.append('email', this.addProfileForm.get('email').value);
        myFormData.append('personal_email_id', this.addProfileForm.get('personal_email_id').value);
        myFormData.append('address_1', this.addProfileForm.get('address_1').value);
        myFormData.append('address_2', this.addProfileForm.get('address_2').value);
        myFormData.append('address_type', this.addProfileForm.get('address_type').value);
        myFormData.append('country_id', this.addProfileForm.get('country_id').value);
        myFormData.append('pincode', this.addProfileForm.get('pincode').value);
        myFormData.append('nationality', this.addProfileForm.get('nationality').value);
        myFormData.append('nationality_type', this.addProfileForm.get('nationality_type').value);
        myFormData.append('state_id', this.addProfileForm.get('state_id').value);
        myFormData.append('city_id', this.addProfileForm.get('city_id').value);
        myFormData.append('marital_status', this.addProfileForm.get('marital_status').value);
        myFormData.append('physically_challanged', this.addProfileForm.get('physically_challanged').value);
        myFormData.append('physically_challenged_percentage', this.addProfileForm.get('physically_challanged_percentage').value);
            myFormData.append('old_path', this.oldPath);


        this.documentService.post('api/employee-tabs/employee-hrm/employee-info/personal-update', myFormData).subscribe((data) => {
                this.basicGrid();
                ($('#profile_info') as any).modal('hide');
                this.addFamilyForm.reset();
                this.filedata = null;
                this.toastr.success('profile added sucessfully...!','Success');
            }, (err) => {
                this.spinnerService.toasterError(err);
            }
        );
    }

// personal info edit and update

    /* File onchange event */
    fileEvent(e) {
        this.filedata = e.target.files[0];
    }

    personalEdit() {

        this.addPersonalForm.setValue({
            nationality: this.personalGrid.nationality_id,
            religion: this.personalGrid.religion,
            passport_no: this.passport[0].document_value,
            passport_expiry: this.passport[0].valid_to,
            tel_phone: this.personalGrid.mobile,
            marital_status_id: this.personalGrid.marital_status_id,

        })
        this.getDropDown();
        this.getCountries();
    }


    personalUpdate() {
        if (!this.addPersonalForm.valid) {

            this.addPersonalForm.markAllAsTouched();
            return;
        }
        const params: any = {};

        params.client_id = this.clientID;
        params.company_id = this.companyID;
        params.employee_id = this.employeeID;
        params.employee_no = this.personalGrid.employee_no;
        params.nationality = this.addPersonalForm.get('nationality').value;
        params.religion = this.addPersonalForm.get('religion').value;
        params.document_value = this.addPersonalForm.get('passport_no').value;
        params.valid_to = this.addPersonalForm.get('passport_expiry').value;
        params.marital_status = this.addPersonalForm.get('marital_status_id').value;
        params.employee_document_id = this.passport[0].employee_document_id;
        params.document_type_id = this.passport[0].document_type_id;


        this.apiModulesService.add(params, 'employee-tabs/employee-hrm/employee-info/document-profile-update').subscribe((data) => {
            this.basicGrid();
            ($('#personal_info_modal') as any).modal('hide');
            this.addFamilyForm.reset();
            this.toastr.success('person details added sucessfully...!', 'Success');
        }, err => {
            this.spinnerService.toasterError(err);
        });

    }

// bank page edit and update

    bankEdit() {

        this.addBankForm.setValue({
            bank_name: this.bankGrid.bank_name,
            account_number: this.bankGrid.account_number,
            bank_code: this.bankGrid.bank_code,
            pan_no: this.pancard[0].document_value,
        })

    }

    bankUpdate() {
        if (!this.addBankForm.valid) {

            this.addBankForm.markAllAsTouched();
            return;
        }
        const params: any = {};
        params.client_id = this.clientID;
        params.company_id = this.companyID;
        params.employee_id = this.employeeID;
        params.bank_name = this.addBankForm.get('bank_name').value;
        params.account_number = this.addBankForm.get('account_number').value;
        params.bank_code = this.addBankForm.get('bank_code').value;
        params.document_value = this.addBankForm.get('pan_no').value;
        params.employee_bank_details_id = this.bankGrid.employee_bank_details_id;
        params.document_type_id = this.pancard[0].document_type_id;
        params.employee_document_id = this.pancard[0].employee_document_id;



        this.apiModulesService.add(params, 'employee-tabs/employee-hrm/employee-info/bank-update').subscribe((data) => {
            this.basicGrid();
            ($('#bank_info_modal') as any).modal('hide');
            this.addBankForm.reset();
            this.toastr.success('bank info added sucessfully...!', 'Success');
        }, err => {
            this.spinnerService.toasterError(err);
        });

    }

//email page edit and update


    emailEdit() {

        this.addEmailForm.setValue({
            email: this.personalGrid.email,
            personal_email_id: this.personalGrid.personal_email_id,

        });
    }

    emailUpdate() {
        if (!this.addEmailForm.valid) {

            this.addEmailForm.markAllAsTouched();
            return;
        }

        const params: any = {};
        params.client_id = this.clientID;
        params.company_id = this.companyID;
        params.employee_id = this.employeeID;
        params.employee_no = this.personalGrid.employee_no;
        params.email = this.addEmailForm.get('email').value;
        params.personal_email_id = this.addEmailForm.get('personal_email_id').value;

        this.apiModulesService.add(params, 'employee-tabs/employee-hrm/employee-info/email-update').subscribe((data) => {
            this.basicGrid();
            ($('#email_info_modal') as any).modal('hide');
            this.addEmailForm.reset();
            this.toastr.success('email info added sucessfully...!', 'Success');
        }, err => {
            this.spinnerService.toasterError(err);
        });


    }


//family list page edit

    familyListEdit() {


        this.addFamilyListForm.setValue({
            father_name: this.fatherList[0].first_name,
            father_dob: this.fatherList[0].dob,
            mother_name: this.motherList[0].first_name,
            mother_dob: this.motherList[0].dob,
            spouse_name: this.spouseList[0].first_name,
            spouse_dob: this.spouseList[0].dob,
        });
    }


//form reset function

    resetForm() {
        this.addFamilyForm.reset();
        this.addFamilyListForm.reset();
        this.addBankForm.reset();
        this.addPersonalForm.reset();
        this.addProfileForm.reset();
        setTimeout(() => {
            this.physicalvalue = '0';
        }, 100)

    }

    // Add new employee family

    public onAddFamily() {
        if (!this.addFamilyForm.valid) {

            this.addFamilyForm.markAllAsTouched();
            return;

        }

        const params: any = {};
        params.client_id = this.clientID;
        params.company_id = this.companyID;
        params.employee_id = this.employeeID;
        params.relation_type = this.addFamilyForm.get('relation_type_id').value;
        params.first_name = this.addFamilyForm.get('first_name').value;
        params.sur_name = this.addFamilyForm.get('sur_name').value;
        params.email = this.addFamilyForm.get('email').value;
        params.mobile = this.addFamilyForm.get('mobile').value;
        params.occupation = this.addFamilyForm.get('occupation').value;
        params.physically_challenged_percentage = this.addFamilyForm.get('physically_challenged_percentage').value;
        params.is_physically_challenged = this.addFamilyForm.get('is_physically_challenged').value;
        params.dob = this.addFamilyForm.get('dob').value;
        params.gender = this.addFamilyForm.get('gender').value;
        params.nationality_id = this.addFamilyForm.get('nationality_id').value;
        params.nationality_type = this.addFamilyForm.get('nationality_type').value
        params.employee_family_id = this.addFamilyForm.get('employee_family_id').value;
        params.contact_status = this.addFamilyForm.get('contact_status').value;


        this.apiModulesService.add(params, 'admin/employeefamily/add').subscribe((data) => {
            this.basicGrid();
            ($('#add_family_info_modal') as any).modal('hide');
            this.addFamilyForm.reset();
            this.toastr.success('employeefamily added sucessfully...!', 'Success');
        }, err => {
            this.spinnerService.toasterError(err);
        });

    }

    public editValue(familyDetails: any) {


        this.addFamilyForm.setValue({
            relation_type_id: familyDetails.relation_type,
            first_name: familyDetails.first_name,
            sur_name: familyDetails.sur_name,
            email: familyDetails.email,
            mobile: familyDetails.mobile,
            occupation: familyDetails.occupation,
            is_physically_challenged: familyDetails.is_physically_challenged,
            physically_challenged_percentage: familyDetails.physically_challenged_percentage,
            dob: familyDetails.dob,
            gender: familyDetails.gender,
            contact_status: familyDetails.contact_status,
            nationality_id: familyDetails.nationality_id,
            nationality_type: familyDetails.nationality_type,
            employee_family_id: familyDetails.employee_family_id,
        });
        this.getDropDown();
    };

//function for dropdown

    getDropDown() {
        const params: any = {};
        params.client_id = this.clientID;
        params.company_id = this.companyID;
        params.employee_id = this.employeeID;

        this.apiModulesService.list('employee-tabs/employee-hrm/employee-info/drop-down', params).subscribe((data) => {
            this.dropdownname = data.data.employee_info;

            this.dropdownGender = data.data.employee_info[0] ? data.data.employee_info[0] : [];
            this.dropdownRelation = data.data.employee_info[1] ? data.data.employee_info[1] : [];
            this.dropdownNationality = data.data.employee_info[2] ? data.data.employee_info[2] : [];
            this.dropdownNationalityType = data.data.employee_info[3] ? data.data.employee_info[3] : [];
            this.physicallyDropdown = data.data.employee_info[4] ? data.data.employee_info[4] : [];
            this.contactStatusDropDown = data.data.employee_info[5] ? data.data.employee_info[5] : [];
            this.dropdownMarital = data.data.employee_info[6] ? data.data.employee_info[6] : [];
            this.dropdownAddressType = data.data.employee_info[7][0].address_type ? data.data.employee_info[7][0].address_type : [];
            this.physicalKeyDropdown = data.data.employee_info[8] ? data.data.employee_info[8] : [];
            this.employeeCategory = data.data.employee_info[9] ? data.data.employee_info[9] : [];
            this.dropdownTitle = data.data.employee_info[10] ? data.data.employee_info[10] : [];

        },(err)=>{
            this.spinnerService.toasterError(err);

        });

    }

    public getCountries() {
        this.apiModulesService.get('country/list').subscribe((data) => {
            const res: any = data;
            this.countriesList = (res.data.countries !== undefined) ? res.data.countries : [];
        });
    }

    public getStates() {
        var params: any = {};
        params.country_id = this.addProfileForm.get('country_id').value;
        this.apiModulesService.list('country/states', params).subscribe((data) => {
            this.statesList = (data.data.states !== undefined) ? data.data.states : [];
        });
    }

    public getCities() {
        const params: any = {};
        params.state_id = this.addProfileForm.get('state_id').value;
        this.apiModulesService.list('country/cities', params).subscribe((data) => {
            this.citiesList = (data.data.states !== undefined) ? data.data.states : [];
        });
    }
}
