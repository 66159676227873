import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { SpinnerService } from 'src/app/snipper/spinner.service';
import { ApiModulesService } from 'src/app/all-modules/api-modules.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Table } from 'primeng/table';


@Component({
    selector: 'app-weekly-shift',
    templateUrl: './weekly-shift.component.html',
    styleUrls: ['./weekly-shift.component.css']
})
export class WeeklyShiftComponent implements OnInit {
    public ShowForm: any = false;
    public addWeeklyShiftForm: FormGroup;
    public editWeeklyShiftForm:FormGroup;
    public weeklyshiftdata = [];
    ClientDropDown = [];
    CompanyDropDown = [];
    ClientID: any;
    CompanyID: any;
    loading = false;
    weeklyshiftdropdown = [];
    nameBgColors: any = ["bg-blue-dim", "bg-blue-dim", "bg-azure-dim", "bg-indigo-dim", "bg-info-dim", "bg-purple-dim", "bg-pink-dim", "bg-orange-dim", "bg-teal-dim", "bg-primary-dim", "bg-success-dim", "bg-warning-dim", "bg-danger-dim", "bg-secondary-dim", "bg-dark-dim", "bg-gray-dim", "bg-blue-dim", "bg-azure-dim", "bg-indigo-dim", "bg-info-dim", "bg-purple-dim", "bg-pink-dim", "bg-orange-dim", "bg-teal-dim", "bg-primary-dim", "bg-success-dim", "bg-warning-dim", "bg-danger-dim", "bg-secondary-dim", "bg-dark-dim", "bg-gray-dim"];
    nameBorderColors: any = ["bg-outline-primary", "bg-outline-success", "bg-outline-info", "bg-outline-gray", "bg-outline-warning", "bg-outline-dark", "bg-outline-light", "bg-outline-secondary", "bg-outline-danger"];
    statuses: { label: string; value: string; }[];
    statusApi: any;
    statusValues: any;
    weeksName = [];
    week_shift_id: any;

    constructor(private spinnerService: SpinnerService,
        private apiModulesService: ApiModulesService,
        private formBuilder: FormBuilder,
        private toaster: ToastrService,
        private toastr: ToastrService,) {
    }

    ngOnInit(): void {
        this.spinnerService.raiseDataEmitterEvent('off');
        this.ShowForm = false;
        this.GetClientDropDown();
        this.addWeeklyShiftForm = this.formBuilder.group({
            week_shift_name: ["", [Validators.required]],
            week_name_desc: ["", [Validators.required]],
            SUN: ["", [Validators.required]],
            MON: ["", [Validators.required]],
            TUE: ["", [Validators.required]],
            WED: ["", [Validators.required]],
            THU: ["", [Validators.required]],
            FRI: ["", [Validators.required]],
            SAT: ["", [Validators.required]]

        });
        this.editWeeklyShiftForm = this.formBuilder.group({
            week_shift_name:["",[Validators.required]],
            week_name_desc:["",[Validators.required]],
            week_shift_id: ["", []],
            client_id: ["", []],
            company_id: ["", []],
            SUN: ["", [Validators.required]],
            MON: ["", [Validators.required]],
            TUE: ["", [Validators.required]],
            WED: ["", [Validators.required]],
            THU: ["", [Validators.required]],
            FRI: ["", [Validators.required]],
            SAT: ["", [Validators.required]]

        });
        this.statuses = [
            { label: 'Active', value: 'active' },
            { label: 'Inactive', value: 'inactive' },
        ];
    }

    showAddForm() {
        this.ShowForm = true;
        this.resetForm();
    }

    ngOnDestroy(): void {
        // Do not forget to unsubscribe the event

    }

    public getWeeklyShift() {
        this.spinnerService.raiseDataEmitterEvent('on');
        if (this.CompanyDropDown.length !== 0 && this.CompanyID != null) {
            var params: any = {};
            params.client_id = this.ClientID;
            params.company_id = this.CompanyID;
            this.apiModulesService.list("admin/weekly_shift/getlist", params).subscribe((data) => {

                this.ShowForm = false;
                this.weeklyshiftdata = (data.data.weekly_shift != undefined) ? data.data.weekly_shift : [];
                this.loading = false;
                var totalRecords = this.weeklyshiftdata.length;
                this.resetForm();

                this.spinnerService.raiseDataEmitterEvent('off');

            }, (err) => {
                this.weeklyshiftdata = [];
                this.spinnerService.raiseDataEmitterEvent('off');
                // this.rerender();
            });
        } else {
            this.rerender();
        }

    }

    public getdropDownShift() {
        var params: any = {};
        params.client_id = this.ClientID;
        params.company_id = this.CompanyID;
        this.apiModulesService.list("admin/weekly_shift/dropdown", params).subscribe((data) => {
            this.weeklyshiftdropdown = (data.data.weekly_shift[0] != undefined) ? data.data.weekly_shift[0] : [];
            this.weeksName = (data.data.weekly_shift[1] != undefined) ? data.data.weekly_shift[1] : [];
        });
    }

    rerender() {


    }

    /* client DropDown codes*/
    public GetClientDropDown() {
        this.apiModulesService.get('DropDown/client').subscribe((data) => {
            this.ClientDropDown = data.data.client;

            this.ClientID = data.data.client[0].client_id;
            this.GetCompanyDropDown();

        })
    }

    /* company DropDown codes */
    public GetCompanyDropDown() {
        const params: any = {};
        params.client_id = this.ClientID;

        this.apiModulesService.list('DropDown/company', params).subscribe((data) => {
            this.CompanyDropDown = data.data.company != undefined ? data.data.company : [];
            if (this.CompanyDropDown.length !== 0) {
                setTimeout(() => {
                    this.CompanyID = this.CompanyDropDown[0].company_id;
                    this.getWeeklyShift();
                    this.getdropDownShift();
                }, 100)
            }
            else {
                this.CompanyID = null;
                this.rerender();
            }

        }
        )
    }

    clear(table: Table) {
        table.clear();
    }

    getSeverity(status: string) {
        switch (status.toLowerCase()) {
            case 'inactive':
                return 'danger';

            case 'active':
                return 'success';
        }
    }

    changeStatus(Status: any, data) {
        let params: any = {};
        params.client_id = this.ClientID;
        params.week_shift_id = data.week_shift_id,
            params.company_id = this.CompanyID;

        this.apiModulesService.edit(params, 'admin/weekly_shift/' + Status).subscribe((data) => {
            this.getWeeklyShift();
            ($('#week-status') as any).modal('hide');
            this.toaster.success('Status Changed Successfully!', 'Success')
        }, (err) => {
            this.toastr.error("Something went wrong!", "Try Again");
        }
        );
    }

    public addWeeklyPlan() {

        if (!this.addWeeklyShiftForm.valid) {

            this.addWeeklyShiftForm.markAllAsTouched();
            return;
        }
        const data: any = {};
        for (let i = 0; i < this.weeksName.length; i++) {
            data[this.weeksName[i].day_id] = this.addWeeklyShiftForm.value[this.weeksName[i].day_short]
        }
        var params: any = {};

        params.company_id = this.CompanyID;
        params.client_id = this.ClientID;
        params.week_shift_name = this.addWeeklyShiftForm.get('week_shift_name').value;
        params.week_shift_desc = this.addWeeklyShiftForm.get('week_name_desc').value;
        params.data = [data];


            this.apiModulesService.add(params, "admin/weekly_shift/create",).subscribe((data) => {
                this.toastr.success('Weekly Shift Added Sucessfully...!', 'Success');
                this.addWeeklyShiftForm.reset();
                ($('#add_weekly_plan') as any).modal('hide');
                this.getWeeklyShift();
            }, err => {

                if (err.status === 437) {
                    this.toastr.error(' Weekly Shift Already Exists...!', 'Failed');
                } else {
                    this.toastr.error('Something Went Wrong Please Try Again...!', 'Error');
                }
            });

    }



    public updateWeeklyPlan() {
        if (!this.editWeeklyShiftForm.valid) {

            this.editWeeklyShiftForm.markAllAsTouched();
            return;
        }
        const data: any = {};
        for (let i = 0; i < this.weeksName.length; i++) {
            data[this.weeksName[i].day_id] = this.editWeeklyShiftForm.value[this.weeksName[i].day_short]
        }
        var params: any = {};

        params.company_id = this.CompanyID;
        params.client_id = this.ClientID;
        params.week_shift_name = this.editWeeklyShiftForm.get('week_shift_name').value;
        params.week_shift_desc = this.editWeeklyShiftForm.get('week_name_desc').value;
        params.data = [data];
        // var week_shift_id = this.editWeeklyShiftForm.get('week_shift_id').value;
        params.week_shift_id = this.editWeeklyShiftForm.get('week_shift_id').value;
        this.apiModulesService.edit(params, "admin/weekly_shift/update",).subscribe((data) => {
            this.toastr.success('Weekly Shift Modified Sucessfully...!', 'Success');
            setTimeout(()=>{
              ($('#edit_weekly_plan') as any).modal('hide');
            },100)

            this.getWeeklyShift();
        }, err => {

            if (err.status === 437) {
                this.toastr.error('Weekly Shift Already Exists...!', 'Failed');
            } else {
                this.toastr.error('Something Went Wrong Please Try Again...!', 'Error');
            }
        });

    }


    //Reset form
    public resetForm() {
        this.addWeeklyShiftForm.reset();
    }

    filter(array,filterVal){
        let day_id=this.weeksName.filter((val:any)=>val.day_short===filterVal).map((data:any)=>data.day_id)[0]
        return array.filter((val:any)=>val.day_id===day_id).map((data:any)=>data.shift_id)[0]
    }
    public editWeeklyPlan(shift_id) {
        var params: any = {};

        params.client_id = this.ClientID;
        params.company_id = this.CompanyID;
        params.week_shift_id = shift_id;
        this.apiModulesService.edit(params,"admin/weekly_shift/edit").subscribe((data)=>{
          let weekshiftdata:any= data.data.weekly_shift[0][0];
          let weekData:any=[]
          weekData=data.data.weekly_shift[1];



        this.editWeeklyShiftForm.setValue({

            week_shift_name: weekshiftdata.week_shift_name,
            week_name_desc: weekshiftdata.week_shift_desc,
            week_shift_id: weekshiftdata.week_shift_id,
            SUN:this.filter(weekData,'SUN') !=undefined ?this.filter(weekData,'SUN'):null,
            MON:this.filter(weekData,'MON') !=undefined ?this.filter(weekData,'MON'):null,
            TUE: this.filter(weekData,'TUE') !=undefined ?this.filter(weekData,'TUE'):null,
            WED:this.filter(weekData,'WED') !=undefined ?this.filter(weekData,'WED'):null,
            THU:this.filter(weekData,'THU') !=undefined ?this.filter(weekData,'THU'):null,
            FRI:this.filter(weekData,'FRI') !=undefined ?this.filter(weekData,'FRI'):null,
            SAT: this.filter(weekData,'SAT') !=undefined ?this.filter(weekData,'SAT'):null,

            client_id: this.ClientID,
            company_id: this.CompanyID
        });
        this.ShowForm = true;


    })
}

    getAcronym(str: any) {
        if (str != null) {
            let matches = str.match(/\b(\w)/g); // ['J','S','O','N']
            matches = matches.slice(0, 2);
            const acronym = matches.join(''); // JSON
            return acronym;
        }
    }

    getBgColors(str: any) {
        if (str != null) {
            const min = 0;
            const max = 15;
            const index = this.findAlphapositions(str, 1);
            return this.nameBgColors[index];
        }
    }

    findAlphapositions(str: any, n: any) {
        var NUM = 31;

        return str[0].charCodeAt(0) & NUM;
    }

    callStatusModel(url_name: any, data: any) {
        this.statusApi = url_name;
        this.statusValues = data;


    }

}
