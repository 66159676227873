import { Component, ElementRef } from '@angular/core';
import { SpinnerService } from 'src/app/snipper/spinner.service';
import { FormBuilder, FormControl, FormGroup, FormsModule, Validators } from '@angular/forms';
import { ApiModulesService } from 'src/app/all-modules/api-modules.service';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-vpf',
  templateUrl: './vpf.component.html',
  styleUrls: ['./vpf.component.css']
})
export class VPFComponent {
  selectedPaymentType: string = ''; // variable to store the selected payment type
  fixedAmount: any// variable to store the fixed amount
  percentageAmount: any // variable to store the percentage amount
  employeeOptionData: any;
  MonthYearDate: any;
  clientID = localStorage.getItem('client_id');
  companyID = localStorage.getItem('company_id');
  employeeID = localStorage.getItem('employee_id');
  selectedOption: string = '';
  percentageAmountVpfAmount: number=0;
  addVpfForm: FormGroup;
  basicValue: any;
  Currentstatus:any;
  CurrentVpf:any;
  formatedDate: any=new Date();
  vpfData: any;
   stopMonthYearDate: Date;
  formatedStopDate:any;
  changedByStop=false
  minDate:any;
  constructor(private spinnerService: SpinnerService, private elementRef: ElementRef,
    private formBuilder: FormBuilder, private apiModulesService: ApiModulesService, private toastrService: ToastrService, private datePipe: DatePipe) { }
  ngOnInit() {
    const today = new Date();
    // Set minDate to the first day of the current month
    this.minDate = new Date(today.getFullYear(), today.getMonth(), 1);
    this.spinnerService.raiseDataEmitterEvent('off');
    const currentDate = new Date();
    this.MonthYearDate = currentDate
    this.stopMonthYearDate = currentDate

    // this.employeeOptionData = [
    //   { component_id: "1", component_key: "basic", component_name: "Basic", component_values: "47917" },

    // ]
    // this.basicValue = this.employeeOptionData[0].component_values
    this.addVpfForm = this.formBuilder.group({
      component_values: [['']],
      paymentType: ['',[Validators.required]],
      percentage: ['', []]
    });

    this.monthChange();
    this.stopMonthChange();
  }
  inputPercentage(event) {

    const basicEntered = Number(this.basicValue) * event.target.value / 100;

    this.addVpfForm.patchValue({
      component_values: basicEntered
    })
  }
  changeRadio(option: string) {
    this.addVpfForm.patchValue({
      component_values: null,
      percentage: null
    })
    this.selectedOption = option;
  }

  monthChange() {
    this.formatedDate = this.datePipe.transform(this.MonthYearDate, 'MMM/yyyy') || this.MonthYearDate;
    this.getStartVpfData();

  }
  stopMonthChange() {
    this.formatedStopDate = this.datePipe.transform(this.stopMonthYearDate, 'MMM/yyyy') || this.stopMonthYearDate;
    this.getStopVpfData();

  }
  public onAddVPf(status) {
    if (this.addVpfForm.invalid) {
      this.addVpfForm.markAllAsTouched();
      return;
  }

    var params: any = {};
    params.client_id = this.clientID;
    params.company_id = this.companyID;
    params.employee_id = this.employeeID;
    params.vpf_status = status;
    params.percentage= this.basicValue;
    if (status=== 'stop') {
      params.vpf_employee_option = null
      params.percentage= null
      params.vpf_amount= null
      params.year_and_month = this.formatedStopDate;

    } else if (status=== 'start') {
      params.vpf_employee_option = this.selectedOption;
      params.percentage= this.addVpfForm.get('percentage').value;
      params.vpf_amount= this.addVpfForm.get('component_values').value;
      params.year_and_month = this.formatedDate;

    }
    this.apiModulesService.add(params, 'employee-tabs/IND-employee-payroll/vpf/add',).subscribe((data) => {
      this.addVpfForm.reset();
      this.toastrService.success('pf added successfully...!', 'Success');
      this.getStartVpfData();
      this.getStopVpfData();


    }, (err) => {
      this.spinnerService.toasterError(err);
        });

  }
  getStartVpfData() {
    this.spinnerService.raiseDataEmitterEvent('on');
    const params: any = {};
    params.client_id = this.clientID;
    params.company_id = this.companyID;
    params.employee_id = this.employeeID;
    params.year_and_month = this.formatedDate;
    this.apiModulesService.list('employee-tabs/IND-employee-payroll/vpf/start', params).subscribe((data) => {
      this.vpfData = data.data.vpf_amount[1][0] != undefined ? data.data.vpf_amount[1][0] : [];
      this.basicValue=data.data.vpf_amount[0][0].basic_value  != undefined ?data.data.vpf_amount[0][0].basic_value:0;
      this.Currentstatus=data.data.vpf_amount[0][0].current_status  != undefined ?data.data.vpf_amount[0][0].current_status:null;
      this.CurrentVpf=data.data.vpf_amount[0][0].current_vpf  != undefined ?data.data.vpf_amount[0][0].current_vpf:null;

      if(this.vpfData.length!=0){
        this.addVpfForm.setValue({
          component_values: this.vpfData.vpf_amount,
        paymentType: this.vpfData.vpf_employee_option,
        percentage: this.vpfData.percentage
        });
      }
      this.spinnerService.raiseDataEmitterEvent('off');

    },(err)=>{
      this.spinnerService.toasterError(err);

    });
  }
  getStopVpfData() {
    this.spinnerService.raiseDataEmitterEvent('on');
    const params: any = {};
    params.client_id = this.clientID;
    params.company_id = this.companyID;
    params.employee_id = this.employeeID;
    params.year_and_month = this.formatedStopDate;
    this.apiModulesService.list('employee-tabs/IND-employee-payroll/vpf/stop', params).subscribe((data) => {
      const respose:any=data.data.vpf_amount[0]!=undefined?data.data.vpf_amount[0]:[];
      if(respose.length!=0 && this.changedByStop){


        this.stopMonthYearDate=respose.year_and_month !=null?new Date(respose.year_and_month):this.stopMonthYearDate;
      }else{

        this.stopMonthYearDate=respose.year_and_month !=null?new Date(respose.year_and_month):null;

      }
      this.changedByStop=false;
      this.spinnerService.raiseDataEmitterEvent('off');

    },(err)=>{
      this.spinnerService.toasterError(err);

    });
  }


  resetForm() {
    this.addVpfForm.reset();
  }
}
