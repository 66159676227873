import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, NavigationEnd, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, filter } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';

@Injectable({
    providedIn: 'root'
})
export class AdminAuthenticationGaurd implements CanActivate {
    activeUrl: any;

    constructor(private service: AuthService, private router: Router) {
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)
      ).subscribe((event: NavigationEnd) => {

        this.activeUrl = event.url;
        this.service.PemissionMenus(event.url);
      });
    }

    async canActivate(
      next: ActivatedRouteSnapshot,
      state: RouterStateSnapshot
    ): Promise<boolean> {
      const navigatedUrl: string = state.url;

      if (this.service.adminLoggedIn()) {
        await this.service.PemissionMenus(navigatedUrl);
        const hasPermission = await this.service.checkPermission();
        if (hasPermission) {
          return true;
        } else {
          this.router.navigate(['login']);
          return false;
        }
      } else {
        this.router.navigate(['login']);
        return false;
      }
    }
  }






