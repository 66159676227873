import { Component,OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ApiModulesService } from 'src/app/all-modules/api-modules.service';
import { documentservice } from 'src/app/all-modules/documents.sevice';
import { SpinnerService } from 'src/app/snipper/spinner.service';

@Component({
  selector: 'app-adminpayrollreport',
  templateUrl: './adminpayrollreport.component.html',
  styleUrls: ['./adminpayrollreport.component.css']
})
export class AdminpayrollreportComponent implements OnInit{

  nameBgColors: any = ['bg-blue-dim', 'bg-blue-dim', 'bg-azure-dim', 'bg-indigo-dim', 'bg-info-dim', 'bg-purple-dim', 'bg-pink-dim', 'bg-orange-dim', 'bg-teal-dim', 'bg-primary-dim', 'bg-success-dim', 'bg-warning-dim', 'bg-danger-dim', 'bg-secondary-dim', 'bg-dark-dim', 'bg-gray-dim', 'bg-blue-dim', 'bg-azure-dim', 'bg-indigo-dim', 'bg-info-dim', 'bg-purple-dim', 'bg-pink-dim', 'bg-orange-dim', 'bg-teal-dim', 'bg-primary-dim', 'bg-success-dim', 'bg-warning-dim', 'bg-danger-dim', 'bg-secondary-dim', 'bg-dark-dim', 'bg-gray-dim'];
  nameBorderColors: any = ['bg-outline-primary', 'bg-outline-success', 'bg-outline-info', 'bg-outline-gray', 'bg-outline-warning', 'bg-outline-dark', 'bg-outline-light', 'bg-outline-secondary', 'bg-outline-danger'];
  ClientID: any;
  CompanyDropDown: any;
  CompanyID: any;
  ClientDropDown: any;
  uploadFileData: any;
  financial_date_id: any;
  financialDropdownData: any;
  statusApi: any;
  statusValues: any;
  statuses: { label: string; value: string; }[];
  constructor(private spinnerService: SpinnerService, private apiModulesService: ApiModulesService, private documentService: documentservice,
    private toastr: ToastrService,) {
  }
  ngOnInit() {
    this.spinnerService.raiseDataEmitterEvent('on');
    this.GetClientDropDown();
    this.statuses = [
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ];
  }

  public GetClientDropDown() {
    this.apiModulesService.get('DropDown/client').subscribe((data) => {
      this.ClientDropDown = data.data.client;
      this.ClientID = data.data.client[0].client_id;
      this.GetCompanyDropDown();
    }, (err) => {
      this.spinnerService.raiseDataEmitterEvent('off');
      let errors = err.error.data;
      errors = errors.replace('SQLSTATE[42000]: [Microsoft][ODBC Driver 17 for SQL Server][SQL Server]', 'Error: ')
      this.toastr.error(errors, 'TryAgain');
    });
  }
  /* company DropDown codes */
  public GetCompanyDropDown() {
    const params: any = {};
    params.client_id = this.ClientID;
    this.apiModulesService.list('DropDown/company', params).subscribe((data) => {
      this.CompanyDropDown = data.data.company != undefined ? data.data.company : [];
      if (this.CompanyDropDown.length !== 0) {
        this.CompanyID = this.CompanyDropDown[0].company_id;
        setTimeout(() => {

          this.dropDown();
        }, 100)
      }
      else {
        this.CompanyID = null;
      }
    }, (err) => {
      this.spinnerService.raiseDataEmitterEvent('off');
      let errors = err.error.data;
      errors = errors.replace('SQLSTATE[42000]: [Microsoft][ODBC Driver 17 for SQL Server][SQL Server]', 'Error: ')
      this.toastr.error(errors, 'TryAgain');
    });
  }
  dropDown() {
    this.spinnerService.raiseDataEmitterEvent('on');
    const params: any = {};
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;

    this.apiModulesService.list('employee-tabs/employee-hrm/payroll-draft/dropdown', params).subscribe((data) => {
      this.financialDropdownData = data.data.payroll_report;
      this.financial_date_id = this.financialDropdownData[0].financial_date_id;
      this.payrollUploadFileData();
      this.spinnerService.raiseDataEmitterEvent('off');

    }, (err: any) => {
      this.spinnerService.toasterError(err);
    })

  }

  payrollUploadFileData() {
    this.spinnerService.raiseDataEmitterEvent('on');
    const params: any = {};
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    params.financial_date_id = this.financial_date_id;
    this.apiModulesService.list('admin/payroll-master/list', params).subscribe((data: any) => {
      this.uploadFileData = data.data.payroll_upload != undefined ? data.data.payroll_upload : []

      this.spinnerService.raiseDataEmitterEvent('off');
    }, (err: any) => {
      this.spinnerService.toasterError(err);
        });
  }

  public DownloadFile(dataImportId) {
    const params: any = {};
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    params.data_import_id = dataImportId;
    this.apiModulesService.add(params, 'admin/payroll-master/download').subscribe(
      (data) => {
        const docDetails = data.data.payroll_upload;
        this.documentService.DownloadExcel(docDetails.doc_name, docDetails.doc_url);
        this.toastr.success('File Downloaded Sucessfully...!', 'Success');
      },
      (err) => {
        this.spinnerService.toasterError(err);
      }
    );
  }
  changeStatus(Status: any, dataImportId) {
    let params: any = {};
    params.client_id = this.ClientID;
      params.company_id = this.CompanyID;
      params.data_import_id = dataImportId,
    this.apiModulesService.edit(params, 'admin/payroll-master/' + Status).subscribe((data) => {

      ($('#admin-payroll-status') as any).modal('hide');
      this.toastr.success('Status Changed Successfully!', 'Success')
      this.payrollUploadFileData();
    }, (err) => {
      this.spinnerService.toasterError(err);

    }
    );
  }
  callStatusModel(url_name: any, data: any) {
    this.statusApi = url_name;
    this.statusValues = data;
  }
  getSeverity(status: string) {
    switch (status.toLowerCase()) {
      case 'inactive':
        return 'danger';

      case 'active':
        return 'success';
    }
  }
  getAcronym(str: any) {
    var matches = str.match(/\b(\w)/g); // ['J','S','O','N']
    matches = matches.slice(0, 2);
    var acronym = matches.join(''); // JSON
    return acronym
  }
  getBgColors(str: any) {
    var min = 0;
    var max = 15;
    var index = this.findAlphapositions(str, 1);
    return this.nameBgColors[index];
  }
  findAlphapositions(str: any, n: any) {
    var NUM = 31;

    return str[0].charCodeAt(0) & NUM;
  }

}
