// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.statusactive{
	font-size: 30px;
}
.popup-headers{
  margin-top: -20px;
}
`, "",{"version":3,"sources":["webpack://./src/app/all-modules/employees/bankdetails/bankdetails.component.css"],"names":[],"mappings":"AAAA;CACC,eAAe;AAChB;AACA;EACE,iBAAiB;AACnB","sourcesContent":[".statusactive{\r\n\tfont-size: 30px;\r\n}\r\n.popup-headers{\r\n  margin-top: -20px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
