// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.client-company-name {
	border: 1px solid rgba(226, 221, 221, 0.562);
	margin-right: 5px;
	padding: 5px;
}
.back{
	position: relative;
    right: -7px;
    top: -125px;
}`, "",{"version":3,"sources":["webpack://./src/app/all-modules/company/paycomponentformula/paycomponentformula.component.css"],"names":[],"mappings":"AAAA;CACC,4CAA4C;CAC5C,iBAAiB;CACjB,YAAY;AACb;AACA;CACC,kBAAkB;IACf,WAAW;IACX,WAAW;AACf","sourcesContent":[".client-company-name {\r\n\tborder: 1px solid rgba(226, 221, 221, 0.562);\r\n\tmargin-right: 5px;\r\n\tpadding: 5px;\r\n}\r\n.back{\r\n\tposition: relative;\r\n    right: -7px;\r\n    top: -125px;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
