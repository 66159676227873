// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-emp-header {
  display: flex;

}

li {
  list-style: none;
}

.employee-name-no {
  display: flex;
  text-align: center;
  flex-direction: column;

  justify-content: center;
}

.card-align {
  /* width: 27px; */
  display: inline;
  padding: 7px;
  margin-bottom: 0px;
  float: unset;
  text-decoration: underline;
  color: #2196F3;
}

.link-group {
  color: #2196F3;
  text-decoration-color: #2196F3;
  cursor: pointer;
}

.emp-time-view {
  width: 14%;
}

.to-date {
  position: relative;
  left: 110%;
  top: -30px;
}

.cal_to_date {
  width: 40%;
  display: inline-flex;
}

label {
  display: inline-block;
  width: 14%;
}
`, "",{"version":3,"sources":["webpack://./src/app/all-modules/ess-tabs/IND-ess/IND-employee-hrms/manager-page/timemanager/employeetimeview/employeetimeview.component.css"],"names":[],"mappings":"AAAA;EACE,aAAa;;AAEf;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,kBAAkB;EAClB,sBAAsB;;EAEtB,uBAAuB;AACzB;;AAEA;EACE,iBAAiB;EACjB,eAAe;EACf,YAAY;EACZ,kBAAkB;EAClB,YAAY;EACZ,0BAA0B;EAC1B,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,8BAA8B;EAC9B,eAAe;AACjB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,UAAU;AACZ;;AAEA;EACE,UAAU;EACV,oBAAoB;AACtB;;AAEA;EACE,qBAAqB;EACrB,UAAU;AACZ","sourcesContent":[".card-emp-header {\r\n  display: flex;\r\n\r\n}\r\n\r\nli {\r\n  list-style: none;\r\n}\r\n\r\n.employee-name-no {\r\n  display: flex;\r\n  text-align: center;\r\n  flex-direction: column;\r\n\r\n  justify-content: center;\r\n}\r\n\r\n.card-align {\r\n  /* width: 27px; */\r\n  display: inline;\r\n  padding: 7px;\r\n  margin-bottom: 0px;\r\n  float: unset;\r\n  text-decoration: underline;\r\n  color: #2196F3;\r\n}\r\n\r\n.link-group {\r\n  color: #2196F3;\r\n  text-decoration-color: #2196F3;\r\n  cursor: pointer;\r\n}\r\n\r\n.emp-time-view {\r\n  width: 14%;\r\n}\r\n\r\n.to-date {\r\n  position: relative;\r\n  left: 110%;\r\n  top: -30px;\r\n}\r\n\r\n.cal_to_date {\r\n  width: 40%;\r\n  display: inline-flex;\r\n}\r\n\r\nlabel {\r\n  display: inline-block;\r\n  width: 14%;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
