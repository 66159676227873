import { Component, OnInit,ViewChild,AfterViewInit,OnDestroy } from '@angular/core';
import{ApiModulesService} from '../../../api-modules.service';
import{AllModulesService} from '../../../all-modules.service';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { SpinnerService } from 'src/app/snipper/spinner.service';
import { Table } from 'primeng/table';
@Component({
  selector: 'app-documenttype-main',
  templateUrl: './documenttype-main.component.html',
  styleUrls: ['./documenttype-main.component.css']
})
export class DocumenttypeMainComponent implements OnInit,AfterViewInit,OnDestroy {
  public documentData=[];
  public rows = [];
  public srch = [];
  public countryname=[];
  loading=false;
  listHeaders=[];
  selected_country:any;
  public totalRecords: any = 0;
  limit = 10;
  pageNo = 1;
  searchKeyword: string = '';
  public addDocumentForm:FormGroup;
  public editDocumentForm:FormGroup;
  codeRegex = /^[ A-Za-z0-9_]*$/;
  invalidAddForm=false;
  invalidEditForm=false;
  isDtInitialized: boolean = false;
  nameBgColors:any = ['bg-blue-dim','bg-blue-dim','bg-azure-dim','bg-indigo-dim','bg-info-dim','bg-purple-dim','bg-pink-dim','bg-orange-dim','bg-teal-dim','bg-primary-dim','bg-success-dim','bg-warning-dim','bg-danger-dim','bg-secondary-dim','bg-dark-dim','bg-gray-dim','bg-blue-dim','bg-azure-dim','bg-indigo-dim','bg-info-dim','bg-purple-dim','bg-pink-dim','bg-orange-dim','bg-teal-dim','bg-primary-dim','bg-success-dim','bg-warning-dim','bg-danger-dim','bg-secondary-dim','bg-dark-dim','bg-gray-dim'];
    nameBorderColors:any = ['bg-outline-primary','bg-outline-success','bg-outline-info','bg-outline-gray','bg-outline-warning','bg-outline-dark','bg-outline-light','bg-outline-secondary','bg-outline-danger'];
  statuses: { label: string; value: string; }[];
  statusApi: any;
  statusValues: any;

  constructor(private apiModulesService:ApiModulesService,private AllModulesService:AllModulesService,private toastr: ToastrService,private formBuilder: FormBuilder,private spinnerService: SpinnerService,) { }

  ngOnInit(): void {
    this.getCountrinesName();
    this.addDocumentForm=this.formBuilder.group({
      country_id: ['', [Validators.required]],
      document_type: ['', [Validators.required,Validators.pattern(this.codeRegex)]],

     });
     this.editDocumentForm=this.formBuilder.group({
      country_id: ['', [Validators.required]],
      document_type: ['', [Validators.required,Validators.pattern(this.codeRegex)]],
      document_type_id:['',[]],
     });
     this.statuses = [
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ];
  }

  ngAfterViewInit(): void {

  }

  rerender(): void {

  }

    //Reset form
    public resetForm() {
      this.addDocumentForm.reset();
    }

     //add data
  public onAddDocument() {
    if(this.addDocumentForm.invalid){
      this.addDocumentForm.markAllAsTouched()
      this.invalidAddForm=true;
      return
    }
    this.invalidAddForm=false;
    var params:any = {};
    params.document_type = this.addDocumentForm.get('document_type').value;
    params.country_id = this.addDocumentForm.get('country_id').value;
    params.status = 1;

      this.apiModulesService.add(params,'admin/document-type/add',).subscribe((data) => {
        this.selected_country = this.addDocumentForm.get('country_id').value;
        this.getDocument();
        this.toastr.success('Document Added Sucessfully...!', 'Success');
        this.addDocumentForm.reset();
        ($('#add_document')as any).modal('hide');
    }, err => {

        if (err.status === 437) {
            this.toastr.error('Document Already Exists...!', 'Failed');
        } else {
            this.toastr.error('Something Went Wrong Please Try Again...!', 'Error');
        }
    });

  }

  public getDocument() {
    this.spinnerService.raiseDataEmitterEvent('on');
    var params:any={};
    params.country_id=this.selected_country;
          this.apiModulesService.list('admin/document-type/list',params).subscribe((data) => {
              this.documentData = (data.data.document_type != undefined) ? data.data.document_type : [];
              this.loading = false;
              var totalRecords = (this.documentData[0] != undefined) ? this.documentData[0].total_records : 0;
              this.totalRecords = totalRecords;
              this.resetForm();
              setTimeout(() => {
                this.spinnerService.raiseDataEmitterEvent('off');
            }, 100);
          }, (err) => {
            this.documentData = [];
            this.spinnerService.raiseDataEmitterEvent('off');
            this.toastr.error('Something went wrong!', 'Try Again');
              // this.rerender();
          });
  }

  clear(table: Table) {
    table.clear();
  }

  getSeverity(status: string) {
    switch (status.toLowerCase()) {
      case 'inactive':
        return 'danger';

      case 'active':
        return 'success';
    }
  }

  //get countries name
  public getCountrinesName(){
    this.apiModulesService.get('country/namelist').subscribe((data)=>{
      this.countryname =data;
      this.selected_country=this.countryname[0].country_id;
      this.getDocument();
    });
  }
  // status change
  changeStatus(status:any,DocumentTypeId:any) {

    var params:any = {};
    params.document_type_id= DocumentTypeId;
    params.status= status;
      this.apiModulesService.edit(params,'admin/document-type/status').subscribe(() => {
        this.getDocument();
        ($('#document-status') as any).modal('hide');
        this.toastr.success('Status changed successfully...!', 'Success');
      },(err)=>{
        this.toastr.error('Something went wrong!', 'Try Again');
      }
      );
  }

  // searchName(val) {
  //   this.rows.splice(0, this.rows.length);
  //   let temp = this.srch.filter(function (d) {
  //     val = val.toLowerCase();
  //     return d.document_type.toLowerCase().indexOf(val) !== -1;
  //   });
  //   this.rows.push(...temp);
  // }

  //search by company
  searchRegionName(val) {
    this.rows.splice(0, this.rows.length);
    let temp = this.srch.filter(function (d) {
      val = val.toLowerCase();
      return d.country_name.country_name.toLowerCase().indexOf(val) !== -1 || !val;
    });
    this.rows.push(...temp);
  }

     //edit value get
     public onEditDocument(DocumentTypeId: any) {
      var params:any = {};
      params.document_type_id = DocumentTypeId;
      this.apiModulesService.edit(params,'admin/document-type/edit').subscribe((data) => {
        let documentDetail = data.data.document_type;
        this. editDocumentForm.setValue({
          document_type:documentDetail.document_type,
          country_id:documentDetail.country_id,
          document_type_id:documentDetail.document_type_id,

        });
        let setCountry= this.countryname.filter((data:any)=>documentDetail.country_id === data.country_id);

      });

    }
    //edit update
    public onEditChanges(){
      if (this.editDocumentForm.invalid) {

        this.editDocumentForm.markAllAsTouched();
        this.invalidEditForm = true;
        return;
      }
      this.invalidEditForm = false;
      var params:any={};
      params.document_type=this.editDocumentForm.get('document_type').value;
      params.country_id=this.editDocumentForm.get('country_id').value;
      params.document_type_id=this.editDocumentForm.get('document_type_id').value;

      this.apiModulesService.edit(params,'admin/document-type/update').subscribe((data)=>{


          this.selected_country = this.editDocumentForm.get('country_id').value;
          this.getDocument();
          this.toastr.success('Document Modified Sucessfully...!', 'Success');
          ($('#edit_document')as any).modal('hide');
      }, err => {
          if (err.status === 437) {
              this.toastr.error('Document Already Exists...!', 'Failed');
          } else {
              this.toastr.error('Something Went Wrong Please Try Again...!', 'Error');
          }
      });
    }
    ngOnDestroy(): void {
      // Do not forget to unsubscribe the event
    }
    getAcronym(str:any){
      var matches = str.match(/\b(\w)/g); // ['J','S','O','N']
      matches= matches.slice(0,2);
      var acronym = matches.join(''); // JSON
      return acronym;
  }
  getBgColors(str:any){
      var min = 0;
      var max = 15;
      var index = this.findAlphapositions(str,1);
      return this.nameBgColors[index];
  }
  findAlphapositions(str:any, n:any)
  {
      var NUM = 31;

      return str[0].charCodeAt(0) & NUM;
  }
  callStatusModel(url_name:any,data:any){
    this.statusApi=url_name;
    this.statusValues=data;
  }

}
