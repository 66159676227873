import { Component, OnInit,ViewChild  } from '@angular/core';
import{ApiModulesService} from '../../../api-modules.service';
import{AllModulesService} from '../../../all-modules.service';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import {Router} from '@angular/router';

import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { SpinnerService } from 'src/app/snipper/spinner.service';
import { Table } from 'primeng/table';


@Component({
  selector: 'app-states-main',
  templateUrl: './states-main.component.html',
  styleUrls: ['./states-main.component.css']
})
export class StatesMainComponent implements OnInit {



  public statesData =[];
 public statename = [];
 public countryname =[];
 listHeaders=[];
 loading=false;
 isDtInitialized: boolean = false;
 public rows = [];
 public srch = [];
 nameRegex=/^[a-zA-Z\s]*$/;
 searchStates:any;
 searchCountry:any;
 selected_country:any;
 public totalRecords: any = 0;
 searchKeyword: string = '';

 public addStateForm:FormGroup;
 public editStateForm:FormGroup;
  search: any[];
  nameBgColors:any = ['bg-blue-dim','bg-blue-dim','bg-azure-dim','bg-indigo-dim','bg-info-dim','bg-purple-dim','bg-pink-dim','bg-orange-dim','bg-teal-dim','bg-primary-dim','bg-success-dim','bg-warning-dim','bg-danger-dim','bg-secondary-dim','bg-dark-dim','bg-gray-dim','bg-blue-dim','bg-azure-dim','bg-indigo-dim','bg-info-dim','bg-purple-dim','bg-pink-dim','bg-orange-dim','bg-teal-dim','bg-primary-dim','bg-success-dim','bg-warning-dim','bg-danger-dim','bg-secondary-dim','bg-dark-dim','bg-gray-dim'];
    nameBorderColors:any = ['bg-outline-primary','bg-outline-success','bg-outline-info','bg-outline-gray','bg-outline-warning','bg-outline-dark','bg-outline-light','bg-outline-secondary','bg-outline-danger'];
  statuses: { label: string; value: string; }[];
  statusApi: any;
  statusValue: any;
  statusValues: any;
  constructor(private router: Router,
    private apiModulesService: ApiModulesService,
     private toastr: ToastrService,
     private formBuilder: FormBuilder,
      private AllModulesService:AllModulesService,
      private spinnerService: SpinnerService,
      private  http: HttpClient) { }

  ngOnInit(): void {
    this.getCountrinesName();

    // add state
 this.addStateForm=this.formBuilder.group({
  country_id: ['', [Validators.required]],
  state_name: ['', [Validators.required,Validators.pattern(this.nameRegex)]],

 });
 this.editStateForm=this.formBuilder.group({
  country_id: ['', [Validators.required]],
  state_name: ['', [Validators.required,Validators.pattern(this.nameRegex)]],
  state_id:['',[]],
 });
 this.statuses = [
  { label: 'Active', value: 'active' },
  { label: 'Inactive', value: 'inactive' },
];
  }
  ngAfterViewInit(): void {

  }
  rerender(): void {

  }

   //get state
   public getState() {
    var params:any={};
    params.country_id=this.selected_country;
    this.spinnerService.raiseDataEmitterEvent('on');
    this.apiModulesService.list('admin/state/list',params).subscribe((data)=>{
      this.statesData = (data.data.state != undefined) ? data.data.state : [];
      this.loading=false;
      var totalRecords = (this.statesData[0] != undefined) ? this.statesData[0].total_records : 0;
      this.totalRecords = totalRecords;
      this.resetForm();
      setTimeout(() => {
        this.spinnerService.raiseDataEmitterEvent('off');
      }, 100);
    }, (err) => {
      this.statesData = [];
      this.spinnerService.raiseDataEmitterEvent('off');
      this.toastr.error('Something went wrong!', 'Try Again');
      // this.rerender();
    });
  }


  clear(table: Table) {
    table.clear();
  }

  getSeverity(status: string) {
    switch (status.toLowerCase()) {
      case 'inactive':
        return 'danger';

      case 'active':
        return 'success';
    }
  }



  //Reset form
  public resetForm() {
    this.addStateForm.reset();
  }
  public onAddstate() {
    if (!this.addStateForm.valid) {
      this.addStateForm.markAllAsTouched();
      return;
    }
    var params:any = {};
    params.state_name = this.addStateForm.get('state_name').value;
    params.country_id = this.addStateForm.get('country_id').value;
    params.status = 1;
      this.apiModulesService.add(params,'admin/state/add',).subscribe((data) => {
        this.toastr.success('State Added Sucessfully...!', 'Success');
        this.selected_country=this.addStateForm.get('country_id').value;
        this.getState();
        this.addStateForm.reset();
        ($('#add_state')as any).modal('hide');

      },err=>{

          if (err.status === 437) {
              this.toastr.error('State Already Exists...!', 'Failed');
          } else {
              this.toastr.error('Something Went Wrong Please Try Again...!', 'Error');
          }
      });

  }


loadState(){
  this.getState();
}
//get countries name
  public getCountrinesName(){
    this.apiModulesService.get('country/namelist').subscribe((data)=>{
      this.countryname =data;
      this.selected_country=this.countryname[0].country_id;
      this.getState();
    });
  }
  searchName(val) {
     this.rows.splice(0, this.rows.length);
     let temp = this.search.filter(function (d) {
     val = val.toLowerCase();
       return d.state_name.toLowerCase().indexOf(val) !== -1 ||
              d.state_id.toLowerCase().indexOf(val) !== -1 ||
              d.country_name.country_name.toLowerCase().indexOf(val) !== -1;
     });
     this.rows.push(...temp);
  }

  //search by company
  // searchRegionName($event: any) {
  //   let country = $event.target.options[$event.target.options.selectedIndex].text;
  //   this.searchCountry = country.toLowerCase();
  //   this.getState();

    // this.rows.splice(0, this.rows.length);
    // let temp = this.srch.filter(function (d) {
    //   val = val.toLowerCase();
    //   return d.country_name.country_name.toLowerCase().indexOf(val) !== -1 || !val;
    // });
    // this.rows.push(...temp);

  //edit value get
  public onEditStates(StateId: any) {
    var params:any = {};
    params.state_id = StateId;
    this.apiModulesService.edit(params,'admin/state/edit').subscribe((data) => {
      let stateDetail = data.data.state;
      this. editStateForm.setValue({
       state_name:stateDetail.state_name,
        country_id:stateDetail.country_id,
     state_id:stateDetail.state_id,

      });
    });

  }
  //edit update
  public onEditChanges(){
    if (!this.editStateForm.valid) {
      this.editStateForm.markAllAsTouched();
      return;
    }
    var params:any={};
    params.state_name=this.editStateForm.get('state_name').value;
    params.country_id=this.editStateForm.get('country_id').value;
    params.state_id=this.editStateForm.get('state_id').value;
    this.apiModulesService.edit(params,'admin/state/update').subscribe((data)=>{

        this.selected_country=this.editStateForm.get('country_id').value;
        this.getState();
        this.toastr.success('State Edited Sucessfully...!', 'Success');
        ($('#edit_state')as any).modal('hide');
      },err=>{

        if (err.status === 437) {
            this.toastr.error('State Already Exists...!', 'Failed');
        } else {
            this.toastr.error('Something Went Wrong Please Try Again...!', 'Error');
        }
    });

  }


  // status change
  changeStatus(status:any,State_id:any) {

    var params:any = {};
    params.state_id = State_id;
    params.status=status;
    this.apiModulesService.edit(params,'admin/state/status').subscribe((data)=>{
        this. rerender();
        this.getState();
        ($('#state-status') as any).modal('hide');
        this.toastr.success('Status Changed Sucessfully...!', 'Success');
      });
  }
  getAcronym(str:any){
    var matches = str.match(/\b(\w)/g); // ['J','S','O','N']
    matches= matches.slice(0,2);
    var acronym = matches.join(''); // JSON
    return acronym;
}
getBgColors(str:any){
    var min = 0;
    var max = 15;
    var index = this.findAlphapositions(str,1);
    return this.nameBgColors[index];
}
findAlphapositions(str:any, n:any)
{
    var NUM = 31;

    return str[0].charCodeAt(0) & NUM;
}
callStatusModel(url_name:any,data:any){
  this.statusApi=url_name;
  this.statusValues=data;
}

}
