import {
  Component, Input, ViewChild
} from '@angular/core';

import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormControlDirective, FormControlName, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { AllModulesService } from 'src/app/all-modules/all-modules.service';
import { ApiModulesService } from 'src/app/all-modules/api-modules.service';
import { SpinnerService } from 'src/app/snipper/spinner.service';
import { CalendarOptions } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import { Table } from 'primeng/table';
import { CalendarComponent } from "../../../../../common/calendar/calendar.component";
import Chart from 'chart.js/auto';
import { ChartOptions } from 'chart.js';
import { documentservice } from 'src/app/all-modules/documents.sevice';
import { FullCalendarComponent } from '@fullcalendar/angular';
@Component({
  selector: 'app-leave-home',
  templateUrl: './leave-home.component.html',
  styleUrls: ['./leave-home.component.css']
})
export class LeaveHomeComponent {
  @ViewChild('calendar') calendarComponent: FullCalendarComponent;

  calendarOptions: CalendarOptions = {
    plugins: [dayGridPlugin],
    initialView: 'dayGridMonth',
    weekends: true,
    events: [],
    eventContent: this.customEventContent.bind(this),
    eventClick: this.handleEventClick.bind(this)
  };
  leaveAnalysis: any
  public chart: any;
  // @ViewChild(CalendarComponent) calendarComponent: CalendarComponent;
  ClientID = localStorage.getItem('client_id');
  CompanyID = localStorage.getItem('company_id');
  EmployeeID = localStorage.getItem('employee_id');
  selectedSession: string = '';  // Default to 'days'
  leave_day_type: string = '';
  headerdata = [];
  leaveTypeId: any;
  public pipe = new DatePipe('en-US');
  fromToDateError = false;
  no_of_days: any;
  public ShowForm: any = false;
  applyLeaveForm: FormGroup;
  leaveDayTypeDropDown1 = [];
  halfDayTypeDropDown = [];
  actionType = [];
  date_array = [];
  no_of_days_alter: any;
  no_of_days_filter: any;
  plan_type_name: any;
  leaveplantypeid: any;
  leave_plan_id: any;
  leave_plan_type_id: any;
  leaveApplyActionFlag: any;
  days: any;
  clickMonth:any;
  leaveDayTypeDropDown2: any;
  leaveDayTypeDropDown3: any;
  pendingDropDown: any;
  approveTypeDropDown: any;
  rejectTypeDropDown: any;
  leave_pending_days: any;
  leaveHistoryDropDown: any;
  initialized = false;
  leaveDayTypeDropDown: any;
  halfDayTypeDropDown1: any;
  halfDayTypeDropDown2: any;
  nextDayFormatted: string;
  secondLastDayFormatted: string;
  pending_leave_days: any;
  approved_leave_days: any;
  Rejected_leave_days: any;
  company_holiday: any;
  historydata: any;
  calendardata: any;
  isButtonDisabled: boolean = false;
  available_days: [];
  leave_day_name: [];
  company_holiday_data: any;
  searchKeyword: string = '';
  rotate = false;
  calendar_rotate = false;
  EmployeeLeaveId: any;
  reason_for_cancel: any;
  responsiveOptions: any[] | undefined;
  items: any = [];
  todayDate: any;
  nameBgColors: any = ["bg-blue-dim", "bg-blue-dim", "bg-azure-dim", "bg-indigo-dim", "bg-info-dim", "bg-purple-dim", "bg-pink-dim", "bg-orange-dim", "bg-teal-dim", "bg-primary-dim", "bg-success-dim", "bg-warning-dim", "bg-danger-dim", "bg-secondary-dim", "bg-dark-dim", "bg-gray-dim", "bg-blue-dim", "bg-azure-dim", "bg-indigo-dim", "bg-info-dim", "bg-purple-dim", "bg-pink-dim", "bg-orange-dim", "bg-teal-dim", "bg-primary-dim", "bg-success-dim", "bg-warning-dim", "bg-danger-dim", "bg-secondary-dim", "bg-dark-dim", "bg-gray-dim"];
  nameBorderColors: any = ["bg-outline-primary", "bg-outline-success", "bg-outline-info", "bg-outline-gray", "bg-outline-warning", "bg-outline-dark", "bg-outline-light", "bg-outline-secondary", "bg-outline-danger"];
  cardBgColors = [
    'rgba(253,126,20,.25)', 'rgba(241,0,117,.25)', 'rgba(0,204,204,.25)', 'rgb(18,182,89,.25)', 'rgba(91,71,251,.2)'
  ]
  leaveCardBgColors = [
    'rgba(255,255,255, 0.9)', 'rgba(255,255,255, 0.9)', 'rgba(255,255,255, 0.9)', 'rgba(255,255,255, 0.9)', 'rgba(172, 161, 167, 0.57) '
  ]
  cardBorderColors = [
    '25px 19px 27px -20px rgba(253,126,20,.25)', '25px 19px 27px -20px rgba(0,204,204,.25)', '25px 19px 27px -20px rgba(91,71,251,.2)', '25px 19px 27px -20px #fbfcd7', '25px 19px 27px -20px #d9b8f5'
  ]
  monthName: { full_name: string; short_name: string; }[];
  leaveDays: any[];
  showCalendar: any;
  clickDate: any;
  plan_type_key: any;
  consider_weekend_leaves: any;
  consider_holidays: any;
  filedata: any;
  selectedCalendarYear: number;
  selectedCalendarMonth: number;
  emittedValue: any;
  date: any;
  constructor(private Documentservice: documentservice,
    private spinnerService: SpinnerService, private ApiModuleService: ApiModulesService, private formBuilder: FormBuilder, private toastr: ToastrService, private AllModulesService: AllModulesService,private datePipe: DatePipe) {
    this.FromDateToDateValidation = this.FromDateToDateValidation.bind(this);
  }
  ngOnInit() {

    var today = new Date(),
      year = today.getFullYear(),
      month = today.getMonth(),
      day = today.getDate()
     this.dateModification(today,'todayDate');
      this.spinnerService.raiseDataEmitterEvent('on');

    this.getLeaveHome();

    this.responsiveOptions = [
      {
        breakpoint: '1199px',
        numVisible: 1,
        numScroll: 1
      },
      {
        breakpoint: '991px',
        numVisible: 2,
        numScroll: 1
      },
      {
        breakpoint: '767px',
        numVisible: 1,
        numScroll: 1
      }
    ];
    this.applyLeaveForm = this.formBuilder.group({
      employee_leave_id: [''],
      client_id: [''],
      company_id: [''],
      employee_id: [''],
      no_of_days: ['', [Validators.required]],
      leave_reason: ['', [Validators.required]],
      leave_plan_type_id: [''],
      from_date: ['', [Validators.required], this.FromDateToDateValidation],
      to_date: ['', [Validators.required], this.FromDateToDateValidation],
      // to_time: ['', []],
      // from_time: ['', []],
      alternative_contact_number: ['', []],
      attachment_url: ['', []],
      expected_date: ['', []],
      address_to_contact: ['', [Validators.pattern('[0-9]{10}')]],
      // selectedSession: [''],
      leave_day_type: [''],
      leaveDetails: this.formBuilder.array([])
    });

  }
  FromDateToDateValidation(control: FormControl): Promise<{ [key: string]: any } | null> {
    return new Promise((resolve) => {
      // Perform your validation logic asynchronously
      if ((control.value != null && control.value !== '') && this.consider_weekend_leaves == 'No') {
        const isHoliday = this.company_holiday.some((data: any) => control.value === data.holiday_date);
        const dayName = new Date(control.value);
        const filterByDayName = this.leave_day_name.some((data: any) => data.day_name == dayName.toLocaleDateString('en-US', { weekday: 'long' }))

        if (isHoliday || filterByDayName) {
          resolve({ fromDateToDate: true }); // Validation fails
        } else {
          resolve(null); // Validation passes
        }
      } else {
        resolve(null); // Handle empty/null values, validation passes
      }
    });
  }

  //clear leave history table
  clear(table: Table) {
    table.clear();
  }
  // Function to handle the input event for phone number field
  onPhoneNumberInput(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const phoneNumber = inputElement.value.replace(/\D/g, ''); // Remove all non-digit characters
    const truncatedPhoneNumber = phoneNumber.slice(0, 10); // Truncate to 10 digits if longer
    inputElement.value = truncatedPhoneNumber; // Update the input field with the cleaned phone number
    this.applyLeaveForm.get('address_to_contact').setValue(truncatedPhoneNumber); // Update the form control value
  }
  private updateCalendar() {
    if (this.calendarComponent) {
    const calendarApi = this.calendarComponent.getApi();
    calendarApi.gotoDate(new Date(2024, 5));
    }else{
    }
  }
  customEventContent(arg: any) {
    const eventStatus = arg.event.extendedProps.leave_status;
    const eventClass = eventStatus === 'Pending' ? 'pending-home-event' : eventStatus === 'Approved' ? 'approve-home-event' : 'holiday-home-event';

    const eventContent = document.createElement('div');
    eventContent.innerHTML = `${eventStatus}`;
    eventContent.className = eventClass;
    eventContent.style.border = '1px solid #fff';

    // Center-align the content
    eventContent.style.textAlign = 'center';
    return { domNodes: [eventContent] };
  }
  public getLeaveHome() {
    this.spinnerService.raiseDataEmitterEvent('on');
    var params: any = {};
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    params.employee_id = this.EmployeeID;

    this.ApiModuleService.list('employee-tabs/employee-hrm/leave-home/list', params).subscribe((data) => {
      this.ShowForm = false;
      this.headerdata = (data.data.leaves != undefined) ? data.data.leaves[0] : [];
      this.leave_day_name = (data.data.leaves != undefined) ? data.data.leaves[1] : [];
      this.company_holiday = (data.data.leaves != undefined) ? data.data.leaves[2] : [];
      this.leaveDayTypeDropDown = (data.data.leaves != undefined) ? data.data.leaves[3] : [];
      this.historydata = (data.data.leaves != undefined) ? data.data.leaves[4] : [];
      this.calendardata = (data.data.leaves != undefined) ? data.data.leaves[5] : [];

      this.resetForm();
      setTimeout(() => {
        this.leaveTypeId = this.headerdata[0].leave_plan_type_id;
        this.leavePlanFilter(this.headerdata[0].leave_plan_type_id);
        if (this.initialized == true) {
        } else {
          this.initialized = true;
        }
        this.calendarView();
        this.spinnerService.raiseDataEmitterEvent('off');
        if (history.state && history.state.emittedValue && history.state.date) {
          this.emittedValue = history.state.emittedValue;
          this.date = this.convertDate(history.state.date);
          this.openApplyLeavePopup(this.emittedValue);
              this.applyLeaveForm.patchValue({
                expected_date: this.date,
              });
          history.replaceState({ emittedValue: null, date: null }, '');
      }

      }, 100);
    }, (err) => {
      this.headerdata = [];
      this.leave_day_name = [];
      this.company_holiday = [];
      this.leaveDayTypeDropDown = [];
      this.historydata = [];
      this.calendardata = [];
      this.spinnerService.toasterError(err);
    });

  }
  openApplyLeavePopup(emittedValue: string) {
    // Logic to open the apply leave modal and display the emitted value
    ($('#add_leave') as any).modal('show');

    // Assuming you have a method to handle the emitted value and display it in the modal
    this.demo(emittedValue);
  }
  changeToSummary() {
    this.calendar_rotate = false;

  }

  handleEventClick(arg) {
    // Access the start date of the clicked event

    const startDate = this.pipe.transform(arg.event.start.toLocaleString(), 'yyyy-MM-dd');

      this.dateModification(startDate,'clickDate')
    var showData = this.calendardata.filter((val: any) => val.date == startDate)
    this.showCalendar = showData[0]
    this.calendar_rotate = true;

  }
  dateModification(value,name){
      const options: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'short', year: 'numeric' };
      const formattedDate = new Intl.DateTimeFormat('en-US', options).format(new Date(value));

      this[name] = formattedDate;
  }
  demo(leave_plan_type_id) {

    var filter_val = this.headerdata.filter((data: any) => data.leave_plan_type_id == leave_plan_type_id)[0]
    this.plan_type_name = filter_val.leave_plan_type_name
    // setTimeout(()=>{
    //   ($('#add_leave') as any).modal('show');

    // },1000)
    this.plan_type_key = filter_val.leave_key
    this.consider_weekend_leaves = filter_val.consider_weekend_leaves
    this.consider_holidays = filter_val.consider_weekend_leaves
    this.leaveplantypeid = leave_plan_type_id
    this.leave_plan_id = filter_val.leave_plan_id

    // Add more code to handle the clicked row here
  }


  fileEvent(e, index) {
    this.filedata = e.target.files[0];
  }

  resetForm() {
    this.itemsList.clear();
    this.date_array = [];
    this.applyLeaveForm.reset();
    this.no_of_days = ''
    this.no_of_days_alter = ''
    this.no_of_days_filter = ''
    this.reason_for_cancel = ''
  }
  showAddForm() {
    this.ShowForm = true;
    this.resetForm();
  }
  showList() {

    this.ShowForm = true;
  } showLists() {

    this.ShowForm = false;
  }
  applyLeave() {
    if (!this.applyLeaveForm.valid) {
      this.applyLeaveForm.markAllAsTouched();
      return;
    }
    const params: any = {};
    for (let i = 0; i < this.date_array.length; i++) {
      if (i === 0 && this.applyLeaveForm.get('leaveDetails').value[0]) {
        this.date_array[i].leave_day_type = this.applyLeaveForm.get('leaveDetails').value[0].leave_day_type;
        this.date_array[i].half_day_type = this.applyLeaveForm.get('leaveDetails').value[0].leave_day_type == 'full day' ? null : this.applyLeaveForm.get('leaveDetails').value[0].half_day_type;
        this.date_array[i].start_time = this.applyLeaveForm.get('leaveDetails').value[0].leave_day_type == 'full day' ? null : this.applyLeaveForm.get('leaveDetails').value[0].start_time;
        this.date_array[i].end_time = this.applyLeaveForm.get('leaveDetails').value[0].leave_day_type == 'full day' ? null : this.applyLeaveForm.get('leaveDetails').value[0].end_time;

      } if (i === (this.date_array.length - 1) && this.applyLeaveForm.get('leaveDetails').value[1]) {
        this.date_array[i].leave_day_type = this.applyLeaveForm.get('leaveDetails').value[1].leave_day_type;
        this.date_array[i].half_day_type = this.applyLeaveForm.get('leaveDetails').value[1].leave_day_type == 'full day' ? null : this.applyLeaveForm.get('leaveDetails').value[1].half_day_type;
        this.date_array[i].start_time = this.applyLeaveForm.get('leaveDetails').value[1].leave_day_type == 'full day' ? null : this.applyLeaveForm.get('leaveDetails').value[1].start_time;
        this.date_array[i].end_time = this.applyLeaveForm.get('leaveDetails').value[1].leave_day_type == 'full day' ? null : this.applyLeaveForm.get('leaveDetails').value[1].end_time;

      }

    }
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    params.employee_id = this.EmployeeID;
    params.leave_plan_type_id = this.leaveplantypeid;
    params.leave_plan_id = this.leave_plan_id
    params.no_of_days = this.no_of_days;
    params.attachment_url = this.filedata!=null && this.filedata!='' ?this.filedata.name : null;
    params.expected_date = this.applyLeaveForm.get('expected_date').value;
    params.leave_reason = this.applyLeaveForm.value.leave_reason;
    params.from_date = this.applyLeaveForm.value.from_date;
    params.to_date = this.applyLeaveForm.value.to_date;
    params.leave_details = this.date_array;
    // params.from_time = this.applyLeaveForm.value.from_time;
    // params.to_time = this.applyLeaveForm.value.to_time;
    params.alternative_contact_number = this.applyLeaveForm.value.alternative_contact_number;
    params.address_to_contact = this.applyLeaveForm.value.address_to_contact;
    const employee_leave_id = this.applyLeaveForm.value.employee_leave_id;

    this.ApiModuleService.list('employee-tabs/employee-hrm/leave-home/validate', params).subscribe((data) => {
      let promise = new Promise((resolve, reject) => {
        let url: any;
        if (this.filedata != null && this.filedata != '') {
          url = this.uploadDocument();
        } else {
          url = this.addLeave(params);
        }
        resolve(url);
        reject('err');
      });
      promise.then((result) => {
        params.attachment_url = result;

          if(this.filedata != null && this.filedata != ''){

          this.addLeave(params)

        }

      }, (err) => {
        this.spinnerService.toasterError(err);
      });

    }, (err: any) => {
      this.spinnerService.toasterError(err);
    });


  }


  addLeave(params) {
    this.ApiModuleService.list('employee-tabs/employee-hrm/leave-home/apply-leave', params).subscribe((data) => {
      ($('#add_leave') as any).modal('hide');
      this.applyLeaveForm.reset();
      this.date_array = [];
      this.getLeaveHome();
        this.filedata = null;
      this.toastr.success('leave Applied SuccessFully..!', 'Success')
    }, (err: any) => {
      this.spinnerService.toasterError(err);

    });
  }
  get itemsList(): FormArray {

    return this.applyLeaveForm.get('leaveDetails') as FormArray;

  }
  addItems(value: any) {
    this.itemsList.push(this.newItem(value));
  }
  calculateDiff() {

    if (this.applyLeaveForm.get('from_date').valid && this.applyLeaveForm.get('to_date').valid) {
      const dateFrom = new Date(this.applyLeaveForm.get('from_date').value);
      const toDate = new Date(this.applyLeaveForm.get('to_date').value);

      if ((Date.UTC(dateFrom.getFullYear(), dateFrom.getMonth(), dateFrom.getDate())
        <= Date.UTC(toDate.getFullYear(), toDate.getMonth(), toDate.getDate()))) {
        this.fromToDateError = false;
        this.itemsList.clear();
        this.date_array = [];
        // this.itemsList.reset([]);
        this.no_of_days = (Math.floor((Date.UTC(toDate.getFullYear(), toDate.getMonth(), toDate.getDate()) - Date.UTC(dateFrom.getFullYear(), dateFrom.getMonth(), dateFrom.getDate())) / (1000 * 60 * 60 * 24))) + 1;
        this.no_of_days_alter = this.no_of_days;
        // Calculate the next day from the "from_date"
        const nextDay = new Date(dateFrom);
        nextDay.setDate(nextDay.getDate() + 1);
        this.nextDayFormatted = nextDay.toISOString().split('T')[0];

        // Calculate the second last day from the "to_date"
        const secondLastDay = new Date(toDate);
        secondLastDay.setDate(secondLastDay.getDate() - 1);
        this.secondLastDayFormatted = secondLastDay.toISOString().split('T')[0];



        // }
        for (let i = 0; i < this.no_of_days_alter; i++) {
          const DateFT: any = new Date(this.pipe.transform(
            Date.UTC(dateFrom.getFullYear(), dateFrom.getMonth(), dateFrom.getDate() + i),
            'yyyy-MM-dd'
          ));
          const DateFTn: any = (this.pipe.transform(
            Date.UTC(dateFrom.getFullYear(), dateFrom.getMonth(), dateFrom.getDate() + i),
            'yyyy-MM-dd'
          ));
          const dayName = DateFT.toLocaleDateString('en-US', { weekday: 'long' });

          var filterByDayName = []
          var filterByDate = []
          if (this.consider_weekend_leaves == 'No') {
            filterByDayName = this.leave_day_name.filter((data: any) => data.day_name == dayName)

          } else {
            filterByDayName = []
          }
          if (this.consider_holidays == 'No') {
            filterByDate = this.company_holiday.filter((data: any) => this.pipe.transform(DateFT, 'yyyy-MM-dd') == data.holiday_date);

          } else {
            filterByDate = [];
          }
          if (filterByDayName.length == 0 && filterByDate.length == 0) {
            const value = {
              date: DateFTn,
              leave_day_type: "full day",
              half_day_type: '',
              start_time: null,
              end_time: null,

            };

            if (i === 0 || i === (this.no_of_days_alter - 1)) {
              this.addItems(value);
            }
            this.date_array.push(value);
            this.no_of_days = this.date_array.length
            this.no_of_days_filter = this.date_array.length
            this.applyLeaveForm.patchValue({
              no_of_days: this.date_array.length
            })
          }


        }
      }
      else {
        this.fromToDateError = true;
        this.no_of_days = '';
        this.itemsList.clear();
        this.date_array = [];
        this.applyLeaveForm.patchValue({
          no_of_days: ''
        });
      }
    }
  }

  changeDate(i: any) {
    let no_of_days = this.no_of_days_filter;
    let count = 0;
    no_of_days = this.applyLeaveForm.value.leaveDetails.length === 1 ? no_of_days - 1 : no_of_days - 2;
    for (let index = 0; index < this.applyLeaveForm.value.leaveDetails.length; index++) {
      count = this.applyLeaveForm.value.leaveDetails[index].leave_day_type == 'full day' ? count + 1 : count + 0.5;

      (this.applyLeaveForm.get('leaveDetails') as FormArray)
        .at(index) // Access the specific form group within the array
        .patchValue({
          half_day_type: this.applyLeaveForm.get('leaveDetails').value[index].leave_day_type == 'full day' ? null : this.applyLeaveForm.get('leaveDetails').value[index].half_day_type,
          start_time: this.applyLeaveForm.get('leaveDetails').value[index].leave_day_type == 'full day' ? null : this.applyLeaveForm.get('leaveDetails').value[index].start_time,
          end_time: this.applyLeaveForm.get('leaveDetails').value[index].leave_day_type == 'full day' ? null : this.applyLeaveForm.get('leaveDetails').value[index].end_time
        });
    }
    this.no_of_days = no_of_days + count
    this.applyLeaveForm.patchValue({ no_of_days: this.no_of_days });
  }

  newItem(value): FormGroup {
    return this.formBuilder.group({
      date: [value.date, [Validators.required]],
      leave_day_type: [value.leave_day_type, [Validators.required]],
      half_day_type: [value.half_day_type, []],
      start_time: [value.start_time, [this.startTimeValidator('end_time')]],
      end_time: [value.end_time, [this.endTimeValidator('start_time')]],
    });
  }

  endTimeValidator(controlName: string) {
    return (control: FormControl): { [key: string]: boolean } | null => {
      if (control.value != null && control.value !== ' ') {
        const addShiftForm = control.parent; // Get the form group

        const controlValue = control.value;
        const startControl = addShiftForm?.get(`${controlName}`);
        const startValue = startControl?.value;

        if (controlValue <= (startValue == undefined ? null : startValue)) {
          addShiftForm?.get(`${controlName}`).setErrors({ 'TimeInvalid': true })
          return { 'TimeInvalid': true };
        }
      }
      control.parent?.get(`${controlName}`).setErrors(null)
      return null; // Return null if validation passes
    };
  }
  startTimeValidator(controlName: string) {
    return (control: FormControl): { [key: string]: boolean } | null => {
      if (control.value != null && control.value !== ' ') {
        const addShiftForm = control.parent; // Get the form group
        const controlValue = control.value;
        const endControl = addShiftForm?.get(`${controlName}`);
        const endValue = endControl?.value;

        if (controlValue >= (endValue == undefined ? null : endValue)) {
          addShiftForm?.get(`${controlName}`).setErrors({ 'TimeInvalid': true })
          return { 'TimeInvalid': true };
        }


      }
      control.parent?.get(`${controlName}`).setErrors(null)
      return null; // Return null if validation passes
    };
  }


  downloadExcel() {
    this.spinnerService.raiseDataEmitterEvent('on');
    const params: any = {};
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    params.employee_id = this.EmployeeID;
    this.ApiModuleService.list('employee-tabs/employee-hrm/leave-home/download', params).subscribe((data) => {
      const fileDetails = data.data.leaves.file;
      this.Documentservice.DownloadExcel(fileDetails.name, fileDetails.excel)
      this.spinnerService.raiseDataEmitterEvent('off');
    }, (err: any) => {
      this.spinnerService.toasterError(err);

    });

  }

  calendarView() {
    this.calendarOptions.events = this.calendardata;
    this.monthName = [{ "full_name": "January", "short_name": "JAN" },
    { "full_name": "February", "short_name": "FEB" },
    { "full_name": "March", "short_name": "MAR" },
    { "full_name": "April", "short_name": "APR" },
    { "full_name": "May", "short_name": "MAY" },
    { "full_name": "June", "short_name": "JUN" },
    { "full_name": "July", "short_name": "JUL" },
    { "full_name": "August", "short_name": "AUG" },
    { "full_name": "September", "short_name": "SEP" },
    { "full_name": "October", "short_name": "OCT" },
    { "full_name": "November", "short_name": "NOV" },
    { "full_name": "December", "short_name": "DEC" }
    ];
    this.leaveDays = [];
    for (let i = 0; i <= 11; i++) {
      // Initialize leaveDays object if it's not already initialized
      if (!this.leaveDays[this.monthName[i].full_name]) {
        this.leaveDays[this.monthName[i].full_name] = [];
      }

      // Filter and map leave days from calanderData with 'Approved' leave status
      const approvedLeaveDays = this.calendardata
        .filter((data) => data.month_name === this.monthName[i].full_name && data.leave_status === 'Approved')
        .map((val) => { return { "date": val.ExtractedDay, 'isleave_taken': true } });

      // Filter and map company holidays
      const companyHolidays = this.company_holiday
        .filter((data) => data.month_name === this.monthName[i].full_name)
        .map((val) => { return { "date": val.ExtractedDay, 'isleave_taken': false } });

      // Concatenate approvedLeaveDays and companyHolidays arrays into leaveDays
      this.leaveDays[this.monthName[i].full_name] = this.leaveDays[this.monthName[i].full_name].concat(approvedLeaveDays, companyHolidays);

      // Sort leaveDays array by date
      this.leaveDays[this.monthName[i].full_name] = this.leaveDays[this.monthName[i].full_name].sort((a, b) => {
        return Number(a.date) - Number(b.date);
      });


    }
  }

  changeStatus(employee_leave_id: any, leave_plan_type_id: any) {
    const params: any = {};
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    params.employee_id = this.EmployeeID;
    params.employee_leave_id = employee_leave_id;
    params.leave_plan_type_id = leave_plan_type_id;
    params.reason_for_cancel = this.reason_for_cancel;
    this.ApiModuleService.edit(params, 'employee-tabs/employee-hrm/leave-home/cancel-leave').subscribe((data) => {
      this.getLeaveHome();
      ($('#cancel-status') as any).modal('hide');
      this.toastr.success('Leave canceled sucessfully...!', 'Success');
    }, (err) => {

      this.spinnerService.toasterError(err);

    }
    );
  }
  filterFun(arrayName, ArrayKey, arrayValue) {
    return this[arrayName].filter((data: any) => data[ArrayKey] === arrayValue);
  }
  callStatusModel(employee_leave_id: any, leave_plan_type_id: any) {
    this.EmployeeLeaveId = employee_leave_id
    this.leave_plan_type_id = leave_plan_type_id
  }
  getAcronym(str: any) {
    var matches = str.match(/\b(\w)/g); // ['J','S','O','N']
    matches = matches.slice(0, 2);
    var acronym = matches.join(''); // JSON
    return acronym;
  }
  getBgColors(str: any) {
    var min = 0;
    var max = 15;
    var index = this.findAlphapositions(str, 1);
    return this.nameBgColors[index];
  }
  findAlphapositions(str: any, n: any) {
    var NUM = 31;

    return str[0].charCodeAt(0) & NUM;
  }
  getSeverity(status: string) {
    switch (status) {
      case 'Approved':
        return 'success';
      case 'Pending':
        return 'warning';
      case 'Rejected':
        return 'danger';
      case 'Cancelled':
        return 'danger';
    }
  }
  rotateFunction() {
    this.rotate = !this.rotate;
  }
  leavePlanFilter(leave_plan_type_id) {
    this.leaveTypeId = leave_plan_type_id
    if (leave_plan_type_id != null && leave_plan_type_id != '') {
      this.leaveAnalysis = this.headerdata.filter((data: any) => data.leave_plan_type_id == leave_plan_type_id)[0];

      if (this.initialized == false) {
        const chartOptions: ChartOptions<'pie'> = {
          plugins: {
            legend: {
              labels: {
                color: 'black', // Font color of the legend labels
                boxWidth: 20,

                font: {
                  size: 15, // Font size of the legend labels
                  style: 'normal', // Font style of the legend labels
                  family: "Open_Sans", // Font family of the legend labels
                },
              },
              position: 'top', // Position of the legend
            },
          },
          scales: {
            x: {
              suggestedMax: 10,
            }
          },
          aspectRatio: 2,
          indexAxis: 'y',
          // Other chart options...
        };
        this.chart = new Chart("MyChartdays", {
          type: 'bar',
          data: {
            labels: [''],

            datasets: [
              {
                label: 'Eligible',
                backgroundColor: '#d8cffa',
                data: [this.leaveAnalysis.total_leave_days], // Data for January for each dataset
              },
              {
                label: 'Availed',
                backgroundColor: '#96bc93',
                data: [this.leaveAnalysis.taken_leave_days],
              },
              {
                label: 'Pending',
                backgroundColor: '#f9e4f4',
                data: [this.leaveAnalysis.leave_applied],
              },
              {
                label: 'Balance',
                backgroundColor: '#fae5d9',
                data: [this.leaveAnalysis.available_leaves],
              },


            ]
          },
          options: chartOptions
        });
      } else {
        setTimeout(() => {
          this.chart.data.datasets = [
            {
              label: 'Eligible',
              backgroundColor: '#eacbf7',
              data: [this.leaveAnalysis.total_leave_days], // Data for January for each dataset
            },
            {
              label: 'Availed',
              backgroundColor: '#c4f2e8',
              data: [this.leaveAnalysis.taken_leave_days],
            },
            {
              label: 'Pending',
              backgroundColor: '#f7f0d5',
              data: [this.leaveAnalysis.leave_applied],
            },
            {
              label: 'Balance',
              backgroundColor: '#fac6be',
              data: [this.leaveAnalysis.available_leaves],
            },


          ]
          this.chart.data.labels = [''];
          this.chart.update(); // Update the chart after modifying the dataset
        }, 100);
      }
    }
  }

  uploadDocument() {
      this.spinnerService.raiseDataEmitterEvent('on');
    const myFormData = new FormData();
    myFormData.append('attachment_url', this.filedata != undefined ? this.filedata : null);
    myFormData.append('client_id', this.ClientID);
    myFormData.append('company_id', this.CompanyID);
    myFormData.append('employee_id', this.EmployeeID);
    myFormData.append('leave_plan_id', this.leave_plan_id);
    myFormData.append('leave_plan_type_id', this.leaveplantypeid);
    myFormData.append('no_of_days', this.no_of_days);
    myFormData.append('from_date', this.applyLeaveForm.value.from_date);
    myFormData.append('to_date', this.applyLeaveForm.value.to_date);
    myFormData.append('expected_date', this.applyLeaveForm.value.expected_date);
    let promise = new Promise((resolve, reject) => {
      this.Documentservice.post('api/employee-tabs/employee-hrm/leave-home/attachment', myFormData).subscribe((res) => {

        resolve(res.data.leaves)
      }, (err: any) => {
        reject(err)
        this.spinnerService.toasterError(err);
      });
    })
    let data = promise.then((result) => {
      return result
    });
    return data;
  }
  convertDate(date: string): string | null {
    return this.datePipe.transform(date, 'yyyy-MM-dd');
  }
}


