import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { ApiModulesService } from '../../../../api-modules.service';

import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription, Subject, } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { SpinnerService } from 'src/app/snipper/spinner.service';
import { Table } from 'primeng/table';
import { EmployeeDropdownService } from "../../../../azatecon-services/employee-dropdown.service";
import { CalendarComponent } from "../../../../common/calendar/calendar.component";
import { DatePipe } from '@angular/common';
import Chart from 'chart.js/auto';
import { ChartOptions } from 'chart.js';

declare const $: any;
import { CalendarOptions } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import { documentservice } from 'src/app/all-modules/documents.sevice';

@Component({
    selector: 'app-leaveapprovel',
    templateUrl: './leaveapprovel.component.html',
    styleUrls: ['./leaveapprovel.component.css']
})
export class LeaveapprovelComponent implements OnInit, OnDestroy, AfterViewInit {
    // @ViewChild(CalendarComponent) calendarComponent: CalendarComponent;
    calendarOptions: CalendarOptions = {
        plugins: [dayGridPlugin],
        initialView: 'dayGridMonth',
        weekends: true,
        events: [],
        eventContent: this.customEventContent.bind(this),
        eventClick: this.handleEventClick.bind(this)
    };
    calendardata = [];
    public pipe = new DatePipe('en-US');
    public clientname = [];
    isDtInitialized = false;
    public totalRecords: any = 0;
    public ShowForm: any = false;
    public initialized = false;
    leaveApprovelData = [];
    totalLeaveApprovelData = [];
    delicate_status = '';
    totalLeaveGraph = [];
    employeeLeaveGraph: any;
    LeaveGraph = [];
    employeeLeaveData = [];
    leaveApprovelHistoryData = [];
    ShowDashBoard = true;
    leavePlanGraph: any;
    leave_plan_type_id: any;
    employee_id_graph: any;
    managerDelicateForm: FormGroup;
    ClientID = localStorage.getItem('client_id');
    CompanyID = localStorage.getItem('company_id');
    EmployeeID = localStorage.getItem('employee_id');
    nameBgColors: any = ['bg-blue-dim', 'bg-blue-dim', 'bg-azure-dim', 'bg-indigo-dim', 'bg-info-dim', 'bg-purple-dim', 'bg-pink-dim', 'bg-orange-dim', 'bg-teal-dim', 'bg-primary-dim', 'bg-success-dim', 'bg-warning-dim', 'bg-danger-dim', 'bg-secondary-dim', 'bg-dark-dim', 'bg-gray-dim', 'bg-blue-dim', 'bg-azure-dim', 'bg-indigo-dim', 'bg-info-dim', 'bg-purple-dim', 'bg-pink-dim', 'bg-orange-dim', 'bg-teal-dim', 'bg-primary-dim', 'bg-success-dim', 'bg-warning-dim', 'bg-danger-dim', 'bg-secondary-dim', 'bg-dark-dim', 'bg-gray-dim'];
    nameBorderColors: any = ['bg-outline-primary', 'bg-outline-success', 'bg-outline-info', 'bg-outline-gray', 'bg-outline-warning', 'bg-outline-dark', 'bg-outline-light', 'bg-outline-secondary', 'bg-outline-danger'];
    cardBgColors = [
        'rgb(247, 227, 215)', 'rgb(215, 230, 195)', 'rgb(227, 250, 242)', 'rgb(202, 225, 237)', 'rgb(236, 230, 247)'
    ]
    actionType = [];
    delegateEmpoyees = [];
    chooseButton: any;
    delicate_id: number;
    statusChangeData: any = {};
    leave_action_reason: any;
    statuses: { label: string; value: string; }[];
    leave_history_data: any;
    employeesHistoryData: any;
    manager_employee_history_data: any;
    NewEmployeeID: any;
    manager_calendar_show_data: any;
    FromDate: any;
    ToDate: any;
    Show: boolean;
    leave_employee: any;
    EmployeeName: any;
    EmployeeNo: any;
    leaveStatusDrop = []
    public chart: any;
    public chartLeavePlan: any;
    public leaveChart: any;
    choosePlanEmployees: any[];

    constructor(private Documentservice: documentservice, private apiModulesService: ApiModulesService, private toaster: ToastrService, private toastr: ToastrService, private formBuilder: FormBuilder, private spinnerService: SpinnerService, private filterServices: EmployeeDropdownService) {
    }

    ngOnInit(): void {
        this.spinnerService.raiseDataEmitterEvent('off');
        this.getLeaveApprovel();
        this.checkDelicateFunction();
        this.managerDelicateForm = this.formBuilder.group({
            manager_id: ["", [Validators.required]],
            delicate_id: ["", []],
            manager_no: ["", [Validators.required]],
            from_date: ["", [Validators.required]],
            to_date: ["", [Validators.required]],
            notifing_employee_id: ["", [Validators.required]]
        })
        this.statuses = [
            { label: 'Active', value: 'active' },
            { label: 'Inactive', value: 'inactive' },
        ];
        this.leaveStatusDrop = [
            { value: "Pending" },
            { value: "Approved" },
            { value: "Rejected" },
            { value: "Cancelled" },
        ];
    }
    showAddForm() {
        this.ShowForm = true;
    }

    // show() {
    //     this.Show = true;
    // }
    dashboardShow() {
        this.ShowDashBoard = true;
        this.getLeaveApprovel();

    }
    dashboardHide() {
        this.ShowDashBoard = false;

    }
    goBack() {
        this.ShowForm = false;
        this.ShowDashBoard = false;
        // this.Show = false;
    }

    ngOnDestroy(): void {
        // Do not forget to unsubscribe the event

    }
    employeeDelicate() {
        this.managerDelicateForm.reset();
        const params: any = {
            "client_id": this.ClientID,
            "company_id": this.CompanyID,
            "employee_id": this.EmployeeID,
        }
        this.apiModulesService.list('employee-tabs/employee-hrm/leave-delicate/edit', params).subscribe((data: any) => {

            if (data.data.leaves.length != 0) {
                const delicateValue = data.data.leaves[0]
                this.managerDelicateForm.setValue({
                    manager_id: delicateValue.delicate_employee_id,
                    manager_no: delicateValue.employee_no,
                    to_date: delicateValue.to_date,
                    from_date: delicateValue.from_date,
                    notifing_employee_id: (delicateValue.employee_notification).split(','),
                    delicate_id: delicateValue.delicate_id
                })
            }
            this.getDelegateEmployees();
        }, (err) => {

            this.spinnerService.toasterError(err);

        });
    }
    assingManager() {
        if (!this.managerDelicateForm.valid) {
            this.managerDelicateForm.markAllAsTouched();
            return;
          }
        const params: any = {
            "client_id": this.ClientID,
            "company_id": this.CompanyID,
            "employee_id": this.EmployeeID,
            "delegate_employee_id": this.managerDelicateForm.value.manager_id,
            "notification_employees": this.managerDelicateForm.value.notifing_employee_id.toString(),
            "from_date": this.managerDelicateForm.value.from_date,
            "to_date": this.managerDelicateForm.value.to_date,
        }
        const delicate_id = this.managerDelicateForm.value.delicate_id;
        if (delicate_id != null && delicate_id != '') {
            params.delegate_id = delicate_id;
            this.apiModulesService.list('employee-tabs/employee-hrm/leave-delicate/update', params).subscribe((data: any) => {

                this.checkDelicateFunction();
                ($('#delicate') as any).modal('hide');
                this.managerDelicateForm.reset();

            }, (err) => {

                this.spinnerService.toasterError(err);

            });
        } else {
            this.apiModulesService.list('employee-tabs/employee-hrm/leave-delicate/create', params).subscribe((data: any) => {

                this.checkDelicateFunction();
                ($('#delicate') as any).modal('hide');
                this.managerDelicateForm.reset();

            }, (err) => {

                this.spinnerService.toasterError(err);

            });
        }
    }
    customEventContent(arg) {
        // const eventStatus = arg.event.extendedProps.employee_name;
        // const eventClass = 'pending-event';
        // const randomColor = arg.event.extendedProps.bg_color;
        const eventStatus = arg.event.extendedProps.leave_status;
        const eventName = arg.event.extendedProps.employee_name;
        const eventClass = eventStatus === 'Pending' ? 'pending-home-event' : eventStatus === 'Approved' ? 'approve-home-event' : 'holiday-home-event';

        const eventContent = document.createElement('div');
        eventContent.innerHTML = `<i>${eventName}</i>`;
        eventContent.className = eventClass;
        eventContent.id = arg.event.extendedProps.employee_name;

        // Generate a random color from an array of predefined colors

        // Set the color style of the eventContent element

        return { domNodes: [eventContent] };
    }


    handleEventClick(arg) {
        // Access the start date of the clicked event
        const startDate = this.pipe.transform(arg.event.start.toLocaleString(), 'yyyy-MM-dd');
        var endDate = startDate;
        if (arg.event.end !== null) {
            endDate = this.pipe.transform(arg.event.end.toLocaleString(), 'yyyy-MM-dd');

        }
        else {


        }
        // Format the date for better readability (optional)
        // const formattedClickedDate = this.pipe.transform(clickedDate.toLocaleString(), 'yyyy-MM-dd'); // Modify the format as needed

        this.FromDate = startDate
        this.ToDate = endDate
        this.leave_employee = this.EmployeeID
        this.managerCalendarShow();
        ($('#show_team_leaves') as any).modal('show');


    }

    // Get leave approval data
    public getLeaveApprovel() {

        const params: any = {};
        params.client_id = this.ClientID;
        params.company_id = this.CompanyID;
        params.employee_id = this.EmployeeID;
        this.apiModulesService.list('employee-tabs/employee-hrm/leave-approval/list', params).subscribe((data) => {
            // (data);
            this.totalLeaveApprovelData = (data.data.employee_approval_leave[0] != undefined) ? data.data.employee_approval_leave[0] : [];
            this.delicate_status = (data.data.employee_approval_leave[1][0].delegate_status != undefined) ? data.data.employee_approval_leave[1][0].delegate_status : 'false';
            this.totalLeaveGraph = (data.data.employee_approval_leave[4] != undefined) ? data.data.employee_approval_leave[4] : [];
            this.calendardata = (data.data.employee_approval_leave[3] != undefined) ? data.data.employee_approval_leave[3] : [];
            this.leave_history_data = (data.data.employee_approval_leave[5] != undefined) ? data.data.employee_approval_leave[5] : [];
            this.employeesHistoryData = (data.data.employee_approval_leave[6] != undefined) ? data.data.employee_approval_leave[6] : [];
            if (this.totalLeaveApprovelData.length != 0) {
                if (this.delicate_status == 'false') {
                    this.leaveApprovelData = this.filterServices.singleFilterFunction(this.totalLeaveApprovelData, 'leave_status', 'Pending');

                }
                this.leaveApprovelHistoryData = this.filterServices.singleFilterFunction(this.totalLeaveApprovelData, 'delegate_status', 'no');
            }
            setTimeout(() => {
                this.calendarViews()

            }, 300)
            if (this.initialized == false) {
                this.leaveOverallGraph();
                //   this.ShowDashBoard=true;

            } else {

                setTimeout(() => {
                    this.leaveTotalGraph()

                }, 300)
            }
            this.actionType = data.data.employee_approval_leave[1] != undefined ? data.data.employee_approval_leave[1] : [];

        }, (err) => {
            this.leaveApprovelData = [];

            this.spinnerService.toasterError(err);

        });
    }

    clear(table: Table) {
        table.clear();
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.ShowDashBoard = false;

        }, 1000);
    }

    rerender() {

    }
    getDelegateEmployees() {
        const params: any = {
            "client_id": this.ClientID,
            "company_id": this.CompanyID,
            "employee_id": this.EmployeeID
        }
        this.apiModulesService.list('employee-tabs/employee-hrm/leave-delicate/dropDown', params).subscribe((data: any) => {
            this.delegateEmpoyees = data.data.leaves != undefined ? data.data.leaves : [];
        })
    }
    setManagerNo() {
        // managerDelicateForm.get('manager_id').value
        if (this.managerDelicateForm.get('manager_id').value != null && this.managerDelicateForm.get('manager_id').value != '')
            this.managerDelicateForm.patchValue({
                manager_no: this.delegateEmpoyees.filter((data: any) => data.employee_id == this.managerDelicateForm.get('manager_id').value)[0].employee_no
            })
    }
    checkDelicateFunction() {
        const params: any = {
            "client_id": this.ClientID,
            "company_id": this.CompanyID,
            "employee_id": this.EmployeeID
        }
        this.apiModulesService.list('employee-tabs/employee-hrm/leave-delicate/check', params).subscribe((data: any) => {
            this.delicate_id = data.data.leaves[0] != undefined ? data.data.leaves[0].delicate_id : 0
        })
    }
    removeDelicate() {
        const params: any = {
            "client_id": this.ClientID,
            "company_id": this.CompanyID,
            "employee_id": this.EmployeeID,
            "delegate_id": this.delicate_id,
        }
        this.apiModulesService.list('employee-tabs/employee-hrm/leave-delicate/remove', params).subscribe((data: any) => {

            this.checkDelicateFunction();
            ($('#reclaim_delicate') as any).modal('hide');

        }, (err) => {
            this.spinnerService.toasterError(err);

        });
    }

    changeChooseButton(actionFlag: any, leave: any) {
        this.statusChangeData = leave;
        this.chooseButton = actionFlag;
        this.leave_action_reason = '';
    }

    changeLeaveStatus() {
        const params: any = {};
        params.client_id = this.ClientID;
        params.company_id = this.CompanyID;
        params.employee_id = this.EmployeeID;
        params.action_flag = this.chooseButton;
        params.employee_leave_id = this.statusChangeData.employee_leave_id;
        params.leave_plan_type_id = this.statusChangeData.leave_plan_type_id;
        params.no_of_days = this.statusChangeData.no_of_days;
        params.request_employee_id = this.statusChangeData.employee_id;
        params.leave_action_reason = this.leave_action_reason;
        this.apiModulesService.edit(params, 'employee-tabs/employee-hrm/leave-approval/statusChange').subscribe((data) => {
            ($('#confirm_button_popup') as any).modal('hide');
            this.getLeaveApprovel();
        }, (err: any) => {
            this.spinnerService.toasterError(err);

        });
    }

    leaveHistory() {
        if (this.initialized == false) {

            this.initialized = true
            this.ShowDashBoard == false
        }
        this.employee_id_graph = this.choosePlanEmployees[0].employee_id;
        setTimeout(()=>{
            this.employeeGraphFilter(this.employee_id_graph);
        },100)
    }
    employeeFilterGraph() {
    
        this.choosePlanEmployees = this.filterServices.singleFilterFunction(this.employeesHistoryData, 'leave_plan_type_id', this.leave_plan_type_id);
        setTimeout(()=>{
            this.leaveHistory();
        },100);
    }
    view(list) {
        this.NewEmployeeID = list.employee_id
        this.EmployeeName = list.first_name
        this.EmployeeNo = list.employee_no
        this.managerEmployeeHistory();
    }

    calendarView(list) {
        this.FromDate = list.from_date
        this.ToDate = list.to_date
        this.leave_employee = this.EmployeeID
        this.managerCalendarShow();
    }
    employeeLeaveList(employee_id) {
        this.employeeLeaveData =this.totalLeaveApprovelData.filter((val:any)=> val.employee_id==employee_id && val.leave_status!='Cancelled' &&  val.leave_status!='Rejected' )
    }
    managerEmployeeHistory() {

        this.manager_employee_history_data = this.filterServices.singleFilterFunction(this.employeesHistoryData, 'employee_id', this.NewEmployeeID);

    }

    managerCalendarShow() {
        const params: any = {};
        params.client_id = this.ClientID;
        params.company_id = this.CompanyID;
        params.employee_id = this.leave_employee;
        params.from_date = this.FromDate;
        params.to_date = this.ToDate;
        this.apiModulesService.list('employee-tabs/employee-hrm/leave-approval/managercalendarshow', params).subscribe((data) => {
            this.manager_calendar_show_data = (data.data.employee_approval_leave != undefined) ? data.data.employee_approval_leave : [];
        }, (err) => {
            this.manager_calendar_show_data = [];
            this.spinnerService.toasterError(err);

        });
    }

    getAcronym(str: any) {
        if (str != null) {
            let matches = str.match(/\b(\w)/g); // ['J','S','O','N']
            matches = matches.slice(0, 2);
            const acronym = matches.join(''); // JSON
            return acronym;
        }
    }

    getBgColors(str: any) {
        if (str != null) {
            const min = 0;
            const max = 15;
            const index = this.findAlphapositions(str, 1);
            return this.nameBgColors[index];
        }
    }

    findAlphapositions(str: any, n: any) {
        const NUM = 31;

        return str[0].charCodeAt(0) & NUM;
    }

    calendarViews() {
        this.calendardata.forEach((val, index) => {
            const randomColor = this.cardBgColors[Math.floor(Math.random() * this.cardBgColors.length)];
            this.calendardata[index]['bg_color'] = randomColor
        })
        this.calendarOptions.events = this.calendardata;

    }

    getSeverity(status: string) {
        switch (status.toLowerCase()) {
            case 'pending':
                return 'leave-ap-pending';

            case 'approved':
                return 'leave-ap-approve';

            case 'rejected':
                return 'leave-ap-reject';

            case 'cancelled':
                return 'leave-ap-cancel';
        }
    }

    leaveTotalGraph() {
        this.LeaveGraph = this.totalLeaveGraph.filter((data: any) => data.is_graph == 1);
        this.chart.data.datasets[0].data = this.LeaveGraph.map((data: any) => data.total_leave_days);
        this.chart.data.datasets[1].data = this.LeaveGraph.map((data: any) => data.taken_leave_days);
        this.chart.data.datasets[2].data = this.LeaveGraph.map((data: any) => data.leave_applied);
        this.chart.data.datasets[3].data = this.LeaveGraph.map((data: any) => data.available_leaves);

        this.chart.update()
        this.leavePlanFilter(this.leave_plan_type_id)
    }

    leavePlanFilter(leave_plan_type_id) {
        if (leave_plan_type_id != null && leave_plan_type_id != '') {
            this.leavePlanGraph = this.totalLeaveGraph.filter((data: any) => data.leave_plan_type_id == leave_plan_type_id)[0];


            this.chartLeavePlan.data.datasets[0].data = [this.leavePlanGraph.taken_leave_days, this.leavePlanGraph.available_leaves, this.leavePlanGraph.leave_applied];
            this.chartLeavePlan.data.labels = ['Availed :' + this.leavePlanGraph.taken_leave_days, 'Balance :' + this.leavePlanGraph.available_leaves, 'Pending :' + this.leavePlanGraph.leave_applied];
            this.chartLeavePlan.update(); // Update the chart after modifying the dataset
                this.employeeFilterGraph();

        }
    }

    employeeGraphFilter(employee_id_graph) {
        if (employee_id_graph != null && employee_id_graph != '') {
            this.employeeLeaveGraph = this.filterServices.multipleFilterFunction(this.employeesHistoryData, 'employee_id', 'leave_plan_type_id', employee_id_graph, this.leave_plan_type_id)

           
            this.leaveChart.data.datasets = [
                { label: 'Eligible', data: [this.employeeLeaveGraph[0].total_leave_days], backgroundColor: '#d8cffa' },
                { label: 'Availed', data: [this.employeeLeaveGraph[0].taken_leave_days], backgroundColor: '#96bc93' },
                { label: 'Pending', data: [this.employeeLeaveGraph[0].leave_applied], backgroundColor: '#f9e4f4' },
                { label: 'Balance', data: [this.employeeLeaveGraph[0].available_leaves], backgroundColor: '#fae5d9' },
            ]
            this.leaveChart.update(); // Update the chart after modifying the dataset


        }
    }

    leaveOverallGraph() {
        // over all graph
        this.LeaveGraph = this.totalLeaveGraph.filter((data: any) => data.is_graph == 1);
        const chartAllOptions: ChartOptions<'bar'> = {
            plugins: {
                legend: {
                    labels: {
                        color: 'black', // Font color of the legend labels
                        boxWidth: 20,
                        font: {
                            size: 13, // Font size of the legend labels
                            style: 'normal', // Font style of the legend labels
                            family: 'Arial', // Font family of the legend labels
                        },
                    },
                    position: 'bottom', // Position of the legend
                },
            },
            scales: {
                x: {
                    suggestedMax: 10

                },
                y: {
                    grid: {
                        display: false, // Set to false to hide the vertical lines
                    },
                },

            },
            aspectRatio: 2,
            indexAxis: 'y',
            // Other chart options...
        };
        this.chart = new Chart("leaveCharts", {
            type: 'bar',
            data: {
                labels: this.LeaveGraph.map((data: any) => data.leave_plan_type_name),
                datasets: [
                    {
                        label: 'Eligible',
                        backgroundColor: '#d8cffa',
                        data: this.LeaveGraph.map((data: any) => data.total_leave_days), // Data for January for each dataset
                    },
                    {
                        label: 'Availed',
                        backgroundColor: '#96bc93',
                        data: this.LeaveGraph.map((data: any) => data.taken_leave_days),
                    },
                    {
                        label: 'Pending',
                        backgroundColor: '#f9e4f4',
                        data: this.LeaveGraph.map((data: any) => data.leave_applied),
                    },
                    {
                        label: 'Balance',
                        backgroundColor: '#fae5d9',
                        data: this.LeaveGraph.map((data: any) => data.available_leaves),
                    },


                ]
            },
            options: chartAllOptions
        });
        this.leave_plan_type_id = this.totalLeaveGraph[0].leave_plan_type_id;
        setTimeout(() => {

            this.leavePlanGraph = this.totalLeaveGraph.filter((data: any) => data.leave_plan_type_id == this.leave_plan_type_id)[0];

            //       leavePlan Type Graph
            const chartOptions: ChartOptions<'pie'> = {
                plugins: {
                    legend: {
                        labels: {
                            color: 'black',
                            boxWidth: 20, // Font color of the legend labels
                            font: {
                                size: 13, // Font size of the legend labels
                                style: 'normal', // Font style of the legend labels
                                family: 'Arial', // Font family of the legend labels
                            },
                        },
                        position: 'right', // Position of the legend
                    },
                },
                aspectRatio: 2,
                // Other chart options...
            };
            this.chartLeavePlan = new Chart("leavePlanChart", {
                type: 'pie',

                data: {
                    labels: ['Availed :' + this.leavePlanGraph.taken_leave_days, 'Balance :' + this.leavePlanGraph.available_leaves, 'Pending :' + this.leavePlanGraph.leave_applied],

                    datasets: [
                        {
                            data: [this.leavePlanGraph.taken_leave_days, this.leavePlanGraph.available_leaves, this.leavePlanGraph.leave_applied],
                            backgroundColor: ['#96bc93', '#fae5d9', '#f9e4f4']
                        }
                    ]
                },
                options: chartOptions
            });
        }, 100)
        // employee Leave Plan
        this.LeaveGraph = this.totalLeaveGraph.filter((data: any) => data.is_graph == 1);
        const chartEmployeeOptions: ChartOptions<'bar'> = {
            plugins: {
                legend: {
                    labels: {
                        color: 'black', // Font color of the legend labels
                        boxWidth: 20,
                        font: {
                            size: 13, // Font size of the legend labels
                            style: 'normal', // Font style of the legend labels
                            family: 'Arial', // Font family of the legend labels
                        },
                    },
                    position: 'bottom', // Position of the legend
                },
            },

            aspectRatio: 2,
            // Other chart options...
        };
        this.leaveChart = new Chart("employeeLeaveChart", {
            type: 'bar',
            data: {
                labels: ['Employee Leaves'], // Labels will be displayed on the x-axis
                datasets: [
                    {
                        label: 'Eligible',
                        data: [0],
                        backgroundColor: '#d8cffa'
                    },
                    {
                        label: 'Availed',
                        data: [0],
                        backgroundColor: '#96bc93'
                    },
                    {
                        label: 'Balance',
                        data: [0],
                        backgroundColor: '#fae5d9'
                    },
                    {
                        label: 'Pending',
                        data: [0],
                        backgroundColor: '#f9e4f4'
                    }
                ]
            },
            options: chartEmployeeOptions
        });
        this.employeeFilterGraph();
    }
    downloadExcel() {
        this.spinnerService.raiseDataEmitterEvent('on');
        const params: any = {};
        params.client_id = this.ClientID;
        params.company_id = this.CompanyID;
        params.employee_id = this.EmployeeID;
        this.apiModulesService.list('employee-tabs/employee-hrm/leave-approval/download', params).subscribe((data) => {
            const fileDetails = data.data.leaves.file;
            this.Documentservice.DownloadExcel(fileDetails.name, fileDetails.excel)
            this.spinnerService.raiseDataEmitterEvent('off');
        }, (err: any) => {
            this.spinnerService.toasterError(err);

        });

    }

  
}