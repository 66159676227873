// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.edit_button{
  position: relative;
  left: 85%;
}
.table>tbody>tr>td:last-child {
  position: relative;
   right: 0px;
}
`, "",{"version":3,"sources":["webpack://./src/app/all-modules/ess-tabs/IND-ess/IND-employee-payroll/provident-fund/pf-form11/pf-form11.component.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,SAAS;AACX;AACA;EACE,kBAAkB;GACjB,UAAU;AACb","sourcesContent":[".edit_button{\r\n  position: relative;\r\n  left: 85%;\r\n}\r\n.table>tbody>tr>td:last-child {\r\n  position: relative;\r\n   right: 0px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
