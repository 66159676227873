// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.col-md-2
{
  width: 10% !important;
}

.timeformatRadio
{
  margin-left:10px ;
}

.timeFormatButton
{
  margin-left:80px;
  margin-top: -23px;
}

.ml-2
{
  margin-left:5px ;
}


.add-button{
   position:relative;
   top:20px;
  width:40px;
  height:35px;
  color:#fff;
  border-radius: 50%;
  background: #14b349;
 }
.drop-button{
  border-radius: 50%;
  position:relative;
  width:40px;
  height:35px;
  top:20px;
  color:#fff;
  background: #ff4632;
}
.btn-primary {
  background-color: #fff;
  border: 1px solid #fff;
  box-shadow: inset 0.2em 0.2em 0.2em 0 rgba(255, 255, 255, 0.5),
              inset -0.2em -0.2em 0.2em 0 rgb(27 160 255 / 78%);
  color: black;
}
`, "",{"version":3,"sources":["webpack://./src/app/all-modules/company/shifts/shifts.component.css"],"names":[],"mappings":"AAAA;;EAEE,qBAAqB;AACvB;;AAEA;;EAEE,iBAAiB;AACnB;;AAEA;;EAEE,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;;EAEE,gBAAgB;AAClB;;;AAGA;GACG,iBAAiB;GACjB,QAAQ;EACT,UAAU;EACV,WAAW;EACX,UAAU;EACV,kBAAkB;EAClB,mBAAmB;CACpB;AACD;EACE,kBAAkB;EAClB,iBAAiB;EACjB,UAAU;EACV,WAAW;EACX,QAAQ;EACR,UAAU;EACV,mBAAmB;AACrB;AACA;EACE,sBAAsB;EACtB,sBAAsB;EACtB;+DAC6D;EAC7D,YAAY;AACd","sourcesContent":[".col-md-2\r\n{\r\n  width: 10% !important;\r\n}\r\n\r\n.timeformatRadio\r\n{\r\n  margin-left:10px ;\r\n}\r\n\r\n.timeFormatButton\r\n{\r\n  margin-left:80px;\r\n  margin-top: -23px;\r\n}\r\n\r\n.ml-2\r\n{\r\n  margin-left:5px ;\r\n}\r\n\r\n\r\n.add-button{\r\n   position:relative;\r\n   top:20px;\r\n  width:40px;\r\n  height:35px;\r\n  color:#fff;\r\n  border-radius: 50%;\r\n  background: #14b349;\r\n }\r\n.drop-button{\r\n  border-radius: 50%;\r\n  position:relative;\r\n  width:40px;\r\n  height:35px;\r\n  top:20px;\r\n  color:#fff;\r\n  background: #ff4632;\r\n}\r\n.btn-primary {\r\n  background-color: #fff;\r\n  border: 1px solid #fff;\r\n  box-shadow: inset 0.2em 0.2em 0.2em 0 rgba(255, 255, 255, 0.5),\r\n              inset -0.2em -0.2em 0.2em 0 rgb(27 160 255 / 78%);\r\n  color: black;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
