import { Component, OnInit, ViewChild } from '@angular/core';
import { SpinnerService } from 'src/app/snipper/spinner.service';
import { ApiModulesService } from 'src/app/all-modules/api-modules.service';
import { ToastrService } from 'ngx-toastr';
import { FileUpload } from 'primeng/fileupload';
import { documentservice } from 'src/app/all-modules/documents.sevice';

interface UploadEvent {
  originalEvent: Event;
  files: File[];
}
@Component({
  selector: 'app-payrollupload',
  templateUrl: './payrollupload.component.html',
  styleUrls: ['./payrollupload.component.css']
})

export class PayrolluploadComponent implements OnInit {
  @ViewChild('clientfileUpload', { static: false }) fileUpload: FileUpload;
  nameBgColors: any = ['bg-blue-dim', 'bg-blue-dim', 'bg-azure-dim', 'bg-indigo-dim', 'bg-info-dim', 'bg-purple-dim', 'bg-pink-dim', 'bg-orange-dim', 'bg-teal-dim', 'bg-primary-dim', 'bg-success-dim', 'bg-warning-dim', 'bg-danger-dim', 'bg-secondary-dim', 'bg-dark-dim', 'bg-gray-dim', 'bg-blue-dim', 'bg-azure-dim', 'bg-indigo-dim', 'bg-info-dim', 'bg-purple-dim', 'bg-pink-dim', 'bg-orange-dim', 'bg-teal-dim', 'bg-primary-dim', 'bg-success-dim', 'bg-warning-dim', 'bg-danger-dim', 'bg-secondary-dim', 'bg-dark-dim', 'bg-gray-dim'];
  nameBorderColors: any = ['bg-outline-primary', 'bg-outline-success', 'bg-outline-info', 'bg-outline-gray', 'bg-outline-warning', 'bg-outline-dark', 'bg-outline-light', 'bg-outline-secondary', 'bg-outline-danger'];
  financial_date_id: any;
  financialDropdownData: any;
  uploadFileData: any;
  fileData: any;
  uplodedFileName:any;
  showErrorForm = false;
  uploadedFiles:any = [];
  oldPath: any;
  data_import_id: any;
  oldFileName: any;
  file_name:any;
  statusApi: any;
  statusValues: any;
  statuses: { label: string; value: string; }[];
  fileName: string | Blob;
  ClientID: any;
  CompanyID: any;
  CompanyDropDown = [];
  ClientDropDown = [];
  constructor(private spinnerService: SpinnerService, private apiModulesService: ApiModulesService,
    private toastr: ToastrService, protected documentService: documentservice,) {
  }

  ngOnInit() {
    this.spinnerService.raiseDataEmitterEvent('on');
    this.GetClientDropDown();
    this.statuses = [
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ];
  }

  /* client DropDown codes*/
  public GetClientDropDown() {
    this.apiModulesService.get('DropDown/client').subscribe((data) => {
        this.ClientDropDown = data.data.client;
        this.ClientID = data.data.client[0].client_id;
        this.GetCompanyDropDown();
    },(err:any)=>{
      this.spinnerService.toasterError(err);
    })
}

/* company DropDown codes */
public GetCompanyDropDown() {
    const params: any = {};
    params.client_id = this.ClientID;
    this.apiModulesService.list('DropDown/company', params).subscribe((data) => {
            this.CompanyDropDown = data.data.company != undefined ? data.data.company : [];
            if (this.CompanyDropDown.length !== 0) {
                setTimeout(() => {
                    this.CompanyID = this.CompanyDropDown[0].company_id;
                    this.dropDown();
                }, 100)
            }
            else {
                this.CompanyID = null;
            }
    },(err:any)=>{
      this.spinnerService.toasterError(err);
    })
}
  dropDown() {
    this.spinnerService.raiseDataEmitterEvent('on');
    const params: any = {};
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;

    this.apiModulesService.list('employee-tabs/employee-hrm/payroll-draft/dropdown', params).subscribe((data) => {
      this.financialDropdownData = data.data.payroll_report;
      setTimeout(()=>{
        this.financial_date_id = data.data.payroll_report?.[0]?.financial_date_id ?? null;
        if (this.financial_date_id != null) {
          this.payrollUploadFileData();

        } else {
          this.spinnerService.raiseDataEmitterEvent('off');

        }
      },100)

    }, (err: any) => {
      this.spinnerService.toasterError(err);
    })

  }

  payrollUploadFileData() {
    this.spinnerService.raiseDataEmitterEvent('on');
    const params: any = {};
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    params.financial_date_id = this.financial_date_id;
    this.apiModulesService.list('employee-tabs/employee-hrm/upload-payroll/list', params).subscribe((data: any) => {
      this.uploadFileData = data.data.payroll_upload != undefined ? data.data.payroll_upload : []

      this.spinnerService.raiseDataEmitterEvent('off');
    }, (err: any) => {
      this.spinnerService.toasterError(err);
        });
  }

  onFileUpload() {
    if (!this.fileData) {
      this.toastr.error('Choose the File..?', 'Try Again');
      return;
    }
    if(this.ClientID ==null || this.CompanyID ==null ||this.financial_date_id ==null){
      this.toastr.error('Choose the Dropdowns..?', 'Try Again');

      return ;
    }
    if(this.ClientID !=null && this.CompanyID !=null && this.financial_date_id !=null){
    this.spinnerService.raiseDataEmitterEvent('on');
    const myFormData = new FormData();
    myFormData.append('file_path', this.fileData);
    myFormData.append('client_id', this.ClientID);
    myFormData.append('company_id', this.CompanyID);
    myFormData.append('import_status', 'approved');
    myFormData.append('financial_date_id', this.financial_date_id);
    const dataImportId = this.data_import_id != null ? this.data_import_id : '';
    myFormData.append('data_import_id', dataImportId);
    myFormData.append('file_name', this.uplodedFileName);
    this.documentService.post('api/employee-tabs/employee-hrm/upload-payroll/upload', myFormData).subscribe((data) => {
        this.spinnerService.raiseDataEmitterEvent('off');
        if (data.data.payroll_upload.is_error) {
          this.toastr.error('Something went wrong!', 'Try Again');
        } else {
          this.payrollUploadFileData();
          this.oldPath = null;
          this.showErrorForm = false;
          this.fileUpload.clear();
          this.toastr.success('File Uploaded successfully...!', 'Success');
        }
      },
      (err) => {
        this.spinnerService.toasterError(err);
      }

    );
    }
  }


  onCancel(event: any) {
    this.fileData = null;
  }
  onChoose(event: UploadEvent) {
    const filesData = event.files[0];
    this.fileData = filesData;
    this.uplodedFileName = filesData.name;

  }

  public DownloadFile(dataImportId) {
    const params: any = {};
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    params.data_import_id = dataImportId;
    this.apiModulesService.add(params, 'employee-tabs/employee-hrm/upload-payroll/download').subscribe(
      (data) => {
        // this.payrollUploadFileData();
        const docDetails = data.data.payroll_upload;
        this.documentService.DownloadExcel(docDetails.doc_name, docDetails.doc_url);
        this.toastr.success('File Downloaded Sucessfully...!', 'Success');
      },
      (err) => {
        this.spinnerService.toasterError(err);
      }
    );
  }

  getSeverity(status: string) {
    switch (status.toLowerCase()) {
      case 'inactive':
        return 'danger';

      case 'active':
        return 'success';
    }
  }
  getAcronym(str: any) {
    var matches = str.match(/\b(\w)/g); // ['J','S','O','N']
    matches = matches.slice(0, 2);
    var acronym = matches.join(''); // JSON
    return acronym
  }
  getBgColors(str: any) {
    var min = 0;
    var max = 15;
    var index = this.findAlphapositions(str, 1);
    return this.nameBgColors[index];
  }
  findAlphapositions(str: any, n: any) {
    var NUM = 31;

    return str[0].charCodeAt(0) & NUM;
  }


}
