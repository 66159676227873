// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media print {
    /* Hide elements with class "no-print" when printing */
    .no-print {
      display: none;
    }
  }
.span-number{
    border-bottom: dodgerblue 1px solid;
    cursor: pointer;
}
.calendar-width{
  width: 100%;
}
.review-section {
  margin-bottom: 16px !important;
}
`, "",{"version":3,"sources":["webpack://./src/app/all-modules/ess-tabs/IND-ess/IND-employee-payroll/salary-computation/salary-computation.component.css"],"names":[],"mappings":"AAAA;IACI,sDAAsD;IACtD;MACE,aAAa;IACf;EACF;AACF;IACI,mCAAmC;IACnC,eAAe;AACnB;AACA;EACE,WAAW;AACb;AACA;EACE,8BAA8B;AAChC","sourcesContent":["@media print {\r\n    /* Hide elements with class \"no-print\" when printing */\r\n    .no-print {\r\n      display: none;\r\n    }\r\n  }\r\n.span-number{\r\n    border-bottom: dodgerblue 1px solid;\r\n    cursor: pointer;\r\n}\r\n.calendar-width{\r\n  width: 100%;\r\n}\r\n.review-section {\r\n  margin-bottom: 16px !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
