import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Table } from 'primeng/table';
import { ApiModulesService } from 'src/app/all-modules/api-modules.service';
import { SpinnerService } from 'src/app/snipper/spinner.service';

@Component({
  selector: 'app-import-aza-validation',
  templateUrl: './import-aza-validation.component.html',
  styleUrls: ['./import-aza-validation.component.css']
})
export class ImportAzaValidationComponent {
  @Input() data_import_id: any;
  import_status: any;
  @Input() ClientID: any;
  @Input() CompanyID: any;
  @Input() ExcelTypeKey: any;
  @Input() ClientUserType: any;
  @Output() parenFun: EventEmitter<any> = new EventEmitter();
  showGrid = false;
  summaryMappeDHeaders: any;
  summaryData: any;
  sumHeaders: any;
  sumColumn: any;
  sumFilters: any;
  topHeaderFilter: any;
  sideHeaderFilter: any;
  importTypeID: any;
  importTypeData: any;
  summary_grid: any;
  FilterSummaryHeader: any[];
  summery_index: any;
  FilterSummaryData: any;
  validatorErrorData: any;
  showSumValButton: boolean;
  validation_detail_id: any;
  dataImportDetailId: any;
  FilterEditData: any;
  editResponseData: any;
  ClientUploadExcelData: any;
  displayForm = 'All';
  ErrorShow: any = 'All';
  selectedValidationData = [];
  ValidationStatusCount: any;
  searchKeyword: any;
  events: string[];
  status = 'Validated'
  eventStatus = 'azatecon';
  validateHistoryData: any;
  showhistory: any;
  constructor(protected apiModulesService: ApiModulesService,
    protected spinnerService: SpinnerService,
    protected toastrService: ToastrService,
  ) {
  }

  ngOnInit(): void {
    this.spinnerService.raiseDataEmitterEvent('on');
    this.showGrid = true;
    this.getDetails();
    this.events = [];
  }

  backToGrid() {
    this.showGrid = false;
    this.parenFun.emit(this.showGrid)


  }
  public getDetails() {

    const params: any = {};
    params.data_import_id = this.data_import_id;
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    params.event_status = this.eventStatus;
    this.apiModulesService.list('admin/import/validation/list', params).subscribe((data) => {
      this.spinnerService.raiseDataEmitterEvent('off');
      this.summaryData = data.data.dataDetails;
      this.sumHeaders = this.summaryData.headers;
      this.sumColumn = this.summaryData.column;
      this.sumFilters = this.summaryData.column;
      this.events = data.data.import_details;
      this.validateHistoryData = data.data.aza_client_history != undefined ? data.data.aza_client_history : [];
      this.getDatavalidation((data.data.validation != undefined) ? data.data.validation : []);
      this.summaryMappeDHeaders = this.summaryData.headers.map((val: any) => val.action_flag);
    }, (err) => {
      this.spinnerService.raiseDataEmitterEvent('off');

    }
    );
  }

  public FilterValidationDatas(topHeader: any, sideHeader: any) {
    this.topHeaderFilter = topHeader;
    this.sideHeaderFilter = sideHeader;
    if (sideHeader === 'Total') {
      sideHeader = '';
    }
    if (topHeader === 'Total' || topHeader === 'Actions') {
      topHeader = '';
    }
    const params: any = {};
    params.client_id = this.ClientID;
    params.data_import_id = this.data_import_id;
    params.company_id = this.CompanyID;
    params.action = sideHeader;
    params.header_name = topHeader;
    this.apiModulesService.list('admin/import/validation/summary/grid', params).subscribe((data) => {

      this.summary_grid = (data.data.filter_summary != undefined) ? data.data.filter_summary : [];
      this.importTypeData = (data.data.filter_summary[0] != undefined) ? data.data.filter_summary[0] : [];
      this.importTypeID = this.importTypeData[0].import_type_id;
      this.getSummaryBasedImportChoose(this.importTypeID);
      ($('#summary_filter_grid') as any).modal('show');
    }, (err) => {

      // this.toastrService.error('Something went wrong!', 'Try Again');
    }
    );

  }
  clear(table: Table) {
    table.clear();
  }
  getSummaryBasedImportChoose(importTypeId: any) {
    this.importTypeID = importTypeId;
    let Header = this.importTypeData.filter((data) => data.import_type_id === importTypeId).map((val) => val.column_list);
    this.FilterSummaryHeader = [];
    for (let i = 0; i < Header[0].length; i++) {
      let str = Header[0][i];
      this.FilterSummaryHeader[i] = str.replace(',', '')
    }
    this.summery_index = this.importTypeData.filter((x, index) => x.import_type_id === importTypeId).map((val, index) => val.index)[0];
    this.FilterSummaryData = this.summary_grid[this.summery_index];
  }
  public getDatavalidation(validationData) {

    this.validatorErrorData = validationData;
    const importstatus: any = this.events.filter((value: any) => value.active_status == 'true');
    this.import_status = importstatus[0].import_status_key != undefined ? importstatus[0].import_status_key : null;
    // if(this.ClientUserType=='aza_maker'){
    //     if (this.import_status=='Client_Input'|| this.import_status== 'Client_Validation') {
    //         this.showSumValButton = true;
    //     } else {
    //         this.showSumValButton = false;
    //     }
    // }else if(this.ClientUserType=='client_approve'){
    //     if (this.import_status=='Client_Approval') {
    //         this.showSumValButton = true;
    //     } else {
    //         this.showSumValButton = false;
    //     }
    // }
    if (['Pay_Run_Initiation', 'Client_Input', 'Client_Validation', 'Client_Approval', 'Aza_Validation'].includes(this.import_status)) {
      this.showSumValButton = true;
  }else{
    this.showSumValButton = false;

  }
  
  }

  public editValue(validationDetailId, dataImportDetailId, showType, import_type_id) {
    this.validation_detail_id = validationDetailId;
    this.dataImportDetailId = dataImportDetailId;

    const params: any = {};
    params.data_import_id = this.data_import_id;
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    params.data_import_detail_id = dataImportDetailId;
    params.import_type_id = import_type_id;

    this.apiModulesService.edit(params, 'admin/azatecon/maker/validation/edit').subscribe((data) => {
      const response: any = data.data.imports != undefined ? data.data.imports : [];
      this.FilterEditData = JSON.parse(JSON.stringify(response));
      this.editResponseData = JSON.parse(JSON.stringify(response));
      this.ClientUploadExcelData = JSON.parse(JSON.stringify(response));

      this.setDisplayForm(showType);
      this.FilterEditFormData(showType);
    });
  };

  public setDisplayForm(value: string) {
    this.displayForm = value;
  }
  FilterEditFormData(value: any) {

    if (value === 'true') {
      this.ErrorShow = 'true';
      this.ClientUploadExcelData = this.FilterEditData.filter((data: any) => data.data_status === 'true')
    }
    else if (value === 'false') {
      this.ErrorShow = 'false';
      this.ClientUploadExcelData = this.FilterEditData.filter((data: any) => data.data_status === 'false')
    } else {
      this.ErrorShow = 'All';
      this.ClientUploadExcelData = this.FilterEditData;
    }
  }
  public onUpdateValue(errorForm: NgForm) {
    const remarks = {};
    const current_values = {};
    const modified_values = {};
    const json_data_input = {};

    this.FilterEditData.forEach((errors, index: any) => {
      const fieldName = errors.field_name;
      const currentFieldValues = JSON.parse(JSON.stringify(errors.current_field_values)) ?? null;
      const remarksValues = errors.remarks_field_values ?? null;
      const fieldValues = errors.field_values ?? null;
      current_values[fieldName] = JSON.parse(JSON.stringify(currentFieldValues));
      remarks[fieldName] = remarksValues;
      json_data_input[fieldName] = fieldValues;

      for (let j = 0; j < errorForm.value.length; j++) {
        if (errorForm.value[j].hasOwnProperty(`${fieldName}[current_values]`)) {
          const currentFormValues = errorForm.value[j][`${fieldName}[current_values]`] ?? null;
          const remarksFormValues = errorForm.value[j][`${fieldName}[remarks]`] ?? null;
          current_values[fieldName] = JSON.parse(JSON.stringify(currentFormValues));
          remarks[fieldName] = remarksFormValues;
        }
      }
      if (fieldName == this.editResponseData[index].field_name) {
        if (current_values[fieldName] !== this.editResponseData[index].field_values) {
          modified_values[fieldName] = current_values[fieldName];
        }
      }

    });

    const params: any = {
      data_import_detail_id: this.dataImportDetailId,
      data_import_id: this.data_import_id,
      client_id: this.ClientID,
      company_id: this.CompanyID,
      remarks_field_values: remarks,
      modified_json_data: modified_values,
      current_json_data: current_values,
      event_status: this.eventStatus,
      json_data_input: json_data_input
    };
    this.apiModulesService.edit(params, 'admin/azatecon/maker/validation/update').subscribe((data) => {
      this.getDetails();
      ($('#edit_errorValidation') as any).modal('hide');
      this.ErrorresetForm(errorForm);
      this.toastrService.success('Edit error successfully!', 'Success');
    });
  }



  public ErrorresetForm(Error: NgForm) {
    Error.resetForm();
  }


  public openClientApprover() {
   if( !this.showSumValButton){
    return
   }
    this.selectedValidationData = this.validatorErrorData;
    let promise = new Promise((resolve, reject) => {
      // Replace with actual validation logic
      resolve(this.validateExcelData('status_update'));

    });
    promise.then((validationMessage) => {
      ($('#status_updater') as any).modal('show');

      // return this.statusUpdater(validationMessage);

    }).catch((error) => {
      this.toastrService.error('Something went wrong!', 'Try Again');
    });
  }

  public validateExcelData(action) {
    if (this.selectedValidationData.length == 0) {
      return
    }
    this.spinnerService.raiseDataEmitterEvent('on')
    const params: any = {};
    params.data_import_id = this.data_import_id;
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    params.validation_details_id = this.selectedValidationData.map((val: any) => val.validation_detail_id).toString();
    this.apiModulesService.list('admin/validation/template_1', params).subscribe((data) => {
      this.selectedValidationData = [];
      this.ValidationStatusCount = data.data;
      this.getDetails();
      this.spinnerService.raiseDataEmitterEvent('on')
      if (action == 'validate') {
        this.toastrService.success('validated SuccessFully', 'Success');
      }
    }, (err) => {

      this.toastrService.error('Something went wrong!', 'Try Again');
    }
    );
  }


  /* Download Excel File */

  DownloadExcel() {
    if (this.selectedValidationData.length == 0) {
      return
    }
    this.spinnerService.raiseDataEmitterEvent('on')
    const params: any = {};
    params.data_import_id = this.data_import_id;
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    params.validation_detail_id = this.selectedValidationData.map((val: any) => val.validation_detail_id).toString();
    this.apiModulesService.list('admin/excel-imports/excel-export/download', params).subscribe((data) => {
      const file_name = data.data.excelExport.file_name;
      const linkSource =
        'data:application/octet-stream;base64,' + data.data.excelExport.excel;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = file_name;
      downloadLink.click();
      this.selectedValidationData = [];
      this.getDetails();
      this.spinnerService.raiseDataEmitterEvent('off')

    }, (err: any) => {
      this.selectedValidationData = [];

      this.spinnerService.raiseDataEmitterEvent('off')
    });

  }

  statusUpdater(status_f_b) {
    this.spinnerService.raiseDataEmitterEvent('on')
    const params: any = {};
    params.data_import_id = this.data_import_id;
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    if (status_f_b == 'forward') {
      params.import_status_key = this.ClientUserType == 'aza_maker' ? 'Draft_Processing' : 'Draft_Processing';
    } else if (status_f_b == 'backward') {
      params.import_status_key = this.ClientUserType == 'aza_maker' ? 'Client_Validation' : 'Client_Validation';
    }
    this.apiModulesService.list('admin/excel-imports/database-insert', params).subscribe((data) => {
      params.validation_details_id = this.validatorErrorData.map((val: any) => val.validation_detail_id).toString();

      this.getDetails();
        ($('#status_updater') as any).modal('hide');
        this.spinnerService.raiseDataEmitterEvent('off')
    }, (err: any) => {
      this.selectedValidationData = [];

      this.spinnerService.raiseDataEmitterEvent('off')
    });

  }
  changeHistoryToSummary(event) {
    this.showhistory = event;
    this.getDetails();
  }

  openHistory() {
    this.showhistory = true;

  }
}
