import { Component, OnInit, ViewChild } from '@angular/core'
import { ApiModulesService } from '../../../api-modules.service';
import { NgForm, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { SpinnerService } from 'src/app/snipper/spinner.service';
import { documentservice } from 'src/app/all-modules/documents.sevice';
import { FileUpload } from 'primeng/fileupload';
interface UploadEvent {
    originalEvent: Event;
    files: File[];
}
@Component({
    selector: 'app-importdata-main',
    templateUrl: './importdata-main.component.html',
    styleUrls: ['./importdata-main.component.css']
})
export class ImportdataMainComponent implements OnInit {
    @ViewChild('fileUpload', { static: false }) fileUpload: FileUpload;

    DefaultImportData = [];
    display = false;
    public rows = [];
    public search = [];
    ImportTypeId: any;
    importTypeData: any;
    ClientDropDown: any;
    ClientID: any;
    CompanyDropDown: any;
    importCompanyHeaders: any;
    CompanyID: any;
    filesForm: FormGroup;
    droppedItems = [];
    DragDropView: any;
    htmlText: any;
    ExcellData: any;
    public UploadHeaderDatas = [];
    UploadHeaderError = true;
    newHeadersMap = true;
    clientImportData = [];
    tempImportData = [];
    filename = 'No Chosen File';
    fileData: any;
    multiCompany: any;
    uploadedFiles: any[] = [];
    isFilterClear: boolean;

    constructor(private apiModulesService: ApiModulesService, private toastrService: ToastrService,
        private formBuilder: FormBuilder, private spinnerService: SpinnerService, private documentService: documentservice) {
    }

    ngOnInit(): void {
        this.spinnerService.raiseDataEmitterEvent('on');
        this.getImport();

        this.filesForm = this.formBuilder.group({
            files: ['', [Validators.required]]
        })
    }
    onChoose(event: UploadEvent) {
        this.UploadHeaderDatas = [];
        const filesData = event.files[0];

        this.fileData = filesData;


        // this.messageService.add({severity: 'info', summary: 'File Uploaded', detail: ''});
    }
    // onChoose(event:any) {

    //     // this.messageService.add({severity: 'info', summary: 'File Uploaded', detail: ''});
    // }
    onCancel(event: any) {
        this.fileData = null;
        this.uploadedFiles = []
        this.filename = 'No Chosen File';
        this.UploadHeaderError = true;

        // this.messageService.add({severity: 'info', summary: 'File Uploaded', detail: ''});
    }

    getImport() {
        this.apiModulesService.get('DropDown/headers').subscribe((data) => {
            this.importTypeData = data.data.header;
            this.ImportTypeId = this.importTypeData[0].import_type_id;
            this.GetClientDropDown();
        }, (err) => {
            this.spinnerService.raiseDataEmitterEvent('off');
            let errors = err.error.data;
            errors = errors.replace('SQLSTATE[42000]: [Microsoft][ODBC Driver 17 for SQL Server][SQL Server]', 'Error: ')
            this.toastrService.error(errors, 'TryAgain');
        });
    }
    public GetClientDropDown() {
        this.apiModulesService.get('DropDown/client').subscribe((data) => {
            this.ClientDropDown = data.data.client;
            this.ClientID = data.data.client[0].client_id;
            this.GetCompanyDropDown();
        }, (err) => {
            this.spinnerService.raiseDataEmitterEvent('off');
            let errors = err.error.data;
            errors = errors.replace('SQLSTATE[42000]: [Microsoft][ODBC Driver 17 for SQL Server][SQL Server]', 'Error: ')
            this.toastrService.error(errors, 'TryAgain');
        });
    }
    /* company DropDown codes */
    public GetCompanyDropDown() {
        const params: any = {};
        params.client_id = this.ClientID;
        this.apiModulesService.list('DropDown/company', params).subscribe((data) => {
            this.CompanyDropDown = data.data.company != undefined ? data.data.company : [];
            if (this.CompanyDropDown.length !== 0) {
                this.CompanyID = this.CompanyDropDown[0].company_id;
                setTimeout(() => {

                    this.getDragDropData();
                }, 100)
            }
            else {
                this.CompanyID = null;
            }
        }, (err) => {
            this.spinnerService.raiseDataEmitterEvent('off');
            let errors = err.error.data;
            errors = errors.replace('SQLSTATE[42000]: [Microsoft][ODBC Driver 17 for SQL Server][SQL Server]', 'Error: ')
            this.toastrService.error(errors, 'TryAgain');
        });
    }
    filterCompany() {
        this.importCompanyHeaders = this.CompanyDropDown.filter((val: any) => val.company_id != this.CompanyID && val.headers_mapped != 1)
this.multiCompany=[];

    }

    public getDragDropData() {
        this.spinnerService.raiseDataEmitterEvent('on');
        if (this.CompanyID != null && this.CompanyID != '' && this.ClientID != '' && this.ClientID != null && this.ImportTypeId != null && this.ImportTypeId != '') {
            const params: any = {};
            params.import_type_id = this.ImportTypeId;
            params.client_id = this.ClientID;
            params.company_id = this.CompanyID;
            this.apiModulesService.list('admin/importdata/list', params).subscribe((data) => {
                this.DefaultImportData = data.data.import_fields.default_headers;
                this.clientImportData = data.data.import_fields.client_headers;
                this.tempImportData = data.data.import_fields.client_headers;
                this.newHeadersMap = data.data.import_fields.new_data;
                ($('#import_comp_headers') as any).modal('hide');
                this.spinnerService.raiseDataEmitterEvent('off');
            }, (err) => {
                this.spinnerService.raiseDataEmitterEvent('off');
                let errors = err.error.data.import_fields;
                errors = errors.replace('SQLSTATE[42000]: [Microsoft][ODBC Driver 17 for SQL Server][SQL Server]', 'Error: ')
                this.toastrService.error(errors, 'TryAgain');
            });
        } else {
            this.DefaultImportData = [];
            this.clientImportData = [];
            this.tempImportData = [];
            this.newHeadersMap = true
            this.spinnerService.raiseDataEmitterEvent('off');

        }


    }


    drop(event: CdkDragDrop<string[]>) {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
        }
    }

    public DefautHeadersImport() {
        const params: any = {};
        params.import_type_id = this.ImportTypeId;
        params.client_id = this.ClientID;
        params.company_id = this.CompanyID;

        this.apiModulesService.add(params, 'admin/importdata/map/default/headers').subscribe((data) => {
            this.resetForm();
            this.toastrService.success('File Imported  sucessfully...!', 'Success');
            this.getDragDropData()
        }, (err) => {
            this.spinnerService.raiseDataEmitterEvent('off');
            let errors = err.error.data.import_fields;
            errors = errors.replace('SQLSTATE[42000]: [Microsoft][ODBC Driver 17 for SQL Server][SQL Server]', 'Error: ')
            this.toastrService.error(errors, 'TryAgain');
        });
    }
    public updateMultiCompHeaders(overlayPanel: any) {
        overlayPanel.overlayVisible = false;
        const params: any = {};
        params.import_type_id = this.ImportTypeId;
        params.client_id = this.ClientID;
        params.company_id = this.CompanyID;
        params.multi_company_id = this.multiCompany;

        this.apiModulesService.add(params, 'admin/importdata/map/company/headers').subscribe((data) => {
            this.resetForm();
            this.toastrService.success('File Imported  sucessfully...!', 'Success');
            this.getDragDropData()
        }, (err) => {
            this.spinnerService.raiseDataEmitterEvent('off');
            let errors = err.error.data.import_fields;
            errors = errors.replace('SQLSTATE[42000]: [Microsoft][ODBC Driver 17 for SQL Server][SQL Server]', 'Error: ')
            this.toastrService.error(errors, 'TryAgain');
        });
    }





    onFileUpload() {
        if (this.fileData == null || this.fileData == '') {
            this.toastrService.error('Import the File..?', 'TryAgain');
            return;
        }

        this.spinnerService.raiseDataEmitterEvent('on');
        var myFormData = new FormData();
        myFormData.append('excel', this.fileData);
        myFormData.append('client_id', this.ClientID);
        myFormData.append('company_id', this.CompanyID);
        myFormData.append('import_type_id', this.ImportTypeId);
        this.documentService.post('api/admin/importdata/map/headers', myFormData).subscribe((data) => {
            this.resetForm();
            const client_headers = data.data.import_fields.client_headers;
            const default_headers = data.data.import_fields.default_headers;
            this.onMapData(client_headers, default_headers);
        }, (err) => {
            this.spinnerService.raiseDataEmitterEvent('off');
            let errors = err.error.data.import_fields;
            errors = errors.replace('SQLSTATE[42000]: [Microsoft][ODBC Driver 17 for SQL Server][SQL Server]', 'Error: ')
            this.toastrService.error(errors, 'TryAgain');
        });
    }

    onEdit(event: any, id, i) {
        const editedText = event.target.value;
        const data = this.clientImportData.filter(s => s.client_column_key_id === id);
        if (data[0].client_column_key !== editedText) {
            this.clientImportData[i].client_column_key = editedText;

        }
    }

    onMapData(clientImportData, DefaultImportData) {
        const importdata: any = [];
        let newClientData: any = {};
        newClientData.client_column_key = clientImportData[0].client_column_key;
        newClientData.client_column_key_id = clientImportData[0].client_column_key_id;
        newClientData.client_id = clientImportData[0].client_id;
        newClientData.company_id = this.CompanyID;
        newClientData.field_mapping_id = clientImportData[0].field_mapping_id;
        newClientData.import_type_id = clientImportData[0].import_type_id;
        newClientData.record_status = clientImportData[0].record_status;
        newClientData.column_key_id = DefaultImportData[0].column_key_id;
        newClientData.column_key = DefaultImportData[0].column_key;
        importdata.push(newClientData);
        this.DefaultImportData.reduce((accumulator, currentValue, currentIndex) => {
            newClientData = clientImportData.find((ele, index) => index === currentIndex);
            const admindata = DefaultImportData.find((ele, index) => index === currentIndex);
            newClientData.column_key_id = admindata.column_key_id;
            newClientData.column_key = admindata.column_key;
            importdata.push(newClientData);
        });
        const params: any = {};
        params.import_type_id = this.ImportTypeId;
        params.client_id = this.ClientID;
        params.company_id = this.CompanyID;
        params.map_import_datas = importdata;
        this.apiModulesService.edit(params, 'admin/importdata/mapupdate').subscribe((data) => {
            this.getDragDropData();
            this.toastrService.success('Excel Headers  Successfully...!', 'Success');
        }, (err) => {
            this.spinnerService.raiseDataEmitterEvent('off');
            let errors = err.error.data.import_fields;
            errors = errors.replace('SQLSTATE[42000]: [Microsoft][ODBC Driver 17 for SQL Server][SQL Server]', 'Error: ')
            this.toastrService.error(errors, 'TryAgain');
        });
    }

    onMapUpdateData() {

        const importdata: any = [];
        const missingIndex = this.clientImportData;
        let newClientData: any = {};
        newClientData.client_column_key = this.clientImportData[0].client_column_key;
        newClientData.client_column_key_id = this.clientImportData[0].client_column_key_id;
        newClientData.client_id = this.clientImportData[0].client_id;
        newClientData.company_id = this.CompanyID;
        newClientData.field_mapping_id = this.clientImportData[0].field_mapping_id;
        newClientData.import_type_id = this.clientImportData[0].import_type_id;
        newClientData.record_status = this.clientImportData[0].record_status;
        newClientData.column_key_id = this.DefaultImportData[0].column_key_id;
        newClientData.column_key = this.DefaultImportData[0].column_key;
        importdata.push(newClientData);
        this.DefaultImportData.reduce((accumulator, currentValue, currentIndex) => {
            newClientData = this.clientImportData.find((ele, index) => index === currentIndex);
            const admindata = this.DefaultImportData.find((ele, index) => index === currentIndex);
            newClientData.column_key_id = admindata.column_key_id;
            newClientData.column_key = admindata.column_key;
            importdata.push(newClientData);

        });

        let error = false;
        for (let i = 0; i < importdata.length; i++) {
            if (importdata[i].client_column_key === '' || importdata[i].client_column_key === null) {
                error = true;
                break;
            } else {
                error = false;
                continue;
            }
        }
        if (error === false) {
            const param: any = {};
            param.import_type_id = this.ImportTypeId;
            param.import_datas = importdata;
            param.client_id = this.ClientID;
            param.company_id = this.CompanyID;
            param.imported_headers = this.clientImportData
            this.apiModulesService.add(param, 'admin/importdata/map/add').subscribe((data) => {
                const client_headers = data.data.import_fields.client_headers;
                const default_headers = data.data.import_fields.default_headers;

                setTimeout(() => {
                    for (const key in missingIndex) {
                        client_headers[key].client_column_key = missingIndex[key].client_column_key;
                        client_headers[key].column_key_id = missingIndex[key].column_key_id;
                        client_headers[key].column_key = missingIndex[key].column_key;
                    }

                    this.onMapData(client_headers, default_headers);
                }, 100);
            }, (err) => {
                this.spinnerService.raiseDataEmitterEvent('off');
                let errors = err.error.data.import_fields;
                errors = errors.replace('SQLSTATE[42000]: [Microsoft][ODBC Driver 17 for SQL Server][SQL Server]', 'Error: ')
                this.toastrService.error(errors, 'TryAgain');
            });

        } else {
            this.toastrService.error('Please Enter Value For All Fields...!', 'Failed');
        }

    }
    resetForm() {
        this.filesForm.reset();
        this.fileData = null;
        this.uploadedFiles = [];
        this.fileUpload.clear();
        this.filename = 'No Chosen File';
    }
    cancel() {
        this.filesForm.reset();
        this.fileData = null;
        this.uploadedFiles = []
        this.filename = 'No Chosen File';
        this.UploadHeaderError = true;
    }

    clearFilter() {

        this.isFilterClear = false;

    }
}
