// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inactive {
	display: none !important;
}

a:hover,
a:focus-visible {
	cursor: pointer;
}

.modal-width{
    width: auto;
}`, "",{"version":3,"sources":["webpack://./src/app/all-modules/auth-tabs/menus/menus.component.css"],"names":[],"mappings":"AAAA;CACC,wBAAwB;AACzB;;AAEA;;CAEC,eAAe;AAChB;;AAEA;IACI,WAAW;AACf","sourcesContent":[".inactive {\r\n\tdisplay: none !important;\r\n}\r\n\r\na:hover,\r\na:focus-visible {\r\n\tcursor: pointer;\r\n}\r\n\r\n.modal-width{\r\n    width: auto;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
