// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fixed-row{
  position: sticky;
  left: 0px;
  background-color: #fbfbfb;
}
.fixed-row1{
  position: sticky;
  left: 10%;
  background-color: #fbfbfb;
}
`, "",{"version":3,"sources":["webpack://./src/app/all-modules/admin-tabs/payroll-admin-tabs/payroll-run/payroll-run.component.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,SAAS;EACT,yBAAyB;AAC3B;AACA;EACE,gBAAgB;EAChB,SAAS;EACT,yBAAyB;AAC3B","sourcesContent":[".fixed-row{\r\n  position: sticky;\r\n  left: 0px;\r\n  background-color: #fbfbfb;\r\n}\r\n.fixed-row1{\r\n  position: sticky;\r\n  left: 10%;\r\n  background-color: #fbfbfb;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
