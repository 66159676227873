import { Component, OnInit,ViewChild,OnDestroy } from '@angular/core';
import{ApiModulesService} from '../../../api-modules.service';
import{AllModulesService} from '../../../all-modules.service';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';

import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { filter } from 'rxjs/operators';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { SpinnerService } from 'src/app/snipper/spinner.service';
import { Table } from 'primeng/table';

@Component({
  selector: 'app-marital-status-main',
  templateUrl: './marital-status-main.component.html',
  styleUrls: ['./marital-status-main.component.css']
})
export class MaritalStatusMainComponent implements OnInit,OnDestroy {



  nameRegex=/^[a-zA-Z\s]*$/;
  public rows=[];
  public srch=[];
  public countryname=[];
  public maritalStatusData=[];
  isDtInitialized: boolean = false;
  public addStatusForm:FormGroup;
  public editStatusForm:FormGroup;

  page:any;
  searchCountry:any;
  public selected_country:any;
  invalidAddForm=false;
  invalidEditForm=false;
  searchKeyword: string = '';
  public totalRecords: any = 0;
public CountryName:any=1;
public FilterField:any;
  globalData: any;
  listHeaders=[];
  loading=false;
  nameBgColors:any = ['bg-blue-dim','bg-blue-dim','bg-azure-dim','bg-indigo-dim','bg-info-dim','bg-purple-dim','bg-pink-dim','bg-orange-dim','bg-teal-dim','bg-primary-dim','bg-success-dim','bg-warning-dim','bg-danger-dim','bg-secondary-dim','bg-dark-dim','bg-gray-dim','bg-blue-dim','bg-azure-dim','bg-indigo-dim','bg-info-dim','bg-purple-dim','bg-pink-dim','bg-orange-dim','bg-teal-dim','bg-primary-dim','bg-success-dim','bg-warning-dim','bg-danger-dim','bg-secondary-dim','bg-dark-dim','bg-gray-dim'];
  nameBorderColors:any = ['bg-outline-primary','bg-outline-success','bg-outline-info','bg-outline-gray','bg-outline-warning','bg-outline-dark','bg-outline-light','bg-outline-secondary','bg-outline-danger'];
  statuses: { label: string; value: string; }[];
  statusApi: any;
  statusValues: any;


  constructor
   (
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private apiModulesService:ApiModulesService,
    AllModulesService:AllModulesService,
    private  http: HttpClient,
    private spinnerService: SpinnerService,
   ){ }
  ngOnInit(): void
    {

      this.getCountriesName();
         // addForm Validations
         this.addStatusForm=this.formBuilder.group(
      {
         country_id: ['', [Validators.required]],
         marital_status_name: ['', [Validators.required,Validators.pattern(this.nameRegex)]],
         marital_status_code: ['', [Validators.required,Validators.pattern(this.nameRegex)]],
      });

      // editForm Validations
      this.editStatusForm=this.formBuilder.group(
      {
        country_id: ['', [Validators.required]],
        marital_status_name: ['', [Validators.required,Validators.pattern(this.nameRegex)]],
        marital_status_code:['',[Validators.required,Validators.pattern(this.nameRegex)]],
        marital_status_id:['',[]],
      });
      this.statuses = [
        { label: 'Active', value: 'active' },
        { label: 'Inactive', value: 'inactive' },
      ];
    }



    // manually rendering Data table

    rerender(): void {

    }


    public getStatusData() {
      this.spinnerService.raiseDataEmitterEvent('on');
      var params:any={};
      params.country_id=this.selected_country;
            this.apiModulesService.list('admin/marital-status/listStatus',params).subscribe((data) => {
                this.maritalStatusData = (data.data.marital_status != undefined) ? data.data.marital_status : [];
                this.loading = false;
                var totalRecords = (this.maritalStatusData[0] != undefined) ? this.maritalStatusData[0].total_records : 0;
                this.totalRecords = totalRecords;
                this.resetForm();
                setTimeout(() => {
                  this.spinnerService.raiseDataEmitterEvent('off');
              }, 100);
            }, (err) => {
              this.maritalStatusData = [];
              this.spinnerService.raiseDataEmitterEvent('off');
                // this.rerender();
            });
    }

    clear(table: Table) {
      table.clear();
    }

    getSeverity(status: string) {
      switch (status.toLowerCase()) {
        case 'inactive':
          return 'danger';

        case 'active':
          return 'success';
      }
    }


//resert after
  public resetForm()
    {
      this.addStatusForm.reset();
    }
//Add Status
  public onAddStatus()
    {
     if (this.addStatusForm.invalid)
      {
      this.addStatusForm.markAllAsTouched();
      this.invalidAddForm=true;
      return;
      }
      this.invalidAddForm=false;
        var params:any = {};
        params.marital_status_name = this.addStatusForm.get('marital_status_name').value;
        params.country_id = this.addStatusForm.get('country_id').value;
        params. marital_status_code = this.addStatusForm.get('marital_status_code').value;
        params.record_status=1;

      this.apiModulesService.add(params,'admin/marital-status/addStatus',).subscribe((data) =>
      {

        this.selected_country = this.addStatusForm.get('country_id').value;

        this.getStatusData();
        this.toastr.success('Marital Status Added Sucessfully...!', 'Success');
        this.addStatusForm.reset();
        ($('#add_status')as any).modal('hide');
    }, err => {
        if (err.status === 437) {
            this.toastr.error('Marital Status Exists...!', 'Failed');
        } else {
            this.toastr.error('Something Went Wrong Please Try Again...!', 'Error');
        }
    });

    }
  public getCountriesName()
    {
      this.apiModulesService.get('country/namelist').subscribe((data)=>
      {
        this.countryname =data;
        this.selected_country=this.countryname[0].country_id;

        this.getStatusData();
      });
    }


//edit value get
  public onEditStatus(marital_status_id: any)
    {
      var params:any = {};
      params.marital_status_id = marital_status_id;
      this.apiModulesService.edit(params,'admin/marital-status/editStatus').subscribe((data) =>
      {
        let statusDetail = data.data.marital_status;
        this. editStatusForm.setValue(
          {
            marital_status_name:statusDetail.marital_status_name,
            country_id:statusDetail.country_id,
            marital_status_code:statusDetail.marital_status_code,
            marital_status_id:statusDetail.marital_status_id,
          });
          let setCountry= this.countryname.filter((data:any)=>statusDetail.country_id === data.country_id);
      });

    }

//edit update
  public onEditChanges()
    {

     if (this.editStatusForm.invalid)
      {
      this.editStatusForm.markAllAsTouched();
      this.invalidEditForm = true;
      return;
      }
      this.invalidEditForm = false;
    var params:any={};

    params.marital_status_name=this.editStatusForm.get('marital_status_name').value;
    params.country_id = this.editStatusForm.get('country_id').value;
    params.marital_status_id=this.editStatusForm.get('marital_status_id').value;
    params.marital_status_code=this.editStatusForm.get('marital_status_code').value;
    this.apiModulesService.edit(params,'admin/marital-status/updateStatus').subscribe((data)=>
      {

        this.selected_country = this.editStatusForm.get('country_id').value;

        this.getStatusData();
        this.toastr.success('Marital Status Modified Sucessfully...!', 'Success');
        ($('#edit_status')as any).modal('hide');
    }, err => {
        if (err.status === 437) {
            this.toastr.error('Marital Status Already Exists...!', 'Failed');
        } else {
            this.toastr.error('Something Went Wrong Please Try Again...!', 'Error');
        }
    });
    }

//search
    searchBy(val) {
      this.rows.splice(0, this.rows.length);
      let temp = this.srch.filter(function (d) {
        val = val.toLowerCase();
        return d.marital_status_name.toLowerCase().indexOf(val) !== -1 ||d.marital_status_id.toLowerCase().indexOf(val) !== -1;
      });
      this.rows.push(...temp);
    }
// status change
  changeStatus(status:any,statusId:any)
    {
      var params:any = {};
        params.marital_status_id = statusId;
        params.record_status = status;
      this.apiModulesService.edit(params,'admin/marital-status/changeStatus').subscribe(() =>
      {

        this.getStatusData();
        ($('#marital-status') as any).modal('hide');
        this.toastr.success('Status changed successfully...!', 'Success');
      },(err)=>{
        this.toastr.error('Something went wrong!', 'Try Again');
      }
      );
    }
  //search by countries
  searchRegionName(val) {
    this.rows.splice(0, this.rows.length);
    let temp = this.srch.filter(function (d) {

      val = val.toLowerCase();
      return d.country_name.country_name.toLowerCase().indexOf(val) !== -1 || !val;

    });
    this.rows.push(...temp);
  }
  ngOnDestroy(): void {

  }
  ngAfterViewInit(): void {

  }
  getAcronym(str:any){
    var matches = str.match(/\b(\w)/g); // ['J','S','O','N']
    matches= matches.slice(0,2);
    var acronym = matches.join(''); // JSON
    return acronym;
}
getBgColors(str:any){
    var min = 0;
    var max = 15;
    var index = this.findAlphapositions(str,1);
    return this.nameBgColors[index];
}
findAlphapositions(str:any, n:any)
{
    var NUM = 31;

    return str[0].charCodeAt(0) & NUM;
}
callStatusModel(url_name:any,data:any){
  this.statusApi=url_name;
  this.statusValues=data;
}

}




