import {
  Component, ViewChild, AfterViewInit, OnInit, Input,
  Output, EventEmitter
} from '@angular/core';
import { ApiModulesService } from '../../api-modules.service';
import { SpinnerService } from 'src/app/snipper/spinner.service';
import { FormGroup, FormBuilder, Validators, } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { Subject, config } from "rxjs";
import { Filler } from 'chart.js';


@Component({
  selector: 'app-companyformulabuilder',
  templateUrl: './companyformulabuilder.component.html',
  styleUrls: ['./companyformulabuilder.component.css']
})
export class CompanyformulabuilderComponent implements OnInit {
  @Output() parenFun: EventEmitter<any> = new EventEmitter();

  @Input() ClientID: any;
  @Input() CompanyID: any;
  @Input() userKey: any;
  @Input() PayGroupID: any;
  @Input() ComponentID: any;
  @Input() CountryID: any;
  showParent = true;
  payComponentFormulaDetailsId: any;
  companyformulaData: any;
  public totalRecords: any = 0;

  nameBgColors: any = ["bg-blue-dim", "bg-blue-dim", "bg-azure-dim", "bg-indigo-dim", "bg-info-dim", "bg-purple-dim", "bg-pink-dim", "bg-orange-dim", "bg-teal-dim", "bg-primary-dim", "bg-success-dim", "bg-warning-dim", "bg-danger-dim", "bg-secondary-dim", "bg-dark-dim", "bg-gray-dim", "bg-blue-dim", "bg-azure-dim", "bg-indigo-dim", "bg-info-dim", "bg-purple-dim", "bg-pink-dim", "bg-orange-dim", "bg-teal-dim", "bg-primary-dim", "bg-success-dim", "bg-warning-dim", "bg-danger-dim", "bg-secondary-dim", "bg-dark-dim", "bg-gray-dim"];
  nameBorderColors: any = ["bg-outline-primary", "bg-outline-success", "bg-outline-info", "bg-outline-gray", "bg-outline-warning", "bg-outline-dark", "bg-outline-light", "bg-outline-secondary", "bg-outline-danger"];
  searchKeyword: string = '';
  searchKeywords: string = '';
  VariableData: any;
  ComponentList: any;
  filterData: any;
  formulaExpressionName: any[] = [];
  formulaExpression: any[] = [];
  formulaCondition: any[] = [];
  formulaConditionName: any[] = [];
  SelectDataName: any;
  Reference_Type: string;
  Reference_Type_ID: any;
  public referenceData: any[] = [];
  formulaDropDown: any;
  FormulaType: any;
  paycomponentdropdownKey: any;
  paycomponentValue: any;
  formulaTextBox: any = "formula";
  slabdropDown: any;
  filterComponentsCheck = [];
  arithmeticConditionPattern = /^[0-9+\-*/=()%><&|[\]{}]+$/;
  arithmeticPattern = /^[0-9+\-*/=()%[\]{}]+$/;

  constructor(private apiModulesService: ApiModulesService, private toastrService: ToastrService, private formBuilder: FormBuilder, private spinnerService: SpinnerService) { }

  ngOnInit(): void {
    this.showParent = false
    this.GetSelectName();
  }

  /* client DropDown codes*/
  public GetSelectName() {
    var params: any = {};
    params.component_id = this.ComponentID;
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    params.pay_group_id = this.PayGroupID;
    params.users_key = this.userKey;
    this.apiModulesService.list('admin/companyformula/selectname', params).subscribe((data) => {
      this.SelectDataName = data.data.company_formula[0] != undefined ? data.data.company_formula[0] : {};
      this.getCompanyformula();
      // this.getPaycomponentformula();
    });
  }

  //Get all companyformula data
  public getCompanyformula() {
    this.spinnerService.raiseDataEmitterEvent('off');
    var params: any = {};
    params.country_id = this.CountryID;
    params.component_id = this.ComponentID;
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    params.pay_group_id = this.PayGroupID;
    params.users_key = this.userKey;
    params.formula_type = this.FormulaType;
    this.apiModulesService.list("admin/companyformula/list", params).subscribe((data) => {
      this.companyformulaData = (data.data.company_formula[1] != undefined) ? data.data.company_formula[1] : [];
      this.VariableData = (data.data.company_formula[0] != undefined) ? data.data.company_formula[0] : [];
      this.filterComponentsCheck = data.data.company_formula[2] != undefined ? data.data.company_formula[2] : [];

      var totalRecords = this.companyformulaData.length;
      this.totalRecords = totalRecords;
      this.filterComponents();
      this.Paycomponentdropdown();
      (err: any) => {
        this.spinnerService.toasterError(err);
        this.spinnerService.raiseDataEmitterEvent('off');
      };
    });
  }
  public Paycomponentdropdown() {
    this.spinnerService.raiseDataEmitterEvent('off');
    var params: any = {};
    params.component_id = this.ComponentID;
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    params.pay_group_id = this.PayGroupID;
    params.users_key = this.userKey;

    this.apiModulesService.list("admin/companyformula/paydropdown", params).subscribe(
      (data) => {
        this.paycomponentdropdownKey = data.data.paycomponent_formula[0] || [];
        this.paycomponentValue = data.data.paycomponent_formula[1] || [];
        this.Reference_Type = this.paycomponentdropdownKey[0].reference_key != undefined ? this.paycomponentdropdownKey[0].reference_key : null;
        // Calculate total records if returned by the server, otherwise, use the length of the data
        this.totalRecords = data.data.totalRecords || this.paycomponentdropdownKey.length;
        this.referenceFilter()
        this.spinnerService.raiseDataEmitterEvent('off');
      },
      (err: any) => {
        this.paycomponentdropdownKey = [];
        this.spinnerService.toasterError(err);
        this.spinnerService.raiseDataEmitterEvent('off');
      }
    );
  }


  appendVarOrComp(name: string, key: string) {

    if (this.formulaTextBox == 'formula') {
      this.formulaExpression = this.formulaExpression || [];
      this.formulaExpressionName = this.formulaExpressionName || [];
      this.formulaExpression.push(key);
      this.formulaExpressionName.push(name);
    }

    if (this.formulaTextBox == 'condition') {
      this.formulaCondition = this.formulaCondition || [];
      this.formulaConditionName = this.formulaConditionName || [];
      this.formulaCondition.push(key);
      this.formulaConditionName.push(name);
    }

    this.refreshBuilder();
  }


  appendData(data: string) {

    this.onChipAdd(data, this.formulaTextBox, 'append_data')
  }

  clears() {
    if (this.formulaTextBox == 'formula') {
      this.formulaExpression = [];
      this.formulaExpressionName = [];
    } else if (this.formulaTextBox == 'condition') {
      this.formulaCondition = [];
      this.formulaConditionName = [];
    }
  }

  refreshBuilder() {
    this.formulaExpressionName = [...this.formulaExpressionName];
    this.formulaConditionName = [...this.formulaConditionName];
    this.formulaExpression = [...this.formulaExpression];
    this.formulaCondition = [...this.formulaCondition];
  }

  onChipAdd(newValue, fromTab, fromFun) {
    this.formulaExpression = this.formulaExpression || [];
    this.formulaExpressionName = this.formulaExpressionName || [];
    this.formulaCondition = this.formulaCondition || [];
    this.formulaConditionName = this.formulaConditionName || [];
    const filter_data = this.filterComponentsCheck.filter((val: any) => val.lable == newValue);
    if (fromTab == 'formula') {
      if (filter_data.length != 0) {
        this.formulaExpression.push(filter_data[0].key);
      } else if (this.arithmeticPattern.test(newValue)) {
        this.formulaExpression.push(newValue);
        if (fromFun == 'append_data') {
          this.formulaExpressionName.push(newValue);

        }

      } else {
        this.formulaExpressionName.pop();
      }
    }
    else if (fromTab == 'condition') {
      if (filter_data.length != 0) {
        this.formulaCondition.push(filter_data[0].key);

      } else if (this.arithmeticConditionPattern.test(newValue)) {
        this.formulaCondition.push(newValue);
        if (fromFun == 'append_data') {
          this.formulaConditionName.push(newValue);

        }

      } else {
        this.formulaConditionName.pop();
      }
    }
    this.refreshBuilder();

  }
  callChip(event: any, type) {
    this.formulaTextBox = type
    const trimData = event.value.trim()
    this.onChipAdd(trimData, type, 'callChip')
  }

  RemoveChip(formulatype){
    if(formulatype=='formula'){
      const FormulaExperation=[];
      this.formulaExpressionName.forEach((value,index)=>{
        const filter_data = this.filterComponentsCheck.filter((val: any) => val.lable == value);
        if(filter_data.length>0){
          FormulaExperation[index]= filter_data[0].key
        }else{
          FormulaExperation[index]= value
        }

      })
      this.formulaExpression=[...FormulaExperation]

    }else if(formulatype=='condition'){
      const conditionExperation=[];
      this.formulaConditionName.forEach((value,index)=>{
        const filter_data = this.filterComponentsCheck.filter((val: any) => val.lable == value);
        if(filter_data.length>0){
          conditionExperation[index]= filter_data[0].key
        }else{
          conditionExperation[index]= value
        }

      })
      this.formulaCondition=[...conditionExperation]
    }
  }





  paycomponentEdit() {
    if (this.payComponentFormulaDetailsId == 0 || !this.payComponentFormulaDetailsId) {
      this.formulaExpression = [];
      this.formulaExpressionName = [];
      this.formulaCondition = [];
      this.formulaConditionName = [];
      return;
    }
    this.spinnerService.raiseDataEmitterEvent('off');
    let params = {
      component_id: this.ComponentID,
      client_id: this.ClientID,
      company_id: this.CompanyID,
      pay_group_id: this.PayGroupID,
      users_key: this.userKey,
      reference_type: this.Reference_Type,
      reference_type_id: this.Reference_Type_ID,
      pay_component_formula_details_id: this.payComponentFormulaDetailsId,
    };

    this.apiModulesService.edit(params, "admin/companyformula/edit").subscribe((data) => {
      this.formulaExpression = data.data.paycomponent_formula.length != 0 ? data.data.paycomponent_formula[0].formula_expression : [];
      this.formulaExpressionName = data.data.paycomponent_formula.length != 0 ? data.data.paycomponent_formula[0].formula_expression_name : [];
      this.formulaCondition = data.data.paycomponent_formula.length != 0 ? data.data.paycomponent_formula[0].formula_condition : [];
      this.formulaConditionName = data.data.paycomponent_formula.length != 0 ? data.data.paycomponent_formula[0].formula_condition_name : [];
    });
  }

  insertFormula() {
    var params: any = {}
    params.component_id = this.ComponentID;
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    params.pay_group_id = this.PayGroupID;
    params.users_key = this.userKey;
    params.formula_type = this.FormulaType;
    params.pay_component_formula_details_id = this.payComponentFormulaDetailsId == 0 ? null : this.payComponentFormulaDetailsId;
    params.reference_type = this.Reference_Type;
    params.reference_type_id = this.Reference_Type_ID;
    params.formula_expression = this.formulaExpression;
    params.formula_expression_name = this.formulaExpressionName;
    params.formula_condition = this.formulaCondition;
    params.formula_condition_name = this.formulaConditionName;

    this.apiModulesService.list("admin/companyformula/insertformula", params).subscribe((data) => {
      this.getCompanyformula();
      this.toastrService.success("Formula added successfully!", "Success");
    },
      (err) => {
        this.spinnerService.toasterError(err);
      });
  }


  referenceFilter() {

    this.referenceData = this.paycomponentValue.filter((val: any) =>
      val.reference_type == this.Reference_Type && val.is_applicable == 1
    );
    this.Reference_Type_ID = this.referenceData[0].reference_type_id

    setTimeout(() => {
      this.formulaFilter();
    }, 10)

  }

  formulaFilter() {
    if (!this.Reference_Type_ID || !this.Reference_Type) {
      return;
    }

    const formulaData = this.referenceData.filter((val: any) => {
      return (
        val.reference_type_id === this.Reference_Type_ID &&
        val.reference_type && val.reference_type.toLowerCase() === this.Reference_Type.toLowerCase()
      );
    });
    this.FormulaType = formulaData.length > 0 ? formulaData[0].formula_type : null;


    this.GetSlapDropDown();
  }





  public getReferenceFormula() {
    this.spinnerService.raiseDataEmitterEvent('off');
    var params: any = {};
    params.component_id = this.ComponentID;
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    params.pay_group_id = this.PayGroupID;
    params.users_key = this.userKey;
    params.reference_type = this.Reference_Type;
    params.reference_type_id = this.Reference_Type_ID;
    params.formula_type = this.FormulaType;
    this.apiModulesService.list("admin/companyformula/reference-formula", params).subscribe((data) => {
      this.slabdropDown = (data.data.company_formula != undefined) ? data.data.company_formula : [];
      this.formulaExpression = this.slabdropDown.length != 0 ? this.slabdropDown[0].formula_expression : [];
      this.formulaExpressionName = this.slabdropDown.length != 0 ? this.slabdropDown[0].formula_expression_name : [];
      this.formulaCondition = this.slabdropDown.length != 0 ? this.slabdropDown[0].formula_condition : [];
      this.formulaConditionName = this.slabdropDown.length != 0 ? this.slabdropDown[0].formula_condition_name : [];
      this.payComponentFormulaDetailsId = this.slabdropDown.length != 0 ? this.slabdropDown[0].pay_component_formula_details_id : null;

      var totalRecords = this.companyformulaData.length;
      this.totalRecords = totalRecords;

      (err: any) => {
        this.spinnerService.toasterError(err);
        this.spinnerService.raiseDataEmitterEvent('off');
      }
    });
  }


  filterComponents() {
    if (!this.companyformulaData || this.companyformulaData.length === 0) {
      this.spinnerService.raiseDataEmitterEvent('off');
      return;
    }
    this.ComponentList = this.companyformulaData.filter((val: any) => val.component_id != this.ComponentID);
    this.filterData = this.companyformulaData.filter((val: any) => val.component_id == this.ComponentID);
    if (this.filterData.length !== 0) {
      this.formulaExpressionName = this.filterData[0].formula_expression_name;
      this.formulaExpression = this.filterData[0].formula_expression;
    } else {
      this.clears();
    }

    this.spinnerService.raiseDataEmitterEvent('off');

  }



  getAcronym(str: any) {
    var matches = str.match(/\b(\w)/g); // ['J','S','O','N']
    matches = matches.slice(0, 3);
    var acronym = matches.join(''); // JSON
    return acronym;
  }
  getBgColors(str: any) {
    var min = 0;
    var max = 15;
    var index = this.findAlphapositions(str, 1);
    return this.nameBgColors[index];
  }
  findAlphapositions(str: any, n: any) {
    var NUM = 31;

    return str[0].charCodeAt(0) & NUM;
  }

  ToparentFun() {
    this.showParent = true;
    this.parenFun.emit(this.showParent)
  }

  GetSlapDropDown() {
    this.apiModulesService.get('admin/formula/dropdown').subscribe((data) => {
      this.formulaDropDown = data.data.formula;
      if (this.FormulaType == null || this.FormulaType == '') {
        this.FormulaType = 'normal_formula'
      }
      setTimeout(() => {

        this.getReferenceFormula();

      }, 100)
    });
  }

}
