import { Component, OnDestroy, OnInit } from '@angular/core';
import { faPlay, faPause, faStop } from '@fortawesome/free-solid-svg-icons';
import { Chart, ChartOptions } from "chart.js";
import { CalendarOptions } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import { SpinnerService } from 'src/app/snipper/spinner.service';
import { DatePipe } from "@angular/common";
import { ApiModulesService } from 'src/app/all-modules/api-modules.service';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
@Component({
  selector: 'app-time',
  templateUrl: './time.component.html',
  styleUrls: ['./time.component.css']
})
export class TimeComponent implements OnInit, OnDestroy {
  currentDate: any;
  MONTH_DATA: any;
  calendarOptions: CalendarOptions = {
    plugins: [dayGridPlugin],
    initialView: 'dayGridMonth',
    weekends: true,
    events: [],
    eventContent: this.customEventContent.bind(this),
    eventClick: this.handleEventClick.bind(this)
  };
  popedData: any;
  public pipe = new DatePipe('en-US');
  faPlay = faPlay;
  currentFirstIcon = this.faPlay;
  isPlayIconDisabled = false;
  startTime: any;
  faStop = faStop;
  isStopIconDisabled = true;
  endTime: string | null = null;
  lastStopDate: Date | null = null;
  breakTimes: { start: Date | null, end: Date | null, duration: string | null }[] = [];
  timePiceChart: Chart<"pie", number[], string>;
  dtInitialised = false;
  faPause = faPause;
  currentMiddleIcon = this.faPause;
  isMiddleIconDisabled = true;
  status: string = '';
  day_status: string = '';
  workingHours: number = 0;
  initialize = false;
  lastShiftDate: any;
  months: string[] = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  private dateTimer: any;
  fromDateChoose: any;
  yesterDay: any;
  stopClock = false;
  active_status: any;
  clientID = localStorage.getItem('client_id');
  companyID = localStorage.getItem('company_id');
  employeeID = localStorage.getItem('employee_id');
  clockInTime: any;
  employeeBreakTime: any;
  breakDuration: any;
  previousShiftTime: any;
  inTime: any;
  outTime: string;
  employeeTimeData: any;
  // emp_time_id: any;
  work_status_id: any;
  workingStatusData: any;
  calendarData: any;
  timeGraphData: any;
  currentShift: any;
  popupData = [];
  todayDate: any;
  clickDate: any;
  showCalendar: any;
  allotedTimeData: any;
  allotedBreakData: any;
  actualTimeData: any;
  actualBreakData: any = [];
  modifyTimeForm: FormGroup;
  employee_remark: any;
  time_status = 'pending';
  actualTimeId: any;
  compOff_id: any = [];
  filterDatePopup: any;
  leaveTypeData: any;
  constructor(private spinnerService: SpinnerService, private apiModuleService: ApiModulesService,
    private formBuilder: FormBuilder, private toaster: ToastrService, private router: Router,) { }

  ngOnInit() {
    this.spinnerService.raiseDataEmitterEvent('on');
    var today = new Date(),
      year = today.getFullYear(),
      month = today.getMonth(),
      day = today.getDate()
    this.dateModification(today, 'todayDate');
    this.fromDateChoose = new Date()
    this.currentDate = this.formatDate(this.fromDateChoose);
    // this.checkPlayIconStatus();
    // this.scheduleMidnightUpdate();
    // this.timeChartGraph();
    this.getEmployeeTimeData();
    this.getEmployeeTimeGraphData();
    // this.workingStatusTime();
    this.modifyTimeForm = this.formBuilder.group({
      modify_in_time: ['', [Validators.required]],
      modify_out_time: ['', [Validators.required]],
      employee_remark: ['', [Validators.required]],
      emp_time_id: ['', [Validators.required]],
      emp_breaks: this.formBuilder.array([]),
      date: ['', []],
      clock_out_date: ['', []],
    });

  }

  getEmployeeTimeData() {
    this.spinnerService.raiseDataEmitterEvent('on');
    const params: any = {};
    params.client_id = this.clientID;
    params.company_id = this.companyID;
    params.employee_id = this.employeeID;
    this.apiModuleService.list('employee-tabs/employee-hrm/employee-time/list', params).subscribe((data: any) => {
      this.employeeTimeData = data.data.employee_time[0] != undefined ? data.data.employee_time[0] : [];
      if (this.employeeTimeData) {
        this.clockInTime = this.employeeTimeData.in_time ? this.employeeTimeData.in_time.split(":").slice(0, 2).join(":") : null;
        this.outTime = this.employeeTimeData.out_time ? this.employeeTimeData.out_time.split(":").slice(0, 2).join(":") : null;
        this.active_status = this.employeeTimeData.active_status ? this.employeeTimeData.active_status : null;
        setTimeout(() => {
          this.iconsChecker();
        }, 100)
      } else {
        this.clockInTime = null;
        this.outTime = null;
      }
      this.employeeBreakTime = data.data.employee_break != undefined ? data.data.employee_break : [];
      this.previousShiftTime = data.data.previous_shift_time[0] != undefined ? data.data.previous_shift_time[0] : {};
      this.calendarData = data.data.time_calendar != undefined ? data.data.time_calendar : [];
      this.calendarOptions.events = data.data.time_calendar != undefined ? data.data.time_calendar : [];
      this.currentShift = data.data.current_shift[0] != undefined ? data.data.current_shift[0] : [];
      this.spinnerService.raiseDataEmitterEvent('off');

    }, (err: any) => {
      this.spinnerService.toasterError(err);
    });
  }
  getEmployeeTimeGraphData() {
    this.spinnerService.raiseDataEmitterEvent('on');
    const params: any = {};
    params.client_id = this.clientID;
    params.company_id = this.companyID;
    params.employee_id = this.employeeID;
    const chosenDate = new Date(this.fromDateChoose);
    const month = chosenDate.getMonth();
    const year = chosenDate.getFullYear();
    params.month_name = this.months[month];
    params.year_no = year;
    this.apiModuleService.list('employee-tabs/employee-hrm/employee-time/graph', params).subscribe((data: any) => {
      this.timeGraphData = data.data.employee_time != undefined ? data.data.employee_time : [];
      this.timeChartGraph();
      this.spinnerService.raiseDataEmitterEvent('off');

    }, (err: any) => {
      this.spinnerService.toasterError(err);
    });
  }
  iconsChecker() {
    switch (this.active_status) {
      case '':
        this.isPlayIconDisabled = true;
        this.isMiddleIconDisabled = false;
        this.isStopIconDisabled = false;
        this.currentMiddleIcon = this.faPlay;
        break;
      case 'clock_in':
        this.isPlayIconDisabled = true;
        this.isMiddleIconDisabled = false;
        this.isStopIconDisabled = false;
        this.currentMiddleIcon = this.faPlay;
        break;
      case 'break_in':
        this.isPlayIconDisabled = true;
        this.isMiddleIconDisabled = false;
        this.isStopIconDisabled = false;
        this.currentMiddleIcon = this.faPlay;
        break;
      case 'break_out':
        this.isPlayIconDisabled = true;
        this.isMiddleIconDisabled = false;
        this.isStopIconDisabled = false;
        this.currentMiddleIcon = this.faPause;
        break;
      case 'clock_out':
        this.isPlayIconDisabled = true;
        this.isMiddleIconDisabled = true;
        this.isStopIconDisabled = true;
        this.currentMiddleIcon = this.faPlay;
        this.stopClock = true
        break;

    }
  }
  addClockInTime() {
    const params: any = {};
    params.client_id = this.clientID;
    params.company_id = this.companyID;
    params.employee_id = this.employeeID;
    params.in_time = new Date().toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false });
    this.apiModuleService.list('employee-tabs/employee-hrm/employee-time/clock-in', params).subscribe((data: any) => {
      this.getEmployeeTimeData();
    }, (err: any) => {
      this.spinnerService.toasterError(err);
    });
  }
  callBreaks() {
    if (this.isMiddleIconDisabled == false) {
      if (this.active_status == 'break_in') {
        this.addbreakOutTime();
      } else {
        this.addbreakInTime();
      }
    }

  }
  addbreakInTime() {
    const params: any = {};
    params.client_id = this.clientID;
    params.company_id = this.companyID;
    params.employee_id = this.employeeID;
    params.start_break = new Date().toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true });
    this.apiModuleService.list('employee-tabs/employee-hrm/employee-time/start-break', params).subscribe((data: any) => {
      this.getEmployeeTimeData();
    }, (err: any) => {
      this.spinnerService.toasterError(err);
    });
  }
  addbreakOutTime() {
    const params: any = {};
    params.client_id = this.clientID;
    params.company_id = this.companyID;
    params.employee_id = this.employeeID;
    params.end_break = new Date().toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true });
    this.apiModuleService.list('employee-tabs/employee-hrm/employee-time/end-break', params).subscribe((data: any) => {
      this.getEmployeeTimeData();
    }, (err: any) => {
      this.spinnerService.toasterError(err);
    });
  }
  workingStatusTime() {
    const params: any = {};
    params.client_id = this.clientID;
    params.company_id = this.companyID;
    params.employee_id = this.employeeID;
    params.out_time = new Date().toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false });
    this.apiModuleService.list('employee-tabs/employee-hrm/employee-time/working-status', params).subscribe((data: any) => {
      this.workingStatusData = data.data.employee_time[0] != undefined ? data.data.employee_time[0] : {};
      ($('#time-status-submit') as any).modal('show');
    }, (err: any) => {
      this.spinnerService.toasterError(err);
    });
  }
  addClockOutTime() {
    const params: any = {};
    params.client_id = this.clientID;
    params.company_id = this.companyID;
    params.employee_id = this.employeeID;
    params.working_status_id = this.workingStatusData?.working_status_id;
    params.working_hours = this.workingStatusData?.working_hours;
    params.out_time = new Date().toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false });
    this.apiModuleService.list('employee-tabs/employee-hrm/employee-time/clock-out', params).subscribe((data: any) => {
      ($('#time-status-submit') as any).modal('hide');
      this.getEmployeeTimeData();
      this.getEmployeeTimeGraphData();
    }, (err: any) => {
      this.spinnerService.toasterError(err);
    });
  }
  private formatDate(date: Date): string {
    return date.toLocaleDateString('en-US', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  }
  ngOnDestroy(): void {
    // if (this.dateTimer) {
    //   clearInterval(this.dateTimer);
    // }
  }

  timeChartGraph() {
    if (!this.dtInitialised) {
      const chartOption: ChartOptions<'pie'> = {
        plugins: {
          legend: {
            labels: {
              color: 'black',
              boxWidth: 10,
              font: {
                size: 11,
                style: 'normal',
                family: 'Open_Sans',
              },
            },
            position: 'bottom',
          },
        },
        aspectRatio: 1.7,

      };
      this.timePiceChart = new Chart("time_chart", {
        type: 'pie',
        data: {
          labels: this.timeGraphData.map((val: any) => val.working_status_name),
          datasets: [
            {
              data: this.timeGraphData.map((val: any) => val.work_status_count),
            },
          ]
        },
        options: chartOption
      });
      this.dtInitialised = true
    } else {
      this.timePiceChart.data.labels = this.timeGraphData.map((val: any) => val.working_status_name)
      this.timePiceChart.data.datasets[0].data = this.timeGraphData.map((val: any) => val.work_status_count)
      this.timePiceChart.update();
    }
  }
  handleEventClick(arg) {
    // Access the start date of the clicked event
    const startDate = this.pipe.transform(arg.event.start.toLocaleString(), 'yyyy-MM-dd');
    this.filterDatePopup = this.calendarData.filter((fil: any) => fil.start == startDate)[0];
    this.resetForm();

    if ((this.filterDatePopup.status_key == 'week_holiday'  || this.filterDatePopup.status_key == 'holiday' || this.filterDatePopup.status_key == null)&& this.filterDatePopup.is_regularize==0) {
      ($('#employee_time_details') as any).modal('hide');
    } else {
      this.dateModification(startDate, 'clickDate')
      const params: any = {};
      params.client_id = this.clientID;
      params.company_id = this.companyID;
      params.employee_id = this.employeeID;
      params.date = this.clickDate;

      this.apiModuleService.list('employee-tabs/employee-hrm/employee-time/calendar-popup', params).subscribe((data: any) => {
        this.allotedTimeData = data.data.shift_time[0] != undefined ? data.data.shift_time[0] : [];
        this.allotedBreakData = data.data.shift_break != undefined ? data.data.shift_break : [];
        this.actualTimeData = data.data.employee_shift_time[0] != undefined ? data.data.employee_shift_time[0] : [];
        this.actualTimeId = this.actualTimeData.emp_time_id;
        this.actualBreakData = data.data.employee_break_time != undefined ? data.data.employee_break_time : [];
        this.leaveTypeData = data.data.leave_plan_type != undefined ? data.data.leave_plan_type : [];
        this.compOff_id = this.leaveTypeData[0].leave_plan_type_id;

        this.modifyTimeForm.patchValue({
          modify_in_time: this.actualTimeData.modify_in_time,
          modify_out_time: this.actualTimeData.modify_out_time,
          employee_remark: this.actualTimeData.employee_remark,
          emp_time_id: this.actualTimeData.emp_time_id,
          date: '',
        });

        this.actualBreakData.forEach(element => {
          this.addNewItems(element);
        });
        if (this.filterDatePopup.status_key == 'full_hours') {
          ($('#employee_details') as any).modal('show');
        } else {
          ($('#employee_time_details') as any).modal('show');
        }


        this.spinnerService.raiseDataEmitterEvent('off');


      }, (err: any) => {
        this.spinnerService.toasterError(err);
      });
    }
    // this.FromDate = startDate
    // this.ToDate = endDate
    // this.leave_employee = this.EmployeeID
    // this.managerCalendarShow();
    // ($('#employee_time_details') as any).modal('hide');
  }

  compOffLeave() {
    ($('#employee_time_details') as any).modal('hide');
    // this.spinnerService.compOffEmitterEvent(this.compOff_id);
    this.router.navigate(['employee/ess/IND/leave/home'], {
      state: { emittedValue:this.compOff_id ,date:this.clickDate}


    });
  }

  updateModifyTime(value: any, ot_key: string) {
    const params: any = {};
    params.client_id = this.clientID;
    params.company_id = this.companyID;
    params.employee_id = this.employeeID;
    params.employee_remark = value.employee_remark;
    params.modify_in_time = value.modify_in_time;
    params.modify_out_time = value.modify_out_time;
    params.modify_emp_time_id = this.actualTimeId;
    params.date = value.date;
    params.clock_out_date = value.clock_out_date;
    params.ot_key = ot_key;
    params.emp_breaks = this.modifyTimeForm.get('emp_breaks').value;
    this.apiModuleService.list('employee-tabs/employee-hrm/employee-time/modify-time', params).subscribe((data: any) => {
      this.toaster.success('Modified Time and Break Sucessfully...!', 'Success');
      this.modifyTimeForm.reset();
      ($('#employee_over_time_details') as any).modal('hide');
      this.getEmployeeTimeData();
      this.getEmployeeTimeGraphData();
    }, (err: any) => {
      this.spinnerService.toasterError(err);
    });
  }
  get itemsList(): FormArray {
    return this.modifyTimeForm.get('emp_breaks') as FormArray;
  }
  addNewItems(value: any): void {
    this.itemsList.push(this.createBreakTime(value));
  }
  createBreakTime(control_value: any): FormGroup {
    return this.formBuilder.group({
      modify_start_break: [control_value.modify_start_break, []],
      modify_end_break: [control_value.modify_end_break, []]
    });
  }
  public resetForm() {
    const timeBreaksArray = this.modifyTimeForm.get('emp_breaks') as FormArray;
    timeBreaksArray.clear();
    this.modifyTimeForm.reset();
  }
  dateModification(value, name) {
    const options: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'short', year: 'numeric' };
    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(new Date(value));
    this[name] = formattedDate;
  }
  customEventContent(arg: any) {
    let shiftData = arg.event.extendedProps.alloted_time;
    const eventStatus = arg.event.extendedProps.status;
    const eventStatusKey = arg.event.extendedProps.status_key;
    const regularizeKey = arg.event.extendedProps.is_regularize;
    const workData = arg.event.extendedProps.actual_time;
    const shiftEventClass = 'work-shift-event';
    const workClass = 'work-status-event';
    const shiftContent = document.createElement('div');
    const workContent = document.createElement('div');
    // if (['leave', 'half_day', 'short_hours', 'over_time'].includes(eventStatusKey)) {
    //   shiftContent.style.backgroundColor = '#ffdede';
    //   workContent.style.backgroundColor = '#e9e8fa';
    // } else if (['full_hours'].includes(eventStatusKey)) {

    // } else {
    //   shiftContent.style.border = '1px solid #f29b9b';
    // }
    if (['1'].includes(regularizeKey)) {
      shiftContent.innerHTML = 'Regularize';
      workContent.innerHTML = `${workData}`;
      shiftContent.style.backgroundColor = '#ffdede';
      workContent.style.backgroundColor = '#e9e8fa';
    } else if (['week_holiday', 'holiday', '', null].includes(eventStatusKey)&& ['0'].includes(regularizeKey)) {

      shiftContent.innerHTML = shiftData;

      if (eventStatusKey === null) {
        shiftContent.style.backgroundColor = '#c9f4f5';
      }
      if (eventStatusKey == 'week_holiday') {
        shiftContent.style.backgroundColor = '#ffedff';

      }
      if (eventStatusKey == "holiday") {
        shiftContent.style.backgroundColor = '#dcebee';
      }
    } else {
      shiftContent.innerHTML = `${eventStatus}`;
      shiftContent.style.backgroundColor = 'white';
      workContent.style.backgroundColor = 'white';
    }
    workContent.innerHTML = `${workData}`;
    workContent.className = workClass;
    workContent.style.border = '1px solid #fff';
    shiftContent.className = shiftEventClass;
    shiftContent.style.border = '1px solid #fff';
    let array = []
    if (shiftContent.innerHTML != '' && shiftContent.innerHTML != null) {
      array.push(shiftContent)
    }
    if (workContent.innerHTML != '' && workContent.innerHTML != null) {
      array.push(workContent)
    }
    return { domNodes: array };
  }
}

