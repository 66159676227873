import { Component, OnInit } from '@angular/core';
import { ApiModulesService } from 'src/app/all-modules/api-modules.service';
import { SpinnerService } from 'src/app/snipper/spinner.service';
@Component({
  selector: 'app-loan-status',
  templateUrl: './loan-status.component.html',
  styleUrls: ['./loan-status.component.css']
})
export class LoanStatusComponent implements OnInit {
  clientID = localStorage.getItem('client_id');
  companyID = localStorage.getItem('company_id');
  employeeID = localStorage.getItem('employee_id');
  loanStatus: any;
  constructor(private apimoduleservice: ApiModulesService, private spinnerService: SpinnerService,
  ) { }
  ngOnInit() {
    this.spinnerService.raiseDataEmitterEvent('on');
    this.loansStatusData();
  }
  loansStatusData() {
    this.spinnerService.raiseDataEmitterEvent('on');
    const params: any = {};
    params.client_id = this.clientID;
    params.company_id = this.companyID;
    params.employee_id = this.employeeID;
    this.apimoduleservice.list('employee-tabs/IND-employee-payroll/loans/status-list', params).subscribe((data) => {
      this.loanStatus = data.data.loan_status != undefined ? data.data.loan_status : [];
      this.spinnerService.raiseDataEmitterEvent('off');
    }, (err: any) => {
      this.spinnerService.toasterError(err);
    })
  }
}
