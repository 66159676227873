// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-wrappers{
  margin-left: 16px;
    margin-right: 12px;

}
.file_input{
  width: 30%;
}
`, "",{"version":3,"sources":["webpack://./src/app/all-modules/company/previous-salary-file-upload/previous-salary-file-upload.component.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;IACf,kBAAkB;;AAEtB;AACA;EACE,UAAU;AACZ","sourcesContent":[".page-wrappers{\r\n  margin-left: 16px;\r\n    margin-right: 12px;\r\n\r\n}\r\n.file_input{\r\n  width: 30%;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
