import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {ApiModulesService} from '../../api-modules.service';
import {AllModulesService} from '../../all-modules.service';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';

import {Subject} from 'rxjs';
import {Subscription} from 'rxjs';
import { Table } from 'primeng/table';
import {SpinnerService} from "../../../snipper/spinner.service";

@Component({
    selector: 'app-bankdetails',
    templateUrl: './bankdetails.component.html',
    styleUrls: ['./bankdetails.component.css']
})
export class BankdetailsComponent implements OnInit, OnDestroy, AfterViewInit {

    public bankData = [];
    public accountCategory = [];
    public addBankdetailsForm: FormGroup;
    // nameRegex='^[a-zA-Z][a-zA-Z\\s]*$';
    // numberRegex = '^[a-zA-Z0-9]{6,10}$';
    ShowForm=false;
    public rows = [];
    showBankDetails: boolean = true;
    searchKeyword: string = '';
    public search = [];
    statuses=[];
    loading: boolean = false;
    private user_type=localStorage.getItem('user_type');
    statusApi:any;
    statusValues:any;
    tabToGrid=false;
    @Output() parenFun: EventEmitter<any> = new EventEmitter();
    @Input() authEmployeeData: any = {};
    @Input() pageName: any = {};

    nameBgColors: any = ['bg-blue-dim', 'bg-blue-dim', 'bg-azure-dim', 'bg-indigo-dim', 'bg-info-dim', 'bg-purple-dim', 'bg-pink-dim', 'bg-orange-dim', 'bg-teal-dim', 'bg-primary-dim', 'bg-success-dim', 'bg-warning-dim', 'bg-danger-dim', 'bg-secondary-dim', 'bg-dark-dim', 'bg-gray-dim', 'bg-blue-dim', 'bg-azure-dim', 'bg-indigo-dim', 'bg-info-dim', 'bg-purple-dim', 'bg-pink-dim', 'bg-orange-dim', 'bg-teal-dim', 'bg-primary-dim', 'bg-success-dim', 'bg-warning-dim', 'bg-danger-dim', 'bg-secondary-dim', 'bg-dark-dim', 'bg-gray-dim'];
    nameBorderColors: any = ['bg-outline-primary', 'bg-outline-success', 'bg-outline-info', 'bg-outline-gray', 'bg-outline-warning', 'bg-outline-dark', 'bg-outline-light', 'bg-outline-secondary', 'bg-outline-danger'];

    constructor(private apiModuleService: ApiModulesService,
                private allModulesService: AllModulesService,
                private toastr: ToastrService,
                private formBuilder: FormBuilder,
                private spinnerService: SpinnerService
    ) {
    }

    ngOnInit(): void {
        this.spinnerService.raiseDataEmitterEvent('on');
        this.getBankdetails();
        this.bankDropDown();
        this.statuses = [
            { label: 'Active', value: 'active' },
            { label: 'Inactive', value: 'inactive' },
        ];
        this.addBankdetailsForm = this.formBuilder.group({
            employee_bank_details_id: ['', []],
            account_category: ['', [Validators.required]],
        bank_name: ['', [Validators.required, /*Validators.pattern(this.nameRegex)*/]],
            bank_code: ['', [Validators.required, /*Validators.pattern(this.numberRegex)*/]],
            bank_branch: ['', [Validators.required, /*Validators.pattern(this.nameRegex)*/]],
            account_holder_name: ['', [Validators.required, /*Validators.pattern(this.nameRegex)*/]],
            account_number: ['', [Validators.required, /*Validators.pattern(this.numberRegex)*/]],
        });
    }

    ngAfterViewInit(): void {

    }

    // Reset Nationality
    public resetForm() {
        this.addBankdetailsForm.reset();
    }
    showAddForm() {
        this.ShowForm = !this.ShowForm;
    }
    // add Bank Details
    public onAddBankdetails() {

        if (!this.addBankdetailsForm.valid) {

            this.addBankdetailsForm.markAllAsTouched();
            return;
        }
        const params: any = {};
        params.client_id = this.authEmployeeData.client_id;
        params.company_id = this.authEmployeeData.company_id;
        params.employee_id = this.authEmployeeData.employee_id;
        params.bank_name = this.addBankdetailsForm.get('bank_name').value;
        params.bank_code = this.addBankdetailsForm.get('bank_code').value;
        params.bank_branch = this.addBankdetailsForm.get('bank_branch').value;
        params.account_holder_name = this.addBankdetailsForm.get('account_holder_name').value;
        params.account_category = this.addBankdetailsForm.get('account_category').value;
        params.account_number = this.addBankdetailsForm.get('account_number').value;
        if (this.addBankdetailsForm.get('employee_bank_details_id').value != null
            && this.addBankdetailsForm.get('employee_bank_details_id').value !== '') {
            params.employee_bank_details_id = this.addBankdetailsForm.get('employee_bank_details_id').value;
            this.apiModuleService.add(params, 'admin/bankDetails/update',).subscribe((data) => {

                this.toastr.success('BankDetails Updated Successfully...!', 'Success');
                    this.getBankdetails();
                this.ShowForm=false;
                }, (err) => {
                  this.spinnerService.toasterError(err);
                }
            );
        } else {
            this.apiModuleService.add(params, 'admin/bankDetails/add',).subscribe((data) => {

                    this.toastr.success('Bankdetails Added Successfully...!', 'Success');
                    this.getBankdetails();
                this.ShowForm=false;
                }, (err) => {
                  this.spinnerService.toasterError(err);

                }
            );
        }
    }
    parentFun(){

        this.parenFun.emit(this.tabToGrid)
    }
    bankDropDown() {
        const params: any = {};
        params.client_id = this.authEmployeeData.client_id;
        params.company_id = this.authEmployeeData.company_id;
        params.employee_id = this.authEmployeeData.employee_id;
        this.apiModuleService.list('admin/bankDetails/dropDown', params).subscribe((data) => {

            this.accountCategory = data.data.employee_bank_details;

        },(err:any)=>{
          this.spinnerService.toasterError(err);

        })
    }

    public getBankdetails() {
        this.spinnerService.raiseDataEmitterEvent('on') ;
        const params: any = {};
        params.client_id = this.authEmployeeData.client_id;
        params.company_id = this.authEmployeeData.company_id;
        params.employee_id = this.authEmployeeData.employee_id;
        this.apiModuleService.list('admin/bankDetails/list', params).subscribe((data) => {

            this.bankData = data.data.employee_bank_details;
            this.spinnerService.raiseDataEmitterEvent('off');

        }, (err) => {
            this.bankData = [];
            this.spinnerService.toasterError(err);
        });
    }

    editValue(bankDetails) {
        this.ShowForm=true;
        this.addBankdetailsForm.setValue({
            employee_bank_details_id: bankDetails.employee_bank_details_id,
            account_category: bankDetails.account_category,
            bank_name: bankDetails.bank_name,
            bank_code: bankDetails.bank_code,
            bank_branch: bankDetails.bank_branch,
            account_holder_name: bankDetails.account_holder_name,
            account_number: bankDetails.account_number,
        });
    }


// status change
    changeStatus(status: any, BankdetailsId: any) {
        const params: any = {};
        params.client_id = this.authEmployeeData.client_id;
        params.company_id = this.authEmployeeData.company_id;
        params.employee_id = this.authEmployeeData.employee_id;
        params.employee_bank_details_id = BankdetailsId;
        //
        this.apiModuleService.edit(params, 'admin/bankDetails/' + status).subscribe((data) => {
                this.getBankdetails();
                ($('#employeebank-status') as any).modal('hide');
                this.toastr.success('Status Changed Sucessfully...!', 'Success');
            },(err) => {
              this.spinnerService.toasterError(err);
            }
        );

    }

    ngOnDestroy()
        :
        void {
        // Do not forget to unsubscribe the event

    }

    getAcronym(str
                   :
                   any
    ) {
        let matches = str.match(/\b(\w)/g); // ['J','S','O','N']
        matches = matches.slice(0, 3);
        const acronym = matches.join(''); // JSON
        return acronym;
    }

    getBgColors(str
                    :
                    any
    ) {
        const min = 0;
        const max = 15;
        const index = this.findAlphapositions(str, 1);
        return this.nameBgColors[index];
    }

    findAlphapositions(str
                           :
                           any, n
                           :
                           any
    ) {
        const NUM = 31;

        return str[0].charCodeAt(0) & NUM;
    }
    clear(table: Table) {

        table.clear();
    }

    getSeverity(status: string) {
        switch (status.toLowerCase()) {
            case 'inactive':
                return 'danger';

            case 'active':
                return 'success';
        }
    }
    callStatusModel(url_name:any,data:any,actionName:string){
        this.statusApi=url_name;
        this.statusValues=data;
    }
}

