import { Component, OnInit, Input } from '@angular/core';
import { SpinnerService } from "../../../snipper/spinner.service";
import { ApiModulesService } from "../../api-modules.service";
import { FormBuilder } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { AllModulesService } from "../../all-modules.service";
import { DatePipe } from '@angular/common';
import Chart from 'chart.js/auto';

@Component({
    selector: 'app-calendar',
    templateUrl: './calendar.component.html',
    styleUrls: ['./calendar.component.css']
})
export class CalendarComponent implements OnInit {
    @Input() EmployeeID: any;
    @Input() CompanyID: any;
    @Input() ClientID: any;
    @Input() company_holiday: any;
    calanderData: any;
    leaveAnalysis: any;
    initialized = false;
    leaveDays = [];
    monthName = [];
    rotate = false;
    cardBgColors = [
        'rgba(252, 250, 251, 0.12)', 'rgba(252, 250, 251, 0.12)', 'rgba(252, 250, 251, 0.12)', 'rgba(252, 250, 251, 0.12)', 'rgba(172, 161, 167, 0.57) '
    ]
    public chart: any;
    public pipe = new DatePipe('en-US');

    constructor(
        private ApiModuleService: ApiModulesService,) {
    }

    ngOnInit() {
        this.calenderGrid();


    }


    calenderView() {
        const params: any = {};
        params.client_id = this.ClientID;
        params.company_id = this.CompanyID;
        params.employee_id = this.EmployeeID;
        let promise = new Promise((resolve, reject) => {

            this.ApiModuleService.list('employee-tabs/employee-hrm/leave-home/calendarview', params).subscribe((data) => {
                resolve(data.data.leaves)
            }, (err: any) => {
                reject('no data')
            });
        });
        let data = promise.then((result) => {

            return result
        });
        return data;
    }


    calenderGrid() {
        let promise = new Promise((resolve, reject) => {
            let calenderView: any = this.calenderView();
            resolve(calenderView);
            reject([]);
        });
        promise.then((result) => {
            this.calanderData = result[0];
            this.leaveAnalysis = result[1][0];
            this.monthName = [{ "full_name": "January", "short_name": "JAN" },
            { "full_name": "February", "short_name": "FEB" },
            { "full_name": "March", "short_name": "MAR" },
            { "full_name": "April", "short_name": "APR" },
            { "full_name": "May", "short_name": "MAY" },
            { "full_name": "June", "short_name": "JUN" },
            { "full_name": "July", "short_name": "JUL" },
            { "full_name": "August", "short_name": "AUG" },
            { "full_name": "September", "short_name": "SEP" },
            { "full_name": "October", "short_name": "OCT" },
            { "full_name": "November", "short_name": "NOV" },
            { "full_name": "December", "short_name": "DEC" }
            ];
            this.leaveDays=[];
            for (let i = 0; i <= 11; i++) {
                // Initialize leaveDays object if it's not already initialized
                if (!this.leaveDays[this.monthName[i].full_name]) {
                    this.leaveDays[this.monthName[i].full_name] = [];
                }

                // Filter and map leave days from calanderData with 'Approved' leave status
                const approvedLeaveDays = this.calanderData
                    .filter((data) => data.month_name === this.monthName[i].full_name && data.leave_status === 'Approved')
                    .map((val) => { return { "date": val.ExtractedDay, 'isleave_taken': true } });

                // Filter and map company holidays
                const companyHolidays = this.company_holiday
                    .filter((data) => data.month_name === this.monthName[i].full_name)
                    .map((val) => { return { "date": val.ExtractedDay, 'isleave_taken': false } });

                // Concatenate approvedLeaveDays and companyHolidays arrays into leaveDays
                this.leaveDays[this.monthName[i].full_name] = this.leaveDays[this.monthName[i].full_name].concat(approvedLeaveDays, companyHolidays);

                // Sort leaveDays array by date
                this.leaveDays[this.monthName[i].full_name]=  this.leaveDays[this.monthName[i].full_name].sort((a, b) => {
                 return Number(a.date) - Number(b.date);
                });


            }

            setTimeout(() => {
                if (this.initialized == false) {

                    this.initialized = true;
                } else {


                    // data: {// values on X-Axis
                    //     labels: ['Employee Leaves'],
                    //         datasets: [
                    //         {
                    //             label: "Eligible",
                    //             data: [this.leaveAnalysis.total_leave_days],
                    //             backgroundColor: '#dce6be'
                    //         },
                    //         {
                    //             label: "Availed",
                    //             data: [this.leaveAnalysis.taken_days],
                    //             backgroundColor: '#cbebf5'
                    //         }, {
                    //             label: "Balance",
                    //             data: [this.leaveAnalysis.balance_days],
                    //             backgroundColor: '#d7f7f5'
                    //         }, {
                    //             label: "Holidays",
                    //             data: [this.leaveAnalysis.holidays],
                    //             backgroundColor: '#f7e7d5'
                    //         }
                    //     ]
                    // },

                }

            }, 10);
            var today = new Date(),
                year = today.getFullYear(),
                month = today.getMonth(),
                monthTag = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
                day = today.getDate(),
                days = document.getElementsByTagName('td'),
                selectedDay = new Date(),
                setDate,
                contentAvailable,
                daysLen = days.length;

            // options should like '2014-01-01'
            function Calendar(selector, options, calanderData, rotate, initialized) {
                this.rotate = rotate
                this.initialized = false;

                this.calanderData = calanderData[0],
                    this.analysis = calanderData[1],
                    this.contents = [];
                this.options = options;
                this.draw();
            }

            Calendar.prototype.draw = function () {
                this.getCookie('selected_day');
                this.getOptions();
                this.drawDays();
                var that = this,
                    reset = document.getElementById('reset'),
                    graph = document.getElementById('graph'),
                    pre = document.getElementsByClassName('pre-button'),
                    next = document.getElementsByClassName('next-button');

                pre[0].addEventListener('click', function () {
                    that.preMonth();
                });
                next[0].addEventListener('click', function () {
                    that.nextMonth();
                });
                reset.addEventListener('click', function () {
                    that.reset();
                });
                graph.addEventListener('click', function () {
                    var flipCard = document.getElementById('flip-card-inner-rotate');
                    flipCard.style.transform = 'rotateX(360deg)';
                });
                while (daysLen--) {
                    days[daysLen].addEventListener('click', function () {
                        that.clickDay(this);
                    });
                }
            };

            Calendar.prototype.drawHeader = function (e) {
                var headDay = document.getElementsByClassName('head-day'),
                    approvedOn = document.getElementsByClassName('approved_on'),
                    approvedBy = document.getElementsByClassName('approved_by'),
                    appliedDate = document.getElementsByClassName('applied_date'),
                    leavetypeName = document.getElementsByClassName('leave_type_name'),
                    fromDate = document.getElementsByClassName('from_date'),
                    toDate = document.getElementsByClassName('to_date'),
                    noOfDays = document.getElementsByClassName('no_of_days'),
                    headMonth = document.getElementsByClassName('head-month'),
                    flipfrontDate = document.getElementsByClassName('flip-card-date'),
                    headMonthYear = document.getElementsByClassName('head-month-year'),
                    headReason = document.getElementsByClassName('head-reason'),
                    flipCard = document.getElementById('flip-card-inner-rotate');
                const d = new Date(selectedDay);
                let datePipeString = d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate()
                let content = this.calanderData.filter((data: any) => new Date(data.date).getDate() == d.getDate() && new Date(data.date).getMonth() == d.getMonth() && new Date(data.date).getFullYear() == d.getFullYear());
                this.content = content;
                if (content.length != 0) {
                    e ? headDay[0].innerHTML = content[0].leave_status : headDay[0].innerHTML = content[0].leave_status;
                    headMonth[0].innerHTML = datePipeString + '  (' + content[0].day + ')';
                    headReason[0].innerHTML = content[0].leave_reason;
                    if (content[0].leave_status != 'Holiday') {

                        // labels

                        appliedDate[0].innerHTML = 'Applied Date';
                        leavetypeName[0].innerHTML = 'leave Type Name';
                        toDate[0].innerHTML = 'To Date';
                        fromDate[0].innerHTML = 'From Date';
                        noOfDays[0].innerHTML = 'No Of Days';
                        // value

                        appliedDate[1].innerHTML = content[0].applied_date;
                        leavetypeName[1].innerHTML = content[0].leave_type_name;
                        toDate[1].innerHTML = content[0].to_date;
                        fromDate[1].innerHTML = content[0].from_date;
                        noOfDays[1].innerHTML = content[0].no_of_days;
                        if (content[0].leave_status == 'Approved') {
                            approvedOn[0].innerHTML = 'Approved On';
                            approvedBy[0].innerHTML = 'Approved By';
                            approvedOn[1].innerHTML = content[0].approved_date;
                            approvedBy[1].innerHTML = this.analysis[0].first_name;
                        } else {
                            approvedOn[0].innerHTML = '';
                            approvedBy[0].innerHTML = '';
                            approvedOn[1].innerHTML = '';
                            approvedBy[1].innerHTML = '';
                        }
                    } else {
                        // label
                        approvedOn[0].innerHTML = '';
                        approvedBy[0].innerHTML = '';
                        appliedDate[0].innerHTML = '';
                        leavetypeName[0].innerHTML = '';
                        toDate[0].innerHTML = '';
                        fromDate[0].innerHTML = '';
                        noOfDays[0].innerHTML = '';
                        //value
                        approvedOn[1].innerHTML = '';
                        approvedBy[1].innerHTML = '';
                        appliedDate[1].innerHTML = '';
                        leavetypeName[1].innerHTML = '';
                        toDate[1].innerHTML = '';
                        fromDate[1].innerHTML = '';
                        noOfDays[1].innerHTML = '';
                    }
                    // applied_date
                    //     :
                    //     "2023-11-24"
                    // approved_by
                    //     :
                    //     "38"
                    // approved_date
                    //     :
                    //     "2023-11-25"
                    // date
                    //     :
                    //     "2023-11-22"
                    // day
                    //     :
                    //     "Wednesday"
                    // employee_leave_id
                    //     :
                    //     "2"
                    // leave_reason
                    //     :
                    //     "for text purpouse I take leave this day please allow me or give a reson for that"
                    // leave_status
                    //     :
                    //     "Approved"
                    // sino
                    //     :
                    //     "1"
                    if (this.initialized == true) {
                        this.rotate = true;
                        flipCard.style.transform = 'rotateX(180deg)'
                    } else {
                        this.initialized = true;
                    }
                }
                else {
                    headDay[0].innerHTML = 'None';
                    headMonth[0].innerHTML = datePipeString;
                    headReason[0].innerHTML = 'None';
                    if (this.rotate == true) {
                        flipCard.style.transform = 'rotateX(360deg)'
                        this.rotate = false;
                    }

                }
                headMonthYear[0].innerHTML = monthTag[month] + " - " + year;
                const todaydate = new Date(today);
                let dateToday = todaydate.getFullYear() + '-' + (todaydate.getMonth() + 1) + '-' + todaydate.getDate()
                flipfrontDate[0].innerHTML = 'Leave Summary As Of ' + '( ' + dateToday + ')';

            };

            Calendar.prototype.drawDays = function () {
                var startDay = new Date(year, month, 1).getDay(),
                    nDays = new Date(year, month + 1, 0).getDate(),

                    n = startDay;
                for (var k = 0; k < 42; k++) {
                    days[k].innerHTML = '';
                    days[k].id = '';
                    days[k].className = '';
                }

                for (var i = 1; i <= nDays; i++) {
                    days[n].innerHTML = String(i);
                    n++;
                }

                for (var j = 0; j < 42; j++) {

                    if (days[j].innerHTML === "") {

                        days[j].id = "disabled";
                    } else if (j === day + startDay - 1) {
                        if ((this.options && (month === setDate.getMonth()) && (year === setDate.getFullYear())) || (!this.options && (month === today.getMonth()) && (year === today.getFullYear()))) {
                            this.drawHeader(day);
                            days[j].id = "today";

                        }
                    }
                    if (selectedDay) {
                        let leaveData = this.calanderData.filter((data: any) => (new Date(data.date).getDate() + startDay - 1) == j && new Date(data.date).getMonth() == month && new Date(data.date).getFullYear() == year);
                        if (leaveData.length != 0) {
                            if (leaveData[0].leave_status == 'Approved') {
                                // this.drawHeader(j);
                                days[j].id = "approved";
                            }
                            if (leaveData[0].leave_status == 'Holiday') {
                                // this.drawHeader(j);

                                days[j].id = "holiday";

                            }
                            if (leaveData[0].leave_status == 'Pending') {
                                // this.drawHeader(j);
                                days[j].id = "pending";

                            }
                        }
                        if ((j === selectedDay.getDate() + startDay - 1) && (month === selectedDay.getMonth()) && (year === selectedDay.getFullYear())) {
                            days[j].className = "selected";
                            this.drawHeader(selectedDay.getDate());
                        }
                    }
                }
            };

            Calendar.prototype.clickDay = function (o) {
                var selected = document.getElementsByClassName("selected"),
                    len = selected.length;
                if (len !== 0) {
                    selected[0].className = "";
                }
                o.className = "selected";
                selectedDay = new Date(year, month, o.innerHTML);
                this.drawHeader(o.innerHTML);
                this.setCookie('selected_day', 1);

            };

            Calendar.prototype.preMonth = function () {
                if (month < 1) {
                    month = 11;
                    year = year - 1;
                } else {
                    month = month - 1;
                }
                this.drawHeader(1);
                this.drawDays();
            };

            Calendar.prototype.nextMonth = function () {
                if (month >= 11) {
                    month = 0;
                    year = year + 1;
                } else {
                    month = month + 1;
                }
                this.drawHeader(1);
                this.drawDays();
            };

            Calendar.prototype.getOptions = function () {
                if (this.options) {
                    var sets = this.options.split('-');
                    setDate = new Date(sets[0], sets[1] - 1, sets[2]);
                    day = setDate.getDate();
                    year = setDate.getFullYear();
                    month = setDate.getMonth();
                }
            };

            Calendar.prototype.reset = function () {
                month = today.getMonth();
                year = today.getFullYear();
                day = today.getDate();
                selectedDay = new Date();
                this.options = undefined;
                // this.drawHeader(day)
                this.drawDays();
            };

            Calendar.prototype.setCookie = function (name, expiredays) {
                if (expiredays) {
                    var date = new Date();
                    date.setTime(date.getTime() + (expiredays * 24 * 60 * 60 * 1000));
                    var expires = "; expires=" + date.toString();
                } else {
                    var expires = "";
                }
                document.cookie = name + "=" + selectedDay + expires + "; path=/";
            };

            Calendar.prototype.getCookie = function (name) {
                if (document.cookie.length) {
                    var arrCookie = document.cookie.split(';'),
                        nameEQ = name + "=";
                    for (var i = 0, cLen = arrCookie.length; i < cLen; i++) {
                        var c = arrCookie[i];
                        while (c.charAt(0) == ' ') {
                            c = c.substring(1, c.length);

                        }
                        if (c.indexOf(nameEQ) === 0) {
                            selectedDay = today;
                        }
                    }
                }
            };
            var calendar = new Calendar('', '', result, this.rotate, this.initialized)
            //             let selectedDays=document.getElementsByClassName('selected_day');
            //             let d new Date(selectedDays);
            //             let content = this.calanderData.filter((data: any) => new Date(data.date).getDate() == d.getDate() && new Date(data.date).getMonth() == d.getMonth() && new Date(data.date).getFullYear() == d.getFullYear());

            return result
        }).catch((response) => {
            return [];
        });
    }

    rotateFunction(e) {
        // var selectedDays=document.getElementsByClassName('selected');
        // // let len = selectedDays.length;
        // // if (len !== 0) {
        // //     selectedDays[0].className = "";
        // // }
        // // // e.className = "selected";
        // // var today = new Date(),
        // //     year = today.getFullYear(),
        // //     month = today.getMonth(),
        // //  selected = new Date(year, month, selectedDays);

        // let pen=document.getElementsByClassName('selected');
        // this.rotate=!this.rotate;
    }
}
