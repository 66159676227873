import {
    Component,
    OnInit,
    VERSION,
    ViewChild,
    AfterViewInit,
    OnDestroy,
    Input,
    Output,
    EventEmitter
} from '@angular/core';
import {ApiModulesService} from '../../api-modules.service';
import {ToastrService} from 'ngx-toastr';

import {FormGroup, FormBuilder, Validators, FormControl, FormArray,} from '@angular/forms';
import {event} from 'jquery';
import {Subject} from 'rxjs';
import {NgForm} from '@angular/forms';
import {NgFor} from '@angular/common';
import {documentservice} from '../../documents.sevice';
import {SpinnerService} from '../../../snipper/spinner.service';
import {CompanypaycomponentComponent} from './companypaycomponent.component';
import { Table } from 'primeng/table';
@Component({
    selector: 'app-pay-import',
    templateUrl: './paycomponentimport.component.html',
    styleUrls: ['./companypaycomponent.component.css']
})
export class PaycomponentimportComponent implements OnInit, AfterViewInit, OnDestroy {
    // @ViewChild(CompanypaycomponentComponent)companyPayComponent:CompanypaycomponentComponent;
    @Output() parenFun: EventEmitter<any> = new EventEmitter();

    

    isDtInitialized = false;
    selectedComponents=[];
    paycomponentData=[];
   @Input() ClientID:any;
   @Input() CompanyID: any;
   @Input() userKey: any;
   @Input() PaygroupID: any;
   @Input() importComponentTypeId: any;
   searchKeyword:any;
    showForm = true;
    constructor(private apiModuleService: ApiModulesService,
                private toastrService: ToastrService,
                private formBuilder: FormBuilder, private spinnerService: SpinnerService) {
    }
    clear(table: Table) {
        table.clear();
      }

    ngOnInit(): void {
       this.import();
    }
    ngAfterViewInit(): void {
     
    }
    ngOnDestroy() {
        // Do not forget to unsubscribe the event
       
    }
    public import() {
    const params: any = {};
            params.client_id = this.ClientID;
            params.company_id = this.CompanyID;
            params.pay_group_id = this.PaygroupID;
            params.users_key = this.userKey;  
            this.apiModuleService.add(params, 'admin/companypay/get_component').subscribe((data) => {
                // this.componentData = data;
                this.paycomponentData = (data != undefined) ? data : [];

            },(err:any)=>{
                this.paycomponentData =[];
                this.spinnerService.toasterError(err);
            });
        }
       
    
    
    public update() {
        if (this.selectedComponents.length !== 0) {
            this.spinnerService.raiseDataEmitterEvent('on')
            const params: any = {};
            params.company_id = this.CompanyID;
            params.client_id = this.ClientID;
            params.component_id =  this.selectedComponents.map((val: any) => val.component_id);
            params.users_key = this.userKey;
            params.pay_group_id = this.PaygroupID;
            this.apiModuleService.edit(params, 'admin/companypay/importcomponent').subscribe((data) => {
                this.showForm = false;
                this.parentFun();

                this.paycomponentData = [];
                this.toastrService.success('Import Added Sucessfully...!', 'Success');
            }, (err) => {
                if (err.status === 437) {
                    this.toastrService.error('Import Already Exists...!', 'Failed');
                } else {
                    this.spinnerService.toasterError(err);
                }
            });
        }

    }
    parentFun(){

        this.parenFun.emit(this.showForm)
    }
   
}