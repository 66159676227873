import { Component, ViewChild ,ChangeDetectorRef } from '@angular/core';
import { ApiModulesService } from '../../api-modules.service';
import { AllModulesService } from "../../all-modules.service";

import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription, Subject, } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { SpinnerService } from 'src/app/snipper/spinner.service';
import { Table } from 'primeng/table';
declare const $: any;
@Component({
  selector: 'app-paygroups',
  templateUrl: './paygroups.component.html',
  styleUrls: ['./paygroups.component.css']
})
export class PaygroupsComponent {


  public paygroupData = [];
  public listHeaders = [];
  rows: any;
  public srch = [];
  search: any[];
  ClientDropDown = [];
  CompanyDropDown = [];


  public PayGroupForm: FormGroup
  public clientname = [];
  isDtInitialized: boolean = false;
  public totalRecords: any = 0;
  public ShowForm: any = false;
  searchKeyword: string = '';
  loading=false;
  public ShowPermissionForm: any = false;
  ClientID = localStorage.getItem('client_id');
  CompanyID = localStorage.getItem('company_id');
  nameBgColors:any = ["bg-blue-dim","bg-blue-dim","bg-azure-dim","bg-indigo-dim","bg-info-dim","bg-purple-dim","bg-pink-dim","bg-orange-dim","bg-teal-dim","bg-primary-dim","bg-success-dim","bg-warning-dim","bg-danger-dim","bg-secondary-dim","bg-dark-dim","bg-gray-dim","bg-blue-dim","bg-azure-dim","bg-indigo-dim","bg-info-dim","bg-purple-dim","bg-pink-dim","bg-orange-dim","bg-teal-dim","bg-primary-dim","bg-success-dim","bg-warning-dim","bg-danger-dim","bg-secondary-dim","bg-dark-dim","bg-gray-dim"];
  nameBorderColors:any = ["bg-outline-primary","bg-outline-success","bg-outline-info","bg-outline-gray","bg-outline-warning","bg-outline-dark","bg-outline-light","bg-outline-secondary","bg-outline-danger"];


  statuses: { label: string; value: string; }[];
  statusValues: any;
  statusApi: any;
  PayGroupId: any;
  constructor(private apiModulesService: ApiModulesService, private AllModulesService: AllModulesService, private toaster: ToastrService, private toastr: ToastrService, private formBuilder: FormBuilder, private spinnerService: SpinnerService, private cdr: ChangeDetectorRef,private fb: FormBuilder) { }

  ngOnInit(): void {
    this.spinnerService.raiseDataEmitterEvent('off');
    this.GetClientDropDown();
    this.PayGroupForm = this.formBuilder.group({
      pay_group_name: ["", [Validators.required]],
      payroll_date: ["", [Validators.required]],
      client_id: ["", []],
      company_id: ["", []],
      pay_group_description: ["", [Validators.required]],
      pay_group_id: ["", []]

    });

    this.statuses = [
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ];


  }

  showAddForm() {
    this.ShowForm = true;
    this.resetForm();
  }
  ngOnDestroy(): void {

  }

  //Get all paycomponent data
  public getPayGroup() {
    var params:any={};
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;

          this.apiModulesService.list("admin/paygroups/list", params).subscribe((data) => {

              this.paygroupData = (data.data.paygroup != undefined) ? data.data.paygroup : [];
              this.loading = false;
              var totalRecords = (this.paygroupData[0] != undefined) ? this.paygroupData[0].total_records : 0;
              this.totalRecords = totalRecords;
              this.resetForm();
              setTimeout(() => {

            }, 100);
          }, (err) => {
            this.paygroupData = [];

            // this.toastr.error('Something went wrong!', 'Try Again');
              // this.rerender();
          });
  }
  clear(table: Table) {
    table.clear();
  }

  getSeverity(status: string) {
    switch (status.toLowerCase()) {
      case 'inactive':
        return 'danger';

      case 'active':
        return 'success';
    }
  }

  changeStatus(Status: any, data) {
    let params: any = {};
    params.client_id = this.ClientID;
    params.pay_group_id = data.pay_group_id,
      params.company_id = this.CompanyID;

    this.apiModulesService.edit(params, 'admin/paygroups/' + Status).subscribe((data) => {
      this.getPayGroup();
      ($('#paygroup-status') as any).modal('hide');
      this.toaster.success('Status Changed Successfully!', 'Success')
    }, (err) => {
      let errors = err.error.data.paygroup;
      errors = errors.replace('SQLSTATE[42000]: [Microsoft][ODBC Driver 17 for SQL Server][SQL Server]', 'Error: ')
      errors = errors.replace('SQLSTATE[42000]: [Microsoft][ODBC Driver 18 for SQL Server][SQL Server]', 'Error: ')
      this.toastr.error(errors, 'TryAgain');
      }
    );
  }
  public onAddPayGroup() {
    if (!this.PayGroupForm.valid) {

      this.PayGroupForm.markAllAsTouched();
      return;
    }
    var params: any = {};
    params.company_id = this.CompanyID;
    params.client_id = this.ClientID;
    params.pay_group_name= this.PayGroupForm.get('pay_group_name').value;
    params.payroll_date = this.PayGroupForm.get('payroll_date').value;
    params.pay_group_description = this.PayGroupForm.get('pay_group_description').value;


    var pay_group_id = this.PayGroupForm.get('pay_group_id').value;

    if (pay_group_id != '' && pay_group_id != undefined) {
      params.pay_group_id = this.PayGroupForm.get('pay_group_id').value;
      this.apiModulesService.edit(params, "admin/paygroups/update",).subscribe((data) => {


        this.getPayGroup();
        this.toastr.success('Pay Group Modified Sucessfully...!', 'Success');
        ($('#add_pay_group') as any).modal('hide');
      }, (err) => {
        let errors = err.error.data.paygroup;
        errors = errors.replace('SQLSTATE[42000]: [Microsoft][ODBC Driver 17 for SQL Server][SQL Server]', 'Error: ')
        errors = errors.replace('SQLSTATE[42000]: [Microsoft][ODBC Driver 18 for SQL Server][SQL Server]', 'Error: ')
        this.toastr.error(errors, 'TryAgain');
        });

    } else {
      this.apiModulesService.add(params, "admin/paygroups/create",).subscribe((data) => {
        this.getPayGroup();
        this.toastr.success('Pay Group Added Sucessfully...!', 'Success');
        this.PayGroupForm.reset();
        ($('#add_pay_group') as any).modal('hide');
      }, (err) => {
        let errors = err.error.data.paygroup;
        errors = errors.replace('SQLSTATE[42000]: [Microsoft][ODBC Driver 17 for SQL Server][SQL Server]', 'Error: ')
        errors = errors.replace('SQLSTATE[42000]: [Microsoft][ODBC Driver 18 for SQL Server][SQL Server]', 'Error: ')
        this.toastr.error(errors, 'TryAgain');
        });
    }
  }
  //Reset form
  public resetForm() {
    this.PayGroupForm.reset();
  }
  public update(params) {
  this.PayGroupForm.setValue({
    pay_group_name: params.pay_group,
      payroll_date: params.payroll_date,
      pay_group_description: params.pay_group_desc,
      pay_group_id: params.pay_group_id,
      client_id: params.client_id,
      company_id: params.company_id
    });



  }
  // searchName(val) {
  //   this.rows.splice(0, this.rows.length);
  //   let temp = this.srch.filter(function (d) {
  //     val = val.toLowerCase();
  //     return d.pay_group_name.toLowerCase().indexOf(val) !== -1 || d.financial_year_status.toLowerCase().indexOf(val) !== -1 || d.payroll_date.toLowerCase().indexOf(val) !== -1 || d.pay_group_description.toLowerCase().indexOf(val) !== -1
  //   });
  //   this.rows.push(...temp);
  // }
  ngAfterViewInit(): void {

  }
  rerender() {

  }

 /* client DropDown codes*/
 public GetClientDropDown() {
  this.apiModulesService.get('DropDown/client').subscribe((data) => {
    this.ClientDropDown = data.data.client;

    this.ClientID = data.data.client[0].client_id;
    this.GetCompanyDropDown();
  })
}

  /* company DropDown codes */
  public GetCompanyDropDown() {
    const params: any = {};
    params.client_id = this.ClientID;

    this.apiModulesService.list('DropDown/company', params).subscribe((data) => {
      this.CompanyDropDown = data.data.company != undefined ? data.data.company : [];
      if (this.CompanyDropDown.length !== 0) {
        setTimeout(() => {
          this.CompanyID = this.CompanyDropDown[0].company_id;
          this.getPayGroup();

        }, 100)
      }
      else {
        this.CompanyID = null;
        this.rerender();
      }

    }
    )
  }
  getAcronym(str: any) {
    if (str != null) {
        let matches = str.match(/\b(\w)/g); // ['J','S','O','N']
        matches = matches.slice(0, 2);
        const acronym = matches.join(''); // JSON
        return acronym;
    }
}

getBgColors(str: any) {
    if (str != null) {
        const min = 0;
        const max = 15;
        const index = this.findAlphapositions(str, 1);
        return this.nameBgColors[index];
    }
}

findAlphapositions(str: any, n: any) {
    const NUM = 31;

    return str[0].charCodeAt(0) & NUM;
}
  callStatusModel(url_name:any,data:any){
    this.statusApi=url_name;
    this.statusValues=data;
}


}
