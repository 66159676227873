import { Component, OnInit ,ChangeDetectorRef, AfterViewChecked} from '@angular/core';
import { ApiModulesService } from '../../api-modules.service';
import { SpinnerService } from 'src/app/snipper/spinner.service';
import { Table } from 'primeng/table';
import {FormGroup, Validators,FormBuilder, FormArray} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-time-workingstatus',
  templateUrl: './time-workingstatus.component.html',
  styleUrls: ['./time-workingstatus.component.css']
})
export class TimeWorkingstatusComponent implements OnInit {

  // clientID = localStorage.getItem('client_id');
  // companyID = localStorage.getItem('company_id');
  ClientID :any;
  CompanyID :any;
  ClientDropDown = [];
  CompanyDropDown = [];
  timeWorkingStatusData =[];
  otherStatusData =[];
  nameBgColors: any = ["bg-blue-dim", "bg-blue-dim", "bg-azure-dim", "bg-indigo-dim", "bg-info-dim", "bg-purple-dim", "bg-pink-dim", "bg-orange-dim", "bg-teal-dim", "bg-primary-dim", "bg-success-dim", "bg-warning-dim", "bg-danger-dim", "bg-secondary-dim", "bg-dark-dim", "bg-gray-dim", "bg-blue-dim", "bg-azure-dim", "bg-indigo-dim", "bg-info-dim", "bg-purple-dim", "bg-pink-dim", "bg-orange-dim", "bg-teal-dim", "bg-primary-dim", "bg-success-dim", "bg-warning-dim", "bg-danger-dim", "bg-secondary-dim", "bg-dark-dim", "bg-gray-dim"];
  nameBorderColors: any = ["bg-outline-primary", "bg-outline-success", "bg-outline-info", "bg-outline-gray", "bg-outline-warning", "bg-outline-dark", "bg-outline-light", "bg-outline-secondary", "bg-outline-danger"];
  public addTimeForm: FormGroup

  constructor(private apiModulesService: ApiModulesService,
              private spinnerService: SpinnerService,
              private formBuilder: FormBuilder,
              private toastr: ToastrService,
              private cdr: ChangeDetectorRef,) {

            }

  ngOnInit(){
    this.spinnerService.raiseDataEmitterEvent('on');
    this.GetClientDropDown();
    this.addTimeForm = this.formBuilder.group({
      time_status: this.formBuilder.array([]),
    });


  }
  public getWorkingStatus() {
    this.spinnerService.raiseDataEmitterEvent('on');
    var params: any = {};
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    this.apiModulesService.list("admin/time/status/list", params).subscribe((data) => {
        this.timeWorkingStatusData = data.data.time_status !=undefined ? data.data.time_status.filter((val:any)=>val.is_show==1) : [];
        this.otherStatusData = data.data.time_status !=undefined ? data.data.time_status.filter((val:any)=>val.is_show==0) : [];
        this.resetForm();

      // } else {
      //   this.timeWorkingStatusData = [];
      //   this.otherStatusData = [];
      // }

      setTimeout(() => {
        this.spinnerService.raiseDataEmitterEvent('off');
      }, 1000);
    }, (err) => {

      this.spinnerService.toasterError(err);

    });
  }
  public createWorkingStatus() {
    if (!this.addTimeForm.valid) {
      this.addTimeForm.markAllAsTouched();
      return;
    }
    var params: any = {};
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    params.time_status =this.addTimeForm.get('time_status').value;
    this.apiModulesService.add(params,"admin/time/status/add",).subscribe((data) => {
      this.getWorkingStatus();
      this.toastr.success('Time Status Added Sucessfully...!', 'Success');
    }, (err) => {
      this.spinnerService.toasterError(err);
    });
  }
  public resetForm() {
    const timeStatusArray = this.addTimeForm.get('time_status') as FormArray;
    timeStatusArray.clear();
         this.timeWorkingStatusData.forEach((val,key)=>{
      this.addNewItems(val);
    })
  }
  get itemsList(): FormArray {
    return this.addTimeForm.get('time_status') as FormArray;
}
addOtherStatus(){
  this.otherStatusData.forEach((val,key)=>{
    this.addNewItems(val);
  })
}
createTimeStatus(control_value:any): FormGroup {
  return this.formBuilder.group({
    working_status_name: [control_value.working_status_name, []],
    minimum_hours: [control_value.minimum_hours, [Validators.required]],
    maximum_hours: [control_value.maximum_hours, [Validators.required]],
    working_status_id: [control_value.working_status_id, []],
    is_show: [control_value.is_show, []],
    time_status_id: [control_value.time_status_id, [Validators.required]]
  });
}
  addNewItems(value:any): void {
    this.itemsList.push(this.createTimeStatus(value));
  }
  removeItems(index: number): void {
    this.itemsList.removeAt(index);
  }
  /* client DropDown codes*/
 public GetClientDropDown() {
  this.apiModulesService.get('DropDown/client').subscribe((data) => {
    this.ClientDropDown = data.data.client;
    this.ClientID = data.data.client[0].client_id;
    this.GetCompanyDropDown();

  })
}

  /* company DropDown codes */
  public GetCompanyDropDown() {
    const params: any = {};
    params.client_id = this.ClientID;
    this.apiModulesService.list('DropDown/company', params).subscribe((data) => {
      this.CompanyDropDown = data.data.company != undefined ? data.data.company : [];
      if (this.CompanyDropDown.length !== 0) {
        setTimeout(() => {
          this.CompanyID = this.CompanyDropDown[0].company_id;
          this.getWorkingStatus();
        }, 100)
      }
      else {
        this.CompanyID = null;

      }

    }
    )
  }
  ngAfterViewChecked() {
    this.cdr.detectChanges();
}
  clear(table: Table) {
    table.clear();
  }
  getSeverity(status: string) {
    switch (status.toLowerCase()) {
      case 'inactive':
        return 'danger';

      case 'active':
        return 'success';
    }
  }
  getAcronym(str: any) {
    var matches = str.match(/\b(\w)/g); // ['J','S','O','N']
    matches = matches.slice(0, 2);
    var acronym = matches.join(''); // JSON
    return acronym
  }
  getBgColors(str: any) {
    var min = 0;
    var max = 15;
    var index = this.findAlphapositions(str, 1);
    return this.nameBgColors[index];
  }
  findAlphapositions(str: any, n: any) {
    var NUM = 31;

    return str[0].charCodeAt(0) & NUM;
  }
}
