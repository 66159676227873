import { Component, OnInit,AfterViewInit,OnDestroy,Input,EventEmitter,Output } from '@angular/core';
import{ApiModulesService} from '../../../api-modules.service';
import{AllModulesService} from '../../../all-modules.service';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription, Subject, } from 'rxjs';
import { Table } from 'primeng/table';
import { SpinnerService } from 'src/app/snipper/spinner.service';
declare const $: any;

@Component({
  selector: 'app-company-leave-plan-type',
  templateUrl: './company-leave-plan-type.component.html',
  styleUrls: ['./company-leave-plan-type.component.css']
})
export class CompanyLeavePlanTypeComponent implements OnInit, OnDestroy, AfterViewInit {
    public leavedata = [];
    public listHeaders = [];
    rows: any;
    public activeIndex = 0;
    public srch = [];
    search: any[];
    loading=false;
    tabToGrid=false;
    ClientDropDown = [];
    CompanyDropDown = [];
    showTypeDropDown = [];
    public addLeaveForm: FormGroup
    public clientname = [];
    public totalRecords: any = 0;
    public ShowForm: any = false;
    ClientID :any;
    CompanyID :any;
    nameBgColors:any = ["bg-blue-dim","bg-blue-dim","bg-azure-dim","bg-indigo-dim","bg-info-dim","bg-purple-dim","bg-pink-dim","bg-orange-dim","bg-teal-dim","bg-primary-dim","bg-success-dim","bg-warning-dim","bg-danger-dim","bg-secondary-dim","bg-dark-dim","bg-gray-dim","bg-blue-dim","bg-azure-dim","bg-indigo-dim","bg-info-dim","bg-purple-dim","bg-pink-dim","bg-orange-dim","bg-teal-dim","bg-primary-dim","bg-success-dim","bg-warning-dim","bg-danger-dim","bg-secondary-dim","bg-dark-dim","bg-gray-dim"];
    nameBorderColors:any = ["bg-outline-primary","bg-outline-success","bg-outline-info","bg-outline-gray","bg-outline-warning","bg-outline-dark","bg-outline-light","bg-outline-secondary","bg-outline-danger"];
    public days = [];
    editedLeaveData: any;
    showTabs = false;
    statuses: { label: string; value: string; }[];
    constructor(private apiModulesService: ApiModulesService, private AllModulesService: AllModulesService, private toaster: ToastrService, private toastr: ToastrService, private formBuilder: FormBuilder,private spinnerService: SpinnerService) { }

    ngOnInit(): void {

        this.GetClientDropDown();
        this.addLeaveForm = this.formBuilder.group({
            plan_type_name: ["", [Validators.required]],
            plan_type_desc: ["", [Validators.required]],
            client_id: ["", []],
            company_id: ["", []],
            plan_type_short: ["", [Validators.required]],
            plan_show_type: ["", [Validators.required]],
            leave_plan_type_id: ["", []],
        });
        this.statuses = [
            { label: 'Active', value: 'active' },
            { label: 'Inactive', value: 'inactive' },
        ];
    }

    showAddForm() {
        this.ShowForm = true;
        this.resetForm();
    }
    ngOnDestroy(): void {

    }

    //Get all paycomponent data
    public getLeave() {
        this.spinnerService.raiseDataEmitterEvent('off');
        this.ShowForm = false;
        var params:any={};
        params.client_id = this.ClientID;
        params.company_id = this.CompanyID;

        this.apiModulesService.list("admin/leavemanagement/company/leave-plan-type/list", params).subscribe((data) => {

            this.leavedata = (data.data.leaves != undefined) ? data.data.leaves : [];
            this.showTypeDropDown = (data.data.dropDown != undefined) ? data.data.dropDown : [];
            this.loading = false;
            var totalRecords = (this.leavedata[0] != undefined) ? this.leavedata[0].total_records : 0;
            this.totalRecords = totalRecords;
            this.resetForm();
            setTimeout(() => {
                this.spinnerService.raiseDataEmitterEvent('off');
            }, 100);
        }, (err) => {
            this.leavedata = [];
            this.spinnerService.raiseDataEmitterEvent('off');
        });
    }
    clear(table: Table) {
        table.clear();
    }

    getSeverity(status: string) {
        switch (status.toLowerCase()) {
            case 'inactive':
                return 'danger';

            case 'active':
                return 'success';
        }
    }
    /* client DropDown codes*/
    public GetClientDropDown() {
        this.apiModulesService.get('DropDown/client').subscribe((data) => {
            this.ClientDropDown = data.data.client;
            this.ClientID = data.data.client[0].client_id;
            this.GetCompanyDropDown();

        })
    }

    /* company DropDown codes */
    public GetCompanyDropDown() {
        const params: any = {};
        params.client_id = this.ClientID;

        this.apiModulesService.list('DropDown/company', params).subscribe((data) => {
                this.CompanyDropDown = data.data.company != undefined ? data.data.company : [];
                if (this.CompanyDropDown.length !== 0) {
                    setTimeout(() => {
                        this.CompanyID = this.CompanyDropDown[0].company_id;
                        this.dropDown();
                        this.getLeave();
                    }, 100)
                }
                else {
                    this.CompanyID = null;
                    this.rerender();
                }

            }
        )
    }
    rerender() {

    }
    changeStatus(Status: any, data) {
        let params: any = {};
        params.leave_plan_type_id = data.company_leave_plan_type_id,
         params.client_id = this.ClientID;
        params.company_id = this.CompanyID;


        this.apiModulesService.edit(params, 'admin/leavemanagement/company/leave-plan-type/' + Status).subscribe((data) => {
                this.getLeave();
                this.toaster.success('Status Changed Successfully!', 'Success')
            }, (err) => {
                this.toastr.error("Something went wrong!", "Try Again");
            }
        );
    }
    public onAddLeavePlan() {
        if (!this.addLeaveForm.valid) {

            this.addLeaveForm.markAllAsTouched();
            return;
        }
        var params: any = {};
        params.client_id = this.ClientID;
        params.company_id = this.CompanyID;
        params.plan_type_name = this.addLeaveForm.get('plan_type_name').value;
        params.plan_type_desc = this.addLeaveForm.get('plan_type_desc').value;
        params.plan_type_short = this.addLeaveForm.get('plan_type_short').value;
        params.plan_show_type = this.addLeaveForm.get('plan_show_type').value;

        var leave_plan_type_id = this.addLeaveForm.get('leave_plan_type_id').value;

        if (leave_plan_type_id != '' && leave_plan_type_id != undefined) {
            params.leave_plan_type_id = this.addLeaveForm.get('leave_plan_type_id').value;
            this.apiModulesService.edit(params, "admin/leavemanagement/company/leave-plan-type/update",).subscribe((data) => {


                this.getLeave();
                this.toastr.success('Leave Modified Sucessfully...!', 'Success');
                ($('#add_company_leave_plan') as any).modal('hide');
            }, err => {
                if (err.status === 437) {
                    this.toastr.error('Leave Already Exists...!', 'Failed');
                } else {
                    this.toastr.error('Something Went Wrong Please Try Again...!', 'Error');
                }
            });

        } else {
            this.apiModulesService.add(params, "admin/leavemanagement/company/leave-plan-type/create",).subscribe((data) => {
                this.getLeave();
                this.toastr.success('Leave Added Sucessfully...!', 'Success');
                this.addLeaveForm.reset();
                ($('#add_company_leave_plan') as any).modal('hide');
            }, err => {
                if (err.status === 437) {
                    this.toastr.error('Leave Already Exists...!', 'Failed');
                } else {
                    this.toastr.error('Something Went Wrong Please Try Again...!', 'Error');
                }
            });
        }
    }
    //Reset form
    public resetForm() {
        this.addLeaveForm.reset();
    }
    public editplan(leaves: any) {
        this.resetForm();
        const params: any = {};
        params.client_id = this.ClientID;
        params.company_id = this.CompanyID;
        params.leave_plan_type_id = leaves.company_leave_plan_type_id;


        this.apiModulesService.edit(params, 'admin/leavemanagement/company/leave-plan-type/edit').subscribe((data) => {

                const leaveDetail = (data.data.leaves !== undefined) ? data.data.leaves : [];
                this.addLeaveForm.setValue({
                    plan_type_name: leaveDetail.plan_type_name,
                    plan_type_desc: leaveDetail.plan_type_desc,
                    plan_type_short: leaveDetail.plan_type_short,
                    leave_plan_type_id: leaveDetail.company_leave_plan_type_id,
                    client_id: leaveDetail.client_id,
                    company_id: leaveDetail.company_id,
                    plan_show_type: leaveDetail.plan_show_type
                });

            }, (err) => {
                this.toastr.error('Something went wrong!', 'Try Again');
            }
        );
    }
    ngAfterViewInit(): void {

    }

    // dropdown
    public dropDown(){
        const params: any = {};
        params.client_id = this.ClientID;
        params.company_id = this.CompanyID;
        this.apiModulesService.list("admin/leavemanagement/dropDown", params).subscribe((data)=>{
            this.days = data.data.leaves
        })
    }

    getAcronym(str: any) {
        if (str != null) {
            let matches = str.match(/\b(\w)/g); // ['J','S','O','N']
            matches = matches.slice(0, 2);
            const acronym = matches.join(''); // JSON
            return acronym;
        }
    }

    getBgColors(str: any) {
        if (str != null) {
            const min = 0;
            const max = 15;
            const index = this.findAlphapositions(str, 1);
            return this.nameBgColors[index];
        }
    }
    findAlphapositions(str:any, n:any)
    {
        var NUM = 31;

        return str[0].charCodeAt(0) & NUM;
    }
}
