import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AllModulesService} from '../../all-modules.service';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {DatePipe} from '@angular/common';
import {ToastrService} from 'ngx-toastr';
import {ApiModulesService} from '../../api-modules.service';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {documentservice} from '../../documents.sevice';
import{SpinnerService} from '../../../snipper/spinner.service';
import { Table } from 'primeng/table';

declare const $: any;

@Component({
    selector: 'app-clients-list',
    templateUrl: './clients-list.component.html',
    styleUrls: ['./clients-list.component.css'],
})
export class ClientsListComponent implements OnInit, OnDestroy, AfterViewInit {

    public clientsData = [];
    public listHeaders = [
        {
            AliasName: "Name",
            FieldName: "Name"
        }, {
            AliasName: "Country",
            FieldName: "Country"
        }, {
            AliasName: "phone",
            FieldName: "Mobile"
        }, {
            AliasName: "Email",
            FieldName: "Email"
        }
    ];
    public editedClient;
    public addClientForm: FormGroup;
    public addgroupForm: FormGroup;
    public editClientForm: FormGroup;
    public searchForm: FormGroup;
    public tempId: any;
    statusApi:any;
    statusValues:any;
    public countriesList = [];
    public statesList = [];
    public citiesList = [];
    public dropdownname = [];
    public pageNo = 1;
    public limit = 10;
    searchKeyword: string = '';
    public ShowForm: any = false;
    public totalRecords: any = 0;
    searchByColumn: any = '';
    searchValue: any = '';
    emailRegex =
        '^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$';
    mobileRegex = '^((\\+91-?)|0)?[0-9]{10,13}$';


    public rows = [];
    public srch = [];
    public statusValue;

    public pipe = new DatePipe('en-US');
    groupdata: any;
    filedata: any;
    oldPath: any;
    awsimages: any;
    nameBgColors:any = ["bg-blue-dim","bg-blue-dim","bg-azure-dim","bg-indigo-dim","bg-info-dim","bg-purple-dim","bg-pink-dim","bg-orange-dim","bg-teal-dim","bg-primary-dim","bg-success-dim","bg-warning-dim","bg-danger-dim","bg-secondary-dim","bg-dark-dim","bg-gray-dim","bg-blue-dim","bg-azure-dim","bg-indigo-dim","bg-info-dim","bg-purple-dim","bg-pink-dim","bg-orange-dim","bg-teal-dim","bg-primary-dim","bg-success-dim","bg-warning-dim","bg-danger-dim","bg-secondary-dim","bg-dark-dim","bg-gray-dim"];
    nameBorderColors:any = ["bg-outline-primary","bg-outline-success","bg-outline-info","bg-outline-gray","bg-outline-warning","bg-outline-dark","bg-outline-light","bg-outline-secondary","bg-outline-danger"];
    statuses=[];
    loading: boolean = false;
    constructor(
        private allModulesService: AllModulesService,
        private apiModuleService: ApiModulesService,
        private documentService: documentservice,
        private toastr: ToastrService,
        private formBuilder: FormBuilder,
        private spinnerService: SpinnerService,
    ) {
    }

    ngOnInit() {
        this.spinnerService.raiseDataEmitterEvent('on');
        const that = this;

        that.getClients();
        this.getDropDown();
        this.dropDownGroup();
        this.getCountries();
        // Add clients form
        this.addClientForm = this.formBuilder.group({
            client_name: ['', [Validators.required]],
            mobile: ['', [Validators.required, Validators.pattern('[0-9]{10}')]],
            email_id: ['', [Validators.required, Validators.pattern(this.emailRegex)]],
            country_id: ['', [Validators.required]],
            state_id: ['', [Validators.required]],
            city_id: ['', [Validators.required]],
            address_1: ['', [Validators.required]],
            address_2: ['', []],
            pincode: ['', [Validators.required]],
            website: ['', []],
            client_id: ['', []],
            group_id: ['', [Validators.required]],
            profile_img_url: ['', []]
        });
        this.searchForm = this.formBuilder.group({
            column_name: ['', [Validators.required]],
            search_value: ['', [Validators.required]]
        })
         this.statuses = [
          { label: 'Active', value: 'active' },
          { label: 'Inactive', value: 'inactive' },
      ];
        // Edit Clients Form
        this.editClientForm = this.formBuilder.group({
            editClientName: ['', [Validators.required]],
            editClientPhone: ['', [Validators.required]],
            editClientEmail: ['', [Validators.required]],
            editClientCompany: ['', [Validators.required]],
            editClientRole: ['', [Validators.required]],
            editClientId: ['', [Validators.required]],
            editId: ['', [Validators.required]],
        });
        //  add group Form
        this.addgroupForm = this.formBuilder.group({
            description: ['', [Validators.required]],
            connection_id: ['', [Validators.required]],
            group_name: ['', [Validators.required]]
        });
    }

    searchResetForm() {
        this.searchForm.reset();
        this.searchByColumn = '';
        this.searchValue = '';
        if (this.searchForm.value.column_name != '' || this.searchForm.value.search_value != '') {
            this.getClients();
        }
    }

    ngAfterViewInit(): void {

    }

​

    public getClientsBySearch() {
        if (!this.searchForm.valid) {
            this.searchForm.markAllAsTouched();
            return;
        }

        // let table= $('#datatable').DataTable().clear().draw(true);
        // table.data()

        this.getClients();


    }

    // Function to handle the input event for phone number field
    onPhoneNumberInput(event: Event) {
        const inputElement = event.target as HTMLInputElement;
        const phoneNumber = inputElement.value.replace(/\D/g, ''); // Remove all non-digit characters
        const truncatedPhoneNumber = phoneNumber.slice(0, 10); // Truncate to 10 digits if longer
        inputElement.value = truncatedPhoneNumber; // Update the input field with the cleaned phone number
        this.addClientForm.get('mobile').setValue(truncatedPhoneNumber); // Update the form control value
    }

    //  Get all Clients data
    public getClients() {
        this.spinnerService.raiseDataEmitterEvent('on');
        this.ShowForm = false;
        const params: any = {};
        params.page_no = this.pageNo;
        params.records = this.limit;
        params.search_by = this.searchByColumn.toString();
        params.search_value = this.searchValue.toString();
        this.apiModuleService.list("admin/client/list", params).subscribe((data) => {
            this.clientsData = (data.data.clients != undefined) ? data.data.clients : [];
            this.loading = false;
            var totalRecords = (this.clientsData[0] != undefined) ? this.clientsData[0].total_records : 0;
            this.totalRecords = totalRecords;
            this.spinnerService.raiseDataEmitterEvent('off');
        },(err:any)=>{
            this.spinnerService.raiseDataEmitterEvent('off');
            let errors = err.error.data.clients;
            this.clientsData=[];
            errors = errors.replace('SQLSTATE[42000]: [Microsoft][ODBC Driver 17 for SQL Server][SQL Server]', 'Error: ')
            this.toastr.error(errors, 'TryAgain');
        });

    }

    public getAwsimages() {

        this.apiModuleService.get('admin/client/getimage').subscribe((data) => {
            // this.postResponse = res;
            this.awsimages = 'data:image/png;base64,' + data.data.clients.image;

        });
    }

    public getCountries() {

        this.apiModuleService.get('country/list').subscribe((data) => {
            const res: any = data;
            this.countriesList = (res.data.countries !== undefined) ? res.data.countries : [];
        });
    }

    public getStates() {
        const params: any = {};
        params.country_id = this.addClientForm.get('country_id').value;
        this.apiModuleService.list('country/states', params).subscribe((data) => {
            this.statesList = (data.data.states !== undefined) ? data.data.states : [];
        });
    }

    public getCities() {
        const params: any = {};
        params.state_id = this.addClientForm.get('state_id').value;
        this.apiModuleService.list('country/cities', params).subscribe((data) => {
            this.citiesList = (data.data.states !== undefined) ? data.data.states : [];

        });
    }

    public syncData() {
        const params: any = {};
        this.apiModuleService.get('datasync/syncer').subscribe((data) => {

        });
        alert('sucessfully sync');
    }

    showAddForm() {
        this.ShowForm = true;
        this.resetForm();
    }

    showList() {
        this.ShowForm = false;
    }

    // Edit client
    public onEditClient(clientId: any) {
        const params: any = {};
        params.client_id = clientId;
        this.apiModuleService.edit(params, 'admin/client/edit').subscribe((data) => {
            const clientDetail = (data.data !== undefined) ? data.data.clients : [];
            this.addClientForm.setValue({
                client_name: clientDetail.client_name,
                mobile: clientDetail.mobile,
                email_id: clientDetail.email_id,
                country_id: clientDetail.country_id,
                state_id: clientDetail.state_id,
                city_id: clientDetail.city_id,
                address_1: clientDetail.address_1,
                address_2: clientDetail.address_2,
                pincode: clientDetail.pincode,
                website: clientDetail.website,
                client_id: clientId,
                group_id: clientDetail.group_id,
                profile_img_url: null
            });
            this.oldPath = clientDetail.profile_img_url
            this.getStates();
            this.getCities();
            this.ShowForm = true;
        },(err:any)=>{
            let errors = err.error.data.clients;
            errors = errors.replace('SQLSTATE[42000]: [Microsoft][ODBC Driver 17 for SQL Server][SQL Server]', 'Error: ')
            this.toastr.error(errors, 'TryAgain');
        });

    }

    // Reset form
    public resetForm() {
        this.addClientForm.reset();
    }


    /* File onchange event */
    fileEvent(e) {
        this.filedata = e.target.files[0];
    }

    // Add new client
    public onAddClient() {
        if (!this.addClientForm.valid) {
            this.addClientForm.markAllAsTouched();
            return;
        }

        var myFormData = new FormData();

        myFormData.append('profile_img_url', this.filedata);
        myFormData.append('client_name', this.addClientForm.get('client_name').value);
        myFormData.append('country_id', this.addClientForm.get('country_id').value);
        myFormData.append('state_id', this.addClientForm.get('state_id').value);
        myFormData.append('city_id', this.addClientForm.get('city_id').value);
        myFormData.append('address_1', this.addClientForm.get('address_1').value);
        myFormData.append('address_2', this.addClientForm.get('address_2').value);
        myFormData.append('pincode', this.addClientForm.get('pincode').value);
        myFormData.append('email_id', this.addClientForm.get('email_id').value);
        myFormData.append('mobile', this.addClientForm.get('mobile').value);
        myFormData.append('website', this.addClientForm.get('website').value);
        myFormData.append('group_id', this.addClientForm.get('group_id').value);


        var client_id = this.addClientForm.get('client_id').value;
        if (client_id != undefined && client_id != '') {
            myFormData.append('client_id', this.addClientForm.get('client_id').value);
            if (this.filedata != null && this.filedata != '') {
                myFormData.append('old_path', this.oldPath);
            }

            this.documentService.post('api/admin/client/update', myFormData).subscribe((data) => {
                    this.getClients();

                    $('#add_client').modal('hide');
                    this.addClientForm.reset();
                    this.toastr.success('Client updated sucessfully...!', 'Success');
                }, (err) => {

                let errors = err.error.data.clients;
                errors = errors.replace('SQLSTATE[42000]: [Microsoft][ODBC Driver 17 for SQL Server][SQL Server]', 'Error: ')
                this.toastr.error(errors, 'TryAgain');
                }
            );
        }
        else {
            this.documentService.post('api/admin/client/add', myFormData,).subscribe((data) => {
                    this.getClients();

                    $('#add_client').modal('hide');
                    this.addClientForm.reset();
                    this.toastr.success('Client added sucessfully...!', 'Success');
                }, (err) => {
                let errors = err.error.data.clients;
                errors = errors.replace('SQLSTATE[42000]: [Microsoft][ODBC Driver 17 for SQL Server][SQL Server]', 'Error: ')
                this.toastr.error(errors, 'TryAgain');
                }
            );
        }
    }


    // public onAddClient() {
    //   if (!this.addClientForm.valid) {
    //     this.addClientForm.markAllAsTouched();
    //     return;
    //   }
    //   const params:any = {};
    //   params.client_name = this.addClientForm.get('client_name').value;
    //   params.country_id = this.addClientForm.get('country_id').value;
    //   params.state_id = this.addClientForm.get('state_id').value;
    //   params.city_id = this.addClientForm.get('city_id').value;
    //   params.address_1 = this.addClientForm.get('address_1').value;
    //   params.address_2 = this.addClientForm.get('address_2').value;
    //   params.pincode = this.addClientForm.get('pincode').value;
    //   params.email_id = this.addClientForm.get('email_id').value;
    //   params.mobile = this.addClientForm.get('mobile').value;
    //   params.website = this.addClientForm.get('website').value;
    //   params.group_id=this.addClientForm.get('group_id').value
    //   const clientId = this.addClientForm.get('client_id').value;
    //   if(clientId !== undefined && clientId !== ''){
    //     params.client_id = this.addClientForm.get('client_id').value;
    //     this.apiModuleService.add(params,'admin/client/update').subscribe((data) => {
    //       this.getClients('rerender',function(){});
    //       $('#add_client').modal('hide');
    //       this.addClientForm.reset();
    //       this.toastr.success('Client updated sucessfully...!', 'Success');
    //     },(err)=>{
    //       this.toastr.error('Something went wrong!', 'Try Again');
    //     }
    //     );
    //   }
    //   else{
    //     this.apiModuleService.add(params,'admin/client/add',).subscribe((data) => {
    //       this.getClients('rerender',function(){});
    //       $('#add_client').modal('hide');
    //       this.addClientForm.reset();
    //       this.toastr.success('Client added sucessfully...!', 'Success');
    //     },(err)=>{
    //       this.toastr.error('Something went wrong!', 'Try Again');
    //     }
    //     );
    //   }
    // }

    changeStatus(status: any, clientId: any) {
        const params: any = {};
        params.client_id = clientId;
        if(status==='activate'){
          params.stausClient='acivate';
        }
        this.apiModuleService.edit(params, 'admin/client/' + status).subscribe((data) => {
                this.getClients();
                ($('#client-status') as any).modal('hide');
                this.toastr.success('Client updated sucessfully...!', 'Success');
            }, (err) => {

            let errors = err.error.data.clients;
            errors = errors.replace('SQLSTATE[42000]: [Microsoft][ODBC Driver 17 for SQL Server][SQL Server]', 'Error: ');
            errors = errors.replace('SQLSTATE[42000]: [Microsoft][ODBC Driver 18 for SQL Server][SQL Server]', 'Error: ');
            this.toastr.error(errors, 'TryAgain');
            }
        );
    }

    // Delete Client
    onDelete(clientId: any) {
        const params: any = {};
        params.client_id = clientId;
        this.apiModuleService.edit(params, 'admin/client/delete').subscribe((data) => {
            this.getClients();
            this.toastr.success('Client deleted sucessfully...!', 'Success');
        });
    }

    // search by name
    searchID(val) {
        this.rows.splice(0, this.rows.length);
        const temp = this.srch.filter(function (d) {
            val = val.toLowerCase();
            return d.clientId.toLowerCase().indexOf(val) !== -1 || !val;
        });
        this.rows.push(...temp);
    }

    // search by name
    searchName(val) {
        this.rows.splice(0, this.rows.length);
        const temp = this.srch.filter(function (d) {
            val = val.toLowerCase();
            return d.name.toLowerCase().indexOf(val) !== -1 || !val;
        });
        this.rows.push(...temp);
    }

    // search by company
    searchCompany(val) {
        this.rows.splice(0, this.rows.length);
        const temp = this.srch.filter(function (d) {
            val = val.toLowerCase();
            return d.company.toLowerCase().indexOf(val) !== -1 || !val;
        });
        this.rows.push(...temp);
    }

    ngOnDestroy(): void {
        // Do not forget to unsubscribe the event

    }

    getDropDown() {
        const params: any = {};
        params.login_user_id = 1;
        this.apiModuleService.list('admin/client/dropdown', params).subscribe((data) => {
            this.dropdownname = data.data.clients;
        });
    }

    // add data
    public onAddgroup() {
        if (this.addgroupForm.invalid) {
            this.addgroupForm.markAllAsTouched()
            return
        }
        const params: any = {};
        params.description = this.addgroupForm.get('description').value;
        params.connection_id = this.addgroupForm.get('connection_id').value;
        params.group_name = this.addgroupForm.get('group_name').value;
        this.apiModuleService.add(params, 'admin/client/addgroup',).subscribe((data) => {
                ($('#add_group')as any).modal('hide');
                this.dropDownGroup();
                this.addgroupForm.reset();
                this.toastr.success('Group  added sucessfully...!', 'Success');
            }, (err) => {
            let errors = err.error.data.clients;
            errors = errors.replace('SQLSTATE[42000]: [Microsoft][ODBC Driver 17 for SQL Server][SQL Server]', 'Error: ')
            this.toastr.error(errors, 'TryAgain');
            }
        );
    }

    dropDownGroup() {
        this.apiModuleService.get('admin/client/dropdowngroup').subscribe((data) => {
            this.groupdata = data.data.clients;
        })
    }
    clear(table: Table) {
        table.clear();
    }

    getSeverity(status: string) {
        switch (status.toLowerCase()) {
            case 'inactive':
                return 'danger';

            case 'active':
                return 'success';
        }
    }

    getAcronym(str:any){
        var matches = str.match(/\b(\w)/g); // ['J','S','O','N']
        matches= matches.slice(0,2);
        var acronym = matches.join(''); // JSON
        return acronym;
    }
    getBgColors(str:any){
        var min = 0;
        var max = 15;
        var index = this.findAlphapositions(str,1);
        return this.nameBgColors[index];
    }
    findAlphapositions(str:any, n:any)
    {
        var NUM = 31;

        return str[0].charCodeAt(0) & NUM;
    }
    callStatusModel(url_name:any,data:any,actionName:string){
        this.statusApi=url_name;
        this.statusValues=data;
    }
}


function callback(arg0: { recordsTotal: any; recordsFiltered: any; data: undefined[]; }) {
    throw new Error('Function not implemented.');
}




