import { Component, OnInit } from '@angular/core';
import { ApiModulesService } from 'src/app/all-modules/api-modules.service';
import { SpinnerService } from 'src/app/snipper/spinner.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-todaytimedetails',
  templateUrl: './todaytimedetails.component.html',
  styleUrls: ['./todaytimedetails.component.css']
})
export class TodaytimedetailsComponent implements OnInit {

  clientID = localStorage.getItem('client_id');
  companyID = localStorage.getItem('company_id');
  employeeID = localStorage.getItem('employee_id');
  employeeTimedetails: any;

  constructor(private spinnerService: SpinnerService, private apiModuleService: ApiModulesService,
    private toaster: ToastrService) {}

  ngOnInit() {
    this.spinnerService.raiseDataEmitterEvent('on');
    this.todayEmployeeTimeViewData();
  }
  todayEmployeeTimeViewData() {
    this.spinnerService.raiseDataEmitterEvent('on');
    const params: any = {};
      params.client_id = this.clientID,
      params.company_id = this.companyID,
      params.employee_id = this.employeeID,
      this.apiModuleService.list('employee-tabs/employee-hrm/manager-time/today', params).subscribe((data: any) => {
        this.employeeTimedetails = data.data.today_time !== undefined ? data.data.today_time : [];
        this.spinnerService.raiseDataEmitterEvent('off');
      }, (err: any) => {
        this.spinnerService.toasterError(err);
      });
  }
}
