import {Component, OnInit, ViewChild, AfterViewInit, OnDestroy} from '@angular/core';
import {SpinnerService} from 'src/app/snipper/spinner.service';
import {ApiModulesService} from 'src/app/all-modules/api-modules.service';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {Table} from 'primeng/table';
import {EmployeeDropdownService} from "../../../azatecon-services/employee-dropdown.service";

@Component({
    selector: 'app-shift-assign',
    templateUrl: './shift-assign.component.html',
    styleUrls: ['./shift-assign.component.css']
})
export class ShiftAssignComponent implements OnInit {

    ClientDropDown = [];
    CompanyDropDown = [];
    ClientID: any;
    CompanyID: any;
    loading = false;
    nameBgColors: any = ["bg-blue-dim", "bg-blue-dim", "bg-azure-dim", "bg-indigo-dim", "bg-info-dim", "bg-purple-dim", "bg-pink-dim", "bg-orange-dim", "bg-teal-dim", "bg-primary-dim", "bg-success-dim", "bg-warning-dim", "bg-danger-dim", "bg-secondary-dim", "bg-dark-dim", "bg-gray-dim", "bg-blue-dim", "bg-azure-dim", "bg-indigo-dim", "bg-info-dim", "bg-purple-dim", "bg-pink-dim", "bg-orange-dim", "bg-teal-dim", "bg-primary-dim", "bg-success-dim", "bg-warning-dim", "bg-danger-dim", "bg-secondary-dim", "bg-dark-dim", "bg-gray-dim"];
    nameBorderColors: any = ["bg-outline-primary", "bg-outline-success", "bg-outline-info", "bg-outline-gray", "bg-outline-warning", "bg-outline-dark", "bg-outline-light", "bg-outline-secondary", "bg-outline-danger"];
    statuses: { label: string; value: string; }[];
    statusApi: any;
    statusValues: any;
    public totalRecords: any = 0;
    public ShowForm: any = false;
    public shiftAssignData = [];
    public month: any;
    public year: any;
    public addShiftAssignForm: FormGroup;
    shiftAssignDropdown = [];
    shiftEmployeeDropdown = [];
    shiftEmployeeData = [];
    monthYearDropdown = [];
    employeeAllData = [];
    employeeAllFilterData = [];
    employeeAssignedData = [];
    employeeAssignedFilterData = [];
    chooseEmployeeAll = [];
    removeEmployee = [];
    departmentDropDown: any = [];
    designationDropDown = [];

    constructor(private spinnerService: SpinnerService,
                private apiModulesService: ApiModulesService,
                private formBuilder: FormBuilder,
                private toaster: ToastrService,
                private toastr: ToastrService,
                private employeeService: EmployeeDropdownService) {
    }

    ngOnInit(): void {
        this.spinnerService.raiseDataEmitterEvent('off');
        this.GetClientDropDown();
        this.addShiftAssignForm = this.formBuilder.group({
            month_shift_id: ["", [Validators.required]],
            month_year: ["", [Validators.required]],
            department_id: ["", [Validators.required]],
            designation_id: ["", [Validators.required]],
            chooseEmployeeAll: [""],
            removeEmployee: [""],
            shift_assign_id: [""]
        });


        this.statuses = [
            {label: 'Active', value: 'active'},
            {label: 'Inactive', value: 'inactive'},
        ];
    }

    rerender() {


    }

    /* client DropDown codes*/
    public GetClientDropDown() {
        this.apiModulesService.get('DropDown/client').subscribe((data) => {
            this.ClientDropDown = data.data.client;

            this.ClientID = data.data.client[0].client_id;
            this.GetCompanyDropDown();

        })
    }

    /* company DropDown codes */
    public GetCompanyDropDown() {
        const params: any = {};
        params.client_id = this.ClientID;
        // //
        this.apiModulesService.list('DropDown/company', params).subscribe((data) => {
                this.CompanyDropDown = data.data.company != undefined ? data.data.company : [];
                if (this.CompanyDropDown.length !== 0) {
                    setTimeout(() => {
                        this.CompanyID = this.CompanyDropDown[0].company_id;
                        this.getShiftAssign();
                        this.getdropDownShifts();
                        let promise = new Promise((resolve, reject) => {
                            let overallDropDown: any = this.employeeService.OverallDropDownData(this.ClientID, this.CompanyID);
                            resolve(overallDropDown);
                            reject([]);
                        });
                        promise.then((result) => {
                            this.departmentDropDown = result
                            return result
                        }).catch((response) => {
                            return [];
                        });

                    }, 100);
                }
                else {
                    this.CompanyID = null;
                    this.rerender();
                }

            }
        )
    }

    getDesignationDropDown() {
        if (this.addShiftAssignForm.get('department_id').value != null && this.addShiftAssignForm.get('department_id').value != '') {
            this.designationDropDown = this.employeeService.designationDropDown(this.addShiftAssignForm.get('department_id').value)
        }
    }

    getEmployeeDropDown() {
        if ((this.addShiftAssignForm.get('department_id').value != null && this.addShiftAssignForm.get('department_id').value != '') && (this.addShiftAssignForm.get('designation_id').value != null && this.addShiftAssignForm.get('designation_id').value != '')) {
            const employeeData = this.employeeService.employeeDropDown(this.addShiftAssignForm.get('department_id').value, this.addShiftAssignForm.get('designation_id').value);
            if (this.shiftEmployeeDropdown.length != 0) {
                let filterEmployee = [];
                this.shiftEmployeeDropdown.forEach((value, key, index: any) => {

                    if (key == 0) {
                        filterEmployee = employeeData.filter((val: any) => val.employee_id != value.employee_id)
                    } else {
                        filterEmployee = filterEmployee.filter((val: any) => val.employee_id != value.employee_id)

                    }
                });
                this.employeeAllFilterData = filterEmployee;
                this.employeeAllData = filterEmployee;
            } else {
                this.employeeAllFilterData = employeeData;
                this.employeeAllData = employeeData;
            }
        } else {
            this.employeeAllFilterData = [];
            this.employeeAllData = [];
        }
        this.employeeAssignedFunction();
    }

//Get all shift assign data
    public getShiftAssign() {
        this.spinnerService.raiseDataEmitterEvent('on');
        if (this.CompanyDropDown.length !== 0 && this.CompanyID != null) {
            this.ShowForm = false;
            var params: any = {};
            params.client_id = this.ClientID;
            params.company_id = this.CompanyID;
            this.apiModulesService.list("admin/shift_assign/getlist", params).subscribe((data) => {
                this.shiftAssignData = (data.data.shift_assign != undefined) ? data.data.shift_assign : [];
                this.loading = false;
                var totalRecords = this.shiftAssignData.length;
                this.totalRecords = totalRecords;
                this.resetForm();
                setTimeout(() => {
                    this.spinnerService.raiseDataEmitterEvent('off');
                }, 100);
            }, (err) => {
                this.shiftAssignData = [];
                this.spinnerService.raiseDataEmitterEvent('off');

            });
        } else {
            this.rerender();
        }

    }

    callEmployeeFunction() {

        const length = this.chooseEmployeeAll.length;
        if (length !== 0) {
            let assignedData: any = [];
            for (let i = 0; i < length; i++) {
                const filterAllData: any = this.employeeAllData.filter((data: any) => data.employee_id === this.chooseEmployeeAll[i])

                if (i === 0) {
                    let fillArrayName = 'employeeAllData';
                    if (this.employeeAssignedFilterData.length == 0) {
                        fillArrayName = 'employeeAllData';
                    } else {
                        fillArrayName = 'employeeAllFilterData';
                    }
                    const filterAssign: any = this[fillArrayName].filter((data: any) => data.employee_id !== this.chooseEmployeeAll[i])
                    assignedData = filterAssign
                } else {
                    const filterAssigned: any = assignedData.filter((data: any) => data.employee_id !== this.chooseEmployeeAll[i])
                    assignedData = filterAssigned
                }
                this.employeeAssignedFilterData.push(filterAllData[0]);

            }
            // this.employeeAssignedData;
            this.employeeAllFilterData = assignedData;
            this.addShiftAssignForm.patchValue({
                removeEmployee: "",
                chooseEmployeeAll: ""

            })
        }
        this.chooseEmployeeAll = [];
        this.removeEmployee = [];
    }

    removeEmployeeFunction() {
        const length = this.removeEmployee.length;

        if (length !== 0) {
            let assignedData: any = [];
            for (let i = 0; i < length; i++) {
                const filterAllData: any = this.employeeAssignedFilterData.filter((data: any) => data.employee_id === this.removeEmployee[i])
                this.employeeAllFilterData.push(filterAllData[0]);
                if (i === 0) {

                    const filterAssign: any = this.employeeAssignedFilterData.filter((data: any) => data.employee_id !== this.removeEmployee[i])
                    assignedData = filterAssign
                } else {
                    const filterAssigned: any = assignedData.filter((data: any) => data.employee_id !== this.removeEmployee[i])
                    assignedData = filterAssigned
                }

            }
            this.employeeAssignedFilterData = assignedData;
            this.addShiftAssignForm.patchValue({
                removeEmployee: "",
                chooseEmployeeAll: ""

            })
        }
        this.chooseEmployeeAll = [];
        this.removeEmployee = [];
    }

// add the employee for shift assign
    public addShiftAssign() {
        if (!this.addShiftAssignForm.valid) {
            this.addShiftAssignForm.markAllAsTouched();
            return;
        }
        var params: any = {};
        params.company_id = this.CompanyID;
        params.client_id = this.ClientID;
        params.month_shift_id = this.addShiftAssignForm.get('month_shift_id').value;
        params.month = this.month;
        params.year = this.year;
        params.shift_assign_id = this.addShiftAssignForm.get('shift_assign_id').value;
        params.assign_details = this.employeeAssignedFilterData;
        //
        this.apiModulesService.edit(params, "admin/shift_assign/assign",).subscribe((data) => {
            //

            this.getShiftAssign();
            this.toastr.success('Shift Assign Modified Sucessfully...!', 'Success');
            ($('#shift-assign') as any).modal('hide');
        }, err => {
            if (err.status === 437) {
                this.toastr.error('Shift Assign Already Exists...!', 'Failed');
            } else {
                this.toastr.error('Something Went Wrong Please Try Again...!', 'Error');
            }
        });


    }


    public getdropDownShifts() {
        var params: any = {};
        params.client_id = this.ClientID;
        params.company_id = this.CompanyID;
        this.apiModulesService.list("admin/shift_assign/dropdown", params).subscribe((data) => {
            this.shiftAssignDropdown = (data.data.shift_assign[0] != undefined) ? data.data.shift_assign[0] : [];
            this.monthYearDropdown = (data.data.shift_assign[1] != undefined) ? data.data.shift_assign[1] : [];

        });
    }

    public getAssignedEmployee() {
        if (this.addShiftAssignForm.get('month_year').value != null && this.addShiftAssignForm.get('month_year').value != '') {

            var params: any = {};
            params.client_id = this.ClientID;
            params.company_id = this.CompanyID;
            params.shift_assign_id = this.addShiftAssignForm.get('shift_assign_id').value;
            params.sub_action = this.addShiftAssignForm.get('shift_assign_id').value != null && this.addShiftAssignForm.get('shift_assign_id').value != '' ? 'edit' : 'add';
            let text: any = this.addShiftAssignForm.get('month_year').value;
            const myArray = text.split("/");
            this.month = myArray[0] != undefined ? myArray[0] : '';
            this.year = myArray[1] != undefined ? myArray[1] : '';
            params.month = this.month;
            params.year = this.year;
            this.apiModulesService.list("admin/shift_assign/employee", params).subscribe((data) => {
                this.shiftEmployeeDropdown = (data.data.shift_assign != undefined) ? data.data.shift_assign : [];
                this.getEmployeeDropDown();
            });
        }
    }

    public resetForm() {
        this.addShiftAssignForm.reset();
        this.employeeAllFilterData = [];
        this.employeeAllData = [];
        this.employeeAssignedData = [];
        this.employeeAssignedFilterData = [];
        this.shiftEmployeeData = [];
        this.month = '';
        this.year = '';
    }

    clear(table: Table) {
        table.clear();
    }

    getSeverity(status: string) {
        switch (status.toLowerCase()) {
            case 'inactive':
                return 'danger';

            case 'active':
                return 'success';
        }
    }

    changeStatus(Status: any, data) {
        let params: any = {};
        params.client_id = this.ClientID;
        params.company_id = this.CompanyID;
        params.shift_assign_id = data.shift_assign_id;
        // //
        this.apiModulesService.edit(params, 'admin/shift_assign/' + Status).subscribe((data) => {
                this.getShiftAssign();
                ($('#assign-status') as any).modal('hide');
                this.toaster.success('Status Changed Successfully!', 'Success')
            }, (err) => {
                this.toastr.error("Something went wrong!", "Try Again");
            }
        );
    }

    editShiftAssign(shift_assign_id) {
        this.resetForm();
        let params: any = {};
        params.client_id = this.ClientID;
        params.company_id = this.CompanyID;
        params.shift_assign_id = shift_assign_id;
        // //
        this.apiModulesService.edit(params, 'admin/shift_assign/edit').subscribe((data) => {
            //
            const shiftMainData = data.data.shift_assign[0][0];
            let shiftEmployeeData = [];
            let notAssignedData = [];
            shiftEmployeeData = data.data.shift_assign[1];
            this.shiftEmployeeData = data.data.shift_assign[1];
            let monthyear: any = shiftMainData.month.concat('/', shiftMainData.year);
            this.addShiftAssignForm.patchValue(({
                month_shift_id: shiftMainData.month_shift_id,
                shift_assign_id: shiftMainData.shift_assign_id,
                // department_id: shiftEmployeeData[0].department_id,
                // designation_id: shiftEmployeeData[0].designation_id,
                department_id: shiftEmployeeData[0].department_id,
                designation_id: shiftEmployeeData[0].designation_id,
                month_year: monthyear,
                // chooseEmployeeAll: "",
                // removeEmployee: ""
            }));
            // this.getDesignationDropDown();
            // this.getAssignedEmployee();
            const length = shiftEmployeeData.length;
            // this.employeeAllFilterData = notAssignedData;

        });


    }

    employeeAssignedFunction() {
        const length = this.shiftEmployeeData.length;
        let notAssignedData =[];
        if(length !=0){
             this.employeeAssignedFilterData=[];

            for (let i = 0; i < length; i++) {
                const filterAllData: any = this.employeeAllData.filter((val: any) => val.employee_id == this.shiftEmployeeData[i].employee_id);
                this.employeeAssignedFilterData.push(filterAllData[0]);
                this.employeeAssignedData.push(filterAllData[0]);
                if (i === 0) {

                    const filterAssign: any = this.employeeAllData.filter((value: any) => value.employee_id !== this.shiftEmployeeData[i].employee_id);
                    notAssignedData = filterAssign
                } else {
                    const filterAssigned: any = notAssignedData.filter((value: any) => value.employee_id !== this.shiftEmployeeData[i].employee_id);
                    notAssignedData = filterAssigned
                }
            }
            this.employeeAllFilterData = notAssignedData;
        }

    }

    removeAllEmployee() {
        this.employeeAllFilterData = this.employeeAllData;
        this.employeeAssignedFilterData = [];
        this.clearAssignedComponents();
    }

    clearAssignedComponents() {
        this.chooseEmployeeAll = [];
        this.removeEmployee = [];
        this.addShiftAssignForm.patchValue({
            removeEmployee: "",
            chooseEmployeeAll: ""
        })
    }

    pushAllEmployee() {
        this.employeeAssignedFilterData = this.employeeAllData;
        this.employeeAllFilterData = [];
        this.clearAssignedComponents();
    }

    getAcronym(str: any) {
        if (str != null) {
            let matches = str.match(/\b(\w)/g); // ['J','S','O','N']
            matches = matches.slice(0, 2);
            const acronym = matches.join(''); // JSON
            return acronym;
        }
    }

    getBgColors(str: any) {
        if (str != null) {
            const min = 0;
            const max = 15;
            const index = this.findAlphapositions(str, 1);
            return this.nameBgColors[index];
        }
    }

    findAlphapositions(str: any, n: any) {
        var NUM = 31;

        return str[0].charCodeAt(0) & NUM;
    }

    callStatusModel(url_name: any, data: any) {
        this.statusApi = url_name;
        this.statusValues = data;


    }
}
