import { Component, OnInit } from '@angular/core';
import { SpinnerService } from 'src/app/snipper/spinner.service';
import { ApiModulesService } from 'src/app/all-modules/api-modules.service';
import { documentservice } from 'src/app/all-modules/documents.sevice';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-final',
  templateUrl: './final.component.html',
  styleUrls: ['./final.component.css']
})
export class FinalComponent implements OnInit {

  draftListData: any;
  nameBgColors: any = ['bg-blue-dim', 'bg-blue-dim', 'bg-azure-dim', 'bg-indigo-dim', 'bg-info-dim', 'bg-purple-dim', 'bg-pink-dim', 'bg-orange-dim', 'bg-teal-dim', 'bg-primary-dim', 'bg-success-dim', 'bg-warning-dim', 'bg-danger-dim', 'bg-secondary-dim', 'bg-dark-dim', 'bg-gray-dim', 'bg-blue-dim', 'bg-azure-dim', 'bg-indigo-dim', 'bg-info-dim', 'bg-purple-dim', 'bg-pink-dim', 'bg-orange-dim', 'bg-teal-dim', 'bg-primary-dim', 'bg-success-dim', 'bg-warning-dim', 'bg-danger-dim', 'bg-secondary-dim', 'bg-dark-dim', 'bg-gray-dim'];
  nameBorderColors: any = ['bg-outline-primary', 'bg-outline-success', 'bg-outline-info', 'bg-outline-gray', 'bg-outline-warning', 'bg-outline-dark', 'bg-outline-light', 'bg-outline-secondary', 'bg-outline-danger'];
  finalReportData: any;
  financial_file_id: any;
  file_name: any;
  password: any;
  submitted: any;
  financialDropdownData: any;
  financial_date_id: any;
  ClientID: any;
  CompanyID: any;
  CompanyDropDown = [];
  ClientDropDown = [];
  constructor(private spinnerService: SpinnerService, private apiModulesService: ApiModulesService,
    private documentService: documentservice, private toastr: ToastrService) {
  }
  ngOnInit() {
    this.spinnerService.raiseDataEmitterEvent('on');
    this.GetClientDropDown();
  }


  payrollFinalData() {
    this.spinnerService.raiseDataEmitterEvent('on');
    const params: any = {};
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    params.financial_date_id = this.financial_date_id;
    this.apiModulesService.list('employee-tabs/employee-hrm/payroll-final/list', params).subscribe((data: any) => {
      this.finalReportData = data.data.payroll_final != undefined ? data.data.payroll_final : []

      this.spinnerService.raiseDataEmitterEvent('off');

    }, (err: any) => {
      this.spinnerService.toasterError(err);
    });
  }
  fileForDownload(financial_file_id, file_name) {
    this.financial_file_id = financial_file_id
    this.file_name = file_name
  }
  public DownloadFile(): void {
    this.submitted = true;
    if (this.password == '' || this.password == null) {
      return; // Password is empty, do nothing
    }

    const params: any = {};
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    params.financial_file_id = this.financial_file_id;
    params.password = this.password;

    this.apiModulesService.add(params, 'employee-tabs/employee-hrm/payroll-final/download').subscribe(
      (data) => {
        const docDetails = data.data.payroll_final;
        this.documentService.DownloadExcel(docDetails.file_name, docDetails.doc_url);
        this.resetForm();
        ($('#final_password') as any).modal('hide');
      },
      (err) => {
        this.spinnerService.toasterError(err);
      }
    );
  }

  resetForm() {
    this.submitted = false;
  }
  /* client DropDown codes*/
  public GetClientDropDown() {
    this.apiModulesService.get('DropDown/client').subscribe((data) => {
      this.ClientDropDown = data.data.client;
      this.ClientID = data.data.client[0].client_id;
      this.GetCompanyDropDown();
    }, (err: any) => {
      this.spinnerService.toasterError(err);
    })
  }

  /* company DropDown codes */
  public GetCompanyDropDown() {
    const params: any = {};
    params.client_id = this.ClientID;
    this.apiModulesService.list('DropDown/company', params).subscribe((data) => {
      this.CompanyDropDown = data.data.company != undefined ? data.data.company : [];
      if (this.CompanyDropDown.length !== 0) {
        setTimeout(() => {
          this.CompanyID = this.CompanyDropDown[0].company_id;
          this.dropDown();
        }, 100)
      }
      else {
        this.CompanyID = null;
      }
    }, (err: any) => {
      this.spinnerService.toasterError(err);
    })
  }
  dropDown() {
    this.spinnerService.raiseDataEmitterEvent('on');
    const params: any = {};
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;

    this.apiModulesService.list('employee-tabs/employee-hrm/payroll-draft/dropdown', params).subscribe((data) => {
      this.financialDropdownData = data.data.payroll_report;
      setTimeout(()=>{
        this.financial_date_id = data.data.payroll_report?.[0]?.financial_date_id ?? null;
        if (this.financial_date_id != null) {
          this.payrollFinalData();

        } else {
          this.spinnerService.raiseDataEmitterEvent('off');

        }
      },100)

    }, (err: any) => {
      this.spinnerService.toasterError(err);
    })

  }
  getBgColors(str: any) {
    const min = 0;
    const max = 15;
    const index = this.findAlphapositions(str, 1);
    return this.nameBgColors[index];
  }

  findAlphapositions(str: any, n: any) {
    const NUM = 31;

    return str[0].charCodeAt(0) & NUM;
  }




  getAcronym(str: any) {
    let matches = str.match(/\b(\w)/g); // ['J','S','O','N']
    matches = matches.slice(0, 3);
    const acronym = matches.join(''); // JSON
    return acronym;
  }

}
