
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ApiModulesService } from 'src/app/all-modules/api-modules.service';
import { documentservice } from 'src/app/all-modules/documents.sevice';
import { ToastrService } from 'ngx-toastr';
import { SpinnerService } from 'src/app/snipper/spinner.service';
import { ImportsGridComponent } from '../imports-grid/imports-grid.component';
import { FileUpload } from 'primeng/fileupload';
declare const $: any;
interface UploadEvent {
    originalEvent: Event;
    files: File[];
}
@Component({
    selector: 'app-import-dropdown-upload',
    templateUrl: './import-dropdown-upload.component.html',
    styleUrls: ['./import-dropdown-upload.component.css']
})
export class ImportDropdownUploadComponent implements OnInit, OnDestroy {
    @ViewChild(ImportsGridComponent) ImportGrid: ImportsGridComponent;
    @ViewChild('importfileUpload', { static: false }) fileUpload: FileUpload;
    data_import_id: any;
    import_status: any;
    showValidation: any;
    ClientID: any;
    CompanyID: any;
    CompanyDropDown: any;
    ClientDropDown: any;
    financialDropdownData: any;
    financial_date_id: any;
    fileData: any;
    uplodedFileName:any;
    uploadedFiles = [];
    totalRecords: Number;
    showErrorForm = false;
    @Input() ExcelTypeKey: any;
    @Input() ClientUserType: any;
    ExcelImportID: any;
    headers: any;
    ExcellDataLength: any;
    misMatchHeader: any;
    headerMapResponse: any;
    sheetnames: any;
    constructor(protected apiModulesService: ApiModulesService,
        protected toastrService: ToastrService,
        protected documentService: documentservice,
        protected spinnerService: SpinnerService
    ) {
    }

    ngOnInit(): void {
        this.spinnerService.raiseDataEmitterEvent('on');

        this.GetClientDropDown();
    }


    public MonthYearDropDown(inputDateString) {
        // const month = date.toLocaleString('en-us', { month: 'long' }); // Get full month name
        // const year = date.getFullYear();
        // this.MonthYearChoose = `${month}/${year}`;

        // Input string
        // var inputDateString = 'Wed May 08 2024 15:01:57 GMT+0530 (India Standard Time)';
        var date = new Date(inputDateString);
        var day: any = date.getDate();
        var month: any = date.getMonth() + 1; // Month is zero-based, so add 1
        var year: any = date.getFullYear();

        // Format day and month with leading zeros if needed
        day = (day < 10 ? '0' : '') + day;
        month = (month < 10 ? '0' : '') + month;

        // Format the date as 'DD/MM/YYYY'
        var formattedDate = day + '/' + month + '/' + year;
        // Output the formatted date
        return formattedDate;

    }

    /* client DropDown codes*/
    public GetClientDropDown() {
        this.apiModulesService.get('DropDown/client').subscribe((data) => {
            this.ClientDropDown = data.data.client;
            this.ClientID = data.data.client[0].client_id;
            this.GetCompanyDropDown();
        },(err:any)=>{
            this.spinnerService.toasterError(err);
        }
        )
    }

    /* company DropDown codes */
    public GetCompanyDropDown() {
        const params: any = {};
        params.client_id = this.ClientID;
        this.apiModulesService.list('DropDown/company', params).subscribe((data) => {
            this.CompanyDropDown = data.data.company != undefined ? data.data.company : [];
            if (this.CompanyDropDown.length !== 0) {
                this.CompanyID = this.CompanyDropDown[0].company_id;

                setTimeout(() => {
                    this.payDatesDropDown();
                }, 100)
            }
            else {
                this.CompanyID = null;
            }
        },(err:any)=>{
            this.spinnerService.toasterError(err);
        }
        )
    }

    public payDatesDropDown(){
        const params: any = {};
        params.client_id = this.ClientID;
        params.company_id = this.CompanyID;
        this.apiModulesService.list('employee-tabs/employee-hrm/payroll-draft/dropdown', params).subscribe((data) => {
            this.financialDropdownData=data.data.payroll_report;
            if (this.CompanyDropDown.length !== 0) {
                this.financial_date_id = this.financialDropdownData[0].financial_date_id;

                setTimeout(() => {
                    this.importGrid()
                }, 100)
            }
            else {
                this.CompanyID = null;
            }
        },(err:any)=>{
            this.spinnerService.toasterError(err);
        }
        )
    }

    onChoose(event: UploadEvent) {
        const filesData = event.files[0];

        this.fileData = filesData;
        this.uplodedFileName = filesData.name;


        // this.messageService.add({severity: 'info', summary: 'File Uploaded', detail: ''});
    }
    onFileUpload() {
        if (this.fileData == null || this.fileData == '') {
            this.toastrService.error('Import the File..?', 'TryAgain');
            return;
        }
        this.spinnerService.raiseDataEmitterEvent('on');
        var myFormData = new FormData();
        myFormData.append('excel', this.fileData);
        myFormData.append('client_id', this.ClientID);
        myFormData.append('company_id', this.CompanyID);
        myFormData.append('excel_type_key', this.ExcelTypeKey);
        myFormData.append('excel_type_key', this.ExcelTypeKey);
        myFormData.append('financial_date_id', this.financial_date_id);
        myFormData.append('upload_file_name', this.uplodedFileName);
        this.documentService.post('api/admin/import/common/inspect', myFormData).subscribe((data) => {

            this.headerMapResponse = data.data.imports;
            this.sheetnames = data.data.imports.sheet_name;
            this.misMatchHeader = data.data.imports.errors;
            if (data.data.imports.is_error == true) {
                this.showHeaderNotMap()
                this.spinnerService.raiseDataEmitterEvent('off');

            } else {
                this.showErrorForm = false;
                this.fileUpload.clear();
                this.importGrid();
            }
        }, (err) => {
            this.spinnerService.toasterError(err);
        });
    }
    onCancel(event: any) {
        this.fileData = null;
        this.uplodedFileName=null;
        // this.messageService.add({severity: 'info', summary: 'File Uploaded', detail: ''});
    }

    showHeaderNotMap() {
        this.showErrorForm = true;
        ($('#header_error_modal') as any).modal('show');
    }
    ngOnDestroy(): void {
        // Do not forget to unsubscribe the event
    }

    importGrid() {
          setTimeout(() => {
            this.ImportGrid.getImport()
        }, 100)
    }
    changeGridToValidation(value) {

        this.data_import_id = value.data_import_id;
        this.import_status = value.import_status;
        this.showValidation = true;
    }
    changeValidationToGrid(value) {
        this.showValidation = false;
        this.importGrid();
    }
}
