import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import{ApiModulesService} from '../../../api-modules.service';
import {ToastrService} from 'ngx-toastr';
import {Subject} from 'rxjs';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {filter} from 'rxjs/operators';
import {HttpClient, HttpResponse} from '@angular/common/http';
import { SpinnerService } from 'src/app/snipper/spinner.service';
import { Table } from 'primeng/table';
declare const $: any;

@Component({
    selector: 'app-cities-main',
    templateUrl: './cities-main.component.html',
    styleUrls: ['./cities-main.component.css']
})
export class CitiesMainComponent implements OnInit, AfterViewInit, OnDestroy {
    public citiesData = [];
    public statesData = [];
    public statename = [];
    public countryname = [];
    public url: any = 'citymain';
    public rows = [];
    public search = [];
    searchCities: any;
    nameRegex = /^[a-zA-Z\s]*$/;
    public addCityForm: FormGroup;
    public editCityForm: FormGroup;
    countriesList: any;
    statesList: any;
    stateData: any;
    states: any;
    selected_country: any;
    selected_state: any;
    cityData: any;
    statesname: any;
    stateslist: any;
    country_id: any;
    limit = 10;
    pageNo = 1;
    listHeaders = [];
    public totalRecords: any = 0;
    searchKeyword: string = '';
    page: any;
    searchCountry: any;

    public CountryName: any;
    public FilterField: any;
    globalData: any;
    loading=false;
    nameBgColors:any = ['bg-blue-dim','bg-blue-dim','bg-azure-dim','bg-indigo-dim','bg-info-dim','bg-purple-dim','bg-pink-dim','bg-orange-dim','bg-teal-dim','bg-primary-dim','bg-success-dim','bg-warning-dim','bg-danger-dim','bg-secondary-dim','bg-dark-dim','bg-gray-dim','bg-blue-dim','bg-azure-dim','bg-indigo-dim','bg-info-dim','bg-purple-dim','bg-pink-dim','bg-orange-dim','bg-teal-dim','bg-primary-dim','bg-success-dim','bg-warning-dim','bg-danger-dim','bg-secondary-dim','bg-dark-dim','bg-gray-dim'];
    nameBorderColors:any = ['bg-outline-primary','bg-outline-success','bg-outline-info','bg-outline-gray','bg-outline-warning','bg-outline-dark','bg-outline-light','bg-outline-secondary','bg-outline-danger'];
    statuses: { label: string; value: string; }[];
    statusApi: any;
    statusValues: any;
    constructor(private apiModulesService: ApiModulesService, private toastr: ToastrService, private formBuilder: FormBuilder,private spinnerService: SpinnerService, private  http: HttpClient) {
    }

    ngOnInit(): void {
        const that = this;

        // that.getCitiesType('rerender',function(){});

        this.getCountries();
        // Add Statestype
        this.addCityForm = this.formBuilder.group({
            state_id: ['', [Validators.required]],
            city_name: ['', [Validators.required, Validators.pattern(this.nameRegex)]],
            country_id: ['', [Validators.required]],

        });

        //edit statestype
        this.editCityForm = this.formBuilder.group({
            state_id: ['', [Validators.required]],
            city_name: ['', [Validators.required, Validators.pattern(this.nameRegex)]],
            city_id: ['', []],
            country_id: ['', [Validators.required]],
        });
        this.statuses = [
            { label: 'Active', value: 'active' },
            { label: 'Inactive', value: 'inactive' },
          ];
    }

    ngAfterViewInit(): void {

    }

    rerender(): void {
        this.citiesData=[];
    }

    citiesLoad() {
        this.getCitiesType();
    }
    public getCitiesType() {

        if(this.selected_state !==null &&  this.states.length!=0){
          this.spinnerService.raiseDataEmitterEvent('on');

        let params: any = {};
        params.state_id = this.selected_state;
        this.apiModulesService.list('admin/city/list', params).subscribe((data) => {
          this.citiesData = (data.data.cities != undefined) ? data.data.cities : [];
          this.loading=false;
          var totalRecords = (this.citiesData[0] != undefined) ? this.citiesData[0].total_records : 0;
          this.totalRecords = totalRecords;
          this.resetForm();
          setTimeout(() => {
            this.spinnerService.raiseDataEmitterEvent('off');
          }, 100);
        }, (err) => {
          this.citiesData = [];
          this.spinnerService.raiseDataEmitterEvent('off');
          this.toastr.error('Something went wrong!', 'Try Again');
          // this.rerender();
        });
      }else{
        this.rerender();
      }
    }
      clear(table: Table) {
        table.clear();
      }

      getSeverity(status: string) {
        switch (status.toLowerCase()) {
          case 'inactive':
            return 'danger';

          case 'active':
            return 'success';
        }
      }


    //  get country
    public getCountries() {
        this.apiModulesService.get('country/list').subscribe((data) => {
            const res: any = data;
            this.countriesList = (res.data.countries != undefined) ? res.data.countries : [];
            this.selected_country = this.countriesList[0].country_id;
            this.getState();

        });
    }

    // public addStates() {
    //     var params: any = {};
    //     params.country_id = this.addCityForm.get('country_id').value;
    //     this.apiModulesService.list('country/states', params).subscribe((data) => {
    //         this.statesList = (data.data.states != undefined) ? data.data.states : [];
    //     });

    // }

    public editStates(countryId:any) {
        var params: any = {};
        params.country_id = countryId;
        this.apiModulesService.list('country/states', params).subscribe((data) => {
            this.stateslist = (data.data.states != undefined) ? data.data.states : [];
        });

    }


    public getState() {
        var params: any = {};
        params.country_id = this.selected_country;
        this.apiModulesService.list('country/states', params).subscribe((data) => {
            this.states = (data.data.states != undefined) ? data.data.states : [];
            if(  this.states.length!=0){
              setTimeout(()=>{
                this.selected_state = this.states[0].state_id;
                this.getCitiesType();
              },100)
            }else{
              this.selected_state=null;
              this.rerender();
            }



        });


    }


    searchName(val) {
        this.rows.splice(0, this.rows.length);
        let temp = this.search.filter(function (d) {
            val = val.toLowerCase();
            return d.city_name.toLowerCase().indexOf(val) !== -1 ||
                d.state_name.state_name.toLowerCase().indexOf(val) !== -1;

        });
        this.rows.push(...temp);
    }

    searchRegionName(val) {
        this.rows.splice(0, this.rows.length);
        let temp = this.search.filter(function (d) {
            val = val.toLowerCase();
            return d.country_name.country_name.toLowerCase().indexOf(val) !== -1 ||
                d.state_name.state_name.toLowerCase().indexOf(val) !== -1;
        });

        this.rows.push(...temp);

    }

    //edit value get
    public onEditCities(CityId: any) {
        var params: any = {};
        params.city_id = CityId;

        this.apiModulesService.edit(params, 'admin/city/edit').subscribe((data) => {
            let cityDetail = data.data.cities;
            this.editCityForm.setValue({
                city_name: cityDetail.city_name,
                city_id: cityDetail.city_id,
                country_id: cityDetail.state_name.country_id,
                state_id: cityDetail.state_id,

            });

            this.editStates(cityDetail.state_name.country_id);
        });

    }

    //edit update
    public onEditChanges() {
        if (!this.editCityForm.valid) {
            this.editCityForm.markAllAsTouched();
            return;
        }
        var params: any = {};
        params.city_name = this.editCityForm.get('city_name').value;
        params.state_id = this.editCityForm.get('state_id').value;
        params.city_id = this.editCityForm.get('city_id').value;
        params.country_id = this.editCityForm.get('country_id').value;
        this.apiModulesService.edit(params, 'admin/city/update').subscribe((data) => {
            this.selected_country = this.editCityForm.get('country_id').value;
            this.selected_state = this.editCityForm.get('state_id').value;
            this.getCitiesType();
            this.toastr.success('City Modified Sucessfully...!', 'Success');
            ($('#edit_city')as any).modal('hide');
        }, err => {
            if (err.status === 437) {
                this.toastr.error('City Already Exists...!', 'Failed');
            } else {
                this.toastr.error('Something Went Wrong Please Try Again...!', 'Error');
            }
        });
    }

    changeStatus(status: any, CityId: any) {

        var params: any = {};
        params.city_id = CityId;
        params.status = status;
        this.apiModulesService.edit(params, 'admin/city/status').subscribe(() => {
            this.getCitiesType();
            ($('#city-status') as any).modal('hide');
            this.toastr.success('Status Changed Sucessfully...!', 'Success');
        }, err => {
            this.toastr.error('Something Went Wrong Please Try Again...!', 'Error');
        });
    }

    public resetForm() {
        this.addCityForm.reset();
    }

    /*  add new city using onAddcity Function   */
    public onAddcity() {
        if (this.addCityForm.invalid) {
            this.addCityForm.markAllAsTouched();
            return;
        }
        var params: any = {};
        params.city_name = this.addCityForm.get('city_name').value;
        params.state_id = this.addCityForm.get('state_id').value;
        params.country_id = this.addCityForm.get('country_id').value;
        this.apiModulesService.add(params, 'admin/city/add',).subscribe((data) => {

            //  this.getStatesType();


            this.selected_country = this.addCityForm.get('country_id').value;
            this.selected_state = this.addCityForm.get('state_id').value;
            this.getCitiesType();
            this.toastr.success('City Added Sucessfully...!', 'Success');
            this.addCityForm.reset();
            ($('#add_city')as any).modal('hide');
        }, err => {
            if (err.status === 437) {
                this.toastr.error('City Already Exists...!', 'Failed');
            } else {
                this.toastr.error('Something Went Wrong Please Try Again...!', 'Error');
            }
        });

    }

    ngOnDestroy(): void {
        // Do not forget to unsubscribe the event

    }
    getAcronym(str:any){
        var matches = str.match(/\b(\w)/g); // ['J','S','O','N']
        matches= matches.slice(0,2);
        var acronym = matches.join(''); // JSON
        return acronym;
    }
    getBgColors(str:any){
        var min = 0;
        var max = 15;
        var index = this.findAlphapositions(str,1);
        return this.nameBgColors[index];
    }
    findAlphapositions(str:any, n:any)
    {
        var NUM = 31;

        return str[0].charCodeAt(0) & NUM;
    }
    callStatusModel(url_name:any,data:any){
        this.statusApi=url_name;
        this.statusValues=data;
      }
}



