// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.flip-card-calendar {
    background-color: transparent;
    width: 100%;
    height: 100%;
    border: 1px solid #f1f1f1;
    perspective: 1000px; /* Remove this if you don't want the 3D effect */
}

.flip-card-calendar-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: start;
    transition: transform 0.8s;
    transform-style: preserve-3d;

}

.flip-card-front-calendar, .flip-card-back-calendar {
    position: absolute;
    width: 100%;
    height: 100%; /* Safari */
    backface-visibility: hidden;
}

.flip-card-back-calendar {
    position: absolute;
    width: 100%;
    height: 100%; /* Safari */
    backface-visibility: hidden;
}

.flip-card-front-calendar {
    background-color: #fff;
    color: black;
}

/* Style the back side */
.flip-card-back-calendar {
    background-color: #fff;
    color: black;
    transform: rotateX(180deg);
}

.flip-card-date {
    font-size: 15px;
}
`, "",{"version":3,"sources":["webpack://./src/app/all-modules/admin-tabs/cities/cities-main/cities-main.component.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;IAC7B,WAAW;IACX,YAAY;IACZ,yBAAyB;IACzB,mBAAmB,EAAE,gDAAgD;AACzE;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,0BAA0B;IAC1B,4BAA4B;;AAEhC;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY,EACyB,WAAW;IAChD,2BAA2B;AAC/B;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY,EACyB,WAAW;IAChD,2BAA2B;AAC/B;;AAEA;IACI,sBAAsB;IACtB,YAAY;AAChB;;AAEA,wBAAwB;AACxB;IACI,sBAAsB;IACtB,YAAY;IACZ,0BAA0B;AAC9B;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".flip-card-calendar {\r\n    background-color: transparent;\r\n    width: 100%;\r\n    height: 100%;\r\n    border: 1px solid #f1f1f1;\r\n    perspective: 1000px; /* Remove this if you don't want the 3D effect */\r\n}\r\n\r\n.flip-card-calendar-inner {\r\n    position: relative;\r\n    width: 100%;\r\n    height: 100%;\r\n    text-align: start;\r\n    transition: transform 0.8s;\r\n    transform-style: preserve-3d;\r\n\r\n}\r\n\r\n.flip-card-front-calendar, .flip-card-back-calendar {\r\n    position: absolute;\r\n    width: 100%;\r\n    height: 100%;\r\n    -webkit-backface-visibility: hidden; /* Safari */\r\n    backface-visibility: hidden;\r\n}\r\n\r\n.flip-card-back-calendar {\r\n    position: absolute;\r\n    width: 100%;\r\n    height: 100%;\r\n    -webkit-backface-visibility: hidden; /* Safari */\r\n    backface-visibility: hidden;\r\n}\r\n\r\n.flip-card-front-calendar {\r\n    background-color: #fff;\r\n    color: black;\r\n}\r\n\r\n/* Style the back side */\r\n.flip-card-back-calendar {\r\n    background-color: #fff;\r\n    color: black;\r\n    transform: rotateX(180deg);\r\n}\r\n\r\n.flip-card-date {\r\n    font-size: 15px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
