// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ::ng-deep .p-chips .p-chips-multiple-container .p-chips-input-token input{
    font-size: 13px;
    font-weight: normal;
    font-family: 'segoe UI-MONOSPACE';
}`, "",{"version":3,"sources":["webpack://./src/app/all-modules/admin-tabs/userdefinedfield/userdefinedfield-main/userdefinedfield-main.component.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,mBAAmB;IACnB,iCAAiC;AACrC","sourcesContent":[":host ::ng-deep .p-chips .p-chips-multiple-container .p-chips-input-token input{\r\n    font-size: 13px;\r\n    font-weight: normal;\r\n    font-family: 'segoe UI-MONOSPACE';\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
