import {AfterViewInit, Component, OnChanges, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AllModulesService} from 'src/app/all-modules/all-modules.service';
import {ApiModulesService} from 'src/app/all-modules/api-modules.service';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {DatePipe} from '@angular/common';
import {Subject} from 'rxjs';

import {Router} from '@angular/router';
import { SpinnerService } from 'src/app/snipper/spinner.service';
import { Table } from 'primeng/table';


declare const $: any;

@Component({
    selector: 'app-employee-list',
    templateUrl: './employee-list.component.html',
    styleUrls: ['./employee-list.component.css'],
})
export class EmployeeListComponent implements OnInit, OnDestroy, AfterViewInit {
    //
    //
    //
    isDtInitialized = false;
    public lstEmployee: any;
    ClientID: any;
    CompanyID: any;
    employeeID = localStorage.getItem('employee_id');
    public tempId: any;
    public editId: any;
    public EmployeesData = [];
    public dropdownname: [];
    public dropdownTitle: [];
    public dropdownGender: [];
    public dropdownNationalityType: [];
    public dropdownNationality: [];
    public dropdownMarital: [];
    public dropdownDocument: [];
    public dropdownAddressType: [];
    public physicallyDropdown: [];
    public employeeCategory: [];
    public countriesList = [];
    public statesList = [];
    public citiesList = [];
    public addEmployeeForm: FormGroup;
    public searchForm: FormGroup;
    public pipe = new DatePipe('en-US');
    public rows = [];
    public srch = [];
    public statusValue;
    //
    public DateJoin;
    public pageNo = 1;
    public limit = 10;
    public ShowForm: any = false;
    public Show = true;
    public modulesSwitch = '-1';
    public totalRecords: any = '0';
    showTabs = false;
    searchKeyword: string = '';
    percentage_value: any;
    statusApi:any;
    statusValues:any;
    emailRegex =
        '^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$';
    mobileRegex = '^((\\+91-?)|0)?[0-9]{10,13}$';
    numberRegex = '^[a-zA-Z0-9]{6,10}$';
    employeeIdRegex = '^[0-9\\-a-zA-Z\\-]{1,12}$';
    employeeName = '^[a-zA-Z][a-zA-Z\\s]*$';
    text: boolean;
    employeesDetails: any;
    field = true;
    CompanyDropDown = [];
    ClientDropDown = [];
    searchByColumn: any = '';
    searchValue: any = '';
    physicalvalue = '0';
    tabToGrid=false;
    nameBgColors: any = ['bg-blue-dim', 'bg-blue-dim', 'bg-azure-dim', 'bg-indigo-dim', 'bg-info-dim', 'bg-purple-dim', 'bg-pink-dim', 'bg-orange-dim', 'bg-teal-dim', 'bg-primary-dim', 'bg-success-dim', 'bg-warning-dim', 'bg-danger-dim', 'bg-secondary-dim', 'bg-dark-dim', 'bg-gray-dim', 'bg-blue-dim', 'bg-azure-dim', 'bg-indigo-dim', 'bg-info-dim', 'bg-purple-dim', 'bg-pink-dim', 'bg-orange-dim', 'bg-teal-dim', 'bg-primary-dim', 'bg-success-dim', 'bg-warning-dim', 'bg-danger-dim', 'bg-secondary-dim', 'bg-dark-dim', 'bg-gray-dim'];
    nameBorderColors: any = ['bg-outline-primary', 'bg-outline-success', 'bg-outline-info', 'bg-outline-gray', 'bg-outline-warning', 'bg-outline-dark', 'bg-outline-light', 'bg-outline-secondary', 'bg-outline-danger'];
    editedEmployeeData: any = {};
    public activeIndex = 0;
    public listHeaders = [
        {
            AliasName: 'Employee No',
            FieldName: 'Employee No'
        }, {
            AliasName: 'Name',
            FieldName: 'Name'
        }, {
            AliasName: 'Dob',
            FieldName: 'Dob'
        }, {
            AliasName: 'phone',
            FieldName: 'Mobile'
        }, {
            AliasName: 'Email',
            FieldName: 'Email'
        }
    ];
    scrollableTabs = [
        {
            title: 'Family'
        }, {
            title: 'Documents'
        }, {
            title: 'Skills'
        }, {
            title: 'Experience'
        }, {
            title: 'Education'
        }, {
            title: 'Experience Documents'
        }, {
            title: 'Bank'
        }, {
            title: 'Employment'
        }, {
            title: 'Salary Compensation'
        },
        {
            title: 'Deductions'
        }
    ];
    statuses=[];
    loading: boolean = false;
  viewEmployeesPopupData: any;
  viewFamilyPopupData=[];
  viewDocumentsPopupData=[];
  viewBankPopupData=[];
  viewEducationPopupData=[];
  viewEmployementPopupData: any;
  awsimages: string;
    constructor(
        private ApiModuleService: ApiModulesService,
        private toastr: ToastrService,
        private formBuilder: FormBuilder,
        private router: Router,
        private spinnerService: SpinnerService,
    ) {
    }


    ngOnInit() {
        this.spinnerService.raiseDataEmitterEvent('on');
        this.getCountries();
        this.GetClientDropDown();
        const that = this;

        this.addEmployeeForm = this.formBuilder.group({
            employee_no: ['', [Validators.required, Validators.pattern(this.employeeIdRegex)]],
            title: ['', [Validators.required]],
            first_name: ['', [Validators.required, Validators.pattern(this.employeeName)]],
            middle_name: ['', [Validators.pattern(this.employeeName)]],
            sur_name: ['', [Validators.pattern(this.employeeName)]],
            sex: ['', [Validators.required]],
            dob: ['', [Validators.required]],
            mobile: ['', [Validators.pattern(this.mobileRegex)]],
            address_type: ['', [Validators.required]],
            employee_category: ['', [Validators.required]],
            email_id: ['', [Validators.pattern(this.emailRegex)]],
            country_id: ['', [Validators.required]],
            address_1: ['', [Validators.required]],
            address_2: ['', []],
            pincode: ['', [Validators.required, Validators.pattern(this.numberRegex), Validators.maxLength(10)]],
            state_id: ['', [Validators.required]],
            city_id: ['', [Validators.required]],
            nationality: ['', [Validators.required]],
            nationality_type: ['', [Validators.required]],
            marital_status: ['', [Validators.required]],
            physically_challanged: ['', [Validators.required]],
            physically_challanged_percentage: ['', []],
            employee_id: ['', []],
            personal_email_id: ['', [Validators.pattern(this.emailRegex)]],

        });
        // search  form
        this.searchForm = this.formBuilder.group({
            column_name: ['', [Validators.required]],
            search_value: ['', [Validators.required]]
        })
        this.statuses = [
          { label: 'Active', value: 'active' },
          { label: 'Inactive', value: 'inactive' },
      ];
        // edit form validation

    }

    ngAfterViewInit(): void {

    }

    public percentageChange(value) {
        this.addEmployeeForm.patchValue({physically_challanged_percentage: null});

        if (this.addEmployeeForm.get('physically_challanged').value == 1) {
            this.field = false;
        } else {
            this.field = true;
        }
    }

    public getEmployeeBySearch() {
        if (!this.searchForm.valid) {
            this.searchForm.markAllAsTouched();
            return;
        }
        this.getEmployeesData();
    }

    searchResetForm() {
        this.searchForm.reset();
        this.searchByColumn = '';
        this.searchValue = '';
        if (this.searchForm.value.column_name != '' || this.searchForm.value.search_value != '') {
            this.getEmployeesData();
        }
    }

    changeViewGridToTabs(employeeId: number, clientId: number, companyId: number, employeeNo: any,employeeName:any) {
        this.editedEmployeeData.client_id = clientId;
        this.editedEmployeeData.company_id = companyId;
        this.editedEmployeeData.employee_id = employeeId;
        this.editedEmployeeData.employee_no = employeeNo;
        this.editedEmployeeData.employee_name = employeeName;
        this.showTabs = true;
    }

    changeTabsViewToGrid(value) {
       this.tabToGrid=value;
        this.activeIndex = 0;
        this.getEmployeesData();
        this.resetForm();
    }

    changeTabsIndex(index) {
        this.activeIndex = index;
    }
    onPhoneNumberInput(event: Event) {
        const inputElement = event.target as HTMLInputElement;
        const phoneNumber = inputElement.value.replace(/\D/g, '');
        const truncatedPhoneNumber = phoneNumber.slice(0, 10);
        inputElement.value = truncatedPhoneNumber;
        this.addEmployeeForm.get('mobile').setValue(truncatedPhoneNumber);
    }
    public getEmployeesData() {
      this.spinnerService.raiseDataEmitterEvent('on');
        if (this.CompanyDropDown.length !== 0 && this.CompanyID != null) {

            const params: any = {};
            params.page = this.pageNo;
            params.limit = this.limit;
            params.client_id = this.ClientID;
            params.company_id = this.CompanyID;
            params.search_by = this.searchByColumn.toString();
            params.search_value = this.searchValue.toString();
            this.ApiModuleService.list('admin/employees/list', params).subscribe((data) => {
                this.EmployeesData = (data.data.employee != undefined) ? data.data.employee : [];
                this.loading = false;
                this.ShowForm = false;
                this.showTabs = false;
                var totalRecords = (this.EmployeesData[0] != undefined) ? this.EmployeesData[0].total_records : 0;
                this.totalRecords = totalRecords;
                this.resetForm();
                setTimeout(() => {
                  this.spinnerService.raiseDataEmitterEvent('off');

              }, 100);
            }, (err) => {
              this.EmployeesData = [];
              this.spinnerService.toasterError(err);
            });
        } else {
          this.EmployeesData = [];
          this.spinnerService.raiseDataEmitterEvent('off');
        }
    }
    public viewEmployeesData(employee_id) {
      this.spinnerService.raiseDataEmitterEvent('on');
            const params: any = {};
            params.client_id = this.ClientID;
            params.company_id = this.CompanyID;
            params.employee_id = employee_id;
            this.ApiModuleService.list('admin/employees/view', params).subscribe((data) => {
                this.viewEmployeesPopupData = (data.data.employee_details[0] != undefined) ? data.data.employee_details[0] : [];
                this.viewFamilyPopupData = (data.data.employee_family != undefined) ? data.data.employee_family : [];
                this.viewBankPopupData = (data.data.employee_bank != undefined) ? data.data.employee_bank : [];
                this.viewDocumentsPopupData = (data.data.employee_documents != undefined) ? data.data.employee_documents : [];
                this.viewEducationPopupData = (data.data.employee_education != undefined) ? data.data.employee_education : [];
                this.viewEmployementPopupData = (data.data.employee_employement[0] != undefined) ? data.data.employee_employement[0] : [];
                this.awsimages =data.data.employee_details[0].img!=null?'data:image/png;base64,' +  data.data.employee_details[0].img:null;
                  this.spinnerService.raiseDataEmitterEvent('off');

            }, (err) => {
              this.spinnerService.toasterError(err);
            });
    }

    rerender() {
    }

    head(i) {
        this.modulesSwitch = i;
    }

    // addExperienceDetails(employee, client, company, employee_no) {
    //     const value: any = {};
    //     value.employee = employee;
    //     value.client = client;
    //     value.company = company;
    //     value.employee_no = employee_no;
    //     this.router.navigate(['/layout/employees/experience']);
    //     this.ApiModuleService.sendData(value);
    // }
    // Add employee  Modal Api Call
    addEmployee() {
        if (!this.addEmployeeForm.valid) {
            this.addEmployeeForm.markAllAsTouched();
            return;
        }
        const DateOfBirth = this.pipe.transform(
            this.addEmployeeForm.value.dob,
            'yyyy-MM-dd'
        );
        const params = {
            // firstname: this.addEmployeeForm.value.FirstName,
            // lastname: this.addEmployeeForm.value.LastName,
            // username: this.addEmployeeForm.value.UserName,
            // email: this.addEmployeeForm.value.Email,
            // password: this.addEmployeeForm.value.Password,
            // confirmpassword: this.addEmployeeForm.value.ConfirmPassword,
            // employeeId: this.addEmployeeForm.value.EmployeeID,
            // joindate: DateJoin,
            // phone: this.addEmployeeForm.value.PhoneNumber,
            // company: this.addEmployeeForm.value.CompanyName,
            // department: this.addEmployeeForm.value.DepartmentName,
            // designation: this.addEmployeeForm.value.Designation,
            // mobile: '9944996335',
            // role: 'Web developer',
            company_id: this.CompanyID,
            client_id: this.ClientID,
            employee_no: this.addEmployeeForm.value.employee_no,
            title: this.addEmployeeForm.value.title,
            first_name: this.addEmployeeForm.value.first_name,
            middle_name: this.addEmployeeForm.value.middle_name,
            sur_name: this.addEmployeeForm.value.sur_name,
            sex: this.addEmployeeForm.value.sex,
            dob: DateOfBirth,
            address_type: this.addEmployeeForm.value.address_type,
            employee_category: this.addEmployeeForm.value.employee_category,
            mobile: this.addEmployeeForm.value.mobile,
            email: this.addEmployeeForm.value.email_id,
            personal_email_id: this.addEmployeeForm.value.personal_email_id,
            country_id: this.addEmployeeForm.value.country_id,
            address_1: this.addEmployeeForm.value.address_1,
            address_2: this.addEmployeeForm.value.address_2,
            state_id: this.addEmployeeForm.value.state_id,
            city_id: this.addEmployeeForm.value.city_id,
            pincode: this.addEmployeeForm.value.pincode,
            nationality: this.addEmployeeForm.value.nationality,
            nationality_type: this.addEmployeeForm.value.nationality_type,
            marital_status: this.addEmployeeForm.value.marital_status,
            is_physically_challenged: this.addEmployeeForm.value.physically_challanged,
            physically_challenged_percentage: this.addEmployeeForm.value.physically_challanged_percentage,
            employee_id: this.addEmployeeForm.value.employee_id,
        };
        const employee_id = this.addEmployeeForm.value.employee_id;
        if ((employee_id !== undefined && employee_id !== '') && employee_id !== null) {
            this.ApiModuleService.edit(params, 'admin/employees/update').subscribe((data) => {
                    this.getEmployeesData();


                    this.toastr.success('Employeee updated sucessfully...!', 'Success');
                }, (err) => {
                  this.spinnerService.toasterError(err);
                }
            );
        }
        else {
            this.ApiModuleService.add(params, 'admin/employees/add').subscribe((data) => {
                    this.getEmployeesData();


                    this.toastr.success('Employeee added sucessfully...!', 'Success');
                }, (err) => {
                  this.spinnerService.toasterError(err);
            });

        }
    }

    changeText() {
        this.text = true;
    }

    changeTexts() {
        this.text = false;
    }

    showAddForm() {
        this.ShowForm = true;
    }

    documentpages(value) {
        this.employeesDetails = value;
    }

    showList() {
        this.ShowForm = false;
    }

    // to know the date picker changes
    from(data) {
        this.DateJoin = this.pipe.transform(data, 'dd-MM-yyyy');
    }

    // edit modal api call

    // To Get The employee Edit Id And Set Values To Edit Modal Form
    public editValue(employee_id: any) {
        this.resetForm();
        const params: any = {};
        params.client_id = this.ClientID;
        params.employee_id = employee_id;
        params.company_id = this.CompanyID;
        this.ApiModuleService.edit(params, 'admin/employees/edit').subscribe((data) => {
                const employeeDetail = (data.data.employee !== undefined) ? data.data.employee : [];
                this.addEmployeeForm.setValue({
                    //  company_id:employeeDetail.company_id,
                    //  client_id:employeeDetail.client_id,
                    employee_no: employeeDetail.employee_no,
                    title: employeeDetail.title,
                    first_name: employeeDetail.first_name,
                    middle_name: employeeDetail.middle_name,
                    sur_name: employeeDetail.sur_name,
                    sex: employeeDetail.sex,
                    dob: employeeDetail.dob,
                    address_type: employeeDetail.address_type,
                    mobile: employeeDetail.mobile,
                    email_id: employeeDetail.email,
                    personal_email_id: employeeDetail.personal_email_id,
                    country_id: employeeDetail.country_id,
                    address_1: employeeDetail.address_1,
                    address_2: employeeDetail.address_2,
                    state_id: employeeDetail.state_id,
                    city_id: employeeDetail.city_id,
                    pincode: employeeDetail.pincode,
                    nationality: employeeDetail.nationality,
                    nationality_type: employeeDetail.nationality_type,
                    marital_status: employeeDetail.marital_status,
                    physically_challanged: employeeDetail.is_physically_challenged,
                    employee_category: employeeDetail.employee_category,
                    physically_challanged_percentage: employeeDetail.physically_challenged_percentage,
                    employee_id: employee_id
                });

                this.ShowForm = true;
            }, (err) => {
              this.spinnerService.toasterError(err);
        });
    }

    // delete employee data api call
    deleteEmployee() {
        this.ApiModuleService.delete(this.tempId, '').subscribe((data) => {

        });
        $('#delete_employee').modal('hide');
        this.toastr.success('Employee deleted sucessfully..!', 'Success');
    }

    public resetForm() {
        this.addEmployeeForm.reset();
        setTimeout(() => {
            this.physicalvalue = '0';
        }, 100)

    }


    // getting the status value
    getStatus(data) {
        this.statusValue = data;
    }

    ngOnDestroy(): void {
        // Do not forget to unsubscribe the event
        //
    }

    changeStatus(status: any, employee_id: any) {
        const params: any = {};
        params.employee_id = employee_id;
        params.company_id = this.CompanyID;
        params.client_id = this.ClientID;
        this.ApiModuleService.edit(params, 'admin/employees/' + status).subscribe((data) => {
                this.getEmployeesData();
                ($('#employee-status') as any).modal('hide');
                this.toastr.success('Status  updated sucessfully...!', 'Success');
            }, (err) => {
              this.spinnerService.toasterError(err);
        });
    }


    getDropDown() {
        if (this.CompanyDropDown.length !== 0 && this.CompanyID !== null) {
            const params: any = {};
            params.client_id = this.ClientID;
            params.company_id = this.CompanyID;
            this.ApiModuleService.list('admin/employees/dropdown', params).subscribe((data) => {


                this.dropdownTitle = data.data.employee[0] !== undefined ? data.data.employee[0] : [];
                this.dropdownname = data.data.employee;
                this.dropdownGender = data.data.employee[1] !== undefined ? data.data.employee[1] : [];
                this.dropdownNationality = data.data.employee[2] !== undefined ? data.data.employee[2] : [];
                this.dropdownNationalityType = data.data.employee[3] !== undefined ? data.data.employee[3] : [];
                this.dropdownMarital = data.data.employee[4] !== undefined ? data.data.employee[4] : [];
                this.dropdownDocument = data.data.employee[5] !== undefined ? data.data.employee[5] : [];
                this.dropdownAddressType = data.data.employee[6][0].address_type !== undefined ? data.data.employee[6][0].address_type : [];
                this.physicallyDropdown = data.data.employee[7] !== undefined ? data.data.employee[7] : [];
                this.employeeCategory = data.data.employee[8] !== undefined ? data.data.employee[8] : [];


            });
        } else {
            this.dropdownTitle = [];
            this.dropdownname = [];
            this.dropdownGender = [];
            this.dropdownNationality = [];
            this.dropdownNationalityType = [];
            this.dropdownMarital = [];
            this.dropdownDocument = [];
            this.dropdownAddressType = [];
            this.physicallyDropdown = [];
            this.employeeCategory = [];
        }
    }

    public getCountries() {
        this.ApiModuleService.get('country/list').subscribe((data) => {
            const res: any = data;
            this.countriesList = (res.data.countries !== undefined) ? res.data.countries : [];
        }, (err) => {
          this.spinnerService.toasterError(err);
    });
    }

    public getStates() {
        var params: any = {};
        params.country_id = this.addEmployeeForm.get('country_id').value;
        this.ApiModuleService.list('country/states', params).subscribe((data) => {
            this.statesList = (data.data.states !== undefined) ? data.data.states : [];
        }, (err) => {
          this.spinnerService.toasterError(err);
    });
    }

    public getCities() {
        const params: any = {};
        params.state_id = this.addEmployeeForm.get('state_id').value;
        this.ApiModuleService.list('country/cities', params).subscribe((data) => {
            this.citiesList = (data.data.states !== undefined) ? data.data.states : [];
        }, (err) => {
          this.spinnerService.toasterError(err);
    });
    }

    // Get all Nationality Type data


    /* client DropDown codes*/
    public GetClientDropDown() {
        this.ApiModuleService.get('DropDown/client').subscribe((data) => {
            this.ClientDropDown = data.data.client;
            this.ClientID = data.data.client[0].client_id;
            this.GetCompanyDropDown();
        },(err:any)=>{
          this.spinnerService.toasterError(err);
        })
    }

    /* company DropDown codes */
    public GetCompanyDropDown() {
        const params: any = {};
        params.client_id = this.ClientID;
        this.ApiModuleService.list('DropDown/company', params).subscribe((data) => {
                this.CompanyDropDown = data.data.company != undefined ? data.data.company : [];
                if (this.CompanyDropDown.length !== 0) {
                    setTimeout(() => {
                        this.CompanyID = this.CompanyDropDown[0].company_id;
                        this.getDropDown();
                        this.getEmployeesData();
                    }, 100)
                }
                else {
                    this.CompanyID = null;
                    this.rerender();
                }
        },(err:any)=>{
          this.spinnerService.toasterError(err);
        })
    }


  clear(table: Table) {
      table.clear();
  }

  getSeverity(status: string) {
      switch (status.toLowerCase()) {
          case 'inactive':
              return 'danger';

          case 'active':
              return 'success';
      }
  }
    getAcronym(str: any) {
        let matches = str.match(/\b(\w)/g); // ['J','S','O','N']
        matches = matches.slice(0, 2);
        const acronym = matches.join(''); // JSON
        return acronym;
    }

    getBgColors(str: any) {
        const min = 0;
        const max = 15;
        const index = this.findAlphapositions(str, 1);
        return this.nameBgColors[index];
    }

    findAlphapositions(str: any, n: any) {
        const NUM = 31;

        return str[0].charCodeAt(0) & NUM;
    }
    callStatusModel(url_name:any,data:any,actionName:string){
        this.statusApi=url_name;
        this.statusValues=data;
    }


}
