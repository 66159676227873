// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.col-md-2
{
  width: 10% !important;
}
.inline-flex
{
  display: inline-flex;
}

.form-group-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%; /* Set a fixed width for the wrapper */
}

.form-group-wrapper .dropdownMargin {
  flex-basis: calc(25% - 10px);
  margin-right: -10px;
  margin-bottom: 10px; /* Add margin between rows */
}

.labelColumn{
  width: 185px;
}

`, "",{"version":3,"sources":["webpack://./src/app/all-modules/company/shifts/monthly-shift/monthly-shift.component.css"],"names":[],"mappings":"AAAA;;EAEE,qBAAqB;AACvB;AACA;;EAEE,oBAAoB;AACtB;;AAEA;EACE,aAAa;EACb,eAAe;EACf,8BAA8B;EAC9B,WAAW,EAAE,sCAAsC;AACrD;;AAEA;EACE,4BAA4B;EAC5B,mBAAmB;EACnB,mBAAmB,EAAE,4BAA4B;AACnD;;AAEA;EACE,YAAY;AACd","sourcesContent":[".col-md-2\r\n{\r\n  width: 10% !important;\r\n}\r\n.inline-flex\r\n{\r\n  display: inline-flex;\r\n}\r\n\r\n.form-group-wrapper {\r\n  display: flex;\r\n  flex-wrap: wrap;\r\n  justify-content: space-between;\r\n  width: 100%; /* Set a fixed width for the wrapper */\r\n}\r\n\r\n.form-group-wrapper .dropdownMargin {\r\n  flex-basis: calc(25% - 10px);\r\n  margin-right: -10px;\r\n  margin-bottom: 10px; /* Add margin between rows */\r\n}\r\n\r\n.labelColumn{\r\n  width: 185px;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
