
import { Component, OnInit, AfterViewInit, OnDestroy, Input, EventEmitter, Output } from '@angular/core';
import { ApiModulesService } from '../../../api-modules.service';
import { AllModulesService } from '../../../all-modules.service';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import { Subscription, Subject, } from 'rxjs';
import { Table } from 'primeng/table';
import { SpinnerService } from 'src/app/snipper/spinner.service';
declare const $: any;
@Component({
  selector: 'app-leaveplantype',
  templateUrl: './leaveplantype.component.html',
  styleUrls: ['./leaveplantype.component.css']
})
export class LeaveplantypeComponent implements OnInit, OnDestroy {

  public leavedata = [];
  public listHeaders = [];
  public activeIndex = 0;
  public srch = [];
  tabToGrid = false;
  ClientDropDown = [];
  CompanyDropDown = [];
  selectedLeaves = [];
  public addLeaveForm: FormGroup
  public clientname = [];
  leaveRuleDetails:any;
  public totalRecords: any = 0;
  public ShowForm: any = false;
  ClientID: any;
  CompanyID: any;
  LeaveMaster = [];
  @Input() authLeaveData: any = {};
  @Output() parenFun: EventEmitter<any> = new EventEmitter();
  statusApi: any;
  statusValues: any;
  nameBgColors: any = ["bg-blue-dim", "bg-blue-dim", "bg-azure-dim", "bg-indigo-dim", "bg-info-dim", "bg-purple-dim", "bg-pink-dim", "bg-orange-dim", "bg-teal-dim", "bg-primary-dim", "bg-success-dim", "bg-warning-dim", "bg-danger-dim", "bg-secondary-dim", "bg-dark-dim", "bg-gray-dim", "bg-blue-dim", "bg-azure-dim", "bg-indigo-dim", "bg-info-dim", "bg-purple-dim", "bg-pink-dim", "bg-orange-dim", "bg-teal-dim", "bg-primary-dim", "bg-success-dim", "bg-warning-dim", "bg-danger-dim", "bg-secondary-dim", "bg-dark-dim", "bg-gray-dim"];
  nameBorderColors: any = ["bg-outline-primary", "bg-outline-success", "bg-outline-info", "bg-outline-gray", "bg-outline-warning", "bg-outline-dark", "bg-outline-light", "bg-outline-secondary", "bg-outline-danger"];
  editedLeaveData: any;
  showTabs = false;
rulesEdited={'leave_id':'','leave_plan_type_id':'','plan_type_name':''}
  statuses: { label: string; value: string; }[];
  constructor(private apiModulesService: ApiModulesService,  private toaster: ToastrService, private toastr: ToastrService, private formBuilder: FormBuilder, private spinnerService: SpinnerService) { }

  ngOnInit(): void {
    this.getLeave();

    this.addLeaveForm = this.formBuilder.group({
      plan_type_name: ["", [Validators.required]],
      plan_type_desc: ["", [Validators.required]],
      client_id: ["", []],
      company_id: ["", []],
      plan_type_short: ["", [Validators.required]],
      leave_plan_type_id: ["", []],
      leave_plan_id: ["", []],
    });
    this.statuses = [
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ];
  }

  showAddForm() {
    this.ShowForm = true;
    this.resetForm();
  }
  ngOnDestroy(): void {

  }

  //Get all paycomponent data
  public getLeave() {
    this.spinnerService.raiseDataEmitterEvent('off');
    this.ShowForm = false;
    var params: any = {};
    params.client_id = this.authLeaveData.client_id;
    params.company_id = this.authLeaveData.company_id;
    params.leave_plan_id = this.authLeaveData.leave_plan_id;
    this.apiModulesService.list("admin/leavemanagement/plan/type/get", params).subscribe((data) => {
      this.leavedata = (data.data.leaves != undefined) ? data.data.leaves : [];
      if (this.leavedata.length == 0) {
        this.getImportLeave();
      }
      var totalRecords = (this.leavedata[0] != undefined) ? this.leavedata[0].total_records : 0;
      this.totalRecords = totalRecords;
      this.resetForm();
      setTimeout(() => {
        this.spinnerService.raiseDataEmitterEvent('off');
      }, 100);
    }, (err) => {
      this.leavedata = [];
      this.spinnerService.raiseDataEmitterEvent('off');

    });
  }
  clear(table: Table) {
    table.clear();
  }

  getSeverity(status: string) {
    switch (status.toLowerCase()) {
      case 'inactive':
        return 'danger';

      case 'active':
        return 'success';
    }
  }
  parentFun() {

    this.parenFun.emit(this.tabToGrid)
  }

  changeStatus(Status: any, data) {
    let params: any = {};
    params.leave_plan_type_id = data.leave_plan_type_id,
    params.client_id = data.client_id;
    params.company_id = data.company_id;
    params.leave_plan_id = data.leave_plan_id;
    this.apiModulesService.edit(params, 'admin/leavemanagement/plan/type/' + Status).subscribe((data) => {
      this.getLeave();
      ($('#leave-plan-status') as any).modal('hide');

      this.toaster.success('Status Changed Successfully!', 'Success')
    }, (err) => {
      this.toastr.error("Something went wrong!", "Try Again");
    }
    );
  }
  public importLeavePlan() {
    if (this.selectedLeaves.length == 0) {
      this.toastr.error('choose leave Types!', 'Error');

      return
    }
    var params: any = {};
    params.client_id = this.authLeaveData.client_id;
    params.company_id = this.authLeaveData.company_id;
    params.leave_plan_id = this.authLeaveData.leave_plan_id;
    params.import_leave_id = this.selectedLeaves.map((val: any) => val.leave_id).toString();

    this.apiModulesService.add(params, "admin/leavemanagement/plan/type/add",).subscribe((data) => {
      this.getLeave();
      ($('#import_alert_plan') as any).modal('hide');

      this.toastr.success('Leave Plan Imported Sucessfully...!', 'Success');
      this.selectedLeaves = [];
    }, err => {
      if (err.status === 437) {
        this.toastr.error('Leave Already Exists...!', 'Failed');
      } else {
        this.toastr.error('Something Went Wrong Please Try Again...!', 'Error');
      }
    });
  }
  public getImportLeave() {
    var params: any = {};
    this.apiModulesService.add(params, "admin/leavemanagement/plan/rule/get",).subscribe((data) => {
      this.LeaveMaster = (data.data.leaves != undefined) ? data.data.leaves : [];
    }, err => {
      if (err.status === 437) {
        this.toastr.error('Leave Already Exists...!', 'Failed');
      } else {
        this.toastr.error('Something Went Wrong Please Try Again...!', 'Error');
      }
    });
  }
  public updateLeaveRules(form:NgForm) {
   
    if (!form.valid) {
      // form.markAllAsTouched();
      return;
    }
    var params: any = {};
    params.client_id = this.authLeaveData.client_id;
    params.company_id = this.authLeaveData.company_id;
    params.leave_plan_id = this.authLeaveData.leave_plan_id;
    params.leave_plan_type_id = this.rulesEdited.leave_plan_type_id;
    params.leave_id = this.rulesEdited.leave_id;
    params.leave_rule_details = form.value;
    this.apiModulesService.add(params, "admin/leavemanagement/plan/rule/update",).subscribe((data) => {
      this.getLeave();
      this.leaveRuleFormReset(form);
      this.toastr.success('Leave Rules updated Sucessfully...!', 'Success');
      ($('#add_leaveplan_rules') as any).modal('hide');
      this.addLeaveForm.reset();
    }, err => {
      if (err.status === 437) {
        this.toastr.error('Leave Already Exists...!', 'Failed');
      } else {
        this.toastr.error('Something Went Wrong Please Try Again...!', 'Error');
      }
    });
  }
  public onAddLeavePlan() {
    if (!this.addLeaveForm.valid) {
      this.addLeaveForm.markAllAsTouched();
      return;
    }
    var params: any = {};
    params.client_id = this.authLeaveData.client_id;
    params.company_id = this.authLeaveData.company_id;
    params.leave_plan_id = this.authLeaveData.leave_plan_id;
    params.plan_type_name = this.addLeaveForm.get('plan_type_name').value;
    params.plan_type_desc = this.addLeaveForm.get('plan_type_desc').value;
    params.plan_type_short = this.addLeaveForm.get('plan_type_short').value;
    var leave_plan_type_id = this.addLeaveForm.get('leave_plan_type_id').value;

    if (leave_plan_type_id != '' && leave_plan_type_id != undefined) {
      params.leave_plan_type_id = this.addLeaveForm.get('leave_plan_type_id').value;
      this.apiModulesService.edit(params, "admin/leavemanagement/plan/type/update",).subscribe((data) => {

        this.getLeave();
        this.toastr.success('Leave Modified Sucessfully...!', 'Success');
        ($('#add_leaveplan') as any).modal('hide');
      }, err => {
        if (err.status === 437) {
          this.toastr.error('Leave Already Exists...!', 'Failed');
        } else {
          this.toastr.error('Something Went Wrong Please Try Again...!', 'Error');
        }
      });

    }
  }
  //Reset form
  public resetForm() {
    this.addLeaveForm.reset();
  }
  public editplan(leaves: any) {
    this.resetForm();
    const params: any = {};
    params.client_id = this.authLeaveData.client_id;
    params.company_id = this.authLeaveData.company_id;
    params.leave_plan_id = this.authLeaveData.leave_plan_id;
    params.leave_plan_type_id = leaves.leave_plan_type_id;

    this.apiModulesService.edit(params, 'admin/leavemanagement/plan/type/edit').subscribe((data) => {
      const leaveDetail = (data.data.leaves !== undefined) ? data.data.leaves : [];
      this.addLeaveForm.setValue({
        plan_type_name: leaveDetail.plan_type_name,
        plan_type_desc: leaveDetail.plan_type_desc,
        plan_type_short: leaveDetail.plan_type_short,
        leave_plan_type_id: leaveDetail.leave_plan_type_id,
        client_id: leaveDetail.client_id,
        company_id: leaveDetail.company_id,
        leave_plan_id: leaveDetail.leave_plan_id
      });

    }, (err) => {
      this.toastr.error('Something went wrong!', 'Try Again');
    }
    );
  }
  public editRuleplan(edited) {
    const params: any = {};
    params.client_id     = this.authLeaveData.client_id;
    params.company_id    = this.authLeaveData.company_id;
    params.leave_plan_id = this.authLeaveData.leave_plan_id;
    params.leave_id      = edited.leave_id;
    this.apiModulesService.edit(params, 'admin/leavemanagement/plan/rule/edit').subscribe((data) => {
      this.rulesEdited.leave_id=edited.leave_id
      this.rulesEdited.leave_plan_type_id=edited.leave_plan_type_id
      this.rulesEdited.plan_type_name=edited.plan_type_name
     this.leaveRuleDetails = (data.data.leaves !== undefined) ? data.data.leaves : [];
    }, (err) => {
      this.toastr.error('Something went wrong!', 'Try Again');
    }
    );
  }
 
leaveRuleFormReset(form:NgForm){
form.resetForm();
this.rulesEdited.leave_id=''
this.rulesEdited.leave_plan_type_id=''
}

  getAcronym(str: any) {
    if (str != null) {
      let matches = str.match(/\b(\w)/g); // ['J','S','O','N']
      matches = matches.slice(0, 2);
      const acronym = matches.join(''); // JSON
      return acronym;
    }
  }

  getBgColors(str: any) {
    if (str != null) {
      const min = 0;
      const max = 15;
      const index = this.findAlphapositions(str, 1);
      return this.nameBgColors[index];
    }
  }
  findAlphapositions(str: any, n: any) {
    var NUM = 31;

    return str[0].charCodeAt(0) & NUM;
  }
  callStatusModel(url_name: any, data: any, actionName: string) {
    this.statusApi = url_name;
    this.statusValues = data;
  }
}