// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

:host ::ng-deep .p-inputnumber-input{
    min-width: 167px !important;
    max-width: 100%;
}
:host ::ng-deep .p-inputnumber-input {
    width: 167px !important;
}
.statusactive{
	font-size: 30px;
}`, "",{"version":3,"sources":["webpack://./src/app/all-modules/employees/education/education.component.css"],"names":[],"mappings":";;AAEA;IACI,2BAA2B;IAC3B,eAAe;AACnB;AACA;IACI,uBAAuB;AAC3B;AACA;CACC,eAAe;AAChB","sourcesContent":["\r\n\r\n:host ::ng-deep .p-inputnumber-input{\r\n    min-width: 167px !important;\r\n    max-width: 100%;\r\n}\r\n:host ::ng-deep .p-inputnumber-input {\r\n    width: 167px !important;\r\n}\r\n.statusactive{\r\n\tfont-size: 30px;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
