import { Component,ElementRef} from '@angular/core';
import { SpinnerService } from 'src/app/snipper/spinner.service';
import { FormBuilder, FormControl, FormGroup, FormsModule, Validators } from '@angular/forms';
import { ApiModulesService } from 'src/app/all-modules/api-modules.service';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-nps',
  templateUrl: './nps.component.html',
  styleUrls: ['./nps.component.css']
})
export class NpsComponent {

  clientID = localStorage.getItem('client_id');
  companyID = localStorage.getItem('company_id');
  employeeID = localStorage.getItem('employee_id');
  MonthYearDate: any;
  selectedOption: string = '';
  addNpsForm: FormGroup;
  basicValue: any;
  percentage: number = 0;
  formatedDate: any=new Date();
  npsData: any;
  stopMonthYearDate: Date;
  formatedStopDate:any;
  changedByStop=false
  minDate:any;
  Currentstatus: any;
  isFormEnabled: boolean = false;
  constructor(private spinnerService: SpinnerService, private elementRef: ElementRef,
    private formBuilder: FormBuilder, private apiModulesService: ApiModulesService,
     private toastrService: ToastrService, private datePipe: DatePipe) { }
  ngOnInit(){
    const today = new Date();
    // Set minDate to the first day of the current month
    this.minDate = new Date(today.getFullYear(), today.getMonth(), 1);
    this.spinnerService.raiseDataEmitterEvent('off');
    const currentDate = new Date();
    this.MonthYearDate = currentDate;
    this.stopMonthYearDate = currentDate;

    this.addNpsForm = this.formBuilder.group({
      pran_no: [{ value: '', disabled: true }, Validators.required],
      percentage: [{ value: 0, disabled: true }, Validators.required],
      annual_value: [{ value: '', disabled: true }, Validators.required],
      monthly_value: [{ value: '', disabled: true }, Validators.required],
    });

    this.monthChange();
    this.stopMonthChange();
  }
  onRadioChange(value: string): void {
    if (value === 'yes') {
      this.isFormEnabled = true;
      this.addNpsForm.enable(); // Enable all form controls
    } else {
      this.isFormEnabled = false;
      this.addNpsForm.disable(); // Disable all form controls
    }
  }

  inputPercentage(event: any): void {
    const percentage = Number(event.target.value);
    let annualValue = 0;
    let monthlyValue = 0;

    if (this.basicValue?.tax_type_key === 'old_regime') {
      annualValue = (this.basicValue?.annual_max_nps_contribution || 0) * (percentage / 10);
      monthlyValue = (this.basicValue?.monthly_max_nps_contribution || 0) * (percentage / 10);
    } else if (this.basicValue?.tax_type_key === 'new_regime') {
      annualValue = (this.basicValue?.annual_max_nps_contribution || 0) * (percentage / 14);
      monthlyValue = (this.basicValue?.monthly_max_nps_contribution || 0) * (percentage / 14);
    }

    this.addNpsForm.patchValue({
      annual_value: annualValue.toFixed(2),
      monthly_value: monthlyValue.toFixed(2),
    });
  }

  monthChange() {
    this.formatedDate = this.datePipe.transform(this.MonthYearDate, 'MMM/yyyy') || this.MonthYearDate;
    this.getStartNpsData();

  }
  stopMonthChange() {
    this.formatedStopDate = this.datePipe.transform(this.stopMonthYearDate, 'MMM/yyyy') || this.stopMonthYearDate;
    this.getStopNpsData();

  }
  public onAddNps(status) {
    if (this.addNpsForm.invalid) {
      this.addNpsForm.markAllAsTouched();
      return;
  }

    var params: any = {};
    params.client_id = this.clientID;
    params.company_id = this.companyID;
    params.employee_id = this.employeeID;
    params.nps_status = status;
    params.percentage= this.basicValue;
    if (status=== 'stop') {
      params.pran_no = null
      params.percentage= null
      params.annual_value= null
      params.monthly_value= null
      params.year_and_month = this.formatedStopDate;

    } else if (status=== 'start') {
      params.pran_no= this.addNpsForm.get('pran_no').value;
      params.percentage= this.addNpsForm.get('percentage').value;
      params.annual_value= this.addNpsForm.get('annual_value').value;
      params.monthly_value= this.addNpsForm.get('monthly_value').value;
      params.year_and_month = this.formatedDate;

    }
    this.apiModulesService.add(params, 'employee-tabs/IND-employee-payroll/nps/add',).subscribe((data) => {
      this.addNpsForm.reset();
      this.toastrService.success('Nps added successfully...!', 'Success');
      this.getStartNpsData();
      this.getStopNpsData();


    }, (err) => {
      this.spinnerService.toasterError(err);
        });

  }
  getStartNpsData() {
    this.spinnerService.raiseDataEmitterEvent('on');
    const params: any = {};
    params.client_id = this.clientID;
    params.company_id = this.companyID;
    params.employee_id = this.employeeID;
    params.year_and_month = this.formatedDate;
    this.apiModulesService.list('employee-tabs/IND-employee-payroll/nps/start', params).subscribe((data) => {
      this.basicValue=data.data.basic_amount_nps[0]  != undefined ?data.data.basic_amount_nps[0]:[];
      this.npsData = data.data.employee_nps[0] != undefined ? data.data.employee_nps[0]:[];
      this.Currentstatus=data.data.basic_amount_nps[0].current_status  != undefined ?data.data.basic_amount_nps[0].current_status:null;
      if(this.npsData.length!=0){
        this.addNpsForm.setValue({
          pran_no: this.npsData.pran_no,
          annual_value: this.npsData.annual_value,
          monthly_value: this.npsData.monthly_value,
          percentage: this.npsData.percentage
        });
      }
      this.spinnerService.raiseDataEmitterEvent('off');

    },(err)=>{
      this.spinnerService.toasterError(err);

    });
  }
  getStopNpsData() {
    this.spinnerService.raiseDataEmitterEvent('on');
    const params: any = {};
    params.client_id = this.clientID;
    params.company_id = this.companyID;
    params.employee_id = this.employeeID;
    params.year_and_month = this.formatedStopDate;
    this.apiModulesService.list('employee-tabs/IND-employee-payroll/nps/stop', params).subscribe((data) => {
      const respose:any=data.data.employee_nps[0]!=undefined?data.data.employee_nps[0]:[];
      if(respose.length!=0 && this.changedByStop){


        this.stopMonthYearDate=respose.year_and_month !=null?new Date(respose.year_and_month):this.stopMonthYearDate;
      }else{

        this.stopMonthYearDate=respose.year_and_month !=null?new Date(respose.year_and_month):null;

      }
      this.changedByStop=false;
      this.spinnerService.raiseDataEmitterEvent('off');

    },(err)=>{
      this.spinnerService.toasterError(err);

    });
  }

  resetForm() {
    this.addNpsForm.reset();
  }
}
