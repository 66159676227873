// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


export const environment = {
  production: false,
  apiUrl: 'https://essapi.azatecon.com/',
    fresh_work_api:'https://azateconcss.myfreshworks.com/login/auth/1727161439554?client_id=451979510707337272&redirect_uri=https%3A%2F%2Fazatecon6354894596173193.freshdesk.com%2Ffreshid%2Fcustomer_authorize_callback%3Fhd%3Dazatecon6354894596173193.freshdesk.com'

};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
