import { Component ,ViewChild} from '@angular/core';
import { SpinnerService } from 'src/app/snipper/spinner.service';
import { PreviousPayslipComponent } from './previous-payslip/previous-payslip.component';
@Component({
  selector: 'app-previous-salary-documents',
  templateUrl: './previous-salary-documents.component.html',
  styleUrls: ['./previous-salary-documents.component.css']
})
export class PreviousSalaryDocumentsComponent {
  activeTab="previous_payslip";
  @ViewChild(PreviousPayslipComponent)previousPayslipComponent: PreviousPayslipComponent;

    constructor( private spinnerService: SpinnerService){}


    ngOnInit()
    {
      this.spinnerService.raiseDataEmitterEvent('off');
     }

     changeTabs(activeTab){

      this.activeTab = activeTab;

     }
}
