import {Component, OnInit, ViewChild} from '@angular/core';

import {SpinnerService} from "../../../snipper/spinner.service";
import { ImportDropdownUploadComponent } from '../import-common/import-dropdown-upload/import-dropdown-upload.component';

@Component({
  selector: 'app-import-approver',
  templateUrl: './import-approver.component.html',
  styleUrls: ['./import-approver.component.css']
})
export class ImportApproverComponent  implements OnInit{
    @ViewChild(ImportDropdownUploadComponent) ImportGrid:ImportDropdownUploadComponent ;
   
    ExcelTypeKey = 'import_employee_details';
    ClientUserType='client_approve';
    totalRecords:any;
    constructor(
        protected spinnerService: SpinnerService
    ) {
    }

    ngOnInit(): void {      
    }
 
}