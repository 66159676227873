// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
table.dataTable{
	max-width: 100% !important;
}
.modal-title{
	font-size: 22px;
}
.inactive{
	display: none !important;
}
.formContainer{
	padding-left: 20px;
    padding-right: 20px;
}
.totalRecords{
	font-size: 16px;
}
.dataTable_empty{
	display:none;
}
mat-icon{
	visibility: hidden;
  }

  mat-list-item:hover mat-icon{
	visibility: visible;
  }
`, "",{"version":3,"sources":["webpack://./src/app/all-modules/company/company.component.css"],"names":[],"mappings":";AACA;CACC,0BAA0B;AAC3B;AACA;CACC,eAAe;AAChB;AACA;CACC,wBAAwB;AACzB;AACA;CACC,kBAAkB;IACf,mBAAmB;AACvB;AACA;CACC,eAAe;AAChB;AACA;CACC,YAAY;AACb;AACA;CACC,kBAAkB;EACjB;;EAEA;CACD,mBAAmB;EAClB","sourcesContent":["\r\ntable.dataTable{\r\n\tmax-width: 100% !important;\r\n}\r\n.modal-title{\r\n\tfont-size: 22px;\r\n}\r\n.inactive{\r\n\tdisplay: none !important;\r\n}\r\n.formContainer{\r\n\tpadding-left: 20px;\r\n    padding-right: 20px;\r\n}\r\n.totalRecords{\r\n\tfont-size: 16px;\r\n}\r\n.dataTable_empty{\r\n\tdisplay:none;\r\n}\r\nmat-icon{\r\n\tvisibility: hidden;\r\n  }\r\n\r\n  mat-list-item:hover mat-icon{\r\n\tvisibility: visible;\r\n  }\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
