import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Table } from 'primeng/table';
import { ApiModulesService } from 'src/app/all-modules/api-modules.service';
import { SpinnerService } from 'src/app/snipper/spinner.service';

@Component({
  selector: 'app-import-validation',
  templateUrl: './import-validation.component.html',
  styleUrls: ['./import-validation.component.css']
})
export class ImportValidationComponent {
  @Input() data_import_id: any;
  import_status: any;
  @Input() ClientID: any;
  @Input() CompanyID: any;
  @Input() ExcelTypeKey: any;
  @Input() ClientUserType: any;
  @Output() parenFun: EventEmitter<any> = new EventEmitter();
  showGrid = false;
  summaryMappeDHeaders: any;
  summaryData: any;
  sumHeaders: any;
  sumColumn: any;
  sumFilters: any;
  topHeaderFilter: any;
  sideHeaderFilter: any;
  importTypeID: any;
  importTypeData: any;
  summary_grid: any;
  FilterSummaryHeader: any[];
  summery_index: any;
  FilterSummaryData: any;
  validatorErrorData: any;
  showSumValButton: boolean;
  validation_detail_id: any;
  FilterEditData: any;
  ClientUploadExcelData: any;
  displayForm = 'All';
  ErrorShow: any = 'All';
  selectedValidationData = [];
  ValidationStatusCount:any;
  searchKeyword: any;
  events: string[];
  status='Validated';
  showhistory:any;
  eventStatus='client';
  validateHistoryData:any;

  constructor(protected apiModulesService: ApiModulesService,
    protected spinnerService: SpinnerService,
    protected toastrService: ToastrService,
  ) {
  }

  ngOnInit(): void {
    this.spinnerService.raiseDataEmitterEvent('on');
    this.showGrid = true;
    this.getDetails();
    this.events = [];
  }

  backToGrid() {
    this.showGrid = false;
    this.parenFun.emit(this.showGrid)


  }
  public getDetails() {

    const params: any = {};
    params.data_import_id = this.data_import_id;
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    params.event_status = this.eventStatus;

    this.apiModulesService.list('admin/import/validation/list', params).subscribe((data) => {
      this.spinnerService.raiseDataEmitterEvent('off');
      this.summaryData = data.data.dataDetails;
      this.sumHeaders = this.summaryData.headers;
      this.sumColumn = this.summaryData.column;
      this.sumFilters = this.summaryData.column;
      this.events=data.data.import_details;
      this.validateHistoryData=data.data.aza_client_history !=undefined?data.data.aza_client_history:[];
      this.getDatavalidation((data.data.validation != undefined) ? data.data.validation : []);
      this.summaryMappeDHeaders = this.summaryData.headers.map((val: any) => val.action_flag);
    }, (err) => {
      this.spinnerService.raiseDataEmitterEvent('off');

    }
    );
  }

  public FilterValidationDatas(topHeader: any, sideHeader: any) {
    this.topHeaderFilter = topHeader;
    this.sideHeaderFilter = sideHeader;
    if (sideHeader === 'Total') {
      sideHeader = '';
    }
    if (topHeader === 'Total' || topHeader === 'Actions') {
      topHeader = '';
    }
    const params: any = {};
    params.client_id = this.ClientID;
    params.data_import_id = this.data_import_id;
    params.company_id = this.CompanyID;
    params.action = sideHeader;
    params.header_name = topHeader;
    this.apiModulesService.list('admin/import/validation/summary/grid', params).subscribe((data) => {

      this.summary_grid = (data.data.filter_summary != undefined) ? data.data.filter_summary : [];
      this.importTypeData = (data.data.filter_summary[0] != undefined) ? data.data.filter_summary[0] : [];
      this.importTypeID = this.importTypeData[0].import_type_id;
      this.getSummaryBasedImportChoose(this.importTypeID);
      ($('#summary_filter_grid') as any).modal('show');
    }, (err) => {

      // this.toastrService.error('Something went wrong!', 'Try Again');
    }
    );

  }
  clear(table: Table) {
    table.clear();
  }
  getSummaryBasedImportChoose(importTypeId: any) {
    this.importTypeID = importTypeId;
    let Header = this.importTypeData.filter((data) => data.import_type_id === importTypeId).map((val) => val.column_list);
    this.FilterSummaryHeader = [];
    for (let i = 0; i < Header[0].length; i++) {
      let str = Header[0][i];
      this.FilterSummaryHeader[i] = str.replace(',', '')
    }
    this.summery_index = this.importTypeData.filter((x, index) => x.import_type_id === importTypeId).map((val, index) => val.index)[0];
    this.FilterSummaryData = this.summary_grid[this.summery_index];
  }
  public getDatavalidation(validationData) {

    this.validatorErrorData = validationData;
    const importstatus:any=this.events.filter((value: any) => value.active_status == 'true');

      this.import_status=importstatus[0].import_status_key !=undefined?importstatus[0].import_status_key:null;
      if(this.ClientUserType=='client_maker'){
          if (this.import_status=='Client_Input'|| this.import_status== 'Client_Validation') {
              this.showSumValButton = true;
          } else {
              this.showSumValButton = false;
          }
      }else if(this.ClientUserType=='client_approve'){
          if (this.import_status=='Client_Approval'|| this.import_status=='Client_Input'|| this.import_status== 'Client_Validation') {
              this.showSumValButton = true;
          } else {
              this.showSumValButton = false;
          }
      }

  }

  public editValue(validationDetailId, dataImportId, dataImportDetailId, showType, import_type_id) {

    this.validation_detail_id = validationDetailId;
    const params: any = {};
    params.data_import_id = dataImportId;
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    params.validation_detail_id = validationDetailId;
    params.data_import_detail_id = dataImportDetailId;
    params.import_type_id = import_type_id;
    this.apiModulesService.edit(params, 'admin/import/validation/edit').subscribe((data) => {
      this.FilterEditData = data.data.editValue;
      this.ClientUploadExcelData = data.data.editValue;
      this.setDisplayForm(showType);
      this.FilterEditFormData(showType);
    });
  };
  public setDisplayForm(value: string) {
    this.displayForm = value;
  }
  FilterEditFormData(value: any) {

    if (value === 'true') {
      this.ErrorShow = 'true';
      this.ClientUploadExcelData = this.FilterEditData.filter((data: any) => data.data_status === 'true')
    }
    else if (value === 'false') {
      this.ErrorShow = 'false';
      this.ClientUploadExcelData = this.FilterEditData.filter((data: any) => data.data_status === 'false')
    } else {
      this.ErrorShow = 'All';
      this.ClientUploadExcelData = this.FilterEditData;
    }
  }


  public onUpdatevalue(Error: NgForm) {
    let Error_object = [];
    for (let i = 0; i < this.FilterEditData.length; i++) {
      if (Error.value.hasOwnProperty(i) && (Object.keys(Error.value[i])[0] === this.FilterEditData[i].field_name)) {
        Error_object[i] = Error.value[i];
      }
      else {
        const field_name: any = {};
        field_name[this.FilterEditData[i].field_name] = this.FilterEditData[i].field_values;
        Error_object[i] = field_name;
      }
    }
    if (Error_object.length === this.FilterEditData.length) {
      const params: any = {};

      params.validation_detail_id = this.validation_detail_id;
      params.client_id = this.ClientID;
      params.company_id = this.CompanyID;
      params.client_values = Error_object;

      this.apiModulesService.edit(params, 'admin/import/validation/update').subscribe((data) => {
        this.getDetails();
        ($('#edit_errorValidation') as any).modal('hide');
        this.ErrorresetForm(Error);

        this.toastrService.success('Edit error Sucessfully...!', 'Success');
      });
    }
    else {
      this.getDetails();
      // ($('#edit_errorValidation') as any).modal('hide');
      this.toastrService.error('Something Went Wrong...! Try Again', 'Failed');

    }

  }

  public ErrorresetForm(Error: NgForm) {
    Error.resetForm();

  }


  public openClientApprover(){
    this.selectedValidationData=this.validatorErrorData;
    let promise=new Promise((resolve, reject) => {
        // Replace with actual validation logic
            resolve(this.validateExcelData('status_update'));

    });
      promise.then((validationMessage) => {
          ($('#status_updater') as any).modal('show');
          // return this.statusUpdater(validationMessage);
      }).catch((error) => {
          this.toastrService.error('Something went wrong!', 'Try Again');
          });
  }
  public validateExcelData(action) {
    if (this.selectedValidationData.length == 0) {
      return
    }
    this.spinnerService.raiseDataEmitterEvent('on')
    const params: any = {};
    params.data_import_id = this.data_import_id;
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    params.validation_details_id = this.selectedValidationData.map((val: any) => val.validation_detail_id).toString();
    this.apiModulesService.list('admin/validation/template_1', params).subscribe((data) => {
      this.selectedValidationData = [];
      this.ValidationStatusCount=data.data;
      this.getDetails();
      this.spinnerService.raiseDataEmitterEvent('on')
    if(action=='validate'){
    this.toastrService.success('validated SuccessFully', 'Success');
      }
    }, (err) => {

      this.toastrService.error('Something went wrong!', 'Try Again');
    }
    );
  }


  /* Download Excel File */
  DownloadExcel() {
    if (this.selectedValidationData.length == 0) {
      return
    }
    this.spinnerService.raiseDataEmitterEvent('on')
    const params: any = {};
    params.data_import_id = this.data_import_id;
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    params.validation_detail_id = this.selectedValidationData.map((val: any) => val.validation_detail_id).toString();
    this.apiModulesService.list('admin/excel-imports/excel-export/download', params).subscribe((data) => {
      const file_name = data.data.excelExport.file_name;
      const linkSource =
        'data:application/octet-stream;base64,' + data.data.excelExport.excel;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = file_name;
      downloadLink.click();
      this.selectedValidationData = [];
      this.getDetails();
      this.spinnerService.raiseDataEmitterEvent('off')

    },(err:any)=>{
      this.selectedValidationData = [];

      this.spinnerService.raiseDataEmitterEvent('off')
    });

  }
  changeHistoryToSummary(value) {
    this.showhistory = value;
    this.getDetails();
}

openHistory(){
  this.showhistory = true;

}
  statusUpdater(){
      this.spinnerService.raiseDataEmitterEvent('on')
      const params: any = {};
      params.data_import_id = this.data_import_id;
      params.client_id = this.ClientID;
      params.company_id = this.CompanyID;
      params.import_status_key=this.ClientUserType=='client_approve'?'Aza_Validation':'Client_Approval';
      params.validation_details_id = this.validatorErrorData.map((val: any) => val.validation_detail_id).toString();
      this.apiModulesService.list('admin/import/common/status-update', params).subscribe((data) => {
          this.selectedValidationData = [];
          this.getDetails();
          ($('#status_updater') as any).modal('hide');
          this.spinnerService.raiseDataEmitterEvent('off')

      },(err:any)=>{
          this.selectedValidationData = [];

          this.spinnerService.raiseDataEmitterEvent('off')
      });

  }
}
