import { Component, EventEmitter, NgModule, OnInit, Output } from '@angular/core';
import { Table } from 'primeng/table';
import { FormArray, FormBuilder, FormControl, FormGroup, FormsModule, Validators } from '@angular/forms';
import { ApiModulesService } from 'src/app/all-modules/api-modules.service';
import { SpinnerService } from 'src/app/snipper/spinner.service';
import { ToastrService } from 'ngx-toastr';
import { documentservice } from 'src/app/all-modules/documents.sevice';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-claim-reimbursement',
  templateUrl: './claim-reimbursement.component.html',
  styleUrls: ['./claim-reimbursement.component.css']
})
@NgModule({
  imports: [FormsModule],
})
export class ClaimReimbursementComponent implements OnInit {
  @Output() toggleVisibility = new EventEmitter<boolean>();

  claimRIBData = [];
  nameBgColors: any = ['bg-blue-dim', 'bg-blue-dim', 'bg-azure-dim', 'bg-indigo-dim', 'bg-info-dim', 'bg-purple-dim', 'bg-pink-dim', 'bg-orange-dim', 'bg-teal-dim', 'bg-primary-dim', 'bg-success-dim', 'bg-warning-dim', 'bg-danger-dim', 'bg-secondary-dim', 'bg-dark-dim', 'bg-gray-dim', 'bg-blue-dim', 'bg-azure-dim', 'bg-indigo-dim', 'bg-info-dim', 'bg-purple-dim', 'bg-pink-dim', 'bg-orange-dim', 'bg-teal-dim', 'bg-primary-dim', 'bg-success-dim', 'bg-warning-dim', 'bg-danger-dim', 'bg-secondary-dim', 'bg-dark-dim', 'bg-gray-dim'];
  nameBorderColors: any = ['bg-outline-primary', 'bg-outline-success', 'bg-outline-info', 'bg-outline-gray', 'bg-outline-warning', 'bg-outline-dark', 'bg-outline-light', 'bg-outline-secondary', 'bg-outline-danger'];
  statusApi: any;
  statusValues: any;
  public gridListView: boolean = true;
  editMode: boolean = false;
  activeIndex: any;
  singleBoxCheck: boolean = false;
  originalClaimValue: string;
  clientID = localStorage.getItem('client_id');
  companyID = localStorage.getItem('company_id');
  employeeID = localStorage.getItem('employee_id');

  financialId: any;
  addClaimForm: FormGroup;
  addLTAEligibleForm: FormGroup;
  EmpReimbursementId: any;
  cardBgColors = [
    'rgba(253,126,20,.25)', 'rgba(241,0,117,.25)', 'rgba(0,204,204,.25)', 'rgb(18,182,89,.25)', 'rgba(91,71,251,.2)'
  ]
  cardBorderColors = [
    '25px 19px 27px -20px rgba(253,126,20,.25)', '25px 19px 27px -20px rgba(0,204,204,.25)', '25px 19px 27px -20px rgba(91,71,251,.2)', '25px 19px 27px -20px #fbfcd7', '25px 19px 27px -20px #d9b8f5'
  ]
  filedata: any;
  oldPath: any;
  isHovered: boolean = false;
  url: string = '';
  urlSafe: SafeResourceUrl;
  awsimages: any;
  isHoveredeligible: boolean = false;
  isFileShow = false;
  isButtonVisible = true;
  transportData = [
    {
      selected_value: 'Air',
      selected_text: 'Air'
    },
    {
      selected_value: 'Train',
      selected_text: 'Train'
    },
    {
      selected_value: 'Road',
      selected_text: 'Road'
    },

  ]

  previousfromDate: Date;
  previoustoDate: Date;
  activeTab: string;

  isCurrentYears: boolean = true;
  activeCard: 'current' | 'previous' = 'current';
  public ShowForm: any = true;
  ltaEligibilityData: any;
  ltaYearData: any;
  ltaActiveIndex: any;
  previousYearData: any;
  currentYearData: any;
  date = new Date();

  constructor(private spinnerService: SpinnerService,
    private apiModulesService: ApiModulesService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder,
    private documentService: documentservice,
    public sanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit() {

    this.previousfromDate = new Date(2018, 0); // January 1, 2018
    this.previoustoDate = new Date(2021, 11, 31); // December 31, 2021
    this.addClaimForm = this.formBuilder.group({
      claim_under_process: [''],
      emp_reimbursement_id: [''],
      file_path: new FormControl({ value: null, disabled: !this.editMode })
    });
    this.getclaimRIBData();
    // this.addLTAEligibleForm = this.formBuilder.group({
    //   lta_applied_date: ["", [Validators.required]],
    //   from_date: ["", [Validators.required]],
    //   to_date: ["", [Validators.required]],
    //   lta_claimed: ["", [Validators.required]],
    //   mode_of_transport: ["", [Validators.required]],
    //   from_location: ["", [Validators.required]],
    //   to_location: ["", [Validators.required]],
    //   client_id: ["", []],
    //   company_id: ["", []],
    //   employee_id: ["", []]

    // });

    this.addLTAEligibleForm = this.formBuilder.group({
      lta_eligible: this.formBuilder.array([]),
    });
  }

  showCurrentYears() {
    this.isCurrentYears = true;
    const date = this.currentYearData?.max_from_month_year || new Date().toISOString();  // Set default to today if undefined
    this.getLTAEligibilityData(date);
    this.activeCard = 'current';
    this.cdr.detectChanges();
  }

  showPreviousYears() {
    this.isCurrentYears = false;
    this.activeCard = 'previous';
    const date = this.previousYearData?.max_from_month_year || new Date().toISOString();  // Set default to today if undefined
    this.getLTAEligibilityData(date);
    this.cdr.detectChanges();
  }
  ltaEligibilityButton() {
    const date = this.date.toISOString().split('T')[0];
    this.getLTAEligibilityData(date);
  }

  ltaEligibilityBack() {
    this.isFileShow = false;
    this.activeCard = 'current';
  }
// Method to hide parent buttons
onLtaEligibilityClick(): void {
  this.toggleVisibility.emit(false); // Emit 'false' to hide parent buttons
}

// Method to show parent buttons
onBackClick(): void {
  this.toggleVisibility.emit(true); // Emit 'true' to show parent buttons
}
  hideButton() {
    this.ShowForm = true;
  }
  getclaimRIBData() {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
    this.spinnerService.raiseDataEmitterEvent('on');
    const params: any = {};
    params.client_id = this.clientID;
    params.company_id = this.companyID;
    params.employee_id = this.employeeID;
    params.month_name = currentMonth;
    this.apiModulesService.list('employee-tabs/IND-employee-payroll/claim-reimbursement/list', params).subscribe((data) => {
      this.claimRIBData = data.data.claim_reimbursement != undefined ? data.data.claim_reimbursement : [];
      this.spinnerService.raiseDataEmitterEvent('off');
      this.getLtaYearData();
    }, (err) => {
      this.spinnerService.toasterError(err);
    });
  }
  uploadViewFile(filepath) {
    this.url = filepath != null ? 'data:application/pdf;base64,' + filepath : null;
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
  }

  /* File onchange event */
  fileEvent(e) {
    this.filedata = e.target.files[0];
  }

  // Function to get the current financial year based on the current date
  getCurrentFinancialYear() {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const nextYear = currentYear + 1;

    const financialYearStartMonth = 4;
    const currentMonth = currentDate.getMonth() + 1;

    const financialYear = currentMonth >= financialYearStartMonth
      ? `${currentYear}-${nextYear}`
      : `${currentYear - 1}-${currentYear}`;
    return financialYear
  }

  onAddClaim(item) {
    this.financialId = this.getCurrentFinancialYear();
    const myFormData = new FormData();
    myFormData.append('client_id', this.clientID);
    myFormData.append('company_id', this.companyID);
    myFormData.append('employee_id', this.employeeID);
    myFormData.append('file_path', this.filedata);
    myFormData.append('emp_reimbursement_id', item);
    myFormData.append('financial_id', this.financialId);
    myFormData.append('claim_under_process', this.addClaimForm.get('claim_under_process').value);
    myFormData.append('old_path', this.oldPath);

    this.documentService.post('api/employee-tabs/IND-employee-payroll/claim-reimbursement/upload', myFormData).subscribe((data) => {
      this.addClaimForm.reset();
      this.getclaimRIBData();
      this.filedata = null;
      this.oldPath = null;
      this.toastrService.success('Claim Updated Successfully...!', 'Success');
    }, (err) => {
      this.spinnerService.toasterError(err);
    });

    this.activeIndex = null;
    this.editMode = false;
  }
  getLtaYearData() {
    this.spinnerService.raiseDataEmitterEvent('on');
    const params: any = {};
    params.client_id = this.clientID;
    this.apiModulesService.list('employee-tabs/IND-employee-payroll/claim-reimbursement/getyear', params).subscribe((data) => {
      this.ltaYearData = data.data.lta_previous_current != undefined ? data.data.lta_previous_current : [];
      this.previousYearData = this.ltaYearData.find(item => item.datakey === "previous_year") || {};
      this.currentYearData = this.ltaYearData.find(item => item.datakey === "current_year") || {};
      this.spinnerService.raiseDataEmitterEvent('off');

    }, (err) => {
      this.spinnerService.toasterError(err);
    });
  }
  getLTAEligibilityData(date: string) {
    this.spinnerService.raiseDataEmitterEvent('on');
    const params: any = {};
    params.client_id = this.clientID;
    params.company_id = this.companyID;
    params.employee_id = this.employeeID;
    params.date = date;
    this.apiModulesService.list('employee-tabs/IND-employee-payroll/claim-reimbursement/getlist', params).subscribe((data) => {
      this.ltaEligibilityData = data.data.lta_eligibility != undefined ? data.data.lta_eligibility : [];
      setTimeout(()=>{
        this.resetForm();
        this.spinnerService.raiseDataEmitterEvent('off');

      },100)

    }, (err) => {
      this.spinnerService.toasterError(err);
    });
  }

  updateLTAEligible(index: number) {
    const ltaEligibleArray = this.addLTAEligibleForm.get('lta_eligible') as FormArray;
    const eligibility = ltaEligibleArray.at(index).value;
    const params: any = {
      client_id: this.clientID,
      company_id: this.companyID,
      employee_id: this.employeeID,
      lta_eligible_id: eligibility.lta_eligible_id,
      lta_applied_date: eligibility.lta_applied_date,
      from_date: eligibility.from_date,
      to_date: eligibility.to_date,
      lta_claimed: eligibility.lta_claimed,
      mode_of_transport: eligibility.mode_of_transport,
      from_location: eligibility.from_location,
      to_location: eligibility.to_location,
    };
    this.apiModulesService.add(params, "employee-tabs/IND-employee-payroll/claim-reimbursement/update").subscribe((data) => {
          this.toastrService.success('LTA Modified Successfully!', 'Success');
          setTimeout(() => {
            const date = this.date.toISOString().split('T')[0];
            this.getLTAEligibilityData(date);
          }, 100)
        },
        (err) => {
          this.spinnerService.toasterError(err);
        }
      );
  }
  get itemsList(): FormArray {
    return this.addLTAEligibleForm.get('lta_eligible') as FormArray;
  }
  addNewItems(value: any): void {
    this.itemsList.push(this.createLTAEligible(value));
  }
  createLTAEligible(control_value: any): FormGroup {
    return this.formBuilder.group({
      year_label: [control_value.year_label, []],
      year_from_to_date: [control_value.year_from_to_date, []],
      lta_status: [control_value.lta_status, []],
      lta_applied_date: [control_value.lta_applied_date, [Validators.required]],
      from_date: [control_value.from_date, [Validators.required]],
      to_date: [control_value.to_date, [Validators.required]],
      annual_eligibility: [control_value.annual_eligibility],
      lta_claimed: [control_value.lta_claimed, [Validators.required]],
      mode_of_transport: [control_value.mode_of_transport, [Validators.required]],
      from_location: [control_value.from_location, [Validators.required]],
      to_location: [control_value.to_location, [Validators.required]],
      lta_eligible_id: [control_value.lta_eligible_id, [Validators.required]]
    });
  }
  resetForm() {
    this.addClaimForm.reset();
    if (this.isFileShow) {
      const timeStatusArray: any = this.addLTAEligibleForm.get('lta_eligible') as FormArray;
      timeStatusArray.clear();
      this.ltaEligibilityData.forEach((val, key) => {
        this.addNewItems(val);
      })
    }
    this.isFileShow = true;
  }
  addCurrentLTA() {
    this.ltaYearData.forEach((val, key) => {
      this.addNewItems(val);
    })
  }
  changeTabsViewToGrid(value) {
  }
  showGrid() {
    this.gridListView = true;
  }
  showListview() {
    this.gridListView = false;
  }
  showGridView() {
    this.gridListView = true;
  }
  hideGridView() {
    this.gridListView = false;
  }
  toggleTextbox(index: any, editedData: any) {
    this.editMode = !this.editMode;
    if (this.editMode) {
      this.addClaimForm.get('file_path')?.enable();
    } else {
      this.addClaimForm.get('file_path')?.disable();
    }
    this.activeIndex = index
    this.oldPath = editedData.file_path
    this.addClaimForm.patchValue({
      claim_under_process: editedData.claim_under_process
    })
  }
  saveChanges() {

    this.activeIndex = null
    this.editMode = false;

  }
  toggleGridTextbox(event: any, index: any, editedData: any) {
    this.editMode = !this.editMode;
    if (this.editMode) {
      this.addClaimForm.get('file_path')?.enable();
    } else {
      this.addClaimForm.get('file_path')?.disable();
    }
    if (event.target.checked == true) {
      this.activeIndex = index;
      this.oldPath = editedData.file_path
      this.addClaimForm.patchValue({
        claim_under_process: editedData.claim_under_process
      })
    } else {
      this.activeIndex = null
      this.oldPath = null

    }
  }
  saveGridChanges(event: any, index: any) {
    // After saving, hide the textbox
    this.activeIndex = null
  }
  setActiveTab(tab: string) {
    this.activeTab = tab;
  }
  getBgColors(str: any) {
    const min = 0;
    const max = 15;
    const index = this.findAlphapositions(str, 1);
    return this.nameBgColors[index];
  }
  findAlphapositions(str: any, n: any) {
    const NUM = 31;

    return str[0].charCodeAt(0) & NUM;
  }
  clear(table: Table) {

    table.clear();
  }
  getSeverity(status: string) {
    switch (status.toLowerCase()) {
      case 'inactive':
        return 'danger';

      case 'active':
        return 'success';
    }
  }
  getAcronym(str: any) {
    let matches = str.match(/\b(\w)/g); // ['J','S','O','N']
    matches = matches.slice(0, 3);
    const acronym = matches.join(''); // JSON
    return acronym;
  }
  callStatusModel(url_name: any, data: any, actionName: string) {
    this.statusApi = url_name;
    this.statusValues = data;
  }
  toggleHover(value: boolean) {
    this.isHovered = value;
  }

}

