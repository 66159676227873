import { Component,OnInit,ViewChild ,AfterViewInit } from '@angular/core';
import { ApiModulesService } from '../../api-modules.service';
import { FormGroup, FormBuilder, Validators, } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

import { Subject, config } from 'rxjs';
import {NgForm} from '@angular/forms';
import { SpinnerService } from 'src/app/snipper/spinner.service';
import { Table } from 'primeng/table';
import { NgFor } from '@angular/common';

@Component({
  selector: 'app-companyudvalues',
  templateUrl: './companyudvalues.component.html',
  styleUrls: ['./companyudvalues.component.css']
})
export class CompanyudvaluesComponent implements OnInit,AfterViewInit{



  isDtInitialized = false;
  companyvalueData:any;
    FilterCompanyValueData=[];
  udValueDropDown=[];
  companyvalueDetail=[];
  addcompanyValue:any;
  ClientDropDown=[];
  CompanyDropDown=[];
  financiallabelDropDown=[];
  company_ud_values_id:any;
  company_financial_year_id:any;
  ud_module_id:any;
  ud_values:any;
  udvalue:any;
  client_id:any;
  company_id:any;
  udmodulenameDropDown=[];
  selectedClientID: any;
  selectedCompanyID: any;
  ClientID:any;
  CompanyID:any;
  searchKeyword: string = '';
  public totalRecords: any = 0;
    loading=false;

    statuses: { label: string; value: string; }[];
  nameBgColors:any = ['bg-blue-dim','bg-blue-dim','bg-azure-dim','bg-indigo-dim','bg-info-dim','bg-purple-dim','bg-pink-dim','bg-orange-dim','bg-teal-dim','bg-primary-dim','bg-success-dim','bg-warning-dim','bg-danger-dim','bg-secondary-dim','bg-dark-dim','bg-gray-dim','bg-blue-dim','bg-azure-dim','bg-indigo-dim','bg-info-dim','bg-purple-dim','bg-pink-dim','bg-orange-dim','bg-teal-dim','bg-primary-dim','bg-success-dim','bg-warning-dim','bg-danger-dim','bg-secondary-dim','bg-dark-dim','bg-gray-dim'];
  nameBorderColors:any = ['bg-outline-primary','bg-outline-success','bg-outline-info','bg-outline-gray','bg-outline-warning','bg-outline-dark','bg-outline-light','bg-outline-secondary','bg-outline-danger'];
  statusApi: any;
  statusValues: any;

  constructor(private apiModuleService: ApiModulesService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder,private spinnerService: SpinnerService) { }

    ngOnInit(): void {
        this.spinnerService.raiseDataEmitterEvent('on');

        this.statuses = [
            { label: 'Active', value: 'active' },
            { label: 'Inactive', value: 'inactive' },
        ];
    this.GetClientDropDown();
  }

  ngAfterViewInit(): void {

  }

  rerender() {

  }

    //Get all companyvalue data
    public getCompanyvalue() {
        this.spinnerService.raiseDataEmitterEvent('on');
        var params:any={};
        params.client_id = this.ClientID;
        params.company_id = this.CompanyID;

        this.apiModuleService.list('admin/companyvalue/list', params).subscribe((data) => {

            this.companyvalueData = (data.data.company_values != undefined) ? data.data.company_values : [];
            this.loading = false;
            var totalRecords = (this.companyvalueData[0] != undefined) ? this.companyvalueData[0].total_records : 0;
            this.totalRecords = totalRecords;
            this.resetForm();
            setTimeout(() => {
                this.spinnerService.raiseDataEmitterEvent('off');
            }, 100);
        }, (err) => {
            this.companyvalueData = [];
            this.spinnerService.raiseDataEmitterEvent('off');
            // this.toastr.error('Something went wrong!', 'Try Again');
            // this.rerender();
        });
    }
    clear(table: Table) {
        table.clear();
    }

    getSeverity(status: string) {
        switch (status.toLowerCase()) {
            case 'inactive':
                return 'danger';

            case 'active':
                return 'success';
        }
    }
gridLoadBasedOnModule(){

        this.companyvalueData = this.FilterCompanyValueData.filter((value:any)=>value.ud_module_id===this.ud_module_id);
        var totalRecords = this.companyvalueData.length;
}
  public resetForm() {


    this.company_ud_values_id=null ||'';
    this.company_financial_year_id=null ||'';
    this.company_id=null ||'';
    this.client_id=null||'';
    this.ud_values=[];
    this.udvalue=[];
}

   // add paycomponent
   public onAddcompanyvalue(addcompanyValue : NgForm) {
    let udvalue={};
  for(let i=0;i<this.udValueDropDown.length;i++){
    udvalue[this.udValueDropDown[i].ud_field_key]=(addcompanyValue.value[i][this.udValueDropDown[i].ud_field_key] != undefined)?addcompanyValue.value[i][this.udValueDropDown[i].ud_field_key] :null;
   }

    var params:any = {};
    params.company_id = this.CompanyID;
    params.client_id = this.ClientID;
    // params.client_id = addcompanyValue.value.client_id;
    // params.company_id = addcompanyValue.value.company_id;
    params.company_financial_year_id = addcompanyValue.value.company_financial_year_id;
    // params.component_shot = addpayComponent.value.component_shot;
    // params.ud_module_id = addcompanyValue.value.ud_module_id;
       params.ud_module_id = this.ud_module_id;
    params.ud_values = udvalue;


    this.apiModuleService.add(params,'admin/companyvalue/add',).subscribe((data) => {
      this.toastrService.success('Companyvalue added successfully...!', 'Success');
        this.getCompanyvalue();
        ($('#add_companyvalue')as any).modal('hide');
        addcompanyValue.reset();
      }, (err) => {
        let errors = err.error.data.company_values;
        errors = errors.replace('SQLSTATE[42000]: [Microsoft][ODBC Driver 17 for SQL Server][SQL Server]', 'Error: ')
        errors = errors.replace('SQLSTATE[42000]: [Microsoft][ODBC Driver 18 for SQL Server][SQL Server]', 'Error: ')
        this.toastrService.error(errors, 'TryAgain');
        });
}


//Edit paycomponent
public onEditCompanyvalue(companyvalueId: any,companyid, client_id) {
    const params: any = {};
    params.company_id = this.CompanyID;
    params.client_id = this.ClientID;
    params.company_ud_values_id = companyvalueId;

    this.apiModuleService.edit(params,'admin/companyvalue/edit').subscribe((data) => {
        let companyvalueDetail = data.data.company_values[0];
        this.company_ud_values_id=companyvalueDetail.company_ud_values_id;
        this.client_id=this.ClientID;
        this.company_id=this.CompanyID;
        this.company_financial_year_id=companyvalueDetail.company_financial_year_id;
        // this.ud_module_id=companyvalueDetail.ud_module_id;
        const length=this.udValueDropDown.length;
        for(let i=0;i<length;i++ ){
          let key_name=this.udValueDropDown[i].ud_field_key;

    this.udValueDropDown[i]['fields']=companyvalueDetail.ud_values[key_name];

        }
  });

}

//edit paycomponent
public onEditChanges(editCompanyvalue:NgForm){
  let udvalue={};

  for(let i=0;i<this.udValueDropDown.length;i++){
    udvalue[this.udValueDropDown[i].ud_field_key]=(editCompanyvalue.value[i][this.udValueDropDown[i].ud_field_key] != undefined)?editCompanyvalue.value[i][this.udValueDropDown[i].ud_field_key] :null;
   }
    var params:any = {};
    params.company_ud_values_id = this.company_ud_values_id;
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    params.company_financial_year_id = editCompanyvalue.value.company_financial_year_id;
    // params.ud_module_id = editCompanyvalue.value.ud_module_id;
    params.ud_module_id = this.ud_module_id;
    params.ud_values = udvalue;

  this.apiModuleService.edit(params,'admin/companyvalue/update').subscribe((data)=>{
    this.toastrService.success('Paycomponent Edited Sucessfully...!', 'Success');
      this.getCompanyvalue();
      ($('#edit_companyvalue')as any).modal('hide');
      editCompanyvalue.reset();
    }, (err) => {
      let errors = err.error.data.company_values;
      errors = errors.replace('SQLSTATE[42000]: [Microsoft][ODBC Driver 17 for SQL Server][SQL Server]', 'Error: ')
      errors = errors.replace('SQLSTATE[42000]: [Microsoft][ODBC Driver 18 for SQL Server][SQL Server]', 'Error: ')
      this.toastrService.error(errors, 'TryAgain');
      });
}


  public getDropdowncomponent(){
      if (this.CompanyDropDown.length !== 0 && this.CompanyID != null) {

          var params: any = {};
          params.company_id = this.CompanyID;
          params.client_id = this.ClientID;
          this.apiModuleService.list('admin/companyvalue/dropdowncompany', params).subscribe((data) => {

              this.financiallabelDropDown = data.data.company_values[0];
              this.udmodulenameDropDown = data.data.company_values[1];
              this.ud_module_id = this.udmodulenameDropDown[0].ud_module_id;
              this.udValueDropDown = data.data.company_values[2];

              this.getCompanyvalue();
          });
      }else{
          this.rerender();
      }
  }
  setFormDynamic(){

    }
   /* client DropDown codes*/
   public GetClientDropDown() {
    this.apiModuleService.get('DropDown/client').subscribe((data) => {
        this.ClientDropDown = data.data.client;

        this.ClientID = data.data.client[0].client_id;
        this.GetCompanyDropDown();

    })
}
    addReset(form:NgForm){
       
        form.reset();
    }
/* company DropDown codes */
public GetCompanyDropDown() {
    const params: any = {};
    params.client_id = this.ClientID;

    this.apiModuleService.list('DropDown/company', params).subscribe((data) => {
        this.CompanyDropDown = data.data.company != undefined ? data.data.company : [];
        if (this.CompanyDropDown.length !== 0) {
            setTimeout(() => {
                this.CompanyID = this.CompanyDropDown[0].company_id;
                this.getDropdowncomponent();
            }, 100)
        }
        else {
            this.CompanyID = null;
            this.rerender();
        }

        }
    )
}

 // status change
 changeStatus(status:any,companyvalueId:any) {

  var params:any = {};
  params.company_id = this.CompanyID;
  params.client_id = this.ClientID;
  params.company_ud_values_id = companyvalueId;
 //
    this.apiModuleService.edit(params,'admin/companyvalue/'+status).subscribe((data) => {
      this.getCompanyvalue();
      ($('#companyudvalues-status') as any).modal('hide');
      this.toastrService.success('Status Changed Sucessfully...!', 'Success');
    }, (err) => {
      let errors = err.error.data.company_values;
      errors = errors.replace('SQLSTATE[42000]: [Microsoft][ODBC Driver 17 for SQL Server][SQL Server]', 'Error: ')
      errors = errors.replace('SQLSTATE[42000]: [Microsoft][ODBC Driver 18 for SQL Server][SQL Server]', 'Error: ')
      this.toastrService.error(errors, 'TryAgain');
      });
}

  ngOnDestroy() {

  }

  getAcronym(str:any){
    var matches = str.match(/\b(\w)/g); // ['J','S','O','N']
    matches= matches.slice(0,2);
    var acronym = matches.join(''); // JSON
    return acronym;
  }
  getBgColors(str:any){
    var min = 0;
    var max = 15;
    var index = this.findAlphapositions(str,1);
    return this.nameBgColors[index];
  }
  findAlphapositions(str:any, n:any)
  {
    var NUM = 31;

    return str[0].charCodeAt(0) & NUM;
  }
  callStatusModel(url_name:any,data:any){
    this.statusApi=url_name;
    this.statusValues=data;
}
}
