import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';

import {Subject} from 'rxjs';
import{ApiModulesService} from '../../../../api-modules.service';
import {ToastrService} from 'ngx-toastr';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {DatePipe} from '@angular/common';
import { SpinnerService } from 'src/app/snipper/spinner.service';

@Component({
    selector: 'employee-hrm-leave',
    templateUrl: './employee-hrms-leaves.component.html',
    styleUrls: ['./employee-hrms-leaves.component.css']
})
export class EmployeeHrmsLeavesComponent implements OnInit, OnDestroy, AfterViewInit {



    isDtInitialized = false;
    ClientID = localStorage.getItem('client_id');
    CompanyID = localStorage.getItem('company_id');
    EmployeeID = localStorage.getItem('employee_id');
    public ShowForm: any = false;

    leaveGridData = [];
    leavedDashboardData = [];
    leaveDayTypeDropDown = [];
    halfDayTypeDropDown = [];
    leavePlanDayTypeDropDown = [];
    approvedEmployeeDropDown = [];
    actionType = [];
    no_of_days: any;
    chooseButton: any;
    statusChangeData:any={};
    leave_status_reason:any;
    timeRegex = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/ ;

    public pipe = new DatePipe('en-US');
    fromToDateError = false;
    addEmployeeLeaveForm: FormGroup;
    nameBgColors: any = ['bg-blue-dim', 'bg-blue-dim', 'bg-azure-dim', 'bg-indigo-dim', 'bg-info-dim', 'bg-purple-dim', 'bg-pink-dim', 'bg-orange-dim', 'bg-teal-dim', 'bg-primary-dim', 'bg-success-dim', 'bg-warning-dim', 'bg-danger-dim', 'bg-secondary-dim', 'bg-dark-dim', 'bg-gray-dim', 'bg-blue-dim', 'bg-azure-dim', 'bg-indigo-dim', 'bg-info-dim', 'bg-purple-dim', 'bg-pink-dim', 'bg-orange-dim', 'bg-teal-dim', 'bg-primary-dim', 'bg-success-dim', 'bg-warning-dim', 'bg-danger-dim', 'bg-secondary-dim', 'bg-dark-dim', 'bg-gray-dim'];
    nameBorderColors: any = ['bg-outline-primary', 'bg-outline-success', 'bg-outline-info', 'bg-outline-gray', 'bg-outline-warning', 'bg-outline-dark', 'bg-outline-light', 'bg-outline-secondary', 'bg-outline-danger'];

    constructor(private spinnerService: SpinnerService,private ApiModuleService: ApiModulesService, private toastr: ToastrService, private formBuilder: FormBuilder, private router: Router) {
    }

    ngOnInit() {
        this.spinnerService.raiseDataEmitterEvent('off');

        this.getLeaveData();
        this.getLeaveDropDown();
        this.addEmployeeLeaveForm = this.formBuilder.group({
            employee_leave_id: [''],
            no_of_days: ['', [Validators.required]],
            leave_reason: ['', [Validators.required]],
            leave_plan_type_id: ['', [Validators.required]],
            from_date: ['', [Validators.required]],
            to_date: ['', [Validators.required]],
            leaveDetails: this.formBuilder.array([])

        });
    }

    ngAfterViewInit(): void {

    }

    get itemsList(): FormArray {
        return this.addEmployeeLeaveForm.get('leaveDetails') as FormArray;

    }

    newItem(value): FormGroup {
        return this.formBuilder.group({
            date: [value.date, [Validators.required]],
            leave_day_type: [value.leave_day_type, [Validators.required]],
            half_day_type: [value.half_day_type, []],
            start_time: [value.start_time, [ Validators.pattern(this.timeRegex)]],
            end_time: [value.end_time, [ Validators.pattern(this.timeRegex)]],
        });
    }

    addItems(value: any) {
        this.itemsList.push(this.newItem(value));
    }

    getLeaveData() {
        this.ShowForm = false;
        const params: any = {};
        params.client_id = this.ClientID;
        params.company_id = this.CompanyID;
        params.employee_id = this.EmployeeID;
        this.ApiModuleService.list('employee-tabs/employee-hrm/employee-leaves/list', params).subscribe((data) => {

                    this.leaveGridData = (data.data.employee_leave.leave_grid != undefined) ? data.data.employee_leave.leave_grid : [];
                    this.leavedDashboardData = (data.data.employee_leave.leave_dashboard_data != undefined) ? data.data.employee_leave.leave_dashboard_data : [];

        }, (err) => {
            this.spinnerService.toasterError(err);

        });
    }

    addLeave() {
        if (!this.addEmployeeLeaveForm.valid) {
            this.addEmployeeLeaveForm.markAllAsTouched();
            return;
        }
        const params: any = {};
        params.client_id = this.ClientID;
        params.company_id = this.CompanyID;
        params.employee_id = this.EmployeeID;
        params.leave_plan_type_id = this.addEmployeeLeaveForm.value.leave_plan_type_id;
        params.no_of_days = this.no_of_days;
        params.leave_reason = this.addEmployeeLeaveForm.value.leave_reason;
        params.from_date = this.addEmployeeLeaveForm.value.from_date;
        params.to_date = this.addEmployeeLeaveForm.value.to_date;
        params.leave_details = this.addEmployeeLeaveForm.value.leaveDetails;
        const employee_leave_id = this.addEmployeeLeaveForm.value.employee_leave_id;
        if (employee_leave_id !== null && employee_leave_id !== '') {
            params.employee_leave_id = this.addEmployeeLeaveForm.value.employee_leave_id;
            this.ApiModuleService.list('employee-tabs/employee-hrm/employee-leaves/update', params).subscribe((data) => {

                ($('#add_leave') as any).modal('hide');
                this.addEmployeeLeaveForm.reset();
                this.getLeaveData();
                this.toastr.success('leave Created SuccessFully..!', 'Success')
            }, (err: any) => {
                this.spinnerService.toasterError(err);

            });
        }

        else {
            this.ApiModuleService.list('employee-tabs/employee-hrm/employee-leaves/create', params).subscribe((data) => {

                ($('#add_leave') as any).modal('hide');
                this.addEmployeeLeaveForm.reset();
                this.getLeaveData();
                this.toastr.success('leave Created SuccessFully..!', 'Success')
            }, (err: any) => {
                this.spinnerService.toasterError(err);

            });
        }
    }

    getLeaveDropDown() {
        this.ShowForm = false;
        const params: any = {};
        params.client_id = this.ClientID;
        params.company_id = this.CompanyID;
        params.employee_id = this.EmployeeID;
        this.ApiModuleService.list('employee-tabs/employee-hrm/employee-leaves/dropDown', params).subscribe((data) => {

            const leaveDropdown = (data.data.employee_leave != undefined) ? data.data.employee_leave : [];

            this.leaveDayTypeDropDown = leaveDropdown[0] != undefined ? leaveDropdown[0] : [0];
            this.halfDayTypeDropDown = leaveDropdown[1] != undefined ? leaveDropdown[1] : [1];
            this.leavePlanDayTypeDropDown = leaveDropdown[2] != undefined ? leaveDropdown[2] : [];
            this.approvedEmployeeDropDown = leaveDropdown[3] != undefined ? leaveDropdown[3] : [];
            this.actionType = leaveDropdown[4] != undefined ? leaveDropdown[4] : [];
        }, (err) => {
            this.spinnerService.toasterError(err);

        });
    }

    calculateDiff() {
        if (this.addEmployeeLeaveForm.get('from_date').valid && this.addEmployeeLeaveForm.get('to_date').valid) {
            const dateFrom = new Date(this.addEmployeeLeaveForm.get('from_date').value);
            const toDate = new Date(this.addEmployeeLeaveForm.get('to_date').value);
            if ((Date.UTC(dateFrom.getFullYear(), dateFrom.getMonth(), dateFrom.getDate())
                <= Date.UTC(toDate.getFullYear(), toDate.getMonth(), toDate.getDate()))) {
                this.fromToDateError = false;
                this.itemsList.clear();
                // this.itemsList.reset([]);
                this.no_of_days = (Math.floor((Date.UTC(toDate.getFullYear(), toDate.getMonth(), toDate.getDate()) - Date.UTC(dateFrom.getFullYear(), dateFrom.getMonth(), dateFrom.getDate())) / (1000 * 60 * 60 * 24))) + 1;


// this.addEmployeeLeaveForm.get('leaveDetails.date').patchValue([]);
                for (let i = 0; i < this.no_of_days; i++) {
                    const DateFT = this.pipe.transform(
                        Date.UTC(dateFrom.getFullYear(), dateFrom.getMonth(), dateFrom.getDate() + i),
                        'yyyy-MM-dd'
                    );
                    const value = {
                        date: DateFT,
                        leave_day_type: 'full',
                        half_day_type: '',
                        start_time: '',
                        end_time:'',
                    };
                    this.addItems(value);
                }
                this.addEmployeeLeaveForm.patchValue({
                    no_of_days: (Math.floor((Date.UTC(toDate.getFullYear(), toDate.getMonth(), toDate.getDate()) - Date.UTC(dateFrom.getFullYear(), dateFrom.getMonth(), dateFrom.getDate())) / (1000 * 60 * 60 * 24))) + 1
                })
            }

            else {
                this.fromToDateError = true;
                this.no_of_days = '';
                this.itemsList.clear();
                this.addEmployeeLeaveForm.patchValue({
                    no_of_days: ''
                });
            }

        }
    }

    changeDate(i: any) {
        let count: any = 0;
        for (let index = 0; index < this.addEmployeeLeaveForm.value.leaveDetails.length; index++) {
            if (this.addEmployeeLeaveForm.value.leaveDetails[index].leave_day_type === 'full') {
                count = count + 1;
                (this.addEmployeeLeaveForm.get('leaveDetails') as FormArray)
                    .at(i) // Access the specific form group within the array
                    .patchValue({
                        half_day_type: '',
                        start_time: '',
                        end_time:''
                    });
            } else {
                count = count + 0.5
            }
        }
        this.no_of_days = count;

        this.addEmployeeLeaveForm.patchValue({no_of_days: count});

    }

    getAcronym(str: any) {
        let matches = str.match(/\b(\w)/g); // ['J','S','O','N']
        matches = matches.slice(0, 3);
        const acronym = matches.join(''); // JSON
        return acronym;
    }

    getBgColors(str: any) {
        const min = 0;
        const max = 15;
        const index = this.findAlphapositions(str, 1);
        return this.nameBgColors[index];
    }

    findAlphapositions(str: any, n: any) {
        const NUM = 31;

        return str[0].charCodeAt(0) & NUM;
    }

    editLeave(employeeLeaveId) {
        this.itemsList.clear();
        const params: any = {};
        params.client_id = this.ClientID;
        params.company_id = this.CompanyID;
        params.employee_id = this.EmployeeID;
        params.employee_leave_id = employeeLeaveId;
        this.ApiModuleService.edit(params, 'employee-tabs/employee-hrm/employee-leaves/edit').subscribe((data) => {
            const mainFormData = data.data.employee_leave.leave_edit[0];
            let subFormData = [];
            subFormData = data.data.employee_leave.leave_type != undefined ? data.data.employee_leave.leave_type : [];
            this.no_of_days = mainFormData.no_of_days;
            this.addEmployeeLeaveForm.patchValue({
                employee_leave_id: mainFormData.employee_leave_id,
                no_of_days: mainFormData.no_of_days,
                leave_reason: mainFormData.leave_reason,
                leave_plan_type_id: mainFormData.leave_plan_type_id,
                from_date: mainFormData.from_date,
                to_date: mainFormData.to_date,
            });

            for (let i = 0; i < subFormData.length; i++) {
                subFormData[i].half_day_type = subFormData[i].half_day_type != null ? subFormData[i].half_day_type : '';
                subFormData[i].start_time = subFormData[i].start_time != null ? subFormData[i].start_time : '';
                subFormData[i].end_time = subFormData[i].end_time != null ? subFormData[i].end_time : '';
                this.addItems(subFormData[i]);
            }

        },(err)=>{
            this.spinnerService.toasterError(err);

        })
    }

    changeChooseButton(actionFlag: any, leave: any) {
        this.statusChangeData = leave;
        this.chooseButton = actionFlag;
        this.leave_status_reason='';
    }
    changeLeaveStatus(){
        const params: any = {};
        params.client_id = this.ClientID;
        params.company_id = this.CompanyID;
        params.employee_id = this.EmployeeID;
        params.action_flag = this.chooseButton;
        params.employee_leave_id = this.statusChangeData.employee_leave_id;
        params.leave_plan_type_id = this.statusChangeData.leave_plan_type_id;
        params.leave_action_reason = this.leave_status_reason;
        this.ApiModuleService.edit(params, 'employee-tabs/employee-hrm/employee-leaves/statusChange').subscribe((data) => {

            ($('#confirm_button_popup')as any).modal('hide');
            this.getLeaveData();
        }, (err: any) => {
            this.spinnerService.toasterError(err);

        });
    }
    resetForm() {
        this.itemsList.clear();
        this.addEmployeeLeaveForm.reset();
    }

    ngOnDestroy(): void {
        // Do not forget to unsubscribe the event

    }
}
