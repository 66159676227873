import {
  Component, ViewChild, AfterViewInit, OnInit, Input,
  Output, EventEmitter
} from '@angular/core';
import { ApiModulesService } from '../../api-modules.service';
import { SpinnerService } from 'src/app/snipper/spinner.service';
import { FormGroup, FormBuilder, Validators, NgForm, } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { Subject, config } from "rxjs";
import { ChangeDetectorRef } from '@angular/core';


@Component({
  selector: 'app-paycomponentformula',
  templateUrl: './paycomponentformula.component.html',
  styleUrls: ['./paycomponentformula.component.css'],

})
export class PaycomponentformulaComponent {

  @Output() parenFun: EventEmitter<any> = new EventEmitter();

  @Input() ClientID: any;
  @Input() CompanyID: any;
  @Input() userKey: any;
  @Input() PayGroupID: any;
  @Input() ComponentID: any;
  @Input() CityID: any;
  showParent = true;
  searchKeywords: string = '';

  paycomponentformulaData = [

  ];

  totalRecords: any;
  SelectDataName: any;
  selectedComponents: any;
  showForm: boolean;
  ReferenceTypeID: any;
  paycomponentdropdownData: any;
  Referencetype_ID: string = 'All';
  filterData: boolean = false;
  PaycomponentData: any[] = [];
  selectAll = false;
  PayComponentFormulaID: any;
  getFormulaData: any[] = [];
  reference_type: any;
  reference_type_id: any;
  formulaData: any;
  reference_name: any;
  





  constructor(private apiModulesService: ApiModulesService,
    private toastrService: ToastrService, private spinnerService: SpinnerService, private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {

    this.showParent = false;
    this.GetSelectName();


  }


  
  public GetSelectName() {
    this.spinnerService.raiseDataEmitterEvent('on');

    var params: any = {};
    params.component_id = this.ComponentID;
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    params.pay_group_id = this.PayGroupID;
    params.users_key = this.userKey;
    this.apiModulesService.list('admin/companyformula/selectname', params).subscribe((data) => {
      this.SelectDataName = data.data.company_formula[0] != undefined ? data.data.company_formula[0] : {};
      this.getPaycomponentformula();
      this.Paycomponentdropdown();

    });
  }
  //Get all companyformula data
  public getPaycomponentformula() {
    var params: any = {};
    params.component_id = this.ComponentID;
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    params.pay_group_id = this.PayGroupID;
    params.users_key = this.userKey;


    this.apiModulesService.list("admin/paycomponent-formula/list", params).subscribe(
      (data) => {
        this.paycomponentformulaData = data.data.paycomponent_formula || [];

        this.PaycomponentData = this.paycomponentformulaData;
        // Calculate total records if returned by the server, otherwise, use the length of the data
        this.totalRecords = data.data.totalRecords || this.paycomponentformulaData.length;

        this.selectAll = this.isAllCheckboxesSelected();
      },
      (err: any) => {
        console.error('API call failed:', err);
        this.paycomponentformulaData = [];
        this.spinnerService.toasterError(err);
        this.spinnerService.raiseDataEmitterEvent('off');
      }
    );
  }

  //Get all companyformula data
  public Paycomponentdropdown() {
    var params: any = {};
    params.component_id = this.ComponentID;
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    params.pay_group_id = this.PayGroupID;
    params.users_key = this.userKey;

    this.apiModulesService.list("admin/paycomponent-formula/paydropdown", params).subscribe(
      (data) => {
        this.paycomponentdropdownData = data.data.paycomponent_formula[0] || [];


        let newElement = {
          reference_key: 'All',
          reference_type_id: 0
        };
        this.paycomponentdropdownData.unshift(newElement);

        // Calculate total records if returned by the server, otherwise, use the length of the data
        this.totalRecords = data.data.totalRecords || this.paycomponentformulaData.length;
        setTimeout(() => {
          this.paycomponentdropdownData = data.data.paycomponent_formula[0] || [];
          this.paycomponentfilter();
        }, 1000)


      },
      (err: any) => {
        console.error('API call failed:', err);
        this.paycomponentformulaData = [];
        this.spinnerService.toasterError(err);
        this.spinnerService.raiseDataEmitterEvent('off');
      }
    );
  }

  // Filter function called when dropdown value changes
  public paycomponentfilter() {

    // When "All" or 0 is selected, display all data
    if (this.Referencetype_ID === 'All') {
      this.PaycomponentData = [...this.paycomponentformulaData];
    } else {
      // Otherwise, filter based on the selected reference_type
      this.PaycomponentData = this.paycomponentformulaData.filter((val: any) => val.reference_type === this.Referencetype_ID);
    }
    this.spinnerService.raiseDataEmitterEvent('off');

  }



  toggleSelectAll(): void {
    // Toggle all items based on the selectAll checkbox
    this.PaycomponentData.forEach((item) => (item.is_applicable = this.selectAll));
  }

  checkboxChanged(): void {
    // If all checkboxes are selected, selectAll should be true, otherwise false
    this.selectAll = this.isAllCheckboxesSelected();
  }

  isAllCheckboxesSelected(): boolean {
    // Check if every item is applicable
    return this.PaycomponentData.every((item) => item.is_applicable);
  }



  public updatePayComponentFormula(PaycomponentFormula: NgForm) {
    const params: any = {};
    const payComponentData = { ...PaycomponentFormula.value };
    delete payComponentData.is_applicable;
    params.component_id = this.ComponentID;
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    params.pay_group_id = this.PayGroupID;
    params.users_key = this.userKey;
    params.reference_type_id = this.ReferenceTypeID;
    params.paycomponent_formula = payComponentData;
    this.apiModulesService.edit(params, 'admin/paycomponent-formula/update').subscribe((data) => {
      this.showForm = false;
      this.GetSelectName();
      this.toastrService.success('Import Added Sucessfully...!', 'Success');
    }, (err) => {
      if (err.status === 437) {
        this.toastrService.error('Import Already Exists...!', 'Failed');
      } else {
        this.spinnerService.toasterError(err);
      }
    });
  }





  toparentFun() {
    this.showParent = true;

    this.parenFun.emit(this.showParent)
  }

  // onPageChange(event: PageEvent) {
  //   this.first = event.first;
  //   this.rows = event.rows;
  // }

  getFormula(Reference_Type: any, Referencetype_ID: any,ReferenceName:any) {
    this.reference_name=ReferenceName;
    const params: any = {
      client_id: this.ClientID,
      component_id: this.ComponentID,
      reference_type: Reference_Type,
      reference_type_id: Referencetype_ID,
    };
    this.apiModulesService.list('admin/paycomponent-formula/getlist', params).subscribe((data) => {
      this.getFormulaData = data.data.paycomponent_formula;
      //this.getPaycomponentformula();
      this.totalRecords = data.data.totalRecords;
      //($('#formula_type') as any).modal('hide');
      this.spinnerService.raiseDataEmitterEvent('off');
    },
      (err: any) => {
        console.error('API call failed:', err);
        this.spinnerService.toasterError(err);
        this.spinnerService.raiseDataEmitterEvent('off');
      });
      
  }


}


