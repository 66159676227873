import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {Subject} from 'rxjs';
import {Subscription} from 'rxjs';
import {ApiModulesService} from '../../api-modules.service';

import { Table } from 'primeng/table';
import {SpinnerService} from "../../../snipper/spinner.service";

@Component({
    selector: 'app-skills',
    templateUrl: './skills.component.html',
    styleUrls: ['./skills.component.css']
})
export class SkillsComponent implements OnInit, AfterViewInit, OnDestroy {
    public skillsData = [];
    public listHeaders = [];
    public ShowForm: any = false;
    public deleteForm: any = false;
    public addSkillsForm: FormGroup;
    public addEmployeeSkillsForm: FormGroup;
    delete_id: any;
    public show: boolean = true;
    statuses=[];
    statusApi:any;
    private user_type=localStorage.getItem('user_type');
    statusValues:any;
    loading: boolean = false;
    searchKeyword: string = '';
    @Input() authEmployeeData: any;
    @Input() pageName: any = {};

    tabToGrid=false;
    @Output() parenFun: EventEmitter<any> = new EventEmitter();
    nameBgColors: any = ['bg-blue-dim', 'bg-blue-dim', 'bg-azure-dim', 'bg-indigo-dim', 'bg-info-dim', 'bg-purple-dim', 'bg-pink-dim', 'bg-orange-dim', 'bg-teal-dim', 'bg-primary-dim', 'bg-success-dim', 'bg-warning-dim', 'bg-danger-dim', 'bg-secondary-dim', 'bg-dark-dim', 'bg-gray-dim', 'bg-blue-dim', 'bg-azure-dim', 'bg-indigo-dim', 'bg-info-dim', 'bg-purple-dim', 'bg-pink-dim', 'bg-orange-dim', 'bg-teal-dim', 'bg-primary-dim', 'bg-success-dim', 'bg-warning-dim', 'bg-danger-dim', 'bg-secondary-dim', 'bg-dark-dim', 'bg-gray-dim'];
    nameBorderColors: any = ['bg-outline-primary', 'bg-outline-success', 'bg-outline-info', 'bg-outline-gray', 'bg-outline-warning', 'bg-outline-dark', 'bg-outline-light', 'bg-outline-secondary', 'bg-outline-danger'];

    constructor(private apiModelServices: ApiModulesService,
                private toastr: ToastrService,
                private formBuilder: FormBuilder,
                private spinnerService: SpinnerService
                ) {
    }

    ngOnInit(): void {
      this.spinnerService.raiseDataEmitterEvent('on');
        this.getSkills();
        this.addSkillsForm = this.formBuilder.group({
            items: this.formBuilder.array([]),
        });
        this.addEmployeeSkillsForm = this.formBuilder.group({
            total_years: ['', [Validators.required]],
            proficiency: ['', [Validators.required]],
            skills_name: ['', [Validators.required]],
            employee_skills_id: ['', []],
        })
        this.statuses = [
          { label: 'Active', value: 'active' },
          { label: 'Inactive', value: 'inactive' },
      ];
        // for adding row
        this.addItems();
    }

    ngAfterViewInit(): void {

    }

    get itemsList(): FormArray {
        return this.addSkillsForm.get('items') as FormArray;

    }

    // for changes of forms
    newItem(): FormGroup {
        return this.formBuilder.group({
            total_years: '',
            proficiency: '',
            skills_name: '',
            employee_skills_id: '',
        });
    }
    parentFun(){

        this.parenFun.emit(this.tabToGrid)
    }
    newItems(value): FormGroup {
        return this.formBuilder.group({
            total_years: value.total_years,
            proficiency: value.proficiency,
            skills_name: value.skills_name,
            employee_skills_id: value.employee_skills_id

        });
    }

    public hide() {
        this.show = !this.show
    }

    showList() {
        this.ShowForm = false;
        this.deleteForm = false;

    }

    showAddForm() {
        this.ShowForm = true;
        this.deleteForm = false;
    }

    // for adding rows
    addItems() {
        this.itemsList.push(this.newItem());
    }

// for remove rows
    removeItems(i) {
        this.itemsList.removeAt(i);
    }

    getSkills() {
      this.spinnerService.raiseDataEmitterEvent('on');
        const params: any = {};
        params.client_id = this.authEmployeeData.client_id;
        params.company_id = this.authEmployeeData.company_id;
        params.employee_id = this.authEmployeeData.employee_id;
        this.apiModelServices.list('admin/employeeSkills/list', params).subscribe((data) => {

            this.spinnerService.raiseDataEmitterEvent('off');
                this.skillsData = (data.data.employee_skills != undefined) ? data.data.employee_skills : [];

        }, (err) => {

          this.spinnerService.toasterError(err);
        });
    }

    addSkills() {
        if (!this.addEmployeeSkillsForm.valid) {

            this.addEmployeeSkillsForm.markAllAsTouched();
            return;
        }

        const params: any = {};
        params.client_id = this.authEmployeeData.client_id;
        params.company_id = this.authEmployeeData.company_id;
        params.employee_id = this.authEmployeeData.employee_id;
        params.total_years = this.addEmployeeSkillsForm.value.total_years;
        params.proficiency = this.addEmployeeSkillsForm.value.proficiency;
        params.skills_name = this.addEmployeeSkillsForm.value.skills_name;
        if (this.addEmployeeSkillsForm.value.employee_skills_id != '' && this.addEmployeeSkillsForm.value.employee_skills_id != null) {
            params.employee_skills_id = this.addEmployeeSkillsForm.value.employee_skills_id;
            this.apiModelServices.edit(params, 'admin/employeeSkills/update').subscribe((data) => {
                this.toastr.success('Skills data update Successfully', 'Success');
                ($('#add_employee_skills') as any).modal('hide');
                this.getSkills();
                this.ShowForm = false;
            }, err => {
              this.spinnerService.toasterError(err);
            });
        } else {
            this.apiModelServices.add(params, 'admin/employeeSkills/add').subscribe((data) => {
                this.getSkills();
                ($('#add_employee_skills') as any).modal('hide');
                this.getSkills();
                this.ShowForm = false;
                this.toastr.success('Skills data Add Successfully', 'Success')
            }, err => {
              this.spinnerService.toasterError(err);
            });
        }
    }

    public onEditSkills(value) {
        this.resetForm();
        this.addEmployeeSkillsForm.setValue({
            total_years: value.total_years,
            proficiency: value.proficiency,
            skills_name: value.skills_name,
            employee_skills_id: value.employee_skills_id,
        })
        // this.ShowForm = true;
        // this.deleteForm = true;
        // this.addSkillsForm.reset();
        // this.newItems(value);
        // this.itemsList.removeAt(0);
        // this.itemsList.push(this.newItems(value))
    }

    changeStatus(Status: any, skills: any) {
        const params: any = {};
        params.client_id = skills.client_id;
        params.company_id = skills.company_id;
        params.employee_id = skills.employee_id;
        params.employee_skills_id = skills.employee_skills_id;

        this.apiModelServices.edit(params, 'admin/employeeSkills/' + Status).subscribe((data) => {
            this.getSkills();
            ($('#employeeskill-status') as any).modal('hide');
            this.toastr.success('Skills status Successfully', 'Success')
        }, err => {
          this.spinnerService.toasterError(err);
        });
    }

    public delete(i) {
        this.delete_id = i;
    }

    // Reset form
    public resetForm() {
        this.addSkillsForm.reset();
        this.addEmployeeSkillsForm.reset();
    }

    getAcronym(str: any) {
        var matches = str.match(/\b(\w)/g); // ['J','S','O','N']
        matches = matches.slice(0, 2);
        var acronym = matches.join(''); // JSON
        return acronym;
    }

    getBgColors(str: any) {
        var min = 0;
        var max = 15;
        var index = this.findAlphapositions(str, 1);
        return this.nameBgColors[index];
    }

    findAlphapositions(str: any, n: any) {
        var NUM = 31;

        return str[0].charCodeAt(0) & NUM;
    }

    ngOnDestroy(): void {
        // Do not forget to unsubscribe the event

    }
    clear(table: Table) {
      table.clear();
  }

  getSeverity(status: string) {
      switch (status.toLowerCase()) {
          case 'inactive':
              return 'danger';

          case 'active':
              return 'success';
      }
  }
  callStatusModel(url_name:any,data:any,actionName:string){
    this.statusApi=url_name;
    this.statusValues=data;
}
}
