// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.close-icon{
  font-size:15px;
  margin-top: 3px;
}
.view-icon{
  font-size:14px;
  margin-top: 4px;
}
.title-value {
  display: list-item;
  width: 56%;
  font-size: 13px;
  color: rgba(128, 128, 128, 0.82);
}
.page-header {
  padding-bottom: 0px;
  margin-bottom: 0px;
  border-bottom: 1px solid #D9D9D9;
}
.start-btn {
  background-color:#08bd3f;
  color: #fff;

  }
  .stop-btn {
  background-color:#eb3838;
  color: #fff;
  }
  .submit-btn {
    /* border-radius: 50px; */
    font-size: 14px;
    font-weight: normal;
    width: 91px !important;
    /* padding: 10px 20px; */
    color: black;
}
.box-shadow{
	box-shadow: 3px 5px 6px rgba(57, 92, 241, 0.19);
}
`, "",{"version":3,"sources":["webpack://./src/app/header/notification/notification.component.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,eAAe;AACjB;AACA;EACE,cAAc;EACd,eAAe;AACjB;AACA;EACE,kBAAkB;EAClB,UAAU;EACV,eAAe;EACf,gCAAgC;AAClC;AACA;EACE,mBAAmB;EACnB,kBAAkB;EAClB,gCAAgC;AAClC;AACA;EACE,wBAAwB;EACxB,WAAW;;EAEX;EACA;EACA,wBAAwB;EACxB,WAAW;EACX;EACA;IACE,yBAAyB;IACzB,eAAe;IACf,mBAAmB;IACnB,sBAAsB;IACtB,wBAAwB;IACxB,YAAY;AAChB;AACA;CACC,+CAA+C;AAChD","sourcesContent":[".close-icon{\r\n  font-size:15px;\r\n  margin-top: 3px;\r\n}\r\n.view-icon{\r\n  font-size:14px;\r\n  margin-top: 4px;\r\n}\r\n.title-value {\r\n  display: list-item;\r\n  width: 56%;\r\n  font-size: 13px;\r\n  color: rgba(128, 128, 128, 0.82);\r\n}\r\n.page-header {\r\n  padding-bottom: 0px;\r\n  margin-bottom: 0px;\r\n  border-bottom: 1px solid #D9D9D9;\r\n}\r\n.start-btn {\r\n  background-color:#08bd3f;\r\n  color: #fff;\r\n\r\n  }\r\n  .stop-btn {\r\n  background-color:#eb3838;\r\n  color: #fff;\r\n  }\r\n  .submit-btn {\r\n    /* border-radius: 50px; */\r\n    font-size: 14px;\r\n    font-weight: normal;\r\n    width: 91px !important;\r\n    /* padding: 10px 20px; */\r\n    color: black;\r\n}\r\n.box-shadow{\r\n\tbox-shadow: 3px 5px 6px rgba(57, 92, 241, 0.19);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
