// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.personal-info li {
  margin-bottom: 0px !important;
}
.card-formula{
  margin-bottom: 10px !important;
  box-shadow: 0px 4px 4px rgba(57, 92, 241, 0.19);
}
.pay-card-main
{
  margin-left:5px;
}
.pay-card-sub
{
  margin-left:40px;
}
.title-value{
color:#14325e;
}
.title-label{
  color:#14325e;

}
.bold-class{
  font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./src/app/all-modules/ess-tabs/IND-ess/IND-employee-payroll/employee-payslip/payslip-formula-popup/payslip-formula-popup.component.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;AAC/B;AACA;EACE,8BAA8B;EAC9B,+CAA+C;AACjD;AACA;;EAEE,eAAe;AACjB;AACA;;EAEE,gBAAgB;AAClB;AACA;AACA,aAAa;AACb;AACA;EACE,aAAa;;AAEf;AACA;EACE,iBAAiB;AACnB","sourcesContent":[".personal-info li {\r\n  margin-bottom: 0px !important;\r\n}\r\n.card-formula{\r\n  margin-bottom: 10px !important;\r\n  box-shadow: 0px 4px 4px rgba(57, 92, 241, 0.19);\r\n}\r\n.pay-card-main\r\n{\r\n  margin-left:5px;\r\n}\r\n.pay-card-sub\r\n{\r\n  margin-left:40px;\r\n}\r\n.title-value{\r\ncolor:#14325e;\r\n}\r\n.title-label{\r\n  color:#14325e;\r\n\r\n}\r\n.bold-class{\r\n  font-weight: bold;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
