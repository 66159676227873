// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.value-time {
  display: inline-flex;
  width: 18%;
  font-size: 13px;
  color: rgba(128, 128, 128, 0.82);
}
.time-value{
  display: inline-flex;
  width: 38%;
  font-size: 13px;
  color: rgba(128, 128, 128, 0.82);
}
.hover-icon {
  color: initial; /* Set the initial color */
  transition: color 0.3s ease; /* Smooth color transition */
}

.hover-icon:hover {
  color: #007bff; /* Change to your desired hover color, e.g., blue */
}
`, "",{"version":3,"sources":["webpack://./src/app/all-modules/ess-tabs/IND-ess/IND-employee-hrms/manager-page/timemanager/time-approval/time-approval.component.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,UAAU;EACV,eAAe;EACf,gCAAgC;AAClC;AACA;EACE,oBAAoB;EACpB,UAAU;EACV,eAAe;EACf,gCAAgC;AAClC;AACA;EACE,cAAc,EAAE,0BAA0B;EAC1C,2BAA2B,EAAE,4BAA4B;AAC3D;;AAEA;EACE,cAAc,EAAE,mDAAmD;AACrE","sourcesContent":[".value-time {\r\n  display: inline-flex;\r\n  width: 18%;\r\n  font-size: 13px;\r\n  color: rgba(128, 128, 128, 0.82);\r\n}\r\n.time-value{\r\n  display: inline-flex;\r\n  width: 38%;\r\n  font-size: 13px;\r\n  color: rgba(128, 128, 128, 0.82);\r\n}\r\n.hover-icon {\r\n  color: initial; /* Set the initial color */\r\n  transition: color 0.3s ease; /* Smooth color transition */\r\n}\r\n\r\n.hover-icon:hover {\r\n  color: #007bff; /* Change to your desired hover color, e.g., blue */\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
