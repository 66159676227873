
import {AfterViewInit, Component, OnChanges, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AllModulesService} from 'src/app/all-modules/all-modules.service';
import {ApiModulesService} from 'src/app/all-modules/api-modules.service';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {DatePipe} from '@angular/common';
import {Subject} from 'rxjs';

import {Router} from '@angular/router';
import { SpinnerService } from 'src/app/snipper/spinner.service';
import { Table } from 'primeng/table';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-mutiple-users',
  templateUrl: './mutiple-users.component.html',
  styleUrls: ['./mutiple-users.component.css']
})
export class MutipleUsersComponent {
  isDtInitialized = false;
  public lstEmployee: any;
  ClientID: any;
  CompanyID: any;
  employeeID = localStorage.getItem('employee_id');
  public tempId: any;
  public editId: any;
  public EmployeesData = [];
  public dropdownname: [];
  public dropdownTitle: [];
  public dropdownGender: [];
  public dropdownNationalityType: [];
  public dropdownNationality: [];
  public dropdownMarital: [];
  public dropdownDocument: [];
  public dropdownAddressType: [];
  public physicallyDropdown: [];
  public employeeCategory: [];
  public countriesList = [];
  public statesList = [];
  public citiesList = [];
  public addEmployeeForm: FormGroup;
  public searchForm: FormGroup;
  public pipe = new DatePipe('en-US');
  public rows = [];
  public srch = [];
  public statusValue;
  //
  public DateJoin;
  public pageNo = 1;
  public limit = 10;
  public ShowForm: any = false;
  public Show = true;
  public modulesSwitch = '-1';
  public totalRecords: any = '0';
  showTabs = false;
  searchKeyword: string = '';
  percentage_value: any;
  statusApi:any;
  statusValues:any;
  emailRegex =
      '^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$';
  mobileRegex = '^((\\+91-?)|0)?[0-9]{10,13}$';
  numberRegex = '^[a-zA-Z0-9]{6,10}$';
  employeeIdRegex = '^[0-9\\-a-zA-Z\\-]{1,12}$';
  employeeName = '^[a-zA-Z][a-zA-Z\\s]*$';
  text: boolean;
  employeesDetails: any;
  field = true;
  CompanyDropDown = [];
  ClientDropDown = [];
  searchByColumn: any = '';
  searchValue: any = '';
  physicalvalue = '0';
  tabToGrid=false;
  nameBgColors: any = ['bg-blue-dim', 'bg-blue-dim', 'bg-azure-dim', 'bg-indigo-dim', 'bg-info-dim', 'bg-purple-dim', 'bg-pink-dim', 'bg-orange-dim', 'bg-teal-dim', 'bg-primary-dim', 'bg-success-dim', 'bg-warning-dim', 'bg-danger-dim', 'bg-secondary-dim', 'bg-dark-dim', 'bg-gray-dim', 'bg-blue-dim', 'bg-azure-dim', 'bg-indigo-dim', 'bg-info-dim', 'bg-purple-dim', 'bg-pink-dim', 'bg-orange-dim', 'bg-teal-dim', 'bg-primary-dim', 'bg-success-dim', 'bg-warning-dim', 'bg-danger-dim', 'bg-secondary-dim', 'bg-dark-dim', 'bg-gray-dim'];
  nameBorderColors: any = ['bg-outline-primary', 'bg-outline-success', 'bg-outline-info', 'bg-outline-gray', 'bg-outline-warning', 'bg-outline-dark', 'bg-outline-light', 'bg-outline-secondary', 'bg-outline-danger'];
  editedEmployeeData: any = {};
  public activeIndex = 0;
  statuses=[];
  loading: boolean = false;
  roles: any;
viewEmployeesPopupData: any;
viewFamilyPopupData=[];
viewDocumentsPopupData=[];
viewBankPopupData=[];
viewEducationPopupData=[];
viewEmployementPopupData: any;
awsimages: string;
  RoleID: any;
  MultipleROle: any;
  public selectedEmployees= [];
  public selectedFilteredUsers = [];
  dropdownSettings: IDropdownSettings;

  constructor(
    private ApiModuleService: ApiModulesService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private router: Router,
    private spinnerService: SpinnerService,
) {
}
  ngOnInit() {
    this.GetClientDropDown();
    this.getRoles();
    const that = this;
}
  public GetClientDropDown() {
    this.ApiModuleService.get('DropDown/client').subscribe((data) => {
        this.ClientDropDown = data.data.client;
        this.ClientID = data.data.client[0].client_id;
        setTimeout(() => {

        this.GetCompanyDropDown();
        },100)
    },(err:any)=>{
      this.spinnerService.toasterError(err);
    })

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'role_id',
      textField: 'role_name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
    };
}

/* company DropDown codes */
public GetCompanyDropDown() {
    const params: any = {};
    params.client_id = this.ClientID;
    this.ApiModuleService.list('DropDown/company', params).subscribe((data) => {
            this.CompanyDropDown = data.data.company != undefined ? data.data.company : [];
            if (this.CompanyDropDown.length !== 0) {
                setTimeout(() => {
                    this.CompanyID = this.CompanyDropDown[0].company_id;
                    this.getEmployeesData();
                }, 100)
            }
            else {
                this.CompanyID = null;
            }
    },(err:any)=>{
      this.spinnerService.toasterError(err);
    })
}
public getEmployeesData() {
  this.spinnerService.raiseDataEmitterEvent('off');
    if (this.CompanyDropDown.length !== 0 && this.CompanyID != null) {
        const params: any = {};
        params.client_id = this.ClientID;
        params.company_id = this.CompanyID;
        this.ApiModuleService.list('admin/users/get_employee', params).subscribe((data) => {
            this.EmployeesData = (data.data.userCreation != undefined) ? data.data.userCreation : [];
            this.loading = false;
            this.ShowForm = false;
            this.showTabs = false;
            var totalRecords = (this.EmployeesData[0] != undefined) ? this.EmployeesData[0].total_records : 0;
            this.totalRecords = totalRecords;
            this.selectedEmployees=[]
            this.selectedFilteredUsers=[]
            this.RoleID=null
            setTimeout(() => {
              this.spinnerService.raiseDataEmitterEvent('off');

          }, 100);
        }, (err) => {
          this.EmployeesData = [];
          this.spinnerService.toasterError(err);
          this.selectedEmployees=[]
          this.selectedFilteredUsers=[]
          this.RoleID=null
        });
    } else {
      this.EmployeesData = [];
      this.spinnerService.raiseDataEmitterEvent('off');
    }
}
getBgColors(str: any) {
  const min = 0;
  const max = 15;
  const index = this.findAlphapositions(str, 1);
  return this.nameBgColors[index];
}
findAlphapositions(str: any, n: any) {
  const NUM = 31;

  return str[0].charCodeAt(0) & NUM;
}
callStatusModel(url_name:any,data:any,actionName:string){
  this.statusApi=url_name;
  this.statusValues=data;
}
getAcronym(str: any) {
  var matches = str.match(/\b(\w)/g); // ['J','S','O','N']
  matches = matches.slice(0, 3);
  var acronym = matches.join(''); // JSON
  return acronym;
}
getSeverity(status: string) {
  switch (status.toLowerCase()) {
    case 'inactive':
      return 'danger';
    case 'active':
      return 'success';
  }
}
public getRoles() {

  this.ApiModuleService.get('admin/users/roles').subscribe((data) => {
    this.roles = data.data.userDetails != undefined ? data.data.userDetails : [];
  })
}
public addUsersSubmit() {
  if (!this.selectedFilteredUsers || this.selectedFilteredUsers.length === 0  ) {
    this.toastr.warning('No employees selected!', 'Warning');
    return;
  }
  if(this.MultipleROle==undefined || this.MultipleROle==null || this.MultipleROle=='' || this.MultipleROle.length==0){
    this.toastr.error('No Role Selected !', 'Error');
    return;

  }

  const  user_data= this.selectedFilteredUsers.map((user: any) => ({
    employee_id: user.employee_id,
    company_id: user.company_id,
    client_id: user.client_id,
    user_type:'employee',
    role_id: this.MultipleROle,
    email:user.email,
    name:user.employee_name
    }));
    const params:any={};
    params.client_id = this.ClientID;
    params.user_data = user_data
   this.ApiModuleService.add(params, 'admin/users/add_multipleuser').subscribe(
     (data) => {
      this.toastr.success('All Employees Credential Created');
      this.getEmployeesData();
     },
     (err) => {
       this.spinnerService.toasterError(err);
     }
   );
}

onItemSelect(item: any) {
}

onItem(item: any) {
}

onSelectAll(items: any) {
}



}
