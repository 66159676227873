// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-control{
  width: 70%;
}
.percentage{
  width: 48%;
}
.input-box{
  position: relative;
  top: -25px;
  left: 82px;
}
.span-box{
  width: 15%;
  position: relative;
  top: 5px;
  left: 82px;
}
.input-text{
width: 15%;
}
.input-contribution{
width: 48%;
}
.value-contribution{
width: 100%;
}
.submit-btn {
	border-radius:5px;
	font-size: 14px;
	font-weight: normal;
	width: 68px !important;
	color: black;
  margin-left: 14%;
}
.start-btn {
background-color:#08bd3f;
color: #fff;

}
.stop-btn{
  left: 21px;
  background-color: #eb3838;
  color: #fff;
  position: relative;
  top: 69px;
}
.calendar-width{
  width: auto;
}
.card {
  border: 1px solid #ededed;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
  margin-bottom: 15px !important;
}
.left-align{
margin-left: 10px;
}
.input-left-align{
  margin-left: -12px;

}
.range{
  width: 48%
}
`, "",{"version":3,"sources":["webpack://./src/app/all-modules/ess-tabs/IND-ess/IND-employee-payroll/provident-fund/nps/nps.component.css"],"names":[],"mappings":"AAAA;EACE,UAAU;AACZ;AACA;EACE,UAAU;AACZ;AACA;EACE,kBAAkB;EAClB,UAAU;EACV,UAAU;AACZ;AACA;EACE,UAAU;EACV,kBAAkB;EAClB,QAAQ;EACR,UAAU;AACZ;AACA;AACA,UAAU;AACV;AACA;AACA,UAAU;AACV;AACA;AACA,WAAW;AACX;AACA;CACC,iBAAiB;CACjB,eAAe;CACf,mBAAmB;CACnB,sBAAsB;CACtB,YAAY;EACX,gBAAgB;AAClB;AACA;AACA,wBAAwB;AACxB,WAAW;;AAEX;AACA;EACE,UAAU;EACV,yBAAyB;EACzB,WAAW;EACX,kBAAkB;EAClB,SAAS;AACX;AACA;EACE,WAAW;AACb;AACA;EACE,yBAAyB;EACzB,0CAA0C;EAC1C,8BAA8B;AAChC;AACA;AACA,iBAAiB;AACjB;AACA;EACE,kBAAkB;;AAEpB;AACA;EACE;AACF","sourcesContent":[".form-control{\r\n  width: 70%;\r\n}\r\n.percentage{\r\n  width: 48%;\r\n}\r\n.input-box{\r\n  position: relative;\r\n  top: -25px;\r\n  left: 82px;\r\n}\r\n.span-box{\r\n  width: 15%;\r\n  position: relative;\r\n  top: 5px;\r\n  left: 82px;\r\n}\r\n.input-text{\r\nwidth: 15%;\r\n}\r\n.input-contribution{\r\nwidth: 48%;\r\n}\r\n.value-contribution{\r\nwidth: 100%;\r\n}\r\n.submit-btn {\r\n\tborder-radius:5px;\r\n\tfont-size: 14px;\r\n\tfont-weight: normal;\r\n\twidth: 68px !important;\r\n\tcolor: black;\r\n  margin-left: 14%;\r\n}\r\n.start-btn {\r\nbackground-color:#08bd3f;\r\ncolor: #fff;\r\n\r\n}\r\n.stop-btn{\r\n  left: 21px;\r\n  background-color: #eb3838;\r\n  color: #fff;\r\n  position: relative;\r\n  top: 69px;\r\n}\r\n.calendar-width{\r\n  width: auto;\r\n}\r\n.card {\r\n  border: 1px solid #ededed;\r\n  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);\r\n  margin-bottom: 15px !important;\r\n}\r\n.left-align{\r\nmargin-left: 10px;\r\n}\r\n.input-left-align{\r\n  margin-left: -12px;\r\n\r\n}\r\n.range{\r\n  width: 48%\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
