import { Component, OnInit } from '@angular/core';
import { SpinnerService } from 'src/app/snipper/spinner.service';
import { FormGroup, Validators } from '@angular/forms';
import { ApiModulesService } from '../../api-modules.service';
import { AllModulesService } from '../../all-modules.service';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder } from '@angular/forms';
import { Table } from 'primeng/table';
@Component({
  selector: 'app-company-location',
  templateUrl: './company-location.component.html',
  styleUrls: ['./company-location.component.css']
})
export class CompanyLocationComponent implements OnInit {

  isDtInitialized: boolean = false;
  ClientID = localStorage.getItem('client_id');
  CompanyID = localStorage.getItem('company_id');
  public addlocationForm: FormGroup
  public ShowForm: any = false;
  public companylocationData = [];
  public totalRecords: any = 0;
  searchKeyword: string = '';
  ClientDropDown = [];
  CompanyDropDown = [];
  nameBgColors: any = ["bg-blue-dim", "bg-blue-dim", "bg-azure-dim", "bg-indigo-dim", "bg-info-dim", "bg-purple-dim", "bg-pink-dim", "bg-orange-dim", "bg-teal-dim", "bg-primary-dim", "bg-success-dim", "bg-warning-dim", "bg-danger-dim", "bg-secondary-dim", "bg-dark-dim", "bg-gray-dim", "bg-blue-dim", "bg-azure-dim", "bg-indigo-dim", "bg-info-dim", "bg-purple-dim", "bg-pink-dim", "bg-orange-dim", "bg-teal-dim", "bg-primary-dim", "bg-success-dim", "bg-warning-dim", "bg-danger-dim", "bg-secondary-dim", "bg-dark-dim", "bg-gray-dim"];
  nameBorderColors: any = ["bg-outline-primary", "bg-outline-success", "bg-outline-info", "bg-outline-gray", "bg-outline-warning", "bg-outline-dark", "bg-outline-light", "bg-outline-secondary", "bg-outline-danger"];
  statuses = [];
  loading = false;
  statusApi: any;
  statusValues: any;
  getLocationData: any;
  countriesList: any;
  statesList: any;
  citiesList: any;
  public srch = [];
  search: any[];
  rows: any;
  constructor(private apiModulesService: ApiModulesService, private AllModulesService: AllModulesService, private toaster: ToastrService, private toastr: ToastrService, private formBuilder: FormBuilder, private spinnerService: SpinnerService) { }

  ngOnInit(): void {
    this.spinnerService.raiseDataEmitterEvent('off');
    this.getCountries();

    this.GetClientDropDown();
    
    this.addlocationForm = this.formBuilder.group({
      location_name: ["", [Validators.required]],
      company_address: ["", [Validators.required]],
      country_id:["", [Validators.required]],
      state_id:["", [Validators.required]],
      city_id:["", [Validators.required]],
      pincode:["", [Validators.required]],
      location_code:["", [Validators.required]],
      company_location_id: ["", []]

    });
    this.statuses = [
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ];

  }

  showAddForm() {
    this.ShowForm = true;
    this.resetForm();
  }
  /* client DropDown codes*/
  public GetClientDropDown() {
    this.apiModulesService.get('DropDown/client').subscribe((data) => {
      this.ClientDropDown = data.data.client;
      this.ClientID = data.data.client[0].client_id;
      this.GetCompanyDropDown();
    })
  }

  /* company DropDown codes */
  public GetCompanyDropDown() {
    const params: any = {};
    params.client_id = this.ClientID;

    this.apiModulesService.list('DropDown/company', params).subscribe((data) => {
      this.CompanyDropDown = data.data.company != undefined ? data.data.company : [];
      if (this.CompanyDropDown.length !== 0) {
        setTimeout(() => {
          this.CompanyID = this.CompanyDropDown[0].company_id;
          this.getLocation();
        }, 100)
      }
      else {
        this.CompanyID = null;
      }
    })
  }

  public getLocation() {
    this.spinnerService.raiseDataEmitterEvent('on');
    var params: any = {};
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    this.apiModulesService.list("admin/location/grid-load", params).subscribe((data) => {
      this.getLocationData = data.data.company_location != undefined ? data.data.company_location : [];
      this.spinnerService.raiseDataEmitterEvent('off');

    }, (err) => {

      this.spinnerService.toasterError(err);

    });
  }
  addLocation() {
    if (!this.addlocationForm.valid) {
      this.addlocationForm.markAllAsTouched();
      return;
    }
    var params: any = {};
    params.company_id = this.CompanyID;
    params.client_id = this.ClientID;
    params.location_name = this.addlocationForm.get('location_name').value;
    params.company_address = this.addlocationForm.get('company_address').value;
    params.country_id = this.addlocationForm.get('country_id').value;
    params.state_id = this.addlocationForm.get('state_id').value;
    params.pincode = this.addlocationForm.get('pincode').value;
    params.location_code = this.addlocationForm.get('location_code').value;
    params.city_id = this.addlocationForm.get('city_id').value;
    var company_location_id = this.addlocationForm.get('company_location_id').value;

    if (company_location_id != '' && company_location_id != undefined) {
      params.company_location_id = this.addlocationForm.get('company_location_id').value;
      this.apiModulesService.edit(params, "admin/location/update",).subscribe((data) => {
        this.toastr.success('Location Modified Sucessfully...!', 'Success');
        ($('#add_location') as any).modal('hide');
        this.getLocation()
      }, (err) => {
      this.spinnerService.toasterError(err)
      });

    } else {
      this.apiModulesService.add(params, "admin/location/add",).subscribe((data) => {
        this.toastr.success('Location Added Sucessfully...!', 'Success');
        this.addlocationForm.reset();
        ($('#add_location') as any).modal('hide');
        this.getLocation()

      }, (err) => {
        this.spinnerService.toasterError(err)
      });
    }
    
  }
  
  public edit(params) {
    this.addlocationForm.setValue({
      location_name: params.location_name,
      company_address: params.company_address,
      country_id:params.country_id,
      state_id :params.state_id,
      location_code :params.location_code,
      pincode :params.pincode,
      city_id : params.city_id,
      company_location_id : params.company_location_id,

    });
  }
 resetForm() {
    this.addlocationForm.reset();
  }

  getAcronym(str: any) {
    var matches = str.match(/\b(\w)/g); // ['J','S','O','N']
    matches = matches.slice(0, 2);
    var acronym = matches.join(''); // JSON
    return acronym;
  }
  getBgColors(str: any) {
    var min = 0;
    var max = 15;
    var index = this.findAlphapositions(str, 1);
    return this.nameBgColors[index];
  }
  findAlphapositions(str: any, n: any) {
    var NUM = 31;

    return str[0].charCodeAt(0) & NUM;
  }

  public getCountries() {
    this.apiModulesService.get('country/list').subscribe((data) => {
        const res: any = data;
        this.countriesList = (res.data.countries !== undefined) ? res.data.countries : [];
    }, (err) => {
      this.spinnerService.toasterError(err);
});
}

 public getStates() {
   var params: any = {};
  params.country_id = this.addlocationForm.get('country_id').value;
   this.apiModulesService.list("country/states", params).subscribe((data) => {
     this.statesList = (data.data.states !== undefined) ? data.data.states : [];
   });
 }

   public getCities() {
     const params: any = {};
     params.state_id = this.addlocationForm.get('state_id').value;
     this.apiModulesService.list("country/cities", params).subscribe((data) => {
       this.citiesList = (data.data.states !== undefined) ? data.data.states : [];
     });
   }
  clear(table: Table) {
    table.clear();
  }
  changeStatus(Status: any,location) {
    const params: any = {};
    params.client_id = this.ClientID
    params.company_id = this.CompanyID;
    params.company_location_id = location.company_location_id;
    this.apiModulesService.edit(params, 'admin/location/' + Status).subscribe((data) => {
      ($('#companylocation-status') as any).modal('hide');
      this.toastr.success('Company changed sucessfully...!', 'Success');
      this.getLocation();
    }, (err) => {
      this.spinnerService.toasterError(err)

      }
    );
  }

  getSeverity(status: string) {
    switch (status.toLowerCase()) {
      case 'inactive':
        return 'danger';

      case 'active':
        return 'success';
    }
  }
  callStatusModel(url_name: any, data: any) {
    this.statusApi = url_name;
    this.statusValues = data;
  }

}