// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.statusactive{
	font-size: 30px;
}`, "",{"version":3,"sources":["webpack://./src/app/all-modules/employees/salary-compensation/salary-compensation.component.css"],"names":[],"mappings":"AAAA;CACC,eAAe;AAChB","sourcesContent":[".statusactive{\r\n\tfont-size: 30px;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
