import { Component, OnInit, ViewChild,AfterViewInit,OnDestroy } from '@angular/core';
import { ApiModulesService } from '../../api-modules.service';
import { AllModulesService } from "../../all-modules.service";

import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription, Subject, } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { SpinnerService } from 'src/app/snipper/spinner.service';
import { Table } from 'primeng/table';
import { LeaveplantypeComponent } from './leaveplantype/leaveplantype.component';

declare const $: any;
@Component({
  selector: 'app-leavemanagement',
  templateUrl: './leavemanagement.component.html',
  styleUrls: ['./leavemanagement.component.css']
})
export class LeavemanagementComponent implements OnInit, OnDestroy, AfterViewInit {
  public leavedata = [];
  public listHeaders = [];
  rows: any;
  public activeIndex = 0;
  public srch = [];
  search: any[];
  ClientDropDown = [];
  CompanyDropDown = [];
  public addLeaveForm: FormGroup
  public clientname = [];
  isDtInitialized: boolean = false;
  public totalRecords: any = 0;
  public ShowForm: any = false;
  ClientID :any;
  CompanyID :any;
  tabToGrid=false;
  loading=false;
  nameBgColors:any = ["bg-blue-dim","bg-blue-dim","bg-azure-dim","bg-indigo-dim","bg-info-dim","bg-purple-dim","bg-pink-dim","bg-orange-dim","bg-teal-dim","bg-primary-dim","bg-success-dim","bg-warning-dim","bg-danger-dim","bg-secondary-dim","bg-dark-dim","bg-gray-dim","bg-blue-dim","bg-azure-dim","bg-indigo-dim","bg-info-dim","bg-purple-dim","bg-pink-dim","bg-orange-dim","bg-teal-dim","bg-primary-dim","bg-success-dim","bg-warning-dim","bg-danger-dim","bg-secondary-dim","bg-dark-dim","bg-gray-dim"];
  nameBorderColors:any = ["bg-outline-primary","bg-outline-success","bg-outline-info","bg-outline-gray","bg-outline-warning","bg-outline-dark","bg-outline-light","bg-outline-secondary","bg-outline-danger"];

  public days = []
  editedLeaveData: any = {};
  showTabs = false;
  scrollableTabs = [
    {
        title: 'Leave Plan Type'
    }, {
        title: 'Leave Company Holiday'
    }
];
  statuses: { label: string; value: string; }[];
  statusApi: any;
  statusValues: any;
  constructor(private apiModulesService: ApiModulesService, private AllModulesService: AllModulesService, private toaster: ToastrService, private toastr: ToastrService, private formBuilder: FormBuilder,private spinnerService: SpinnerService) { }

  ngOnInit(): void {
   
    this.GetClientDropDown();
    this.addLeaveForm = this.formBuilder.group({
      leave_plan_name: ["", [Validators.required]],
      plan_start_date: ["", [Validators.required]],
      client_id: ["", []],
      company_id: ["", []],
      plan_end_date: ["", [Validators.required]],
      leave_day_name: ["", [Validators.required]],
      leave_plan_id: ["", []],
      work_days_per_week: ["", [Validators.required]],
      leave_plan_desc: ["", [Validators.required]]

    });
    this.statuses = [
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ];
  }

  showAddForm() {
    this.ShowForm = true;
    this.resetForm();
  }
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
  }

  //Get all paycomponent data
  public getLeave() {
    this.spinnerService.raiseDataEmitterEvent('off');
    var params:any={};
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
          this.apiModulesService.list("admin/leavemanagement/getLeave", params).subscribe((data) => {
              this.leavedata = (data.data.leaves != undefined) ? data.data.leaves : [];
              this.loading = false;
              this.ShowForm = false;
              this.showTabs = false;
              var totalRecords = (this.leavedata[0] != undefined) ? this.leavedata[0].total_records : 0;
              this.totalRecords = totalRecords;
              this.resetForm();
              setTimeout(() => {
                this.spinnerService.raiseDataEmitterEvent('off');
            }, 100);
          }, (err) => {
            this.leavedata = [];
            this.spinnerService.raiseDataEmitterEvent('off');
          });
  }
  clear(table: Table) {
    table.clear();
  }
  
  getSeverity(status: string) {
    switch (status.toLowerCase()) {
      case 'inactive':
        return 'danger';
  
      case 'active':
        return 'success';
    }
  }
  changeStatus(Status: any, data) {
    let params: any = {};
    params.client_id = this.ClientID;
    params.leave_plan_id = data.leave_plan_id,
      params.company_id = this.CompanyID;
    this.apiModulesService.edit(params, 'admin/leavemanagement/' + Status).subscribe((data) => {
      this.getLeave();
      ($('#comp-leave-plan-status') as any).modal('hide');
      this.toaster.success('Status Changed Successfully!', 'Success')
    }, (err) => {
      this.toastr.error("Something went wrong!", "Try Again");
    }
    );
  }
  callStatusModel(url_name: any, data: any) {
    this.statusApi = url_name;
    this.statusValues = data;
  }
  public onAddLeave() {
    if (!this.addLeaveForm.valid) {
      this.addLeaveForm.markAllAsTouched();
      return;
    }
    var params: any = {};
    params.company_id = this.CompanyID;
    params.client_id = this.ClientID;
    params.leave_plan_name = this.addLeaveForm.get('leave_plan_name').value;
    params.leave_plan_desc = this.addLeaveForm.get('leave_plan_desc').value;
    params.plan_start_date = this.addLeaveForm.get('plan_start_date').value;
    params.plan_end_date = this.addLeaveForm.get('plan_end_date').value;
    params.leave_day_name = this.addLeaveForm.get('leave_day_name').value;
    params.work_days_per_week = this.addLeaveForm.get('work_days_per_week').value;
    var leave_plan_id = this.addLeaveForm.get('leave_plan_id').value;

    if (leave_plan_id != '' && leave_plan_id != undefined) {
      params.leave_plan_id = this.addLeaveForm.get('leave_plan_id').value;
      this.apiModulesService.edit(params, "admin/leavemanagement/updateLeave",).subscribe((data) => {

        this.getLeave();
        this.toastr.success('Leave Modified Sucessfully...!', 'Success');
        ($('#add_leave') as any).modal('hide');
      }, err => {
        if (err.status === 437) {
          this.toastr.error('Leave Already Exists...!', 'Failed');
        } else {
          this.toastr.error('Something Went Wrong Please Try Again...!', 'Error');
        }
      });

    } else {
      this.apiModulesService.add(params, "admin/leavemanagement/createLeave",).subscribe((data) => {
        this.getLeave();
        this.toastr.success('Leave Added Sucessfully...!', 'Success');
        this.addLeaveForm.reset();
        ($('#add_leave') as any).modal('hide');
      }, err => {
        if (err.status === 437) {
          this.toastr.error('Leave Already Exists...!', 'Failed');
        } else {
          this.toastr.error('Something Went Wrong Please Try Again...!', 'Error');
        }
      });
    }
  }
  //Reset form
  public resetForm() {
    this.addLeaveForm.reset();
  }
  public edit(leaves: any) {
    this.resetForm();
    const params: any = {};
    params.client_id = this.ClientID;
    params.leave_plan_id = leaves.leave_plan_id;
    params.company_id = this.CompanyID;
    this.apiModulesService.edit(params, 'admin/leavemanagement/editLeave').subscribe((data) => {
            const leaveDetail = (data.data.leaves !== undefined) ? data.data.leaves : [];
            this.addLeaveForm.setValue({
                leave_plan_name: leaveDetail.leave_plan_name,
                leave_plan_desc: leaveDetail.leave_plan_desc,
                plan_start_date: leaveDetail.plan_start_date,
                plan_end_date: leaveDetail.plan_end_date,
                leave_day_name: leaveDetail.leave_day_name,
                work_days_per_week: leaveDetail.work_days_per_week,
                leave_plan_id: leaveDetail.leave_plan_id,
                client_id: leaveDetail.client_id,
                company_id: leaveDetail.company_id
            });

            this.ShowForm = true;
        }, (err) => {
            this.toastr.error('Something went wrong!', 'Try Again');
        }
    );
}
 
  ngAfterViewInit(): void {
    setTimeout(() => {
    }, 2000);
  }
  rerender() {
  
  } 
  // dropdown 
  public dropDown(){
    const params: any = {};
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    this.apiModulesService.list("admin/leavemanagement/dropDown", params).subscribe((data)=>{
      this.days = data.data.leaves
    })
  }

 /* client DropDown codes*/
 public GetClientDropDown() {
  this.apiModulesService.get('DropDown/client').subscribe((data) => {
    this.ClientDropDown = data.data.client;
    this.ClientID = data.data.client[0].client_id;
    this.GetCompanyDropDown();

  })
}

  /* company DropDown codes */
  public GetCompanyDropDown() {
    const params: any = {};
    params.client_id = this.ClientID;
    this.apiModulesService.list('DropDown/company', params).subscribe((data) => {
      this.CompanyDropDown = data.data.company != undefined ? data.data.company : [];
      if (this.CompanyDropDown.length !== 0) {
        setTimeout(() => {
          this.CompanyID = this.CompanyDropDown[0].company_id;
          this.dropDown();
          this.getLeave();
        }, 100)
      }
      else {
        this.CompanyID = null;
        this.rerender();
      }

    }
    )
  }
  changeViewGridToTabs(leaveplanId: number, clientId: number, companyId: number) {
    this.editedLeaveData.client_id = clientId;
    this.editedLeaveData.company_id = companyId;
    this.editedLeaveData.leave_plan_id = leaveplanId;
    this.showTabs = true;
}
changeTabsViewToGrid(value) {
  this.tabToGrid=value;
  this.activeIndex = 0;
  this.getLeave();
  this.resetForm();
}
changeTabsIndex(index) {
  this.activeIndex = index;
}
getAcronym(str: any) {
  if (str != null) {
      let matches = str.match(/\b(\w)/g); // ['J','S','O','N']
      matches = matches.slice(0, 2);
      const acronym = matches.join(''); // JSON
      return acronym;
  }
}

getBgColors(str: any) {
  if (str != null) {
      const min = 0;
      const max = 15;
      const index = this.findAlphapositions(str, 1);
      return this.nameBgColors[index];
  }
}
  findAlphapositions(str:any, n:any)
  {
    var NUM = 31;
  
    return str[0].charCodeAt(0) & NUM;
  }   
}