import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { AllModulesService } from '../../../all-modules.service';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
  FormArray,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { ApiModulesService } from '../../../api-modules.service';
import { WebStorage } from 'src/app/core/storage/web.storage';
import { Subscription } from 'rxjs';
import { SpinnerService } from "../../../../snipper/spinner.service";
import { Table } from "primeng/table";

declare const $: any;

@Component({
  selector: 'app-user-main',
  templateUrl: './user-main.component.html',
  styleUrls: ['./user-main.component.css'],
})
export class UserMainComponent implements OnInit, OnDestroy, AfterViewInit {
  public url: any = 'users';
  public allUsers: any = [];
  public addUsers: FormGroup;
  public addEntityMapped: FormGroup;
  public editUsers: FormGroup;
  public editId: any;
  public tempId: any;
  public rows = [];
  public srch = [];
  searchKeyword: string = '';
  dropdownList = [];
  statuses: any;
  userType: any;
  loading = false;
  dropdownSettings: IDropdownSettings;
  entityDropdownSettings: IDropdownSettings;
  public ShowForm: any = false;
  loginData: any;
  public isvalidconfirmpassword: boolean = false;
  public subscription: Subscription;
  public CustomControler: any;
  public clientname = [];
  public userTypeDropDown = [];
  public clientDropDown = [];
  public companyDropDown = [];
  public employeeDropDown = [];
  roles: any;
  type: any;
  list: any[];
  edit_roles: any;
  selectedItems: any = [];
  remove_items: any = [];
  nameBgColors: any = ['bg-blue-dim', 'bg-blue-dim', 'bg-azure-dim', 'bg-indigo-dim', 'bg-info-dim', 'bg-purple-dim', 'bg-pink-dim', 'bg-orange-dim', 'bg-teal-dim', 'bg-primary-dim', 'bg-success-dim', 'bg-warning-dim', 'bg-danger-dim', 'bg-secondary-dim', 'bg-dark-dim', 'bg-gray-dim', 'bg-blue-dim', 'bg-azure-dim', 'bg-indigo-dim', 'bg-info-dim', 'bg-purple-dim', 'bg-pink-dim', 'bg-orange-dim', 'bg-teal-dim', 'bg-primary-dim', 'bg-success-dim', 'bg-warning-dim', 'bg-danger-dim', 'bg-secondary-dim', 'bg-dark-dim', 'bg-gray-dim'];
  nameBorderColors: any = ['bg-outline-primary', 'bg-outline-success', 'bg-outline-info', 'bg-outline-gray', 'bg-outline-warning', 'bg-outline-dark', 'bg-outline-light', 'bg-outline-secondary', 'bg-outline-danger'];
  statusApi: any;
  statusValues: any;
  ClientDropDown: any;
  ClientID: any;
  CompanyDropDown: any;
  CompanyID: any;
  userID: any;
  mappedData: any;
  entityId: any;
  userListId: any;

  constructor(
    private allModuleService: AllModulesService,
    private apiModulesService: ApiModulesService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private storage: WebStorage,
    private spinnerService: SpinnerService
  ) {
    this.subscription = this.storage.Createaccountvalue.subscribe((data) => {
      this.CustomControler = data;
    });
  }

  ngOnInit() {
    this.spinnerService.raiseDataEmitterEvent('on');
    $('.floating')
      .on('focus blur', function (e) {
        $(this)
          .parents('.form-focus')
          .toggleClass('focused', e.type === 'focus' || this.value.length > 0);
      })
      .trigger('blur');
    this.getUserTypeDropDown();
    this.getUsers();

    this.statuses = [
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ];
    this.userType = [
      { label: 'Admin', value: 'admin' },
      { label: 'Client', value: 'client' },
      { label: 'Company', value: 'company' },
      { label: 'Employee', value: 'employee' },
    ];
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'role_id',
      textField: 'role_name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
    };
    this.entityDropdownSettings = {
      singleSelection: false,
      idField: 'company_id',
      textField: 'company_name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 5,
      allowSearchFilter: true,
    };
    // Add Provident Form Validation And Getting Values

    this.addUsers = this.formBuilder.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required]],
      password: ['', [Validators.required]],
      confirmPassword: new FormControl('', [Validators.required]),
      user_type: ['', [Validators.required]],
      client_id: ['', []],
      company_id: [''],
      employee_id: [''],
      role_id: ['', [Validators.required]]
    });
    this.addEntityMapped = this.formBuilder.group({
      client_id: ['', [Validators.required]],
      company_id: ['', [Validators.required]],


    });

    // Edit Provident Form Validation And Getting Values

    this.editUsers = this.formBuilder.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required]],
      id: ['', []],
      client_id: ['', [Validators.required]],
      role_id: ['', [Validators.required]]
    });
    // for data table configuration
  }

  ngAfterViewInit(): void {

  }

  // manually rendering Data table
  onItemSelect(item: any) {
  }

  onItem(item: any) {
  }

  onSelectAll(items: any) {
  }

  getUsers() {
    this.spinnerService.raiseDataEmitterEvent('on');
    this.apiModulesService.get('admin/users/list').subscribe((data) => {
      this.allUsers = (data.data.userDetails != undefined) ? data.data.userDetails : [];
      this.GetClientDropDown();
      this.spinnerService.raiseDataEmitterEvent('off');
    }, (err) => {
      this.allUsers = [];
      this.spinnerService.raiseDataEmitterEvent('off'); let errors = err.error.data.userDetails;
      errors = errors.replace('SQLSTATE[42000]: [Microsoft][ODBC Driver 17 for SQL Server][SQL Server]', 'Error: ')
      this.toastr.error(errors, 'TryAgain');

    });
  }

  shareCheckedList(item: any[]) {

  }

  shareIndividualCheckedList(item: {}) {
  }


  public addUsersSubmit() {
    if (!this.addUsers.valid) {
      this.addUsers.markAllAsTouched();
      return;
    }
    if (this.addUsers.value.password != this.addUsers.value.confirmPassword) {
      this.isvalidconfirmpassword = true;
    } else {
      this.isvalidconfirmpassword = false;
      const params: any = {}
      params.name = this.addUsers.get('name').value;
      params.email = this.addUsers.get('email').value;
      params.role_id = this.addUsers.get('role_id').value;
      params.password = this.addUsers.get('password').value;
      params.user_type = this.addUsers.get('user_type').value;
      if (this.addUsers.get('client_id').value != null && this.addUsers.get('client_id').value !== '') {
        params.client_id = this.addUsers.get('client_id').value;
        params.company_id = this.addUsers.get('company_id').value;
        params.employee_id = this.addUsers.get('employee_id').value;
      }
      this.apiModulesService.add(params, 'admin/users/add',).subscribe((data) => {
        this.getUsers();
        this.getUserTypeDropDown();
        ($('#add_user') as any).modal('hide');
        this.addUsers.reset();
        this.toastr.success('User added successfully...!', 'Success');
      }, (err) => {
        this.toastr.error('Something went wrong!', 'Try Again');
      }
      );
    }
  }

  // Delete Provident Modal Api Call

  deleteUsers() {
  }

  // search by name
  searchName(val) {
    this.rows.splice(0, this.rows.length);
    let temp = this.srch.filter(function (d) {
      val = val.toLowerCase();
      return d.name.toLowerCase().indexOf(val) !== -1 || d.email.toLowerCase().indexOf(val) !== -1
    });
    this.rows.push(...temp);
  }

  changeStatus(status: any, id: any) {
    const params: any = {};
    params.id = id;
    params.status = status;
    this.apiModulesService.edit(params, 'admin/users/status').subscribe(() => {
      this.getUsers();
      ($("#usermain-status") as any).modal("hide");
      this.toastr.success('Status updated successfully...!', 'Success');
    }, (err) => {
      this.toastr.error('Something went wrong!', 'Try Again');
    }
    );
  }


  public resetForm() {
    this.addUsers.reset();
    this.editUsers.reset();
    this.addEntityMapped.reset();
  }

  showAddForm() {
    this.ShowForm = true;
    this.resetForm();
  }

  showList() {
    this.ShowForm = false;
  }

  // getclientname
  public getCompany() {
    const params: any = {};
    params.user_type = this.addUsers.get('user_type').value;
    params.client_id = this.addUsers.get('client_id').value;
    if ((this.addUsers.get('user_type').value != null && this.addUsers.get('user_type').value != '') && (this.addUsers.get('client_id').value != null && this.addUsers.get('client_id').value != '')) {
      this.apiModulesService.list('admin/users/company/dropDown', params).subscribe((data) => {
        this.companyDropDown = data.data.userDetails != undefined ? data.data.userDetails : [];
      });
    } else {
      this.companyDropDown = [];
    }

  } public getEmployee() {
    const params: any = {};
    params.user_type = this.addUsers.get('user_type').value;
    params.client_id = this.addUsers.get('client_id').value;
    params.company_id = this.addUsers.get('company_id').value;
    if ((this.addUsers.get('company_id').value != null && this.addUsers.get('company_id').value != '') && (this.addUsers.get('client_id').value != null && this.addUsers.get('client_id').value != '')) {
      this.apiModulesService.list('admin/users/employee/dropDown', params).subscribe((data) => {
        this.employeeDropDown = data.data.userDetails != undefined ? data.data.userDetails : [];
      });
    } else {
      this.employeeDropDown = [];
    }

  }
  public getUserTypeDropDown() {
    this.apiModulesService.get('admin/users/user-type').subscribe((data) => {
      this.type = data.data.type;
      this.userTypeDropDown = data.data.userDetails[0] != undefined ? data.data.userDetails[0] : [];
      this.clientDropDown = data.data.userDetails[1] != undefined ? data.data.userDetails[1] : [];
    });
  }

  public getRoles() {
    this.addUsers.patchValue({
      client_id: '',
      company_id: '',
      employee_id: ''
    });
    this.companyDropDown = [];
    this.employeeDropDown = [];
    this.apiModulesService.get('admin/users/roles').subscribe((data) => {
      this.roles = data.data.userDetails != undefined ? data.data.userDetails : [];
    })
  }

  // public  getEditRoles(){
  // }
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
  }

  // Edit
  public edit(id: any) {
    this.editUsers.reset();
    var params: any = {};
    params.id = id
    this.getRoles();
    this.apiModulesService.edit(params, 'admin/users/edit').subscribe((data) => {
      let userDetail = data.data.userDetails != undefined ? data.data.userDetails : [];
      let params: any = {}
      params.user_id = id;
      this.apiModulesService.list('admin/users/roles_edit', params).subscribe((data) => {
        let selectedItems = data.data.userDetails != undefined ? data.data.userDetails : [];
        this.selectedItems = data.data.userDetails != undefined ? data.data.userDetails : [];
        //  = [{role_id: '5', role_name: 'Client Admin Roles', client_id: '1', record_status: '1',isDisabled: true}];

        this.editUsers.setValue({
          name: userDetail.name,
          email: userDetail.email,
          role_id: selectedItems,
          id: userDetail.id,
          client_id: userDetail.client_id

        });
      });
    });

  }

  // update
  public update() {
    if (!this.editUsers.valid) {
      this.editUsers.markAllAsTouched();
      return;
    }
    let remove_roles: any = [];

    var params: any = {};
    params.name = this.editUsers.get('name').value;
    params.email = this.editUsers.get('email').value;
    params.roles = this.editUsers.get('role_id').value;
    params.id = this.editUsers.get('id').value;
    params.client_id = this.editUsers.get('client_id').value;
    params.stausClient = this.editUsers.get('client_id').value;
    params.deactive_roles = this.selectedItems;

    this.apiModulesService.edit(params, 'admin/users/update').subscribe((data) => {
      ($('#edit_user') as any).modal('hide');
      this.getUsers();

      this.toastr.success('user updated successfully...!', 'success');
    }, (err) => {
      this.toastr.error('Something went wrong!', 'Try Again');
    }
    );
  }
  /* client DropDown codes*/
  public GetClientDropDown() {
    // const params: any = {};
    this.apiModulesService.get('admin/users/dropdown/client').subscribe((data) => {
      this.ClientDropDown = data.data.client;
      this.GetCompanyDropDown();
    }, (err: any) => {
      this.spinnerService.toasterError(err);
    })
  }

  /* company DropDown codes */
  public GetCompanyDropDown() {
    this.addEntityMapped.patchValue({
      company_id: ''
    });
    const params: any = {};
    params.client_id = this.addEntityMapped.get('client_id').value;
    this.apiModulesService.list('admin/users/dropdown/company', params).subscribe((data) => {
      this.CompanyDropDown = data.data.company != undefined ? data.data.company : [];
      if (this.CompanyDropDown.length !== 0) {
        setTimeout(() => {
        }, 100)
      }
      else {
        this.CompanyID = null;
      }
    }, (err: any) => {
      this.spinnerService.toasterError(err);
    })
  }
  public userIDCapture(id: any) {
    this.userID = id;
  }
  public permissionEntities() {
    var params: any = {};
    params.user_id = this.userID;
    params.client_id = this.addEntityMapped.get('client_id').value;
    params.user_entity = this.addEntityMapped.get('company_id').value;
    this.apiModulesService.list('admin/users/entity-create', params).subscribe((data) => {
      ($('#permission_user') as any).modal('hide');
      this.resetForm();
      this.toastr.success('User Mapped Successfully...!', 'success');
    }, (err) => {
      this.toastr.error('Something went wrong!', 'Try Again');

    })
  }
  getListMapping(id: any) {
    var params: any = {};
    this.userListId = id;
    params.user_id = id;
    this.apiModulesService.list('admin/users/mapped-list', params).subscribe((data) => {
      this.mappedData = (data.data.userCreation != undefined) ? data.data.userCreation : [];
    })
  }
  activeMapping(entities_mapping_id: any) {
    var params: any = {};
    params.entities_mapping_id = entities_mapping_id;
    this.apiModulesService.list('admin/users/active', params).subscribe((data) => {
      this.toastr.success('Status Changed Successfully!', 'Success')
      this.getListMapping(this.userListId);
    }, (err) => {
      this.toastr.error('Something went wrong!', 'Try Again');
    });
  }
  deActiveMapping(entities_mapping_id: any) {
    var params: any = {};
    params.entities_mapping_id = entities_mapping_id;
    this.apiModulesService.list('admin/users/de-active', params).subscribe((data) => {
      this.toastr.success('Status Changed Successfully!', 'Success')
      this.getListMapping(this.userListId);
    }, (err) => {
      this.toastr.error('Something went wrong!', 'Try Again');
    });
  }
  getAcronym(str: any) {
    var matches = str.match(/\b(\w)/g); // ['J','S','O','N']
    matches = matches.slice(0, 3);
    var acronym = matches.join(''); // JSON
    return acronym;
  }

  getBgColors(str: any) {
    var min = 0;
    var max = 15;
    var index = this.findAlphapositions(str, 1);
    return this.nameBgColors[index];
  }

  findAlphapositions(str: any, n: any) {
    var NUM = 31;

    return str[0].charCodeAt(0) & NUM;
  }
  clear(table: Table) {
    table.clear();
  }

  getSeverity(status: string) {
    switch (status.toLowerCase()) {
      case 'inactive':
        return 'danger';

      case 'active':
        return 'success';
    }
  } getUserType(status: string) {
    switch (status.toLowerCase()) {
      case 'admin':
        return 'danger';

      case 'client':
        return 'success';
      case 'company':
        return 'info';
      case 'employee':
        return 'warning';
    }
  }

  callStatusModel(url_name: any, data: any, actionName: string) {
    this.statusApi = url_name;
    this.statusValues = data;
  }


}
