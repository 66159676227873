// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table{
    /* padding-top: 140px; */
    }
    `, "",{"version":3,"sources":["webpack://./src/app/all-modules/admin-tabs/nationality/nationality-main/nationality-main.component.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;IACxB","sourcesContent":["table{\r\n    /* padding-top: 140px; */\r\n    }\r\n    "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
