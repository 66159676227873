import { Component, OnInit, VERSION, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { ApiModulesService } from '../../api-modules.service';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray, } from '@angular/forms';
import { Table } from 'primeng/table';
import { Subject } from 'rxjs';
import { NgForm } from '@angular/forms';
import { NgFor } from '@angular/common';
import { documentservice } from '../../documents.sevice';
import { SpinnerService } from '../../../snipper/spinner.service';
import { PaycomponentimportComponent } from "./paycomponentimport.component";
import { CompanyformulabuilderComponent } from '../companyformulabuilder/companyformulabuilder.component';
import { param } from 'jquery';
declare const $: any;


@Component({
    selector: 'app-companypaycomponent',
    templateUrl: './companypaycomponent.component.html',
    styleUrls: ['./companypaycomponent.component.css']
})
export class CompanypaycomponentComponent implements OnInit, AfterViewInit, OnDestroy {

    activeTab = "paycomponent_formula";
 
    @ViewChild(PaycomponentimportComponent,) payComponentImport: PaycomponentimportComponent;

    companypayData = [];
    rows: any;
    search: any[];
    ClientID: any;
    CompanyID: any;
    ClientDropDown = [];
    CompanyDropDown = [];
    form: FormGroup;
    nameRegex = /^[A-Za-z\s]+$/;
    companypayDetail = [];
    componentData: any;
    paycomponentData = [];
    paycomponentFilterData = [];
    import_component_type_id: any;
    isDtInitialized = false;
    value: any = [];
    name = 'Angular ' + VERSION.major;
    checklist: any;
    paymaster = false;
    countryfillter: any;
    paycomponent_id: any;
    frequency_id: any;
    is_mandatory: any;
    componentDropDown: any;
    frequencyDropDown: any;
    configValueDropDown = [];
    component_id: any;
    country_id: any;
    pay_frequency_id: any;
    component_type_id: any;
    component_key: any;
    component_shot: any;
    component_name: any;
    component_display_name: any;
    component_desc: any;
    default_values: any;
    config_values: any;
    Referencetype_ID: any;
    Reference_Type:any;
    loading: boolean = false;
    public totalRecords: any = 0;
    public ShowForm: any = false;
    configvalues: any;
    statusApi: any;
    statusValues: any;
    statuses: { label: string; value: string; }[];

    nameBgColors: any = ['bg-blue-dim', 'bg-blue-dim', 'bg-azure-dim', 'bg-indigo-dim', 'bg-info-dim', 'bg-purple-dim', 'bg-pink-dim', 'bg-orange-dim', 'bg-teal-dim', 'bg-primary-dim', 'bg-success-dim', 'bg-warning-dim', 'bg-danger-dim', 'bg-secondary-dim', 'bg-dark-dim', 'bg-gray-dim', 'bg-blue-dim', 'bg-azure-dim', 'bg-indigo-dim', 'bg-info-dim', 'bg-purple-dim', 'bg-pink-dim', 'bg-orange-dim', 'bg-teal-dim', 'bg-primary-dim', 'bg-success-dim', 'bg-warning-dim', 'bg-danger-dim', 'bg-secondary-dim', 'bg-dark-dim', 'bg-gray-dim'];
    nameBorderColors: any = ['bg-outline-primary', 'bg-outline-success', 'bg-outline-info', 'bg-outline-gray', 'bg-outline-warning', 'bg-outline-dark', 'bg-outline-light', 'bg-outline-secondary', 'bg-outline-danger'];
    userDropDown = [];
    userKey: any;
    PaygroupDropDown = [];
    PaygroupID: any;
    fileDateId: any;
    isFileShow = false;
    componentformulaData: any;
    childResponse: string;
    city_id: any;
    paycomponentdropdownData: any;
    selection_type: any;
    componentFormDropDown:any

    constructor(private apiModuleService: ApiModulesService,
        private toastrService: ToastrService,
        private formBuilder: FormBuilder, private spinnerService: SpinnerService) {
    }

    ngOnInit(): void {
        this.spinnerService.raiseDataEmitterEvent('on');

        this.GetuserDropDown();
        //this.Paycomponentdropdown();


        // Add
        this.form = this.formBuilder.group({
            pay_frequency_id: [''],
            list_name: [''],
            component: this.formBuilder.array([])
        });

    }

    changeTabs(activeTab) {

        this.activeTab = activeTab;
    
      }

    ngAfterViewInit(): void {

    }

    showAddForm() {
        this.ShowForm = true;
    }

    showList() {
        this.ShowForm = false;
        
    }

    showFilePage(ID,CountryID) {
        this.component_id = ID;
        this.country_id = CountryID;
        this.isFileShow = true;
      }
      showDatePage(event) {
        this.isFileShow = false;
        this.activeTab = "paycomponent_formula";
        this.getCompanypay();
      }
     

      showTogrid(event) {
        this.ShowForm = false;
    }

    rerender(): void {
    }

   

    // Get all companypaycomponent data
    public getCompanypay() {
        this.spinnerService.raiseDataEmitterEvent('on');

            const params: any = {};
            params.client_id = this.ClientID;
            params.company_id = this.CompanyID;
            params.import_component_type_id = this.import_component_type_id;
            params.users_key = this.userKey,
            params.pay_group_id = this.PaygroupID
            this.apiModuleService.list('admin/companypay/list', params).subscribe((data) => {
                this.companypayData = data.data.company_pay_component;

                if (this.isDtInitialized) {
                    this.paymaster = false;

                    this.companypayData = (data.data.company_pay_component != undefined) ? data.data.company_pay_component : [];
                    let totalRecords = (this.companypayData != undefined) ? this.companypayData.length : 0;
                    this.totalRecords = totalRecords;
                    this.spinnerService.raiseDataEmitterEvent('off');

                } else {
                    this.companypayData = (data.data.company_pay_component != undefined) ? data.data.company_pay_component : [];
                    let totalRecords = (this.companypayData != undefined) ? this.companypayData.length : 0;
                    this.totalRecords = totalRecords;
                    this.isDtInitialized = true;
                    this.spinnerService.raiseDataEmitterEvent('off');

                }


            }, (err: any) => {
                this.spinnerService.toasterError(err);
            });

        this.statuses = [
            { label: 'active', value: 'active' },
            { label: 'inactive', value: 'inactive' },
        ];
    }
    clear(table: Table) {
        table.clear();
    }

    getSeverity(status: string) {
        switch (status.toLowerCase()) {
            case 'inactive':
                return 'danger';

            case 'active':
                return 'success';
        }
    }

    // Reset companypaycomponent
    public resetform() {

    }

    public importreset() {
        this.form.reset();
        this.paymaster = false;
    }

    public getDropDownComponent() {
        const params: any = {};
        params.client_id = this.ClientID;
        params.country_id = this.countryfillter;
        this.apiModuleService.list('admin/paycomponent/dropdowncomponent', params).subscribe((data) => {

            this.componentDropDown = data.data.pay_component[0];
            this.frequencyDropDown = data.data.pay_component[1];
            this.import_component_type_id = this.componentDropDown[0].component_type_id;
            this.configValueDropDown = data.data.pay_component[2];
            let newElement = { 
                component_type:'All',
                component_type_id: 0,  // Example value
                country_id:1
              };
              this.componentDropDown.unshift(newElement);

              // Set the default value to 'All'
              this.import_component_type_id = 0;
      
            this.getCompanypay();
        });
    }

    // Edit companypaycomponent
    public onEditcompanyPay(companypayId: any, countryId: any) {
        const params: any = {};
        params.client_id = this.ClientID;
        params.company_id = this.CompanyID;
        params.country_id = countryId;
        this.apiModuleService.list('admin/companypay/dropdown', params).subscribe((data) => {

             this.componentFormDropDown = data.data.pay_component[0];
            this.frequencyDropDown = data.data.pay_component[1];
            this.configValueDropDown = data.data.pay_component[2];
            const params: any = {};
            params.component_id = companypayId;
            params.client_id = this.ClientID;
            params.company_id = this.CompanyID;
            params.users_key = this.userKey,
                params.pay_group_id = this.PaygroupID,

                this.apiModuleService.edit(params, 'admin/companypay/edit').subscribe((data) => {
                    let companypayDetail = data.data.company_pay_component;
                    this.component_id = companypayDetail.component_id;
                    // this.country_id=companypayDetail.country_id;
                    this.component_type_id = companypayDetail.component_type_id;
                    this.component_key = companypayDetail.component_key;
                    this.component_name = companypayDetail.component_name;
                    this.pay_frequency_id = companypayDetail.pay_frequency_id;
                    this.component_desc = companypayDetail.component_desc;
                    this.default_values = companypayDetail.default_values;
                    const length = this.configValueDropDown.length;
                    for (let i = 0; i < length; i++) {
                        let key_name = this.configValueDropDown[i].ud_field_key;
                        this.configValueDropDown[i]['fields'] = companypayDetail.config_values[key_name] != undefined && companypayDetail.config_values[key_name] != null ? companypayDetail.config_values[key_name] : null;

                    }
                }, (err) => {
                    this.spinnerService.toasterError(err);

                });

        });

    }

    // edit companypaycomponent
    public onEditChanges(editcompanypayComponent: NgForm) {
        const configvalue = {};

        for (let i = 0; i < this.configValueDropDown.length; i++) {
            configvalue[this.configValueDropDown[i].ud_field_key] = (editcompanypayComponent.value[i][this.configValueDropDown[i].ud_field_key] != undefined) ? editcompanypayComponent.value[i][this.configValueDropDown[i].ud_field_key] : null;
        }
        const params: any = {};
        params.component_id = this.component_id;
        params.client_id = this.ClientID;
        params.company_id = this.CompanyID;
        params.country_id = this.country_id;
        params.users_key = this.userKey,
            params.pay_group_id = this.PaygroupID,
            params.component_type_id = editcompanypayComponent.value.component_type_id;
        params.component_key = this.component_key;
        // params.component_shot = editpayComponent.value.component_shot;
        params.component_name = editcompanypayComponent.value.component_name
        // params.component_display_name = editpayComponent.value.component_display_name;
        params.frequency_id = editcompanypayComponent.value.pay_frequency_id;
        // params.is_mandatory = editpayComponent.value.is_mandatory;
        params.component_desc = editcompanypayComponent.value.component_desc;
        params.default_values = editcompanypayComponent.value.default_values;
        params.config_values = configvalue;


        this.apiModuleService.edit(params, 'admin/companypay/update').subscribe((data) => {
            this.toastrService.success('CompanyPaycomponent Edited Sucessfully...!', 'Success');
            this.getCompanypay();
            ($('#edit_companypay') as any).modal('hide');
            editcompanypayComponent.reset();
        }, (err) => {
            if (err.status === 437) {
                this.toastrService.error('CompanyPaycomponent Already Exists...!', 'Failed');
            } else {
                this.spinnerService.toasterError(err);
            }
        });
    }



    callUpdateFunction(){
        this.updateImportFields(()=>{ this.getCompanypay()});
    }
    updateImportFields(callback:any){
        this.payComponentImport.update();
        setTimeout(()=>{
            callback()
        },2000)

    }

    // status change
    changeStatus(status: any, companypayId: any) {

        const params: any = {};
        params.client_id = this.ClientID;
        params.company_id = this.CompanyID;
        params.pay_group_id = this.PaygroupID;
        params.users_key = this.userKey;
        params.component_id = companypayId;
        params.status = status;
        //
        this.apiModuleService.edit(params, 'admin/companypay/status').subscribe((data) => {
            this.getCompanypay();
            ($('#companypaycomponent-status') as any).modal('hide');
            this.toastrService.success('Status Changed Sucessfully...!', 'Success');
        });
    }

    ngOnDestroy() {

    }


    /* client DropDown codes*/
    public GetuserDropDown() {
        this.apiModuleService.get('DropDown/user').subscribe((data) => {
            this.userDropDown = data.data.users;
            this.userKey = data.data.users[0].key;
            this.GetClientDropDown();
        })
    }

    /* client DropDown codes*/
    public GetClientDropDown() {
        this.apiModuleService.get('DropDown/client').subscribe((data) => {
            this.ClientDropDown = data.data.client;

            if (this.ClientDropDown.length !== 0) {
                setTimeout(() => {
                    this.ClientID =  this.ClientID  !=null  ?   this.ClientID : data.data.client[0].client_id;
                    this.countryfillter = this.ClientDropDown.filter((value: any) => value.client_id === this.ClientID).map((val: any) => val.country_id)[0];
                    if (this.userKey == 'client') {
                        this.CompanyID=null
                        this.PaygroupID=null
                        this.getDropDownComponent();
                    } else {
                        this.GetCompanyDropDown();
                    }
                }, 100)
            }




        })
    }

    /* company DropDown codes */
    public GetCompanyDropDown() {
        if(this.userKey=='client'){
            this.getDropDownComponent();

        }else{

            const params: any = {};
            params.client_id = this.ClientID;
    
            this.apiModuleService.list('DropDown/company', params).subscribe((data) => {
                this.CompanyDropDown = data.data.company != undefined ? data.data.company : [];
                if (this.CompanyDropDown.length !== 0) {
                    setTimeout(() => {
                        this.CompanyID = this.CompanyDropDown[0].company_id;
                        if (this.userKey == 'company') {
                            this.PaygroupID=null
                            this.getDropDownComponent();
                        } else {
                            this.GetpaygroupDropDown();
                        }
    
                    }, 100)
                }
                else {
                    this.CompanyID = null;
                    this.rerender();
                }
    
            }
            )
        }
 
    }

    /* client DropDown codes*/
    public GetpaygroupDropDown() {
        if(this.userKey!='pay_group'){
            this.getDropDownComponent();

        }else{

        const params: any = {};
        params.client_id = this.ClientID;
        params.company_id = this.CompanyID;
        this.apiModuleService.list('DropDown/paygroups', params).subscribe((data) => {
            this.PaygroupDropDown = data.data.paygroup;
            this.PaygroupID = data.data.paygroup[0].pay_group_id;
            this.getDropDownComponent();
        })
    }
    }

    public Paycomponentdropdown(Component_ID:any,Reference_Type:any) {
        this.component_id=Component_ID,
        this.Reference_Type=Reference_Type!=null?Reference_Type:this.userKey
        this.spinnerService.raiseDataEmitterEvent('off');
        var params:any={}
        params.users_key = this.userKey
        this.apiModuleService.list("admin/companyformula/paycomponentdropdown", params).subscribe(
          (data) => {
            this.paycomponentdropdownData = data.data.company_formula;
            this.getCompanypay();
            this.totalRecords = data.data.totalRecords;
            this.spinnerService.raiseDataEmitterEvent('off');
          },
          (err: any) => {
            this.spinnerService.toasterError(err);
            this.spinnerService.raiseDataEmitterEvent('off');
          }
        );
      }

      public updateSelectionType() {
        const params: any = {}
        params.component_id = this.component_id;
        params.users_key = this.userKey;
        params.selection_type = this.Reference_Type,
        this.apiModuleService.list('admin/companyformula/updateselectiontype', params).subscribe(
          (data) => {
            this.component_id = null;
            this.getCompanypay();
            ($('#selection_type') as any).modal('hide');
            this.toastrService.success("Formula added successfully!", "Success");
          },
          (err) => {
            this.spinnerService.toasterError(err);
          }
        );
    
      }

    getAcronym(str: any) {
        var matches = str.match(/\b(\w)/g); // ['J','S','O','N']
        matches = matches.slice(0, 2);
        var acronym = matches.join(''); // JSON
        return acronym;
    }
    getBgColors(str: any) {
        var min = 0;
        var max = 15;
        var index = this.findAlphapositions(str, 1);
        return this.nameBgColors[index];
    }
    findAlphapositions(str: any, n: any) {
        var NUM = 31;

        return str[0].charCodeAt(0) & NUM;
    }
    callStatusModel(url_name: any, data: any) {
        this.statusApi = url_name;
        this.statusValues = data;
    }

    
}
