import { Component, OnInit } from '@angular/core';
import { SpinnerService } from 'src/app/snipper/spinner.service';
import { ApiModulesService } from '../../api-modules.service';
import { documentservice } from '../../documents.sevice';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-previous-salary-file-upload',
  templateUrl: './previous-salary-file-upload.component.html',
  styleUrls: ['./previous-salary-file-upload.component.css']
})
export class PreviousSalaryFileUploadComponent implements OnInit {
  ClientDropDown = [];
  CompanyDropDown = [];
  ClientID = localStorage.getItem('client_id');
  CompanyID = localStorage.getItem('company_id');
  filePath: any;
  fileData: any;
  typeDropDown=[{'label':'Payslip','value':'payslip'},
  {'label':'Tax','value':'tax_sheet'}]
  documentType:any='payslip'
  constructor(private spinnerService: SpinnerService, private apiModulesService: ApiModulesService,
              private documentService:documentservice,private toastr:ToastrService,) { }

  ngOnInit() {
    this.spinnerService.raiseDataEmitterEvent('off')
    this.GetClientDropDown();
  }

  /* client DropDown codes*/
  public GetClientDropDown() {
    this.apiModulesService.get('DropDown/client').subscribe((data) => {
      this.ClientDropDown = data.data.client;
      this.ClientID = data.data.client[0].client_id;
      this.GetCompanyDropDown();
    })
  }

  /* company DropDown codes */
  public GetCompanyDropDown() {
    const params: any = {};
    params.client_id = this.ClientID;
    this.apiModulesService.list('DropDown/company', params).subscribe((data) => {
      this.CompanyDropDown = data.data.company != undefined ? data.data.company : [];
      if (this.CompanyDropDown.length !== 0) {
        setTimeout(() => {
          this.CompanyID = this.CompanyDropDown[0].company_id;
        }, 100)
      }
      else {
        this.CompanyID = null;
      }
    })
  }

  // uploadSalaryFile(filedata){
  //     this.spinnerService.raiseDataEmitterEvent('on');
  //     var myFormData = new FormData();
  //     myFormData.append('client_id', this.ClientID);
  //     myFormData.append('company_id', this.CompanyID);
  //     myFormData.append('file_path', filedata.filePath);

  //     this.documentService.post('api/admin/files/upload', myFormData).subscribe((data) => {


  //     }, (err) => {
  //       this.spinnerService.toasterError(err);

  //     });
  //   }
  onFileSelect(event: any): void {
    const file = event.target.files[0];
    if (file) {
        this.filePath = file;
    }
}
    uploadSalaryFile() {
      if (!this.filePath) {
        this.toastr.error('Please select a file before uploading', 'Error');
        return;
    }
      this.spinnerService.raiseDataEmitterEvent('on');
      const myFormData = new FormData();
      myFormData.append('client_id', this.ClientID);
      myFormData.append('company_id', this.CompanyID);
      myFormData.append('document_type', this.documentType);
      myFormData.append('file_path', this.filePath);
      this.documentService.post('api/admin/previous-upload/upload', myFormData).subscribe((data) => {
        this.toastr.success('Previous Salary Upload Sucessfully...!', 'Success');
          this.spinnerService.raiseDataEmitterEvent('off');

        },
        (err) => {
          this.spinnerService.toasterError(err);
        }

      );
      }
    }
