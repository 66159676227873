import { Component, OnInit } from '@angular/core';
import { SpinnerService } from 'src/app/snipper/spinner.service';
import { ApiModulesService } from 'src/app/all-modules/api-modules.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-tax-document',
  templateUrl: './tax-document.component.html',
  styleUrls: ['./tax-document.component.css']
})
export class TaxDocumentComponent implements OnInit{
  clientID = localStorage.getItem('client_id');
  companyID = localStorage.getItem('company_id');
  employeeID = localStorage.getItem('employee_id');
  yearDropDown: any;
  // url: string = '';
  // urlSafe: SafeResourceUrl;
  public sanitizedUrl: SafeResourceUrl;
  constructor(private spinnerService: SpinnerService,
              private apiModulesService:ApiModulesService,
              public sanitizer: DomSanitizer,) { }

  ngOnInit(){
    this.spinnerService.raiseDataEmitterEvent('on');
    this.dropDown();

  }
  dropDown() {
    this.spinnerService.raiseDataEmitterEvent('on');
    const params: any = {};
    params.client_id = this.clientID;
    params.company_id = this.companyID;
    params.employee_id = this.employeeID;
    this.apiModulesService.list('employee-tabs/IND-employee-payroll/payslip/drop-down', params).subscribe((data) => {
      this.yearDropDown = data.data.pay_slip[2] != undefined ? data.data.pay_slip[2] : [];
      this.uploadViewFile();
      this.spinnerService.raiseDataEmitterEvent('off');

    },(err)=>{
      this.spinnerService.toasterError(err);

    })
  }
  // uploadViewFile(filepath){
  //   this.url = filepath != null ? 'data:application/pdf;base64,' + filepath : null;
  //   this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
  // }
  uploadViewFile(){
    const url = 'assets/img/FORM12BB (2).pdf';
    this.sanitizedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
