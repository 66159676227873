import { Component, ViewChild } from '@angular/core';
import {SpinnerService} from '../../../../../snipper/spinner.service';
import { ApplyLoansComponent } from './apply-loans/apply-loans.component';
@Component({
  selector: 'app-loans-advances',
  templateUrl: './loans-advances.component.html',
  styleUrls: ['./loans-advances.component.css']
})
export class LoansAdvancesComponent {
  activeTab="apply_loans";
  @ViewChild(ApplyLoansComponent)applyloansComponent: ApplyLoansComponent;

    constructor( private spinnerService: SpinnerService){}


    ngOnInit()
    {
      this.spinnerService.raiseDataEmitterEvent('off');
     }

     changeTabs(activeTab){

      this.activeTab = activeTab;

     }

}
