import { Component } from '@angular/core';
import { SpinnerService } from 'src/app/snipper/spinner.service';
import {ApiModulesService} from "../../../../../api-modules.service";
@Component({
  selector: 'app-time-manager',
  templateUrl: './time-manager.component.html',
  styleUrls: ['./time-manager.component.css']
})
export class TimeManagerComponent {
    activeTab="employee_shift_mapping";

    constructor( private spinnerService: SpinnerService,private apiModulesService:ApiModulesService){}


    ngOnInit()
    {
        this.spinnerService.raiseDataEmitterEvent('on');
    }

    changeTabs(activeTab){

        this.activeTab = activeTab;

    }
}
