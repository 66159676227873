import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {AllModulesService} from 'src/app/all-modules/all-modules.service';
import {ApiModulesService} from 'src/app/all-modules/api-modules.service';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import { Table } from 'primeng/table';
import{SpinnerService} from '../../../snipper/spinner.service';
@Component({
    selector: 'app-employeefamily',
    templateUrl: './employeefamily.component.html',
    styleUrls: ['./employeefamily.component.css']
})
export class EmployeefamilyComponent implements OnInit, AfterViewInit,OnDestroy {
    public familyData = [];
    private user_type=localStorage.getItem('user_type');
    public listHeaders = [];
    public rows = [];
    public srch = [];
    family_status: any;
    searchKeyword: string = '';
    family_employee: any;
    showFamilyDetails:boolean=true;
    public dropdownname = [];
    public dropdownGender = [];
    public dropdownRelation = [];
    public dropdownNationalityId = [];
    public dropdownNationalityType = [];
    public physicallyDropdown = [];
    public contactStatusDropDown = [];
    public ShowForm: any = false;
    public show: boolean = true;
    public addFamilyForm: FormGroup;
    showPhysicalChallange= true;
    tabToGrid=false;
     @Output() parenFun: EventEmitter<any> = new EventEmitter();
    emailRegex ='^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$';
    mobileRegex = '^((\\+91-?)|0)?[0-9]{10,13}$';
    employeeName='^[a-zA-Z][a-zA-Z\\s]*$';
    field = true;
    percentage_value:any;
    statusApi:any;
    statusValues:any;
    physicalvalue:any='No';
    statuses=[];
    loading: boolean = false;
    @Input() authEmployeeData: any = {};
    @Input() pageName: any = {};
    nameBgColors: any = ['bg-blue-dim', 'bg-blue-dim', 'bg-azure-dim', 'bg-indigo-dim', 'bg-info-dim', 'bg-purple-dim', 'bg-pink-dim', 'bg-orange-dim', 'bg-teal-dim', 'bg-primary-dim', 'bg-success-dim', 'bg-warning-dim', 'bg-danger-dim', 'bg-secondary-dim', 'bg-dark-dim', 'bg-gray-dim', 'bg-blue-dim', 'bg-azure-dim', 'bg-indigo-dim', 'bg-info-dim', 'bg-purple-dim', 'bg-pink-dim', 'bg-orange-dim', 'bg-teal-dim', 'bg-primary-dim', 'bg-success-dim', 'bg-warning-dim', 'bg-danger-dim', 'bg-secondary-dim', 'bg-dark-dim', 'bg-gray-dim'];
    nameBorderColors: any = ['bg-outline-primary', 'bg-outline-success', 'bg-outline-info', 'bg-outline-gray', 'bg-outline-warning', 'bg-outline-dark', 'bg-outline-light', 'bg-outline-secondary', 'bg-outline-danger'];

    constructor(private apiModulesService: ApiModulesService,
               private toastr: ToastrService,
               private formBuilder: FormBuilder,
               private spinnerService: SpinnerService) {}

    ngOnInit(): void {

       this.spinnerService.raiseDataEmitterEvent('on');
        this.ShowForm = false;
        this.getFamily();
        this.getDropDown();
        // Add clients form
        this.statuses = [
          { label: 'Active', value: 'active' },
          { label: 'Inactive', value: 'inactive' },
      ];
        this.addFamilyForm = this.formBuilder.group({

            relation_type_id: ['', [Validators.required]],
            first_name: ['', [Validators.required , Validators.pattern(this.employeeName)]],
            sur_name: ['', [Validators.pattern(this.employeeName)]],
            email: ['', [ Validators.pattern(this.emailRegex)]],
            mobile: ['', [ Validators.pattern(this.mobileRegex)]],
            occupation: ['', []],
            is_physically_challenged: ['', [Validators.required]],
            physically_challenged_percentage: ['', []],
            dob: ['', []],
            gender: ['', [Validators.required]],
            contact_status: ['', [Validators.required]],
            nationality_id: ['', []],
            nationality_type: ['', []],
            employee_family_id: ['', []]
        });
    };
    ngAfterViewInit(): void {

    }
    public hide() {
        this.show = !this.show
    }
    public percentageChange(value) {
        this.addFamilyForm.patchValue({physically_challenged_percentage:null}) ;

        if (this.addFamilyForm.get('is_physically_challenged').value == 'Yes') {
            this.field = false;
        } else {
            this.field = true;
        }
    }


    onPhoneNumberInput(event: Event) {
        const inputElement = event.target as HTMLInputElement;
        const phoneNumber = inputElement.value.replace(/\D/g, '');
        const truncatedPhoneNumber = phoneNumber.slice(0, 10);
        inputElement.value = truncatedPhoneNumber;
        this.addFamilyForm.get('mobile').setValue(truncatedPhoneNumber);
    }
    public getFamily() {
      this.spinnerService.raiseDataEmitterEvent('on');
        const params: any = {};
                params.client_id = this.authEmployeeData.client_id;
            params.company_id = this.authEmployeeData.company_id;
            params.employee_id = this.authEmployeeData.employee_id;
            this.apiModulesService.list('admin/employeefamily/list', params).subscribe((data) => {
                this.ShowForm = false;
               this.familyData = (data.data.employee_family != undefined) ? data.data.employee_family : [];
               this.spinnerService.raiseDataEmitterEvent('off');
                 },(err)=>{
                    this.familyData=[];
                    this.spinnerService.toasterError(err);
                });
    }

    // Add new employee family
    public onAddFamily() {
        if (!this.addFamilyForm.valid) {
            this.addFamilyForm.markAllAsTouched();
            return;

        }

        const params: any = {};
        params.client_id = this.authEmployeeData.client_id;
        params.company_id = this.authEmployeeData.company_id;
        params.employee_id = this.authEmployeeData.employee_id;
        params.relation_type= this.addFamilyForm.get('relation_type_id').value;
        params.first_name = this.addFamilyForm.get('first_name').value;
        params.sur_name = this.addFamilyForm.get('sur_name').value;
        params.email = this.addFamilyForm.get('email').value;
        params.mobile = this.addFamilyForm.get('mobile').value;
        params.occupation = this.addFamilyForm.get('occupation').value;
        params.physically_challenged_percentage = this.addFamilyForm.get('physically_challenged_percentage').value;
        params.is_physically_challenged = this.addFamilyForm.get('is_physically_challenged').value;
        params.dob = this.addFamilyForm.get('dob').value;
        params.gender = this.addFamilyForm.get('gender').value;
        params.nationality_id = this.addFamilyForm.get('nationality_id').value;
        params.nationality_type = this.addFamilyForm.get('nationality_type').value
        params.employee_family_id = this.addFamilyForm.get('employee_family_id').value;
        params.contact_status = this.addFamilyForm.get('contact_status').value;
        this.apiModulesService.add(params, 'admin/employeefamily/add').subscribe((data) => {
            this.getFamily();
            ($('#add_employeefamily') as any).modal('hide');
            this.toastr.success('employeefamily added sucessfully...!', 'Success');
        }, (err)=>{
          this.familyData=[];
          this.spinnerService.toasterError(err);
      });
    }
    parentFun(){

        this.parenFun.emit(this.tabToGrid)
    }
    public showChallange(value) {
        if (value == 'Yes') {
            this.showPhysicalChallange = false;
        }
        else {
            this.showPhysicalChallange = true;
        }

    }

    public editValue(
        familyDetails: any) {
        this.addFamilyForm.setValue({
            relation_type_id: familyDetails.relation_type,
            first_name: familyDetails.first_name,
            sur_name: familyDetails.sur_name,
            email: familyDetails.email,
            mobile: familyDetails.mobile,
            occupation: familyDetails.occupation,
            is_physically_challenged: familyDetails.is_physically_challenged,
            physically_challenged_percentage: familyDetails.physically_challenged_percentage,
            dob: familyDetails.dob,
            gender: familyDetails.gender,
            nationality_id: familyDetails.nationality_id,
            nationality_type: familyDetails.nationality_type,
            contact_status: familyDetails.contact_status,
            employee_family_id: familyDetails.employee_family_id,
        });
        this.ShowForm = true;
    };


    showAddForm() {
        this.ShowForm = true;
        this.resetForm();
    }

    showList() {
        this.ShowForm = false;
    }

    // Reset form
    public resetForm() {
        this.addFamilyForm.reset();
        setTimeout( function(){
           this.physicalvalue= 'No';
        },100)

    }

    changeStatus(status: any, familyId: any) {
        var params: any = {};
        params.client_id = this.authEmployeeData.client_id;
        params.company_id = this.authEmployeeData.company_id;
        params.employee_id = this.authEmployeeData.employee_id;
        params.employee_family_id = familyId;
        this.apiModulesService.edit(params, 'admin/employeefamily/' + status).subscribe((data) => {
            this.getFamily();
            ($('#employeefamily-status') as any).modal('hide');
            this.toastr.success('employeefamily updated sucessfully...!', 'Success');
        }, (err) => {
          this.spinnerService.toasterError(err);
          }
      );


    }

    changeFamilyStatus(status: any, EmployeeFamilyId: any) {
        var params: any = {};
        params.client_id = this.authEmployeeData.client_id;
        params.company_id = this.authEmployeeData.company_id;
        params.employee_id = this.authEmployeeData.employee_id;
        params.employee_family_id = EmployeeFamilyId;
        this.apiModulesService.edit(params, 'admin/employeefamily/' + status).subscribe((data) => {
            this.getFamily();
            this.toastr.success('employeefamily updated sucessfully...!', 'Success');
        },(err) => {
          this.spinnerService.toasterError(err);
          }
      );

    }

    getDropDown() {

        const params: any = {};
        params.client_id = this.authEmployeeData.client_id;
        params.company_id = this.authEmployeeData.company_id;
        params.employee_id = this.authEmployeeData.employee_id;
        this.apiModulesService.list('admin/employeefamily/dropdown', params).subscribe((data) => {
            this.dropdownname = data.data.employee_family;
            this.dropdownGender = data.data.employee_family[0] ? data.data.employee_family[0]: [];
            this.dropdownRelation = data.data.employee_family[1] ? data.data.employee_family[1] :[];
            this.dropdownNationalityId = data.data.employee_family[2] ? data.data.employee_family[2] :[];
            this.dropdownNationalityType = data.data.employee_family[3] ? data.data.employee_family[3] :[];
            this.physicallyDropdown = data.data.employee_family[4] ? data.data.employee_family[4]:[] ;
            this.contactStatusDropDown = data.data.employee_family[5] ? data.data.employee_family[5]:[] ;
        });

    }
    getAcronym(str:any){
        var matches = str.match(/\b(\w)/g); // ['J','S','O','N']
        matches= matches.slice(0,2);
        var acronym = matches.join(''); // JSON
        return acronym;
    }
    getBgColors(str:any){
        var min = 0;
        var max = 15;
        var index = this.findAlphapositions(str,1);
        return this.nameBgColors[index];
    }
    findAlphapositions(str:any, n:any)
    {
        var NUM = 31;

        return str[0].charCodeAt(0) & NUM;
    }
    ngOnDestroy(): void {
        // Do not forget to unsubscribe the event
    }

  clear(table: Table) {
    table.clear();
}

getSeverity(status: string) {
    switch (status.toLowerCase()) {
        case 'inactive':
            return 'danger';

        case 'active':
            return 'success';
    }
}

callStatusModel(url_name:any,data:any,actionName:string){
    this.statusApi=url_name;
    this.statusValues=data;
}
}
