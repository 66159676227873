import { Component, OnInit, ViewChild,AfterViewInit,OnDestroy} from '@angular/core';
import { ApiModulesService } from 'src/app/all-modules/api-modules.service';
import { AllModulesService } from 'src/app/all-modules/all-modules.service';

import { Subject } from "rxjs";
import { ToastrService } from "ngx-toastr";
import {FormGroup, FormBuilder, Validators, NgForm} from "@angular/forms";
import { SpinnerService } from 'src/app/snipper/spinner.service';
import { Table } from 'primeng/table';
@Component({
  selector: 'app-companydesignation-main',
  templateUrl: './companydesignation-main.component.html',
  styleUrls: ['./companydesignation-main.component.css']
})
export class CompanyDesignationMainComponent implements OnInit,AfterViewInit,OnDestroy{


  public designationData = [];
  ClientID: any;
  CompanyID: any;
  public rows = [];
  public srch = [];
  searchKeyword: string = '';
  public countryname = [];
  public clientname = [];
  ClientDropDown = [];
  CompanyDropDown = [];
  public companyname = [];
  public dropdownname = [];
  listHeaders = [];
  loading=false;
  isDtInitialized = false;
  public totalRecords: any = 0;


  public addDesignationForm: FormGroup;
  public editDesignationForm: FormGroup;
  nameBgColors:any = ["bg-blue-dim","bg-blue-dim","bg-azure-dim","bg-indigo-dim","bg-info-dim","bg-purple-dim","bg-pink-dim","bg-orange-dim","bg-teal-dim","bg-primary-dim","bg-success-dim","bg-warning-dim","bg-danger-dim","bg-secondary-dim","bg-dark-dim","bg-gray-dim","bg-blue-dim","bg-azure-dim","bg-indigo-dim","bg-info-dim","bg-purple-dim","bg-pink-dim","bg-orange-dim","bg-teal-dim","bg-primary-dim","bg-success-dim","bg-warning-dim","bg-danger-dim","bg-secondary-dim","bg-dark-dim","bg-gray-dim"];
  nameBorderColors:any = ["bg-outline-primary","bg-outline-success","bg-outline-info","bg-outline-gray","bg-outline-warning","bg-outline-dark","bg-outline-light","bg-outline-secondary","bg-outline-danger"];
  statuses: { label: string; value: string; }[];
  statusApi: any;
  statusValues: any;


  constructor(private apiModulesService: ApiModulesService, private AllModulesService: AllModulesService, private toastr: ToastrService, private formBuilder: FormBuilder,private spinnerService: SpinnerService) { }

  ngOnInit(): void {
      this.spinnerService.raiseDataEmitterEvent('on');

    this.GetClientDropDown();

    this.addDesignationForm = this.formBuilder.group({
      designation_code: ["", [Validators.required]],
      designation_name: ["", [Validators.required]],
      client_id: ["", []],
      company_id: ["", []],
      company_department_id: ["", [Validators.required]],
    });
    this.editDesignationForm = this.formBuilder.group({
      designation_code: ["", [Validators.required]],
      designation_name: ["", [Validators.required]],
      client_id: ["", [Validators.required]],
      company_id: ["", [Validators.required]],
      designation_id: ["", []],
      company_department_id: ["", [Validators.required]],
    });
    this.statuses = [
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ];
  }
  ngAfterViewInit(): void {

  }
  //Reset form
  public resetForm() {
    this.addDesignationForm.reset();
  }

  //get data
  public getDocument() {
    this.spinnerService.raiseDataEmitterEvent('on');
    var params:any={};
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;

          this.apiModulesService.list("admin/companydesignation/list", params).subscribe((data) => {

              this.designationData = (data.data.company_designation != undefined) ? data.data.company_designation : [];
              this.loading = false;
              var totalRecords = (this.designationData[0] != undefined) ? this.designationData[0].total_records : 0;
              this.totalRecords = totalRecords;
              this.resetForm();
              setTimeout(() => {
                this.spinnerService.raiseDataEmitterEvent('off');
            }, 100);
          }, (err) => {
            this.designationData = [];
            this.spinnerService.raiseDataEmitterEvent('off');
            // this.toastr.error('Something went wrong!', 'Try Again');
              // this.rerender();
          });
  }
  clear(table: Table) {
    table.clear();
  }

  getSeverity(status: string) {
    switch (status.toLowerCase()) {
      case 'inactive':
        return 'danger';

      case 'active':
        return 'success';
    }
  }
companyChange()  {
  this. getDocument();
  this.getDropDown();
}

  // status change
  changeStatus(status: any, DesignationId: any) {

    var params: any = {};
    params.designation_id = DesignationId;
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    params.status = status;

    this.apiModulesService.edit(params, "admin/companydesignation/status").subscribe(() => {
      this.getDocument();
      ($('#companydesignation-status') as any).modal('hide');
      this.toastr.success("Status changed successfully...!", "Success");
    }, (err) => {
      this.toastr.error("Something went wrong!", "Try Again");
    }
    );
  }

  searchName(val) {
    this.rows.splice(0, this.rows.length);
    let temp = this.srch.filter(function (d) {
      val = val.toLowerCase();
      return d.designation_code.toLowerCase().indexOf(val) !== -1 || d.designation_name.toLowerCase().indexOf(val) !== -1;
    });
    this.rows.push(...temp);
  }

  //add data
  public onAddDesignation() {
    if (this.addDesignationForm.invalid) {

      this.addDesignationForm.markAllAsTouched()
      return
    }
    var params: any = {};

    params.designation_code = this.addDesignationForm.get('designation_code').value;
    params.designation_name = this.addDesignationForm.get('designation_name').value;
    params.company_department_id = this.addDesignationForm.get('company_department_id').value;
    params.client_id = this.ClientID
    params.company_id = this.CompanyID

    this.apiModulesService.add(params, "admin/companydesignation/add",).subscribe((data) => {

      this.toastr.success("Designation added successfully...!", "Success");
      this.getDocument();
      ($("#add_designation") as any).modal("hide");
      this.addDesignationForm.reset();

    }, (err) => {
      if (err.status === 437) {
        this.toastr.error('Designation Already Exists...!', 'Failed');
      } else {
        this.toastr.error('Something Went Wrong Please Try Again...!', 'Error');
      }
    }
    );

  }
  //edit value get
  public onEditDesignation(DesignationId: any) {
    var params: any = {};
    params.designation_id = DesignationId;
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;

    this.apiModulesService.edit(params, "admin/companydesignation/edit").subscribe((data) => {

      let designationDetail = data.data.company_designation;
      this.editDesignationForm.setValue({
        designation_code: designationDetail.designation_code,
        client_id: designationDetail.client_id,
        designation_id: designationDetail.designation_id,
        designation_name: designationDetail.designation_name,
        company_id: designationDetail.company_id,
        company_department_id: designationDetail.department_id,
      });
    });

  }
  //edit update
  public onEditChanges() {
    if (!this.editDesignationForm.valid) {

      this.editDesignationForm.markAllAsTouched();
      return;
    }
    var params: any = {};
    params.designation_code = this.editDesignationForm.get('designation_code').value;
    params.designation_name = this.editDesignationForm.get('designation_name').value;
    params.designation_id = this.editDesignationForm.get('designation_id').value;
    params.client_id = this.editDesignationForm.get('client_id').value;
    params.company_id = this.editDesignationForm.get('company_id').value;
    params.company_department_id = this.editDesignationForm.get('company_department_id').value;

    this.apiModulesService.edit(params, 'admin/companydesignation/update').subscribe((data) => {
      this.toastr.success("Designation upated successfully...!", "Success");
      this.getDocument();
      ($("#edit_designation") as any).modal("hide");
      this.editDesignationForm.reset();

    }, (err) => {
      if (err.status === 437) {
        this.toastr.error('Designation Already Exists...!', 'Failed');
      } else {
        this.toastr.error('Something Went Wrong Please Try Again...!', 'Error');
      }
    });
  }

  //get clientname
  public getClientName() {
    this.apiModulesService.get("commonname/clientname").subscribe((data) => {
      this.clientname = data;
    });
  }
  //get clientname
  public getCompanyName() {
    this.apiModulesService.get("commonname/companyname").subscribe((data) => {
      this.companyname = data;
    });
  }
  ngOnDestroy() {
  }
  getDropDown() {
    const params: any = {};
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;

    this.apiModulesService.list('admin/companydesignation/dropdown', params).subscribe((data) => {

      this.dropdownname = data.data.company_designation;
    });
  }
  rerender() {

  }
  /* client DropDown codes*/
  public GetClientDropDown() {
    this.apiModulesService.get('DropDown/client').subscribe((data) => {
      this.ClientDropDown = data.data.client;

      this.ClientID = data.data.client[0].client_id;
      this.GetCompanyDropDown();
    })
  }
  /* company DropDown codes */
  public GetCompanyDropDown() {
    const params: any = {};
    params.client_id = this.ClientID;

    this.apiModulesService.list('DropDown/company', params).subscribe((data) => {
      this.CompanyDropDown = data.data.company != undefined ? data.data.company : [];
      if (this.CompanyDropDown.length !== 0) {
        setTimeout(() => {
          this.CompanyID = this.CompanyDropDown[0].company_id;
          this.getDropDown();
          this.getDocument();
        }, 100)
      }
      else {
        this.CompanyID = null;
        this.rerender();
      }

    }
    )
  }
  getAcronym(str:any){
    var matches = str.match(/\b(\w)/g); // ['J','S','O','N']
    matches= matches.slice(0,2);
    var acronym = matches.join(''); // JSON
    return acronym;
  }
  getBgColors(str:any){
    var min = 0;
    var max = 15;
    var index = this.findAlphapositions(str,1);
    return this.nameBgColors[index];
  }
  findAlphapositions(str:any, n:any)
  {
    var NUM = 31;

    return str[0].charCodeAt(0) & NUM;
  }
  callStatusModel(url_name:any,data:any){
    this.statusApi=url_name;
    this.statusValues=data;
}
}
