import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import { ApiModulesService } from '../../api-modules.service';
import { ToastrService } from 'ngx-toastr';
import { FormGroup,FormBuilder,Validators} from '@angular/forms';
import { Subscription,Subject, } from 'rxjs';

import { Table } from 'primeng/table';
import {SpinnerService} from "../../../snipper/spinner.service";

@Component({
  selector: 'app-education',
  templateUrl: './education.component.html',
  styleUrls: ['./education.component.css']
})
export class EducationComponent implements OnInit, AfterViewInit, OnDestroy {

  public educationData=[];
  public listHeaders=[];
  public addEducationForm:FormGroup;
  public dropdownname = [];
  public dropdown_education= [];
  statusApi:any;
  statusValues:any;
  searchKeyword: string = '';
  public dropdown_degree= [];
    @Input() authEmployeeData: any;
    @Input() pageName: any = {};

  public ShowForm: any = false;
  statuses=[];
  loading: boolean = false;
    tabToGrid=false;
    private user_type=localStorage.getItem('user_type');
    @Output() parenFun: EventEmitter<any> = new EventEmitter();
    nameBgColors: any = ['bg-blue-dim', 'bg-blue-dim', 'bg-azure-dim', 'bg-indigo-dim', 'bg-info-dim', 'bg-purple-dim', 'bg-pink-dim', 'bg-orange-dim', 'bg-teal-dim', 'bg-primary-dim', 'bg-success-dim', 'bg-warning-dim', 'bg-danger-dim', 'bg-secondary-dim', 'bg-dark-dim', 'bg-gray-dim', 'bg-blue-dim', 'bg-azure-dim', 'bg-indigo-dim', 'bg-info-dim', 'bg-purple-dim', 'bg-pink-dim', 'bg-orange-dim', 'bg-teal-dim', 'bg-primary-dim', 'bg-success-dim', 'bg-warning-dim', 'bg-danger-dim', 'bg-secondary-dim', 'bg-dark-dim', 'bg-gray-dim'];
    nameBorderColors: any = ['bg-outline-primary', 'bg-outline-success', 'bg-outline-info', 'bg-outline-gray', 'bg-outline-warning', 'bg-outline-dark', 'bg-outline-light', 'bg-outline-secondary', 'bg-outline-danger'];
    constructor(private apiModulesService: ApiModulesService,
      private toaster:ToastrService,
      private toastr: ToastrService,
      private formBuilder: FormBuilder,
      private spinnerService: SpinnerService

      ) { }

  ngOnInit(): void {
    this.spinnerService.raiseDataEmitterEvent('on');
    this.getEducation();
    this.getDropDown();
    this.statuses = [
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
  ];
    this.ShowForm = false;
    this.addEducationForm=this.formBuilder.group({
      institute: ['', [Validators.required]],
      employee_id: ['', []],
      client_id: ['', []],
      company_id: ['', []],
      education_id: ['', [Validators.required]],
      degree_id: ['', [Validators.required]],
      passing_year: ['', [Validators.required]],
      percentage: ['', []],
      grade: ['', []],
      employee_education_id: ['', []],
    });
  }

    ngAfterViewInit(): void {

    }

    ngOnDestroy(): void {
        // Do not forget to unsubscribe the event

    }
    // Get all employee family data
    getEducation(){
      this.spinnerService.raiseDataEmitterEvent('on');
        const params: any = {};
      params.client_id= this.authEmployeeData.client_id;
      params.company_id=this.authEmployeeData.company_id;
      params.employee_id=this.authEmployeeData.employee_id;

        this.apiModulesService.list('admin/employee-education/list',params).subscribe((data)=>{


          this.educationData = (data.data.employee_education != undefined) ? data.data.employee_education : [];
      this.spinnerService.raiseDataEmitterEvent('off');
        }, (err) => {
          this.spinnerService.toasterError(err);
            this.educationData = [];

        });
      }
    changeStatus(Status:any,education){
      const params:any={};
      params.employee_id=education.employee_id,
      params.client_id=education.client_id,
      params.employee_education_id=education.employee_education_id,
      params.company_id=education.company_id,

      this.apiModulesService.edit(params,'admin/employee-education/'+Status).subscribe((data)=>{
        this.getEducation();
        ($('#employeeeducation-status') as any).modal('hide');
        this.toaster.success('Status Changed Successfully!','Success')
      },(err)=>{
        this.spinnerService.toasterError(err);
      }
      );
    }
    public onAddEducation() {
      if (!this.addEducationForm.valid) {

        this.addEducationForm.markAllAsTouched();
        return;
      }
      const params:any = {};
      params.client_id= this.authEmployeeData.client_id;
      params.company_id=this.authEmployeeData.company_id;
      params.employee_id=this.authEmployeeData.employee_id;
      params.institute = this.addEducationForm.get('institute').value;
      params.education_id = this.addEducationForm.get('education_id').value;
      params.degree_id = this.addEducationForm.get('degree_id').value;
      params.passing_year = this.addEducationForm.get('passing_year').value;
      params.percentage = this.addEducationForm.get('percentage').value;
      params.grade = this.addEducationForm.get('grade').value;
      params.record_status = 1 ;

      if(this.addEducationForm.get('employee_education_id').value!=''&&this.addEducationForm.get('employee_education_id').value!=null){
        params.employee_education_id=this.addEducationForm.get('employee_education_id').value;
        this.apiModulesService.add(params,'admin/employee-education/update',).subscribe((data) => {
          this.getEducation();
          this.toastr.success('education Update sucessfully...!', 'Success');
        },(err)=>{
          this.spinnerService.toasterError(err);
        }
        );
      }else{
        this.apiModulesService.add(params,'admin/employee-education/add',).subscribe((data) => {
          this.getEducation();
          this.toastr.success('education added sucessfully...!', 'Success');
        },(err)=>{
          this.spinnerService.toasterError(err);
        }
        );
      }
      this.getEducation();
      ($('#add_education')as any).modal('hide');
      this.addEducationForm.reset();
    }
  getDropDown() {
    const params: any = {};
    params.client_id= this.authEmployeeData.client_id;
    params.company_id=this.authEmployeeData.company_id;
    params.employee_id=this.authEmployeeData.employee_id;

    this.apiModulesService.list('admin/employee-education/dropdown', params).subscribe((data) => {

      this.dropdownname = data.data.employee_education;
      this.dropdown_education= this.dropdownname[0] !=undefined ? this.dropdownname[0]:[];
    },(err:any)=>{
      this.spinnerService.toasterError(err);
    });
  }
  getDropDownDegree() {
      if(this.addEducationForm.get('education_id').value!=null && this.addEducationForm.get('education_id').value !=''){
          this.dropdown_degree= this.dropdownname[1].filter((data:any)=>data.education_id === this.addEducationForm.get('education_id').value) ;
      }


  }
    parentFun(){

        this.parenFun.emit(this.tabToGrid)
    }
// Reset form
public resetForm() {
  this.addEducationForm.reset();
}
public update(
  educationDetails: any) {


  this.addEducationForm.setValue({
      employee_education_id: educationDetails.employee_education_id,
    institute: educationDetails.institute,
    education_id: educationDetails.education_id,
    degree_id: educationDetails.degree_id,
    passing_year: educationDetails.passing_year,
    percentage: educationDetails.percentage,
    grade: educationDetails.grade,
    employee_id: educationDetails.employee_id,
    client_id: educationDetails.client_id,
    company_id: educationDetails.company_id


  });
  this.ShowForm = true;
};
showAddForm() {
  this.ShowForm = true;
  this.resetForm();
}
showList() {
  this.ShowForm = false;
}
    getAcronym(str:any){
        let matches = str.match(/\b(\w)/g); // ['J','S','O','N']
        matches= matches.slice(0,2);
        const acronym = matches.join(''); // JSON
        return acronym;
    }
    getBgColors(str:any){
        const min = 0;
        const max = 15;
        const index = this.findAlphapositions(str,1);
        return this.nameBgColors[index];
    }
    findAlphapositions(str:any, n:any)
    {
        const NUM = 31;

        return str[0].charCodeAt(0) & NUM;
    }
    clear(table: Table) {
      table.clear();
  }

  getSeverity(status: string) {
      switch (status.toLowerCase()) {
          case 'inactive':
              return 'danger';

          case 'active':
              return 'success';
      }
  }
  callStatusModel(url_name:any,data:any,actionName:string){
    this.statusApi=url_name;
    this.statusValues=data;
}
}

