import { Component, ViewChild } from '@angular/core';
import { ImportDropdownUploadComponent } from '../import-common/import-dropdown-upload/import-dropdown-upload.component';
import { SpinnerService } from 'src/app/snipper/spinner.service';

@Component({
  selector: 'app-import-employee-migration',
  templateUrl: './import-employee-migration.component.html',
  styleUrls: ['./import-employee-migration.component.css']
})
export class ImportEmployeeMigrationComponent {
  @ViewChild(ImportDropdownUploadComponent) ImportGrid:ImportDropdownUploadComponent ;
   
  ExcelTypeKey = 'import_excel_migation';
  totalRecords:any;
  constructor(
      protected spinnerService: SpinnerService
  ) {
  }

  ngOnInit(): void {      
  }
}
