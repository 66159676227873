import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import{ApiModulesService} from '../../../api-modules.service';
import{AllModulesService} from '../../../all-modules.service';
import { SpinnerService } from 'src/app/snipper/spinner.service';
import { Table } from 'primeng/table';
@Component({
  selector: 'app-userdefinedfield-main',
  templateUrl: './userdefinedfield-main.component.html',
  styleUrls: ['./userdefinedfield-main.component.css']
})
export class UserdefinedfieldMainComponent implements OnInit {



  addUserFieldForm: FormGroup;
  public totalRecords: any = 0;
  dropdown: any;
  CountryDropDown: any;
  ModuleNameDropdown: any;
  isDtInitialized: boolean = false;
  searchKeyword: string = '';
  FieldTypeDropdown: any;
  selectedCountryID: any;
  udOptions: string[] = [];
  public ShowForm: any = false;
  separatorExp: RegExp = / /;  // Allow space as a separator
  fieldList = [];
  public fieldsdata = [];
  YesorNoDropdown: any;
  loading=false;
  nameBgColors:any = ['bg-blue-dim','bg-blue-dim','bg-azure-dim','bg-indigo-dim','bg-info-dim','bg-purple-dim','bg-pink-dim','bg-orange-dim','bg-teal-dim','bg-primary-dim','bg-success-dim','bg-warning-dim','bg-danger-dim','bg-secondary-dim','bg-dark-dim','bg-gray-dim','bg-blue-dim','bg-azure-dim','bg-indigo-dim','bg-info-dim','bg-purple-dim','bg-pink-dim','bg-orange-dim','bg-teal-dim','bg-primary-dim','bg-success-dim','bg-warning-dim','bg-danger-dim','bg-secondary-dim','bg-dark-dim','bg-gray-dim'];
    nameBorderColors:any = ['bg-outline-primary','bg-outline-success','bg-outline-info','bg-outline-gray','bg-outline-warning','bg-outline-dark','bg-outline-light','bg-outline-secondary','bg-outline-danger'];
  statuses: { label: string; value: string; }[];
  statusApi: any;
  statusValues: any;



  constructor(private apiModuleServices: ApiModulesService, private formBuilder: FormBuilder, private toastr: ToastrService,private fb: FormBuilder,private spinnerService: SpinnerService,) {
  }
  ngOnInit(): void {

    this.dropDown();
    const that = this;

    this.addUserFieldForm = this.fb.group({
      ud_field_type: ['options'], // Default value for 'ud_field_type'
      ud_options: [''] // Default value for 'ud_options' with required validator
    });
    this.addUserFieldForm = this.formBuilder.group({

      ud_module_id: ['', [Validators.required]],
      ud_field_key: ['', []],
      ud_field_lable: ['', [Validators.required]],
      ud_field_type: ['', [Validators.required]],
      ud_options: ['', Validators.required],
      ud_field_order: ['', [Validators.required]],
      is_mandatory: ['', [Validators.required]],
      is_shown: ['', [Validators.required]],
      user_defined_field_id: ['', []],
      country_id: ['', []],
    })
    this.statuses = [
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ];
        // Subscribe to changes in the ud_field_type control
        this.addUserFieldForm.get('ud_field_type').valueChanges.subscribe(value => {
          const udOptionsControl = this.addUserFieldForm.get('ud_options');
          if (value === 'options') {
            udOptionsControl.setValidators([Validators.required]);
          } else {
            udOptionsControl.clearValidators();
          }
          udOptionsControl.updateValueAndValidity();
        });

        // Listen to changes in the ud_options control and update the udOptions array
        this.addUserFieldForm.get('ud_options').valueChanges.subscribe(value => {
          if (typeof value === 'string') {
            // Manually split by spaces and filter out empty strings
            this.udOptions = value.split(/\s+/).filter(option => option.trim() !== '');
          } else {
            this.udOptions = value;
          }
        });

  }

  dropDown() {
    var params: any = {};
    this.apiModuleServices.get('admin/userdefinedfield/dropdown').subscribe((data) => {
      this.dropdown = data.data.user_defined;
      this.CountryDropDown = this.dropdown[0]
      this.ModuleNameDropdown = this.dropdown[1]
      this.FieldTypeDropdown = this.dropdown[2]
      this.YesorNoDropdown=this.dropdown[3]
      this.selectedCountryID = this.CountryDropDown[0].country_id;
      this.getList();
      // this.getList('rerender', function () {
      // });
    })
  }

  showAddForm() {
    this.ShowForm = true;
    this.resetForm();
  }
  showList() {
    this.ShowForm = false;
  }

  gridLoadByCountry(){
      this.getList();
  }
  //Reset form
  public resetForm() {
    this.addUserFieldForm.reset();
  }
  public getList() {
    this.spinnerService.raiseDataEmitterEvent('on');
    this.ShowForm = false;
    const params: any = {};
    params.country_id=this.selectedCountryID;
          this.apiModuleServices.list('admin/userdefinedfield/list', params).subscribe((data) => {
              this.fieldsdata = (data.data.user_defined != undefined) ? data.data.user_defined : [];
              this.loading = false;
              var totalRecords = (this.fieldsdata[0] != undefined) ? this.fieldsdata[0].total_records : 0;
              this.totalRecords = totalRecords;
              this.resetForm();
              setTimeout(() => {
                this.spinnerService.raiseDataEmitterEvent('off');
            }, 100);
          }, (err) => {
            this.fieldsdata = [];
            this.spinnerService.raiseDataEmitterEvent('off');
            this.toastr.error('Something went wrong!', 'Try Again');
              // this.rerender();
          });
  }

  clear(table: Table) {
    table.clear();
  }

  getSeverity(status: string) {
    switch (status.toLowerCase()) {
      case 'inactive':
        return 'danger';

      case 'active':
        return 'success';
    }
  }

  changeStatus(Status: any, user_defined_field_id: any) {
    const params: any = {};
    params.user_defined_field_id = user_defined_field_id;
    this.apiModuleServices.edit(params, 'admin/userdefinedfield/' + Status).subscribe((data) => {
      this.getList();
      ($('#userdefinedfield-status') as any).modal('hide');
      this.toastr.success('Status Changed Successfully!', 'Success');
    })
  }
  public onAddField() {
    if (!this.addUserFieldForm.valid) {
      this.addUserFieldForm.markAllAsTouched();
      return;
    }
    var params: any = {};
    params.ud_field_key = this.addUserFieldForm.get('ud_field_key').value;
    params.ud_field_lable = this.addUserFieldForm.get('ud_field_lable').value;
    params.ud_field_type = this.addUserFieldForm.get('ud_field_type').value;
    params.ud_options = this.addUserFieldForm.get('ud_options').value;
    params.ud_field_order = this.addUserFieldForm.get('ud_field_order').value;
    params.is_mandatory = this.addUserFieldForm.get('is_mandatory').value;
    params.is_shown = this.addUserFieldForm.get('is_shown').value;
    params.country_id = this.selectedCountryID;
    params.ud_module_id = this.addUserFieldForm.get('ud_module_id').value;
      params.ud_options=params.ud_options !=null && params.ud_options !='' ?params.ud_options.toString():null;
    if (this.addUserFieldForm.get('user_defined_field_id').value != '' && this.addUserFieldForm.get('user_defined_field_id').value != null) {
      params.user_defined_field_id = this.addUserFieldForm.get('user_defined_field_id').value;
      this.apiModuleServices.add(params, 'admin/userdefinedfield/update',).subscribe((data) => {
        this.getList();
        this.toastr.success('field Update sucessfully...!', 'Success');
      }, (err) => {
        this.toastr.error('Something went wrong!', 'Try Again');
      }
      );
    } else {
      this.apiModuleServices.add(params, 'admin/userdefinedfield/add',).subscribe((data) => {
        this.getList();
        this.toastr.success('field added sucessfully...!', 'Success');
      }, (err) => {
        this.toastr.error('Something went wrong!', 'Try Again');
      }
      );

    }
    this.getList();
    ($('#add_field') as any).modal('hide');

    this.addUserFieldForm.reset();
  }

  public update(fieldDetails: any) {
    this.addUserFieldForm.setValue({
      user_defined_field_id: fieldDetails.user_defined_field_id,
      ud_module_id: fieldDetails.ud_module_id,
      ud_field_lable: fieldDetails.ud_field_lable,
      ud_field_type: fieldDetails.ud_field_type,
      ud_field_key: fieldDetails.ud_field_key,
      ud_options: fieldDetails.ud_options,
      ud_field_order: fieldDetails.ud_field_order,
      is_mandatory: fieldDetails.is_mandatory,
      country_id: fieldDetails.country_id,
      is_shown: fieldDetails.is_shown
    });
    this.ShowForm = true;
  };

  onEditField(user_defined_field_id: any) {

    const params: any = {};
params.user_defined_field_id=user_defined_field_id
    this.apiModuleServices.edit(params, 'admin/userdefinedfield/edit').subscribe((data) => {
      const fieldDetails = (data.data !== undefined) ? data.data.user_defined : [];
      this.addUserFieldForm.setValue({
        user_defined_field_id:user_defined_field_id,
        ud_module_id: fieldDetails.ud_module_id,
        ud_field_lable: fieldDetails.ud_field_lable,
        ud_field_type: fieldDetails.ud_field_type,
        ud_field_key: fieldDetails.ud_field_key,
        ud_options: fieldDetails.ud_options,
        ud_field_order: fieldDetails.ud_field_order,
        is_mandatory: fieldDetails.is_mandatory,
        country_id: fieldDetails.country_id,
        is_shown: fieldDetails.is_shown
      });
      this.ShowForm = true;
    });
  }
  ngAfterViewInit(): void {

}
ngOnDestroy(): void {
  // Do not forget to unsubscribe the event

}
getAcronym(str:any){
  var matches = str.match(/\b(\w)/g); // ['J','S','O','N']
  matches= matches.slice(0,2);
  var acronym = matches.join(''); // JSON
  return acronym;
}
getBgColors(str:any){
  var min = 0;
  var max = 15;
  var index = this.findAlphapositions(str,1);
  return this.nameBgColors[index];
}
findAlphapositions(str:any, n:any)
{
  var NUM = 31;

  return str[0].charCodeAt(0) & NUM;
}
callStatusModel(url_name:any,data:any){
  this.statusApi=url_name;
  this.statusValues=data;
}
}
