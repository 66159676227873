import { Component, OnInit, OnDestroy ,ViewChild,AfterViewInit} from '@angular/core';
import{ApiModulesService} from '../../../api-modules.service';
import{AllModulesService} from '../../../all-modules.service';

import { Subject } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { SpinnerService } from 'src/app/snipper/spinner.service';

@Component({
    selector: 'app-nationality-main',
    templateUrl: './nationality-main.component.html',
    styleUrls: ['./nationality-main.component.css']
})
export class NationalityMainComponent implements OnInit ,OnDestroy, AfterViewInit{




    public url: any = 'nationalitymain';
    public rows = [];
    public search = [];
    isDtInitialized = false;
    public totalRecords: any = 0;
    public nationalityData = [];
    nameRegex=/^[a-zA-Z\s]*$/;


    public addNationalityForm:FormGroup;
    public editNationalityForm:FormGroup;
    public deleteNationalityForm:FormGroup;
    nameBgColors:any = ['bg-blue-dim','bg-blue-dim','bg-azure-dim','bg-indigo-dim','bg-info-dim','bg-purple-dim','bg-pink-dim','bg-orange-dim','bg-teal-dim','bg-primary-dim','bg-success-dim','bg-warning-dim','bg-danger-dim','bg-secondary-dim','bg-dark-dim','bg-gray-dim','bg-blue-dim','bg-azure-dim','bg-indigo-dim','bg-info-dim','bg-purple-dim','bg-pink-dim','bg-orange-dim','bg-teal-dim','bg-primary-dim','bg-success-dim','bg-warning-dim','bg-danger-dim','bg-secondary-dim','bg-dark-dim','bg-gray-dim'];
    nameBorderColors:any = ['bg-outline-primary','bg-outline-success','bg-outline-info','bg-outline-gray','bg-outline-warning','bg-outline-dark','bg-outline-light','bg-outline-secondary','bg-outline-danger'];
​
    constructor(private allModulesService: AllModulesService,
                private apiModuleService: ApiModulesService,
                private toastr: ToastrService,
                private formBuilder: FormBuilder,
                private spinnerService: SpinnerService) { }
​
    ngOnInit(): void {
        this.spinnerService.raiseDataEmitterEvent('off');
        this.getNationality('rerender', function (){});

        //Add
        this.addNationalityForm=this.formBuilder.group({
            country_name: ['', [Validators.required,Validators.pattern(this.nameRegex)]],
            nationality: ['', [Validators.required,Validators.pattern(this.nameRegex)]],
        });
        //edit
        this.editNationalityForm=this.formBuilder.group({

            country_name: ['', [Validators.required,Validators.pattern(this.nameRegex)]],
            nationality: ['', [Validators.required,Validators.pattern(this.nameRegex)]],
            nationality_id: ['', []]
        });
        this.deleteNationalityForm=this.formBuilder.group({
            nationality_id: ['', []],
        });
    }
    ngAfterViewInit(): void {

    }
    rerender(): void {

    }

    //Get all Nationality data
    public getNationality(callFrom: any, callback: any){
        var params:any = {};
        this.apiModuleService.list('admin/nationality/list',params).subscribe((data) => {



                    this.nationalityData = (data.data.nationality != undefined) ? data.data.nationality : [];
                    var totalRecords = this.nationalityData.length;
                    this.totalRecords = totalRecords;

            setTimeout(function () {
                var firstcolwidth = $('.table.custom-table>tbody>tr>td.fixed:first-child').width();
                var lastcolwidth = $('.table.custom-table>thead>tr>th:last-child').width();
                $('.table.custom-table>thead>tr>th.fixed:nth-child(2)').css('left', firstcolwidth + 20);
                $('.table.custom-table>tbody>tr>td.fixed:nth-child(2)').css('left', firstcolwidth + 20);
                $('.table.custom-table>thead>tr>th:nth-last-child(2)').css('right', lastcolwidth + 20);
                $('.table.custom-table>tbody>tr>td:nth-last-child(2)').css('right', lastcolwidth + 20);
            }, 2000);
            callback({
                recordsTotal: totalRecords,
                recordsFiltered: totalRecords,
                data: []
            });
    //  this.industriesData = gridData;

        });
    }

    //reset form
    public resetform() {
        this.addNationalityForm.reset();
      }

    //add nationality
    public onAddnationality() {
        if (this.addNationalityForm.invalid) {
            this.addNationalityForm.markAllAsTouched();
            return;
        }
        var params:any = {};
        params.country_name = this.addNationalityForm.get('country_name').value;
        params.nationality = this.addNationalityForm.get('nationality').value;
        this.apiModuleService.add(params,'admin/nationality/add',).subscribe((data) => {


            //this.addNationalityForm.reset();
                this.toastr.success('Nationality Added Sucessfully...!', 'Success');
                ($('#add_nationality')as any).modal('hide');
                this.getNationality('rerender', function (){});
                this.addNationalityForm.reset();

        },(err)=>{
            if (err.status === 437) {
                this.toastr.error('Nationality Already Exists...!', 'Failed');
            } else {
                this.toastr.error('Something Went Wrong Please Try Again...!', 'Error');
            }
        });
    }
​
    //Edit Nationality
    public onEditNationality(nationalityId: any) {
        var params:any = {};
        params.nationality_id = nationalityId;
        this.apiModuleService.edit(params,'admin/nationality/edit').subscribe((data) => {
            let nationalityDetail = data.data.nationality;
            this.editNationalityForm.setValue({
                nationality_id:nationalityDetail.nationality_id,
                country_name:nationalityDetail.country_name,
                nationality:nationalityDetail.nationality,
            });
            //delete natinality
            this.deleteNationalityForm.setValue({
                nationality_id:nationalityDetail.nationality_id,
            });
        });
    }
    //edit nationality
    public onEditChanges(){
        var params:any={};
        params.nationality_id=this.editNationalityForm.get('nationality_id').value;
        params.country_name=this.editNationalityForm.get('country_name').value;
        params.nationality=this.editNationalityForm.get('nationality').value;
        this.apiModuleService.edit(params,'admin/nationality/update').subscribe((data)=>{

            this.toastr.success('Nationality updated successfully...!', 'Success');
            this.getNationality('rerender', function (){
            });
            ($('#edit_nationality')as any).modal('hide');
         },(err)=>{
            if (err.status === 437) {
                this.toastr.error('Nationality Already Exists...!', 'Failed');
            } else {
                this.toastr.error('Something Went Wrong Please Try Again...!', 'Error');
            }
        });
    }

    //Delete Nationality
    onDelete() {
        var params:any = {};
        params.nationality_id =this.deleteNationalityForm.get('nationality_id').value;
        this.apiModuleService.edit(params,'admin/nationality/delete').subscribe((data) => {
            ($('#delete_nationality')as any).modal('hide');
            this.rerender();
            this.getNationality('rerender', function (){});
            this.toastr.success('Nationality Deleted Sucessfully...!', 'Success');
        });
    }

    ngOnDestroy(): void {
    }
    getAcronym(str:any){
        var matches = str.match(/\b(\w)/g); // ['J','S','O','N']
        matches= matches.slice(0,2);
        var acronym = matches.join(''); // JSON
        return acronym;
    }
    getBgColors(str:any){
        var min = 0;
        var max = 15;
        var index = this.findAlphapositions(str,1);
        return this.nameBgColors[index];
    }
    findAlphapositions(str:any, n:any)
    {
        var NUM = 31;

        return str[0].charCodeAt(0) & NUM;
    }
}
