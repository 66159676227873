import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { NavigationEnd, Router } from '@angular/router';
import { HeaderService } from "./header.service";
import { AuthService } from "../services/auth.service";
import { ApiModulesService } from "../all-modules/api-modules.service";
import { TranslateService } from "@ngx-translate/core";
import { SideBarService } from "../side-bar.service";
import { SpinnerService } from "../snipper/spinner.service";
import { Table } from "primeng/table";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent implements OnInit, AfterViewInit {
  private freshwork_domain=environment.fresh_work_api;
  ;

  jsonData: any = {
    notification: [],
    message: [],
  };
  apiClick = false;
  @Input() notify: boolean = false;
  @Output() callSideBarApi: EventEmitter<any> = new EventEmitter();
  @Output() showNotification: EventEmitter<any> = new EventEmitter();
  notifications: any;
  messagesData: any;
  public loginData: any = [];
  public rows = [];
  public srch = [];
  public base = '';
  public page = '';
  public miniSidebar: boolean = false;
  miniSideBarChange = false;
  public baricon: boolean = false;
  public showMenu: boolean = false;
  languageFlag = [
    { lan: 'en', img: 'assets/img/flags/us.png', name: 'English' },
    { lan: 'ta', img: 'assets/img/flags/in.png', name: 'தமிழ்' }
  ]
  ClientID: any = localStorage.getItem('client_id');
  email = localStorage.getItem('email');
  employeeName = 'Arun Kumar';
  employeeId = 'Demo000135';
  // cities=[
  //   'en','ta'
  // ]
  selectedLang = localStorage.getItem('languages') != undefined ? localStorage.getItem('languages') : 'en';
  awsLoginImages: any;
  public gridListView: boolean = true;
  cardBgColors = [
    'rgba(253,126,20,.25)', 'rgba(241,0,117,.25)', 'rgba(0,204,204,.25)', 'rgb(18,182,89,.25)', 'rgba(91,71,251,.2)'
  ]
  cardBorderColors = [
    '25px 19px 27px -20px rgba(253,126,20,.25)', '25px 19px 27px -20px rgba(0,204,204,.25)', '25px 19px 27px -20px rgba(91,71,251,.2)', '25px 19px 27px -20px #fbfcd7', '25px 19px 27px -20px #d9b8f5'
  ]
  nameBgColors: any = ['bg-blue-dim', 'bg-blue-dim', 'bg-azure-dim', 'bg-indigo-dim', 'bg-info-dim', 'bg-purple-dim', 'bg-pink-dim', 'bg-orange-dim', 'bg-teal-dim', 'bg-primary-dim', 'bg-success-dim', 'bg-warning-dim', 'bg-danger-dim', 'bg-secondary-dim', 'bg-dark-dim', 'bg-gray-dim', 'bg-blue-dim', 'bg-azure-dim', 'bg-indigo-dim', 'bg-info-dim', 'bg-purple-dim', 'bg-pink-dim', 'bg-orange-dim', 'bg-teal-dim', 'bg-primary-dim', 'bg-success-dim', 'bg-warning-dim', 'bg-danger-dim', 'bg-secondary-dim', 'bg-dark-dim', 'bg-gray-dim'];
  nameBorderColors: any = ['bg-outline-primary', 'bg-outline-success', 'bg-outline-info', 'bg-outline-gray', 'bg-outline-warning', 'bg-outline-dark', 'bg-outline-light', 'bg-outline-secondary', 'bg-outline-danger'];
  notificationData = [];
  constructor(private sideBar: SideBarService, private apiModulesService: ApiModulesService, private headerService: HeaderService, private router: Router, private translate: TranslateService, private _authService: AuthService, private spinnerService: SpinnerService) {
    // this.translate.addLangs(['en','ta']);
    this.translate.addLangs(['en']);
    this.sideBar.toggleSideBar.subscribe((res: string) => {
      if (res === 'true') {
        this.miniSidebar = true;
      } else {
        this.miniSidebar = false;
      }
    });
    router.events.subscribe((event: object) => {
      if (event instanceof NavigationEnd) {
        const splitVal = event.url.split('/');
        this.base = splitVal[1];
        this.page = splitVal[2];
        if (this.base === 'components' || this.page === 'tasks' || this.page === 'email') {
          this.baricon = false
          localStorage.setItem('baricon', 'false');
        }
        else {
          this.baricon = true
          localStorage.setItem('baricon', 'true');
        }
      }
    });
    if (localStorage.getItem('baricon') == 'true') {
      this.baricon = true;
    }
    else {
      this.baricon = false
    }
  }

  ngOnInit() {
    // this.getDatas("notification");
    // this.getDatas("message");
    this.notificationData = [
      { date: '17-Aug-2024', role: 'Employee', status: 'Not compulsory', action_and_view: '', section: 'Payroll', activity: 'Submit Reimbursements', remarks: 'Reimbursements if any have to be submitted before 15-August-24', direct_link: 'employee/ess/IND/reimbursement' },
      { date: '30-Jul-2024', role: 'Employee', status: 'pending', action_and_view: 'Same Regularisation Box', section: 'Time', activity: 'Yesterday Time Regularisation to be completed', remarks: '', direct_link: 'employee/ess/IND/time' },
      { date: '30-Jul-2024', role: 'Manager', status: 'pending', action_and_view: 'Same Approval / Rejection Box', section: 'Absence', activity: 'Sathish has requested for Leave on 17/Aug/24', remarks: '', direct_link: 'employee/ess/IND/approval/leave' },

    ];
      window.FreshworksWidget('show');

    this.spinnerService.raiseDataEmitterEvent('on');
    this.getLoginType();
    this.notifications = [
      {
        message: "Patient appointment booking",
        author: "John Doe",
        function: "added new task",
        time: "4 mins ago",
      },
      {
        message: "Patient appointment booking",
        author: "John Doe",
        function: "added new task",
        time: "1 hour ago",
      },
      {
        message: "Patient appointment booking",
        author: "John Doe",
        function: "added new task",
        time: "4 mins ago",
      },
      {
        message: "Patient appointment booking",
        author: "John Doe",
        function: "added new task",
        time: "1 hour ago",
      },
      {
        message: "Patient appointment booking",
        author: "John Doe",
        function: "added new task",
        time: "4 mins ago",
      },
      {
        message: "Patient appointment booking",
        author: "John Doe",
        function: "added new task",
        time: "1 hour ago",
      },
    ];

    this.messagesData = [
      {
        message: "Lorem ipsum dolor sit amet, consectetur adipiscing",
        author: "Mike Litorus",
        time: "4 mins ago",
      },
      {
        message: "Lorem ipsum dolor sit amet, consectetur adipiscing",
        author: "Mike Litorus",
        time: "1 hour ago",
      },
      {
        message: "Lorem ipsum dolor sit amet, consectetur adipiscing",
        author: "Mike Litorus",
        time: "4 mins ago",
      },
      {
        message: "Lorem ipsum dolor sit amet, consectetur adipiscing",
        author: "Mike Litorus",
        time: "1 hour ago",
      },
      {
        message: "Lorem ipsum dolor sit amet, consectetur adipiscing",
        author: "Mike Litorus",
        time: "4 mins ago",
      },
      {
        message: "Lorem ipsum dolor sit amet, consectetur adipiscing",
        author: "Mike Litorus",
        time: "1 hour ago",
      },
    ];
  }

  languages() {
    this.translate.use(this.selectedLang);
    localStorage.setItem('language', this.selectedLang);
  }
  getDatas(section) {

    this.headerService.getDataFromJson(section).subscribe((data) => {
      this.jsonData[section] = data;
    });
  }
  public getLoginType() {
    this.apiModulesService.get("details").subscribe((data) => {
      this.loginData = data.data;
      this.awsLoginImages = data.data.image != null ? 'data:image/png;base64,' + data.data.image : null;
      localStorage.setItem('color', data.data.bg_color != undefined && data.data.bg_color != null ? data.data.bg_color : '#3e75b3');
      document.getElementById('header-color').style.backgroundColor = localStorage.getItem('color');
      document.getElementById('logout-colors').style.backgroundColor = localStorage.getItem('color');
      window.FreshworksWidget('identify', 'ticketForm', {
        name: this.loginData?.name,
        email: this.loginData?.email,
      }, {
        formId: 153000000546 // Ticket Form ID
      });

    }, (err: any) => {
      //  alert('something went wrong please try again');
      // window.location.reload()

    })
  }
  clearData(section) {
    this.jsonData[section] = [];
  }
  onSubmit() {
    this.router.navigate(["/pages/search"]);
  }

  Logout() {
    let params: any = {}
    params.token = localStorage.getItem('token');
    this.apiModulesService.list('logout', params).subscribe();
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('is_first_login');
    window.localStorage.clear();

    ($('#logout_pop_up') as any).modal('hide');
    localStorage.setItem('languages', this.selectedLang);
    this.router.navigateByUrl("/login");
    this.spinnerService.raiseDataEmitterEvent('off');

  }
  public toggleSideBar(): void {
    this.miniSideBarChange = !this.miniSideBarChange;
    this.sideBar.switchSideMenuPosition();
  }

  ApiCall() {
    this.callSideBarApi.emit(this.apiClick)
  }
  NotificationApi() {
    this.notify = true;

    this.showNotification.emit(this.notify)
  }
  public togglesMobileSideBar(): void {
    this.sideBar.switchMobileSideBarPosition();
  }

  toggleMenu() {
    $("#sidebar").toggleClass("active");
    setTimeout(function () {
      $("#sidebar").toggleClass("fixed");
    }, 2000);
  }
  ngAfterViewInit() {
    setTimeout(() => {

      this.translate.setDefaultLang(this.selectedLang);
    }, 1000);
  }

  changeTabsViewToGrid(value) {
  }
  showGrid() {
    this.gridListView = true;
  }
  showListview() {
    this.gridListView = false;
  }
  showGridView() {
    this.gridListView = true;
  }
  hideGridView() {
    this.gridListView = false;
  }
  activeGridOrCard() {
    // this.gridListView=this.claimReImburstment.gridListView

  }

  getBgColors(str: any) {
    const min = 0;
    const max = 15;
    const index = this.findAlphapositions(str, 1);
    return this.nameBgColors[index];
  }
  findAlphapositions(str: any, n: any) {
    const NUM = 31;

    return str[0].charCodeAt(0) & NUM;
  }
  clear(table: Table) {

    table.clear();
  }
  getSeverity(status: string) {
    switch (status.toLowerCase()) {
      case 'inactive':
        return 'danger';

      case 'active':
        return 'success';
    }
  }
  getAcronym(str: any) {
    let matches = str.match(/\b(\w)/g); // ['J','S','O','N']
    matches = matches.slice(0, 3);
    const acronym = matches.join(''); // JSON
    return acronym;
  }
  redirectToFreshdesk() {
    window.open(this.freshwork_domain, '_blank');
  }
}
