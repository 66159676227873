import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { documentservice } from 'src/app/all-modules/documents.sevice';
import { SpinnerService } from 'src/app/snipper/spinner.service';
import { ApiModulesService } from 'src/app/all-modules/api-modules.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-employee-settings',
  templateUrl: './employee-settings.component.html',
  styleUrls: ['./employee-settings.component.css']
})
export class EmployeeSettingsComponent implements OnInit {
  filedata = [];
  url = [];
  images: { url: string,name:any }[];
  profileImages: any;
  uploadImages: any
  clientID = localStorage.getItem('client_id');
  companyID = localStorage.getItem('company_id');
  employeeID = localStorage.getItem('employee_id');
  setingsData: any;
  emp_setting_id: any;
  public form: FormGroup;
  loading: boolean;
  oldpassword=true;
  password=true;
  errors: [];
  isvalidconfirmpassword = false;
passwordRegex:any= /^(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\-])(?=.*\d).{8,}$/;
  constructor(private spinnerservice: SpinnerService, private toastr: ToastrService,private router:Router, private formBuilder: FormBuilder, private apiModulesService: ApiModulesService, private documentService: documentservice,) { }


  ngOnInit() {
    this.spinnerservice.raiseDataEmitterEvent('on');
    this.employeeImageData();
    this.form = this.formBuilder.group({
      old_password: ['', [Validators.required]],
      password: ['', [Validators.required,Validators.pattern(this.passwordRegex)]],
      confirmPassword: ['', [Validators.required]],

  });
    this.images = [
      { url: "/assets/img/Scenery1.jpg",name:"Image 1"},
      { url: "/assets/img/Scenery2.jpg",name:"Image 2" },
      { url: "/assets/img/Scenery3.jpg",name:"Image 3" },
      { url: "/assets/img/Birthday1.jpg",name:"Image 4" }
    ]
    this.profileImages = [
      { old_url:null },
      {old_url:null},
      { old_url:null },
      { old_url:null}
    ]

    this.uploadImages = [
      { url: "/assets/img/Scenery1.jpg",old_url:null },
      { url: "/assets/img/Scenery2.jpg" ,old_url:null},
      { url: "/assets/img/Scenery3.jpg",old_url:null },
      { url: "/assets/img/Birthday1.jpg" ,old_url:null}


    ]
    // const uploadLink = document.getElementById('uploadLink');
    // const fileInput = document.getElementById('upload') as HTMLInputElement;

    // uploadLink.addEventListener('click', () => {
    //   fileInput.click();
    // });
  }

  fileEvent(e, index) {
    //The below 'if' means when we are touching the file control box, for creating new or editing the existing.

    this.filedata[index] = e.target.files[0];
    var reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = (e) => {
      var img = new Image;
      this.url[index] = e.target.result;
      // this.src = e.target.result;
    }
  }
  cancel(i) {
    this.filedata[i] = null;
    this.url[i] = null;
  }

  updateImageSetting(item:any) {
    this.spinnerservice.raiseDataEmitterEvent('on');

    const myFormData = new FormData();
    var path = [];
    for (let i = 0; i < 4; i++) {
      if (this.url[i] != undefined && this.url[i] != null) {
        path[i] = 'home/images/cli' + this.clientID + '/comp' + this.companyID + '/emp' + this.employeeID
      } else  {
        path[i] = this.profileImages[i].old_url
      }
      let num = i
      myFormData.append('file_' + num, this.filedata[i] != undefined ? this.filedata[i] : null);
      myFormData.append('path_' + num, path[i]);


    }

    myFormData.append('client_id', this.clientID);
    myFormData.append('company_id', this.companyID);
    myFormData.append('employee_id', this.employeeID);
    myFormData.append('emp_setting_id', item);

    this.documentService.post('api/employee-tabs/settings/update', myFormData).subscribe((data) => {
      this.employeeImageData();
      this.toastr.success('Successfully update document!', 'Success');
      /* Image Post Request */
      this.filedata = [];
      this.url = []
      this.spinnerservice.raiseDataEmitterEvent('off');

    }, (err) => {
      this.spinnerservice.toasterError(err);
    });

  }

  employeeImageData() {

    this.spinnerservice.raiseDataEmitterEvent('on');
    const params: any = {};
    params.client_id = this.clientID;
    params.company_id = this.companyID;
    params.employee_id = this.employeeID;

    this.apiModulesService.list('employee-tabs/settings/list', params).subscribe((data) => {
      var employee_setting=data.data.employee_setting[0]!=undefined?data.data.employee_setting[0]:{}
      this.uploadImages = [
        { url: employee_setting.image_1!=undefined  && employee_setting.image_1 !=null?'data:image/png;base64,' +employee_setting.image_1:null},
        { url:  employee_setting.image_2!=undefined && employee_setting.image_2 !=null ?'data:image/png;base64,' +employee_setting.image_2:null},
        { url:  employee_setting.image_3!=undefined && employee_setting.image_3 !=null?'data:image/png;base64,' +employee_setting.image_3:null},
        { url:  employee_setting.image_4!=undefined && employee_setting.image_4 !=null?'data:image/png;base64,' +employee_setting.image_4:null}
      ]
      this.profileImages = [
        {old_url:employee_setting.old_image_1!=undefined && employee_setting.old_image_1 !=null? employee_setting.old_image_1:null},
        {old_url:employee_setting.old_image_2!=undefined && employee_setting.image_2 !=null ?employee_setting.old_image_2:null},
        { old_url:employee_setting.old_image_3!=undefined && employee_setting.old_image_3 !=null ?employee_setting.old_image_3:null},
        {old_url:employee_setting.old_image_4!=undefined && employee_setting.old_image_4 !=null ?employee_setting.old_image_4:null},

      ]
      this.emp_setting_id=employee_setting.emp_setting_id !=undefined?employee_setting.emp_setting_id:null
      this.spinnerservice.raiseDataEmitterEvent('off');
    }, (err) => {
      this.spinnerservice.toasterError(err);
    })
  }
  resetPassword() {
    if (!this.form.valid) {
        this.form.markAllAsTouched();
        return;
    }
    if (this.form.value.password != this.form.value.confirmPassword) {
        this.isvalidconfirmpassword = true;
    }
    else {
        this.isvalidconfirmpassword = false;

        const params: any = {};
        params.old_password = this.form.get('old_password').value;
        params.password = this.form.get('password').value;
        params.password_confirmation = this.form.get('confirmPassword').value;
        this.apiModulesService.list('reset/password', params)
            .subscribe((res: any) => {
              ($('#password_changes') as any).modal('hide');
                params.token=localStorage.getItem('token');
                this.apiModulesService.list('logout',params).subscribe((data:any)=>{
                    window.localStorage.removeItem('token');
                    window.localStorage.removeItem('is_first_login');
                    window.localStorage.clear();
                    this.router.navigateByUrl('/login');
                })
            }, (err: any) => {
                this.errors = [];
                this.errors=err.error.data.userDetails
                setTimeout(() => {
                  this.errors = [];
              }, 3000)
            });
    }
}

oldpasswordVisible(){
    this.oldpassword=!this.oldpassword
    setTimeout(()=>{
        this.oldpassword=!this.oldpassword;
    },500)
}

passwordVisible(){
    this.password=!this.password
setTimeout(()=>{
    this.password=!this.password;
},500)
}
}
