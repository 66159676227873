import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import{ApiModulesService} from '../../../api-modules.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { Table } from 'primeng/table';
import { SpinnerService } from 'src/app/snipper/spinner.service';
declare const $: any;

@Component({
  selector: 'app-countries-main',
  templateUrl: './countries-main.component.html',
  styleUrls: ['./countries-main.component.css'],
})
export class CountriesMainComponent implements OnInit, OnDestroy, AfterViewInit {

  public editId: any;
  public tempId: any;
  public rows = [];
  public srch = [];
  searchKeyword: string = '';
  public countryData = [];
  public listHeaders = [];
  search: any;
  isDtInitialized:boolean = false;
  addCountryForm: FormGroup;
  regionDropDown: any;
  languageDropDown: any;
  nameRegex = /^[a-zA-Z\s]*$/;
  editCountryForm: FormGroup;
  selected_country: any = 1;
  selectedCountry:any;
  countriesList: any;
  statusApi:any;
  statusValue:any;
  states: any;
  public totalRecords: any = 0;
  limit = 10;
  pageNo = 1;
  loading=false;
  statuses:any;
  nameBgColors:any = ["bg-blue-dim","bg-blue-dim","bg-azure-dim","bg-indigo-dim","bg-info-dim","bg-purple-dim","bg-pink-dim","bg-orange-dim","bg-teal-dim","bg-primary-dim","bg-success-dim","bg-warning-dim","bg-danger-dim","bg-secondary-dim","bg-dark-dim","bg-gray-dim","bg-blue-dim","bg-azure-dim","bg-indigo-dim","bg-info-dim","bg-purple-dim","bg-pink-dim","bg-orange-dim","bg-teal-dim","bg-primary-dim","bg-success-dim","bg-warning-dim","bg-danger-dim","bg-secondary-dim","bg-dark-dim","bg-gray-dim"];
    nameBorderColors:any = ["bg-outline-primary","bg-outline-success","bg-outline-info","bg-outline-gray","bg-outline-warning","bg-outline-dark","bg-outline-light","bg-outline-secondary","bg-outline-danger"];
  constructor(
    private apiModuleService: ApiModulesService,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,private spinnerService: SpinnerService
  ) { }

  ngOnInit() {
    const that = this;

    // Add Provident Form Validation And Getting Values
    this.DropDown();
    this.getCountry();
    this.addCountryForm = this.formBuilder.group({
      country_name: ['', [Validators.required, Validators.pattern(this.nameRegex)]],
      iso2: ['', [Validators.required]],
      iso3: ['', [Validators.required]],
      phone_code: ['', [Validators.required]],
      capital: ['', [Validators.required, Validators.pattern(this.nameRegex)]],
      region_id: ['', [Validators.required]],
      sub_region_name: ['', [Validators.required]],
      language_id: ['', [Validators.required]],
      currency_code: ['', [Validators.required, Validators.pattern(this.nameRegex)]],
      currency_name: ['', [Validators.required, Validators.pattern(this.nameRegex)]],
      currency_symbol: ['', [Validators.required]],
    });

    // Edit Provident Form Validation And Getting Values

    this.editCountryForm = this.formBuilder.group({
      country_id: ['', []],
      country_name: ['', [Validators.required, Validators.pattern(this.nameRegex)]],
      iso2: ['', [Validators.required, Validators.maxLength(2)]],
      iso3: ['', [Validators.required, Validators.maxLength(3)]],
      phone_code: ['', [Validators.required]],
      capital: ['', [Validators.required, Validators.pattern(this.nameRegex)]],
      region_id: ['', [Validators.required]],
      sub_region_name: ['', [Validators.required]],
      language_id: ['', [Validators.required]],
      currency_code: ['', [Validators.required, Validators.pattern(this.nameRegex)]],
      currency_name: ['', [Validators.required, Validators.pattern(this.nameRegex)]],
      currency_symbol: ['', [Validators.required]],
    });
    this.statuses = [
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ];
  }

  ngAfterViewInit(): void {

  }
  rerender(): void {

  }
  // Get all country data
  public getCountry() {
    this.spinnerService.raiseDataEmitterEvent('on');
    this.apiModuleService.get('admin/country/list').subscribe((data) => {
      this.countryData = (data.data.country != undefined) ? data.data.country : [];
      this.loading=false;
      var totalRecords = (this.countryData[0] != undefined) ? this.countryData[0].total_records : 0;
      this.totalRecords = totalRecords;
      this.resetForm();
      setTimeout(() => {
        this.spinnerService.raiseDataEmitterEvent('off');
      }, 100);
    }, (err) => {
      this.countryData = [];
      this.spinnerService.raiseDataEmitterEvent('off');
      this.toastrService.error('Something went wrong!', 'Try Again');
      // this.rerender();
    });
  }

  clear(table: Table) {
    table.clear();
  }

  getSeverity(status: string) {
    switch (status.toLowerCase()) {
      case 'inactive':
        return 'danger';

      case 'active':
        return 'success';
    }
  }

  // Reset country
  public resetForm() {
    this.addCountryForm.reset();
  }

  // add country
  public onAddcountry() {
    if (!this.addCountryForm.valid) {
      this.addCountryForm.markAllAsTouched();
      return;
    }
    const params: any = {};
    params.country_name = this.addCountryForm.get('country_name').value;
    params.iso2 = this.addCountryForm.get('iso2').value;
    params.iso3 = this.addCountryForm.get('iso3').value;
    params.phone_code = this.addCountryForm.get('phone_code').value;
    params.capital = this.addCountryForm.get('capital').value;
    params.region_id = this.addCountryForm.get('region_id').value;
    params.sub_region_name = this.addCountryForm.get('sub_region_name').value;
    params.language_id = this.addCountryForm.get('language_id').value;
    params.currency_code = this.addCountryForm.get('currency_code').value;
    params.currency_name = this.addCountryForm.get('currency_name').value;
    params.currency_symbol = this.addCountryForm.get('currency_symbol').value;
    params.status = 1;
    this.apiModuleService.add(params, 'admin/country/add').subscribe((data) => {
      ($('#add_country') as any).modal('hide');
      this.getCountry();
      this.toastrService.success('Country Added Sucessfully...!', 'Success');
      this.addCountryForm.reset();
    }, (err: any) => {
      let errors = err.error.data.country;
      errors = errors.replace('SQLSTATE[42000]: [Microsoft][ODBC Driver 17 for SQL Server][SQL Server]', 'Error: ');
      this.toastrService.error(errors, 'TryAgain')
  });
  }
  // Edit country
  public onEditCountry(country_id: any) {
    const params: any = {};
    params.country_id = country_id;
    this.apiModuleService.edit(params, 'admin/country/edit').subscribe((data) => {
      let countryDetail = data.data.country;
      this.editCountryForm.setValue({
        country_id: country_id,
        country_name: countryDetail.country_name,
        iso2: countryDetail.iso2,
        iso3: countryDetail.iso3,
        phone_code: countryDetail.phone_code,
        capital: countryDetail.capital,
        region_id: countryDetail.region_id,
        sub_region_name: countryDetail.sub_region_name,
        language_id: countryDetail.language_id,
        currency_code: countryDetail.currency_code,
        currency_name: countryDetail.currency_name,
        currency_symbol: countryDetail.currency_symbol,
      });
    });
  }
  // edit country
  public onEditChanges() {
    if (!this.editCountryForm.valid) {
      this.editCountryForm.markAllAsTouched();
      return;
    }
    const params: any = {};
    params.country_id = this.editCountryForm.get('country_id').value;
    params.country_name = this.editCountryForm.get('country_name').value;
    params.iso2 = this.editCountryForm.get('iso2').value;
    params.iso3 = this.editCountryForm.get('iso3').value;
    params.phone_code = this.editCountryForm.get('phone_code').value;
    params.capital = this.editCountryForm.get('capital').value;
    params.region_id = this.editCountryForm.get('region_id').value;
    params.sub_region_name = this.editCountryForm.get('sub_region_name').value;
    params.language_id = this.editCountryForm.get('language_id').value;
    params.currency_code = this.editCountryForm.get('currency_code').value;
    params.currency_name = this.editCountryForm.get('currency_name').value;
    params.currency_symbol = this.editCountryForm.get('currency_symbol').value;
    this.apiModuleService.edit(params, 'admin/country/update').subscribe((data) => {
      this.getCountry();
      this.toastrService.success('Country Edited Sucessfully...!', 'Success');
      ($('#edit_country') as any).modal('hide');
    }, (err: any) => {
      let errors = err.error.data.country;
      errors = errors.replace('SQLSTATE[42000]: [Microsoft][ODBC Driver 17 for SQL Server][SQL Server]', 'Error: ');
      this.toastrService.error(errors, 'TryAgain')
  });
}
  // search by name
  // searchName(name) {
  //   this.rows.splice(0, this.rows.length);
  //   let temp = this.search.filter(function (d) {
  //     name = name.toLowerCase();
  //     return d.country_name.toLowerCase().indexOf(name) !== -1 ||
  //       d.capital.toLowerCase().indexOf(name) !== -1 ||
  //       d.currency_code.toLowerCase().indexOf(name) !== -1;
  //   });
  //   this.rows.push(...temp);
  // }

  DropDown() {
      this.apiModuleService.get('admin/country/dropdown', ).subscribe((data) => {
          this.regionDropDown = data.data.country[0];
          this.languageDropDown = data.data.country[1];
      });
  }

  changeStatus(Status: any, country) {
    const params: any = {};
    params.country_id = country.country_id,
    this.apiModuleService.edit(params, 'admin/country/' + Status).subscribe((data) => {
      this.getCountry();
      ($('#country-status') as any).modal('hide');
      this.toastrService.success('Status Changed Successfully!', 'Success');
    }, (err: any) => {
      let errors = err.error.data.country;
      errors = errors.replace('SQLSTATE[42000]: [Microsoft][ODBC Driver 17 for SQL Server][SQL Server]', 'Error: ');
      this.toastrService.error(errors, 'TryAgain')
  });
}
  // search by region name
  // searchRegionName(val) {
  //   this.rows.splice(0, this.rows.length);
  //   const temp = this.srch.filter(function (d) {
  //     val = val.toLowerCase();
  //     return d.country.toLowerCase().indexOf(val) !== -1 || !val;
  //   });
  //   this.rows.push(...temp);
  // }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event

  }
  getAcronym(str:any){
    var matches = str.match(/\b(\w)/g); // ['J','S','O','N']
    matches= matches.slice(0,2);
    var acronym = matches.join(''); // JSON
    return acronym;
}
getBgColors(str:any){
    var min = 0;
    var max = 15;
    var index = this.findAlphapositions(str,1);
    return this.nameBgColors[index];
}
findAlphapositions(str:any, n:any)
{
    var NUM = 31;

    return str[0].charCodeAt(0) & NUM;
}
callStatusModel(url_name:any,data:any,actionName:string){
this.statusApi=url_name;
this.statusValue=data;
}
}
