import {Component, OnInit, ViewChild, AfterViewInit, OnDestroy, Input, Output, EventEmitter} from '@angular/core';
import {ApiModulesService} from '../../../api-modules.service';
import {AllModulesService} from '../../../all-modules.service';
import {ToastrService} from 'ngx-toastr';
import {FormGroup, FormBuilder, Validators, NgForm} from '@angular/forms';
import {Subscription, Subject,} from 'rxjs';
import {HttpParams} from '@angular/common/http';
import {SpinnerService} from 'src/app/snipper/spinner.service';
import {Table} from 'primeng/table';

declare const $: any;

@Component({
    selector: 'app-leave-company-settings',
    templateUrl: './leave-company-settings.component.html',
    styleUrls: ['./leave-company-settings.component.css']
})
export class LeaveCompanySettingsComponent implements OnInit, OnDestroy, AfterViewInit {
    public leavedata = [];
    public listHeaders = [];
    rows: any;
    public activeIndex = 0;
    public srch = [];
    search: any[];
    ClientDropDown = [];
    CompanyDropDown = [];
    leaveCustomisedata = [];
    tabToGrid = false;
    public addLeaveForm: FormGroup
    loading = false;
    graphDisable=false;
    cardDisable=false;
    public clientname = [];
    public totalRecords: any = 0;
    public ShowForm: any = false;
    ClientID: any;
    CompanyID: any;
    nameBgColors: any = ["bg-blue-dim", "bg-blue-dim", "bg-azure-dim", "bg-indigo-dim", "bg-info-dim", "bg-purple-dim", "bg-pink-dim", "bg-orange-dim", "bg-teal-dim", "bg-primary-dim", "bg-success-dim", "bg-warning-dim", "bg-danger-dim", "bg-secondary-dim", "bg-dark-dim", "bg-gray-dim", "bg-blue-dim", "bg-azure-dim", "bg-indigo-dim", "bg-info-dim", "bg-purple-dim", "bg-pink-dim", "bg-orange-dim", "bg-teal-dim", "bg-primary-dim", "bg-success-dim", "bg-warning-dim", "bg-danger-dim", "bg-secondary-dim", "bg-dark-dim", "bg-gray-dim"];
    nameBorderColors: any = ["bg-outline-primary", "bg-outline-success", "bg-outline-info", "bg-outline-gray", "bg-outline-warning", "bg-outline-dark", "bg-outline-light", "bg-outline-secondary", "bg-outline-danger"];

    public days = []
    editedLeaveData: any;
    showTabs = false;
    ShowPermissionForm = false;
    leave_plantype_dropdown = [];
    leave_plan_dropdown = [];
    statuses: { label: string; value: string; }[];

    constructor(private apiModulesService: ApiModulesService, private AllModulesService: AllModulesService, private toaster: ToastrService, private toastr: ToastrService, private formBuilder: FormBuilder, private spinnerService: SpinnerService) {
    }

    ngOnInit(): void {
        this.GetClientDropDown();
        this.addLeaveForm = this.formBuilder.group({
            leave_plan_type_id: ["", [Validators.required]],
            no_of_days: ["", [Validators.required]],
            company_leave_setting_id: ["", []]
        });
        this.statuses = [
            {label: 'Active', value: 'active'},
            {label: 'Inactive', value: 'inactive'},
        ];
    }

    showAddForm() {
        this.ShowForm = true;
        this.resetForm();
    }

    ngOnDestroy(): void {
        // Do not forget to unsubscribe the event

    }

    /* client DropDown codes*/
    public GetClientDropDown() {
        this.apiModulesService.get('DropDown/client').subscribe((data) => {
            this.ClientDropDown = data.data.client;

            this.ClientID = data.data.client[0].client_id;
            this.GetCompanyDropDown();

        })
    }

    /* company DropDown codes */
    public GetCompanyDropDown() {
        const params: any = {};
        params.client_id = this.ClientID;

        this.apiModulesService.list('DropDown/company', params).subscribe((data) => {
                this.CompanyDropDown = data.data.company != undefined ? data.data.company : [];
                if (this.CompanyDropDown.length !== 0) {
                    setTimeout(() => {
                        this.CompanyID = this.CompanyDropDown[0].company_id;
                        this.dropDown();
                        this.getLeave();
                    }, 100)
                }
                else {
                    this.CompanyID = null;
                    this.rerender();
                }

            }
        )
    }

    //Get all paycomponent data
    public getLeave() {
        this.spinnerService.raiseDataEmitterEvent('off');
        this.ShowForm = false;
        var params: any = {};
        params.client_id = this.ClientID;
        params.company_id = this.CompanyID;

        this.apiModulesService.list("admin/leavemanagement/company/leave-setting/list", params).subscribe((data) => {

            this.leavedata = (data.data.leaves != undefined) ? data.data.leaves : [];
            this.leaveCustomisedata = (data.data.leaves != undefined) ? data.data.leaves : [];
            this.loading = false;
            var totalRecords = (this.leavedata[0] != undefined) ? this.leavedata[0].total_records : 0;
            this.totalRecords = totalRecords;
            this.resetForm();
            setTimeout(() => {
                this.spinnerService.raiseDataEmitterEvent('off');
            }, 100);
        }, (err) => {
            this.leavedata = [];
            this.spinnerService.raiseDataEmitterEvent('off');
            // this.toastr.error('Something went wrong!', 'Try Again');
            // this.rerender();
        });
    }

    clear(table: Table) {
        table.clear();
    }

    getSeverity(status: string) {
        switch (status.toLowerCase()) {
            case 'inactive':
                return 'danger';

            case 'active':
                return 'success';
        }
    }

    changeStatus(Status: any, data) {
        let params: any = {};
        params.company_leave_setting_id = data.company_leave_setting_id,
            params.client_id = data.client_id;
        params.company_id = data.company_id;
        params.leave_plan_id = data.leave_plan_id;

        this.apiModulesService.edit(params, 'admin/leavemanagement/company/leave-setting/' + Status).subscribe((data) => {
                this.getLeave();
                this.toaster.success('Status Changed Successfully!', 'Success')
            }, (err) => {
                this.toastr.error("Something went wrong!", "Try Again");
            }
        );
    }

    public onAddLeaveSettings() {
        if (!this.addLeaveForm.valid) {

            this.addLeaveForm.markAllAsTouched();
            return;
        }
        var params: any = {};
        params.client_id = this.ClientID;
        params.company_id = this.CompanyID;
        params.leave_plan_type_id = this.addLeaveForm.get('leave_plan_type_id').value;
        params.no_of_days = this.addLeaveForm.get('no_of_days').value;

        var company_leave_setting_id = this.addLeaveForm.get('company_leave_setting_id').value;

        if (company_leave_setting_id != '' && company_leave_setting_id != undefined) {
            params.company_leave_setting_id = this.addLeaveForm.get('company_leave_setting_id').value;
            this.apiModulesService.edit(params, "admin/leavemanagement/company/leave-setting/update",).subscribe((data) => {


                this.getLeave();
                this.toastr.success('Leave Modified Sucessfully...!', 'Success');
                ($('#add_leavesettings') as any).modal('hide');
            }, err => {

                if (err.status === 437) {
                    this.toastr.error('Leave Already Exists...!', 'Failed');
                } else {
                    this.toastr.error('Something Went Wrong Please Try Again...!', 'Error');
                }
            });

        } else {
            this.apiModulesService.add(params, "admin/leavemanagement/company/leave-setting/create",).subscribe((data) => {
                this.getLeave();
                this.toastr.success('Leave Added Sucessfully...!', 'Success');
                this.addLeaveForm.reset();
                ($('#add_leavesettings') as any).modal('hide');
            }, err => {

                if (err.status === 437) {
                    this.toastr.error('Leave Already Exists...!', 'Failed');
                } else {
                    this.toastr.error('Something Went Wrong Please Try Again...!', 'Error');
                }
            });
        }
    }
 public updateCustomize(leaveCustomizeform:NgForm) {
        if (!leaveCustomizeform.valid||this.graphDisable||this.cardDisable) {

            leaveCustomizeform.submitted;
            return;
        }
        var params: any = {};
        params.client_id = this.ClientID;
        params.company_id = this.CompanyID;
        params.permission = leaveCustomizeform.value;


            this.apiModulesService.add(params, "admin/leavemanagement/company/leave-setting/customize",).subscribe((data) => {
                this.getLeave();
                this.customoizeFormReset(leaveCustomizeform);
                this.toastr.success('Leave Added Sucessfully...!', 'Success');
                this.addLeaveForm.reset();
                ($('#add_leavesettings') as any).modal('hide');
            }, err => {

                if (err.status === 437) {
                    this.toastr.error('Leave Already Exists...!', 'Failed');
                } else {
                    this.toastr.error('Something Went Wrong Please Try Again...!', 'Error');
                }
            });

    }
    validCheckCard(leaveCustomizeform){
    }

    validCheck(leaveCustomizeform,formname,Disablename) {
        const length=this.leavedata.length
        var graph = leaveCustomizeform.value;
        // graph=graph.toArray();
        // const con=graph.filter((val:any)=>val.is_graph==true);
        let count=0;
        for(let i=0;i<length;i++){
         count=leaveCustomizeform.value[i][formname]==true?count+1:count+0;
        }
        this[Disablename]=count<=4?false:true;
    }
    //Reset form
    public resetForm() {
        this.addLeaveForm.reset();


    }
public customoizeFormReset(leaveCustomizeform:NgForm){
    // leaveCustomizeform.reset();
    this.cardDisable=false;
    this.graphDisable=false;
    this.getLeave()
}
    public editSetting(leaves: any) {
        this.resetForm();
        const params: any = {};
        params.client_id = this.ClientID;
        params.company_id = this.CompanyID;
        params.company_leave_setting_id = leaves.company_leave_setting_id;

        this.apiModulesService.edit(params, 'admin/leavemanagement/company/leave-setting/edit').subscribe((data) => {

                const leaveDetail = (data.data.leaves !== undefined) ? data.data.leaves : [];
                this.addLeaveForm.setValue({
                    leave_plan_type_id: leaveDetail.leave_plan_type_id,
                    no_of_days: leaveDetail.no_of_days,
                    company_leave_setting_id: leaveDetail.company_leave_setting_id,
                });
            }, (err) => {
                this.toastr.error('Something went wrong!', 'Try Again');
            }
        );
    }

    // searchName(val) {
    //   this.rows.splice(0, this.rows.length);
    //   let temp = this.srch.filter(function (d) {
    //     val = val.toLowerCase();
    //     return d.leave_plan_id.toLowerCase().indexOf(val) !== -1 || d.leave_day_name.toLowerCase().indexOf(val) !== -1 || d.leave_plan_type_id.toLowerCase().indexOf(val) !== -1 || d.no_of_days.toLowerCase().indexOf(val) !== -1
    //   });
    //   this.rows.push(...temp);
    // }

    showPermission(){
        this.ShowPermissionForm=true
        setTimeout(()=>{
            this.leaveCustomisedata=this.leavedata;
        },2000)
    }
    hidePermission(){
        this.ShowPermissionForm=false
    }
    ngAfterViewInit(): void {

    }

    rerender() {
    }

    // dropdown
    public dropDown() {
        const params: any = {};
        params.client_id = this.ClientID;
        params.company_id = this.CompanyID;
        this.apiModulesService.list("admin/leavemanagement/company/leave-setting/dropdown", params).subscribe((data) => {
            this.leave_plantype_dropdown = data.data.leaves

        })
    }

    getAcronym(str: any) {
        if (str != null) {
            let matches = str.match(/\b(\w)/g); // ['J','S','O','N']
            matches = matches.slice(0, 2);
            const acronym = matches.join(''); // JSON
            return acronym;
        }
    }

    getBgColors(str: any) {
        if (str != null) {
            const min = 0;
            const max = 15;
            const index = this.findAlphapositions(str, 1);
            return this.nameBgColors[index];
        }
    }

    findAlphapositions(str: any, n: any) {
        var NUM = 31;

        return str[0].charCodeAt(0) & NUM;
    }
}
