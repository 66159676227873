import { Component, OnInit, ViewChild,AfterViewInit,OnDestroy } from '@angular/core';
import { ApiModulesService } from '../../api-modules.service';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";

import { ToastrService } from 'ngx-toastr';
import { AllModulesService } from '../../all-modules.service';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-companyorganisation',
  templateUrl: './companyorganisation.component.html',
  styleUrls: ['./companyorganisation.component.css']
})
export class CompanyorganisationComponent implements OnInit, OnDestroy, AfterViewInit {


  public rows = [];
  public srch = [];
  ClientDropDown = [];
  CompanyDropDown = [];
  ClientID = localStorage.getItem('client_id');
  CompanyID = localStorage.getItem('company_id');
  public organisationData = [];


  public addOrganisationForm: FormGroup;
  nameBgColors:any = ["bg-blue-dim","bg-blue-dim","bg-azure-dim","bg-indigo-dim","bg-info-dim","bg-purple-dim","bg-pink-dim","bg-orange-dim","bg-teal-dim","bg-primary-dim","bg-success-dim","bg-warning-dim","bg-danger-dim","bg-secondary-dim","bg-dark-dim","bg-gray-dim","bg-blue-dim","bg-azure-dim","bg-indigo-dim","bg-info-dim","bg-purple-dim","bg-pink-dim","bg-orange-dim","bg-teal-dim","bg-primary-dim","bg-success-dim","bg-warning-dim","bg-danger-dim","bg-secondary-dim","bg-dark-dim","bg-gray-dim"];
  nameBorderColors:any = ["bg-outline-primary","bg-outline-success","bg-outline-info","bg-outline-gray","bg-outline-warning","bg-outline-dark","bg-outline-light","bg-outline-secondary","bg-outline-danger"];
  public editOrganisationForm: FormGroup;
  isDtInitialized = false;
  public totalRecords: any = 0;
  listHeaders = [];
  public dropdownname = [];
  constructor(private apiModulesService: ApiModulesService, private AllModulesService: AllModulesService, private toastr: ToastrService, private formBuilder: FormBuilder) { }

  ngOnInit(): void {


    this.GetClientDropDown();
    this.addOrganisationForm = this.formBuilder.group({
      company_id: ["", []],
      client_id: ["", []],
      company_org_tile: ["", [Validators.required]],
      parent_company_org_id: ["", [Validators.required]],
      reason: ["", [Validators.required]],
      org_level: ["", [Validators.required]],
      effective_date: ["", [Validators.required]],
    });

    this.editOrganisationForm = this.formBuilder.group({
      company_id: ["", []],
      client_id: ["", []],
      company_org_tile: ["", [Validators.required]],
      parent_company_org_id: ["", [Validators.required]],
      reason: ["", [Validators.required]],
      org_level: ["", [Validators.required]],
      effective_date: ["", [Validators.required]],
      company_org_id: ["", []],
    });
  }
  ngAfterViewInit(): void {

  }
  public resetForm() {
    this.addOrganisationForm.reset();
  }
  // get datas
  public getOrganisationType() {
    if (this.CompanyDropDown.length !== 0 && this.CompanyID != null) {
    const params: any = {};
    // params.company_id=this.CompanyID;
    params.company_id = this.CompanyID;
    params.client_id = this.ClientID;
    this.apiModulesService.list("admin/companyorganisation/list", params).subscribe((data) => {

          this.organisationData = (data.data.company_organisation != undefined) ? data.data.company_organisation : [];
          var totalRecords = this.organisationData.length;
          this.totalRecords = totalRecords;

    }, (err) => {
      this.rerender();
    });
  } else {
    this.rerender();
  }

}
  changeStatus(status: any, OrganisationId: any) {

    var params: any = {};
    params.company_org_id = OrganisationId;
    params.company_id = this.CompanyID;
    params.client_id = this.ClientID;
    params.status = status;

    this.apiModulesService.edit(params, "admin/companyorganisation/status").subscribe(() => {
      this.getOrganisationType();
      this.toastr.success("Status changed successfully...!", "Success");
    }, (err) => {
      this.toastr.error("Something went wrong!", "Try Again");
    }
    );
  }
  //add data
  public onAddOrganisation() {

    if (this.addOrganisationForm.invalid) {
      this.addOrganisationForm.markAllAsTouched()
      return
    }
    var params: any = {};
    params.company_id = this.CompanyID;
    params.client_id = this.ClientID;
    params.company_org_tile = this.addOrganisationForm.get('company_org_tile').value;
    params.parent_company_org_id = this.addOrganisationForm.get('parent_company_org_id').value;
    params.reason = this.addOrganisationForm.get('reason').value;
    params.org_level = this.addOrganisationForm.get('org_level').value;
    params.effective_date = this.addOrganisationForm.get('effective_date').value;
    params.record_status = 1;

    this.apiModulesService.add(params, "admin/companyorganisation/add",).subscribe((data) => {
      this.toastr.success("Company Organisation added successfully...!", "Success");
      this.getOrganisationType();
      ($("#add_organisation") as any).modal("hide");
      this.addOrganisationForm.reset();
    }, (err) => {
      if (err.status === 437) {
        this.toastr.error('Company Organisation Already Exists...!', 'Failed');
      } else {
        this.toastr.error('Something Went Wrong Please Try Again...!', 'Error');
      }
    }
    );

  }


  //edit value get
  public onEditOrganisation(OrganisationId: any) {
    var params: any = {};
    params.company_org_id = OrganisationId;
    params.company_id = this.CompanyID;
    params.client_id = this.ClientID;

    this.apiModulesService.edit(params, "admin/companyorganisation/edit").subscribe((data) => {
      let organisationDetail = data.data.company_organisation;
      this.editOrganisationForm.setValue({
        company_org_tile: organisationDetail.company_org_tile,
        client_id: organisationDetail.client_id,
        company_org_id: organisationDetail.company_org_id,
        parent_company_org_id: organisationDetail.parent_company_org_id,
        reason: organisationDetail.reason,
        org_level: organisationDetail.org_level,
        effective_date: organisationDetail.effective_date,
        company_id: organisationDetail.company_id,
      });
    }, (err) => {
      this.toastr.error("Something went wrong!", "Try Again");
    }
    );

  }
  //edit update
  public onEditChanges() {
    if (!this.editOrganisationForm.valid) {

      this.editOrganisationForm.markAllAsTouched();
      return;
    }
    var params: any = {};
    params.company_org_tile = this.editOrganisationForm.get('company_org_tile').value;
    params.company_org_id = this.editOrganisationForm.get('company_org_id').value;
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    params.parent_company_org_id = this.editOrganisationForm.get('parent_company_org_id').value;
    params.reason = this.editOrganisationForm.get('reason').value;
    params.org_level = this.editOrganisationForm.get('org_level').value;
    params.effective_date = this.editOrganisationForm.get('effective_date').value;

    this.apiModulesService.edit(params, 'admin/companyorganisation/update').subscribe((data) => {

      this.toastr.success("Company Organisation updated successfully...!", "Success");
      this.getOrganisationType();
      ($("#edit_organisation") as any).modal("hide");
      this.editOrganisationForm.reset();


    }, (err) => {
      if (err.status === 437) {
        this.toastr.error('Company Organisation Already Exists...!', 'Failed');
      } else {
        this.toastr.error('Something Went Wrong Please Try Again...!', 'Error');
      }
    });
  }
  ngOnDestroy() {

  }
  rerender() {

  }
  /* client DropDown codes*/
  public GetClientDropDown() {
    this.apiModulesService.get('DropDown/client').subscribe((data) => {
      this.ClientDropDown = data.data.client;

      this.ClientID = data.data.client[0].client_id;
      this.GetCompanyDropDown();
    })
  }

  /* company DropDown codes */
  public GetCompanyDropDown() {
    const params: any = {};
    params.client_id = this.ClientID;

    this.apiModulesService.list('DropDown/company', params).subscribe((data) => {
      this.CompanyDropDown = data.data.company != undefined ? data.data.company : [];
      if (this.CompanyDropDown.length !== 0) {
        setTimeout(() => {
          this.CompanyID = this.CompanyDropDown[0].company_id;
          this.getOrganisationType();
          this.getDropDown();
        }, 100)
      }
      else {
        this.CompanyID = null;
        this.rerender();
      }

    }
    )
  }

  getDropDown() {
    const params: any = {};
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;

    this.apiModulesService.list('admin/companyorganisation/dropdown', params).subscribe((data) => {

      this.dropdownname = data.data.company_organisation;
    });
  }
  getAcronym(str:any){
    var matches = str.match(/\b(\w)/g); // ['J','S','O','N']
    matches= matches.slice(0,3);
    var acronym = matches.join(''); // JSON
    return acronym;
  }
  getBgColors(str:any){
    var min = 0;
    var max = 15;
    var index = this.findAlphapositions(str,1);
    return this.nameBgColors[index];
  }
  findAlphapositions(str:any, n:any)
  {
    var NUM = 31;

    return str[0].charCodeAt(0) & NUM;
  }
}
