import { Component } from '@angular/core';
import { ApiModulesService } from '../../api-modules.service';
import { FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { SpinnerService } from 'src/app/snipper/spinner.service';
import { Table } from 'primeng/table';

@Component({
  selector: 'app-import-azatecon-maker',
  templateUrl: './import-azatecon-maker.component.html',
  styleUrls: ['./import-azatecon-maker.component.css']
})
export class ImportAzateconMakerComponent {

  ClientID: any;
  financial_date_id: any;
  CompanyID: any;
  CompanyDropDown: any;
  ClientDropDown: any;
  ExcelTypeKey = 'import_employee_details';
  ClientUserType='aza_maker';
  showValidation=false;
  totalRecords=0;
  searchKeyword: string = '';
  importDataColumn=[];
  data_import_id:any;
  nameBgColors: any = ["bg-blue-dim", "bg-blue-dim", "bg-azure-dim", "bg-indigo-dim", "bg-info-dim", "bg-purple-dim", "bg-pink-dim", "bg-orange-dim", "bg-teal-dim", "bg-primary-dim", "bg-success-dim", "bg-warning-dim", "bg-danger-dim", "bg-secondary-dim", "bg-dark-dim", "bg-gray-dim", "bg-blue-dim", "bg-azure-dim", "bg-indigo-dim", "bg-info-dim", "bg-purple-dim", "bg-pink-dim", "bg-orange-dim", "bg-teal-dim", "bg-primary-dim", "bg-success-dim", "bg-warning-dim", "bg-danger-dim", "bg-secondary-dim", "bg-dark-dim", "bg-gray-dim"];
  nameBorderColors: any = ["bg-outline-primary", "bg-outline-success", "bg-outline-info", "bg-outline-gray", "bg-outline-warning", "bg-outline-dark", "bg-outline-light", "bg-outline-secondary", "bg-outline-danger"];
  financialDropdownData: any;

  constructor(protected apiModulesService: ApiModulesService,
              protected formBuilder: FormBuilder,
              protected toastrService: ToastrService,
              protected spinnerService: SpinnerService
  ) {
  }
  ngOnInit(): void {
      this.GetClientDropDown()
  }
  public GetClientDropDown() {
      this.apiModulesService.get('DropDown/client').subscribe((data) => {
          this.ClientDropDown = data.data.client;
          this.ClientID = data.data.client[0].client_id;
          this.GetCompanyDropDown();
      })
  }

  /* company DropDown codes */
  public GetCompanyDropDown() {
      const params: any = {};
      params.client_id = this.ClientID;
      this.apiModulesService.list('DropDown/company', params).subscribe((data) => {
              this.CompanyDropDown = data.data.company != undefined ? data.data.company : [];
              if (this.CompanyDropDown.length !== 0) {
                  this.CompanyID = this.CompanyDropDown[0].company_id;

                  setTimeout(() => {
                      this.payDatesDropDown()
                  }, 100)
              }
              else {
                  this.CompanyID = null;
              }
          }
      )
  }
  
  public payDatesDropDown(){
    const params: any = {};
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    this.apiModulesService.list('employee-tabs/employee-hrm/payroll-draft/dropdown', params).subscribe((data) => {
        this.financialDropdownData=data.data.payroll_report;
        if (this.CompanyDropDown.length !== 0) {
            this.financial_date_id = this.financialDropdownData[0].financial_date_id;

            setTimeout(() => {
                this.getImport()
            }, 100)
        }
        else {
            this.CompanyID = null;
        }
    },(err:any)=>{
        this.spinnerService.toasterError(err);
    }
    )
}

  public getImport() {


      if (this.ClientID!= null && this.CompanyID !== null) {
          const params: any = {};
          this.spinnerService.raiseDataEmitterEvent('on');

          params.client_id = this.ClientID;
          params.company_id = this.CompanyID;
          params.financial_date_id = this.financial_date_id;
          this.apiModulesService.list('admin/azatecon/maker/list', params).subscribe((data) => {
              this.importDataColumn = (data.data.imports != undefined) ? data.data.imports : [];
              this.totalRecords=this.importDataColumn.length;
              this.spinnerService.raiseDataEmitterEvent('off');

          }, (err) => {
              this.importDataColumn = [];
              this.totalRecords=this.importDataColumn.length;

              this.spinnerService.raiseDataEmitterEvent('off');

          });
      } else {
          this.importDataColumn = [];
          this.totalRecords = this.importDataColumn.length;

          this.spinnerService.raiseDataEmitterEvent('off');
      }
  }

  getAcronym(str: any) {
      var matches = str.match(/\b(\w)/g); // ['J','S','O','N']
      var acronym = matches.join(''); // JSON
      return acronym;
  }
  getBgColors(str: any) {
      var min = 0;
      var max = 15;
      var index = this.findAlphapositions(str, 1);
      return this.nameBgColors[index];
  }
  findAlphapositions(str: any, n: any) {
      var NUM = 31;

      return str[0].charCodeAt(0) & NUM;
  }
  clear(table: Table) {
      table.clear();
  }

  viewValidation(dataimportId){
      this.data_import_id=dataimportId;
      this.showValidation=true;

  }
  changeValidationToGrid(value) {
      this.showValidation = false;
      this.getImport();
  }
}
