// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content {
	padding: 30px;
}


.buttons button {
	margin: 5px;
  width: 50%;
}
.buttons_eye button {
	/* margin: 5px; */
	width: 100%;
}
.document_table{
	background-color: aliceblue;
}
img{
	padding: 30px;
	width:100%;
}
.popup-headers{
  margin-top: -20px;
}
`, "",{"version":3,"sources":["webpack://./src/app/all-modules/employees/documents/documents.component.css"],"names":[],"mappings":"AAAA;CACC,aAAa;AACd;;;AAGA;CACC,WAAW;EACV,UAAU;AACZ;AACA;CACC,iBAAiB;CACjB,WAAW;AACZ;AACA;CACC,2BAA2B;AAC5B;AACA;CACC,aAAa;CACb,UAAU;AACX;AACA;EACE,iBAAiB;AACnB","sourcesContent":[".content {\r\n\tpadding: 30px;\r\n}\r\n\r\n\r\n.buttons button {\r\n\tmargin: 5px;\r\n  width: 50%;\r\n}\r\n.buttons_eye button {\r\n\t/* margin: 5px; */\r\n\twidth: 100%;\r\n}\r\n.document_table{\r\n\tbackground-color: aliceblue;\r\n}\r\nimg{\r\n\tpadding: 30px;\r\n\twidth:100%;\r\n}\r\n.popup-headers{\r\n  margin-top: -20px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
