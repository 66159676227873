import {Injectable} from "@angular/core";
import {ApiModulesService} from "../api-modules.service";

@Injectable({
    providedIn: 'root'
})

export class EmployeeDropdownService {
    protected clientId: any;
    protected companyID: any;
    protected DropDownData: any = {
        'employee': [],
        'department': [],
        'designation': [],
    };

    constructor(private apiModuleService: ApiModulesService) {

    }

    protected employeeApiDropDown() {
        const params: any = {};
        params.client_id = this.clientId;
        params.company_id = this.companyID;
        let promise = new Promise((resolve, reject) => {
            this.apiModuleService.list('DropDown/department/designation/employee', params).subscribe((data: any) => {
                this.DropDownData.employee = data.data.dropDown[0] != undefined ? data.data.dropDown[0] : [];
                this.DropDownData.department = data.data.dropDown[1] != undefined ? data.data.dropDown[1] : [];
                this.DropDownData.designation = data.data.dropDown[2] != undefined ? data.data.dropDown[2] : [];
                resolve(this.DropDownData.department)
            }, (err: any) => {
                reject('no data')
            });
        });
        let data = promise.then((result) => {
            return result
        });
        return data;

    }

    public OverallDropDownData(client_id: number, company_id: number) {
        this.companyID = company_id;
        this.clientId = client_id;
        let promise = new Promise((resolve, reject) => {
            let data = this.employeeApiDropDown();
            resolve(data);
            reject('no data')

        });
        let value = promise.then((result) => {

            return result
        }).catch((reason) => {
            return reason;
        });
        return value
    }

    public designationDropDown(department_id: number):Array<object> {
        return this.singleFilterFunction(this.DropDownData.designation,'department_id',department_id);
    }
    public employeeDropDown(department_id: number,designation_id:number):Array<object> {

        return this.multipleFilterFunction(this.DropDownData.employee,'department_id','designation_id',department_id,designation_id);
    }

    public singleFilterFunction(arrayFilter: Array<any>, filterKey: string, byFilterValue: any) {
        return arrayFilter.filter((data: any) => data[filterKey] == byFilterValue)
    }
    public multipleFilterFunction(arrayFilter: Array<any>, firstFilterKey: string, secondFilterKey:string,byFirstFilterValue: any,bySecondFilterValue: any) {
        return arrayFilter.filter((data: any) => data[firstFilterKey] == byFirstFilterValue &&  data[secondFilterKey] == bySecondFilterValue)
    }
}
