
import { Component, OnInit, ViewChild,AfterViewInit,OnDestroy,Input,Output,EventEmitter } from '@angular/core';
import{ApiModulesService} from '../../../api-modules.service';
import{AllModulesService} from '../../../all-modules.service';

import { ToastrService } from 'ngx-toastr';
import { Table } from 'primeng/table';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription, Subject, } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { SpinnerService } from 'src/app/snipper/spinner.service';
declare const $: any;
@Component({
  selector: 'app-leavecompanyholiday',
  templateUrl: './leavecompanyholiday.component.html',
  styleUrls: ['./leavecompanyholiday.component.css']
})
export class LeavecompanyholidayComponent implements OnInit, OnDestroy, AfterViewInit {
  public leavedata = [];
  public listHeaders = [];
  rows: any;
  public activeIndex = 0;
  public srch = [];
  search: any[];
  tabToGrid=false;
  ClientDropDown = [];
  CompanyDropDown = [];
  public addLeaveForm: FormGroup
  loading=false;
  public clientname = [];
  public totalRecords: any = 0;
  public ShowForm: any = false;
  ClientID :any;
  CompanyID :any;
  @Input() authLeaveData: any = {};
  @Output() parenFun: EventEmitter<any> = new EventEmitter();
  nameBgColors:any = ['bg-blue-dim','bg-blue-dim','bg-azure-dim','bg-indigo-dim','bg-info-dim','bg-purple-dim','bg-pink-dim','bg-orange-dim','bg-teal-dim','bg-primary-dim','bg-success-dim','bg-warning-dim','bg-danger-dim','bg-secondary-dim','bg-dark-dim','bg-gray-dim','bg-blue-dim','bg-azure-dim','bg-indigo-dim','bg-info-dim','bg-purple-dim','bg-pink-dim','bg-orange-dim','bg-teal-dim','bg-primary-dim','bg-success-dim','bg-warning-dim','bg-danger-dim','bg-secondary-dim','bg-dark-dim','bg-gray-dim'];
  nameBorderColors:any = ['bg-outline-primary','bg-outline-success','bg-outline-info','bg-outline-gray','bg-outline-warning','bg-outline-dark','bg-outline-light','bg-outline-secondary','bg-outline-danger'];

  public stateDropDown = []
  stateID:any;
  editedLeaveData: any;
  showTabs = false;
  statuses: { label: string; value: string; }[];
  constructor(private apiModulesService: ApiModulesService, private AllModulesService: AllModulesService, private toaster: ToastrService, private toastr: ToastrService, private formBuilder: FormBuilder,private spinnerService: SpinnerService) { }

  ngOnInit(): void {
    this.spinnerService.raiseDataEmitterEvent('on');

  this.dropDown();

    this.addLeaveForm = this.formBuilder.group({
      holiday_name: ['', [Validators.required]],
      holiday_desc: ['', [Validators.required]],
      client_id: ['', []],
      company_id: ['', []],
      holiday_date: ['', [Validators.required]],
      state_id: ['', [Validators.required]],
      type: ['', [Validators.required]],
      holiday_id: ['', []],
      leave_plan_id: ['', []],
    });
    this.statuses = [
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ];
  }

  showAddForm() {
    this.ShowForm = true;
    this.resetForm();
  }
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event

  }

  //Get all paycomponent data
  public getLeaveHoliday() {
    this.spinnerService.raiseDataEmitterEvent('on');
    this.ShowForm = false;
    var params:any={};
    params.client_id = this.authLeaveData.client_id;
    params.company_id = this.authLeaveData.company_id;
    params.leave_plan_id = this.authLeaveData.leave_plan_id;
    params.state_id = this.stateID;

          this.apiModulesService.list('admin/leavemanagement/company/holidays/get', params).subscribe((data) => {

              this.leavedata = (data.data.leaves != undefined) ? data.data.leaves : [];
              this.loading = false;
              var totalRecords = (this.leavedata[0] != undefined) ? this.leavedata[0].total_records : 0;
              this.totalRecords = totalRecords;
              this.resetForm();
              setTimeout(() => {
                this.spinnerService.raiseDataEmitterEvent('off');
            }, 100);
          }, (err) => {
            this.leavedata = [];
            this.spinnerService.raiseDataEmitterEvent('off');
            // this.toastr.error('Something went wrong!', 'Try Again');
              // this.rerender();
          });
  }

  clear(table: Table) {
    table.clear();
  }

  getSeverity(status: string) {
    switch (status.toLowerCase()) {
      case 'inactive':
        return 'danger';

      case 'active':
        return 'success';
    }
  }
  changeStatus(Status: any, data: any) {
    let params: any = {};
    params.client_id = data.client_id;
    params.holiday_id = data.holiday_id,
    params.company_id = data.company_id;
    params.leave_plan_id = data.leave_plan_id;

    this.apiModulesService.edit(params, 'admin/leavemanagement/company/holidays/' + Status).subscribe((data) => {
      this.getLeaveHoliday();
      this.toaster.success('Status Changed Successfully!', 'Success')
    }, (err) => {
      this.toastr.error('Something went wrong!', 'Try Again');
    }
    );
  }
  public onAddLeaveHoliday() {
    if (!this.addLeaveForm.valid) {

      this.addLeaveForm.markAllAsTouched();
      return;
    }
    var params: any = {};
    params.client_id = this.authLeaveData.client_id;
    params.company_id = this.authLeaveData.company_id;
    params.leave_plan_id = this.authLeaveData.leave_plan_id;
    params.holiday_name = this.addLeaveForm.get('holiday_name').value;
    params.holiday_desc = this.addLeaveForm.get('holiday_desc').value;
    params.holiday_date = this.addLeaveForm.get('holiday_date').value;
    params.type = this.addLeaveForm.get('type').value;
    params.state_id =this.addLeaveForm.get('state_id').value;


    var holiday_id = this.addLeaveForm.get('holiday_id').value;

    if (holiday_id != '' && holiday_id != undefined) {
      params.holiday_id = this.addLeaveForm.get('holiday_id').value;
      this.apiModulesService.edit(params, 'admin/leavemanagement/company/holidays/update',).subscribe((data) => {

this.stateID=this.addLeaveForm.get('state_id').value;
        this.getLeaveHoliday();

        this.toastr.success('Leave Modified Sucessfully...!', 'Success');
        ($('#add_leaveholiday') as any).modal('hide');
        this.resetForm();
      }, err => {

        if (err.status === 437) {
          this.toastr.error('Leave Already Exists...!', 'Failed');
        } else {
          this.toastr.error('Something Went Wrong Please Try Again...!', 'Error');
        }
      });

    } else {
      this.apiModulesService.add(params, 'admin/leavemanagement/company/holidays/add',).subscribe((data) => {
        this.stateID=this.addLeaveForm.get('state_id').value;

        this.getLeaveHoliday();
        this.toastr.success('Leave Added Sucessfully...!', 'Success');
        ($('#add_leaveholiday') as any).modal('hide');
        this.resetForm();

      }, err => {

        if (err.status === 437) {
          this.toastr.error('Leave Already Exists...!', 'Failed');
        } else {
          this.toastr.error('Something Went Wrong Please Try Again...!', 'Error');
        }
      });
    }
  }
  //Reset form
  public resetForm() {
    this.addLeaveForm.reset();
  }

  public editHoliday(leaves: any) {
    this.resetForm();
    const params: any = {};
    params.client_id = this.authLeaveData.client_id;
    params.holiday_id = leaves.holiday_id;
    params.company_id = this.authLeaveData.company_id;
    params.leave_plan_id = this.authLeaveData.leave_plan_id;


    this.apiModulesService.edit(params, 'admin/leavemanagement/company/holidays/edit').subscribe((data) => {

            const leaveDetail = (data.data.leaves !== undefined) ? data.data.leaves : [];
            this.addLeaveForm.setValue({
                //  company_id:leaveDetail.company_id,
                //  client_id:leaveDetail.client_id,
                holiday_name: leaveDetail.holiday_name,
                state_id: leaveDetail.state_id,
                holiday_desc: leaveDetail.holiday_desc,
                holiday_date: leaveDetail.holiday_date,
                type: leaveDetail.type,
                holiday_id: leaveDetail.holiday_id,
                client_id: leaveDetail.client_id,
                company_id: leaveDetail.company_id,
                leave_plan_id: leaveDetail.leave_plan_id
            });

        }, (err) => {
            this.toastr.error('Something went wrong!', 'Try Again');
        }
    );
}
  // searchName(val) {
  //   this.rows.splice(0, this.rows.length);
  //   let temp = this.srch.filter(function (d) {
  //     val = val.toLowerCase();
  //     return d.holiday_name.toLowerCase().indexOf(val) !== -1 || d.leave_day_name.toLowerCase().indexOf(val) !== -1 || d.holiday_desc.toLowerCase().indexOf(val) !== -1 || d.holiday_date.toLowerCase().indexOf(val) !== -1
  //   });
  //   this.rows.push(...temp);
  // }
  ngAfterViewInit(): void {

  }

  // dropdown
  public dropDown(){
    const params: any = {};
    params.client_id = this.authLeaveData.client_id;
    params.company_id = this.authLeaveData.company_id;
    this.apiModulesService.list('admin/leavemanagement/company/holidays/dropDown', params).subscribe((data)=>{
      this.stateDropDown = data.data.leaves
      this.stateID=this.stateDropDown[0].state_id !=undefined ?this.stateDropDown[0].state_id:null;
      this.getLeaveHoliday();

    },(err:any)=>{
      this.spinnerService.raiseDataEmitterEvent('off');

    })
  }

  changeViewGridToTabs(leaveplanId: number, clientId: number, companyId: number) {
    this.editedLeaveData.client_id = clientId;
    this.editedLeaveData.company_id = companyId;
    this.editedLeaveData.holiday_id = leaveplanId;
    this.showTabs = true;
}
changeTabsViewToGrid() {
  this.showTabs = false;
  this.activeIndex = 0;
  this.getLeaveHoliday();
}
changeTabsIndex(index) {
  this.activeIndex = index;
}
getAcronym(str: any) {
  if (str != null) {
      let matches = str.match(/\b(\w)/g); // ['J','S','O','N']
      matches = matches.slice(0, 2);
      const acronym = matches.join(''); // JSON
      return acronym;
  }
}
parentFun(){

  this.parenFun.emit(this.tabToGrid)
}
getBgColors(str: any) {
  if (str != null) {
      const min = 0;
      const max = 15;
      const index = this.findAlphapositions(str, 1);
      return this.nameBgColors[index];
  }
}
  findAlphapositions(str:any, n:any)
  {
    var NUM = 31;

    return str[0].charCodeAt(0) & NUM;
  }
}
