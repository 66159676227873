import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { SpinnerService } from '../../../../../snipper/spinner.service';
import { ApiModulesService } from 'src/app/all-modules/api-modules.service';
import { Table } from 'primeng/table';
import { jsPDF } from "jspdf";
@Component({
  selector: 'app-tax-computation',
  templateUrl: './tax-computation.component.html',
  styleUrls: ['./tax-computation.component.css']
})
export class TaxComputationComponent implements OnInit {
  awsLoginImages: any;
  taxComputationData = [];
  employeeData = [];
  companyDetails = [];
  componentDetails = [];
  taxDetails = [];
  taxMonth: any;
  taxYear: any;
  clientID = localStorage.getItem('client_id');
  companyID = localStorage.getItem('company_id');
  employeeID = localStorage.getItem('employee_id');
  invesDecId: any;
  taxComputationInvData: any = [];
  investmentDeclarationData: any = [];
  hraDetailsData: any = [];
  lossofPropertyData: any = [];
  lessExcemptionData: any;
  previousEmployerData: any;
  previousNewEmployee = 'false';
  financialStartMonthYear: any;
  financialEndMonthYear: any;
  constructor(private spinnerService: SpinnerService, private apiModulesService: ApiModulesService) { }
  ngOnInit() {
    this.spinnerService.raiseDataEmitterEvent('on');
    this.getTaxData();

  }

 
  getTaxData() {
    this.spinnerService.raiseDataEmitterEvent('on');
    const params: any = {};
    params.client_id = this.clientID;
    params.company_id = this.companyID;
    params.employee_id = this.employeeID;
    this.apiModulesService.list('employee-tabs/IND-employee-payroll/tax-computation/list', params).subscribe((data: any) => {
      this.taxComputationData = data.data.tax_computation != undefined ? data.data.tax_computation : [];
      this.employeeData = this.taxComputationData[0] != undefined ? this.taxComputationData[0] : [];
      this.componentDetails = this.taxComputationData[1] != undefined ? this.taxComputationData[1] : [];
      this.taxDetails = this.taxComputationData[2] != undefined ? this.taxComputationData[2] : [];
      this.companyDetails = this.taxComputationData[3] != undefined ? this.taxComputationData[3] : [];
      this.awsLoginImages = this.companyDetails[0].profile_img_url != null && this.companyDetails[0].profile_img_url != undefined ? 'data:image/png;base64,' + this.companyDetails[0].profile_img_url : null;
      this.spinnerService.raiseDataEmitterEvent('off');
      let date = new Date(this.employeeData[0].current_year + '-' + this.employeeData[0].current_month);
      this.taxMonth = date.toLocaleString('default', { month: 'long' });
      this.taxYear = this.employeeData[0].current_year;
      this.invesDecId = this.taxComputationData[0].emp_inv_dec_id;
      this.investmentDeclarationData = this.taxComputationData[4];
      this.lossofPropertyData = this.taxComputationData[5];
      this.hraDetailsData = this.taxComputationData[6];
      this.lessExcemptionData = this.taxComputationData[7];
      this.previousEmployerData = this.taxComputationData[8];
      this.previousNewEmployee = this.taxComputationData[0].previous_emp_show;



    }, (err: any) => {
      this.spinnerService.toasterError(err); 
    });
  }

  Convert_HTML_To_PDF() {
    const doc = new jsPDF();
    // Source HTMLElement or a string containing HTML.
    const elementHTML: any = document.querySelector('#taxContentToPrint');
    doc.html(elementHTML, {
      callback: function (doc) {
        // Save the PDF
        // doc.addPage('1','p')
        for (let i = doc.getNumberOfPages(); i != length; i--) {
          doc.deletePage(i);
        }

        doc.setLineHeightFactor(10);
        doc.setFontSize(20);
        doc.setLineHeightFactor(10);
        // doc.save(`taxComputation ${this.taxMonth}-${this.taxYear}.pdf`);
        doc.save(`taxComputation.pdf`);
      },
      margin: [10, 10, 10, 10],
      autoPaging: 'slice',
      x: 0,
      y: 10,
      width: 190, // target width in the PDF document
      windowWidth: 1100 // window width in CSS pixels

    });
  }








}

