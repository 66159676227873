import { Component, OnInit } from '@angular/core';
import { ApiModulesService } from 'src/app/all-modules/api-modules.service';
import { SpinnerService } from 'src/app/snipper/spinner.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
  FormArray,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-arrears',
  templateUrl: './arrears.component.html',
  styleUrls: ['./arrears.component.css']
})
export class ArrearsComponent implements OnInit {
  ClientDropDown: any;
  ClientID: any;
  public addArrears: FormGroup;
  CompanyDropDown: any;
  CompanyID: any;
  PaygroupDropDown: any;
  public ShowForm: any = false;
  PaygroupID: any;
  financialDropdownData: any;
  financial_date_id: any;
  dropdownSettings: IDropdownSettings;
  get_list_drop_down: any;
  arrearcalculationDropdownData: any;
  financial_date_ids: any;
  constructor(private apiModuleService: ApiModulesService,
    private spinnerService: SpinnerService, private formBuilder: FormBuilder, private toastr: ToastrService,) {
  }
  ngOnInit(): void {
    this.spinnerService.raiseDataEmitterEvent('off');
    this.GetClientDropDown();
    // this.gridLoad();
    this.addArrears = this.formBuilder.group({
      financial_date_id: ['', [Validators.required]],
      financial_date_ids: ['', [Validators.required]],
      remarks: ['', [Validators.required]],
      arrear_info_id: ['']
    });
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'financial_date_id',
      textField: 'financial_dates',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
    };
  }
  /* client DropDown codes*/
  public GetClientDropDown() {
    this.apiModuleService.get('DropDown/client').subscribe((data) => {
      this.ClientDropDown = data.data.client;
      this.ClientID = data.data.client[0].client_id;
      if (this.ClientDropDown.length !== 0) {
        setTimeout(() => {
          this.ClientID = data.data.client[0].client_id;
          this.GetCompanyDropDown();
        }, 100)
      }
    })
  }

  public GetCompanyDropDown() {
    const params: any = {};
    params.client_id = this.ClientID;
    this.apiModuleService.list('DropDown/company', params).subscribe((data) => {
      this.CompanyDropDown = data.data.company != undefined ? data.data.company : [];
      if (this.CompanyDropDown.length !== 0) {
        setTimeout(() => {
          this.CompanyID = this.CompanyDropDown[0].company_id;
          this.GetpaygroupDropDown();
        }, 100)
      }
      else {
        this.CompanyID = null;
      }
    }
    )
  }

  /* client DropDown codes*/
  public GetpaygroupDropDown() {
    const params: any = {};
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    this.apiModuleService.list('DropDown/paygroups', params).subscribe((data) => {
      this.PaygroupDropDown = data.data.paygroup;
      this.PaygroupID = data.data.paygroup[0].pay_group_id;
      this.gridLoad();
      this.payDatesDropDown();
      this.arrearsCalculationDropdown();
    })
  }
  payDatesDropDown() {
    const params: any = {};
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    this.apiModuleService.list('admin/assign-arrear-months/dropdown', params).subscribe((data) => {
      this.financialDropdownData = data.data.arrear_months;
      setTimeout(() => {
        this.financial_date_id = data.data.arrear_months?.[0]?.financial_date_id ?? null;
        if (this.financial_date_id != null) {
        } else {
          this.spinnerService.raiseDataEmitterEvent('off');
        }
      }, 100)
    }, (err: any) => {
      this.spinnerService.toasterError(err);
    })
  }
  arrearsCalculationDropdown() {
    const params: any = {};
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    this.apiModuleService.list('admin/assign-arrear-months/dropdown1', params).subscribe((data) => {
      this.arrearcalculationDropdownData = data.data.arrear_months;
      setTimeout(() => {
        this.financial_date_ids = data.data.arrear_months?.[0]?.financial_date_id ?? null;
        if (this.financial_date_id != null) {
        } else {
          this.spinnerService.raiseDataEmitterEvent('off');
        }
      }, 100)
    }, (err: any) => {
      this.spinnerService.toasterError(err);
    })
  }
  public Submit() {
    if (!this.addArrears.valid) {
      this.addArrears.markAllAsTouched();
      return;
    }
    const params: any = {}
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    params.pay_group_id = this.PaygroupID;
    params.financial_data = this.addArrears.get('financial_date_id').value;
    params.arrear_cal_dates = this.addArrears.get('financial_date_ids').value;
    const financial_date_id_length = params.financial_data.length;
    params.no_of_months =  financial_date_id_length
    params.remarks = this.addArrears.get('remarks').value;
    this.apiModuleService.add(params, 'admin/assign-arrear-months/add',).subscribe((data) => {
      this.gridLoad();
      ($('#add_popup') as any).modal('hide');
      this.addArrears.reset();
      this.toastr.success('User added successfully...!', 'Success');
    }, (err) => {
      this.toastr.error('Something went wrong!', 'Try Again');
    }
    );
  }
  public gridLoad() {
    this.spinnerService.raiseDataEmitterEvent('on');
    const params: any = {}
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    params.pay_group_id = this.PaygroupID;
    this.apiModuleService.add(params, 'admin/assign-arrear-months/list',).subscribe((data) => {
    this.get_list_drop_down = (data.data.arrear_months != undefined) ? data.data.arrear_months : [];
    this.spinnerService.raiseDataEmitterEvent('off');
    }, (err) => {
      this.get_list_drop_down = [];
      this.spinnerService.raiseDataEmitterEvent('off');            let errors = err.error.data.arrear_months;
      errors = errors.replace('SQLSTATE[42000]: [Microsoft][ODBC Driver 17 for SQL Server][SQL Server]', 'Error: ')
      this.toastr.error(errors, 'TryAgain');
  }
    );
  }
  // manually rendering Data table
  onItemSelect(item: any) {
  }

  onItem(item: any) {
  }

  onSelectAll(items: any) {
  }
  public resetForm() {
    this.addArrears.reset();
  }

  showAddForm() {
    this.ShowForm = true;
    this.resetForm();
  }
  showList() {
    this.ShowForm = false;
  }
  public edit(params) {
    this.addArrears.patchValue({
      remarks: params.remarks,
      financial_date_id : params.financial_data,
      arrear_info_id : params.arrear_info_id,
      financial_date_ids : params.arrear_cal_dates,
    });
  }

  // fn(){
  //   this.addArrears.patchValue({
  //     client_id:'',
  //     company_id:'',
  //     employee_id:''
  // });
  // this.companyDropDown=[];
  // this.employeeDropDown=[];
  // }

}
