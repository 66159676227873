// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content {
	padding: 30px;
}
.inactive{
	display: none !important;
}
table.dataTable{
	max-width: 100% !important;
}


.formContainer{
	padding-left: 20px;
    padding-right: 20px;
}

.padding-left-grid{
padding-left:23px;
}
.statusactive{
	font-size: 30px;
}`, "",{"version":3,"sources":["webpack://./src/app/all-modules/employees/skills/skills.component.css"],"names":[],"mappings":"AAAA;CACC,aAAa;AACd;AACA;CACC,wBAAwB;AACzB;AACA;CACC,0BAA0B;AAC3B;;;AAGA;CACC,kBAAkB;IACf,mBAAmB;AACvB;;AAEA;AACA,iBAAiB;AACjB;AACA;CACC,eAAe;AAChB","sourcesContent":[".content {\r\n\tpadding: 30px;\r\n}\r\n.inactive{\r\n\tdisplay: none !important;\r\n}\r\ntable.dataTable{\r\n\tmax-width: 100% !important;\r\n}\r\n\r\n\r\n.formContainer{\r\n\tpadding-left: 20px;\r\n    padding-right: 20px;\r\n}\r\n\r\n.padding-left-grid{\r\npadding-left:23px;\r\n}\r\n.statusactive{\r\n\tfont-size: 30px;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
