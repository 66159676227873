import {Component, OnInit, Input, ViewChild, AfterViewInit, OnDestroy, Output, EventEmitter} from '@angular/core';
import {ApiModulesService} from '../../api-modules.service';
import {ToastrService} from 'ngx-toastr';
import {FormGroup, FormBuilder, Validators, FormArray, FormControl} from '@angular/forms';
import {documentservice} from '../../documents.sevice';
import {EmployeeExperienceComponent} from '../employee-experience/employee-experience.component';
import {DatePipe} from '@angular/common';
import { Table } from 'primeng/table';
import { SpinnerService } from 'src/app/snipper/spinner.service';

@Component({
    selector:  'app-experience-document',
    templateUrl:'./experience-documents.component.html',
    styleUrls:['./experience-documents.component.css']
})
export class ExperienceDocumentsComponent extends EmployeeExperienceComponent implements OnDestroy,OnInit,AfterViewInit {
   experienceDocumentForm:FormGroup;
    dropDown=[];
    dropDownExperience=[];
    dropDownDocumentType=[];
    datePickerRequired=false;
    // user_type=localStorage.getItem('user_type');
    searchKeyword: string = '';
    public pipe = new DatePipe('en-US');
    doc_year:any;
    doc_month:any;
    statuses=[];
    loading: boolean = false;
    tabToGrid=false;
    @Output() parenFun: EventEmitter<any> = new EventEmitter();
    nameBgColors: any = ['bg-blue-dim', 'bg-blue-dim', 'bg-azure-dim', 'bg-indigo-dim', 'bg-info-dim', 'bg-purple-dim', 'bg-pink-dim', 'bg-orange-dim', 'bg-teal-dim', 'bg-primary-dim', 'bg-success-dim', 'bg-warning-dim', 'bg-danger-dim', 'bg-secondary-dim', 'bg-dark-dim', 'bg-gray-dim', 'bg-blue-dim', 'bg-azure-dim', 'bg-indigo-dim', 'bg-info-dim', 'bg-purple-dim', 'bg-pink-dim', 'bg-orange-dim', 'bg-teal-dim', 'bg-primary-dim', 'bg-success-dim', 'bg-warning-dim', 'bg-danger-dim', 'bg-secondary-dim', 'bg-dark-dim', 'bg-gray-dim'];
    nameBorderColors: any = ['bg-outline-primary', 'bg-outline-success', 'bg-outline-info', 'bg-outline-gray', 'bg-outline-warning', 'bg-outline-dark', 'bg-outline-light', 'bg-outline-secondary', 'bg-outline-danger'];
    constructor(
        public apiModelService: ApiModulesService,
        public toaster: ToastrService,
        public documentService: documentservice,
        public formBuilder: FormBuilder,   public spinnerService: SpinnerService) {
        super(apiModelService,toaster,documentService,formBuilder,spinnerService);
    }
    ngOnInit(): void {
      this.spinnerService.raiseDataEmitterEvent('on');
        this.getExperience();
        this.experienceDropDown();
        this.statuses = [
          { label: 'Active', value: 'active' },
          { label: 'Inactive', value: 'inactive' },
      ];
        this.experienceDocumentForm=this.formBuilder.group({
            employee_experience_id:['',[Validators.required]],
            document_type:['',[Validators.required]],
            doc_file_path:['',[]],
            old_path:['',[]],
            doc_month:[''],
            doc_year:[''],
            doc_file_name:[''],
            experience_document_id:['']
        })
    }
    ngAfterViewInit(): void {

    }

    ngOnDestroy(): void {
        // Do not forget to unsubscribe the event

    }
    public getExperience(){
      this.spinnerService.raiseDataEmitterEvent('on');
        this.ShowForm = false;
        const params: any = {};
        params.client_id = this.authEmployeeData.client_id;
        params.company_id = this.authEmployeeData.company_id;
        params.employee_id = this.authEmployeeData.employee_id;

        this.apiModelService.list('admin/experienceDocuments/list', params).subscribe((data) => {

                this.experienceData = (data.data.experience_documents != undefined) ? data.data.experience_documents : [];
                this.spinnerService.raiseDataEmitterEvent('off');



        }, (err) => {
            this.experienceData = [];
            this.spinnerService.toasterError(err);
        });
    }
    changeStatus(Status: any, experience) {
        const params: any = {};
        params.employee_id = experience.employee_id,
            params.client_id = experience.client_id,
            params.company_id = experience.company_id,
            params.experience_document_id = experience.experience_document_id,

        this.apiModelService.edit(params, 'admin/experienceDocuments/' + Status).subscribe((data) => {
                this.getExperience();
                ($('#employeedocuments-status') as any).modal('hide');
                this.toaster.success('Status Changed Successfully!', 'Success');
            }, (err) => {
              this.spinnerService.toasterError(err);
            }
        );
    }
    onEditExperience(experience){
        this.resetForm();
        let date=null;
        if(experience.doc_month != null &&experience.doc_year != null ){
             date=experience.doc_year.concat(-experience.doc_month);
        }
        const DateOfBirth = this.pipe.transform(
            date,
            'yyyy-MM'
        );
        this.experienceDocumentForm.setValue({
                employee_experience_id:experience.employee_experience_id,
                document_type:experience.document_type,
                doc_file_path:null,
                old_path:experience.doc_file_path,
                doc_month:DateOfBirth,
                doc_year:experience.doc_year,
                doc_file_name:experience.doc_file_name,
                experience_document_id:experience.experience_document_id
            })

    }
    parentFun(){

        this.parenFun.emit(this.tabToGrid)
    }
    experienceDropDown(){
        const params: any = {};
        params.client_id = this.authEmployeeData.client_id;
        params.company_id = this.authEmployeeData.company_id;
        params.employee_id = this.authEmployeeData.employee_id;

        this.apiModelService.edit(params, 'admin/experienceDocuments/dropDown').subscribe((data) => {
            this.dropDown=data.data.experience_documents != undefined? data.data.experience_documents :[];
            this.dropDownExperience=data.data.experience_documents[0] != undefined? data.data.experience_documents[0] :[];
            this.dropDownDocumentType=data.data.experience_documents[1] != undefined? data.data.experience_documents[1] :[];
            }, (err) => {
              this.spinnerService.toasterError(err);
            }
        );
    }
    datePickersEnabled(value){
        if(value==='Payslips'||value==='Bank Statement'){
            this.datePickerRequired=true;
        }else{
            this.experienceDocumentForm.patchValue({
                doc_year:null,
                doc_month:null
            })
            this.datePickerRequired=false;
        }
    }
    fileEvent(e) {
        this.filedata = e.target.files[0];
    }
    addExperienceDocuments(){
      if (!this.experienceDocumentForm.valid) {

            this.experienceDocumentForm.markAllAsTouched();
            return;
        }
        this.spinnerService.raiseDataEmitterEvent('on');
        if(this.experienceDocumentForm.value.doc_month!=null && this.experienceDocumentForm.value.doc_month!=''){
            const text=this.experienceDocumentForm.value.doc_month;
            const seprator=text.toString().split('-');
            this.doc_year=seprator[0];
             this.doc_month=seprator[1];
        }
        const myFormData = new FormData();
        myFormData.append('doc_file_path', this.filedata);
        myFormData.append('client_id', this.authEmployeeData.client_id);
        myFormData.append('company_id', this.authEmployeeData.company_id);
        myFormData.append('employee_no', this.authEmployeeData.employee_no);
        myFormData.append('employee_id', this.authEmployeeData.employee_id);
        myFormData.append('doc_year', this.doc_year);
        myFormData.append('doc_month', this.doc_month);
        myFormData.append('file_name', this.experienceDocumentForm.value.doc_file_name);
        myFormData.append('old_path', this.experienceDocumentForm.value.old_path);
        myFormData.append('document_type', this.experienceDocumentForm.value.document_type);
        myFormData.append('employee_experience_id', this.experienceDocumentForm.value.employee_experience_id);
        const experienceId = this.experienceDocumentForm.get('experience_document_id').value;
        if (experienceId != undefined && experienceId != '') {

            myFormData.append('experience_document_id', this.experienceDocumentForm.value.experience_document_id);
            this.documentService.post('api/admin/experienceDocuments/update', myFormData).subscribe((data) => {
                this.toaster.success('Successfully update document!', 'Success');
                /* Image Post Request */
                this.filedata = null;
                ($('#add_experience_documents') as any).modal('hide');
                this.getExperience();
            }, (err) => {
                this.filedata = null;
                this.spinnerService.toasterError(err);
            });
        }
        else{
            this.documentService.post('api/admin/experienceDocuments/add', myFormData).subscribe((data) => {
                this.toaster.success('Successfully update document!', 'Success');
                /* Image Post Request */
                ($('#add_experience_documents') as any).modal('hide');
                this.filedata = null;
                this.getExperience();
            }, (err) => {
                this.filedata = null;
                this.spinnerService.toasterError(err);
            });
        }

    }
    resetForm(){
        this.experienceDocumentForm.reset();
        this.filedata = null;
        this.doc_year=null;
        this.doc_month=null;
    }
    downloadDocuments(experience){

        const params :any={};
        params.client_id = this.authEmployeeData.client_id;
        params.company_id = this.authEmployeeData.company_id;
        params.employee_id = this.authEmployeeData.employee_id;
        params.document_name=experience.doc_file_name;
        params.experience_document_id = experience.experience_document_id;
        params.document_path=experience.doc_file_path;
        this.apiModelService.list('admin/experienceDocuments/download',params).subscribe((data)=>{
            const docDetails=data.data.experience_documents;
            this.documentService.DownloadExcel(docDetails.doc_name,docDetails.doc_url);
        }, (err) => {
          this.spinnerService.toasterError(err);
        });
    }

    clear(table: Table) {
      table.clear();
  }

  getSeverity(status: string) {
      switch (status.toLowerCase()) {
          case 'inactive':
              return 'danger';

          case 'active':
              return 'success';
      }
  }
  callStatusModel(url_name:any,data:any,actionName:string){
    this.statusApi=url_name;
    this.statusValues=data;
}
}
