// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `p-dropdown {
  min-width: 225px;
  max-width: 100%;
  height: 32px;
  font-family: "Open_Sans" !important;
  font-size: 14px;
  font-weight: normal;
  line-height: 22px;
  letter-spacing: 0.4px;
  text-align: left;
  border: 1px solid #D9D9D9;
  border-radius: 3px !important;
  width: 19%;
}
`, "",{"version":3,"sources":["webpack://./src/app/all-modules/ess-tabs/IND-ess/IND-employee-hrms/manager-page/payrollreports/draft/draft.component.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,eAAe;EACf,YAAY;EACZ,mCAAmC;EACnC,eAAe;EACf,mBAAmB;EACnB,iBAAiB;EACjB,qBAAqB;EACrB,gBAAgB;EAChB,yBAAyB;EACzB,6BAA6B;EAC7B,UAAU;AACZ","sourcesContent":["p-dropdown {\r\n  min-width: 225px;\r\n  max-width: 100%;\r\n  height: 32px;\r\n  font-family: \"Open_Sans\" !important;\r\n  font-size: 14px;\r\n  font-weight: normal;\r\n  line-height: 22px;\r\n  letter-spacing: 0.4px;\r\n  text-align: left;\r\n  border: 1px solid #D9D9D9;\r\n  border-radius: 3px !important;\r\n  width: 19%;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
