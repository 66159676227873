import { Component, OnInit } from '@angular/core';
import { SpinnerService } from 'src/app/snipper/spinner.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
@Component({
  selector: 'app-user-manual',
  templateUrl: './user-manual.component.html',
  styleUrls: ['./user-manual.component.css']
})
export class UserManualComponent implements OnInit{
  public sanitizedUrl: SafeResourceUrl;
  constructor( private spinnerService: SpinnerService,private sanitizer: DomSanitizer) {
  }

  ngOnInit() {
    this.spinnerService.raiseDataEmitterEvent('off');
    const url = 'assets/img/Azatecon_User Guide_Ver 1_Jan24.pdf';  // Replace with your desired URL
    this.sanitizedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }



}
