// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.apply-leave-btn{
  width: max-content;
  box-shadow: inset 0.2em 0.2em 0.2em 0 rgba(255,255,255,0.5), inset -0.2em -0.2em 0.2em 0 rgba(10, 192, 247,0.5);

}
.popup-control{
  width:30%;
  position: relative;
  left: 33px;
  top: -28px;
}
.label-hour{
padding-left: 5px;
}

.bank-name{
  width:100%;
}
.card-shadow {
	border: 1px solid #ededed;
	box-shadow: 4px 6px 7px rgba(10, 142, 250, 0.452) !important;
	margin-bottom: 30px;
}
`, "",{"version":3,"sources":["webpack://./src/app/all-modules/ess-tabs/IND-ess/IND-employee-hrms/manager-page/timemanager/time-manager.component.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,+GAA+G;;AAEjH;AACA;EACE,SAAS;EACT,kBAAkB;EAClB,UAAU;EACV,UAAU;AACZ;AACA;AACA,iBAAiB;AACjB;;AAEA;EACE,UAAU;AACZ;AACA;CACC,yBAAyB;CACzB,4DAA4D;CAC5D,mBAAmB;AACpB","sourcesContent":[".apply-leave-btn{\r\n  width: max-content;\r\n  box-shadow: inset 0.2em 0.2em 0.2em 0 rgba(255,255,255,0.5), inset -0.2em -0.2em 0.2em 0 rgba(10, 192, 247,0.5);\r\n\r\n}\r\n.popup-control{\r\n  width:30%;\r\n  position: relative;\r\n  left: 33px;\r\n  top: -28px;\r\n}\r\n.label-hour{\r\npadding-left: 5px;\r\n}\r\n\r\n.bank-name{\r\n  width:100%;\r\n}\r\n.card-shadow {\r\n\tborder: 1px solid #ededed;\r\n\tbox-shadow: 4px 6px 7px rgba(10, 142, 250, 0.452) !important;\r\n\tmargin-bottom: 30px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
