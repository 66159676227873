import { Component } from '@angular/core';
import {SpinnerService} from "../../../../../snipper/spinner.service";

@Component({
  selector: 'app-monthinput',
  templateUrl: './monthinput.component.html',
  styleUrls: ['./monthinput.component.css']
})
export class MonthinputComponent {
  month = [
    {
      selected_value: 'August',
      selected_text: 'Month'
    },
    {
      selected_value: 'September',
      selected_text: 'Month'
    }

  ]
  year = [
    {
      selected_value: '2022',
      selected_text: 'Year'
    },
    {
      selected_value: '2023',
      selected_text: 'Year'
    }
  ]
  monthlist:any;
  monthinput:any;
  Employercount: any;
  Employeecount: any;
  Employerlist: any;
  Employeelist: any;
  filterData: any[] = [];
  nameBgColors:any = ["bg-blue-dim","bg-blue-dim","bg-azure-dim","bg-indigo-dim","bg-info-dim","bg-purple-dim","bg-pink-dim","bg-orange-dim","bg-teal-dim","bg-primary-dim","bg-success-dim","bg-warning-dim","bg-danger-dim","bg-secondary-dim","bg-dark-dim","bg-gray-dim","bg-blue-dim","bg-azure-dim","bg-indigo-dim","bg-info-dim","bg-purple-dim","bg-pink-dim","bg-orange-dim","bg-teal-dim","bg-primary-dim","bg-success-dim","bg-warning-dim","bg-danger-dim","bg-secondary-dim","bg-dark-dim","bg-gray-dim"];
  nameBorderColors:any = ["bg-outline-primary","bg-outline-success","bg-outline-info","bg-outline-gray","bg-outline-warning","bg-outline-dark","bg-outline-light","bg-outline-secondary","bg-outline-danger"];
  
  constructor(private spinnerService: SpinnerService) {
  }
  ngOnInit(): void {
    this.spinnerService.raiseDataEmitterEvent('off');
    this.monthlist=[
    {'Inputs_From':'Employer','Element':'Overtime','Included_In':'Salary','Input_value':'','Reference':'Type of Overtime','Amount':''},
    {'Inputs_From':'Employer','Element':'Shift Allowance','Included_In':'salary','Input_value':'','Reference':'Type of Allowance','Amount':''},
    {'Inputs_From':'Employer','Element':'Compensation Elements','Included_In':'salary','Input_value':'','Reference':'Compensation Value','Amount':''},
    {'Inputs_From':'Employer','Element':'Bonus','Included_In':'salary','Input_value':'','Reference':'Bonus Value','Amount':''},
    {'Inputs_From':'Employer','Element':'Incentives','Included_In':'salary','Input_value':'','Reference':'Incentives Value','Amount':''},
    {'Inputs_From':'Employee','Element':'ELSS','Included_In':'ESS Investment Declaration','Input_value':'','Reference':'','Amount':''},
    {'Inputs_From':'Employee','Element':'NPS','Included_In':'ESS Investment Declaration','Input_value':'','Reference':'','Amount':''},
    {'Inputs_From':'Employee','Element':'Life Insurance','Included_In':'ESS Investment Declaration','Input_value':'','Reference':'','Amount':''},
    {'Inputs_From':'Employee','Element':'Medical Insurance','Included_In':'ESS Investment Declaration','Input_value':'','Reference':'','Amount':''},
    {'Inputs_From':'Employee','Element':'Vehicle Allowance','Included_In':'ESS Flexi Benefit Plan','Input_value':'','Reference':'','Amount':''},
    {'Inputs_From':'Employee','Element':'Books and Periodicals','Included_In':'ESS Flexi Benefit Plan','Input_value':'','Reference':'','Amount':''},
    {'Inputs_From':'Employee','Element':'LTA','Included_In':'ESS Flexi Benefit Plan','Input_value':'','Reference':'','Amount':''},
    {'Inputs_From':'Employee','Element':'Vehicle Allowance','Included_In':'ESS Flexi Benefit Plan','Input_value':'','Reference':'','Amount':''},
    {'Inputs_From':'Employee','Element':'Books and Periodicals','Included_In':'ESS Flexi Benefit Plan','Input_value':'','Reference':'','Amount':''}
  ]

  this.monthinput=[
    {'Inputs_From':'Employer','Element':'Spouse Name','Input_Mode':'ERP Integration','Previous_Value':'NA','New_Value':'Lekshmi','Date_Of_Input':'1 AUG 2023'},
    {'Inputs_From':'Employer','Element':'Spouse Name','Input_Mode':'ERP Integration','Previous_Value':'NA','New_Value':'Pooja','Date_Of_Input':'31 JUL 2023'},
    {'Inputs_From':'Employer','Element':'Spouse Name','Input_Mode':'ERP Integration','Previous_Value':'NA','New_Value':'Preethi','Date_Of_Input':'1 AUG 2023'},
    {'Inputs_From':'Employer','Element':'Spouse Name','Input_Mode':'ERP Integration','Previous_Value':'NA','New_Value':'Madhu','Date_Of_Input':'17 JUL 2023'},
    {'Inputs_From':'Employer','Element':'Spouse Name','Input_Mode':'ERP Integration','Previous_Value':'NA','New_Value':'Keerthy','Date_Of_Input':'17 JUL 2023'},
    {'Inputs_From':'Employee','Element':'Aadhar Number','Input_Mode':'ESS','Previous_Value':'NA','New_Value':'424423653768','Date_Of_Input':'1 AUG 2023'},
    {'Inputs_From':'Employee','Element':'Pan Card','Input_Mode':'ESS','Previous_Value':'NA','New_Value':'EFDR1078J','Date_Of_Input':'1 AUG 2023'},
    {'Inputs_From':'Employee','Element':'Bank Name','Input_Mode':'ESS','Previous_Value':'Indian Bank','New_Value':'canara Bank','Date_Of_Input':'1 AUG 2023'},
    {'Inputs_From':'Employee','Element':'Bank Account No','Input_Mode':'ESS','Previous_Value':'578855563','New_Value':'365376890','Date_Of_Input':'1 AUG 2023'}
  ]
  const employerData = this.monthlist.filter(item => item.Inputs_From === 'Employer');
  const employeeData = this.monthlist.filter(item => item.Inputs_From === 'Employee');
  this.Employercount=employerData.length
  this.Employeecount=employeeData.length
  const employerlist = this.monthinput.filter(item => item.Inputs_From === 'Employer');
  const employeelist = this.monthinput.filter(item => item.Inputs_From === 'Employee');
  this.Employerlist=employerlist.length
  this.Employeelist=employeelist.length
  }
  getAcronym(str:any){
    var matches = str.match(/\b(\w)/g); // ['J','S','O','N']
    matches= matches.slice(0,2);
    var acronym = matches.join(''); // JSON
    return acronym;
}
getBgColors(str:any){
    var min = 0;
    var max = 15;
    var index = this.findAlphapositions(str,1);
    return this.nameBgColors[index];
}
findAlphapositions(str:any, n:any)
{
    var NUM = 31;

    return str[0].charCodeAt(0) & NUM;
}
}
