import { Component, OnInit } from '@angular/core';
import { ApiModulesService } from '../../api-modules.service';
import { AllModulesService } from "../../all-modules.service";
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators, ValidatorFn } from '@angular/forms';
import { SpinnerService } from 'src/app/snipper/spinner.service';
import { Table } from 'primeng/table';
import { documentservice } from '../../documents.sevice';

@Component({
  selector: 'app-companyannouncement',
  templateUrl: './companyannouncement.component.html',
  styleUrls: ['./companyannouncement.component.css']
})
export class CompanyannouncementComponent implements OnInit {


  rows: any;
  public activeIndex = 0;
  searchKeyword: string = '';
  public srch = [];
  search: any[];
  ClientDropDown = [];
  CompanyDropDown = [];
  public addAnnouncementForm: FormGroup
  public clientname = [];
  public totalRecords: any = 0;
  public ShowForm: any = false;
  loading = false;
  ClientID: any;
  CompanyID: any;
  nameBgColors: any = ["bg-blue-dim", "bg-blue-dim", "bg-azure-dim", "bg-indigo-dim", "bg-info-dim", "bg-purple-dim", "bg-pink-dim", "bg-orange-dim", "bg-teal-dim", "bg-primary-dim", "bg-success-dim", "bg-warning-dim", "bg-danger-dim", "bg-secondary-dim", "bg-dark-dim", "bg-gray-dim", "bg-blue-dim", "bg-azure-dim", "bg-indigo-dim", "bg-info-dim", "bg-purple-dim", "bg-pink-dim", "bg-orange-dim", "bg-teal-dim", "bg-primary-dim", "bg-success-dim", "bg-warning-dim", "bg-danger-dim", "bg-secondary-dim", "bg-dark-dim", "bg-gray-dim"];
  nameBorderColors: any = ["bg-outline-primary", "bg-outline-success", "bg-outline-info", "bg-outline-gray", "bg-outline-warning", "bg-outline-dark", "bg-outline-light", "bg-outline-secondary", "bg-outline-danger"];
  public dropdown = []
  statuses: { label: string; value: string; }[];
  statusApi: any;
  statusValues: any;
  companyAnnouncementData = [];
  filedata: any;

  constructor(private apiModulesService: ApiModulesService, private documentService: documentservice, private AllModulesService: AllModulesService, private toaster: ToastrService, private toastr: ToastrService, private formBuilder: FormBuilder, private spinnerService: SpinnerService,) { }

  ngOnInit() {
    this.spinnerService.raiseDataEmitterEvent('off');
    this.GetClientDropDown();
    this.addAnnouncementForm = this.formBuilder.group({
      announcements: ["", [Validators.required]],
      from_date: ["", [Validators.required]],
      to_date: ["", [Validators.required]],
      announcement_title: ["", [Validators.required]],
      image_path: [''],
      old_path: [''],
      announcement_id: ["", []],

    }, { validator: this.dateRangeValidator() });

    this.statuses = [
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ];
  }
  dateRangeValidator(): ValidatorFn {
    return (formGroup: FormGroup): { [key: string]: any } | null => {
      const fromDate = formGroup.get('from_date').value;
      const toDate = formGroup.get('to_date').value;

      if (fromDate && toDate && fromDate > toDate ) {
        return { 'dateRangeInvalid': true };
      }
      return null;
    };
  }
  showAddForm() {
    this.ShowForm = true;
    this.resetForm();
  }
  //Reset form
  public resetForm() {
    this.addAnnouncementForm.reset();
  }
  /* client DropDown codes*/
  public GetClientDropDown() {
    this.apiModulesService.get('DropDown/client').subscribe((data) => {
      this.ClientDropDown = data.data.client;
      this.ClientID = data.data.client[0].client_id;
      this.GetCompanyDropDown();

    })
  }

  /* company DropDown codes */
  public GetCompanyDropDown() {
    const params: any = {};
    params.client_id = this.ClientID;
    this.apiModulesService.list('DropDown/company', params).subscribe((data) => {
      this.CompanyDropDown = data.data.company != undefined ? data.data.company : [];
      if (this.CompanyDropDown.length !== 0) {
        setTimeout(() => {
          this.CompanyID = this.CompanyDropDown[0].company_id;
          this.getAnnouncement();
        }, 100)
      }
      else {
        this.CompanyID = null;

      }

    }
    )
  }

  public getAnnouncement() {
    this.spinnerService.raiseDataEmitterEvent('on');
    var params: any = {};
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    this.apiModulesService.list("admin/announcement/list", params).subscribe((data) => {
      this.companyAnnouncementData = data.data.company_announcement != undefined ? data.data.company_announcement : [];
      setTimeout(() => {
        this.spinnerService.raiseDataEmitterEvent('off');
      }, 100);
    }, (err) => {

      this.spinnerService.toasterError(err);

    });
  }
  onAddAnnouncement() {
    if (!this.addAnnouncementForm.valid) {
      this.addAnnouncementForm.markAllAsTouched();
      return;
    }
    var myFormData = new FormData();
    myFormData.append('client_id', this.ClientID);
    myFormData.append('company_id', this.CompanyID);
    myFormData.append('image_path', this.filedata);
    myFormData.append('announcements', this.addAnnouncementForm.get('announcements').value);
    myFormData.append('from_date', this.MonthYearDropDown(this.addAnnouncementForm.get('from_date').value));
    myFormData.append('to_date', this.MonthYearDropDown(this.addAnnouncementForm.get('to_date').value));
    myFormData.append('announcement_title', this.addAnnouncementForm.get('announcement_title').value);
    myFormData.append('announcement_id', this.addAnnouncementForm.get('announcement_id').value);
    myFormData.append('old_path', this.addAnnouncementForm.get('old_path').value);

    //   if (this.filedata != null && this.filedata != '') {
    // }


    this.documentService.post('api/admin/announcement/update', myFormData).subscribe((data) => {
      this.getAnnouncement();

      this.addAnnouncementForm.reset();
      this.filedata = null;
      ($("#add_announcement") as any).modal("hide");
      this.toastr.success('Successfully update announcement!', 'Success');
      /* Image Post Request */
      this.filedata = [];
      // this.url = []
      this.spinnerService.raiseDataEmitterEvent('off');

    }, (err) => {
      this.spinnerService.toasterError(err);


    });

  }

  /* File onchange event */
  fileEvent(e) {
    this.filedata = e.target.files[0];
  }
  onEditAnnouncement(announcement) {
    this.addAnnouncementForm.setValue({
      announcements: announcement.announcements,
      from_date: new Date(announcement.from_date.toString()),
      to_date: new Date(announcement.to_date),
      announcement_title: announcement.announcement_title,
      image_path: null,
      old_path: announcement.image_path,
      announcement_id: announcement.announcement_id,
    })
  }

  changeStatus(Status: any, data) {
    let params: any = {};
    params.client_id = this.ClientID;
    params.announcement_id = data.announcement_id,
      params.company_id = this.CompanyID;
    this.apiModulesService.edit(params, 'admin/announcement/' + Status).subscribe((data) => {
      this.getAnnouncement();
      ($('#announcement-status') as any).modal('hide');
      this.toaster.success('Status Changed Successfully!', 'Success')
    }, (err) => {
      this.spinnerService.toasterError(err);

    }
    );
  }
  clear(table: Table) {
    table.clear();
  }
  getSeverity(status: string) {
    switch (status.toLowerCase()) {
      case 'inactive':
        return 'danger';

      case 'active':
        return 'success';
    }
  }
  getAcronym(str: any) {
    var matches = str.match(/\b(\w)/g); // ['J','S','O','N']
    matches = matches.slice(0, 2);
    var acronym = matches.join(''); // JSON
    return acronym
  }
  getBgColors(str: any) {
    var min = 0;
    var max = 15;
    var index = this.findAlphapositions(str, 1);
    return this.nameBgColors[index];
  }
  findAlphapositions(str: any, n: any) {
    var NUM = 31;

    return str[0].charCodeAt(0) & NUM;
  }
  callStatusModel(url_name: any, data: any) {
    this.statusApi = url_name;
    this.statusValues = data;
  }
  public MonthYearDropDown(inputDateString) {
    var date = new Date(inputDateString);
    var day: any = date.getDate();
    var month: any = date.getMonth() + 1;
    var year: any = date.getFullYear();
    day = (day < 10 ? '0' : '') + day;
    month = (month < 10 ? '0' : '') + month;
    var formattedDate = month + '/' + day + '/' + year;
    return formattedDate;
  }
}
