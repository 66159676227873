import { AfterViewInit, Component, OnInit, ViewChild, OnDestroy } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import { ToastrService } from 'ngx-toastr';
import { Subject } from "rxjs";
import { ApiModulesService } from "../../api-modules.service";
import { DatePipe } from '@angular/common';
import { AllModulesService } from '../../all-modules.service';
import {documentservice} from '../../documents.sevice';
import { Table } from "primeng/table";
import {SpinnerService} from "../../../snipper/spinner.service";


@Component({
  selector: 'app-companyregistration',
  templateUrl: './company-registration.component.html',
  styleUrls: ['./company-registration.component.css']

})
export class CompanyRegistrationcomponent implements OnInit, OnDestroy, AfterViewInit {

  companyList = [];
  public pageNo = 1;
  public limit = 10;
  filedata: any;
  oldPath: any;
  public ShowForm: any = false;
  public totalRecords: any = 0;
  searchKeyword: string = '';
  addCompanyForm: FormGroup;
  public searchForm: FormGroup;
  dropdown: any;
  company_types: void;
  industry_types: any;
  parent_company_id: any;
  countriesList: any;
  statesList: any;
  citiesList: any;
  searchByColumn: any = '';
  searchValue: any = '';
  ClientID = localStorage.getItem('client_id');
  emailRegex =
    '^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$';
  mobileRegex = '^((\\+91-?)|0)?[0-9]{10,13}$';
  numberRegex = '^[a-zA-Z0-9]{6,10}$';
  selectedClientID: any;
  ClientDropDown: any;
  statuses=[];
  loading: boolean = false;
  public listHeaders = [
    {
        AliasName: "Name",
        FieldName: "Name"
    },{
        AliasName: "phone",
        FieldName: "Mobile"
    }, {
        AliasName: "Email",
        FieldName: "Email"
    }
];

    nameBgColors:any = ["bg-blue-dim","bg-blue-dim","bg-azure-dim","bg-indigo-dim","bg-info-dim","bg-purple-dim","bg-pink-dim","bg-orange-dim","bg-teal-dim","bg-primary-dim","bg-success-dim","bg-warning-dim","bg-danger-dim","bg-secondary-dim","bg-dark-dim","bg-gray-dim","bg-blue-dim","bg-azure-dim","bg-indigo-dim","bg-info-dim","bg-purple-dim","bg-pink-dim","bg-orange-dim","bg-teal-dim","bg-primary-dim","bg-success-dim","bg-warning-dim","bg-danger-dim","bg-secondary-dim","bg-dark-dim","bg-gray-dim"];
    nameBorderColors:any = ["bg-outline-primary","bg-outline-success","bg-outline-info","bg-outline-gray","bg-outline-warning","bg-outline-dark","bg-outline-light","bg-outline-secondary","bg-outline-danger"];
  statusApi: any;
  statusValue: any;
  statusValues: any;

    constructor(private apiModuleServices: ApiModulesService, private spinnerService: SpinnerService, private formBuilder: FormBuilder, private toastr: ToastrService,  private documentService: documentservice) {

  }
  ngOnInit(): void {
      this.spinnerService.raiseDataEmitterEvent('on');
    const that = this;
    this.GetClientDropDown();
    this.getCountries();
    this.addCompanyForm = this.formBuilder.group({

      client_id: ["", []],
      company_name: ["", [Validators.required]],
      company_admin_name: ["", [Validators.required]],
      company_type: ["", [Validators.required]],
      company_type_name: ["", [Validators.required]],
      industry_type_id: ["", [Validators.required]],
      parent_company_id: ["", [Validators.required]],
      business_type: ["", [Validators.required]],
      registration_number: ["", []],
      registration_date: ["", []],
      renewal_date: ["", []],
      hro_start_date: ["", []],
      country_id: ["", [Validators.required]],
      state_id: ["", [Validators.required]],
      city_id: ["", [Validators.required]],
      address_1: ["", [Validators.required]],
      address_2: ["", []],
      pincode: ["", [Validators.required]],
      mobile: ["", [Validators.required, Validators.pattern('[0-9]{10}')]],
      email: ["", [Validators.required]],
      email_notification: ["", [Validators.required]],
      cc_email_notification: [""],
      website: ["", []],
      landline: ["", [Validators.pattern('[0-9]{10}')]],
      profile_img_url: ["", []],
      company_id: [""],
      tan_number: ["", [Validators.required]],
      pan_number: ["", []],
      gst_number:["", []],
    })
    this.searchForm = this.formBuilder.group({
      column_name: ['', [Validators.required]],
      search_value: ['', [Validators.required]]
  })
  this.statuses = [
    { label: 'Active', value: 'active' },
    { label: 'Inactive', value: 'inactive' },
];
  }
  public CompanyList() {
      this.spinnerService.raiseDataEmitterEvent('on');
    this.ShowForm = false;
    const params: any = {};
    params.page = this.pageNo;
    params.limit = this.limit;
    params.client_id = this.selectedClientID;
    params.search_by = this.searchByColumn.toString();
    params.search_value = this.searchValue.toString();
    this.apiModuleServices.list('admin/company_registration/list', params).subscribe((data) => {

      this.companyList = (data.data.company != undefined) ? data.data.company : [];
      var totalRecords = (this.companyList[0] != undefined) ? this.companyList[0].total_records : 0;
      this.totalRecords = totalRecords;
        this.spinnerService.raiseDataEmitterEvent('off');
      this.loading=false;
    },(err:any)=>{
        this.spinnerService.raiseDataEmitterEvent('off');
    });
  }
  clientChange() {

    this.CompanyList();
    this.dropDown();
  }

  public GetClientDropDown() {
    this.apiModuleServices.get('DropDown/client').subscribe((data) => {


      this.ClientDropDown = data.data.client;
      this.selectedClientID = data.data.client[0].client_id;
      this.dropDown();

    },(err:any)=>{
        this.spinnerService.raiseDataEmitterEvent('off');
    });
  }
  public getCountries() {
    this.apiModuleServices.get("country/list").subscribe((data) => {
      const res: any = data;
      this.countriesList = (res.data.countries !== undefined) ? res.data.countries : [];

    },(err:any)=>{
        this.spinnerService.raiseDataEmitterEvent('off');
    });
  }

  public getStates() {

    var params: any = {};
    params.country_id = this.addCompanyForm.get('country_id').value;
    this.apiModuleServices.list("country/states", params).subscribe((data) => {

      this.statesList = (data.data.states !== undefined) ? data.data.states : [];
    });
  }

  public getCities() {
    const params: any = {};
    params.state_id = this.addCompanyForm.get('state_id').value;
    this.apiModuleServices.list("country/cities", params).subscribe((data) => {
      this.citiesList = (data.data.states !== undefined) ? data.data.states : [];
    });
  }
  clear(table: Table) {
    table.clear();
}

getSeverity(status: string) {
    switch (status.toLowerCase()) {
        case 'inactive':
            return 'danger';

        case 'active':
            return 'success';
    }
}
  showAddForm() {
    this.ShowForm = true;
    this.resetForm();
  }
  showList() {
    this.ShowForm = false;
  }
  //Reset form
  public resetForm() {
    this.addCompanyForm.reset();
  }
  dropDown() {
    var params: any = {};
    params.client_id = this.selectedClientID;
    this.apiModuleServices.list("admin/company_registration/dropdown", params).subscribe((data) => {

      this.dropdown = data.data.company;
      this.company_types = this.dropdown[0];
      this.industry_types = this.dropdown[1];
      this.parent_company_id = this.dropdown[2];
      this.CompanyList();
    },(err:any)=>{
        this.spinnerService.raiseDataEmitterEvent('off');
    });
  }
      /* File onchange event */
      fileEvent(e) {
        this.filedata = e.target.files[0];
    }
  onAddCompany() {
    if (!this.addCompanyForm.valid) {
      this.addCompanyForm.markAllAsTouched();
      return;
    }
    var myFormData = new FormData();
    myFormData.append('profile_img_url', this.filedata);
    myFormData.append('company_name', this.addCompanyForm.get('company_name').value);
    myFormData.append('company_admin_name', this.addCompanyForm.get('company_admin_name').value);
    myFormData.append('company_type', this.addCompanyForm.get('company_type').value);
    myFormData.append('company_type_name', this.addCompanyForm.get('company_type_name').value);
    myFormData.append('industry_type_id', this.addCompanyForm.get('industry_type_id').value);
    myFormData.append('parent_company_id', this.addCompanyForm.get('parent_company_id').value);
    myFormData.append('business_type', this.addCompanyForm.get('business_type').value);
    myFormData.append('registration_number', this.addCompanyForm.get('registration_number').value);
    myFormData.append('registration_date', this.addCompanyForm.get('registration_date').value);
    myFormData.append('renewal_date', this.addCompanyForm.get('renewal_date').value);
    myFormData.append('hro_start_date', this.addCompanyForm.get('hro_start_date').value);
    myFormData.append('country_id', this.addCompanyForm.get('country_id').value);
    myFormData.append('state_id', this.addCompanyForm.get('state_id').value);
    myFormData.append('city_id', this.addCompanyForm.get('city_id').value);
    myFormData.append('address_1', this.addCompanyForm.get('address_1').value);
    myFormData.append('address_2', this.addCompanyForm.get('address_2').value);
    myFormData.append('pincode', this.addCompanyForm.get('pincode').value);
    myFormData.append('mobile', this.addCompanyForm.get('mobile').value);
    myFormData.append('tan_number', this.addCompanyForm.get('tan_number').value);
    myFormData.append('pan_number', this.addCompanyForm.get('pan_number').value);
    myFormData.append('gst_number', this.addCompanyForm.get('gst_number').value);
    myFormData.append('email', this.addCompanyForm.get('email').value);
    myFormData.append('website', this.addCompanyForm.get('website').value);
    myFormData.append('landline', this.addCompanyForm.get('landline').value);
    myFormData.append('email_notification', this.addCompanyForm.get('email_notification').value);
    myFormData.append('cc_email_notification', this.addCompanyForm.get('cc_email_notification').value);
    myFormData.append('client_id', this.selectedClientID);
    // params.client_id = this.selectedClientID;


    const companyId = this.addCompanyForm.get('company_id').value;
    if ((companyId !== null && companyId !== '')) {
      myFormData.append('company_id' , this.addCompanyForm.get("company_id").value);
      myFormData.append('old_path', this.oldPath);

      this.documentService.post( 'api/admin/company_registration/update', myFormData) .subscribe((data) => {
        this.CompanyList();
        ($("#add_company") as any).modal("hide");
        this.addCompanyForm.reset();
        this.toastr.success("Company updated sucessfully...!", "Success");
      }, (err) => {
        let errors = err.error.data.company;
        errors = errors.replace('SQLSTATE[42000]: [Microsoft][ODBC Driver 17 for SQL Server][SQL Server]', 'Error: ')
        this.toastr.error(errors, 'TryAgain');
        }
      );
    }
    else {
      this.documentService.post('api/admin/company_registration/add', myFormData).subscribe((data) => {
        this.CompanyList();
        ($("#add_company") as any).modal("hide");
        this.addCompanyForm.reset();
        this.toastr.success("Company added sucessfully...!", "Success");
      }, (err) => {
        let errors = err.error.data.company;
        errors = errors.replace('SQLSTATE[42000]: [Microsoft][ODBC Driver 17 for SQL Server][SQL Server]', 'Error: ')
        this.toastr.error(errors, 'TryAgain');
        }
      );
    }
  }
  onEditcompany(item) {
    const params: any = {};
    params.client_id = this.selectedClientID;
    params.company_id = item.company_id;
    this.apiModuleServices.edit(params, 'admin/company_registration/edit').subscribe((data) => {
      const companyDetail = (data.data.company !== undefined) ? data.data.company : [];
      let resultArray=[]
      if(item && item.email_notification){
        const inputString = item.email_notification ;
      resultArray = inputString.split(',').map(item => item.trim());
      }

      let ccEmailArray = [];
      if (item && item.cc_email_notification) {
          const ccEmailInputString = item.cc_email_notification;
          ccEmailArray = ccEmailInputString.split(',').map(val => val.trim());
      }
      this.addCompanyForm.setValue({
        company_name: companyDetail.company_name,
        company_admin_name: companyDetail.company_admin_name,
        company_type: companyDetail.company_type,
        company_type_name: companyDetail.company_type_name,
        industry_type_id: companyDetail.industry_type_id,
        parent_company_id: companyDetail.parent_company_id,
        business_type: companyDetail.business_type,
        registration_number: companyDetail.registration_number,
        registration_date: companyDetail.registration_date,
        renewal_date: companyDetail.renewal_date,
        hro_start_date: companyDetail.hro_start_date,
        country_id: companyDetail.country_id,
        state_id: companyDetail.state_id,
        city_id: companyDetail.city_id,
        address_1: companyDetail.address_1,
        address_2: companyDetail.address_2,
        pincode: companyDetail.pincode,
        mobile: companyDetail.mobile,
        tan_number: companyDetail.tan_number,
        pan_number: companyDetail.pan_number,
        gst_number: companyDetail.gst_number,
        email: companyDetail.email,
        website: companyDetail.website,
        email_notification: resultArray,
        cc_email_notification: ccEmailArray,
        landline: companyDetail.landline,
        profile_img_url: null,
        client_id: this.selectedClientID,
        company_id:item.company_id
      });
      this.oldPath=companyDetail.profile_img_url;
      this.getStates();
      this.getCities();
      this.ShowForm = true;
    }, (err) => {
      let errors = err.error.data.company;
      errors = errors.replace('SQLSTATE[42000]: [Microsoft][ODBC Driver 17 for SQL Server][SQL Server]', 'Error: ')
      this.toastr.error(errors, 'TryAgain');
      }
    );
  }
  // Function to handle the input event for phone number field
  onPhoneNumberInput(event: Event) {
  const inputElement = event.target as HTMLInputElement;
  const phoneNumber = inputElement.value.replace(/\D/g, ''); // Remove all non-digit characters
  const truncatedPhoneNumber = phoneNumber.slice(0, 10); // Truncate to 10 digits if longer
  inputElement.value = truncatedPhoneNumber; // Update the input field with the cleaned phone number
  this.addCompanyForm.get('mobile').setValue(truncatedPhoneNumber); // Update the form control value
  }
  onLandlineInput(event:Event){
    const Element=event.target as HTMLInputElement;
    const landlineNumber = Element.value.replace(/\D/g, '');
    const truncatedlandlineNumber = landlineNumber.slice(0, 10);
    Element.value = truncatedlandlineNumber;
    this.addCompanyForm.get('landline').setValue(truncatedlandlineNumber);
  }
  changeStatus(Status: any, companyid: any, client_id) {
    const params: any = {};
    params.client_id = this.selectedClientID
    params.company_id = companyid;
    this.apiModuleServices.edit(params, 'admin/company_registration/' + Status).subscribe((data) => {
      this.CompanyList();
      ($('#company-status') as any).modal('hide');
      this.toastr.success('Company changed sucessfully...!', 'Success');
    }, (err) => {
      let errors = err.error.data.company;
      errors = errors.replace('SQLSTATE[42000]: [Microsoft][ODBC Driver 17 for SQL Server][SQL Server]', 'Error: ')
      this.toastr.error(errors, 'TryAgain');
      }
    );
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event

  }

  ngAfterViewInit(): void {

  }
  searchResetForm() {
    this.searchForm.reset();
    this.searchByColumn = '';
    this.searchValue = '';
    if (this.searchForm.value.column_name != '' || this.searchForm.value.search_value != '') {
        this.CompanyList();
    }
}
public getCompanyBySearch() {
  if (!this.searchForm.valid) {
      this.searchForm.markAllAsTouched();
      return;
  }
  // let table= $('#datatable').DataTable().clear().draw(true);
  // table.data()
  this.CompanyList();
}


    getAcronym(str:any){
        var matches = str.match(/\b(\w)/g); // ['J','S','O','N']
        matches= matches.slice(0,2);
        var acronym = matches.join(''); // JSON
        return acronym;
    }
    getBgColors(str:any){
        var min = 0;
        var max = 15;
        var index = this.findAlphapositions(str,1);
        return this.nameBgColors[index];
    }
    findAlphapositions(str:any, n:any)
    {
        var NUM = 31;

        return str[0].charCodeAt(0) & NUM;
    }
    callStatusModel(url_name:any,data:any,actionName:string){
      this.statusApi=url_name;
      this.statusValues=data;
  }

}
