import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild} from '@angular/core';
import { ApiModulesService } from '../../api-modules.service';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray, NgForm, } from "@angular/forms";
import { Subscription } from 'rxjs';

import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { Table } from 'primeng/table';
import { SpinnerService } from 'src/app/snipper/spinner.service';
declare const $: any;

@Component({
  selector: 'app-jobplan',
  templateUrl: './jobplan.component.html',
  styleUrls: ['./jobplan.component.css']
})
export class JobplanComponent implements OnInit,AfterViewInit,OnDestroy {



  public ShowForm: any = false;

 public jobplanData:any;
  addJobplanForm: FormGroup;
  editJobplanForm:FormGroup;
  auth_employee: any;
  subscription!:Subscription
  jobplanId: any;
  dropdown_effective_from: any;
  dropdown_job_name: any;
  dropdown: any;
  ClientDropDown = [];
  CompanyDropDown = [];
  ClientID=localStorage.getItem('client_id');
  CompanyID=localStorage.getItem('company_id');
  nameRegex=(/^-?(0|[1-9]\d*)?$/);
  coinameRegex=/^[a-zA-Z\s]*$/;
  addJobplancomponentForm: FormGroup;
  jobplancomponentData: any;
  componentdropdowns: any;
  componentdropdown: any;
  editJobplancomponentForm: FormGroup;
  plan_id: any;
  component_id: any;
  loading=false;
  component_value: any;
  job_component_id: any;
  job_client_id: any;
  job_employee_id: any;
  form: any;
  choose_plan_id: any;
  choose_plan_name: any;
  isDtInitialized: boolean = false;
  public totalRecords: any = 0;
  nameBgColors:any = ["bg-blue-dim","bg-blue-dim","bg-azure-dim","bg-indigo-dim","bg-info-dim","bg-purple-dim","bg-pink-dim","bg-orange-dim","bg-teal-dim","bg-primary-dim","bg-success-dim","bg-warning-dim","bg-danger-dim","bg-secondary-dim","bg-dark-dim","bg-gray-dim","bg-blue-dim","bg-azure-dim","bg-indigo-dim","bg-info-dim","bg-purple-dim","bg-pink-dim","bg-orange-dim","bg-teal-dim","bg-primary-dim","bg-success-dim","bg-warning-dim","bg-danger-dim","bg-secondary-dim","bg-dark-dim","bg-gray-dim"];
  nameBorderColors:any = ["bg-outline-primary","bg-outline-success","bg-outline-info","bg-outline-gray","bg-outline-warning","bg-outline-dark","bg-outline-light","bg-outline-secondary","bg-outline-danger"];
  statuses: { label: string; value: string; }[];
  constructor(private apiModulesService:ApiModulesService,
    private toastrService:ToastrService,
    private formBuilder:FormBuilder,
    private router:Router,
    private spinnerService: SpinnerService) {}

  ngOnInit(): void {
    this.GetClientDropDown();

     //Add
   this.addJobplanForm=this.formBuilder.group({
    company_id:["",[]],
    client_id:["",[]],
    employee_id: ["", []],
    plan_name: ["", [Validators.required,Validators.pattern(this.coinameRegex)]],
    job_id: ["", [Validators.required]],
    description: ["", [Validators.required]],
    ctc_amount: ["",[Validators.required,Validators.pattern(this.nameRegex)]],
    effective_from: ["", [Validators.required]],
});

// //Add
  this.addJobplancomponentForm=this.formBuilder.group({
   company_id:["",[]],
   client_id:["",[]],
   employee_id: ["", []],
   plan_name:["",[]],
   plan_details_id:["",[]],
   plan_id: ["", [Validators.required]],
   component_id: ["", [Validators.required]],
   component_value: ["", [Validators.required]],
 });

//edit
this.editJobplanForm=this.formBuilder.group({
  plan_id:["",[]],
  employee_id: ["", []],
  company_id: ["", []],
  client_id: ["", []],
  plan_name: ["", [Validators.required]],
  job_id: ["", [Validators.required]],
  description: ["", [Validators.required]],
  ctc_amount: ["",[Validators.required]],
  effective_from: ["", [Validators.required]],
});

// //edit job plan component
// this.editJobplancomponentForm=this.formBuilder.group({
//   company_id:["",[]],
//   client_id:["",[]],
//   employee_id: ["", []],
//   plan_id:["",[]],
//    });

this.statuses = [
  { label: 'Active', value: 'active' },
  { label: 'Inactive', value: 'inactive' },
];
  }

  ngAfterViewInit(): void {

}
rerender(): void {

}

//get job plan
  public getJobplan(){
    this.spinnerService.raiseDataEmitterEvent('on');
    var params: any={};
    params.page = 1;
    params.limit = 10;
    params.client_id= 1;
    params.company_id=1;
    params.employee_id=2;
    this.apiModulesService.list("admin/jobplan/list",params).subscribe ((data)=>{
this.jobplanData = (data.data.job_plan != undefined) ? data.data.job_plan : [];
this.loading = false;
var totalRecords = this.jobplanData.length;
this.totalRecords = totalRecords;
this.resetForm();
setTimeout(() => {
  this.spinnerService.raiseDataEmitterEvent('off');
}, 100);
}, (err) => {
this.jobplanData = [];
this.spinnerService.raiseDataEmitterEvent('off');
});
  }
  clear(table: Table) {
    table.clear();
  }

//add reset
  public resetForm() {
    this.addJobplanForm.reset();
}

  //add paycomponent
  public onAddjobplan() {
    if (!this.addJobplanForm.valid) {

      this.addJobplanForm.markAllAsTouched();
      return;
  }
    var params:any = {};
    params.plan_name = this.addJobplanForm.get('plan_name').value;
    params.job_id= this.addJobplanForm.get('job_id').value;
    params.description = this.addJobplanForm.get('description').value;
    params.ctc_amount = this.addJobplanForm.get('ctc_amount').value;
    params.effective_from = this.addJobplanForm.get('effective_from').value;
    params.record_status = 1;
    params.company_id=1;
    params.client_id=1;
    params.employee_id=2;

    this.apiModulesService.add(params,"admin/jobplan/add",).subscribe((data) => {
        ($("#add_jobplan")as any).modal("hide");
        this.getJobplan();
        this.addJobplanForm.reset();
        this.toastrService.success("Jobplan Added Sucessfully...!", "Success");
    },err=>{
      this.toastrService.error("Plan Name Already Exists...?","Failed");
    });
}

//Edit paycomponent
public onEditJobplan(plan_id: any) {
  var params:any = {};
  params.client_id=1;
  params.company_id=1;
  params.employee_id=2;
  params.plan_id = plan_id;

  this.apiModulesService.edit(params,"admin/jobplan/edit").subscribe((data) => {

      let jobplanDetail = data.data.job_plan;
      this.editJobplanForm.setValue({
        plan_id:jobplanDetail.plan_id,
        company_id:jobplanDetail.company_id,
        client_id:jobplanDetail.client_id,
        employee_id:jobplanDetail.employee_id,
        plan_name:jobplanDetail.plan_name,
        job_id:jobplanDetail.job_id,
        description:jobplanDetail.description,
        ctc_amount:jobplanDetail.ctc_amount,
        effective_from:jobplanDetail.effective_from,
      });
  });
}
//edit jobplan
public onEditChanges(){
  if (!this.editJobplanForm.valid) {

    this.toastrService.success("Jobplan Added Failled...!", "failed");
    this.editJobplanForm.markAllAsTouched();
    return;
}
  let params ={
    plan_id:this.editJobplanForm.value.plan_id,
    employee_id:this.editJobplanForm.value.employee_id,
    company_id:this.editJobplanForm.value.company_id,
    client_id:this.editJobplanForm.value.client_id,
    plan_name:this.editJobplanForm.value.plan_name,
    job_id:this.editJobplanForm.value.job_id,
    description:this.editJobplanForm.value.description,
    ctc_amount:this.editJobplanForm.value.ctc_amount,
    effective_from:this.editJobplanForm.value.effective_from,
  };

    this.apiModulesService.edit(params,'admin/jobplan/update').subscribe((data)=>{
      ($("#edit_jobplan")as any).modal("hide");
      this.getJobplan();

      this.toastrService.success("Jobplan Edited Sucessfully...!", "Success");
    },err=>{
      this.toastrService.error("Plan Name Already Exists...?","Failed");
  });
}
 //add reset
public importreset() {
  this.form.reset();
}

   //status change
   changeStatus(status:any,plan_id:any) {
    var params:any = {};
    params.employee_id =2;
    params.company_id = 1;
    params.client_id = 1;
    params.plan_id = plan_id;
      this.apiModulesService.edit(params,"admin/jobplan/"+status).subscribe((data) => {
        this.getJobplan();
        this.toastrService.success("Status Changed Sucessfully...!", "Success");
      }
      ,(err)=>{
        this.toastrService.error("Something went wrong!", "Try Again");
      }
      );
  }

  import(plan_id:any,plan_name){
    var params:any={}
    params.company_id=1;
    params.client_id=1;
    params.employee_id=1;
    params.login_user_id=1;
    params.plan_id=plan_id;
    this.apiModulesService.add(params,'admin/jobplancomponent/list').subscribe((data)=>{
    this.jobplancomponentData = data;
    this.choose_plan_id=plan_id;
    this.choose_plan_name=plan_name;



    });
    }

    public onAddjobplancomponent(f:NgForm) {
      var params: any = {};
      params.client_id=1;
      params.company_id=1;
      params.plan_id=this.choose_plan_id;
      params.employee_id=1;
      params.component=f.value;

        this.apiModulesService.add(params,"admin/jobplancomponent/add").subscribe((data) => {
          ($("#add_jobplancomponent") as any).modal("hide");

          this.toastrService.success("Jobplancomponent Added Sucessfully...!", "Success");
        }
        ,(err)=>{
          this.toastrService.error("Something went wrong!", "Try Again");
        }
        );
    }
    public editValue(
      jobplancompoentDetail: any) {
      this.addJobplancomponentForm.setValue({
        plan_details_id: jobplancompoentDetail.plan_details_id,
        plan_id: jobplancompoentDetail.plan_id,
        component_id: jobplancompoentDetail.component_id,
        component_value: jobplancompoentDetail.component_value,
      });
    };

    onSubmit(f: NgForm){
    }

//jobplan dropdown

getDropDown(){
  var params:any = {};
  params.client_id= this.ClientID;
  params.company_id=this.CompanyID;

  this.apiModulesService.list("admin/jobplan/dropdown",params).subscribe((data)=>{

    this.dropdown=data.data.job_plan;
  });

 }

ngOnDestroy(): void {

}

//jobplancomponent dropdown
getDropdown(){
  var params:any = {};
  params.client_id=this.ClientID;
  params.company_id=this.CompanyID;

  this.apiModulesService.list("admin/jobplancomponent/drop-down",params).subscribe((data)=>{
    this.componentdropdown=data.data.jobplancomponent;
  });
 }
  /* client DropDown codes*/
  public GetClientDropDown() {
    this.apiModulesService.get('DropDown/client').subscribe((data) => {
      this.ClientDropDown = data.data.client;

      this.ClientID = data.data.client[0].client_id;
      this.GetCompanyDropDown();
    })
  }
    /* company DropDown codes */
    public GetCompanyDropDown() {
      const params: any = {};
      params.client_id = this.ClientID;

      this.apiModulesService.list('DropDown/company', params).subscribe((data) => {
        this.CompanyDropDown = data.data.company;
        this.CompanyID = this.CompanyDropDown[0].company_id;
        this.getJobplan();
        this.getDropDown();
        this.getDropdown();
      }
      )
    }
    getAcronym(str:any){
      var matches = str.match(/\b(\w)/g); // ['J','S','O','N']
      matches= matches.slice(0,3);
      var acronym = matches.join(''); // JSON
      return acronym;
    }
    getBgColors(str:any){
      var min = 0;
      var max = 15;
      var index = this.findAlphapositions(str,1);
      return this.nameBgColors[index];
    }
    findAlphapositions(str:any, n:any)
    {
      var NUM = 31;

      return str[0].charCodeAt(0) & NUM;
    }
}
