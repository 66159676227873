import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiModulesService } from '../../api-modules.service';

import { HttpParams } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Subject } from "rxjs";
import { SpinnerService } from 'src/app/snipper/spinner.service';
import { Table } from 'primeng/table';
declare const $: any;

@Component({
  selector: 'app-job-details',
  templateUrl: './job-details.component.html',
  styleUrls: ['./job-details.component.css']
})
export class JobDetailsComponent implements OnInit, OnDestroy, AfterViewInit {



  public JobDetailsData = [];
  public listHeaders = [];
  public listHeadersgroup = [];
  public listHeadersgrade = [];
  public listHeadersfrequency = [];
  public listHeadersfunction = [];
  public PayFrequencyData = [];
  isDtInitialized = false;
  isDtInitializedgroup = false;
  isDtInitializedfunction = false;
  isDtInitializedfrequency = false;
  isDtInitializedjobdetails = false;
  public totalRecords: any = 0;
  ClientDropDown = [];
  CompanyDropDown = [];
  public JobGradeData = [];
  public JobGroupData = [];
  public CompanyjobPositionData = [];
  public countryName = [];
  public dropDownData = [];
  public job_grade_Dropdown = [];
  public job_group_Dropdown = [];
  public job_pay_frequency_Dropdown = [];
  public job_function_Dropdown = [];
  public addJobGradeForm: FormGroup;
  public addJobGroupForm: FormGroup;
  public addCompanyjobPositionForm: FormGroup;
  public addPayFrequencyForm: FormGroup;
  public addJobDetailsForm: FormGroup;
  public ShowForm: any = false;
  public countriesList = [];
  public statesList = [];
  public citiesList = [];
  loading=false;
  ClientID = localStorage.getItem('client_id');
  CompanyID = localStorage.getItem('company_id');

  activeTab = 'job_grade'; // Default active tab
  nameBgColors: any = ["bg-blue-dim", "bg-blue-dim", "bg-azure-dim", "bg-indigo-dim", "bg-info-dim", "bg-purple-dim", "bg-pink-dim", "bg-orange-dim", "bg-teal-dim", "bg-primary-dim", "bg-success-dim", "bg-warning-dim", "bg-danger-dim", "bg-secondary-dim", "bg-dark-dim", "bg-gray-dim", "bg-blue-dim", "bg-azure-dim", "bg-indigo-dim", "bg-info-dim", "bg-purple-dim", "bg-pink-dim", "bg-orange-dim", "bg-teal-dim", "bg-primary-dim", "bg-success-dim", "bg-warning-dim", "bg-danger-dim", "bg-secondary-dim", "bg-dark-dim", "bg-gray-dim"];
  nameBorderColors: any = ["bg-outline-primary", "bg-outline-success", "bg-outline-info", "bg-outline-gray", "bg-outline-warning", "bg-outline-dark", "bg-outline-light", "bg-outline-secondary", "bg-outline-danger"];
  statuses: { label: string; value: string; }[];
  searchKeyword: string = '';
  searchKeyword1: string = '';
  searchKeyword2: string = '';
  searchKeyword3: string = '';
  searchKeyword4: string = '';
  statusApi: any;
  statusValues: any;
  statusid: any;
  constructor(
    private apiModulesService: ApiModulesService,
    private FormBuilder: FormBuilder,
    private toastr: ToastrService,
    private spinnerService: SpinnerService
  ) { }
  ngOnInit(): void {
      this.spinnerService.raiseDataEmitterEvent('on');


    this.getCountries();
    this.GetClientDropDown();
    //addGradeForm
    this.addJobGradeForm = this.FormBuilder.group({
      job_grade_id: ["", []],
      grade_code: ["", [Validators.required]],
      grade_name: ["", [Validators.required]]
    });
    //addjobForm
    this.addJobGroupForm = this.FormBuilder.group({
      job_group_id: ["", []],
      group_code: ["", [Validators.required]],
      group_name: ["", [Validators.required]]
    });
    // addCompanyForm
    this.addCompanyjobPositionForm = this.FormBuilder.group({
      job_position_id: ["", []],
      position_description: ["", [Validators.required]],
      position_name: ["", [Validators.required]],
      position_location: ["", [Validators.required]],
      country_id: ["", [Validators.required]],
      state_id: ["", [Validators.required]],
      city_id: ["", [Validators.required]]
    });
    // addPayFrequencyForm
    this.addPayFrequencyForm = this.FormBuilder.group({
      company_freq_id: ["", []],
      frequency_name: ["", [Validators.required]],
      frequency_desc: ["", [Validators.required]]
    });
    // addJobDetailsForm
    this.addJobDetailsForm = this.FormBuilder.group({
      job_title: [, [Validators.required]],
      job_title_short: ["", [Validators.required]],
      description: ["", [Validators.required]],
      // job_group_id:["",[Validators.required]],
      // job_grade_id:["",[Validators.required]],
      // job_position_id:["",[Validators.required]],
      // company_freq_id:["",[Validators.required]],
      // probation_period:["",[Validators.required]],
      // notice_in_probation:["",[Validators.required]],
      // notice_after_probation:["",[Validators.required]],
      // working_hours:["",[Validators.required]],
      // overtime:["",[Validators.required]],
      // effective_from:["",[Validators.required]],
      job_id: ["", []]
    });
    this.statuses = [
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ];
  }

  public resetForm() {
    this.addJobGradeForm.reset();
    this.addJobGroupForm.reset();
    this.addCompanyjobPositionForm.reset();
    this.addPayFrequencyForm.reset();
    this.addJobDetailsForm.reset();
  }

  ngAfterViewInit(): void {

  }


  ngOnDestroy(): void {

  }

  /* JobGrade **********************************************/


  public getJobGrade() {
    this.spinnerService.raiseDataEmitterEvent('on');
    var params:any={};
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;

          this.apiModulesService.list("admin/companyjobdetails/getJobGrade", params).subscribe((data) => {

              this.JobGradeData = (data.data.job_grade != undefined) ? data.data.job_grade : [];
              this.loading = false;
              var totalRecords = (this.JobGradeData[0] != undefined) ? this.JobGradeData[0].total_records : 0;
              this.totalRecords = totalRecords;
              this.resetForm();
              setTimeout(() => {
                this.spinnerService.raiseDataEmitterEvent('off');
            }, 100);
          }, (err) => {
            this.JobGradeData = [];
            this.spinnerService.raiseDataEmitterEvent('off');
            // this.toastr.error('Something went wrong!', 'Try Again');
              // this.rerender();
          });
  }

  clear(table: Table) {
    table.clear();
  }

  getSeverity(status: string) {
    switch (status.toLowerCase()) {
      case 'inactive':
        return 'danger';

      case 'active':
        return 'success';
    }
  }

  public addJobGrade() {
    if (!this.addJobGradeForm.valid) {

      this.addJobGradeForm.markAllAsTouched();
      return;
    }

    var params: any = {};
    params.job_grade_code = this.addJobGradeForm.get('grade_code').value;
    params.job_grade_name = this.addJobGradeForm.get('grade_name').value;
    params.company_id = this.CompanyID;
    params.client_id = this.ClientID;
    params.record_status = 1;

    params.job_grade_id = this.addJobGradeForm.get('job_grade_id').value;
    if (params.job_grade_id != null && params.job_grade_id != '') {
      this.apiModulesService.edit(params, "admin/companyjobdetails/updateGrade").subscribe((data) => {
        this.toastr.success('job grade added successfully...!', 'Success');
        ($("#add_grade") as any).modal("hide");
        this.getJobGrade();
      });
    } else {
      this.apiModulesService.add(params, "admin/companyjobdetails/addGrade").subscribe((data) => {
        this.toastr.success("Job grade added successfully...!", "Success");
        ($("#add_grade") as any).modal("hide");
        this.getJobGrade();

      }
        , (err) => {
          if (err.status === 437) {
            this.toastr.error('Job grade Already Exists...!', 'Failed');
          } else {
            this.toastr.error('Something Went Wrong Please Try Again...!', 'Error');
          }
        }
      );
    }


  }
  // Function to update activeTab when a tab is clicked
  setActiveTab(tab: string) {
    this.activeTab = tab;
    if (tab === 'job_grade') {
      this.getJobGrade();
    }
    if (tab === 'job_group') {
      this.getJobGroup();
    }
    if (tab === 'pay_frequency') {
      this.getPayFrequency();
    }
    if (tab === 'company_job_details') {
      this.getJobDetails();
    }
    if (tab === 'company_job_position') {
      this.getCompanyjobPosition();
    }
  }
  public onEditGrade(params) {
    this.addJobGradeForm.setValue({
      job_grade_id: params.job_grade_id,
      grade_code: params.job_grade_code,
      grade_name: params.job_grade_name,
    });
  }


  /*JobGroup ************************************************/

  public getJobGroup() {
    this.spinnerService.raiseDataEmitterEvent('on');
    var params:any={};
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;

          this.apiModulesService.list("admin/companyjobdetails/getJobGroup", params).subscribe((data) => {

              this.JobGroupData = (data.data.job_group != undefined) ? data.data.job_group : [];
              this.loading = false;
              var totalRecords = (this.JobGroupData[0] != undefined) ? this.JobGroupData[0].total_records : 0;
              this.totalRecords = totalRecords;
              this.resetForm();
              setTimeout(() => {
                this.spinnerService.raiseDataEmitterEvent('off');
            }, 100);
          }, (err) => {
            this.JobGroupData = [];
            this.spinnerService.raiseDataEmitterEvent('off');
            // this.toastr.error('Something went wrong!', 'Try Again');
              // this.rerender();
          });
  }

  public addJobGroup() {
    if (!this.addJobGroupForm.valid) {

      this.addJobGroupForm.markAllAsTouched();
      return;
    }
    var params: any = {};
    params.job_group_code = this.addJobGroupForm.get('group_code').value;
    params.job_group_name = this.addJobGroupForm.get('group_name').value;
    params.company_id = this.CompanyID;
    params.client_id = this.ClientID;
    params.record_status = 1;

    params.job_group_id = this.addJobGroupForm.get('job_group_id').value;
    if (params.job_group_id != null && params.job_group_id != '') {
      this.apiModulesService.edit(params, "admin/companyjobdetails/updateGroup").subscribe((data) => {
        ($("#add_group") as any).modal("hide");
        this.getJobGroup();
        this.toastr.success("Job group updated successfully...!", "Success");
      });
    } else {
      this.apiModulesService.add(params, "admin/companyjobdetails/addGroup").subscribe((data) => {
        ($("#add_group") as any).modal("hide");
        this.getJobGroup();
        this.toastr.success("Job group added successfully...!", "Success");
      }
        , (err) => {
          this.toastr.error("Something went wrong!", "Try Again");
        }
      );
    }
  }

  public onEditGroup(params) {
    this.addJobGroupForm.setValue({
      job_group_id: params.job_group_id,
      group_code: params.job_group_code,
      group_name: params.job_group_name,
    });
  }

  /* CompanyjobPosition ******************************************/


  public getCompanyjobPosition() {
    this.spinnerService.raiseDataEmitterEvent('on');
    var params:any={};
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;

          this.apiModulesService.list("admin/companyjobdetails/getCompanyJobPosition", params).subscribe((data) => {

              this.CompanyjobPositionData = (data.data.company_job_position != undefined) ? data.data.company_job_position : [];
              this.loading = false;
              var totalRecords = (this.CompanyjobPositionData[0] != undefined) ? this.CompanyjobPositionData[0].total_records : 0;
              this.totalRecords = totalRecords;
              this.resetForm();
              setTimeout(() => {
                this.spinnerService.raiseDataEmitterEvent('off');
            }, 100);
          }, (err) => {
            this.CompanyjobPositionData = [];
            this.spinnerService.raiseDataEmitterEvent('off');
            // this.toastr.error('Something went wrong!', 'Try Again');
              // this.rerender();
          });
  }



  public addCompanyjobPosition() {
    if (!this.addCompanyjobPositionForm.valid) {

      this.addCompanyjobPositionForm.markAllAsTouched();
      return;
    }
    var params: any = {};
    params.position_description = this.addCompanyjobPositionForm.value.position_description;
    params.position_location = this.addCompanyjobPositionForm.value.position_location;
    params.position_name = this.addCompanyjobPositionForm.value.position_name;
    params.country_id = this.addCompanyjobPositionForm.value.country_id;
    params.state_id = this.addCompanyjobPositionForm.value.state_id;
    params.city_id = this.addCompanyjobPositionForm.value.city_id;
    params.company_id = this.CompanyID;
    params.client_id = this.ClientID;
    params.record_status = 1;

    params.job_position_id = this.addCompanyjobPositionForm.value.job_position_id;
    if (params.job_position_id != null && params.job_position_id != '') {
      this.apiModulesService.edit(params, "admin/companyjobdetails/updateCompanyJobPosition").subscribe((data) => {
        ($("#add_function") as any).modal('hide');
        this.getCompanyjobPosition();
        this.toastr.success("Job function updated successfully...!", "Success");
      });
    }
    else {
      this.apiModulesService.add(params, "admin/companyjobdetails/createCompanyJobPosition").subscribe((data) => {
        ($("#add_function") as any).modal('hide');
        this.getCompanyjobPosition();
        this.toastr.success("Job function added successfully...!", "Success");
      }
        , (err) => {
          this.toastr.error("Something went wrong!", "Try Again");
        }
      );
    }
  }

  public onEditFunction(params) {

    this.addCompanyjobPositionForm.setValue(
      {
        job_position_id: params.job_position_id,
        position_description: params.position_description,
        position_name: params.position_name,
        position_location: params.position_location,
        country_id: params.country_id,
        state_id: params.state_id,
        city_id: params.city_id
      }
    );
  }
  public getCountries() {
    this.apiModulesService.get('country/list').subscribe((data) => {

        const res: any = data;

        this.countriesList = (res.data.countries !== undefined) ? res.data.countries : [];
    });
}
  public getStates() {
    var params: any = {};
    params.country_id = this.addCompanyjobPositionForm.get('country_id').value;
    this.apiModulesService.list('country/states', params).subscribe((data) => {

        this.statesList = (data.data.states !== undefined) ? data.data.states : [];
    });
}
public getCities() {
  const params: any = {};
  params.state_id = this.addCompanyjobPositionForm.get('state_id').value;
  this.apiModulesService.list('country/cities', params).subscribe((data) => {

      this.citiesList = (data.data.states !== undefined) ? data.data.states : [];
  });
}


  /* Pay Frequency ***********************************************/


  public getPayFrequency() {
    this.spinnerService.raiseDataEmitterEvent('on');
    var params:any={};
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;

          this.apiModulesService.list("admin/companyjobdetails/getPayFrequency", params).subscribe((data) => {

              this.PayFrequencyData  = (data.data.pay_frequency != undefined) ? data.data.pay_frequency : [];
              this.loading = false;
              var totalRecords = (this.PayFrequencyData [0] != undefined) ? this.PayFrequencyData [0].total_records : 0;
              this.totalRecords = totalRecords;
              this.resetForm();
              setTimeout(() => {
                this.spinnerService.raiseDataEmitterEvent('off');
            }, 100);
          }, (err) => {
            this.PayFrequencyData  = [];
            this.spinnerService.raiseDataEmitterEvent('off');
            // this.toastr.error('Something went wrong!', 'Try Again');
              // this.rerender();
          });
  }



  public addPayFrequency() {
    if (!this.addPayFrequencyForm.valid) {

      this.addPayFrequencyForm.markAllAsTouched();
      return;
    }
    var params: any = {};
    params.company_freq_name = this.addPayFrequencyForm.value.frequency_name;
    params.company_freq_description = this.addPayFrequencyForm.value.frequency_desc;
    params.company_id = this.CompanyID;
    params.client_id = this.ClientID;

    params.company_freq_id = this.addPayFrequencyForm.value.company_freq_id;
    if (params.company_freq_id != null && params.company_freq_id != '') {
      this.apiModulesService.edit(params, "admin/companyjobdetails/updatePayFrequency").subscribe((data) => {
        ($("#add_frequency") as any).modal('hide');
        this.getPayFrequency();
        this.toastr.success("Job frequency updated successfully...!", "Success");
      });
    }
    else {
      this.apiModulesService.add(params, "admin/companyjobdetails/addPayFrequency").subscribe((data) => {
        ($("#add_frequency") as any).modal('hide');
        this.getPayFrequency();
        this.toastr.success("Job frequency added successfully...!", "Success");
      }
        , (err) => {
          this.toastr.error("Something went wrong!", "Try Again");
        }
      );
    }
  }
  public editpayFrequency(params) {

    this.addPayFrequencyForm.setValue(
      {
        company_freq_id: params.company_freq_id,
        frequency_name: params.company_freq_name,
        frequency_desc: params.company_freq_desc
      }
    );
  }




  /* JobDetails ******************************************************/


  public getJobDetails() {
    this.spinnerService.raiseDataEmitterEvent('on');
    var params:any={};
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;

          this.apiModulesService.list("admin/companyjobdetails/getJobDetails", params).subscribe((data) => {

              this.JobDetailsData = (data.data.job_details != undefined) ? data.data.job_details : [];
              this.loading = false;
              var totalRecords = (this.JobDetailsData[0] != undefined) ? this.JobDetailsData[0].total_records : 0;
              this.totalRecords = totalRecords;
              this.resetForm();
              setTimeout(() => {
                this.spinnerService.raiseDataEmitterEvent('off');
            }, 100);
          }, (err) => {
            this.JobDetailsData = [];
            this.spinnerService.raiseDataEmitterEvent('off');
            // this.toastr.error('Something went wrong!', 'Try Again');
              // this.rerender();
          });
  }
  // showAddForm(){
  //   this.ShowForm = true;
  //   this.resetForm();
  // }



  public addJobDetails() {

    if (!this.addJobDetailsForm.valid) {

      this.addJobDetailsForm.markAllAsTouched();
      return;
    }
    var params: any = {};
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;;
    // params.job_group_id=this.addJobDetailsForm.get('job_group_id').value;
    // params.job_grade_id=this.addJobDetailsForm.get('job_grade_id').value;
    // params.job_position_id=this.addJobDetailsForm.get('job_position_id').value;
    // params.company_freq_id=this.addJobDetailsForm.get('company_freq_id').value;
    params.job_title = this.addJobDetailsForm.get('job_title').value;
    params.job_title_short = this.addJobDetailsForm.get('job_title_short').value;
    params.job_description = this.addJobDetailsForm.get('description').value;
    // params.probation_period=this.addJobDetailsForm.get('probation_period').value;
    // params.notice_in_probation=this.addJobDetailsForm.get('notice_in_probation').value;
    // params.notice_after_probation=this.addJobDetailsForm.get('notice_after_probation').value;
    // params.working_hours=this.addJobDetailsForm.get('working_hours').value;
    // params.is_overtime=this.addJobDetailsForm.get('overtime').value;
    // params.effective_from=this.addJobDetailsForm.get('effective_from').value;
    params.job_id = this.addJobDetailsForm.value.job_id
    if (params.job_id != null && params.job_id != '') {
      this.apiModulesService.edit(params, "admin/companyjobdetails/updateJobDetails").subscribe((data) => {
        this.getJobDetails();
        this.toastr.success("Job details updated successfully...!", "Success");
        ($("#add_details") as any).modal('hide');
      }, (err) => {
        let errors = err.error.data.job_details;
        errors = errors.replace('SQLSTATE[42000]: [Microsoft][ODBC Driver 17 for SQL Server][SQL Server]', 'Error: ')
        errors = errors.replace('SQLSTATE[42000]: [Microsoft][ODBC Driver 18 for SQL Server][SQL Server]', 'Error: ')
        this.toastr.error(errors, 'TryAgain');
        });
    }
    else {
      this.apiModulesService.add(params, "admin/companyjobdetails/addJobDetails").subscribe((data) => {
        ($("#add_details") as any).modal('hide');
        this.getJobDetails();
        this.toastr.success("Job details added successfully...!", "Success");
        this.addJobDetailsForm.reset();
      }, (err) => {
        let errors = err.error.data.job_details;
        errors = errors.replace('SQLSTATE[42000]: [Microsoft][ODBC Driver 17 for SQL Server][SQL Server]', 'Error: ')
        errors = errors.replace('SQLSTATE[42000]: [Microsoft][ODBC Driver 18 for SQL Server][SQL Server]', 'Error: ')
        this.toastr.error(errors, 'TryAgain');
        }
      );
    }
  }
  public reset() {
    this.addJobDetailsForm.reset();
  }
  public onEditJobDetails(params) {

    this.addJobDetailsForm.setValue(
      {
        job_title: params.job_title,
        job_title_short: params.job_title_short,
        description: params.job_description,
        job_id: params.job_id
      }

    );

  }


  // public getDropDownDetails(){
  //     let params:any={};
  //     params.client_id= this.ClientID;
  //     params.company_id = this.CompanyID;
  //     this.apiModulesService.list("admin/companyjobdetails/dropDownJobDetails",params).subscribe((data)=>
  //     {
  //       this.dropDownData=data.data.job_details;
  //       this.job_grade_Dropdown=data.data.job_details[0];
  //       this.job_group_Dropdown=data.data.job_details[1];
  //       this.job_function_Dropdown=data.data.job_details[2];
  //       this.job_pay_frequency_Dropdown=data.data.job_details[3];
  //
  //     });
  //   }

  /*   Status For all ********************************************/
  public statusChanging(Id: any, status: any, id_name: any) {
    var params: any = {};
    params[id_name] = Id;
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    this.apiModulesService.edit(params, "admin/companyjobdetails/" + status).subscribe((data) => {
      this.setActiveTab(this.activeTab);
      ($('#grade-status') as any).modal('hide');
      ($('#group-status') as any).modal('hide');
      ($('#payfrequency-status') as any).modal('hide');
      ($('#jobposition-status') as any).modal('hide');
      ($('#jobdetails-status') as any).modal('hide');
      this.toastr.success("Status changed successfully...!", "Success");
    }
      , (err) => {
        this.toastr.error("Something went wrong!", "Try Again");
      }
    );
  }

  /* client DropDown codes*/
  public GetClientDropDown() {
    this.apiModulesService.get('DropDown/client').subscribe((data) => {
      this.ClientDropDown = data.data.client;

      this.ClientID = data.data.client[0].client_id;
      this.GetCompanyDropDown();
    })
  }

  /* company DropDown codes */
  public GetCompanyDropDown() {
    const params: any = {};
    params.client_id = this.ClientID;

    this.apiModulesService.list('DropDown/company', params).subscribe((data) => {
      this.CompanyDropDown = data.data.company != undefined ? data.data.company : [];
      if (this.CompanyDropDown.length !== 0) {
        setTimeout(() => {
          this.CompanyID = this.CompanyDropDown[0].company_id;
          this.setActiveTab(this.activeTab);
        }, 100)
      }
      else {
        this.CompanyID = null;
        this.rerender();
      }

    }
    )
  }
  rerender() {

  }
  getAcronym(str: any) {
    var matches = str.match(/\b(\w)/g); // ['J','S','O','N']
    matches = matches.slice(0, 2);
    var acronym = matches.join(''); // JSON
    return acronym;
  }
  getBgColors(str: any) {
    var min = 0;
    var max = 15;
    var index = this.findAlphapositions(str, 1);
    return this.nameBgColors[index];
  }
  findAlphapositions(str: any, n: any) {
    var NUM = 31;

    return str[0].charCodeAt(0) & NUM;
  }
  callStatusModel(id:any,url_name:any,data:any){
    this.statusid=id;
    this.statusApi=url_name;
    this.statusValues=data;

}
}
