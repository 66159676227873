// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `p-dropdown{
	min-width: 225px;
	max-width: 100%;
	height: 32px;
	font-family: "Open_Sans" !important;
	font-size: 14px;
	font-weight: normal;
	line-height: 22px;
	letter-spacing: 0.4px;
	text-align: left;
	border: 1px solid #D9D9D9;
	border-radius: 3px  !important;
	width: 19%;
}
`, "",{"version":3,"sources":["webpack://./src/app/all-modules/ess-tabs/IND-ess/IND-employee-hrms/manager-page/payrollupload/payrollupload.component.css"],"names":[],"mappings":"AAAA;CACC,gBAAgB;CAChB,eAAe;CACf,YAAY;CACZ,mCAAmC;CACnC,eAAe;CACf,mBAAmB;CACnB,iBAAiB;CACjB,qBAAqB;CACrB,gBAAgB;CAChB,yBAAyB;CACzB,8BAA8B;CAC9B,UAAU;AACX","sourcesContent":["p-dropdown{\r\n\tmin-width: 225px;\r\n\tmax-width: 100%;\r\n\theight: 32px;\r\n\tfont-family: \"Open_Sans\" !important;\r\n\tfont-size: 14px;\r\n\tfont-weight: normal;\r\n\tline-height: 22px;\r\n\tletter-spacing: 0.4px;\r\n\ttext-align: left;\r\n\tborder: 1px solid #D9D9D9;\r\n\tborder-radius: 3px  !important;\r\n\twidth: 19%;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
