// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .content {
	padding-left: 250px;
	padding-top: 90px;
} */
.table{
	padding-top: 20px;
}

.move {
    display: inline-flex;
    cursor: pointer;
    position: relative;
    -webkit-user-select: none;
    user-select: none;
    margin-left: 475px;
}

`, "",{"version":3,"sources":["webpack://./src/app/all-modules/company/job-details/job-details.component.css"],"names":[],"mappings":"AAAA;;;GAGG;AACH;CACC,iBAAiB;AAClB;;AAEA;IACI,oBAAoB;IACpB,eAAe;IACf,kBAAkB;IAClB,yBAAyB;IACzB,iBAAiB;IACjB,kBAAkB;AACtB","sourcesContent":["/* .content {\r\n\tpadding-left: 250px;\r\n\tpadding-top: 90px;\r\n} */\r\n.table{\r\n\tpadding-top: 20px;\r\n}\r\n\r\n.move {\r\n    display: inline-flex;\r\n    cursor: pointer;\r\n    position: relative;\r\n    -webkit-user-select: none;\r\n    user-select: none;\r\n    margin-left: 475px;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
