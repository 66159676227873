import { Component, OnInit, ViewChild,AfterViewInit,OnDestroy } from '@angular/core';
import { ApiModulesService } from '../../api-modules.service';
import { AllModulesService } from "../../all-modules.service";

import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription, Subject, } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { SpinnerService } from 'src/app/snipper/spinner.service';
import { Table } from 'primeng/table';

declare const $: any;
@Component({
  selector: 'app-shiftassignment',
  templateUrl: './shiftassignment.component.html',
  styleUrls: ['./shiftassignment.component.css']
})
export class ShiftassignmentComponent implements OnInit, OnDestroy, AfterViewInit {


  public shiftdata = [];
  public listHeaders = [];
  loading=false;
  rows: any;
  public activeIndex = 0;
  public srch = [];
  search: any[];
  ClientDropDown = [];
  CompanyDropDown = [];


  public addShiftForm: FormGroup
  public clientname = [];
  isDtInitialized: boolean = false;
  public totalRecords: any = 0;
  public ShowForm: any = false;
  ClientID :any;
  CompanyID :any;
  nameBgColors:any = ["bg-blue-dim","bg-blue-dim","bg-azure-dim","bg-indigo-dim","bg-info-dim","bg-purple-dim","bg-pink-dim","bg-orange-dim","bg-teal-dim","bg-primary-dim","bg-success-dim","bg-warning-dim","bg-danger-dim","bg-secondary-dim","bg-dark-dim","bg-gray-dim","bg-blue-dim","bg-azure-dim","bg-indigo-dim","bg-info-dim","bg-purple-dim","bg-pink-dim","bg-orange-dim","bg-teal-dim","bg-primary-dim","bg-success-dim","bg-warning-dim","bg-danger-dim","bg-secondary-dim","bg-dark-dim","bg-gray-dim"];
  nameBorderColors:any = ["bg-outline-primary","bg-outline-success","bg-outline-info","bg-outline-gray","bg-outline-warning","bg-outline-dark","bg-outline-light","bg-outline-secondary","bg-outline-danger"];
  statuses: { label: string; value: string; }[];
  public department_dropdown = []
  public employee_dropdown = []
  public shift_dropdown = []
  constructor(private apiModulesService: ApiModulesService,
     private AllModulesService: AllModulesService,
      private toaster: ToastrService, private toastr: ToastrService,
     private formBuilder: FormBuilder,
     private spinnerService: SpinnerService) { }

  ngOnInit(): void {

    this.GetClientDropDown();
    this.addShiftForm = this.formBuilder.group({
      shift_id: ["", [Validators.required]],
      client_id: ["", []],
      company_id: ["", []],
      employee_id: ["", [Validators.required]],
      from_date: ["", [Validators.required]],
      to_date: ["", [Validators.required]],
      employee_shift_id: ["", []],
      department_id: ["", [Validators.required]],
    });


    this.statuses = [
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ];
  }

  showAddForm() {
    this.ShowForm = true;
    this.resetForm();
  }
  ngOnDestroy(): void {

  }

  //Get all shift data
  public getShift() {
    this.spinnerService.raiseDataEmitterEvent('on');
    if (this.CompanyDropDown.length !== 0 && this.CompanyID != null) {
    this.ShowForm = false;
    var params: any = {};
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    this.apiModulesService.list("admin/shift-assignment/get", params).subscribe((data) => {

    this.shiftdata = (data.data.shifts != undefined) ? data.data.shifts : [];
    this.loading = false;
    var totalRecords = this.shiftdata.length;
    this.totalRecords = totalRecords;
    this.resetForm();
    setTimeout(() => {
      this.spinnerService.raiseDataEmitterEvent('off');
  }, 100);
 }, (err) => {
  this.shiftdata = [];
  this.spinnerService.raiseDataEmitterEvent('off');

    });
  } else {
    this.rerender();
  }

}

clear(table: Table) {
  table.clear();
}

getSeverity(status: string) {
  switch (status.toLowerCase()) {
    case 'inactive':
      return 'danger';

    case 'active':
      return 'success';
  }
}
  changeStatus(Status: any, data) {
    let params: any = {};
    params.client_id = this.ClientID;
    params.employee_shift_id = data.employee_shift_id,
      params.company_id = this.CompanyID;

    this.apiModulesService.edit(params, 'admin/shift-assignment/' + Status).subscribe((data) => {
      this.getShift();
      this.toaster.success('Status Changed Successfully!', 'Success')
    }, (err) => {
      this.toastr.error("Something went wrong!", "Try Again");
    }
    );
  }
  public onAddShift() {
    if (!this.addShiftForm.valid) {

      this.addShiftForm.markAllAsTouched();
      return;
    }
    var params: any = {};
    params.company_id = this.CompanyID;
    params.client_id = this.ClientID;
    params.shift_id = this.addShiftForm.get('shift_id').value;
    params.employee_id = this.addShiftForm.get('employee_id').value;
    params.from_date = this.addShiftForm.get('from_date').value;
    params.to_date = this.addShiftForm.get('to_date').value;

    var employee_shift_id = this.addShiftForm.get('employee_shift_id').value;

    if (employee_shift_id != '' && employee_shift_id != undefined) {
      params.employee_shift_id = this.addShiftForm.get('employee_shift_id').value;
      this.apiModulesService.edit(params, "admin/shift-assignment/update",).subscribe((data) => {


        this.getShift();
        this.toastr.success('Leave Modified Sucessfully...!', 'Success');
        ($('#add_shift') as any).modal('hide');
      }, err => {
        if (err.status === 437) {
          this.toastr.error('Leave Already Exists...!', 'Failed');
        } else {
          this.toastr.error('Something Went Wrong Please Try Again...!', 'Error');
        }
      });

    } else {
      this.apiModulesService.add(params, "admin/shift-assignment/add",).subscribe((data) => {
        this.getShift();
        this.toastr.success('Leave Added Sucessfully...!', 'Success');
        this.addShiftForm.reset();
        ($('#add_shift') as any).modal('hide');
      }, err => {
        if (err.status === 437) {
          this.toastr.error('Leave Already Exists...!', 'Failed');
        } else {
          this.toastr.error('Something Went Wrong Please Try Again...!', 'Error');
        }
      });
    }
  }
  //Reset form
  public resetForm() {
    this.addShiftForm.reset();
  }
  public edit(
    params) {



    this.addShiftForm.setValue({

      shift_id: params.shift_id,
      from_date: params.from_date,
      employee_id: params.employee_id,
      to_date: params.to_date,
      employee_shift_id: params.employee_shift_id,
      client_id: params.client_id,
      company_id: params.company_id,
      department_id: params.department_id,
    });
    this.departmentDropDown();
    this.employeeDropDown();
  }

  ngAfterViewInit(): void {

  }
  rerender() {
  }


 /* client DropDown codes*/
 public GetClientDropDown() {
  this.apiModulesService.get('DropDown/client').subscribe((data) => {
    this.ClientDropDown = data.data.client;

    this.ClientID = data.data.client[0].client_id;
    this.GetCompanyDropDown();

  })
}

  /* company DropDown codes */
  public GetCompanyDropDown() {
    const params: any = {};
    params.client_id = this.ClientID;

    this.apiModulesService.list('DropDown/company', params).subscribe((data) => {
      this.CompanyDropDown = data.data.company != undefined ? data.data.company : [];
      if (this.CompanyDropDown.length !== 0) {
        setTimeout(() => {
          this.CompanyID = this.CompanyDropDown[0].company_id;
          this.getShift();
          this.departmentDropDown();
        }, 100)
      }
      else {
        this.CompanyID = null;
        this.rerender();
      }

    }
    )
  }
  // dropdown
  public departmentDropDown(){
    const params: any = {};
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    this.apiModulesService.list("admin/shift-assignment/dropdown", params).subscribe((data)=>{
      this.department_dropdown = data.data.shifts[0];
      this.shift_dropdown = data.data.shifts[1];
    })
  }
    // dropdown
    public employeeDropDown(){
      const params: any = {};
      params.company_id = this.CompanyID;
      params.client_id = this.ClientID;
      params.department_id = this.addShiftForm.get('department_id').value;
      this.apiModulesService.list("admin/shift-assignment/employee_dropdown", params).subscribe((data)=>{
        this.employee_dropdown = data.data.shifts
      })
    }

  getAcronym(str:any){
    var matches = str.match(/\b(\w)/g); // ['J','S','O','N']
    matches= matches.slice(0,3);
    var acronym = matches.join(''); // JSON
    return acronym;
  }
  getBgColors(str:any){
    var min = 0;
    var max = 15;
    var index = this.findAlphapositions(str,1);
    return this.nameBgColors[index];
  }
  findAlphapositions(str:any, n:any)
  {
    var NUM = 31;

    return str[0].charCodeAt(0) & NUM;
  }
}
