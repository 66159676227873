import { Component, OnInit, ViewChild,AfterViewInit,OnDestroy } from '@angular/core';
import { ApiModulesService } from '../../api-modules.service';
import { AllModulesService } from "../../all-modules.service";

import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription, Subject, } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { SpinnerService } from 'src/app/snipper/spinner.service';
import { Table } from 'primeng/table';

declare const $: any;
@Component({
  selector: 'app-teams',
  templateUrl: './teams.component.html',
  styleUrls: ['./teams.component.css']
})
export class TeamsComponent implements OnInit, OnDestroy, AfterViewInit {


  public teamsdata = [];
  public listHeaders = [];
  rows: any;
  public activeIndex = 0;
  searchKeyword: string = '';
  public srch = [];
  search: any[];
  ClientDropDown = [];
  CompanyDropDown = [];


  public addTeamForm: FormGroup
  public clientname = [];
  isDtInitialized: boolean = false;
  public totalRecords: any = 0;
  public ShowForm: any = false;
  loading=false;
  ClientID :any;
  CompanyID :any;
  nameBgColors:any = ["bg-blue-dim","bg-blue-dim","bg-azure-dim","bg-indigo-dim","bg-info-dim","bg-purple-dim","bg-pink-dim","bg-orange-dim","bg-teal-dim","bg-primary-dim","bg-success-dim","bg-warning-dim","bg-danger-dim","bg-secondary-dim","bg-dark-dim","bg-gray-dim","bg-blue-dim","bg-azure-dim","bg-indigo-dim","bg-info-dim","bg-purple-dim","bg-pink-dim","bg-orange-dim","bg-teal-dim","bg-primary-dim","bg-success-dim","bg-warning-dim","bg-danger-dim","bg-secondary-dim","bg-dark-dim","bg-gray-dim"];
  nameBorderColors:any = ["bg-outline-primary","bg-outline-success","bg-outline-info","bg-outline-gray","bg-outline-warning","bg-outline-dark","bg-outline-light","bg-outline-secondary","bg-outline-danger"];

  public dropdown = []
  showTabs = false;
  scrollableTabs = [
    {
        title: 'Leave Plan Type'
    }, {
        title: 'Leave Company Holiday'
    }, {
        title: 'Leave Settings'
    },
];

statuses: { label: string; value: string; }[];
  statusApi: any;
  statusValues: any;
  constructor(private apiModulesService: ApiModulesService, private AllModulesService: AllModulesService, private toaster: ToastrService, private toastr: ToastrService, private formBuilder: FormBuilder, private spinnerService: SpinnerService,) { }

  ngOnInit(): void {
      this.spinnerService.raiseDataEmitterEvent('on');

    this.GetClientDropDown();
    this.addTeamForm = this.formBuilder.group({
      team_name: ["", [Validators.required]],
      team_description: ["", [Validators.required]],
      client_id: ["", []],
      company_id: ["", []],
      department_id: ["", [Validators.required]],
      team_id: ["", []],

    });

    this.statuses = [
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ];
  }

  showAddForm() {
    this.ShowForm = true;
    this.resetForm();
  }
  ngOnDestroy(): void {

  }

  //Get all paycomponent data


  public getTeam() {
    this.spinnerService.raiseDataEmitterEvent('on');
    var params:any={};
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;

          this.apiModulesService.list("admin/teams/get", params).subscribe((data) => {

              this.teamsdata = (data.data.Teams != undefined) ? data.data.Teams : [];
              this.loading = false;
              var totalRecords = (this.teamsdata[0] != undefined) ? this.teamsdata[0].total_records : 0;
              this.totalRecords = totalRecords;
              this.resetForm();
              setTimeout(() => {
                this.spinnerService.raiseDataEmitterEvent('off');
            }, 100);
          }, (err) => {
            this.teamsdata = [];
            this.spinnerService.raiseDataEmitterEvent('off');
              // this.rerender();
          });
  }

  clear(table: Table) {
    table.clear();
  }

  getSeverity(status: string) {
    switch (status.toLowerCase()) {
      case 'inactive':
        return 'danger';

      case 'active':
        return 'success';
    }
  }


  changeStatus(Status: any, data) {
    let params: any = {};
    params.client_id = this.ClientID;
    params.team_id = data.team_id,
      params.company_id = this.CompanyID;

    this.apiModulesService.edit(params, 'admin/teams/' + Status).subscribe((data) => {
      this.getTeam();
      ($('#teams-status') as any).modal('hide');
      this.toaster.success('Status Changed Successfully!', 'Success')
    }, (err) => {
      let errors = err.error.data.Teams;
      errors = errors.replace('SQLSTATE[42000]: [Microsoft][ODBC Driver 17 for SQL Server][SQL Server]', 'Error: ')
      this.toastr.error(errors, 'TryAgain');
      }
    );
  }
  public onAddTeam() {
    if (!this.addTeamForm.valid) {

      this.addTeamForm.markAllAsTouched();
      return;
    }
    var params: any = {};
    params.company_id = this.CompanyID;
    params.client_id = this.ClientID;
    params.team_name = this.addTeamForm.get('team_name').value;
    params.team_description = this.addTeamForm.get('team_description').value;
    params.department_id = this.addTeamForm.get('department_id').value;

    var team_id = this.addTeamForm.get('team_id').value;

    if (team_id != '' && team_id != undefined) {
      params.team_id = this.addTeamForm.get('team_id').value;
      this.apiModulesService.edit(params, "admin/teams/update",).subscribe((data) => {


        this.getTeam();
        this.toastr.success('Team Modified Sucessfully...!', 'Success');
        ($('#add_leave') as any).modal('hide');
      }, (err) => {
        let errors = err.error.data.Teams;
        errors = errors.replace('SQLSTATE[42000]: [Microsoft][ODBC Driver 17 for SQL Server][SQL Server]', 'Error: ')
        errors = errors.replace('SQLSTATE[42000]: [Microsoft][ODBC Driver 18 for SQL Server][SQL Server]', 'Error: ')
        this.toastr.error(errors, 'TryAgain');
        });

    } else {
      this.apiModulesService.add(params, "admin/teams/add",).subscribe((data) => {
        this.getTeam();
        this.toastr.success('Team Added Sucessfully...!', 'Success');
        this.addTeamForm.reset();
        ($('#add_leave') as any).modal('hide');
      }, (err) => {
        let errors = err.error.data.Teams;
        errors = errors.replace('SQLSTATE[42000]: [Microsoft][ODBC Driver 17 for SQL Server][SQL Server]', 'Error: ')
        errors = errors.replace('SQLSTATE[42000]: [Microsoft][ODBC Driver 18 for SQL Server][SQL Server]', 'Error: ')
        this.toastr.error(errors, 'TryAgain');
        });
    }
  }
  //Reset form
  public resetForm() {
    this.addTeamForm.reset();
  }
  public edit(Teams: any) {
    this.resetForm();
    const params: any = {};
    params.client_id = this.ClientID;
    params.team_id = Teams.team_id;
    params.company_id = this.CompanyID;

    this.apiModulesService.edit(params, 'admin/teams/edit').subscribe((data) => {

               const leaveDetail = (data.data.Teams !== undefined) ? data.data.Teams : [];
              
               this.addTeamForm.setValue({
                //  company_id:leaveDetail.company_id,
                //  client_id:leaveDetail.client_id,
                team_name: leaveDetail.team_name,
                team_description: leaveDetail.team_description,
                department_id: leaveDetail.department_id,
                team_id: leaveDetail.team_id,
                client_id: leaveDetail.client_id,
                company_id: leaveDetail.company_id
            });

            this.ShowForm = true;
          }, (err) => {
            let errors = err.error.data.Teams;
            errors = errors.replace('SQLSTATE[42000]: [Microsoft][ODBC Driver 17 for SQL Server][SQL Server]', 'Error: ')
            errors = errors.replace('SQLSTATE[42000]: [Microsoft][ODBC Driver 18 for SQL Server][SQL Server]', 'Error: ')
            this.toastr.error(errors, 'TryAgain');
            }
    );
}

  ngAfterViewInit(): void {

  }
  rerender() {
  }
  // dropdown
  public dropDown(){
    const params: any = {};
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    this.apiModulesService.list("admin/teams/dropdown", params).subscribe((data)=>{
    this.dropdown = data.data.Teams

    })
  }

 /* client DropDown codes*/
 public GetClientDropDown() {
    this.apiModulesService.get('DropDown/client').subscribe((data) => {
    this.ClientDropDown = data.data.client;

    this.ClientID = data.data.client[0].client_id;
    this.GetCompanyDropDown();

  })
}

  /* company DropDown codes */
    public GetCompanyDropDown() {
    const params: any = {};
    params.client_id = this.ClientID;

    this.apiModulesService.list('DropDown/company', params).subscribe((data) => {
      this.CompanyDropDown = data.data.company != undefined ? data.data.company : [];
      if (this.CompanyDropDown.length !== 0) {
        setTimeout(() => {
          this.CompanyID = this.CompanyDropDown[0].company_id;
          this.companyChange();
        }, 100)
      }
      else {
        this.CompanyID = null;
        this.rerender();
      }

    }
    )
  }
    companyChange()  {
        this.dropDown();
        this.getTeam();
    }

  getAcronym(str:any){
    var matches = str.match(/\b(\w)/g); // ['J','S','O','N']
    matches= matches.slice(0,2);
    var acronym = matches.join(''); // JSON
    return acronym;
  }
  getBgColors(str:any){
    var min = 0;
    var max = 15;
    var index = this.findAlphapositions(str,1);
    return this.nameBgColors[index];
  }
  findAlphapositions(str:any, n:any)
  {
    var NUM = 31;

    return str[0].charCodeAt(0) & NUM;
  }
  callStatusModel(url_name:any,data:any){
    this.statusApi=url_name;
    this.statusValues=data;
}
}
