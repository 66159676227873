import { AfterViewChecked, AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { ApiModulesService } from 'src/app/all-modules/api-modules.service'; 
import { ToastrService } from 'ngx-toastr';
import { SpinnerService } from 'src/app/snipper/spinner.service';
import { Table } from 'primeng/table';
@Component({
  selector: 'app-imports-grid',
  templateUrl: './imports-grid.component.html',
  styleUrls: ['./imports-grid.component.css']
})
export class ImportsGridComponent {
  nameBgColors: any = ["bg-blue-dim", "bg-blue-dim", "bg-azure-dim", "bg-indigo-dim", "bg-info-dim", "bg-purple-dim", "bg-pink-dim", "bg-orange-dim", "bg-teal-dim", "bg-primary-dim", "bg-success-dim", "bg-warning-dim", "bg-danger-dim", "bg-secondary-dim", "bg-dark-dim", "bg-gray-dim", "bg-blue-dim", "bg-azure-dim", "bg-indigo-dim", "bg-info-dim", "bg-purple-dim", "bg-pink-dim", "bg-orange-dim", "bg-teal-dim", "bg-primary-dim", "bg-success-dim", "bg-warning-dim", "bg-danger-dim", "bg-secondary-dim", "bg-dark-dim", "bg-gray-dim"];
  nameBorderColors: any = ["bg-outline-primary", "bg-outline-success", "bg-outline-info", "bg-outline-gray", "bg-outline-warning", "bg-outline-dark", "bg-outline-light", "bg-outline-secondary", "bg-outline-danger"];
 
  importDataColumn: any;
  @Input()  financial_date_id: any;
  @Input()  ClientID: any;
  @Input()  CompanyID: any;
  @Input()  ExcelTypeKey: any;
  @Input() ClientUserType: any;

  data_import_id:any;
  @Output() parenFun: EventEmitter<any> = new EventEmitter();

  totalRecords=0;
  searchKeyword: string = '';

  constructor(protected apiModulesService: ApiModulesService,
    protected formBuilder: FormBuilder,
    protected toastrService: ToastrService,
    protected spinnerService: SpinnerService
) {
}

  public getImport() {


    if (this.ClientID!= null && this.CompanyID !== null) {
        const params: any = {};
        this.spinnerService.raiseDataEmitterEvent('on');

        params.client_id = this.ClientID;
        params.company_id = this.CompanyID;
        params.financial_date_id = this.financial_date_id;
        params.excel_type_key = this.ExcelTypeKey;
        params.import_user_type = this.ClientUserType;
        this.apiModulesService.list('admin/import/common/list', params).subscribe((data) => {
            this.importDataColumn = (data.data.imports != undefined) ? data.data.imports : [];
            this.totalRecords=this.importDataColumn.length;
            this.spinnerService.raiseDataEmitterEvent('off');

        }, (err) => {
            this.importDataColumn = [];
            this.totalRecords=this.importDataColumn.length;

            this.spinnerService.raiseDataEmitterEvent('off');

        });
    } else {
        this.importDataColumn = [];
        this.totalRecords = this.importDataColumn.length;

        this.spinnerService.raiseDataEmitterEvent('off');
    }
}

getAcronym(str: any) {
  var matches = str.match(/\b(\w)/g); // ['J','S','O','N']
  var acronym = matches.join(''); // JSON
  return acronym;
}
getBgColors(str: any) {
  var min = 0;
  var max = 15;
  var index = this.findAlphapositions(str, 1);
  return this.nameBgColors[index];
}
findAlphapositions(str: any, n: any) {
  var NUM = 31;

  return str[0].charCodeAt(0) & NUM;
}
clear(table: Table) {
  table.clear();
}

viewValidation(dataimportId,importStatus){
  this.data_import_id=dataimportId;
  const importObj:any={
    'data_import_id':dataimportId,
    'import_status':importStatus
  }
  this.parenFun.emit(importObj)

}
}