import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {ApiModulesService} from '../../api-modules.service';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';
import {Subject} from 'rxjs';
import { SpinnerService } from 'src/app/snipper/spinner.service';
import { Table } from 'primeng/table';
@Component({
    selector: 'app-overtime-company',
    templateUrl: './overtime.component.html',
    styleUrls: ['./overtime.component.css']
})
export class OvertimeCompanyComponent implements OnDestroy, OnInit, AfterViewInit {




    isDtInitialized = false;
    ClientID: any;
    CompanyID: any;
    DepartmentID:any;
    reject_reason: any;
    chooseButton: any;
    overTimeForm: FormGroup;
    departmentDropDown = [];
    ClientDropDown = [];
    CompanyDropDown = [];
    overtimeData = [];
    employeeDropDown = [];
    statusChangeData: any;
    loading=false;
    public totalRecords: any = 0;
    statusApi:any;
    statusValues:any;
    nameBgColors: any = ['bg-blue-dim', 'bg-blue-dim', 'bg-azure-dim', 'bg-indigo-dim', 'bg-info-dim', 'bg-purple-dim', 'bg-pink-dim', 'bg-orange-dim', 'bg-teal-dim', 'bg-primary-dim', 'bg-success-dim', 'bg-warning-dim', 'bg-danger-dim', 'bg-secondary-dim', 'bg-dark-dim', 'bg-gray-dim', 'bg-blue-dim', 'bg-azure-dim', 'bg-indigo-dim', 'bg-info-dim', 'bg-purple-dim', 'bg-pink-dim', 'bg-orange-dim', 'bg-teal-dim', 'bg-primary-dim', 'bg-success-dim', 'bg-warning-dim', 'bg-danger-dim', 'bg-secondary-dim', 'bg-dark-dim', 'bg-gray-dim'];
    nameBorderColors: any = ['bg-outline-primary', 'bg-outline-success', 'bg-outline-info', 'bg-outline-gray', 'bg-outline-warning', 'bg-outline-dark', 'bg-outline-light', 'bg-outline-secondary', 'bg-outline-danger'];
    statuses: { label: string; value: string; }[];
  statusName: string;
    constructor(private spinnerService: SpinnerService,private ApiModuleService: ApiModulesService, private toastr: ToastrService, private formBuilder: FormBuilder, private router: Router) {
    }

    ngOnInit() {

        this.GetClientDropDown();
        this.overTimeForm = this.formBuilder.group({
            employee_id: ['', [Validators.required]],
            employee_overtime_id: [''],
            overtime_date: ['', [Validators.required]],
            overtime_hours: ['', [Validators.required]],
            descriptions: [''],
            department_id: ['', [Validators.required]],
        });
        this.statuses = [
            { label: 'Active', value: 'active' },
            { label: 'Inactive', value: 'inactive' },
          ];
    }

    ngAfterViewInit(): void {

    }

    changeChooseButton(actionFlag: any, leave: any) {
        this.statusChangeData = leave;
        this.chooseButton = actionFlag;
        this.reject_reason = '';
    }


    /* client DropDown codes*/
    public GetClientDropDown() {
      this.ApiModuleService.get('DropDown/client').subscribe((data) => {
          this.ClientDropDown = data.data.client;
          this.ClientID = data.data.client[0].client_id;
          this.GetCompanyDropDown();
      })
  }

  dropDown() {
    const params: any = {};
    params.company_id = this.CompanyID;
    params.client_id = this.ClientID;
    this.ApiModuleService.list('admin/overtime/drop-down', params).subscribe((data: any) => {
    this.departmentDropDown = data.data.overtime;
     setTimeout(() => {
     this.DepartmentID=this.departmentDropDown[0].company_department_id;
     this.gridOvertime();

      }, 1000)

    })
}


     /* company DropDown codes */
     public GetCompanyDropDown() {
      const params: any = {};
      params.client_id = this.ClientID;

      this.ApiModuleService.list('DropDown/company', params).subscribe((data) => {
              this.CompanyDropDown = data.data.company != undefined ? data.data.company : [];
              if (this.CompanyDropDown.length !== 0) {
                  setTimeout(() => {
                      this.CompanyID = this.CompanyDropDown[0].company_id;
                      this.dropDown();

                  }, 100)
              }
              else {
                  this.CompanyID = null;
                  this.rerender();
              }

          }
      )
  }



    rerender() {

    }

    public gridOvertime() {

        if(this.ClientID!==null  && this.CompanyID!== null && this.DepartmentID!==null){
          this.spinnerService.raiseDataEmitterEvent('on');
          var params:any={};
          params.client_id = this.ClientID;
          params.company_id = this.CompanyID;
          params.department_id=this.DepartmentID;

            this.ApiModuleService.list('admin/overtime/grid-list', params).subscribe((data) => {

            this.overtimeData = (data.data.overtime != undefined) ? data.data.overtime : [];
            this.loading = false;
            var totalRecords = (this.overtimeData[0] != undefined) ? this.overtimeData[0].total_records : 0;
            this.totalRecords = totalRecords;
            this.resetForm();

            this.spinnerService.raiseDataEmitterEvent('off');

                }, (err) => {
                  this.overtimeData = [];
                  this.spinnerService.raiseDataEmitterEvent('off');

                });
        }else{
          this.rerender();
        }

      }

      clear(table: Table) {
        table.clear();
      }

      getSeverity(status: string) {
        switch (status.toLowerCase()) {
          case 'inactive':
            return 'danger';

          case 'active':
            return 'success';
        }
      }
    dropGridChangeBasComp() {
        this.gridOvertime();
        this.gridOvertime();
    }
    getAcronym(str: any) {
      if (str != null) {
          let matches = str.match(/\b(\w)/g);
          matches = matches.slice(0, 2);
          const acronym = matches.join('');
          return acronym;
      }
  }
  getBgColors(str: any) {
      if (str != null) {
          const min = 0;
          const max = 15;
          const index = this.findAlphapositions(str, 1);
          return this.nameBgColors[index];
      }
  }

    findAlphapositions(str: any, n: any) {
        const NUM = 31;

        return str[0].charCodeAt(0) & NUM;
    }

    ngOnDestroy(): void {

    }

    changeOvertimeStatus() {
        const params: any = {};
        params.company_id = this.CompanyID;
        params.client_id = this.ClientID;
        params.employee_id = this.statusChangeData.employee_id;
        params.employee_overtime_id = this.statusChangeData.employee_overtime_id;
        params.reject_reason = this.reject_reason;
        this.ApiModuleService.list('admin/overtime/' + this.chooseButton, params).subscribe((data: any) => {
            ($('#confirm_button_popup') as any).modal('hide');
            this.gridOvertime();
        },(err:any)=>{

            let errors = err.error.data.overtime;
            errors = errors.replace('SQLSTATE[42000]: [Microsoft][ODBC Driver 17 for SQL Server][SQL Server]', 'Error: ');
            this.toastr.error(errors, 'TryAgain')
        })
    }
    // status change
        changeStatus(status: any, overtimeId: any, employeeID: any) {
        const params: any = {};
        params.company_id = this.CompanyID;
        params.client_id = this.ClientID;
        params.employee_id = employeeID;
        params.employee_overtime_id = overtimeId;
        //
                this.ApiModuleService.edit(params, 'admin/overtime/' + status).subscribe((data) => {
                this.gridOvertime();
                ($('#overtime-status') as any).modal('hide');
                this.toastr.success('Status Changed Sucessfully...!', 'Success');
        },(err:any)=>{

            let errors = err.error.data.overtime;
            errors = errors.replace('SQLSTATE[42000]: [Microsoft][ODBC Driver 17 for SQL Server][SQL Server]', 'Error: ');
            this.toastr.error(errors, 'TryAgain')
        })

    }
     getEmployee(){

        const params: any = {};
        params.company_id = this.CompanyID;
        params.client_id = this.ClientID;
        params.department_id = this.overTimeForm.get('department_id').value;

        if(this.ClientID!==null  && this.CompanyID!== null && this.overTimeForm.get('department_id').value!==null && this.overTimeForm.get('department_id').value!==''){
          this.ApiModuleService.list('admin/overtime/employee-dropDown', params).subscribe((data: any) => {

          this.employeeDropDown = data.data.overtime;

          })
        }

    }
     onEdit(value) {

            this.overTimeForm.setValue({
            employee_id: value.employee_id,
            employee_overtime_id: value.employee_overtime_id,
            overtime_date: value.date_over_time,
            overtime_hours: value.overtime_hours,
            descriptions: value.descriptions,
            department_id: value.department_id,
        });
        // this.dropDown();
        this.getEmployee();
    }


        onAddOvertime(){
        if(this.overTimeForm.invalid){
            this.overTimeForm.markAllAsTouched();
            return;
        }
        const params: any = {};
        params.company_id = this.CompanyID;
        params.client_id = this.ClientID;
        params.employee_id = this.overTimeForm.get('employee_id').value;
        params.overtime_date = this.overTimeForm.get('overtime_date').value;
        params.overtime_hours = this.overTimeForm.get('overtime_hours').value;
        params.descriptions = this.overTimeForm.get('descriptions').value;
        const employee_overtime_id=this.overTimeForm.get('employee_overtime_id').value;
        if(employee_overtime_id !=''&& employee_overtime_id!=null){
        params.employee_overtime_id = this.overTimeForm.get('employee_overtime_id').value;
        this.ApiModuleService.list('admin/overtime/update', params).subscribe((data: any) => {
                this.gridOvertime();
                ($('#edit_overtime') as any).modal('hide');
                this.resetForm();
                this.toastr.success('Status Changed Sucessfully...!', 'Success');
            },(err:any)=>{

                let errors = err.error.data.overtime;
                errors = errors.replace('SQLSTATE[42000]: [Microsoft][ODBC Driver 17 for SQL Server][SQL Server]', 'Error: ');
                this.toastr.error(errors, 'TryAgain')
            })
        }else{
                this.ApiModuleService.list('admin/overtime/create', params).subscribe((data: any) => {
                this.gridOvertime();
                ($('#edit_overtime') as any).modal('hide');
                this.resetForm();
                this.toastr.success('Status Changed Sucessfully...!', 'Success');
            },(err:any)=>{

                let errors = err.error.data.overtime;
                errors = errors.replace('SQLSTATE[42000]: [Microsoft][ODBC Driver 17 for SQL Server][SQL Server]', 'Error: ');
                this.toastr.error(errors, 'TryAgain')
            })
        }

    }
    resetForm(){
        this.overTimeForm.reset();
        this.statusChangeData = [];
        this.employeeDropDown = [];
        this.chooseButton = '';
        this.reject_reason = '';

    }

    callStatusModel(url_name:any,data:any,actionName:string){
      this.statusApi=url_name;
      this.statusValues=data;
      this.statusName=actionName;
  }
}
