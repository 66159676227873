import { Component, OnInit, ViewChild } from '@angular/core';
import{ApiModulesService} from '../../../api-modules.service';
import{AllModulesService} from '../../../all-modules.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';


@Component({
  selector: 'app-nationalitytype-main',
  templateUrl: './nationalitytype-main.component.html',
  styleUrls: ['./nationalitytype-main.component.css']
})
export class NationalitytypeMainComponent implements OnInit {



  public rows = [];
  public srch = [];
  public nationalitytypeData = [];
  public countryname = [];
  public addNationalityForm: FormGroup;

  public editNationalityForm: FormGroup;
  public selected_country:any;
  isDtInitialized = false;
  public totalRecords: any = 0;
  invalidAddForm=false;
  invalidEditForm=false;
  touched=false;

  nameRegex = /^[A-Za-z]+$/;
  listHeaders=[];
  nameBgColors:any = ['bg-blue-dim','bg-blue-dim','bg-azure-dim','bg-indigo-dim','bg-info-dim','bg-purple-dim','bg-pink-dim','bg-orange-dim','bg-teal-dim','bg-primary-dim','bg-success-dim','bg-warning-dim','bg-danger-dim','bg-secondary-dim','bg-dark-dim','bg-gray-dim','bg-blue-dim','bg-azure-dim','bg-indigo-dim','bg-info-dim','bg-purple-dim','bg-pink-dim','bg-orange-dim','bg-teal-dim','bg-primary-dim','bg-success-dim','bg-warning-dim','bg-danger-dim','bg-secondary-dim','bg-dark-dim','bg-gray-dim'];
    nameBorderColors:any = ['bg-outline-primary','bg-outline-success','bg-outline-info','bg-outline-gray','bg-outline-warning','bg-outline-dark','bg-outline-light','bg-outline-secondary','bg-outline-danger'];


  constructor(private apiModulesService: ApiModulesService, private toastr: ToastrService, private formBuilder: FormBuilder, private AllModulesService: AllModulesService) { }

  ngOnInit(): void {

    this.getCountrinesName();


    this.addNationalityForm = this.formBuilder.group({
      country_id: ['', [Validators.required]],
      nationality_type_name: ['', [Validators.required, Validators.pattern(this.nameRegex)]],
      nationality_type_code: ['', [Validators.required, Validators.pattern(this.nameRegex)]],
    });

    this.editNationalityForm = this.formBuilder.group({
      country_id: ['', [Validators.required]],
      nationality_type_name: ['', [Validators.required, Validators.pattern(this.nameRegex)]],
      nationality_type_code: ['', [Validators.required, Validators.pattern(this.nameRegex)]],
      nationality_type_id: ['', []],
    });



  }
  rerender(): void {

  }
  ngAfterViewInit(): void {

  }

  //Reset form
  public resetForm() {
    this.addNationalityForm.reset();
  }
  //add data
  public onAddnationality() {
    if (this.addNationalityForm.invalid) {
      this.addNationalityForm.markAllAsTouched();
      return;
    }
    var params: any = {};
    params.nationality_type_name = this.addNationalityForm.get('nationality_type_name').value;
    params.nationality_type_code = this.addNationalityForm.get('nationality_type_code').value;
    params.country_id = this.addNationalityForm.get('country_id').value;
    params.status = 1;
    this.apiModulesService.add(params, 'admin/nationality-type/add',).subscribe((data) => {

      this.toastr.success('Nationality added successfully...!', 'Success');
      // this.selected_country=this.countryname[0].country_id;

      this.selected_country=this.addNationalityForm.get('country_id').value;
      this.getNationality('rerender', function (){});

      this.addNationalityForm.reset();
      ($('#add_nationality') as any).modal('hide');

  },(err)=>{
    if (err.status === 437) {
        this.toastr.error('Nationality Already Exists...!', 'Failed');
    } else {
        this.toastr.error('Something Went Wrong Please Try Again...!', 'Error');
    }
});
  }
  //get data
  public getNationality(callFrom: any, callback: any) {
    var params:any={};
    params.country_id=this.selected_country;
    this.apiModulesService.list('admin/nationality-type/list',params).subscribe((data) => {
            this.nationalitytypeData = (data.data.nationality_type != undefined) ? data.data.nationality_type : [];
            var totalRecords = this.nationalitytypeData.length;
            this.totalRecords = totalRecords;
    setTimeout(function () {
      var firstcolwidth = $('.table.custom-table>tbody>tr>td.fixed:first-child').width();
      var lastcolwidth = $('.table.custom-table>thead>tr>th:last-child').width();
      $('.table.custom-table>thead>tr>th.fixed:nth-child(2)').css('left', firstcolwidth + 20);
      $('.table.custom-table>tbody>tr>td.fixed:nth-child(2)').css('left', firstcolwidth + 20);
      $('.table.custom-table>thead>tr>th:nth-last-child(2)').css('right', lastcolwidth + 20);
      $('.table.custom-table>tbody>tr>td:nth-last-child(2)').css('right', lastcolwidth + 20);
  }, 2000);
    callback({
      recordsTotal: totalRecords,
      recordsFiltered: totalRecords,
      data: []
  });
//  this.industriesData = gridData;

      });
  }


  //get countries name
  public getCountrinesName() {
    this.apiModulesService.get('country/namelist').subscribe((data) => {
      this.countryname = data;
      this.selected_country=this.countryname[0].country_id;
      this.getNationality('rerender', function (){});
    });
  }

  //edit value get
  public onEditNationality(NationalityTypeId: any) {
    var params: any = {};
    params.nationality_type_id = NationalityTypeId;
    this.apiModulesService.edit(params, 'admin/nationality-type/edit').subscribe((data) => {
      let nationalityDetail = data.data.nationality_type;
      this.editNationalityForm.setValue({
        nationality_type_name: nationalityDetail.nationality_type_name,
        nationality_type_code: nationalityDetail.nationality_type_code,
        country_id: nationalityDetail.country_id,
        nationality_type_id: nationalityDetail.nationality_type_id,

      });
     let setCountry= this.countryname.filter((data:any)=>nationalityDetail.country_id === data.country_id);
    });

  }
  //edit update
  public onEditChanges() {
    if (this.editNationalityForm.invalid ) {

      this.editNationalityForm.markAllAsTouched();
      return;
    }

    var params: any = {};
    params.nationality_type_name = this.editNationalityForm.get('nationality_type_name').value;
    params.nationality_type_code = this.editNationalityForm.get('nationality_type_code').value;
    params.country_id = this.editNationalityForm.get('country_id').value;
    params.nationality_type_id = this.editNationalityForm.get('nationality_type_id').value;
    this.apiModulesService.edit(params, 'admin/nationality-type/update').subscribe((data) => {

        this.toastr.success('Nationality added successfully...!', 'Success');
         this.selected_country=this.editNationalityForm.get('country_id').value;
        this.getNationality('rerender', function (){});
        ($('#edit_nationality') as any).modal('hide');

    },(err)=>{
      if (err.status === 437) {
          this.toastr.error('Nationality Already Exists...!', 'Failed');
      } else {
          this.toastr.error('Something Went Wrong Please Try Again...!', 'Error');
      }
  }
    );
  }
  // status change
  changeStatus(status: any, NationalityTypeId: any) {

    var params: any = {};
    params.nationality_type_id = NationalityTypeId;
    params.status = status;
    this.apiModulesService.edit(params, 'admin/nationality-type/status').subscribe((data) => {
      this.getNationality('rerender', function (){});
      this.toastr.success('Status changed successfully...!', 'Success');
    },(err)=>{
      this.toastr.error('Something went wrong!', 'Try Again');
    }
    );
  }
  getAcronym(str:any){
    var matches = str.match(/\b(\w)/g); // ['J','S','O','N']
    matches= matches.slice(0,2);
    var acronym = matches.join(''); // JSON
    return acronym;
}
getBgColors(str:any){
    var min = 0;
    var max = 15;
    var index = this.findAlphapositions(str,1);
    return this.nameBgColors[index];
}
findAlphapositions(str:any, n:any)
{
    var NUM = 31;

    return str[0].charCodeAt(0) & NUM;
}
}

