import {Component, OnInit} from '@angular/core';
import {ApiModulesService} from '../../api-modules.service';
import {ToastrService} from 'ngx-toastr';
import {FormBuilder} from '@angular/forms';
import {SpinnerService} from '../../../snipper/spinner.service';
import {Table} from 'primeng/table';
import {documentservice} from '../../documents.sevice';

@Component({
    selector: 'app-employee-pay-info',
    templateUrl: './employee-pay-info.component.html',
    styleUrls: ['./employee-pay-info.component.css']
})
export class EmployeePayInfoComponent implements OnInit {
    ClientDropDown = [];
    CompanyDropDown = [];
    monthYearDropDown = [];
    overallMonthData = [];
    EmployeesData = [];
    employeeData = [];
    searchHeaders = [];
    headerList = [];
    ClientID: any;
    CompanyID: any;
    chooseMonth: any;
    employeeID: any;
    chooseYear: any;
    monthYear: any;
    searchKeyword:any;
    showForm =false;
    user_type=localStorage.getItem('user_type');
    nameBgColors: any = ['bg-blue-dim', 'bg-blue-dim', 'bg-azure-dim', 'bg-indigo-dim', 'bg-info-dim', 'bg-purple-dim', 'bg-pink-dim', 'bg-orange-dim', 'bg-teal-dim', 'bg-primary-dim', 'bg-success-dim', 'bg-warning-dim', 'bg-danger-dim', 'bg-secondary-dim', 'bg-dark-dim', 'bg-gray-dim', 'bg-blue-dim', 'bg-azure-dim', 'bg-indigo-dim', 'bg-info-dim', 'bg-purple-dim', 'bg-pink-dim', 'bg-orange-dim', 'bg-teal-dim', 'bg-primary-dim', 'bg-success-dim', 'bg-warning-dim', 'bg-danger-dim', 'bg-secondary-dim', 'bg-dark-dim', 'bg-gray-dim'];
    nameBorderColors: any = ['bg-outline-primary', 'bg-outline-success', 'bg-outline-info', 'bg-outline-gray', 'bg-outline-warning', 'bg-outline-dark', 'bg-outline-light', 'bg-outline-secondary', 'bg-outline-danger'];
    activeTab = 'all';
    EmployeesRecurringData = [];
    OverallMonthData = [];
    
    HeaderList = [];
    EmployeesNonRecurringData = [];
    OverallNonMonthData = [];
    HeaderNonrecurringList = [];
    HeaderEmployeeDataList = [];

    constructor(private Documentservice:documentservice,private apiModulesService: ApiModulesService, private toaster: ToastrService, private toastr: ToastrService, private formBuilder: FormBuilder, private spinnerService: SpinnerService,) {
    }

    ngOnInit() {
        this.spinnerService.raiseDataEmitterEvent('on');
        this.GetClientDropDown();
    }

    setActiveTab(tab: string) {
        this.activeTab = tab;
        if (tab === 'all') {
          this.EmployeePayInfoDetails();
        }
        if(tab === 'recurring'){
          this.EmployeeRecurringDetails();
        }
        if(tab === 'non_recurring'){
          this.EmployeeNonRecurringDetails();
        }
        if(tab === 'employee_data'){
          this.EmployeeData();
        }
    }

    public GetClientDropDown() {
        this.apiModulesService.get('DropDown/client').subscribe((data) => {
            this.ClientDropDown = data.data.client != undefined ? data.data.client : [];

            this.ClientID = data.data.client[0].client_id;
            this.GetCompanyDropDown();

        }, err => {
            this.ClientDropDownReRender();
        })
    }

    /* company DropDown codes */
    public GetCompanyDropDown() {
        const params: any = {};
        params.client_id = this.ClientID;

        this.apiModulesService.list('DropDown/company', params).subscribe((data) => {
                this.CompanyDropDown = data.data.company != undefined ? data.data.company : [];
                if (this.CompanyDropDown.length !== 0) {
                    setTimeout(() => {
                        this.CompanyID = this.CompanyDropDown[0].company_id;
                        this.monthYearList();
                    }, 100)
                }
                else {
                    this.CompanyDropDownReRender();
                }

            }, error => {
                this.CompanyDropDownReRender();
            }
        )
    }

    /* company DropDown codes */
    public monthYearList() {
        const params: any = {};
        params.client_id = this.ClientID;
        params.company_id = this.CompanyID;

        this.apiModulesService.list('admin/payInfo/dropDown', params).subscribe((data) => {
                this.monthYearDropDown = data.data.employee_pay_info != undefined ? data.data.employee_pay_info : [];
                if (this.CompanyDropDown.length !== 0) {
                    setTimeout(() => {
                        this.monthYear = this.monthYearDropDown[0].payroll_period;
                        this.EmployeePayInfoDetails();
                        //this.EmployeeRecurringDetails();
                    }, 100)
                }
                else {
                    this.monthYearListReRender();
                }

            }, error => {
                this.monthYearListReRender();
            }
        )
    }

    EmployeePayInfoDetails() {
        this.spinnerService.raiseDataEmitterEvent('on');
        const params: any = {};
        params.client_id = this.ClientID;
        params.company_id = this.CompanyID;
        params.month_year = this.monthYear;

        this.apiModulesService.list('admin/payInfo/list', params).subscribe((data) => {
                this.EmployeesData = data.data.employee_pay_info[0] != undefined ? data.data.employee_pay_info[0] : [];
                this.overallMonthData = data.data.employee_pay_info[1] != undefined ? data.data.employee_pay_info[1] : [];
                this.searchHeaders = data.data.employee_pay_info[2][0].header_list != undefined ? data.data.employee_pay_info[2][0].header_list : [];
               this.searchHeaders.push('employee_name');
            this.headerList = this.searchHeaders.filter((datas:any)=>datas!=='employee_name');
            this.spinnerService.raiseDataEmitterEvent('off');

            }, error => {
                this.EmployeePayInfoReRender();
            }
        )
    }

    EmployeeRecurringDetails() {
        this.spinnerService.raiseDataEmitterEvent('on');
        const params: any = {};
        params.client_id = this.ClientID;
        params.company_id = this.CompanyID;
        params.month_year = this.monthYear;

        this.apiModulesService.list('admin/payInfo/recurringlist', params).subscribe((data) => {
                this.EmployeesRecurringData = data.data.employee_recurring[0] != undefined ? data.data.employee_recurring[0] : [];
                this.OverallMonthData = data.data.employee_recurring[1] != undefined ? data.data.employee_recurring[1] : [];
                this.searchHeaders = data.data.employee_recurring[2][0].headerlist != undefined ? data.data.employee_recurring[2][0].headerlist : [];
               this.searchHeaders.push('employee_name');
            this.HeaderList = this.searchHeaders.filter((datas:any)=>datas!=='employee_name');
            this.spinnerService.raiseDataEmitterEvent('off');

            }, error => {
                this.EmployeePayInfoReRender();
            }
        )
    }

    EmployeeNonRecurringDetails() {
        this.spinnerService.raiseDataEmitterEvent('on');
        const params: any = {};
        params.client_id = this.ClientID;
        params.company_id = this.CompanyID;
        params.month_year = this.monthYear;

        this.apiModulesService.list('admin/payInfo/nonrecurringlist', params).subscribe((data) => {
                this.EmployeesNonRecurringData = data.data.employee_non_recurring[0] != undefined ? data.data.employee_non_recurring[0] : [];
                this.OverallNonMonthData = data.data.employee_non_recurring[1] != undefined ? data.data.employee_non_recurring[1] : [];
                this.searchHeaders = data.data.employee_non_recurring[2][0].header_non_recurring_list != undefined ? data.data.employee_non_recurring[2][0].header_non_recurring_list : [];
               this.searchHeaders.push('employee_name');
            this.HeaderNonrecurringList = this.searchHeaders.filter((datas:any)=>datas!=='employee_name');
            this.spinnerService.raiseDataEmitterEvent('off');

            }, error => {
                this.EmployeePayInfoReRender();
            }
        )
    }

    EmployeeData(){
        this.spinnerService.raiseDataEmitterEvent('on');
        const params:any={};
        params.client_id = this.ClientID;
        params.company_id = this.CompanyID;
        params.month_year = this.monthYear;

        this.apiModulesService.list('admin/payInfo/employeedata',params).subscribe((data)=>{
            this.employeeData = data.data.employee_data[0] != undefined ? data.data.employee_data[0] : [];
                this.searchHeaders = data.data.employee_data[1][0].header_employeedata_list != undefined ? data.data.employee_data[1][0].header_employeedata_list : [];

               this.searchHeaders.push('employee_name');
            this.HeaderEmployeeDataList = this.searchHeaders.filter((datas:any)=>datas!=='employee_name');
            this.spinnerService.raiseDataEmitterEvent('off');

            }, error => {
                this.EmployeePayInfoReRender();
            
        });
    }

    updatePayInfoDetails() {
        this.spinnerService.raiseDataEmitterEvent('on');
        const params: any = {};
        params.client_id = this.ClientID;
        params.company_id = this.CompanyID;
        params.month_year = this.monthYear;
        this.apiModulesService.list('admin/payInfo/update', params).subscribe((data) => {
          this.toaster.success('Updated Status Sucessfully...!', 'Success');
          ($('#pay_run_status') as any).modal('hide');
            this.spinnerService.raiseDataEmitterEvent('off');

            }, error => {
                this.EmployeePayInfoReRender();
            }
        )
    }

    EmployeePayInfoReRender() {
        this.overallMonthData = [];
        this.EmployeesData = [];
        this.headerList = [];
        this.HeaderList = [];
        this.spinnerService.raiseDataEmitterEvent('off');
    }

    monthYearListReRender() {
        this.monthYear = null;
        this.monthYearDropDown = [];
        this.EmployeePayInfoReRender();
    }

    CompanyDropDownReRender() {
        this.CompanyID = null;
        this.CompanyDropDown = [];
        this.monthYearDropDown = [];
        this.monthYearListReRender();
    }

    ClientDropDownReRender() {
        this.ClientID = null;
        this.ClientDropDown = [];
        this.monthYearDropDown = [];
        this.CompanyDropDownReRender();
    }
    clear(table: Table) {
        table.clear();
    }

    getAcronym(str: any) {
        if (str != null) {
            let matches = str.match(/\b(\w)/g); // ['J','S','O','N']
            matches = matches.slice(0, 2);
            const acronym = matches.join(''); // JSON
            return acronym;
        }
    }

    getBgColors(str: any) {
        if (str != null) {
            const min = 0;
            const max = 15;
            const index = this.findAlphapositions(str, 1);
            return this.nameBgColors[index];
        }
    }
    findAlphapositions(str: any, n: any) {
        const NUM = 31;

        return str[0].charCodeAt(0) & NUM;
    }

    downloadExcel(){
        this.spinnerService.raiseDataEmitterEvent('on');
        const params: any = {};
        params.client_id = this.ClientID;
        params.company_id = this.CompanyID;
        params.month_year = this.monthYear;

        this.apiModulesService.list('admin/payInfo/download', params).subscribe((data) => {

            const fileDetails=data.data.employee_pay_info.file;
            this.Documentservice.DownloadExcel(fileDetails.name,fileDetails.excel)
            this.spinnerService.raiseDataEmitterEvent('off');
            }, error => {
                this.EmployeePayInfoReRender();
            }
        )
    }
    employeepayslipShow(employeeID){
        const monthyear=this.monthYear.split('-');


        this.showForm=true;
        this.employeeID=employeeID;
        this.chooseMonth=monthyear[0];
        this.chooseYear=monthyear[1];
    }

    showEmployeeGrid(event){
        this.showForm=event.value;
        this.setActiveTab(this.activeTab)
    }
}
