import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class InvestmentDeclarationService {

    constructor() {
    }


    public regimeCalculation(grossSalary: number, HRATotal: number, Standard_deduction: number, pt: number, homeLoan: number, otherIncome: number, lessSection: number):object{
        const regimeCalculation: any = {};
        regimeCalculation.old_regime = this.oldRegime(grossSalary, HRATotal, Standard_deduction, pt, homeLoan, otherIncome, lessSection);
        regimeCalculation.new_regime = this.newRegime(grossSalary, lessSection);
        return regimeCalculation;
    }

    protected oldRegime(grossSalary: number, HRATotal: number, Standard_deduction: number, pt: number, homeLoan: number, otherIncome: number, lessSection: number) {
        const HRA = HRATotal;
        const netSalary = grossSalary - HRA;
        const StandardDeduction = Standard_deduction + pt;
        const netTaxableSalary = netSalary - StandardDeduction;
        const grossTotalIncome = netTaxableSalary + homeLoan + otherIncome;
        const taxableIncome = grossTotalIncome - lessSection;

        const oldRegime: any = {};

        const taxPayable = this.oldTaxPayableIncome(taxableIncome);
        const surCharge = this.surCharge(taxPayable);
        const cess = (surCharge + taxPayable) * (4 / 100);
        const totalTaxPayable = taxPayable + surCharge + cess;
        const taxPerMonth = (totalTaxPayable) / 12;
        oldRegime.grossSalary = grossSalary;
        oldRegime.netSalary = netSalary;
        oldRegime.netTaxbleSalary = netTaxableSalary;
        oldRegime.grossTotalincome = grossTotalIncome;
        oldRegime.taxableIncome = taxableIncome;
        oldRegime.taxPayable = taxPayable;
        oldRegime.surCharge = surCharge;
        oldRegime.cess = cess;
        oldRegime.totalTaxPayable = totalTaxPayable;
        oldRegime.taxpermonth = taxPerMonth;
        oldRegime.HRA = HRA;
        return oldRegime;
    }


    protected oldTaxPayableIncome(taxableIncome: number) {
        if (taxableIncome <= 250000) {

            return this.zeroTo2_5L();
        }
        else if (taxableIncome <= 500000) {

            return this.zeroTo2_5L() + this._2_5LTo5L(taxableIncome)
        }
        else if (taxableIncome <= 1000000) {

            return this.zeroTo2_5L() + this._2_5LTo5L(taxableIncome) + this._5LTo10L(taxableIncome)
        }
        else if (taxableIncome > 1000000) {
           
            return this.zeroTo2_5L() + this._2_5LTo5L(taxableIncome) + this._5LTo10L(taxableIncome) + this._above10L(taxableIncome)
        }
    }

    protected surCharge(taxPayable: number): number {
        return (taxPayable) * (10 / 100);
    }

    protected zeroTo2_5L() {
        return 0;
    }

    protected _2_5LTo5L(taxableIncome: number): number {
        if (taxableIncome >= 500000) {
            const subTaxval = 500000 - 250000;
            return subTaxval * (5 / 100);
        }
        else if (taxableIncome < 500000) {
            const subTaxval = taxableIncome - 250000;
            return subTaxval * (5 / 100);
        }
    }

    protected _5LTo10L(taxableIncome: number): number {
        if (taxableIncome >= 1000000) {
            const subTaxval = 1000000 - 500000;
            return subTaxval * (20 / 100);
        }
        else if (taxableIncome < 1000000) {
            const subTaxval = taxableIncome - 500000;
            return subTaxval * (20 / 100);
        }
    }

    protected _above10L(taxableIncome: number): number {
        const subTaxval = taxableIncome - 1000000;
        return subTaxval * (30 / 100);
    }

    protected newRegime(grossSalary: number, lessSection: number) {
        const netSalary = grossSalary;
        const taxableIncome = grossSalary - lessSection;
        const taxPayable = this.newTaxPayableIncome(taxableIncome);
        const surCharge = this.surcharge(taxPayable);
        const cess = (taxPayable + surCharge) * (4 / 100);
        const totalTaxPayable = taxPayable + surCharge + cess;
        const taxPerMonth = (totalTaxPayable) / 12;
        const newRegime: any = {};
        newRegime.grossSalary = grossSalary;
        newRegime.netSalary = netSalary;
        newRegime.netTaxbleSalary = netSalary;
        newRegime.grossTotalincome = netSalary;
        newRegime.taxableIncome = taxableIncome;
        newRegime.taxPayable = taxPayable;
        newRegime.surCharge = surCharge;
        newRegime.cess = cess;
        newRegime.totalTaxPayable = totalTaxPayable;
        newRegime.taxpermonth = taxPerMonth;
        newRegime.HRA = 0;
        return newRegime
    }

    protected newTaxPayableIncome(taxableIncome: number) {
        if (taxableIncome <= 300000) {
            return this.zeroTo3L();
        } else if (taxableIncome <= 600000) {
            return this.zeroTo3L() + this._3To6L(taxableIncome);
        } else if (taxableIncome <= 900000) {
            return this.zeroTo2_5L() + this._3To6L(taxableIncome) + this._6To9L(taxableIncome)
        } else if (taxableIncome <= 1200000) {
            return this.zeroTo2_5L() + this._3To6L(taxableIncome) + this._6To9L(taxableIncome) + this._9To12L(taxableIncome)
        } else if (taxableIncome <= 1500000) {
            return this.zeroTo2_5L() + this._3To6L(taxableIncome) + this._6To9L(taxableIncome) + this._9To12L(taxableIncome) + this._12To15L(taxableIncome)
        } else if (taxableIncome > 1500000) {
            return this.zeroTo2_5L() + this._3To6L(taxableIncome) + this._6To9L(taxableIncome) + this._9To12L(taxableIncome) + this._12To15L(taxableIncome) + this._above15L(taxableIncome)
        }
    }

    protected surcharge(taxPayable: number) {
        return taxPayable * (10 / 100);
    }

    protected zeroTo3L() {
        return 0;
    }

    protected _3To6L(taxableIncome: number) {
        if (taxableIncome >= 600000) {
            const subTaxval = 600000 - 300000;
            return subTaxval * (5 / 100);
        }
        else if (taxableIncome < 600000) {
            const subTaxval = taxableIncome - 300000;
            return subTaxval * (5 / 100);
        }
    }

    protected _6To9L(taxableIncome: number) {
        if (taxableIncome >= 900000) {
            const subTaxval = 900000 - 600000;
            return subTaxval * (10 / 100);
        }
        else if (taxableIncome < 900000) {
            const subTaxval = taxableIncome - 600000;
            return subTaxval * (10 / 100);
        }
    }

    protected _9To12L(taxableIncome: number) {
        if (taxableIncome >= 1200000) {
            const subTaxval = 1200000 - 900000;
            return subTaxval * (15 / 100);
        }
        else if (taxableIncome < 1200000) {
            const subTaxval = taxableIncome - 900000;
            return subTaxval * (15 / 100);
        }
    }

    protected _12To15L(taxableIncome: number) {
        if (taxableIncome >= 1500000) {
            const subTaxval = 1500000 - 1200000;
            return subTaxval * (20 / 100);
        }
        else if (taxableIncome < 1500000) {
            const subTaxval = taxableIncome - 1200000;
            return subTaxval * (20 / 100);
        }
    }

    protected _above15L(taxableIncome: number) {
        const subTaxval = taxableIncome - 1500000;
        return subTaxval * (30 / 100);
    }


}
