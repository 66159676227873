import { Component } from '@angular/core';
import { data, param } from 'jquery';
import { ApiModulesService } from 'src/app/all-modules/api-modules.service';
import { SpinnerService } from 'src/app/snipper/spinner.service';
import { EmployeeDropdownService } from '../../azatecon-services/employee-dropdown.service';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray, NG_ASYNC_VALIDATORS, } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';




@Component({
  selector: 'app-employee-payroll-engine',
  templateUrl: './employee-payroll-engine.component.html',
  styleUrls: ['./employee-payroll-engine.component.css']
})
export class EmployeePayrollEngineComponent {
  public totalRecords: any = 0;
  loading = false;
  indpayrollData: any;
  clientID = localStorage.getItem('client_id');
  companyID = localStorage.getItem('company_id');
  payrollData: any;
  no_of_days: any;
  no_of_paid_days: any;
  lop: any;
  lop_reversal: any;
  calculationData: any;
  ClientID: any;
  payID: any;
  CompanyID: any;
  EmployeeID: any;
  DepartmentID: any;
  DesignationID: any;
  ClientDropDown = [];
  CompanyDropDown = [];
  payrollDropDown = [];
  DepartmentDropDown = [];
  employeeDropdown = [];
  departmentDropDown: any = [];
  designationDropdown: any = [];
  DesignationDropDown = [];
  designationDropDown: object[];
  addPayrollengineForm: FormGroup;
  selectedDate: Date[] = [];
  payrollGetmonth: any;
  overtimeDropDown = [];
  constructor(private apiModulesService: ApiModulesService, private spinnerService: SpinnerService, private employeeService: EmployeeDropdownService, private formBuilder: FormBuilder, private toastrService: ToastrService) { }

  ngOnInit(): void {
    this.spinnerService.raiseDataEmitterEvent('off');
    this.GetClientDropDown();
    //this.overtimedropdown();
    //this.PayrollCalculation();
    // this.addItems();
    // this.addPayrollengineForm = new FormGroup({
    //   selectedDate: new FormControl()
    // });


    //Add
    this.addPayrollengineForm = this.formBuilder.group({
      company_id: ['', []],
      client_id: ['', []],
      employee_id: ['', []],
      no_of_days: ['', [Validators.required]],
      no_of_paid_days: ['', [Validators.required]],
      lop: ['', []],
      type_of_lop: ['', []],
      selected_date: [''],
      pay_of_lop: this.formBuilder.array([]),
      all_overtime: this.formBuilder.array([])

    });


  }



  get itemList(): FormArray {


    return this.addPayrollengineForm.get('pay_of_lop') as FormArray;


  }
  get itemsList(): FormArray {

    return this.addPayrollengineForm.get('all_overtime') as FormArray;


  }

  newItem(): FormGroup {
    return this.formBuilder.group({
      lop_reversal: [''],
      previous_lop: [''],
      month_pay_id: ['', [Validators.required]],
      lop_reversal_dates: [''],
      previous_lop_dates: ['']
    });
  }
  newItems(): FormGroup {
    return this.formBuilder.group({
      component_value: [''],
      month_days: [''],
      component_id: [''],
    });
  }

  addItem() {

    this.itemList.push(this.newItem());

  }
  addItems() {

    this.itemsList.push(this.newItems());

  }

  removeItem(index) {

    this.itemList.removeAt(index);

  }
  removeItems(index) {

    this.itemsList.removeAt(index);

  }

  handleDateSelection(date: Date) {
    // Update selectedDates array
    if (!this.selectedDate.includes(date)) {
      this.selectedDate.push(date);
    } else {
      this.selectedDate = this.selectedDate.filter(d => d !== date);
    }

    // Update form control value
    this.addPayrollengineForm.get('selectedDate').setValue(this.selectedDate);
  }




  //Get all INDpayroll data
  public indgetlist() {
    var params: any = {}
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    params.employee_id = this.EmployeeID;
    this.apiModulesService.list("admin/payrollengine/list", params).subscribe((data) => {
      this.payID = (data.data.payroll.pay_id != undefined) ? data.data.payroll.pay_id : [];
      this.loading = false;
      var totalRecords = (this.indpayrollData != undefined) ? this.indpayrollData.total_records : 0;
      this.totalRecords = totalRecords;


      this.spinnerService.raiseDataEmitterEvent('off');
      //his.getDropdownmonth();
      this.PayrollCalculation();



    });
  }

  public PayrollCalculation() {
    var params: any = {}
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    params.employee_id = this.EmployeeID;
    params.pay_id = this.payID;
    this.apiModulesService.list("admin/payrollengine/getcalculation", params).subscribe((data) => {
      this.calculationData = (data.data.payroll != undefined) ? data.data.payroll : [];
      this.loading = false;
      var totalRecords = (this.calculationData != undefined) ? this.calculationData.total_records : 0;
      this.totalRecords = totalRecords;


      this.spinnerService.raiseDataEmitterEvent('off');
      //his.getDropdownmonth();

      this.resetForm();

    });
  }

  public resetForm() {
    this.addPayrollengineForm.reset();
}


  // add  and update
  public onAddpayroll() {
    if (!this.addPayrollengineForm.valid) {
      this.addPayrollengineForm.markAllAsTouched();
      return;
    }
    var params: any = {};
    params.no_of_days = this.addPayrollengineForm.get('no_of_days').value;
    params.no_of_paid_days = this.addPayrollengineForm.get('no_of_paid_days').value;
    params.lop = this.addPayrollengineForm.get('lop').value;
    params.type_of_lop = this.addPayrollengineForm.get('type_of_lop').value;
    params.pay_of_lop = this.addPayrollengineForm.get('pay_of_lop').value;
    params.all_overtime = this.addPayrollengineForm.get('all_overtime').value;
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    params.employee_id = this.EmployeeID;

    //   this.addPayrollengineForm.get('selected_date').value.forEach((date, index) => {
    //     params.pay_of_lopdate[index].lop_date = date;
    // });
    const mypaydate: any = [];
    if (params.type_of_lop == 'date') {
      for (let i = 0; i < this.addPayrollengineForm.get('selected_date').value.length; i++) {
        mypaydate[i] = { 'lop_date': '' }
        mypaydate[i].lop_date = this.addPayrollengineForm.get('selected_date').value[i]
      }

      for (let i = 0; i < this.addPayrollengineForm.get('pay_of_lop').value.length; i++) {
        if (this.addPayrollengineForm.get('pay_of_lop').value[i].lop_reversal_dates.length != 0) {
          params.pay_of_lop[i].lop_reversal = this.addPayrollengineForm.get('pay_of_lop').value[i].lop_reversal_dates.length
        } else {
          params.pay_of_lop[i].lop_reversal = 0;
        }
        if (this.addPayrollengineForm.get('pay_of_lop').value[i].previous_lop_dates.length != 0) {
          params.pay_of_lop[i].previous_lop = this.addPayrollengineForm.get('pay_of_lop').value[i].previous_lop_dates.length
        } else {
          params.pay_of_lop[i].previous_lop = 0;
        }
      }

    }
    for(let i = 0; i < this.addPayrollengineForm.get('all_overtime').value.length; i++){
      this.addPayrollengineForm.get('all_overtime').value[i]
    }
    params.pay_of_lopdate = mypaydate;



    this.apiModulesService.add(params, "admin/payrollengine/update",).subscribe((data) => {
      this.indgetlist();
      // Assuming this.addPayrollengineForm is your FormGroup
      const payOfLopFormArray = this.addPayrollengineForm.get('pay_of_lop') as FormArray;
      payOfLopFormArray.clear();

      // const overtimeFormArray = this.addPayrollengineForm.get('all_overtime') as FormArray;
      // overtimeFormArray.clear();

      this.resetForm();
      this.toastrService.success("payroll Added Sucessfully...!", "Success");
    }, err => {
      this.toastrService.error("Plan Name Already Exists...?", "Failed");
    });
  }

  /* client DropDown codes*/
  public GetClientDropDown() {
    this.apiModulesService.get('DropDown/client').subscribe((data) => {
      this.ClientDropDown = data.data.client;

      this.ClientID = data.data.client[0].client_id;
      this.GetCompanyDropDown();
    })

  }

  /* company DropDown codes */
  public GetCompanyDropDown() {
    const params: any = {};
    params.client_id = this.ClientID;
    // //
    this.apiModulesService.list('DropDown/company', params).subscribe((data) => {
      this.CompanyDropDown = data.data.company != undefined ? data.data.company : [];
      if (this.CompanyDropDown.length !== 0) {
        setTimeout(() => {
          this.CompanyID = this.CompanyDropDown[0].company_id;
          this.getDepartmentDropDown();


        }, 100);
      }
      else {
        this.CompanyID = null;
      }

    }
    )
  }

  getDepartmentDropDown() {
    let promise = new Promise((resolve, reject) => {
      let overallDropDown: any = this.employeeService.OverallDropDownData(this.ClientID, this.CompanyID);
      resolve(overallDropDown);
      reject([]);
    });
    promise.then((result) => {
      this.departmentDropDown = result
      this.DepartmentID = this.departmentDropDown[0].company_department_id
      setTimeout(() => {
        this.getDesignationDropDown();
      }, 100)
      return result
    }).catch((response) => {
      return [];
    });
  }


  getDesignationDropDown() {

    if (this.DepartmentID != null && this.DepartmentID != '') {
      this.designationDropdown = this.employeeService.designationDropDown(this.DepartmentID)

      this.DesignationID = this.designationDropdown[0].designation_id;
      this.getEmployeeDropDown();
    }
  }


  getEmployeeDropDown() {
    if ((this.DesignationID != null && this.DesignationID != '') && (this.DepartmentID != null && this.DepartmentID != '')) {
      this.employeeDropdown = this.employeeService.employeeDropDown(this.DepartmentID, this.DesignationID);

      this.EmployeeID = this.employeeDropdown[0].employee_id;
      this.getDropdownmonth();
      this.overtimedropdown();

    }
  }

  public getDropdownmonth() {
    var params: any = {}
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    params.employee_id = this.EmployeeID;
    this.apiModulesService.list('admin/payrollengine/dropdownmonth', params).subscribe((data) => {

      this.payrollDropDown = data.data.payroll;
    });
  }

  public overtimedropdown(){
    var params: any = {}
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    params.employee_id = this.EmployeeID;
    this.apiModulesService.list('admin/payrollengine/overtimedropdown',params).subscribe((data)=>{

      this.overtimeDropDown = data.data.payroll;
    })
  }

  public getMonthname() {
    var params: any = {}
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    params.employee_id = this.EmployeeID;
    params.pay_id = this.payID;
    this.apiModulesService.list('admin/payrollengine/getmonth', params).subscribe((data) => {

      this.payrollGetmonth = data.data.payroll;
    });
  }
}

