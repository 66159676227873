import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { ApiModulesService } from '../../api-modules.service';
import { AllModulesService } from "../../all-modules.service";

import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription, Subject, } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { SpinnerService } from 'src/app/snipper/spinner.service';
import { Table } from 'primeng/table';
declare const $: any;
@Component({
  selector: 'app-company-financialyear',
  templateUrl: './company-financialyear.component.html',
  styleUrls: ['./company-financialyear.component.css']
})
export class CompanyFinancialyearComponent implements OnInit, OnDestroy, AfterViewInit {


  public companyfinanceData = [];
  public listHeaders = [];
  rows: any;
  public srch = [];
  search: any[];
  ClientDropDown = [];
  CompanyDropDown = [];
  public addFinanceForm: FormGroup
  public clientname = [];
  isDtInitialized: boolean = false;
  public totalRecords: any = 0;
  public ShowForm: any = false;
  searchKeyword: string = '';
  loading = false;
  ClientID = localStorage.getItem('client_id');
  CompanyID = localStorage.getItem('company_id');
  nameBgColors: any = ["bg-blue-dim", "bg-blue-dim", "bg-azure-dim", "bg-indigo-dim", "bg-info-dim", "bg-purple-dim", "bg-pink-dim", "bg-orange-dim", "bg-teal-dim", "bg-primary-dim", "bg-success-dim", "bg-warning-dim", "bg-danger-dim", "bg-secondary-dim", "bg-dark-dim", "bg-gray-dim", "bg-blue-dim", "bg-azure-dim", "bg-indigo-dim", "bg-info-dim", "bg-purple-dim", "bg-pink-dim", "bg-orange-dim", "bg-teal-dim", "bg-primary-dim", "bg-success-dim", "bg-warning-dim", "bg-danger-dim", "bg-secondary-dim", "bg-dark-dim", "bg-gray-dim"];
  nameBorderColors: any = ["bg-outline-primary", "bg-outline-success", "bg-outline-info", "bg-outline-gray", "bg-outline-warning", "bg-outline-dark", "bg-outline-light", "bg-outline-secondary", "bg-outline-danger"];

  financialYearStatus = [
    {
      status_key: "current",
      status_value: "Current"
    },
    {
      status_key: "active",
      status_value: "Active"
    },
    {
      status_key: "closed",
      status_value: "Closed"
    }

  ]
  employeeOptionsData = [
    {
      option_key: "employee_option",
      option_label: "Employee Options"
    },
    {
      option_key: "12%_on_15,000",
      option_label: "12% on 15,000"
    },
    {
      option_key: "12%_on_basic",
      option_label: "12% on Basic"
    }

  ]
  statuses: { label: string; value: string; }[];
  statusValues: any;
  statusApi: any;
  constructor(private apiModulesService: ApiModulesService, private AllModulesService: AllModulesService, private toaster: ToastrService, private toastr: ToastrService, private formBuilder: FormBuilder, private spinnerService: SpinnerService,) { }

  ngOnInit(): void {
    this.spinnerService.raiseDataEmitterEvent('on');

    this.GetClientDropDown();
    this.addFinanceForm = this.formBuilder.group({
      financial_label: ["", [Validators.required]],
      start_date: ["", [Validators.required]],
      client_id: ["", []],
      company_id: ["", []],
      end_date: ["", [Validators.required]],
      financial_year_status: ["", [Validators.required]],
      company_financial_year_id: ["", []]

    });
    this.statuses = [
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ];
  }

  showAddForm() {
    this.ShowForm = true;
    this.resetForm();
  }
  ngOnDestroy(): void {

  }

  //Get all paycomponent data
  public getCompanyFinance() {
    this.spinnerService.raiseDataEmitterEvent('on');
    var params: any = {};
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    this.apiModulesService.list("admin/company-financialyear/list", params).subscribe((data) => {
      this.companyfinanceData = (data.data.company_financial_year != undefined) ? data.data.company_financial_year : [];
      this.loading = false;
      var totalRecords = (this.companyfinanceData[0] != undefined) ? this.companyfinanceData[0].total_records : 0;
      this.totalRecords = totalRecords;
      this.resetForm();
      setTimeout(() => {
        this.spinnerService.raiseDataEmitterEvent('off');
      }, 100);
    }, (err) => {
      this.companyfinanceData = [];
      this.spinnerService.raiseDataEmitterEvent('off');
      // this.toastr.error('Something went wrong!', 'Try Again');
      // this.rerender();
    });
  }
  clear(table: Table) {
    table.clear();
  }

  getSeverity(status: string) {
    switch (status.toLowerCase()) {
      case 'inactive':
        return 'danger';

      case 'active':
        return 'success';
    }
  }

  changeStatus(Status: any, finance) {
    let params: any = {};
    params.client_id = this.ClientID;
    params.company_financial_year_id = finance.company_financial_year_id,
      params.company_id = this.CompanyID;
    this.apiModulesService.edit(params, 'admin/company-financialyear/' + Status).subscribe((data) => {
      this.getCompanyFinance();
      ($('#companyfinancialyear-status') as any).modal('hide');
      this.toaster.success('Status Changed Successfully!', 'Success')
    }, (err) => {
      let errors = err.error.data.company_financial_year;
      errors = errors.replace('SQLSTATE[42000]: [Microsoft][ODBC Driver 17 for SQL Server][SQL Server]', 'Error: ')
      errors = errors.replace('SQLSTATE[42000]: [Microsoft][ODBC Driver 18 for SQL Server][SQL Server]', 'Error: ')
      this.toastr.error(errors, 'TryAgain');
    }
    );
  }
  public onAddfinance() {
    if (!this.addFinanceForm.valid) {
      this.addFinanceForm.markAllAsTouched();
      return;
    }
    var params: any = {};
    params.company_id = this.CompanyID;
    params.client_id = this.ClientID;
    params.financial_label = this.addFinanceForm.get('financial_label').value;
    params.start_date = this.addFinanceForm.get('start_date').value;
    params.end_date = this.addFinanceForm.get('end_date').value;
    params.financial_year_status = this.addFinanceForm.get('financial_year_status').value;
    var company_financial_year_id = this.addFinanceForm.get('company_financial_year_id').value;
    if (company_financial_year_id != '' && company_financial_year_id != undefined) {
      params.company_financial_year_id = this.addFinanceForm.get('company_financial_year_id').value;
      this.apiModulesService.edit(params, "admin/company-financialyear/update",).subscribe((data) => {

        this.getCompanyFinance();
        this.toastr.success('Financial Year Modified Sucessfully...!', 'Success');
        ($('#add_companyfiance') as any).modal('hide');
      }, (err) => {
        let errors = err.error.data.company_financial_year;
        errors = errors.replace('SQLSTATE[42000]: [Microsoft][ODBC Driver 17 for SQL Server][SQL Server]', 'Error: ')
        errors = errors.replace('SQLSTATE[42000]: [Microsoft][ODBC Driver 18 for SQL Server][SQL Server]', 'Error: ')
        this.toastr.error(errors, 'TryAgain');
      });

    } else {
      this.apiModulesService.add(params, "admin/company-financialyear/add",).subscribe((data) => {
        this.getCompanyFinance();
        this.toastr.success('Financial Year Added Sucessfully...!', 'Success');
        this.addFinanceForm.reset();
        ($('#add_companyfiance') as any).modal('hide');
      }, (err) => {
        let errors = err.error.data.company_financial_year;
        errors = errors.replace('SQLSTATE[42000]: [Microsoft][ODBC Driver 17 for SQL Server][SQL Server]', 'Error: ')
        errors = errors.replace('SQLSTATE[42000]: [Microsoft][ODBC Driver 18 for SQL Server][SQL Server]', 'Error: ')
        this.toastr.error(errors, 'TryAgain');
      });
    }
  }
  //Reset form
  public resetForm() {
    this.addFinanceForm.reset();
  }
  public update(params) {


    this.addFinanceForm.patchValue({

      financial_label: params.financial_label,
      start_date: params.start_date,
      end_date: params.end_date,
      financial_year_status: params.financial_year_status,
      company_financial_year_id: params.company_financial_year_id,
      client_id: params.client_id,
      company_id: params.company_id
    });



  }
  searchName(val) {
    this.rows.splice(0, this.rows.length);
    let temp = this.srch.filter(function (d) {
      val = val.toLowerCase();
      return d.financial_label.toLowerCase().indexOf(val) !== -1 || d.financial_year_status.toLowerCase().indexOf(val) !== -1 || d.start_date.toLowerCase().indexOf(val) !== -1 || d.end_date.toLowerCase().indexOf(val) !== -1
    });
    this.rows.push(...temp);
  }
  ngAfterViewInit(): void {

  }
  rerender() {

  }

  /* client DropDown codes*/
  public GetClientDropDown() {
    this.apiModulesService.get('DropDown/client').subscribe((data) => {
      this.ClientDropDown = data.data.client;
      this.ClientID = data.data.client[0].client_id;
      this.GetCompanyDropDown();
    })
  }

  /* company DropDown codes */
  public GetCompanyDropDown() {
    const params: any = {};
    params.client_id = this.ClientID;
    this.apiModulesService.list('DropDown/company', params).subscribe((data) => {
      this.CompanyDropDown = data.data.company != undefined ? data.data.company : [];
      if (this.CompanyDropDown.length !== 0) {
        setTimeout(() => {
          this.CompanyID = this.CompanyDropDown[0].company_id;
          this.getCompanyFinance();
        }, 100)
      }
      else {
        this.CompanyID = null;
        this.rerender();
      }

    }
    )
  }
  getAcronym(str: any) {
    var matches = str.match(/\b(\w)/g); // ['J','S','O','N']
    matches = matches.slice(0, 2);
    var acronym = matches.join(''); // JSON
    return acronym;
  }
  getBgColors(str: any) {
    var min = 0;
    var max = 15;
    var index = this.findAlphapositions(str, 1);
    return this.nameBgColors[index];
  }
  findAlphapositions(str: any, n: any) {
    var NUM = 31;

    return str[0].charCodeAt(0) & NUM;
  }
  callStatusModel(url_name: any, data: any) {
    this.statusApi = url_name;
    this.statusValues = data;
  }
}
