import { Component, Input, OnInit } from '@angular/core';
import { PayslipIncomeDataService } from './payslip-income-data.component.service';
import { ApiModulesService } from 'src/app/all-modules/api-modules.service';
import { SpinnerService } from 'src/app/snipper/spinner.service';
import { DATE } from 'ngx-bootstrap/chronos/units/constants';
@Component({
  selector: 'app-payslip-formula-popup',
  templateUrl: './payslip-formula-popup.component.html',
  styleUrls: ['./payslip-formula-popup.component.css'],
  providers:[PayslipIncomeDataService]
})

export class PayslipFormulaPopupComponent implements OnInit{

  @Input() popupData: any;
  @Input() clientID: any;
  @Input() companyID: any;
  @Input() employeeID: any;
  @Input() month: any;
  @Input() year: any;
  @Input() monthGross: any;
  @Input() annualGross: any;
  @Input() estimatedTax: any;
  @Input() regime_selected: any;
  @Input() from_month: any;
  lopDays:boolean=false;
  Today :any;
  // regime_selected="Old Regime"
IncomeData:any;
  formuladata: any;
  formuladata1: any;
  formuladata2: [];
  formuladata4: any;
  formuladata3: any;
  formuladata5: any;
  formuladata6: any;
  formuladata7: any;
  constructor(private IncomeService :PayslipIncomeDataService,private apiModulesService: ApiModulesService,private  spinnerService: SpinnerService ) { }
  ngOnInit(): void {
  }


  openPopUp(){


this.lopDays=Number(this.popupData.lop_days)>0?true:false;
if(this.popupData.is_new_popup==1){
  ($('#payslip_formula') as any).modal('show');

}

  }
  openYTDPopUp(){


this.lopDays=Number(this.popupData.lop_days)>0?true:false;
if(this.popupData.is_new_popup==1){
  ($('#ytd_payslip_formula') as any).modal('show');
}}
  singleCalculationPopUp(){
this.lopDays=Number(this.popupData.lop_days)>0?true:false;
if(this.popupData.is_new_popup==1){
  ($('#single_payslip_calculation') as any).modal('show');
}
}
  incomePopUp(){

this.IncomeData=this.IncomeService.tax_data.filter((val:any)=>val.month==this.month && val.year==this.year)[0];

this.lopDays=Number(this.popupData.lop_days)>0?true:false;
if(this.popupData.is_new_popup==1){
  this.payslipFormula();
}
}
payslipFormula() {
  const params: any = {};
  params.client_id = this.clientID;
  params.company_id = this.companyID;
  params.employee_id = this.employeeID;
  params.month_name = this.month;
  params.year_no = this.year;
if(this.month!=null && this.month !=''){
  this.apiModulesService.list('employee-tabs/IND-employee-payroll/payslip/formula', params).subscribe((data) => {

    this.formuladata = data.data.pay_slip[0] != undefined ? data.data.pay_slip[0] : [];
    this.formuladata1 = data.data.pay_slip[1] != undefined ? data.data.pay_slip[1] : [];
    this.formuladata2 = data.data.pay_slip[2] != undefined ? data.data.pay_slip[2] : [];

    this.formuladata3 = data.data.pay_slip[3] != undefined ? data.data.pay_slip[3] : [];
    this.formuladata4 = data.data.pay_slip[4] != undefined ? data.data.pay_slip[4] : [];
    this.formuladata5 = data.data.pay_slip[5] != undefined ? data.data.pay_slip[5] : [];
    this.formuladata6 = data.data.pay_slip[6] != undefined ? data.data.pay_slip[6] : [];
    this.formuladata7 = data.data.pay_slip[7] != undefined ? data.data.pay_slip[7] : [];
    ($('#income_tax_popup') as any).modal('show');

  }, (err: any) => {
    this.spinnerService.toasterError(err);

  })
}

}
}
