// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.view-button{
  padding-top:0.5%;
}
.active{
  color:#00c5fb
}
.claim{
  margin-left: 5px;
  margin-right: 5px;
}
.dropdown{
  margin-right: 15px;
}
.add-btn {
  height: 28px;
  border-radius: 0px;
  text-align: center;
  float: inline-end;
}
.view_generate{
  margin-left: 15%;
  width: 30%;
}
.submit_voucher{
  width: 36%;
}
`, "",{"version":3,"sources":["webpack://./src/app/all-modules/ess-tabs/IND-ess/IND-employee-payroll/reimbursement/reimbursement.component.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;AACA;EACE;AACF;AACA;EACE,gBAAgB;EAChB,iBAAiB;AACnB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;EAClB,iBAAiB;AACnB;AACA;EACE,gBAAgB;EAChB,UAAU;AACZ;AACA;EACE,UAAU;AACZ","sourcesContent":[".view-button{\r\n  padding-top:0.5%;\r\n}\r\n.active{\r\n  color:#00c5fb\r\n}\r\n.claim{\r\n  margin-left: 5px;\r\n  margin-right: 5px;\r\n}\r\n.dropdown{\r\n  margin-right: 15px;\r\n}\r\n.add-btn {\r\n  height: 28px;\r\n  border-radius: 0px;\r\n  text-align: center;\r\n  float: inline-end;\r\n}\r\n.view_generate{\r\n  margin-left: 15%;\r\n  width: 30%;\r\n}\r\n.submit_voucher{\r\n  width: 36%;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
