import { Component } from '@angular/core';
import { SpinnerService } from 'src/app/snipper/spinner.service';
import { Table } from 'primeng/table';

import { Subscription, Subject, } from 'rxjs';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { ApiModulesService } from 'src/app/all-modules/api-modules.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-monthly-shift',
  templateUrl: './monthly-shift.component.html',
  styleUrls: ['./monthly-shift.component.css']
})
export class MonthlyShiftComponent {

public addMonthlyShiftForm: FormGroup;
public ShowForm: any = false;
ClientID :any;
CompanyID :any;
ClientDropDown = [];
CompanyDropDown = [];
public monthlyShiftData=[];
statuses: { label: string; value: string; }[];
statusApi: any;
statusValues: any;



nameBgColors:any = ["bg-blue-dim","bg-blue-dim","bg-azure-dim","bg-indigo-dim","bg-info-dim","bg-purple-dim","bg-pink-dim","bg-orange-dim","bg-teal-dim","bg-primary-dim","bg-success-dim","bg-warning-dim","bg-danger-dim","bg-secondary-dim","bg-dark-dim","bg-gray-dim","bg-blue-dim","bg-azure-dim","bg-indigo-dim","bg-info-dim","bg-purple-dim","bg-pink-dim","bg-orange-dim","bg-teal-dim","bg-primary-dim","bg-success-dim","bg-warning-dim","bg-danger-dim","bg-secondary-dim","bg-dark-dim","bg-gray-dim"];
nameBorderColors:any = ["bg-outline-primary","bg-outline-success","bg-outline-info","bg-outline-gray","bg-outline-warning","bg-outline-dark","bg-outline-light","bg-outline-secondary","bg-outline-danger"];
weekDropdown: any;
weekDropdownData:[];
weekNo: any;
shiftNameRegex= '^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$';


constructor(private apiModulesService: ApiModulesService, private toastr: ToastrService,private formBuilder: FormBuilder, private spinnerService:SpinnerService){  }

   ngOnInit():void{

    this.GetClientDropDown();


    this.addMonthlyShiftForm=this.formBuilder.group({
      client_id: ["", []],
      company_id: ["", []],
      // shift_id: ["", []],
      month_shift_id: ["", []],
      month_shift_name:["", [Validators.required,Validators.pattern(this.shiftNameRegex), Validators.maxLength(40)]],
      month_shift_desc:["", [Validators.required, Validators.maxLength(100)]],
      weeks:this.formBuilder.array([])

    });

    this.statuses = [
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ];
  }
get itemAdd():FormArray{
  return this.addMonthlyShiftForm.get('weeks') as FormArray
}
newItem():FormGroup{
  return this.formBuilder.group({
 weekno:['',[Validators.required]]
  })
}
newItempush(weekvalue):FormGroup{
  return this.formBuilder.group({
 weekno:[weekvalue,[Validators.required]]
  })
}
addItems() {
  this.itemAdd.push(this.newItem());
}
editItems(weekvalue) {
  this.itemAdd.push(this.newItempush(weekvalue));
}

public resetForm() {
  this.addMonthlyShiftForm.reset();
  this.itemAdd.clear();

}
addWeekForms(){
  let length=this.weekNo.length;
  for(let i=0;i<length;i++){
    this.addItems();
  }
}
editWeekForms(value){
  let length=this.weekNo.length;
  for(let i=0;i<length;i++){
    this.editItems(value[i].week_shift_id);
  }
}
  clear(table: Table) {
    table.clear();
  }
  getSeverity(status: string) {
    switch (status.toLowerCase()) {
      case 'inactive':
        return 'danger';

      case 'active':
        return 'success';
    }
  }
/* get all month shift datas */

  public getMonthlyShift(){
    this.spinnerService.raiseDataEmitterEvent('on');
    if (this.CompanyDropDown.length !== 0 && this.CompanyID != null){
      var params: any = {};
      params.client_id = this.ClientID;
      params.company_id =this.CompanyID;

      this.apiModulesService.list("admin/monthly-shift/get",params).subscribe((data)=>{
      this.monthlyShiftData = (data.data.monthly_shift != undefined) ? data.data.monthly_shift : [];
      this.resetForm();
      setTimeout(() => {
        this.spinnerService.raiseDataEmitterEvent('off');
    }, 100);
      },(err)=>{
          this.monthlyShiftData=[];
        this.spinnerService.raiseDataEmitterEvent('off');
      });
    }else{
      this.rerender();
    }

  }

  /*Dropdown for week */

public getWeekDropDown(){
  var params: any = {};
  params.client_id = this.ClientID;
  params.company_id = this.CompanyID;
  this.apiModulesService.list("admin/monthly-shift/DropDown",params).subscribe((data)=>{

   this.weekDropdownData=(data.data.monthly_shift[0]!= undefined) ? data.data.monthly_shift[0] :[];
   this.weekNo=(data.data.monthly_shift[1]!= undefined) ? data.data.monthly_shift[1] :[];

  })
}

/* add Monthly shift */

  public addMonthlyShift(){

    if (!this.addMonthlyShiftForm.valid) {
      this.addMonthlyShiftForm.markAllAsTouched();
      return;
    }
      var params: any = {};
      let length=this.addMonthlyShiftForm.get('weeks').value.length;
      const weekAssigned:any={};
      for(let i=0;i<this.addMonthlyShiftForm.get('weeks').value.length;i++){
      weekAssigned[this.weekNo[i].week_no]=this.addMonthlyShiftForm.get('weeks').value[i].weekno;
    }
    params.company_id = this.CompanyID;
    params.client_id = this.ClientID;

    params.month_shift_name=this.addMonthlyShiftForm.get('month_shift_name').value;
    params.month_shift_desc=this.addMonthlyShiftForm.get('month_shift_desc').value;
    params.data=[weekAssigned];

   var month_shift_id = this.addMonthlyShiftForm.get('month_shift_id').value;

   if (month_shift_id != '' && month_shift_id != null){
    params.month_shift_id = this.addMonthlyShiftForm.get('month_shift_id').value;

    this.apiModulesService.edit(params, "admin/monthly-shift/update").subscribe((data) => {

      this.getMonthlyShift();
      this.resetForm();
      this.toastr.success('Month Shift Modified Sucessfully...!', 'Success');
      ($('#add_monthly_shift') as any).modal('hide');
    }, (err:any) => {

      let errors=err.errors.data.monthly_shift;
      errors = errors.replace('SQLSTATE[42000]: [Microsoft][ODBC Driver 17 for SQL Server][SQL Server]', 'Error: ');
      errors = errors.replace('SQLSTATE[42000]: [Microsoft][ODBC Driver 18 for SQL Server][SQL Server]', 'Error: ');
      this.toastr.error(errors, 'TryAgain');

    });

   }
   else {
    this.apiModulesService.list("admin/monthly-shift/create",params).subscribe((data)=>{
      this.getMonthlyShift();
      ($('#add_monthly_shift') as any).modal('hide');
      this.resetForm();
      this.toastr.success('Shift Changed Sucessfully...!', 'Success');
    },(err:any)=>{
      let errors=err.errors.data.monthly_shift;
      errors = errors.replace('SQLSTATE[42000]: [Microsoft][ODBC Driver 17 for SQL Server][SQL Server]', 'Error: ');
      errors = errors.replace('SQLSTATE[42000]: [Microsoft][ODBC Driver 18 for SQL Server][SQL Server]', 'Error: ');
      this.toastr.error(errors, 'TryAgain');
    })
  }
  }

  /*Edit for monthly shift  */
  public monthlyShiftEdit(params) {

    this.resetForm();
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;

    this.apiModulesService.edit(params,"admin/monthly-shift/edit").subscribe((data)=>{
      let monthShiftData:any= data.data.monthly_shift[0][0];
      let weekData:any=data.data.monthly_shift[1];

       this.addMonthlyShiftForm.setValue({
        month_shift_id: monthShiftData.month_shift_id,
        client_id: params.client_id,
        company_id: params.company_id,
        month_shift_name: monthShiftData.month_shift_name,
        month_shift_desc: monthShiftData.month_shift_desc,
        weeks: [],
      });
      this.editWeekForms(weekData);
    });

    this.ShowForm = true;
  }

  /* client DropDown codes*/
 public GetClientDropDown() {
  this.apiModulesService.get('DropDown/client').subscribe((data) => {
    this.ClientDropDown = data.data.client;
    this.ClientID = data.data.client[0].client_id;
    this.GetCompanyDropDown();

  })
}
  /* company DropDown codes */
  public GetCompanyDropDown() {
    const params: any = {};
    params.client_id = this.ClientID;
      this.apiModulesService.list('DropDown/company', params).subscribe((data) => {
      this.CompanyDropDown = data.data.company != undefined ? data.data.company : [];
      if (this.CompanyDropDown.length !== 0) {
        setTimeout(() => {
          this.CompanyID = this.CompanyDropDown[0].company_id;
           this.getMonthlyShift();
           this.getWeekDropDown();

        }, 100)
      }
      else {
        this.CompanyID = null;

      }
    }
    )
  }

  changeStatus(Status: any, data) {
    const params: any = {};
     params.client_id = this.ClientID;
      params.company_id = this.CompanyID;
      params.month_shift_id = data.month_shift_id,
    this.apiModulesService.edit(params, 'admin/monthly-shift/' + Status).subscribe((data) => {
      this.getMonthlyShift();
      ($('#monthly-shift-status') as any).modal('hide');
      this.toastr.success('Status Changed Successfully!', 'Success')
    }, (err:any) => {
      let errors=err.error.data.monthly_shift;
          errors = errors.replace('SQLSTATE[42000]: [Microsoft][ODBC Driver 17 for SQL Server][SQL Server]', 'Error: ');
            this.toastr.error(errors, 'TryAgain')
    }
    );
  }

  getAcronym(str: any) {
    if (str != null) {
        let matches = str.match(/\b(\w)/g); // ['J','S','O','N']
        matches = matches.slice(0, 2);
        const acronym = matches.join(''); // JSON
        return acronym;
    }
}

getBgColors(str: any) {
    if (str != null) {
        const min = 0;
        const max = 15;
        const index = this.findAlphapositions(str, 1);
        return this.nameBgColors[index];
    }
}

findAlphapositions(str:any, n:any)
{
  var NUM = 31;
  return str[0].charCodeAt(0) & NUM;
}
  rerender() {

  }
  callStatusModel(url_name:any,data:any){
    this.statusApi=url_name;
    this.statusValues=data;
}
}
