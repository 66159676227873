import { Component } from '@angular/core';
import { ApiModulesService } from 'src/app/all-modules/api-modules.service';
import { SpinnerService } from 'src/app/snipper/spinner.service';

@Component({
  selector: 'app-payrollreports',
  templateUrl: './payrollreports.component.html',
  styleUrls: ['./payrollreports.component.css']
})
export class PayrollreportsComponent {
  activeTab="draft";
    constructor( private spinnerService: SpinnerService,private apiModulesService:ApiModulesService){}


    ngOnInit()
    {
      // this.spinnerService.raiseDataEmitterEvent('on');
     }

     changeTabs(activeTab){

      this.activeTab = activeTab;

     }

}
