// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table.dataTable{
	max-width: 100% !important;
}


   `, "",{"version":3,"sources":["webpack://./src/app/all-modules/admin-tabs/nationalitytype/nationalitytype-main/nationalitytype-main.component.css"],"names":[],"mappings":"AAAA;CACC,0BAA0B;AAC3B","sourcesContent":["table.dataTable{\r\n\tmax-width: 100% !important;\r\n}\r\n\r\n\r\n   "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
