// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table,
th,
td {
  border: 1px solid rgb(167, 166, 166);
  border-collapse: collapse;
}
.hide-border{
  border: none;
}
.table-bordered{
  color: rgb(24 44 79 / 41%);;
}`, "",{"version":3,"sources":["webpack://./src/app/all-modules/ess-tabs/IND-ess/IND-employee-payroll/monthinput/monthinput.component.css"],"names":[],"mappings":"AAAA;;;EAGE,oCAAoC;EACpC,yBAAyB;AAC3B;AACA;EACE,YAAY;AACd;AACA;EACE,0BAA0B;AAC5B","sourcesContent":["table,\r\nth,\r\ntd {\r\n  border: 1px solid rgb(167, 166, 166);\r\n  border-collapse: collapse;\r\n}\r\n.hide-border{\r\n  border: none;\r\n}\r\n.table-bordered{\r\n  color: rgb(24 44 79 / 41%);;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
