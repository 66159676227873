import { Component } from '@angular/core';
import { ApiModulesService } from 'src/app/all-modules/api-modules.service';
import { SpinnerService } from 'src/app/snipper/spinner.service';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray, NG_ASYNC_VALIDATORS, } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-key-sorting',
    templateUrl: './key-sorting.component.html',
    styleUrls: ['./key-sorting.component.css']
})
export class KeySortingComponent {
    ClientDropDown =[];
    ClientID: any;
    CompanyDropDown =[];
    CompanyID: any;
    sortingData: any;
    key_type:any='my_ctc';
    sortOrder:any;
    constructor(private apiModulesService: ApiModulesService, private spinnerService: SpinnerService, private toastrService: ToastrService) { }
    ngOnInit(): void {
        this.spinnerService.raiseDataEmitterEvent('off');
        this.GetClientDropDown();
    }

    public keySorting() {
        let params: any = {}
        params.client_id = this.ClientID
        params.company_id = this.CompanyID
        params.key_type = this.key_type

        this.spinnerService.raiseDataEmitterEvent('on');
        this.apiModulesService.list('admin/keysort/list', params).subscribe((data) => {
            this.sortingData=data.data.keysort !=undefined && data.data.keysort !=null ? data.data.keysort:[];

            this.spinnerService.raiseDataEmitterEvent('off');
        }, (err) => {
            this.spinnerService.raiseDataEmitterEvent('off');
            this.sortingData=[];
            let errors = err.error.data.keysort;
            errors = errors.replace('SQLSTATE[42000]: [Microsoft][ODBC Driver 17 for SQL Server][SQL Server]', 'Error: ')
            this.toastrService.error(errors, 'TryAgain');

        });
    }


    /* client DropDown codes*/
    public GetClientDropDown() {
        this.apiModulesService.get('DropDown/client').subscribe((data) => {
            this.ClientDropDown = data.data.client;

            this.ClientID = data.data.client[0].client_id;
            this.GetCompanyDropDown();
        })

    }

    /* company DropDown codes */
    public GetCompanyDropDown() {
        const params: any = {};
        params.client_id = this.ClientID;
        // //
        this.apiModulesService.list('DropDown/company', params).subscribe((data) => {
                this.CompanyDropDown = data.data.company != undefined ? data.data.company : [];
                if (this.CompanyDropDown.length !== 0) {
                    setTimeout(() => {
                        this.CompanyID = this.CompanyDropDown[0].company_id;
                        this.keySorting();

                    }, 100);
                }
                else {
                    this.CompanyID = null;
                }


            }
        )
    }
    changeSortOrder(){
        this.sortingData.forEach((value,key)=>{
            this.sortingData[key]['sort_order']=key+1
            this.sortingData[key]['key_type']=this.key_type
        })
        let params: any = {}
        params.client_id = this.ClientID;
        params.company_id = this.CompanyID;
        params.component_data = this.sortingData;

        this.spinnerService.raiseDataEmitterEvent('on');
        this.apiModulesService.list('admin/keysort/add', params).subscribe((data) => {
            ($('#order_key') as any).modal('hide');
            this.toastrService.success('Updated successfully..!', 'Success');

            this.spinnerService.raiseDataEmitterEvent('off');
        }, (err) => {
            this.spinnerService.raiseDataEmitterEvent('off');
            let errors = err.error.data.keysort;
            errors = errors.replace('SQLSTATE[42000]: [Microsoft][ODBC Driver 17 for SQL Server][SQL Server]', 'Error: ')
            this.toastrService.error(errors, 'TryAgain');

        });
    }
}
