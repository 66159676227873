// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .content {
	padding: 30px;
}
table.dataTable{
	max-width: 100% !important;
}

.inactive{
	display: none !important;
}
.formContainer{
	padding-left: 20px;
    padding-right: 20px;
}
.totalRecords{
	font-size: 16px;
} */
:host ::ng-deep .p-inputnumber-input {
	width: 295px !important;
}

.statusactive{
	font-size: 30px;
}
/* .statusactives{
	font-size: 15px;
} */
.popup-headers{
  margin-top: -28px;
}
`, "",{"version":3,"sources":["webpack://./src/app/all-modules/employees/employeefamily/employeefamily.component.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;;;GAgBG;AACH;CACC,uBAAuB;AACxB;;AAEA;CACC,eAAe;AAChB;AACA;;GAEG;AACH;EACE,iBAAiB;AACnB","sourcesContent":["/* .content {\r\n\tpadding: 30px;\r\n}\r\ntable.dataTable{\r\n\tmax-width: 100% !important;\r\n}\r\n\r\n.inactive{\r\n\tdisplay: none !important;\r\n}\r\n.formContainer{\r\n\tpadding-left: 20px;\r\n    padding-right: 20px;\r\n}\r\n.totalRecords{\r\n\tfont-size: 16px;\r\n} */\r\n:host ::ng-deep .p-inputnumber-input {\r\n\twidth: 295px !important;\r\n}\r\n\r\n.statusactive{\r\n\tfont-size: 30px;\r\n}\r\n/* .statusactives{\r\n\tfont-size: 15px;\r\n} */\r\n.popup-headers{\r\n  margin-top: -28px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
