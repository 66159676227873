import { Component, OnInit, ViewChild } from '@angular/core';
import { SpinnerService } from 'src/app/snipper/spinner.service';
import { ImportDropdownUploadComponent } from '../import-common/import-dropdown-upload/import-dropdown-upload.component';

@Component({
    selector: 'app-import-employee-details',
    templateUrl: './import-employee-details.component.html',
    styleUrls: ['./import-employee-details.component.css']
})
export class ImportEmployeeDetailsComponent implements OnInit {
    @ViewChild(ImportDropdownUploadComponent) ImportGrid:ImportDropdownUploadComponent ;
   
    ExcelTypeKey = 'import_employee_details';
    ClientUserType='client_maker';
    totalRecords:any;
    constructor(
        protected spinnerService: SpinnerService
    ) {
    }

    ngOnInit(): void {      
    }
}
