import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Table } from 'primeng/table';
import { ApiModulesService } from 'src/app/all-modules/api-modules.service';
import { SpinnerService } from 'src/app/snipper/spinner.service';

@Component({
  selector: 'app-import-validation-history',
  templateUrl: './import-validation-history.component.html',
  styleUrls: ['./import-validation-history.component.css']
})
export class ImportValidationHistoryComponent implements OnChanges ,OnInit {
  @Input() data_import_id: any;
  @Input() ClientID: any;
  @Input() CompanyID: any;
  @Input() ExcelTypeKey: any;
  @Input() eventStatus: any;
  @Input() ClientUserType: any;
  @Input() validateHistoryData = [];
  @Output() parenFun: EventEmitter<any> = new EventEmitter();
  isEditable=false;
  searchKeyword: any;
  import_edit_evt_id: any;
  groups = [];
  firstGroup = 0;
  rowsGroup = 10;
  totalRecordsGroup: any;
  firstRecord = 0;
  rowsRecord = 5;
  paginatedGroups = [];

  filterKey;
  showGrid = false;
  refreshValidate = true;

  validation_detail_id: any;
  dataImportDetailId: any;
  FilterEditData: any;
  editResponseData: any;
  ClientUploadExcelData: any;
  displayForm: any;
  ErrorShow: any;
  sendRequest:any;
  isExpand=false;
  constructor(protected apiModulesService: ApiModulesService,
    protected spinnerService: SpinnerService,
    protected toastrService: ToastrService,
  ) {
  }
  ngOnInit(): void {
    this.showGrid = true;
    this.refreshValidate = false;
    this.groups = this.validateHistoryData;
    this.totalRecordsGroup = this.validateHistoryData.length;
    this.paginateGroups();

  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes['validateHistoryData']) {
      this.groups = this.validateHistoryData;
      this.totalRecordsGroup = this.validateHistoryData.length;
      this.paginateGroups();
    }
  }
  backToValiadate(value) {
    this.showGrid = false;
    this.parenFun.emit(this.showGrid)
  }

  refreshValiadate(value) {
    this.refreshValidate = true;
    this.parenFun.emit(this.refreshValidate)
  }
  clear(table: Table) {
    table.clear();
  }
  paginateGroups() {
    this.paginatedGroups = this.groups.slice(this.firstGroup, this.firstGroup + this.rowsGroup);
    this.paginatedGroups.forEach(group => {
      group.firstRecord = 0;
      this.paginateRecords(group);
    });
  }

  paginateRecords(group) {
    group.paginatedRecords = group.records.slice(group.firstRecord, group.firstRecord + this.rowsRecord);
  }

  onGroupPageChange(event) {
    this.firstGroup = event.first;
    this.rowsGroup = event.rows;
    this.paginateGroups();
  }

  onRecordPageChange(event, groupIndex) {

    const group = this.paginatedGroups[groupIndex];
    group.firstRecord = event.first;

    this.paginateRecords(group);
  }
  expand(id: any) {
    if(this.import_edit_evt_id!=id){
      this.isExpand=true;
      this.import_edit_evt_id = id
      this.paginateGroups();
    }else{
      this.import_edit_evt_id=null
      this.isExpand=false;

    }
  
  }

  public importHistory(import_edit_evt_id) {


    const params: any = {};
    params.data_import_id = this.data_import_id;
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    params.import_edit_evt_id = import_edit_evt_id;
    params.event_status = this.eventStatus;

    this.apiModulesService.edit(params, 'admin/import/validation/history/create').subscribe((data) => {
      this.refreshValiadate(true);
    });
  };

  public editValue(validationDetailId, dataImportDetailId,import_type_id,is_editable,grid_event_status) {
    this.isEditable=is_editable=='yes' && grid_event_status==this.eventStatus?true:false;
    this.validation_detail_id = validationDetailId;
    this.dataImportDetailId = dataImportDetailId;
    const params: any = {};
    params.data_import_id = this.data_import_id;
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    params.data_import_detail_id = dataImportDetailId;
    params.import_type_id = import_type_id;

    this.apiModulesService.edit(params, 'admin/azatecon/maker/validation/edit').subscribe((data) => {
      const response: any = data.data.imports != undefined ? data.data.imports : [];
      this.FilterEditData = JSON.parse(JSON.stringify(response));
      this.editResponseData = JSON.parse(JSON.stringify(response));
      this.ClientUploadExcelData = JSON.parse(JSON.stringify(response));
      this.FilterEditFormData();
    });
  };
  FilterEditFormData() {
    this.displayForm = 'All';
    this.ClientUploadExcelData = this.FilterEditData;
    this.ClientUploadExcelData = this.FilterEditData.filter((data: any) => data.is_show === 'yes')

  }
  public onUpdateValue(errorForm: NgForm) {
    if(!this.isEditable){
      ($('#edit_history_Validation') as any).modal('hide');
      this.ErrorresetForm(errorForm);
      return
    }
    const remarks = {};
    const current_values = {};
    const modified_values = {};
    const json_data_input = {};

    this.FilterEditData.forEach((errors, index: any) => {
      const fieldName = errors.field_name;
      const currentFieldValues = JSON.parse(JSON.stringify(errors.current_field_values)) ?? null;
      const remarksValues = errors.remarks_field_values ?? null;
      const fieldValues = errors.field_values ?? null;
      current_values[fieldName] = JSON.parse(JSON.stringify(currentFieldValues));
      remarks[fieldName] = remarksValues;
      json_data_input[fieldName] = fieldValues;

      for (let j = 0; j < errorForm.value.length; j++) {
        if (errorForm.value[j].hasOwnProperty(`${fieldName}[current_values]`)) {
          const currentFormValues = errorForm.value[j][`${fieldName}[current_values]`] ?? null;
          const remarksFormValues = errorForm.value[j][`${fieldName}[remarks]`] ?? null;
          current_values[fieldName] = JSON.parse(JSON.stringify(currentFormValues));
          remarks[fieldName] = remarksFormValues;
        }
      }
      if (fieldName == this.editResponseData[index].field_name) {
        if (current_values[fieldName] !== this.editResponseData[index].field_values) {
          modified_values[fieldName] = current_values[fieldName];
        }
      }

    });

    const params: any = {
      data_import_detail_id: this.dataImportDetailId,
      data_import_id: this.data_import_id,
      client_id: this.ClientID,
      company_id: this.CompanyID,
      remarks_field_values: remarks,
      modified_json_data: modified_values,
      current_json_data: current_values,
      event_status: this.eventStatus,
      json_data_input: json_data_input
    };
    this.apiModulesService.edit(params, 'admin/azatecon/maker/validation/update').subscribe((data) => {
      this.refreshValiadate(true);
      ($('#edit_history_Validation') as any).modal('hide');
      this.ErrorresetForm(errorForm);
      this.toastrService.success('Edit error successfully!', 'Success');
    });
  }



  public ErrorresetForm(Error: NgForm) {
    Error.resetForm();
    this.isEditable=false;
  }
  public editAndApprove(importEditEventId) {
    
    this.spinnerService.raiseDataEmitterEvent('on')
    const params: any = {};
    params.import_edit_evt_id = importEditEventId;
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    params.event_status = this.eventStatus;
    params.data_import_id = this.data_import_id;
    this.apiModulesService.list('admin/azatecon/maker/edit/approve', params).subscribe((data) => {
      ($('#request-check') as any).modal('hide');
      this.refreshValiadate(true);
    }, (err) => {
      if (err.status === 437) {
        this.toastrService.error('Import Already Exists...!', 'Failed');
    } else {
        this.spinnerService.toasterError(err);
    }
    
    });
  }
  public clientHistoryImport(importEditEventId) {
if(this.eventStatus!='client'){
  return
}
    this.spinnerService.raiseDataEmitterEvent('on')
    const params: any = {};
    params.import_edit_evt_id = importEditEventId;
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    params.event_status = this.eventStatus;
    params.data_import_id = this.data_import_id;
    this.apiModulesService.list('admin/import/validation/history/create', params).subscribe((data) => {
      this.refreshValiadate(true);
    }, (err) => {
      if (err.status === 437) {
        this.toastrService.error('Import Already Exists...!', 'Failed');
    } else {
        this.spinnerService.toasterError(err);
    }
    
    });
  }


}
