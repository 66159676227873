// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*.content {*/
	/*padding: 30px;*/
/*}*/
.page-wrapper{
    padding:10% 25%;
}
table {
    border-collapse: collapse;
    width: 100%;
}

th, td {
    border: 1px solid #ccc;
    padding: 10px;
    text-align: center;
}

th {
    background-color: #f4f4f4;
}
`, "",{"version":3,"sources":["webpack://./src/app/all-modules/dashboard/admin-dashboard/admin-dashboard.component.css"],"names":[],"mappings":"AAAA,aAAa;CACZ,iBAAiB;AAClB,IAAI;AACJ;IACI,eAAe;AACnB;AACA;IACI,yBAAyB;IACzB,WAAW;AACf;;AAEA;IACI,sBAAsB;IACtB,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":["/*.content {*/\r\n\t/*padding: 30px;*/\r\n/*}*/\r\n.page-wrapper{\r\n    padding:10% 25%;\r\n}\r\ntable {\r\n    border-collapse: collapse;\r\n    width: 100%;\r\n}\r\n\r\nth, td {\r\n    border: 1px solid #ccc;\r\n    padding: 10px;\r\n    text-align: center;\r\n}\r\n\r\nth {\r\n    background-color: #f4f4f4;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
