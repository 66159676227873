// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.drop-align{
  margin-left:75px;
}
.filtericon{
  left: 40%;
  position: absolute;
  top: 19%;
  display: flex;
}
.filters-icon{
  left: 88%;
  position: absolute;
  top: 19%;
  display: flex;
}
`, "",{"version":3,"sources":["webpack://./src/app/all-modules/ess-tabs/IND-ess/IND-employee-hrms/manager-page/timemanager/shiftmapping/shiftmapping.component.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;AACA;EACE,SAAS;EACT,kBAAkB;EAClB,QAAQ;EACR,aAAa;AACf;AACA;EACE,SAAS;EACT,kBAAkB;EAClB,QAAQ;EACR,aAAa;AACf","sourcesContent":[".drop-align{\r\n  margin-left:75px;\r\n}\r\n.filtericon{\r\n  left: 40%;\r\n  position: absolute;\r\n  top: 19%;\r\n  display: flex;\r\n}\r\n.filters-icon{\r\n  left: 88%;\r\n  position: absolute;\r\n  top: 19%;\r\n  display: flex;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
