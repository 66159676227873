// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-button {
  background-color: #fff;
  font-family: 'Open_Sans';
  font-style: normal;
  font-weight: normal;
  margin-bottom: 7px;
  margin-right: 7px;
  font-size: 14px;
  color: black;
  border: 0;
  margin-left: 10px;
  /* box-shadow: 0 8px 16px 0 rgba(57, 92, 241, 0.19), 0 6px 20px 0 rgba(57, 92, 241, 0.19); */
  box-shadow: inset 0.2em 0.2em 0.2em 0 rgb(255 255 255 / 50%), inset -0.2em -0.2em 0.2em 0 rgb(8 175 44 / 50%);
}

.drop-button {
  background-color: #fff;
  font-family: 'Open_Sans';
  font-style: normal;
  font-weight: normal;
  margin-bottom: 7px;
  margin-right: 7px;
  font-size: 14px;
  color: black;
  border: 0;
  margin-left: 10px;
  /* box-shadow: 0 8px 16px 0 rgba(57, 92, 241, 0.19), 0 6px 20px 0 rgba(57, 92, 241, 0.19); */
  box-shadow: inset 0.2em 0.2em 0.2em 0 rgb(255 255 255 / 50%), inset -0.2em -0.2em 0.2em 0 rgb(195 14 14 / 50%);
}
.fa-plus{
  color: black;
}
.fa-minus{
  color: black;
}
`, "",{"version":3,"sources":["webpack://./src/app/all-modules/company/time-workingstatus/time-workingstatus.component.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,wBAAwB;EACxB,kBAAkB;EAClB,mBAAmB;EACnB,kBAAkB;EAClB,iBAAiB;EACjB,eAAe;EACf,YAAY;EACZ,SAAS;EACT,iBAAiB;EACjB,4FAA4F;EAC5F,6GAA6G;AAC/G;;AAEA;EACE,sBAAsB;EACtB,wBAAwB;EACxB,kBAAkB;EAClB,mBAAmB;EACnB,kBAAkB;EAClB,iBAAiB;EACjB,eAAe;EACf,YAAY;EACZ,SAAS;EACT,iBAAiB;EACjB,4FAA4F;EAC5F,8GAA8G;AAChH;AACA;EACE,YAAY;AACd;AACA;EACE,YAAY;AACd","sourcesContent":[".add-button {\r\n  background-color: #fff;\r\n  font-family: 'Open_Sans';\r\n  font-style: normal;\r\n  font-weight: normal;\r\n  margin-bottom: 7px;\r\n  margin-right: 7px;\r\n  font-size: 14px;\r\n  color: black;\r\n  border: 0;\r\n  margin-left: 10px;\r\n  /* box-shadow: 0 8px 16px 0 rgba(57, 92, 241, 0.19), 0 6px 20px 0 rgba(57, 92, 241, 0.19); */\r\n  box-shadow: inset 0.2em 0.2em 0.2em 0 rgb(255 255 255 / 50%), inset -0.2em -0.2em 0.2em 0 rgb(8 175 44 / 50%);\r\n}\r\n\r\n.drop-button {\r\n  background-color: #fff;\r\n  font-family: 'Open_Sans';\r\n  font-style: normal;\r\n  font-weight: normal;\r\n  margin-bottom: 7px;\r\n  margin-right: 7px;\r\n  font-size: 14px;\r\n  color: black;\r\n  border: 0;\r\n  margin-left: 10px;\r\n  /* box-shadow: 0 8px 16px 0 rgba(57, 92, 241, 0.19), 0 6px 20px 0 rgba(57, 92, 241, 0.19); */\r\n  box-shadow: inset 0.2em 0.2em 0.2em 0 rgb(255 255 255 / 50%), inset -0.2em -0.2em 0.2em 0 rgb(195 14 14 / 50%);\r\n}\r\n.fa-plus{\r\n  color: black;\r\n}\r\n.fa-minus{\r\n  color: black;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
