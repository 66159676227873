import { Component, OnInit,ViewChild,AfterViewInit,OnDestroy } from '@angular/core';
import { ApiModulesService } from '../../api-modules.service';
import { ToastrService } from 'ngx-toastr';

import { FormGroup,FormBuilder, Validators } from '@angular/forms';
import { Subject } from "rxjs";
@Component({
  selector: 'app-costcenter',
  templateUrl: './costcenter.component.html',
  styleUrls: ['./costcenter.component.css']
})
export class CostcenterComponent implements OnInit, OnDestroy, AfterViewInit {


  public rows = [];
  ClientID=localStorage.getItem('client_id');
  CompanyID=localStorage.getItem('company_id');
  public srch = [];
  ClientDropDown = [];
  CompanyDropDown = [];
  public dropdownname = [];
  listHeaders=[];
  isDtInitialized = false;
  public totalRecords: any = 0;
  public costcenterData =[];
  public addCostCenterForm:FormGroup;
  public editCostcenterForm:FormGroup;


  nameBgColors:any = ["bg-blue-dim","bg-blue-dim","bg-azure-dim","bg-indigo-dim","bg-info-dim","bg-purple-dim","bg-pink-dim","bg-orange-dim","bg-teal-dim","bg-primary-dim","bg-success-dim","bg-warning-dim","bg-danger-dim","bg-secondary-dim","bg-dark-dim","bg-gray-dim","bg-blue-dim","bg-azure-dim","bg-indigo-dim","bg-info-dim","bg-purple-dim","bg-pink-dim","bg-orange-dim","bg-teal-dim","bg-primary-dim","bg-success-dim","bg-warning-dim","bg-danger-dim","bg-secondary-dim","bg-dark-dim","bg-gray-dim"];
    nameBorderColors:any = ["bg-outline-primary","bg-outline-success","bg-outline-info","bg-outline-gray","bg-outline-warning","bg-outline-dark","bg-outline-light","bg-outline-secondary","bg-outline-danger"];

  constructor(private apiModulesService: ApiModulesService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder) { }

  ngOnInit(): void {

  this.GetClientDropDown();
  this.addCostCenterForm=this.formBuilder.group({
    company_id: ["", []],
    client_id: ["", []],
    company_org_id: ["", [Validators.required]],
    cost_center_code: ["", [Validators.required]],
    cost_center_desc: ["", [Validators.required]],
  });
    this.editCostcenterForm=this.formBuilder.group({
      cost_center_code: ["", [Validators.required]],
      cost_center_desc: ["", [Validators.required]],
      company_org_id: ["", [Validators.required]],
      cost_center_id:["",[]],
     });
  }
  public costcenterType() {
      if (this.CompanyDropDown.length !== 0 && this.CompanyID != null) {
    var params:any = {};
    params.company_id = this.CompanyID;
    params.client_id = this.ClientID;
    this.apiModulesService.list("admin/costcenter/list",params).subscribe((data) => {

            this.costcenterData = (data.data.cost_center != undefined) ? data.data.cost_center : [];
            var totalRecords = this.costcenterData.length;
            this.totalRecords = totalRecords;

  }, (err) => {
    this.rerender();
  });
} else {
  this.rerender();
}

}

  changeStatus(status:any,OrganisationId:any) {

    var params:any = {};
    params.cost_center_id= OrganisationId;
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    params.status= status;

      this.apiModulesService.edit(params,"admin/costcenter/status").subscribe(() => {
        this.costcenterType();
        this.toastr.success("Status changed successfully...!", "Success");
      }
      ,(err)=>{
        this.toastr.error("Something went wrong!", "Try Again");
      }
      );
  }
  public onEditCostCenter(OrganisationId: any) {
    var params:any = {};
    params.cost_center_id = OrganisationId;
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;

    this.apiModulesService.edit(params,"admin/costcenter/edit").subscribe((data) => {
      let CostCenterDetail = data.data.cost_center;
      this.editCostcenterForm.setValue({
        //company_org_id:CostCenterDetail.company_org_id,
        company_org_id:CostCenterDetail.company_org_id,
        cost_center_code:CostCenterDetail.cost_center_code,
        cost_center_desc:CostCenterDetail.cost_center_desc,
        cost_center_id:CostCenterDetail.cost_center_id,
      });

    });

  }
  //edit update
 public onEditChanges(){
  if (!this.editCostcenterForm.valid) {

    this.editCostcenterForm.markAllAsTouched();
    return;
  }
  var params:any={};
  params.company_org_id=this.editCostcenterForm.get('company_org_id').value;
  params.cost_center_id=this.editCostcenterForm.get('cost_center_id').value;
  params.cost_center_code=this.editCostcenterForm.get('cost_center_code').value;
  params.cost_center_desc=this.editCostcenterForm.get('cost_center_desc').value;

  this.apiModulesService.edit(params,'admin/costcenter/update').subscribe((data)=>{
      this.toastr.success("Costcenter updated successfully...!", "Success");
      this.costcenterType();
      ($("#edit_costcenter")as any).modal("hide");
    }
    ,(err)=>{
                if (err.status === 437) {
                    this.toastr.error('Costcenter Already Exists...!', 'Failed');
                } else {
                    this.toastr.error('Something Went Wrong Please Try Again...!', 'Error');
                }
            }
    );
}

//add costcenter
public onAddCostCenter() {
  if(this.addCostCenterForm.invalid){
    this.addCostCenterForm.markAllAsTouched()
    return
  }
  var params:any = {};
  params.company_org_id = this.addCostCenterForm.get('company_org_id').value;
  params.company_id = this.CompanyID;
  params.client_id = this.ClientID;

  params.cost_center_code = this.addCostCenterForm.get('cost_center_code').value;
  params.cost_center_desc = this.addCostCenterForm.get('cost_center_desc').value;
  params.company_org_id = this.addCostCenterForm.get('company_org_id').value;
  params.record_status = 1;

    this.apiModulesService.add(params,"admin/costcenter/add",).subscribe((data) => {
      this.toastr.success("Cost Center added successfully...!", "Success");
      this.costcenterType();
      ($("#add_costcenter")as any).modal("hide");
      this.addCostCenterForm.reset();

    },(err)=>{
      if (err.status === 437) {
          this.toastr.error('Cost Center Already Exists...!', 'Failed');
      } else {
          this.toastr.error('Something Went Wrong Please Try Again...!', 'Error');
      }
  }
    );

}

ngAfterViewInit(): void {

}


public onCostCenter(organisationid,companyid,clientid){
  {
    var value={
      organisationid:organisationid,
      companyid:companyid,
      clientid:clientid
    }
    this.addCostCenterForm.setValue({
      company_org_id:value.organisationid,
      client_id:value.clientid,
      company_id:value.companyid,
      cost_center_code:'',
      cost_center_desc:'',
    });

  }
}

ngOnDestroy(): void {

}

public resetForm() {
  this.addCostCenterForm.reset();
}
rerender() {
}
  /* client DropDown codes*/
  public GetClientDropDown() {
    this.apiModulesService.get('DropDown/client').subscribe((data) => {
      this.ClientDropDown = data.data.client;

      this.ClientID = data.data.client[0].client_id;
      this.GetCompanyDropDown();
    })
  }

    /* company DropDown codes */
    public GetCompanyDropDown() {
      const params: any = {};
      params.client_id = this.ClientID;

      this.apiModulesService.list('DropDown/company', params).subscribe((data) => {
        this.CompanyDropDown = data.data.company != undefined ? data.data.company : [];
        if (this.CompanyDropDown.length !== 0) {
          setTimeout(() => {
            this.CompanyID = this.CompanyDropDown[0].company_id;
            this.getDropDown();
            this.costcenterType();
          }, 100)
        }
        else {
          this.CompanyID = null;
          this.rerender();
        }

      }
      )
    }

    getDropDown() {
      const params: any = {};
      params.client_id = this.ClientID;
      params.company_id = this.CompanyID;

      this.apiModulesService.list('admin/costcenter/dropdown', params).subscribe((data) => {

        this.dropdownname = data.data.cost_center;
        
      });
    }
    getAcronym(str:any){
      var matches = str.match(/\b(\w)/g); // ['J','S','O','N']
      matches= matches.slice(0,3);
      var acronym = matches.join(''); // JSON
      return acronym;
    }
    getBgColors(str:any){
      var min = 0;
      var max = 15;
      var index = this.findAlphapositions(str,1);
      return this.nameBgColors[index];
    }
    findAlphapositions(str:any, n:any)
    {
      var NUM = 31;

      return str[0].charCodeAt(0) & NUM;
    }
}
