import { Component, OnInit } from '@angular/core';
import { SpinnerService } from 'src/app/snipper/spinner.service';
import { ApiModulesService } from 'src/app/all-modules/api-modules.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-daytimeview',
  templateUrl: './daytimeview.component.html',
  styleUrls: ['./daytimeview.component.css']
})
export class DaytimeviewComponent implements OnInit{
  customers: any = [];
  emplyeeWorkDetails: any[];
  groups: any[];
  groupRowsPerPage: number;
  rowRowsPerPage: number;
  rowPage: { [key: string]: number } = {};
  ShowForm: boolean;
  expandedRowKeys: any = {};
  filteredEmployees: { [key: string]: any[] } = {};
  filteredMainEmployees: { [key: string]: any[] } = {};
  filterKey;
  from_date: any;
  to_date: any;
  month = [
    {
      selected_value: 'May',
      selected_text: 'May'
    },


  ]
  year = [

    {
      selected_value: '2024',
      selected_text: '2024'
    }
  ]
  clientID = localStorage.getItem('client_id');
  companyID = localStorage.getItem('company_id');
  employeeID = localStorage.getItem('employee_id');
  managerTimeGrid: any;
  managerTimeGridDetails: any;
  date: any;
  clickDate: any;
  employeeTimeData: any;
  managerTimeHeaders: any;
  constructor(private spinnerService: SpinnerService, private apiModuleService: ApiModulesService,
    private toaster: ToastrService) {
    this.groupRowsPerPage =10;
    this.rowRowsPerPage = 10;
    this.ShowForm = false;
  }

  ngOnInit() {
    this.spinnerService.raiseDataEmitterEvent('on');
    this.getManagerTimeViewData();
  }
  getManagerTimeViewData() {
    this.spinnerService.raiseDataEmitterEvent('on');
    const params: any = {};

    if (!this.from_date || !this.to_date) {
      const currentDate = new Date();
      this.from_date = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
      this.to_date = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    }
      const fromDate = new Date(this.from_date);
      fromDate.setDate(fromDate.getDate() + 1);
      const toDate = new Date(this.to_date);
      toDate.setDate(toDate.getDate() + 1);
      params.client_id = this.clientID,
      params.company_id = this.companyID,
      params.employee_id = this.employeeID,
      params.from_date = fromDate;
      params.to_date = toDate;
      this.apiModuleService.list('employee-tabs/employee-hrm/manager-time/list', params).subscribe((data: any) => {
        this.managerTimeGrid = data.data.manager_time_grid !== undefined ? data.data.manager_time_grid : [];
        this.managerTimeHeaders = data.data.manager_time_header !== undefined ? data.data.manager_time_header : {};
        this.groups = this.groupCustomersByDate(this.managerTimeGrid);
        this.spinnerService.raiseDataEmitterEvent('off');
      }, (err: any) => {
        this.spinnerService.toasterError(err);
      });
  }

  groupCustomersByDate(customers: any[]) {
    const grouped: any = customers.reduce((acc, customer) => {
      let group = acc.find(g => g.date === customer.date);
      if (group) {
        group.customers.push(customer);
      } else {
        group = { date: customer.date, customers: [customer] };
        acc.push(group);
      }
      return acc;
    }, []);

    return grouped;
  }

  toggleRow(group: any, key: any) {
    const date = group.date;
    if (this.expandedRowKeys[date]) {
      delete this.expandedRowKeys[date];
      this.filteredEmployees[date] = [];
      this.filteredMainEmployees[date] = [];
    } else {
      this.expandedRowKeys[date] = true;
      this.rowPage[date] = this.rowPage[date] || 0;
      this.updateFilteredEmployees(date, key);
    }
  }
  toggleFilterRow(group: any, key: any) {
    const date = group.date;

    delete this.expandedRowKeys[date];
    this.filteredEmployees[date] = [];
    this.filteredMainEmployees[date] = [];
    this.expandedRowKeys[date] = true;
    this.rowPage[date] = this.rowPage[date] || 0;
    this.updateFilteredEmployees(date, key);

  }

  updateFilteredEmployees(date: string, key: any) {
    this.spinnerService.raiseDataEmitterEvent('on');
    this.filterKey = key;
    const page = this.rowPage[date] || 0;
    const start = page * this.rowRowsPerPage;
    const end = start + this.rowRowsPerPage;
    const params: any = {};
    params.client_id = this.clientID,
      params.company_id = this.companyID,
      params.employee_id = this.employeeID,
      params.date = date,
      this.apiModuleService.list('employee-tabs/employee-hrm/manager-time/emp-details', params).subscribe((data: any) => {
        this.employeeTimeData = data.data.manager_time !== undefined ? data.data.manager_time : [];
        if (key === null) {
          this.filteredMainEmployees[date] = this.employeeTimeData.filter(emp => emp.date === date);
          this.filteredEmployees[date] = this.employeeTimeData.filter(emp => emp.date === date).slice(start, end);
        } else {
          this.filteredMainEmployees[date] = this.employeeTimeData.filter(emp => emp.date === date && emp.work_status == key);
          this.filteredEmployees[date] = this.employeeTimeData.filter(emp => emp.date === date && emp.work_status == key).slice(start, end);
        }
        this.spinnerService.raiseDataEmitterEvent('off');
      }, (err: any) => {
        this.spinnerService.toasterError(err);
      });


  }
  onGroupPageChange(event: any) {
    // Handle group page change
  }

  onCustomerPageChange(event: any, group: any) {
    this.rowPage[group.date] = event.page;
    this.updateFilteredEmployees(group.date, this.filterKey);
  }

  getCustomersForGroup(group: any) {
    const date = group.date;
    const page = this.rowPage[date] || 0;
    const start = page * this.rowRowsPerPage;
    const end = start + this.rowRowsPerPage;
    return group.customers.slice(start, end);
  }

  getSeverity(status: string) {
    switch (status) {
      case 'active':
        return 'success';
      default:
        return 'warning';
    }
  }

  onRowGroupExpand(event: any) {
    // Handle row group expand
  }

  onRowGroupCollapse(event: any) {
    // Handle row group collapse
  }

}
