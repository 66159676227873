import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root',
})
export class PayslipIncomeDataService {

  tax_data=[{ 'month':"April","year":2023, "estimated_annual_taxable_income":"5,27,130.00",
  "slab":[
    {"key":"Upto Rs. 2,50,000",'tax':"0","value":"0","type":"sub"},
    {"key":"Rs. 2,50,001  -  Rs. 5,00,000",'tax':"5%","value":"12500","type":"sub"},
    {"key":"Rs.5,00,001  - Rs. 5,27,130",'tax':"20%","value":"5426","type":"sub"},
    {"key":"Slab Tax (A)",'tax':"Sum of above Tax Value","value":"17926","type":"total"},

  ],
  "surcharge":[
    {"key":"50 Lacs to 1 Crore",'tax':"10% of Slab Tax","value":"0","type":"sub"},
    {"key":"Above 1 Crore Upto 2 Crores",'tax':"15% of Income Tax","value":"0","type":"sub"},
    {"key":"Above 2 Crores Upto 5 Crores",'tax':"25% of Income Tax","value":"0","type":"sub"},
    {"key":"Above 5 Crores",'tax':"37% of Income Tax","value":"0","type":"sub"},
    {"key":"Tax After Surcharge (B)",'tax':"","value":"0","type":"total"},

  ],
  "cess":"717.04",

  "tax_amount":[
    {"key":"Total Estimated Annual Income Tax (D)",'tax':"Slab Tax + Cess","value":"18643","type":"sub"},
    {"key":"Tax deducted YTD (E)",'tax':"Actual Tax deducted till last month","value":"0","type":"sub"},
    {"key":"Balance Tax (F)",'tax':"D - E","value":"18643","type":"sub"},
    {"key":"This Month Tax Amount",'tax':"F / No of Balance Months","value":"1554","type":"total"},

  ],

},

//May
{ 'month':"May","year":2023, "estimated_annual_taxable_income":"541920",
"slab":[
  {"key":"Upto Rs. 2,50,000",'tax':"0","value":"0","type":"sub"},
  {"key":"Rs. 2,50,001  -  Rs. 5,00,000",'tax':"5%","value":"12500","type":"sub"},
  {"key":"Rs.5,00,001  - Rs. 5,41,920",'tax':"20%","value":"8384","type":"sub"},
  {"key":"Slab Tax (A)",'tax':"Sum of above Tax Value","value":"20884","type":"total"},

],

"surcharge":[
  {"key":"50 Lacs to 1 Crore",'tax':"10% of Slab Tax","value":"0","type":"sub"},
  {"key":"Above 1 Crore Upto 2 Crores",'tax':"15% of Income Tax","value":"0","type":"sub"},
  {"key":"Above 2 Crores Upto 5 Crores",'tax':"25% of Income Tax","value":"0","type":"sub"},
  {"key":"Above 5 Crores",'tax':"37% of Income Tax","value":"0","type":"sub"},
  {"key":"Tax After Surcharge (B)",'tax':"","value":"0","type":"total"},

],
"cess":"835.36",

"tax_amount":[
  {"key":"Total Estimated Annual Income Tax (D)",'tax':"Slab Tax + Cess","value":"21719","type":"sub"},
  {"key":"Tax deducted YTD (E)",'tax':"Actual Tax deducted till last month","value":"1554","type":"sub"},
  {"key":"Balance Tax (F)",'tax':"D - E","value":"20165","type":"sub"},
  {"key":"This Month Tax Amount",'tax':"F / No of Balance Months","value":"1833","type":"total"},

],
},

//june
{ 'month':"June","year":2023, "estimated_annual_taxable_income":" ",
"slab":[
  {"key":"Upto Rs. 2,50,000",'tax':"0","value":"0","type":"sub"},
  {"key":"Rs. 2,50,001  -  Rs. 5,00,000",'tax':"5%","value":"12500","type":"sub"},
  {"key":"Rs.5,00,001  - Rs. 5,31,270",'tax':"20%","value":"6254","type":"sub"},
  {"key":"Slab Tax (A)",'tax':"Sum of above Tax Value","value":"18754","type":"total"},

],

"surcharge":[
  {"key":"50 Lacs to 1 Crore",'tax':"10% of Slab Tax","value":"0","type":"sub"},
  {"key":"Above 1 Crore Upto 2 Crores",'tax':"15% of Income Tax","value":"0","type":"sub"},
  {"key":"Above 2 Crores Upto 5 Crores",'tax':"25% of Income Tax","value":"0","type":"sub"},
  {"key":"Above 5 Crores",'tax':"37% of Income Tax","value":"0","type":"sub"},
  {"key":"Tax After Surcharge (B)",'tax':"","value":"0","type":"total"},

],

"cess":"750.16",

"tax_amount":[
  {"key":"Total Estimated Annual Income Tax (D)",'tax':"Slab Tax + Cess","value":"19504","type":"sub"},
  {"key":"Tax deducted YTD (E)",'tax':"Actual Tax deducted till last month","value":"3387","type":"sub"},
  {"key":"Balance Tax (F)",'tax':"D - E","value":"16117","type":"sub"},
  {"key":"This Month Tax Amount",'tax':"F / No of Balance Months","value":"1612","type":"total"},

],
},

//july
{ 'month':"July","year":2023, "estimated_annual_taxable_income":"551500",
"slab":[
  {"key":"Upto Rs. 2,50,000",'tax':"0","value":"0","type":"sub"},
  {"key":"Rs. 2,50,001  -  Rs. 5,00,000",'tax':"5%","value":"12500","type":"sub"},
  {"key":"Rs.5,00,001  - Rs. 5,51,500",'tax':"20%","value":"10300","type":"sub"},
  {"key":"Slab Tax (A)",'tax':"Sum of above Tax Value","value":"22800","type":"total"},

],

"surcharge":[
  {"key":"50 Lacs to 1 Crore",'tax':"10% of Slab Tax","value":"0","type":"sub"},
  {"key":"Above 1 Crore Upto 2 Crores",'tax':"15% of Income Tax","value":"0","type":"sub"},
  {"key":"Above 2 Crores Upto 5 Crores",'tax':"25% of Income Tax","value":"0","type":"sub"},
  {"key":"Above 5 Crores",'tax':"37% of Income Tax","value":"0","type":"sub"},
  {"key":"Tax After Surcharge (B)",'tax':"","value":"0","type":"total"},

],
"cess":"912",

"tax_amount":[
  {"key":"Total Estimated Annual Income Tax (D)",'tax':"Slab Tax + Cess","value":"23712","type":"sub"},
  {"key":"Tax deducted YTD (E)",'tax':"Actual Tax deducted till last month","value":"4999","type":"sub"},
  {"key":"Balance Tax (F)",'tax':"D - E","value":"18713","type":"sub"},
  {"key":"This Month Tax Amount",'tax':"F / No of Balance Months","value":"2079","type":"total"},

],
},

//August
{ 'month':"August","year":2023, "estimated_annual_taxable_income":"560640",
"slab":[
  {"key":"Upto Rs. 2,50,000",'tax':"0","value":"0","type":"sub"},
  {"key":"Rs. 2,50,001  -  Rs. 5,00,000",'tax':"5%","value":"12500","type":"sub"},
  {"key":"Rs.5,00,001  - Rs. 5,60,640",'tax':"20%","value":"12128","type":"sub"},
  {"key":"Slab Tax (A)",'tax':"Sum of above Tax Value","value":"24628","type":"total"},

],

"surcharge":[
  {"key":"50 Lacs to 1 Crore",'tax':"10% of Slab Tax","value":"0","type":"sub"},
  {"key":"Above 1 Crore Upto 2 Crores",'tax':"15% of Income Tax","value":"0","type":"sub"},
  {"key":"Above 2 Crores Upto 5 Crores",'tax':"25% of Income Tax","value":"0","type":"sub"},
  {"key":"Above 5 Crores",'tax':"37% of Income Tax","value":"0","type":"sub"},
  {"key":"Tax After Surcharge (B)",'tax':"","value":"0","type":"total"},

],
"cess":"985.12",

"tax_amount":[
  {"key":"Total Estimated Annual Income Tax (D)",'tax':"Slab Tax + Cess","value":"25613.12","type":"sub"},
  {"key":"Tax deducted YTD (E)",'tax':"Actual Tax deducted till last month","value":"7078","type":"sub"},
  {"key":"Balance Tax (F)",'tax':"D - E","value":"18535","type":"sub"},
  {"key":"This Month Tax Amount",'tax':"F / No of Balance Months","value":"2317","type":"total"},

],
},

//September
{ 'month':"September","year":2023, "estimated_annual_taxable_income":"615430",
"slab":[
  {"key":"Upto Rs. 2,50,000",'tax':"0","value":"0","type":"sub"},
  {"key":"Rs. 2,50,001  -  Rs. 5,00,000",'tax':"5%","value":"12500","type":"sub"},
  {"key":"Rs.5,00,001  - Rs. 6,15,430",'tax':"20%","value":"23086","type":"sub"},
  {"key":"Slab Tax (A)",'tax':"Sum of above Tax Value","value":"35586","type":"total"},

],

"surcharge":[
  {"key":"50 Lacs to 1 Crore",'tax':"10% of Slab Tax","value":"0","type":"sub"},
  {"key":"Above 1 Crore Upto 2 Crores",'tax':"15% of Income Tax","value":"0","type":"sub"},
  {"key":"Above 2 Crores Upto 5 Crores",'tax':"25% of Income Tax","value":"0","type":"sub"},
  {"key":"Above 5 Crores",'tax':"37% of Income Tax","value":"0","type":"sub"},
  {"key":"Tax After Surcharge (B)",'tax':"","value":"0","type":"total"},

],
"cess":"1423.44",

"tax_amount":[
  {"key":"Total Estimated Annual Income Tax (D)",'tax':"Slab Tax + Cess","value":"37009","type":"sub"},
  {"key":"Tax deducted YTD (E)",'tax':"Actual Tax deducted till last month","value":"9395","type":"sub"},
  {"key":"Balance Tax (F)",'tax':"D - E","value":"27614","type":"sub"},
  {"key":"This Month Tax Amount",'tax':"F / No of Balance Months","value":"3945","type":"total"},

],
},

//October
{ 'month':"October","year":2023, "estimated_annual_taxable_income":"620220",
"slab":[
  {"key":"Upto Rs. 2,50,000",'tax':"0","value":"0","type":"sub"},
  {"key":"Rs. 2,50,001  -  Rs. 5,00,000",'tax':"5%","value":"12500","type":"sub"},
  {"key":"Rs.5,00,001  - Rs. 6,20,220",'tax':"20%","value":"24044","type":"sub"},
  {"key":"Slab Tax (A)",'tax':"Sum of above Tax Value","value":"36544","type":"total"},

],

"surcharge":[
  {"key":"50 Lacs to 1 Crore",'tax':"10% of Slab Tax","value":"0","type":"sub"},
  {"key":"Above 1 Crore Upto 2 Crores",'tax':"15% of Income Tax","value":"0","type":"sub"},
  {"key":"Above 2 Crores Upto 5 Crores",'tax':"25% of Income Tax","value":"0","type":"sub"},
  {"key":"Above 5 Crores",'tax':"37% of Income Tax","value":"0","type":"sub"},
  {"key":"Tax After Surcharge (B)",'tax':"","value":"0","type":"total"},

],
"cess":"1461.76",

"tax_amount":[
  {"key":"Total Estimated Annual Income Tax (D)",'tax':"Slab Tax + Cess","value":"38005.76","type":"sub"},
  {"key":"Tax deducted YTD (E)",'tax':"Actual Tax deducted till last month","value":"13340","type":"sub"},
  {"key":"Balance Tax (F)",'tax':"D - E","value":"24666","type":"sub"},
  {"key":"This Month Tax Amount",'tax':"F / No of Balance Months","value":"4111","type":"total"},

],
},

//November
{ 'month':"November","year":2023, "estimated_annual_taxable_income":"643840",
"slab":[
  {"key":"Upto Rs. 2,50,000",'tax':"0","value":"0","type":"sub"},
  {"key":"Rs. 2,50,001  -  Rs. 5,00,000",'tax':"5%","value":"12500","type":"sub"},
  {"key":"Rs.5,00,001  - Rs. 6,43,840",'tax':"20%","value":"28768","type":"sub"},
  {"key":"Slab Tax (A)",'tax':"Sum of above Tax Value","value":"41268","type":"total"},

],

"surcharge":[
  {"key":"50 Lacs to 1 Crore",'tax':"10% of Slab Tax","value":"0","type":"sub"},
  {"key":"Above 1 Crore Upto 2 Crores",'tax':"15% of Income Tax","value":"0","type":"sub"},
  {"key":"Above 2 Crores Upto 5 Crores",'tax':"25% of Income Tax","value":"0","type":"sub"},
  {"key":"Above 5 Crores",'tax':"37% of Income Tax","value":"0","type":"sub"},
  {"key":"Tax After Surcharge (B)",'tax':"","value":"0","type":"total"},

],
"cess":"1650.72",

"tax_amount":[
  {"key":"Total Estimated Annual Income Tax (D)",'tax':"Slab Tax + Cess","value":"42918.72","type":"sub"},
  {"key":"Tax deducted YTD (E)",'tax':"Actual Tax deducted till last month","value":"17451","type":"sub"},
  {"key":"Balance Tax (F)",'tax':"D - E","value":"25468","type":"sub"},
  {"key":"This Month Tax Amount",'tax':"F / No of Balance Months","value":"5094","type":"total"},

],
},

//December
{ 'month':"December","year":2023, "estimated_annual_taxable_income":"648630",
"slab":[
  {"key":"Upto Rs. 2,50,000",'tax':"0","value":"0","type":"sub"},
  {"key":"Rs. 2,50,001  -  Rs. 5,00,000",'tax':"5%","value":"12500","type":"sub"},
  {"key":"Rs.5,00,001  - Rs. 6,48,630",'tax':"20%","value":"29726","type":"sub"},
  {"key":"Slab Tax (A)",'tax':"Sum of above Tax Value","value":"42226","type":"total"},

],

"surcharge":[
  {"key":"50 Lacs to 1 Crore",'tax':"10% of Slab Tax","value":"0","type":"sub"},
  {"key":"Above 1 Crore Upto 2 Crores",'tax':"15% of Income Tax","value":"0","type":"sub"},
  {"key":"Above 2 Crores Upto 5 Crores",'tax':"25% of Income Tax","value":"0","type":"sub"},
  {"key":"Above 5 Crores",'tax':"37% of Income Tax","value":"0","type":"sub"},
  {"key":"Tax After Surcharge (B)",'tax':"","value":"0","type":"total"},

],
"cess":"1689.04",

"tax_amount":[
  {"key":"Total Estimated Annual Income Tax (D)",'tax':"Slab Tax + Cess","value":"43915.04","type":"sub"},
  {"key":"Tax deducted YTD (E)",'tax':"Actual Tax deducted till last month","value":"22545","type":"sub"},
  {"key":"Balance Tax (F)",'tax':"D - E","value":"21370","type":"sub"},
  {"key":"This Month Tax Amount",'tax':"F / No of Balance Months","value":"5343","type":"total"},

],
},

//January
{ 'month':"January","year":2024, "estimated_annual_taxable_income":"650950",
"slab":[
  {"key":"Upto Rs. 2,50,000",'tax':"0","value":"0","type":"sub"},
  {"key":"Rs. 2,50,001  -  Rs. 5,00,000",'tax':"5%","value":"12500","type":"sub"},
  {"key":"Rs.5,00,001  - Rs. 6,50,950",'tax':"20%","value":"30190","type":"sub"},
  {"key":"Slab Tax (A)",'tax':"Sum of above Tax Value","value":"42690","type":"total"},

],

"surcharge":[
  {"key":"50 Lacs to 1 Crore",'tax':"10% of Slab Tax","value":"0","type":"sub"},
  {"key":"Above 1 Crore Upto 2 Crores",'tax':"15% of Income Tax","value":"0","type":"sub"},
  {"key":"Above 2 Crores Upto 5 Crores",'tax':"25% of Income Tax","value":"0","type":"sub"},
  {"key":"Above 5 Crores",'tax':"37% of Income Tax","value":"0","type":"sub"},
  {"key":"Tax After Surcharge (B)",'tax':"","value":"0","type":"total"},

],
"cess":"1707.6",

"tax_amount":[
  {"key":"Total Estimated Annual Income Tax (D)",'tax':"Slab Tax + Cess","value":"44397.6","type":"sub"},
  {"key":"Tax deducted YTD (E)",'tax':"Actual Tax deducted till last month","value":"27888","type":"sub"},
  {"key":"Balance Tax (F)",'tax':"D - E","value":"16510","type":"sub"},
  {"key":"This Month Tax Amount",'tax':"F / No of Balance Months","value":"5503","type":"total"},

],
}];


}
