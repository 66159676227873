import { AfterViewInit, Component, OnInit, ViewChild, EventEmitter, Input, Output } from '@angular/core';
import { ApiModulesService } from '../../api-modules.service';
import { ToastrService } from 'ngx-toastr';
import { SpinnerService } from 'src/app/snipper/spinner.service';
import { Table } from 'primeng/table';
@Component({
  selector: 'app-country-payroll-formula',
  templateUrl: './country-payroll-formula.component.html',
  styleUrls: ['./country-payroll-formula.component.css']
})
export class CountryPayrollFormulaComponent implements OnInit, AfterViewInit {

  @Output() parentFun: EventEmitter<any> = new EventEmitter();
  @Input() CountryID: any;
  @Input() ComponentID: any;
  @Input() Formulakey: any;

  //@ViewChild(CountryNormalSlapFormulaComponent) CountryNormalSlap: CountryNormalSlapFormulaComponent;


  isDtInitialized = false;

  public totalRecords: any = 0;
  nameBgColors: any = ["bg-blue-dim", "bg-blue-dim", "bg-azure-dim", "bg-indigo-dim", "bg-info-dim", "bg-purple-dim", "bg-pink-dim", "bg-orange-dim", "bg-teal-dim", "bg-primary-dim", "bg-success-dim", "bg-warning-dim", "bg-danger-dim", "bg-secondary-dim", "bg-dark-dim", "bg-gray-dim", "bg-blue-dim", "bg-azure-dim", "bg-indigo-dim", "bg-info-dim", "bg-purple-dim", "bg-pink-dim", "bg-orange-dim", "bg-teal-dim", "bg-primary-dim", "bg-success-dim", "bg-warning-dim", "bg-danger-dim", "bg-secondary-dim", "bg-dark-dim", "bg-gray-dim"];
  nameBorderColors: any = ["bg-outline-primary", "bg-outline-success", "bg-outline-info", "bg-outline-gray", "bg-outline-warning", "bg-outline-dark", "bg-outline-light", "bg-outline-secondary", "bg-outline-danger"];
  VariableData: any;
  CountryDropDown: any;
  ComponentDropDown: any;
  ComponentList: any;
  formulaExpression: any[] = [];
  formulaExpressionName: any[] = []
  filterData: any;
  searchKeyword: string = '';
  searchKeywords: string = '';
  public ShowForm: any = false;
  slapdropdown: any;
  formulaDropDown: any;
  showParent = false;
  selected_country: any;
  selected_component: any;
  paycomponentlabelData: any;
  formulatypeDropDown: any;
  formulaCondition: any[] = [];
  active: {}[];
  formula_id: any;
  Formulatype: any = "formula";
  formula: string;
  FormulaID: any;
  slabdropDown: any;
  SlabList: any;
  formulaConditionName: any[] = [];
  editCountryFormula: any;
  constructor(private apiModulesService: ApiModulesService,
    private toastrService: ToastrService, private spinnerService: SpinnerService) {
    // this.formulaExpression = [];
    // this.formulaExpressionName = [];
    // this.formulaCondition = [];
    // this.formulaConditionName = [];
  }
  filterComponentsCheck = []
  arithmeticConditionPattern = /^[0-9+\-*/=()%><&|[\]{}]+$/;
  arithmeticPattern = /^[0-9+\-*/=()%[\]{}]+$/;
  

  ngOnInit(): void {

    this.GetSlapDropDown();
    // this.getPaycomponentlabel();
  }

  ngAfterViewInit(): void {
  }

  rerender(): void {

  }


  GetSlapDropDown() {
    var params: any = {};
    this.apiModulesService.get('admin/formula/dropdown').subscribe((data) => {
      this.formulaDropDown = data.data.formula;
      //this.FormulaID = data.data.formula.key;
      if (this.Formulakey == null || this.Formulakey == '') {
        this.Formulakey = 'normal_formula'
      }
      setTimeout(() => {
        this.GetComponentDropDown();
        //this.editFormula();

      }, 100)
    });
  }

  GetComponentDropDown() {
    this.spinnerService.raiseDataEmitterEvent('on');
    var params: any = {};
    params.country_id = this.CountryID;
    params.component_id = this.ComponentID;
    params.formula_type = this.Formulakey;

    this.apiModulesService.list('admin/formula/list', params).subscribe(
      (data) => {
        if (data && data.data && data.data.formula) {
          this.ComponentDropDown = data.data.formula[1] != undefined ? data.data.formula[1] : [];
          this.VariableData = data.data.formula[0] != undefined ? data.data.formula[0] : [];
          this.slabdropDown = data.data.formula[3] != undefined ? data.data.formula[3] : [];
          this.paycomponentlabelData = data.data.formula[2][0] != undefined ? data.data.formula[2][0] : {};
          this.formulaExpression = this.slabdropDown.length != 0 ? this.slabdropDown[0].formula_expression : [];
          this.formulaExpressionName = this.slabdropDown.length != 0 ? this.slabdropDown[0].formula_expression_name : [];
          this.formulaCondition = this.slabdropDown.length != 0 ? this.slabdropDown[0].formula_condition : [];
          this.formulaConditionName = this.slabdropDown.length != 0 ? this.slabdropDown[0].formula_condition_name : null;
          this.formula_id = this.slabdropDown.length != 0 ? this.slabdropDown[0].formula_id : null;
          this.filterComponentsCheck = data.data.formula[4] != undefined ? data.data.formula[4] : [];

          if (this.ComponentDropDown && this.ComponentDropDown.length > 0) {
            this.filterComponents();
          } else {
            this.spinnerService.raiseDataEmitterEvent('off');
          }
        } else {
          this.spinnerService.raiseDataEmitterEvent('off');
        }
      },
      (err: any) => {
        this.spinnerService.toasterError(err);
        this.spinnerService.raiseDataEmitterEvent('off');
      }
    );
  }
  appendVarOrComp(name: string, key: string) {
    if (this.Formulatype == 'formula') {
      this.formulaExpression = this.formulaExpression || [];
      this.formulaExpressionName = this.formulaExpressionName || [];
      this.formulaExpression.push(key);
      this.formulaExpressionName.push(name);
    }

    if (this.Formulatype == 'condition') {
      this.formulaCondition = this.formulaCondition || [];
      this.formulaConditionName = this.formulaConditionName || [];
      this.formulaCondition.push(key);
      this.formulaConditionName.push(name);
    }

    this.refreshBuilder();
  }


  appendData(data: string) {

    this.onChipAdd(data, this.Formulatype, 'append_data')
  }

  clears() {
    if (this.Formulatype == 'formula') {
      this.formulaExpression = [];
      this.formulaExpressionName = [];
    } else if (this.Formulatype == 'condition') {
      this.formulaCondition = [];
      this.formulaConditionName = [];
    }
  }
  RemoveChip(index,formulatype){
    if(formulatype=='formula'){
      this.formulaExpression.splice(index, 1);

    }else if(formulatype=='condition'){
      this.formulaCondition.splice(index, 1);

    }
  }

  refreshBuilder() {
    this.formulaExpressionName = [...this.formulaExpressionName];
    this.formulaConditionName = [...this.formulaConditionName];
    this.formulaExpression = [...this.formulaExpression];
    this.formulaCondition = [...this.formulaCondition];
  }


  public insertformula() {

    const params: any = {
      component_id: this.ComponentID,
      country_id: this.CountryID,
      formula_id: this.formula_id == 0 ? null : this.formula_id,
      formula_type: this.Formulakey,
      formula_expression: this.formulaExpression,
      formula_expression_name: this.formulaExpressionName,
      formula_condition: this.formulaCondition,
      formula_condition_name: this.formulaConditionName,
    };

    this.apiModulesService.list('admin/formula/add', params).subscribe(
      (data) => {
        this.GetComponentDropDown();
        this.toastrService.success("Formula added successfully!", "Success");
      },
      (err) => {
        this.spinnerService.toasterError(err);
      }
    );
  }

  public editFormula() {

    this.formulaExpression = [];
    this.formulaExpressionName = [];
    this.formulaCondition = [];
    this.formulaConditionName = [];

    if (this.formula_id != 0) {
      let params = {
        component_id: this.ComponentID,
        country_id: this.CountryID,
        formula_id: this.formula_id,
      };
      this.apiModulesService.edit(params, 'admin/formula/edit').subscribe((data) => {
        this.formulaExpression = data.data.formula.length != 0 ? data.data.formula[0].formula_expression : [];
        this.formulaExpressionName = data.data.formula.length != 0 ? data.data.formula[0].formula_expression_name : [];
        this.formulaCondition = data.data.formula.length != 0 ? data.data.formula[0].formula_condition : [];
        this.formulaConditionName = data.data.formula.length != 0 ? data.data.formula[0].formula_condition_name : [];
      },

        (err) => {
          this.spinnerService.toasterError(err);
        });
    }

  }


  filterComponents() {
    if (!this.ComponentDropDown || this.ComponentDropDown.length === 0) {
      this.spinnerService.raiseDataEmitterEvent('off');
      return;
    }
    this.ComponentList = this.ComponentDropDown.filter((val: any) => val.component_id !== this.ComponentID);
    this.spinnerService.raiseDataEmitterEvent('off');

  }

  clear(table: Table) {
    table.clear();
  }

  ngOnDestroy() {

  }

  getAcronym(str: any) {
    var matches = str.match(/\b(\w)/g); // ['J','S','O','N']
    matches = matches.slice(0, 3);
    var acronym = matches.join(''); // JSON
    return acronym;
  }
  getBgColors(str: any) {
    var min = 0;
    var max = 15;
    var index = this.findAlphapositions(str, 1);
    return this.nameBgColors[index];
  }
  findAlphapositions(str: any, n: any) {
    var NUM = 31;

    return str[0].charCodeAt(0) & NUM;
  }






  ToparentFun() {
    this.showParent = true;
    this.parentFun.emit(this.showParent)
  }
  onChipAdd(newValue, fromTab, fromFun) {
    this.formulaExpression = this.formulaExpression || [];
    this.formulaExpressionName = this.formulaExpressionName || [];
    this.formulaCondition = this.formulaCondition || [];
    this.formulaConditionName = this.formulaConditionName || [];
    const filter_data = this.filterComponentsCheck.filter((val: any) => val.lable == newValue);
    if (fromTab == 'formula') {

      if (filter_data.length != 0) {
        this.formulaExpression.push(filter_data[0].key);
      } else if (this.arithmeticPattern.test(newValue)) {
        this.formulaExpression.push(newValue);
        if (fromFun == 'append_data') {
          this.formulaExpressionName.push(newValue);

        }

      } else {
        this.formulaExpressionName.pop();
      }
    }
    else if (fromTab == 'condition') {
      if (filter_data.length != 0) {
        this.formulaCondition.push(filter_data[0].key);

      } else if (this.arithmeticConditionPattern.test(newValue)) {
        this.formulaCondition.push(newValue);
        if (fromFun == 'append_data') {
          this.formulaConditionName.push(newValue);

        }

      } else {
        this.formulaConditionName.pop();
      }
    }
    this.refreshBuilder();

  }
  callChip(event: any, type) {
    this.Formulatype = type
    const trimData = event.value.trim()
    this.onChipAdd(trimData, type, 'callChip')
  }
}
