import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AllModulesComponent} from './all-modules.component';
import {AdminAuthenticationGaurd} from '../core/auth/admin-authentication.gaurd';
import {EmployeeAuthenticationGuard} from '../core/auth/employee-authentication.guard';
import {ClientAuthenticationGaurd} from '../core/auth/client-authentication.gaurd';
import {CompanyAuthenticationGaurd} from '../core/auth/company-authentication.gaurd';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'dashboard/admin',
        pathMatch: 'full',
    },
    {
        path: '',
        component: AllModulesComponent,
        children: [{
            path: 'dashboard',
            loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
        }]
    },
    {
        path: '',
        component: AllModulesComponent,
        children: [{
            path: 'employee',
            loadChildren: () => import('./ess-tabs/ess-tabs.module').then((m) => m.EssTabsModule)
        }], canActivate: [EmployeeAuthenticationGuard]
    },
    {
        path: '',
        component: AllModulesComponent,
        children: [{
            path: 'admin',
            loadChildren: () => import('./admin-tabs/admin-tabs.module').then((m) => m.AdminTabsModule)
        }], canActivate: [AdminAuthenticationGaurd],
    }, {
        path: '',
        component: AllModulesComponent,
        children: [{
            path: 'client/company',
            loadChildren: () => import('./company/company.module').then((m) => m.CompanyModule)
        }, {
            path: 'client/employee',
            loadChildren: () => import('./employees/employees.module').then((m) => m.EmployeesModule)
        }], canActivate: [ClientAuthenticationGaurd],
    }, {
        path: '',
        component: AllModulesComponent,
        children: [{
            path: 'company/company',
            loadChildren: () => import('./company/company.module').then((m) => m.CompanyModule)
        }, {
            path: 'company/employee',
            loadChildren: () => import('./employees/employees.module').then((m) => m.EmployeesModule)
        }], canActivate: [CompanyAuthenticationGaurd],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class AllModulesRoutingModule {
}
