// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-wrappers{
  margin-left: 16px;
    margin-right: 12px;

}
.white_space{
 background-color: #fff !important;
 border-color: #fff !important;
 border-collapse: collapse;
 border-bottom-color: #fff;
 border-top-color: #fff;
}
.modal-page{
  margin-left: -3px;
    margin-top: -31px;

}
 .undo_redo{
cursor: pointer;
}
.pf_cont_dropdown{
  margin-left: 48%;
  margin-top: -58px;
  margin-bottom: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/app/all-modules/ess-tabs/IND-ess/IND-employee-payroll/provident-fund/pfcontribution/pfcontribution.component.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;IACf,kBAAkB;;AAEtB;AACA;CACC,iCAAiC;CACjC,6BAA6B;CAC7B,yBAAyB;CACzB,yBAAyB;CACzB,sBAAsB;AACvB;AACA;EACE,iBAAiB;IACf,iBAAiB;;AAErB;CACC;AACD,eAAe;AACf;AACA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;AACpB","sourcesContent":[".page-wrappers{\r\n  margin-left: 16px;\r\n    margin-right: 12px;\r\n\r\n}\r\n.white_space{\r\n background-color: #fff !important;\r\n border-color: #fff !important;\r\n border-collapse: collapse;\r\n border-bottom-color: #fff;\r\n border-top-color: #fff;\r\n}\r\n.modal-page{\r\n  margin-left: -3px;\r\n    margin-top: -31px;\r\n\r\n}\r\n .undo_redo{\r\ncursor: pointer;\r\n}\r\n.pf_cont_dropdown{\r\n  margin-left: 48%;\r\n  margin-top: -58px;\r\n  margin-bottom: 5px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
