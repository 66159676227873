// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.display_dashboard{
    display:grid;
    grid-template-columns: auto auto auto auto;
}`, "",{"version":3,"sources":["webpack://./src/app/all-modules/ess-tabs/IND-ess/IND-employee-hrms/employee-hrms-leaves/employee-hrms-leaves.component.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,0CAA0C;AAC9C","sourcesContent":[".display_dashboard{\r\n    display:grid;\r\n    grid-template-columns: auto auto auto auto;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
