import { Component, OnInit,ViewChild ,OnDestroy,AfterViewInit } from '@angular/core';
import{ApiModulesService} from '../../../api-modules.service';
import{AllModulesService} from '../../../all-modules.service';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import {Router} from '@angular/router';

import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { param } from 'jquery';
import { Table } from 'primeng/table';
import { SpinnerService } from 'src/app/snipper/spinner.service';
@Component({
    selector: 'app-industries-main',
    templateUrl: './industries-main.component.html',
    styleUrls: ['./industries-main.component.css']
})
export class IndustriesMainComponent implements OnInit,OnDestroy,AfterViewInit {


    public industriesData =[];
    public countryname = [];
    public rows = [];
    public srch = [];
    public selected_country:any;
    searchIndustries:any;

    isDtInitialized = false;

    public addIndustryForm:FormGroup;
    public editIndustryForm:FormGroup;
    public gridData: any;
    listHeaders =[];
    loading=false;
    searchKeyword: string = '';
    lasttwo: any;
    public totalRecords: any = 0;
    nameBgColors:any = ["bg-blue-dim","bg-blue-dim","bg-azure-dim","bg-indigo-dim","bg-info-dim","bg-purple-dim","bg-pink-dim","bg-orange-dim","bg-teal-dim","bg-primary-dim","bg-success-dim","bg-warning-dim","bg-danger-dim","bg-secondary-dim","bg-dark-dim","bg-gray-dim","bg-blue-dim","bg-azure-dim","bg-indigo-dim","bg-info-dim","bg-purple-dim","bg-pink-dim","bg-orange-dim","bg-teal-dim","bg-primary-dim","bg-success-dim","bg-warning-dim","bg-danger-dim","bg-secondary-dim","bg-dark-dim","bg-gray-dim"];
    nameBorderColors:any = ["bg-outline-primary","bg-outline-success","bg-outline-info","bg-outline-gray","bg-outline-warning","bg-outline-dark","bg-outline-light","bg-outline-secondary","bg-outline-danger"];
    statuses: { label: string; value: string; }[];
    statusApi: any;
    statusValues: any;
    constructor(private apiModulesService: ApiModulesService, private toastr: ToastrService,private formBuilder: FormBuilder, private AllModulesService:AllModulesService,private spinnerService: SpinnerService) { }

    ngOnInit(): void {

        this.getCountriesName();

        this.addIndustryForm=this.formBuilder.group({
            country_id: ['', [Validators.required]],
            industry_name: ['', [Validators.required]],

        });
        this.editIndustryForm=this.formBuilder.group({
            country_id: ['', [Validators.required]],
            industry_name: ['', [Validators.required]],
            industry_type_id:['',[]],
        });

    }
    ngAfterViewInit(): void {

    }

    //Reset form
    public resetForm() {
        this.addIndustryForm.reset();
    }
    //add industry
    public onAddindustry() {
        if (this.addIndustryForm.invalid) {
            this.addIndustryForm.markAllAsTouched();
            return;
        }
        var params:any = {};
        params.industry_type = this.addIndustryForm.get('industry_name').value;
        params.country_id = this.addIndustryForm.get('country_id').value;
        params.status = 1;
        this.apiModulesService.add(params,'admin/industry/add',).subscribe((data) => {

                    this.toastr.success('Industry added successfully...!', 'Success');
                    this.selected_country=this.addIndustryForm.get('country_id').value;

                    this.getIndustriesType();
                    ($('#add_industry')as any).modal('hide');
                    this.addIndustryForm.reset();

            },(err)=>{
                if (err.status === 437) {
                    this.toastr.error('State Already Exists...!', 'Failed');
                } else {
                    this.toastr.error('Something Went Wrong Please Try Again...!', 'Error');
                }
            });
        }

    rerender(): void {

    }
    public getIndustriesType() {
        this.spinnerService.raiseDataEmitterEvent('on');
        const params:any ={};
        params.country_id=this.selected_country;
        this.apiModulesService.list('admin/industry/list',params).subscribe((data) => {
          this.industriesData = (data.data.industries != undefined) ? data.data.industries : [];
          this.loading=false;
          var totalRecords = (this.industriesData[0] != undefined) ? this.industriesData[0].total_records : 0;
          this.totalRecords = totalRecords;
          this.resetForm();
          setTimeout(() => {
            this.spinnerService.raiseDataEmitterEvent('off');
          }, 100);
        }, (err) => {
          this.industriesData = [];
          this.spinnerService.raiseDataEmitterEvent('off');
          this.toastr.error('Something went wrong!', 'Try Again');
          // this.rerender();
        });
        this.statuses = [
            { label: 'Active', value: 'active' },
            { label: 'Inactive', value: 'inactive' },
          ];
      }

      clear(table: Table) {
        table.clear();
      }

      getSeverity(status: string) {
        switch (status.toLowerCase()) {
          case 'inactive':
            return 'danger';

          case 'active':
            return 'success';
        }
      }

// get datas
    public getIndustriesTyp(callFrom: any, callback: any) {
        const params:any ={};
        params.country_id=this.selected_country;
        this.apiModulesService.list('admin/industry/list',params).subscribe((data) => {

                    this.industriesData = (data.data.industries != undefined) ? data.data.industries : [];
                    var totalRecords = this.industriesData.length;
                    this.totalRecords = totalRecords;
            setTimeout(function () {
                var firstcolwidth = $(".table.custom-table>tbody>tr>td.fixed:first-child").width();
                var lastcolwidth = $(".table.custom-table>thead>tr>th:last-child").width();
                $(".table.custom-table>thead>tr>th.fixed:nth-child(2)").css("left", firstcolwidth + 20);
                $(".table.custom-table>tbody>tr>td.fixed:nth-child(2)").css("left", firstcolwidth + 20);
                $(".table.custom-table>thead>tr>th:nth-last-child(2)").css("right", lastcolwidth + 20);
                $(".table.custom-table>tbody>tr>td:nth-last-child(2)").css("right", lastcolwidth + 20);
            }, 2000);
            callback({
                recordsTotal: totalRecords,
                recordsFiltered: totalRecords,
                data: []
            });
    //  this.industriesData = gridData;

        });
    }


//get countries name
    public getCountriesName(){
        this.apiModulesService.get('country/namelist').subscribe((data)=>{
            this.countryname =data;
            this.selected_country=this.countryname[0].country_id;
            this.getIndustriesType();
        });
    }
    searchName(val) {
        this.rows.splice(0, this.rows.length);
        let temp = this.srch.filter(function (d) {
            val = val.toLowerCase();
            return d.industry_type_name.toLowerCase().indexOf(val) !== -1 ||d.industry_type_id.toLowerCase().indexOf(val) !== -1;
        });
        this.rows.push(...temp);
    }

    //edit value get
    public onEditIndustries(IndustryId: any) {
        var params:any = {};
        params.industry_id = IndustryId;
        this.apiModulesService.edit(params,'admin/industry/edit').subscribe((data) => {
            let industryDetail = data.data.industries;
            this. editIndustryForm.setValue({
                industry_name:industryDetail.industry_type_name,
                country_id:industryDetail.country_id,
                industry_type_id:industryDetail.industry_type_id,
            });
        });

    }
    //edit update
    public onEditChanges(){
        if (this.editIndustryForm.invalid) {
            this.editIndustryForm.markAllAsTouched();
            return;
        }
        var params:any={};
        params.industry_name=this.editIndustryForm.get('industry_name').value;
        params.country_id = this.editIndustryForm.get('country_id').value;
        params.industry_id=this.editIndustryForm.get('industry_type_id').value;
        this.apiModulesService.edit(params,'admin/industry/update').subscribe((data)=>{

               this.selected_country=this.editIndustryForm.get('country_id').value;
                    this.toastr.success('Industry updated successfully...!', 'Success');
                    this.getIndustriesType();
                    ($('#edit_industry')as any).modal('hide');


            },(err)=>{
                if (err.status === 437) {
                    this.toastr.error('Industry Already Exists...!', 'Failed');
                } else {
                    this.toastr.error('Something Went Wrong Please Try Again...!', 'Error');
                }
            });
    }
    // status change
    changeStatus(status:any,IndustriesId:any) {

        var params:any = {};
        params.industry_id = IndustriesId;
        params.status= status;
        this.apiModulesService.edit(params,'admin/industry/status').subscribe((data) => {

                this.toastr.success('Status changed successfully...!', 'Success');
                this.getIndustriesType();
                ($('#industry-status') as any).modal('hide');
            },(err)=>{
                this.toastr.error('Something went wrong!', 'Try Again');
            }
        );
    }
    ngOnDestroy() {
    }
    getAcronym(str:any){
        var matches = str.match(/\b(\w)/g); // ['J','S','O','N']
        matches= matches.slice(0,2);
        var acronym = matches.join(''); // JSON
        return acronym;
    }
    getBgColors(str:any){
        var min = 0;
        var max = 15;
        var index = this.findAlphapositions(str,1);
        return this.nameBgColors[index];
    }
    findAlphapositions(str:any, n:any)
    {
        var NUM = 31;

        return str[0].charCodeAt(0) & NUM;
    }
    callStatusModel(url_name:any,data:any){
        this.statusApi=url_name;
        this.statusValues=data;
      }

}
