import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {ApiModulesService} from '../../api-modules.service';
import {FormBuilder, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {FormGroup} from '@angular/forms';
import {Subject} from 'rxjs';
import {documentservice} from '../../documents.sevice';
import {SpinnerService} from "../../../snipper/spinner.service";

import { Table } from 'primeng/table';

@Component({
    selector: 'app-documents',
    templateUrl: './documents.component.html',
    styleUrls: ['./documents.component.css']
})
export class DocumentsComponent implements OnInit,OnDestroy,AfterViewInit {
    public DocumentsData = [];
    public rows = [];
    tabToGrid=false;
    @Output() parenFun: EventEmitter<any> = new EventEmitter();
    @Input() authEmployeeData: any = {};
    @Input() pageName: any = {};

    nameBgColors: any = ['bg-blue-dim', 'bg-blue-dim', 'bg-azure-dim', 'bg-indigo-dim', 'bg-info-dim', 'bg-purple-dim', 'bg-pink-dim', 'bg-orange-dim', 'bg-teal-dim', 'bg-primary-dim', 'bg-success-dim', 'bg-warning-dim', 'bg-danger-dim', 'bg-secondary-dim', 'bg-dark-dim', 'bg-gray-dim', 'bg-blue-dim', 'bg-azure-dim', 'bg-indigo-dim', 'bg-info-dim', 'bg-purple-dim', 'bg-pink-dim', 'bg-orange-dim', 'bg-teal-dim', 'bg-primary-dim', 'bg-success-dim', 'bg-warning-dim', 'bg-danger-dim', 'bg-secondary-dim', 'bg-dark-dim', 'bg-gray-dim'];
    nameBorderColors: any = ['bg-outline-primary', 'bg-outline-success', 'bg-outline-info', 'bg-outline-gray', 'bg-outline-warning', 'bg-outline-dark', 'bg-outline-light', 'bg-outline-secondary', 'bg-outline-danger'];
    filedata:any;
    submited_error=true;
    response=true;
    submit_index:any;
    showDocumentDetails:boolean=true;
    ShowForm=false;
    addDocumentForm:FormGroup;
    filesView: any = [];
    searchKeyword: string = '';
    submitted=false;
    Documents: any;
    statusApi:any;
    statusValues:any;
    documentCategory=[];
    chosenFileName='No File Chosen';
    statuses=[];
    private user_type=localStorage.getItem('user_type');
    loading: boolean = false;
    constructor(
        private toastr: ToastrService,
        private formBuilder: FormBuilder,
        private apiModulesService: ApiModulesService,
        private documentService: documentservice,
        private spinnerService: SpinnerService
    ) {
    }

    ngOnInit(): void {
      this.spinnerService.raiseDataEmitterEvent('on');
        this.addDocumentForm=this.formBuilder.group({
            document_type_id:['',[Validators.required]],
            document_value:['',[Validators.required]],
            document_name:['',[Validators.required]],
            document_path:[''],
            old_path:[''],
            issue_date:[''],
            valid_from:[''],
            issue_place:[''],
            valid_to:[''],
            file_name:[''],
            employee_document_id:[''],
        })

        this.dropDown();
        this.onEditDocumentsDetails();
        this.statuses = [
          { label: 'Active', value: 'active' },
          { label: 'Inactive', value: 'inactive' },
      ];
    }
    ngAfterViewInit(): void {

    }
    resetForm(){
        this.addDocumentForm.reset();
    }

    // Add Documents
    public updateDocuments() {

        if (!this.addDocumentForm.valid) {

            this.addDocumentForm.markAllAsTouched();
            return;
        }
        else {
            this.spinnerService.raiseDataEmitterEvent('on');
            const myFormData = new FormData();
                myFormData.append('document_path', this.filedata != undefined ? this.filedata : null);
                myFormData.append('client_id', this.authEmployeeData.client_id);
                myFormData.append('company_id', this.authEmployeeData.company_id);
                myFormData.append('employee_no', this.authEmployeeData.employee_no);
                myFormData.append('employee_id', this.authEmployeeData.employee_id);
                myFormData.append('document_type_id', this.addDocumentForm.value.document_type_id);
                myFormData.append('document_value', this.addDocumentForm.value.document_value);
                myFormData.append('document_name', this.addDocumentForm.value.document_name);
                myFormData.append('issue_date', this.addDocumentForm.value.issue_date);
                myFormData.append('issue_place', this.addDocumentForm.value.issue_place);
                myFormData.append('valid_from', this.addDocumentForm.value.valid_from);
                myFormData.append('valid_to',this.addDocumentForm.value.valid_to);
                myFormData.append('old_path', this.addDocumentForm.value.old_path);
                myFormData.append('file_name', this.addDocumentForm.value.file_name);
                this.documentService.post('api/admin/documents/add', myFormData).subscribe((data) => {
                    this.toastr.success('Successfully update document!', 'Success');
                    /* Image Post Request */
                    this.filedata = null;
this.resetForm();
                    this.chosenFileName='No File Chosen';
                    this.onEditDocumentsDetails();
                    this.ShowForm=false;
                }, (err) => {
                  this.spinnerService.toasterError(err);
                });
            }
    }
    parentFun(){

        this.parenFun.emit(this.tabToGrid)
    }
    // public uploadDocuments(DocumentForm,employDocForm) {
    //     const allDocuments=DocumentForm;
    //     const count=allDocuments.length;
    //     const  functionCall=allDocuments.filter((val:any)=>val.document_value !== null && val.document_name !== null);
    //     const filterCount=functionCall.length-1;
    //     for (let index=0;index<count;index++) {
    //         if (DocumentForm[index].document_value !== null && DocumentForm[index].document_name !== null) {
    //             const myFormData = new FormData();
    //             myFormData.append('document_path', this.filedata[index] != undefined ? this.filedata[index] : null );
    //             myFormData.append('client_id', this.authEmployeeData.client_id);
    //             myFormData.append('company_id', this.authEmployeeData.company_id);
    //             myFormData.append('employee_no', this.authEmployeeData.employee_no);
    //             myFormData.append('employee_id', this.authEmployeeData.employee_id);
    //             myFormData.append('document_type_id', DocumentForm[index].document_type_id);
    //             myFormData.append('document_value', DocumentForm[index].document_value);
    //             myFormData.append('document_name', DocumentForm[index].document_name);
    //             myFormData.append('issue_date', DocumentForm[index].issue_date);
    //             myFormData.append('issue_place', DocumentForm[index].issue_place);
    //             myFormData.append('valid_from', DocumentForm[index].valid_from);
    //             myFormData.append('valid_to', DocumentForm[index].valid_to);
    //             myFormData.append('old_path', DocumentForm[index].document_path);
    //             myFormData.append('file_name', DocumentForm[index].file_name);
    //             this.documentService.post('api/admin/documents/add', myFormData).subscribe((data) => {
    //                 // this.toastr.success('Successfully update document!', 'Success');
    //                 /* Image Post Request */
    //                 this.response=true;
    //                 this.filedata[index] = null;
    //                 if(DocumentForm[index].document_type_id === functionCall[filterCount].document_type_id)
    //                 {
    //                     setTimeout(() => {
    //                         this.onEditDocumentsDetails();
    //                          this.toastr.success('Status  Added Sucessfully...!', 'Success');
    //                     },2000)
    //                 }
    //                 this.chosenFileName = 'No File Chosen';
    //             }, (err) => {
    //                 this.response=false;
    //                 this.filedata[index] = null;
    //                 this.chosenFileName = 'No File Chosen';
    //                 this.toastr.error('Something went wrong!', 'Try Again');
    //             });
    //             if(this.response===true){
    //                  // this.response=false;
    //                 continue;
    //             }
    //           else{
    //               break;
    //             }
    //         }
    //
    //         else
    //         {
    //             continue
    //         }
    //     }
    // }
    /* File onchange event */
    fileEvent(e,index) {
        this.filedata = e.target.files[0];
    }

    // Edit Nationality
    public onEditDocumentsDetails() {
      this.spinnerService.raiseDataEmitterEvent('on');
        const params: any = {};

            params.client_id = this.authEmployeeData.client_id;
            params.company_id = this.authEmployeeData.company_id;
            params.employee_id = this.authEmployeeData.employee_id;
            this.apiModulesService.list('admin/documents/list', params).subscribe((data) => {
              this.DocumentsData = (data.data.employee_document != undefined) ? data.data.employee_document : [];
              this.spinnerService.raiseDataEmitterEvent('off');

            },(err)=>{

                this.DocumentsData = [];
                this.spinnerService.toasterError(err);
            });
    }


    public DownloadFile(employeeDocumentId) {

        const params: any = {};
        params.client_id = this.authEmployeeData.client_id;
        params.company_id = this.authEmployeeData.company_id;
        params.employee_id = this.authEmployeeData.employee_id;
        params.employee_document_id = employeeDocumentId;

        this.apiModulesService.add(params, 'admin/documents/download').subscribe((data) => {
          this.onEditDocumentsDetails();
          const docDetails=data.data.employee_document;
                this.documentService.DownloadExcel(docDetails.doc_name,docDetails.doc_url);

               // this.toastr.success('Status  Added Sucessfully...!', 'Success');
            }, (err) => {
              this.spinnerService.toasterError(err);
            }
        );


    }
    changeStatus(status: any,employeeDocumentId) {


        const params: any = {};
        params.client_id = this.authEmployeeData.client_id;
        params.company_id = this.authEmployeeData.company_id;
        params.employee_id = this.authEmployeeData.employee_id;
        params.employee_document_id = employeeDocumentId,
        this.apiModulesService.edit(params, 'admin/documents/' + status).subscribe((data) => {
            this.toastr.success('Status  Changed Sucessfully...!', 'Success');
                this.onEditDocumentsDetails();
                ($('#employeedocument-status') as any).modal('hide');
            }, (err) => {
              this.spinnerService.toasterError(err);
            }
        );


    }
    dropDown() {


        const params: any = {};
        params.client_id = this.authEmployeeData.client_id;
        params.company_id = this.authEmployeeData.company_id;
        params.employee_id = this.authEmployeeData.employee_id;
        this.apiModulesService.edit(params, 'admin/documents/dropDown').subscribe((data) => {
                this.documentCategory=data.data.employee_document !=undefined ? data.data.employee_document :[];
            }, (err) => {
              this.spinnerService.toasterError(err);
            }
        );


    }
    editValue(employeeDocumentId) {


        const params: any = {};
        params.client_id = this.authEmployeeData.client_id;
        params.company_id = this.authEmployeeData.company_id;
        params.employee_id = this.authEmployeeData.employee_id;
        params.employee_document_id = employeeDocumentId;
        this.apiModulesService.edit(params, 'admin/documents/edit').subscribe((data) => {
            const documentEditVale=data.data.employee_document[0] !=undefined ? data.data.employee_document[0] :[];
            this.addDocumentForm.setValue({
                document_type_id:documentEditVale.document_type_id,
                document_value:documentEditVale.document_value,
                document_name:documentEditVale.document_name,
                document_path:null,
                old_path:documentEditVale.document_path,
                issue_date:documentEditVale.issue_date,
                valid_from:documentEditVale.valid_from,
                issue_place:documentEditVale.issue_place,
                valid_to:documentEditVale.valid_to,
                file_name:documentEditVale.file_name,
                employee_document_id:documentEditVale.employee_document_id,
            })
            this.ShowForm = true;
            }, (err) => {
              this.spinnerService.toasterError(err);
            }
        );


    }
    getAcronym(str:any){
        let matches = str.match(/\b(\w)/g); // ['J','S','O','N']
        matches= matches.slice(0,3);
        const acronym = matches.join(''); // JSON
        return acronym;
    }
    getBgColors(str:any){
        const min = 0;
        const max = 15;
        const index = this.findAlphapositions(str,1);
        return this.nameBgColors[index];
    }
    findAlphapositions(str:any, n:any)
    {
        const NUM = 31;

        return str[0].charCodeAt(0) & NUM;
    }
    ngOnDestroy(): void  {

    }
    showAddForm() {
        this.ShowForm = !this.ShowForm;
    }clear(table: Table) {
      table.clear();
  }

  getSeverity(status: string) {
      switch (status.toLowerCase()) {
          case 'inactive':
              return 'danger';

          case 'active':
              return 'success';
      }
  }
  callStatusModel(url_name:any,data:any,actionName:string){
    this.statusApi=url_name;
    this.statusValues=data;
}
}

