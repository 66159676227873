import { Component, OnInit, ViewChild,AfterViewInit,OnDestroy } from '@angular/core';
import { ApiModulesService } from '../../api-modules.service';
import { AllModulesService } from "../../all-modules.service";

import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription, Subject, } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { Table } from 'primeng/table';
import { SpinnerService } from 'src/app/snipper/spinner.service';
declare const $: any;
@Component({
  selector: 'app-companydepartment',
  templateUrl: './companydepartment.component.html',
  styleUrls: ['./companydepartment.component.css']
})
export class CompanydepartmentComponent implements OnInit, OnDestroy, AfterViewInit {




  isDtInitialized: boolean = false;
  ClientID = localStorage.getItem('client_id');
  CompanyID = localStorage.getItem('company_id');
  public addDepartmentForm: FormGroup
  public ShowForm: any = false;
  public companydepartmentData = [];
  public totalRecords: any = 0;
  searchKeyword: string = '';
  ClientDropDown = [];
  CompanyDropDown = [];
  nameBgColors: any = ["bg-blue-dim", "bg-blue-dim", "bg-azure-dim", "bg-indigo-dim", "bg-info-dim", "bg-purple-dim", "bg-pink-dim", "bg-orange-dim", "bg-teal-dim", "bg-primary-dim", "bg-success-dim", "bg-warning-dim", "bg-danger-dim", "bg-secondary-dim", "bg-dark-dim", "bg-gray-dim", "bg-blue-dim", "bg-azure-dim", "bg-indigo-dim", "bg-info-dim", "bg-purple-dim", "bg-pink-dim", "bg-orange-dim", "bg-teal-dim", "bg-primary-dim", "bg-success-dim", "bg-warning-dim", "bg-danger-dim", "bg-secondary-dim", "bg-dark-dim", "bg-gray-dim"];
  nameBorderColors: any = ["bg-outline-primary", "bg-outline-success", "bg-outline-info", "bg-outline-gray", "bg-outline-warning", "bg-outline-dark", "bg-outline-light", "bg-outline-secondary", "bg-outline-danger"];
  statuses=[];
  loading=false;
  statusApi: any;
  statusValues: any;
  constructor(private apiModulesService: ApiModulesService, private AllModulesService: AllModulesService, private toaster: ToastrService, private toastr: ToastrService, private formBuilder: FormBuilder,private spinnerService: SpinnerService) { }

  ngOnInit(): void {
      this.spinnerService.raiseDataEmitterEvent('on');

    this.GetClientDropDown();
    this.addDepartmentForm = this.formBuilder.group({
      department_name: ["", [Validators.required]],
      department_code: ["", [Validators.required]],
      client_id: ["", []],
      company_id: ["", []],
      company_department_id: ["", []]

    });
    this.statuses = [
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ];
  }

  showAddForm() {
    this.ShowForm = true;
    this.resetForm();
  }

  //Get all company department data
  public getCompanyDepartment() {
    this.spinnerService.raiseDataEmitterEvent('on');
    var params:any={};
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
          this.apiModulesService.list("admin/company-department/list", params).subscribe((data) => {
              this.companydepartmentData = (data.data.company_department != undefined) ? data.data.company_department : [];
              this.loading = false;
              var totalRecords = (this.companydepartmentData[0] != undefined) ? this.companydepartmentData[0].total_records : 0;
              this.totalRecords = totalRecords;
              this.resetForm();
              setTimeout(() => {
                this.spinnerService.raiseDataEmitterEvent('off');
            }, 100);
          }, (err) => {
            this.companydepartmentData = [];
            this.spinnerService.raiseDataEmitterEvent('off');
            // this.toastr.error('Something went wrong!', 'Try Again');
              // this.rerender();
          });
  }

  changeStatus(Status: any, department) {
    let params: any = {};
    params.client_id = this.ClientID;
    params.company_department_id = department.company_department_id,
      params.company_id = this.CompanyID;
    this.apiModulesService.edit(params, 'admin/company-department/' + Status).subscribe((data) => {
      this.getCompanyDepartment();
      ($('#companydepartment-status') as any).modal('hide');
      this.toaster.success('Status Changed Successfully!', 'Success')
    }, (err) => {
      let errors = err.error.data.company_department;
      errors = errors.replace('SQLSTATE[42000]: [Microsoft][ODBC Driver 17 for SQL Server][SQL Server]', 'Error: ')
      errors = errors.replace('SQLSTATE[42000]: [Microsoft][ODBC Driver 18 for SQL Server][SQL Server]', 'Error: ')
      this.toastr.error(errors, 'TryAgain');
      }
    );
  }
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event

  }
  public onAddDepartment() {
    if (!this.addDepartmentForm.valid) {

      this.addDepartmentForm.markAllAsTouched();
      return;
    }
    var params: any = {};
    params.company_id = this.CompanyID;
    params.client_id = this.ClientID;
    params.department_name = this.addDepartmentForm.get('department_name').value;
    params.department_code = this.addDepartmentForm.get('department_code').value;

    var company_department_id = this.addDepartmentForm.get('company_department_id').value;

    if (company_department_id != '' && company_department_id != undefined) {
      params.company_department_id = this.addDepartmentForm.get('company_department_id').value;
      this.apiModulesService.edit(params, "admin/company-department/update",).subscribe((data) => {


        this.getCompanyDepartment();
        this.toastr.success('department Modified Sucessfully...!', 'Success');
        ($('#add_department') as any).modal('hide');
      }, (err) => {
        let errors = err.error.data.company_department;
        errors = errors.replace('SQLSTATE[42000]: [Microsoft][ODBC Driver 17 for SQL Server][SQL Server]', 'Error: ')
        errors = errors.replace('SQLSTATE[42000]: [Microsoft][ODBC Driver 18 for SQL Server][SQL Server]', 'Error: ')
        this.toastr.error(errors, 'TryAgain');
        });

    } else {
      this.apiModulesService.add(params, "admin/company-department/add",).subscribe((data) => {
        this.getCompanyDepartment();
        this.toastr.success('department Added Sucessfully...!', 'Success');
        this.addDepartmentForm.reset();
        ($('#add_department') as any).modal('hide');
      }, (err) => {
        let errors = err.error.data.company_department;
        errors = errors.replace('SQLSTATE[42000]: [Microsoft][ODBC Driver 17 for SQL Server][SQL Server]', 'Error: ')
        errors = errors.replace('SQLSTATE[42000]: [Microsoft][ODBC Driver 18 for SQL Server][SQL Server]', 'Error: ')
        this.toastr.error(errors, 'TryAgain');
        });
    }
  }
  //Reset form
  public resetForm() {
    this.addDepartmentForm.reset();
  }
  public update(
    params) {



    this.addDepartmentForm.setValue({

      department_name: params.department_name,
      department_code: params.department_code,
      company_department_id: params.company_department_id,
      client_id: params.client_id,
      company_id: params.company_id
    });
  }

  ngAfterViewInit(): void {

  }
  rerender() {

  }
  /* client DropDown codes*/
  public GetClientDropDown() {
    this.apiModulesService.get('DropDown/client').subscribe((data) => {
      this.ClientDropDown = data.data.client;

      this.ClientID = data.data.client[0].client_id;
      this.GetCompanyDropDown();
    })
  }


  /* company DropDown codes */
  public GetCompanyDropDown() {
    const params: any = {};
    params.client_id = this.ClientID;

    this.apiModulesService.list('DropDown/company', params).subscribe((data) => {
      this.CompanyDropDown = data.data.company != undefined ? data.data.company : [];
      if (this.CompanyDropDown.length !== 0) {
        setTimeout(() => {
          this.CompanyID = this.CompanyDropDown[0].company_id;
          this.getCompanyDepartment();
        }, 100)
      }
      else {
        this.CompanyID = null;
        this.rerender();
      }
    })
  }
  getAcronym(str: any) {
    var matches = str.match(/\b(\w)/g); // ['J','S','O','N']
    matches = matches.slice(0, 2);
    var acronym = matches.join(''); // JSON
    return acronym;
  }
  getBgColors(str: any) {
    var min = 0;
    var max = 15;
    var index = this.findAlphapositions(str, 1);
    return this.nameBgColors[index];
  }
  findAlphapositions(str: any, n: any) {
    var NUM = 31;

    return str[0].charCodeAt(0) & NUM;
  }
  clear(table: Table) {
    table.clear();
  }

  getSeverity(status: string) {
    switch (status.toLowerCase()) {
      case 'inactive':
        return 'danger';

      case 'active':
        return 'success';
    }
  }
  callStatusModel(url_name:any,data:any){
    this.statusApi=url_name;
    this.statusValues=data;
}
}
