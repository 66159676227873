import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import{ApiModulesService} from '../../../api-modules.service';
import{AllModulesService} from '../../../all-modules.service';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

import { HttpClient, HttpResponse } from '@angular/common/http';
import { SpinnerService } from 'src/app/snipper/spinner.service';
import { Table } from 'primeng/table';


@Component({
  selector: 'app-globalcustomfields-main',
  templateUrl: './globalcustomfields-main.component.html',
  styleUrls: ['./globalcustomfields-main.component.css']
})
export class GlobalcustomfieldsMainComponent implements OnInit, OnDestroy, AfterViewInit {


  public fieldData = [];
  public rows = [];
  public srch = [];
  public totalRecords: any = 0;
  public countryname = [];
  isDtInitialized: boolean = false;
  public addFieldForm: FormGroup;
  public editFieldForm: FormGroup;

  searchKeyword: string = '';

  codeRegex = /^[ A-Za-z0-9_]*$/;
  page: any;
  searchCountry: any;
  public selected_country: any;
  public CountryName = [];
  public FilterField: any;
  globalData = [];
  listHeaders = [];
  statuses = [];
  public ShowForm: any = false;
  showTabs = false;
  public pageNo = 1;
  public limit = 10;
  nameBgColors: any = ['bg-blue-dim', 'bg-blue-dim', 'bg-azure-dim', 'bg-indigo-dim', 'bg-info-dim', 'bg-purple-dim', 'bg-pink-dim', 'bg-orange-dim', 'bg-teal-dim', 'bg-primary-dim', 'bg-success-dim', 'bg-warning-dim', 'bg-danger-dim', 'bg-secondary-dim', 'bg-dark-dim', 'bg-gray-dim', 'bg-blue-dim', 'bg-azure-dim', 'bg-indigo-dim', 'bg-info-dim', 'bg-purple-dim', 'bg-pink-dim', 'bg-orange-dim', 'bg-teal-dim', 'bg-primary-dim', 'bg-success-dim', 'bg-warning-dim', 'bg-danger-dim', 'bg-secondary-dim', 'bg-dark-dim', 'bg-gray-dim'];
  nameBorderColors: any = ['bg-outline-primary', 'bg-outline-success', 'bg-outline-info', 'bg-outline-gray', 'bg-outline-warning', 'bg-outline-dark', 'bg-outline-light', 'bg-outline-secondary', 'bg-outline-danger'];
  loading: boolean = false;

  constructor(private apiModulesService: ApiModulesService, private AllModulesService: AllModulesService, private toastr: ToastrService, private formBuilder: FormBuilder, private http: HttpClient, private spinnerService: SpinnerService,) { }

  ngOnInit(): void {

    this.getCountriesName();


    this.addFieldForm = this.formBuilder.group({
      custom_field_name: ['', [Validators.required]],
      custom_field_key: ['', [Validators.required, Validators.pattern(this.codeRegex)]],
      custom_field_value: ['', [Validators.required]],
      country_id: ['', [Validators.required]],
    });
    this.editFieldForm = this.formBuilder.group({
      country_id: ['', [Validators.required]],
      custom_field_name: ['', [Validators.required]],
      custom_field_id: ['', []],
      custom_field_key: ['', [Validators.required]],
      custom_field_value: ['', [Validators.required]],
    });
    this.statuses = [
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ];
  }


  public getCustomField() {
    this.spinnerService.raiseDataEmitterEvent('on');
    const params: any = {};
    params.page = this.pageNo;
    params.limit = this.limit;
    params.country_id=this.selected_country;
    this.apiModulesService.list('admin/globalcustomfields/list', params).subscribe((data) => {

      this.fieldData = (data.data.globalCustomFields != undefined) ? data.data.globalCustomFields : [];
      this.loading = false;
      var totalRecords = (this.fieldData[0] != undefined) ? this.fieldData[0].total_records : 0;
      this.totalRecords = totalRecords;
      this.resetForm();
      setTimeout(() => {
        this.spinnerService.raiseDataEmitterEvent('off');
      }, 100);
    }, (err) => {
      this.fieldData = [];
      this.spinnerService.raiseDataEmitterEvent('off');
      this.toastr.error('Something went wrong!', 'Try Again');

    });
  }

  clear(table: Table) {
    table.clear();
  }

  getSeverity(status: string) {
    switch (status.toLowerCase()) {
      case 'inactive':
        return 'danger';

      case 'active':
        return 'success';
    }
  }


  rerender() {

}

  //get countries name
  public getCountriesName() {
    this.apiModulesService.get('country/namelist').subscribe((data) => {
      this.countryname = data;
      this.selected_country = this.countryname[0].country_id;
      this.getCustomField();

    });
  }
  changeStatus(status: any, CustomFieldId: any) {

    var params: any = {};

    params.custom_field_id = CustomFieldId;
    params.status = status;
    this.apiModulesService.edit(params, 'admin/globalcustomfields/status').subscribe(() => {
      this.getCustomField();
      this.toastr.success('Field changed sucessfully...!', 'Success');
    }, (err: any) => {
            let errors = err.error.data.employee_leave;
            errors = errors.replace('SQLSTATE[42000]: [Microsoft][ODBC Driver 17 for SQL Server][SQL Server]', 'Error: ');
            this.toastr.error(errors, 'TryAgain')
        });
  }



  /*  add new CustomField using onAddCustomField Function   */
  public onAddCustomField() {
    if (this.addFieldForm.invalid) {
      this.addFieldForm.markAllAsTouched()
      return
    }
    var params: any = {};
    params.custom_field_name = this.addFieldForm.get('custom_field_name').value;
    params.custom_field_key = this.addFieldForm.get('custom_field_key').value;
    params.custom_field_value = this.addFieldForm.get('custom_field_value').value;
    params.country_id = this.addFieldForm.get('country_id').value;
    params.record_status = 1;
    this.apiModulesService.add(params, 'admin/globalcustomfields/add',).subscribe((data) => {
      ($('#add_field') as any).modal('hide');
      this.selected_country = this.addFieldForm.get('country_id').value;

      this.getCustomField();
      this.toastr.success('Field Added Sucessfully...!', 'Success');
      this.addFieldForm.reset();

    }, err => {
      if (err.status === 437) {
        this.toastr.error('Field Already Exists...!', 'Failed');
      } else {
        this.toastr.error('Something Went Wrong Please Try Again...!', 'Error');
      }
    });
  }

  //Reset form
  public resetForm() {
    this.addFieldForm.reset();
  }
  ngAfterViewInit(): void {

  }
  ngOnDestroy(): void {

  }
  //searh by name
  // searchName(val) {
  //   this.rows.splice(0, this.rows.length);
  //   let temp = this.srch.filter(function (d) {
  //     val = val.toLowerCase();
  //     return d.custom_field_name.toLowerCase().indexOf(val) !== -1 ||d.custom_field_key.toLowerCase().indexOf(val) !== -1 ||d.custom_field_value.toLowerCase().indexOf(val) !== -1;
  //   });
  //   this.rows.push(...temp);
  // }
  //search by company


  //edit value get
  public onEdit(CustomFieldId: any) {
    var params: any = {};
    params.custom_field_id = CustomFieldId;
    this.apiModulesService.edit(params, 'admin/globalcustomfields/edit').subscribe((data) => {
      let fieldDetail = data.data.globalCustomFields;
      this.editFieldForm.setValue({
        custom_field_name: fieldDetail.custom_field_name,
        custom_field_key: fieldDetail.custom_field_key,
        custom_field_value: fieldDetail.custom_field_value,
        country_id: fieldDetail.country_id,
        custom_field_id: fieldDetail.custom_field_id,

      });
      let setCountry = this.countryname.filter((data: any) => fieldDetail.country_id === data.country_id);
    });

  }


  //edit update
  public onEditChanges() {
    if (this.editFieldForm.invalid) {
      this.editFieldForm.markAllAsTouched();

      return;
    }
    var params: any = {};
    params.custom_field_name = this.editFieldForm.get('custom_field_name').value;
    params.country_id = this.editFieldForm.get('country_id').value;
    params.custom_field_id = this.editFieldForm.get('custom_field_id').value;
    params.custom_field_key = this.editFieldForm.get('custom_field_key').value;
    params.custom_field_value = this.editFieldForm.get('custom_field_value').value;
    this.apiModulesService.edit(params, 'admin/globalcustomfields/update').subscribe((data) => {
      this.selected_country = this.editFieldForm.get('country_id').value;

      this.getCustomField();
      this.toastr.success('Field Modified Sucessfully...!', 'Success');
      ($('#edit_field') as any).modal('hide');
    }, err => {
      if (err.status === 437) {
        this.toastr.error('Field Already Exists...!', 'Failed');
      } else {
        this.toastr.error('Something Went Wrong Please Try Again...!', 'Error');
      }
    });
  }



  getAcronym(str: any) {
    var matches = str.match(/\b(\w)/g); // ['J','S','O','N']
    matches = matches.slice(0, 2);
    var acronym = matches.join(''); // JSON
    return acronym;
  }
  getBgColors(str: any) {
    var min = 0;
    var max = 15;
    var index = this.findAlphapositions(str, 1);
    return this.nameBgColors[index];
  }
  findAlphapositions(str: any, n: any) {
    var NUM = 31;

    return str[0].charCodeAt(0) & NUM;
  }
}
