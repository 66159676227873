import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/app/services/auth.service";
import {SpinnerService} from "../../../snipper/spinner.service";

@Component({
  selector: "app-admin-dashboard",
  templateUrl: "./admin-dashboard.component.html",
  styleUrls: ["./admin-dashboard.component.css"],
})
export class AdminDashboardComponent implements OnInit {

  year: number = 2024;
  months: string[] = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];
  shiftCounts: { [key: string]: { [day: number]: number } } = {};
  constructor(private authenticationService: AuthService, private spinnerService: SpinnerService) { }

  ngOnInit() {
    this.spinnerService.raiseDataEmitterEvent('off');

    this.generateShiftCounts();
  }

  generateShiftCounts() {
    // Example shift data for the entire year
    for (let month = 0; month < 12; month++) {
      const daysInMonth = new Date(this.year, month + 1, 0).getDate();
      this.shiftCounts[this.months[month]] = {};
      for (let day = 1; day <= daysInMonth; day++) {
        // Randomly generating shift counts for illustration
        this.shiftCounts[this.months[month]][day] = Math.floor(Math.random() * 5); // Replace with actual data
      }
    }
  }

  getShiftCount(month: string, day: number): number {
    return this.shiftCounts[month][day] || 0;
  }

  getDaysInMonth(month: string): number[] {
    const monthIndex = this.months.indexOf(month);
    const daysInMonth = new Date(this.year, monthIndex + 1, 0).getDate();
    return Array.from({ length: daysInMonth }, (_, i) => i + 1);
  }
}
