
import { Component, OnInit, ViewChild,AfterViewInit,OnDestroy,Input,Output,EventEmitter } from '@angular/core';
import{ApiModulesService} from '../../../api-modules.service';
import{AllModulesService} from '../../../all-modules.service';

import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription, Subject, } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { SpinnerService } from 'src/app/snipper/spinner.service';
import { Table } from 'primeng/table';
declare const $: any;
@Component({
  selector: 'app-leavesettings',
  templateUrl: './leavesettings.component.html',
  styleUrls: ['./leavesettings.component.css']
})
export class LeavesettingsComponent implements OnInit, OnDestroy, AfterViewInit {
  public leavedata = [];
  public listHeaders = [];
  rows: any;
  public activeIndex = 0;
  public srch = [];
  search: any[];
  ClientDropDown = [];
  CompanyDropDown = [];
  tabToGrid=false;
  public addLeaveForm: FormGroup
  loading=false;
  public clientname = [];
  public totalRecords: any = 0;
  public ShowForm: any = false;
  ClientID :any;
  CompanyID :any;
  @Input() authLeaveData: any = {};
  @Output() parenFun: EventEmitter<any> = new EventEmitter();
  nameBgColors:any = ["bg-blue-dim","bg-blue-dim","bg-azure-dim","bg-indigo-dim","bg-info-dim","bg-purple-dim","bg-pink-dim","bg-orange-dim","bg-teal-dim","bg-primary-dim","bg-success-dim","bg-warning-dim","bg-danger-dim","bg-secondary-dim","bg-dark-dim","bg-gray-dim","bg-blue-dim","bg-azure-dim","bg-indigo-dim","bg-info-dim","bg-purple-dim","bg-pink-dim","bg-orange-dim","bg-teal-dim","bg-primary-dim","bg-success-dim","bg-warning-dim","bg-danger-dim","bg-secondary-dim","bg-dark-dim","bg-gray-dim"];
  nameBorderColors:any = ["bg-outline-primary","bg-outline-success","bg-outline-info","bg-outline-gray","bg-outline-warning","bg-outline-dark","bg-outline-light","bg-outline-secondary","bg-outline-danger"];

  public days = []
  editedLeaveData: any;
  showTabs = false;
  leave_plantype_dropdown=[];
  leave_plan_dropdown=[];
  statuses: { label: string; value: string; }[];
  constructor(private apiModulesService: ApiModulesService, private AllModulesService: AllModulesService, private toaster: ToastrService, private toastr: ToastrService, private formBuilder: FormBuilder,private spinnerService: SpinnerService) { }

  ngOnInit(): void {

    this.getLeave();
    this.dropDown();
    this.addLeaveForm = this.formBuilder.group({
      leave_plan_id: ["", [Validators.required]],
      leave_plan_type_id: ["", [Validators.required]],
      client_id: ["", []],
      company_id: ["", []],
      no_of_days: ["", [Validators.required]],
      leave_setting_id: ["", []]
    });
    this.statuses = [
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ];
  }

  showAddForm() {
    this.ShowForm = true;
    this.resetForm();
  }
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event

  }

  //Get all paycomponent data
  public getLeave() {
    this.spinnerService.raiseDataEmitterEvent('off');
    this.ShowForm = false;
    var params:any={};
    params.client_id = this.authLeaveData.client_id;
    params.company_id = this.authLeaveData.company_id;
    params.leave_plan_id = this.authLeaveData.leave_plan_id;

          this.apiModulesService.list("admin/leavemanagement/getleavesetting", params).subscribe((data) => {

              this.leavedata = (data.data.leaves != undefined) ? data.data.leaves : [];
              this.loading = false;
              var totalRecords = (this.leavedata[0] != undefined) ? this.leavedata[0].total_records : 0;
              this.totalRecords = totalRecords;
              this.resetForm();
              setTimeout(() => {
                this.spinnerService.raiseDataEmitterEvent('off');
            }, 100);
          }, (err) => {
            this.leavedata = [];
            this.spinnerService.raiseDataEmitterEvent('off');
            // this.toastr.error('Something went wrong!', 'Try Again');
              // this.rerender();
          });
  }
  clear(table: Table) {
    table.clear();
  }

  getSeverity(status: string) {
    switch (status.toLowerCase()) {
      case 'inactive':
        return 'danger';

      case 'active':
        return 'success';
    }
  }
  parentFun(){

    this.parenFun.emit(this.tabToGrid)
  }
  changeStatus(Status: any, data) {
    let params: any = {};
    params.leave_setting_id = data.leave_setting_id,
    params.client_id = data.client_id;
    params.company_id = data.company_id;
    params.leave_plan_id = data.leave_plan_id;

    this.apiModulesService.edit(params, 'admin/leavemanagement/' + Status).subscribe((data) => {
      this.getLeave();
      this.toaster.success('Status Changed Successfully!', 'Success')
    }, (err) => {
      this.toastr.error("Something went wrong!", "Try Again");
    }
    );
  }

  public importLeavePlan(){
      var params: any = {};
      params.client_id = this.authLeaveData.client_id;
      params.company_id = this.authLeaveData.company_id;
      params.leave_plan_id = this.authLeaveData.leave_plan_id;
      this.apiModulesService.add(params, "admin/leavemanagement/createleavesetting",).subscribe((data) => {
          this.getLeave();
          this.toastr.success('Leave Added Sucessfully...!', 'Success');
          this.addLeaveForm.reset();
          ($('#add_leavesettings') as any).modal('hide');
      }, err => {
          if (err.status === 437) {
              this.toastr.error('Leave Already Exists...!', 'Failed');
          } else {
              this.toastr.error('Something Went Wrong Please Try Again...!', 'Error');
          }
      });
  }
  public onAddLeaveSettings() {
    if (!this.addLeaveForm.valid) {

      this.addLeaveForm.markAllAsTouched();
      return;
    }
    var params: any = {};
    params.client_id = this.authLeaveData.client_id;
    params.company_id = this.authLeaveData.company_id;
    params.leave_plan_id = this.addLeaveForm.get('leave_plan_id').value;
    params.leave_plan_type_id = this.addLeaveForm.get('leave_plan_type_id').value;
    params.no_of_days = this.addLeaveForm.get('no_of_days').value;

    var leave_setting_id = this.addLeaveForm.get('leave_setting_id').value;

    if (leave_setting_id != '' && leave_setting_id != undefined) {
      params.leave_setting_id = this.addLeaveForm.get('leave_setting_id').value;
      this.apiModulesService.edit(params, "admin/leavemanagement/updateleavesetting",).subscribe((data) => {


        this.getLeave();
        this.toastr.success('Leave Modified Sucessfully...!', 'Success');
        ($('#add_leavesettings') as any).modal('hide');
      }, err => {
        if (err.status === 437) {
          this.toastr.error('Leave Already Exists...!', 'Failed');
        } else {
          this.toastr.error('Something Went Wrong Please Try Again...!', 'Error');
        }
      });

    } else {
      // this.apiModulesService.add(params, "admin/leavemanagement/createleavesetting",).subscribe((data) => {
      //   this.getLeave();
      //   this.toastr.success('Leave Added Sucessfully...!', 'Success');
      //   this.addLeaveForm.reset();
      //   ($('#add_leavesettings') as any).modal('hide');
      // }, err => {
      //   if (err.status === 437) {
      //     this.toastr.error('Leave Already Exists...!', 'Failed');
      //   } else {
      //     this.toastr.error('Something Went Wrong Please Try Again...!', 'Error');
      //   }
      // });
    }
  }
  //Reset form
  public resetForm() {
    this.addLeaveForm.reset();
  }
  public editSetting(leaves: any) {
    this.resetForm();
    const params: any = {};
    params.client_id = this.authLeaveData.client_id;
    params.company_id = this.authLeaveData.company_id;
    params.leave_setting_id = leaves.leave_setting_id;
    params.leave_plan_id = this.authLeaveData.leave_plan_id;

    this.apiModulesService.edit(params, 'admin/leavemanagement/editleavesetting').subscribe((data) => {

            const leaveDetail = (data.data.leaves !== undefined) ? data.data.leaves : [];
            this.addLeaveForm.setValue({
                //  company_id:leaveDetail.company_id,
                //  client_id:leaveDetail.client_id,
                leave_plan_id: leaveDetail.leave_plan_id,
                leave_plan_type_id: leaveDetail.leave_plan_type_id,
                no_of_days: leaveDetail.no_of_days,
                leave_setting_id: leaveDetail.leave_setting_id,
                client_id: leaveDetail.client_id,
                company_id: leaveDetail.company_id
            });

        }, (err) => {
            this.toastr.error('Something went wrong!', 'Try Again');
        }
    );
}
  // searchName(val) {
  //   this.rows.splice(0, this.rows.length);
  //   let temp = this.srch.filter(function (d) {
  //     val = val.toLowerCase();
  //     return d.leave_plan_id.toLowerCase().indexOf(val) !== -1 || d.leave_day_name.toLowerCase().indexOf(val) !== -1 || d.leave_plan_type_id.toLowerCase().indexOf(val) !== -1 || d.no_of_days.toLowerCase().indexOf(val) !== -1
  //   });
  //   this.rows.push(...temp);
  // }
  ngAfterViewInit(): void {

  }
  rerender() {
  }
  // dropdown
  public dropDown(){
    const params: any = {};
    params.client_id = this.authLeaveData.client_id;
    params.company_id = this.authLeaveData.company_id;
    this.apiModulesService.list("admin/leavemanagement/dropdownleavesetting", params).subscribe((data)=>{

      this.leave_plan_dropdown = data.data.leaves[0];
      this.leave_plantype_dropdown = data.data.leaves[1]

    })
  }


  changeViewGridToTabs(leaveplanId: number, clientId: number, companyId: number) {
    this.editedLeaveData.client_id = clientId;
    this.editedLeaveData.company_id = companyId;
    this.editedLeaveData.leave_setting_id = leaveplanId;

    this.showTabs = true;
}
changeTabsViewToGrid() {
  this.showTabs = false;
  this.activeIndex = 0;
  this.getLeave();
}
changeTabsIndex(index) {
  this.activeIndex = index;
}
getAcronym(str: any) {
  if (str != null) {
      let matches = str.match(/\b(\w)/g); // ['J','S','O','N']
      matches = matches.slice(0, 2);
      const acronym = matches.join(''); // JSON
      return acronym;
  }
}

getBgColors(str: any) {
  if (str != null) {
      const min = 0;
      const max = 15;
      const index = this.findAlphapositions(str, 1);
      return this.nameBgColors[index];
  }
}
  findAlphapositions(str:any, n:any)
  {
    var NUM = 31;

    return str[0].charCodeAt(0) & NUM;
  }
}
