import{Injectable} from "@angular/core";

@Injectable({
    providedIn:'root'
})

export class HRACalculationService {
    constructor() {
    }

    calculateHra(declarationData:Array<object>,basic:number):number{
    const HRA_data:Array<object>=(this.calculateSingleRowHRA(declarationData,basic));
        const filter = [];
        const totalHRA = [];


        for (let i = 1; i <= 12; i++) {

            let filter = [];
            filter = HRA_data.filter(filterByID);
            function filterByID(item) {
                const date = new Date(item.from_date);
                const month = new Array();
                month[0] = 1;
                month[1] = 2;
                month[2] = 3;
                month[3] = 4;
                month[4] = 5;
                month[5] = 6;
                month[6] = 7;
                month[7] = 8;
                month[8] = 9;
                month[9] = 10;
                month[10] = 11;
                month[11] = 12;
                const monthNumber = month[date.getMonth()];
                if (monthNumber === i && item.record_status==='active') {
                    return true;
                }
                // invalidEntries++;
                return false;
            }
            if (filter.length != 0) {
                totalHRA[i] = filter.reduce(myFunc,0);

            }
        }
        let HraTotal:number= 0;

        if(totalHRA.length!=0) {
            HraTotal= totalHRA.reduce((acc: any, curr: any, index: any) => {
                return (Number (acc)+Number(curr));
            });

        }

        function myFunc(acc, curr, index) {
            let previousVal:number=(Number(acc.hra))!=undefined && isNaN((Number(acc.hra))) !=true   ?(Number(acc.hra)):0;
            let currentVal:number=(Number(curr.hra))!=undefined && isNaN(Number(curr.hra)) != true ?(Number(curr.hra)):0;
            return (previousVal+currentVal);
        }
        return HraTotal;

    }


    calculateSingleRowHRA(declarationData:Array<object>,basic:number):Array<object>{
        const length=declarationData.length;
        let decData:any=declarationData;
        let metroRent=0;
        let basicPerMinusRent=0;
        let basic_10Percent=basic*(10 / 100);
        for(let i=0;i<length;i++){

            if(decData[i].metro_city=='yes'){
                metroRent=basic*(50 / 100);
            }
            else{
                metroRent=basic*(40 / 100);
            }

            basicPerMinusRent=(Number(decData[i].rent_per_month)-basic_10Percent)
            decData[i].hra= Math.min(metroRent,basicPerMinusRent,basic_10Percent);
        }

        return decData
    }




}
