import { Component, Input, OnInit } from '@angular/core';
import { ApiModulesService } from '../../api-modules.service';
import { SpinnerService } from 'src/app/snipper/spinner.service';
@Component({
  selector: 'app-current-experience',
  templateUrl: './current-experience.component.html',
  styleUrls: ['./current-experience.component.css']
})
export class CurrentExperienceComponent implements OnInit {
  @Input() authEmployeeData: any = {};
  currentExperienceData: any = [];
  constructor(private spinnerService: SpinnerService, private apiModuleService: ApiModulesService) { }
  ngOnInit() {
    this.spinnerService.raiseDataEmitterEvent('on');
    this.getcurrentExperienceData();
  }
  getcurrentExperienceData() {
    this.spinnerService.raiseDataEmitterEvent('on');
    const params: any = {};
    params.client_id = this.authEmployeeData.client_id;
    params.company_id = this.authEmployeeData.company_id;
    params.employee_id = this.authEmployeeData.employee_id;
    this.apiModuleService.list('admin/employee-experience/current-experience', params).subscribe((data) => {
      this.currentExperienceData = data.data.current_experience != undefined ? data.data.current_experience : [];
      this.spinnerService.raiseDataEmitterEvent('off');
    }, (err: any) => {
      this.spinnerService.toasterError(err);
    });
  }
}
