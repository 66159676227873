import {Component, OnInit, ViewChild} from '@angular/core';
import { HeaderComponent } from '../header/header.component';
import {SidebarComponent} from "../sidebar/sidebar.component";

@Component({
  selector: 'app-all-modules',
  templateUrl: './all-modules.component.html',
  styleUrls: ['./all-modules.component.css']
})
export class AllModulesComponent implements OnInit {
@ViewChild(SidebarComponent)sidebarComp:SidebarComponent;
@ViewChild(HeaderComponent)headerComp:HeaderComponent;
  sidebarcall:any;
  showNotification=false;
constructor() { }

  ngOnInit() {
  }
    callSidebarApi(value){
    this.sidebarcall=value;
    this.sidebarComp.sidebarUserMenu();
    }
    OpenNotification(value){
    this.showNotification=true;
    }
    closeNotification(value){

this.showNotification=false;
    }
}
