import {Component, OnInit, Input, ViewChild, AfterViewInit, OnDestroy, Output, EventEmitter} from '@angular/core';
import {ApiModulesService} from '../../api-modules.service';
import {ToastrService} from 'ngx-toastr';
import {FormGroup, FormBuilder, Validators, FormArray, FormControl} from '@angular/forms';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Subject} from 'rxjs';
import {File} from 'filepond';
import {environment} from 'src/environments/environment';
import {documentservice} from '../../documents.sevice';

import { Table } from 'primeng/table';
import { SpinnerService } from 'src/app/snipper/spinner.service';

@Component({
    selector: 'app-employee-experience',
    templateUrl: './employee-experience.component.html',
    styleUrls: ['./employee-experience.component.css']
})
export class EmployeeExperienceComponent implements OnInit, AfterViewInit, OnDestroy {
  statuses=[];
  loading: boolean = false;
    experienceData = [];
    public listHeaders = [];
    public ShowForm: any = false;
    public deleteForm: any = false;
    public addExperienceForm: FormGroup;
    public addEmployeeExperienceForm: FormGroup;
    public show = true;
    searchKeyword: string = '';
    public url: any = environment.apiUrl;
    filedata: any = [];
    private user_type=localStorage.getItem('user_type');
    delete_id: any;
    statusApi:any;
    statusValues:any;
    tabToGrid=false;
    @Output() parenFun: EventEmitter<any> = new EventEmitter();
    @Input() authEmployeeData: any;
    @Input() pageName: any = {};

    nameBgColors: any = ['bg-blue-dim', 'bg-blue-dim', 'bg-azure-dim', 'bg-indigo-dim', 'bg-info-dim', 'bg-purple-dim', 'bg-pink-dim', 'bg-orange-dim', 'bg-teal-dim', 'bg-primary-dim', 'bg-success-dim', 'bg-warning-dim', 'bg-danger-dim', 'bg-secondary-dim', 'bg-dark-dim', 'bg-gray-dim', 'bg-blue-dim', 'bg-azure-dim', 'bg-indigo-dim', 'bg-info-dim', 'bg-purple-dim', 'bg-pink-dim', 'bg-orange-dim', 'bg-teal-dim', 'bg-primary-dim', 'bg-success-dim', 'bg-warning-dim', 'bg-danger-dim', 'bg-secondary-dim', 'bg-dark-dim', 'bg-gray-dim'];
    nameBorderColors: any = ['bg-outline-primary', 'bg-outline-success', 'bg-outline-info', 'bg-outline-gray', 'bg-outline-warning', 'bg-outline-dark', 'bg-outline-light', 'bg-outline-secondary', 'bg-outline-danger'];

    constructor(
        public apiModelService: ApiModulesService,
        public toastr: ToastrService,
        public documentService: documentservice,
        public formBuilder: FormBuilder,
        public spinnerService: SpinnerService
        ) {
    }

    ngOnInit(): void {
      this.spinnerService.raiseDataEmitterEvent('on');
        this.getExperience();

        this.addExperienceForm = this.formBuilder.group({
            items: this.formBuilder.array([]),
        });
        this.addEmployeeExperienceForm = this.formBuilder.group({
            company_name: ['', [Validators.required]],
            roles: ['', [Validators.required]],
            description: [''],
            employee_experience_id: [''],
            join_date: ['', [Validators.required]],
            leaving_date: ['', [Validators.required]],
            ctc_amount: ['', [Validators.required]],
            experience_years: ['', [Validators.required]],
        })

        // for adding row
        this.addItems();

    }

    ngAfterViewInit(): void {

    }

    ngOnDestroy(): void {
        // Do not forget to unsubscribe the event

    }

    get itemsList(): FormArray {
        return this.addExperienceForm.get('items') as FormArray;
    }

    newItem(): FormGroup {
        return this.formBuilder.group({
            company_name: '',
            roles: '',
            descriptions: '',
            join_date: '',
            leaving_date: '',
            documents: '',
            document: '',
            amount: '',
            experience_years: '',
        });
    }

    public hide() {
        this.show = !this.show
    }

    newItems(value): FormGroup {
        return this.formBuilder.group({
            company_name: value.company_name,
            roles: value.woked_role,
            descriptions: value.description,
            join_date: value.join_date,
            leaving_date: value.leaving_date,
            document: value.documents,
            documents: '',
            amount: value.ctc_amount,
            experience_years: value.experience_years,
            id: value.employee_experience_id

        });
    }

    // for changes of forms
    showList() {
        this.ShowForm = false;
        this.deleteForm = false;

    }

    showAddForm() {
        this.ShowForm = true;
        this.deleteForm = false;
    }

    // for adding rows
    addItems() {
        this.itemsList.push(this.newItem());
    }

// for remove rows
    removeItems(i) {
        this.itemsList.removeAt(i);
    }

    // list the details
    getExperience() {
      this.spinnerService.raiseDataEmitterEvent('on');
        this.ShowForm = false;
        const params: any = {};
        params.client_id = this.authEmployeeData.client_id;
        params.company_id = this.authEmployeeData.company_id;
        params.employee_id = this.authEmployeeData.employee_id;

        this.apiModelService.list('admin/employee-experience/list', params).subscribe((data) => {

                this.experienceData = (data.data.employee_experience != undefined) ? data.data.employee_experience : [];

                this.spinnerService.raiseDataEmitterEvent('off');

        }, (err) => {
            this.experienceData = [];
            this.spinnerService.toasterError(err);
        });
    }

    fileEvent(event) {
        if (event.target.files.length) {
            for (let i = 0; i < event.target.files.length; i++) {
                this.filedata.push(<File>event.target.files[i]);
// this.filedata=e.target.files;
            }
        }
    }
    parentFun(){

        this.parenFun.emit(this.tabToGrid)
    }
    addExperience() {

        if (!this.addEmployeeExperienceForm.valid) {

            this.addEmployeeExperienceForm.markAllAsTouched();
            return;
        }

        const params: any = {};
        params.client_id = this.authEmployeeData.client_id;
        params.company_id = this.authEmployeeData.company_id;
        params.employee_id = this.authEmployeeData.employee_id;
        params.company_name = this.addEmployeeExperienceForm.value.company_name;
        params.roles = this.addEmployeeExperienceForm.value.roles;
        params.descriptions = this.addEmployeeExperienceForm.value.description;
        params.leaving_date = this.addEmployeeExperienceForm.value.leaving_date;
        params.experience_years = this.addEmployeeExperienceForm.value.experience_years;
        params.ctc_amount = this.addEmployeeExperienceForm.value.ctc_amount;
        params.join_date = this.addEmployeeExperienceForm.value.join_date;
        if (this.addEmployeeExperienceForm.value.employee_experience_id != ''
            && this.addEmployeeExperienceForm.value.employee_experience_id != null) {
            params.employee_experience_id = this.addEmployeeExperienceForm.value.employee_experience_id;
            this.apiModelService.edit(params, 'admin/employee-experience/update').subscribe((data) => {
                this.toastr.success('Experience data update Successfully', 'Success');
                ($('#add_employee_experience') as any).modal('hide');
                this.getExperience();
                this.ShowForm = false;
            }, err => {
              this.spinnerService.toasterError(err);
            });
        } else {
            this.apiModelService.add(params, 'admin/employee-experience/add').subscribe((data) => {
                ($('#add_employee_experience') as any).modal('hide');
                this.getExperience();
                this.ShowForm = false;
                this.toastr.success('Experience data Add Successfully', 'Success')
            }, (err:any) => {
              this.spinnerService.toasterError(err);
            });
        }
    }

    public onEditExperience(value) {

        this.addEmployeeExperienceForm.setValue({
            company_name: value.company_name,
            roles: value.woked_role,
            description: value.description,
            employee_experience_id: value.employee_experience_id,
            join_date: value.join_date,
            leaving_date: value.leaving_date,
            ctc_amount: value.ctc_amount,
            experience_years: value.experience_years,
        })
    }

    changeStatus(Status: any, experience) {

        let params: any = {};
        params.employee_id = experience.employee_id,
            params.client_id = experience.client_id,
            params.company_id = experience.company_id,
            params.employee_experience_id = experience.employee_experience_id,

        this.apiModelService.edit(params, 'admin/employee-experience/' + Status).subscribe((data) => {
                this.getExperience();
                ($('#employeeexperience-status') as any).modal('hide');
                this.toastr.success('Status Changed Successfully!', 'Success');
            }, (err:any) => {
              this.spinnerService.toasterError(err);
              }
        );
    }

//Reset form
    public resetForm() {
        this.addExperienceForm.reset();
        this.addEmployeeExperienceForm.reset();
    }

    public delete(i) {
        this.delete_id = i;
    }

// downloadMyFile(){
//   const link = document.createElement('a');
//   link.setAttribute('target', '_blank');
//   link.setAttribute('href', 'abc.net/files/test.ino');
//   link.setAttribute('download', `products.csv`);
//   document.body.appendChild(link);
//   link.click();
//   link.remove();
// }
    getAcronym(str: any) {
        let matches = str.match(/\b(\w)/g); // ['J','S','O','N']
        matches = matches.slice(0, 2);
        const acronym = matches.join(''); // JSON
        return acronym;
    }

    getBgColors(str: any) {
        const min = 0;
        const max = 15;
        const index = this.findAlphapositions(str, 1);
        return this.nameBgColors[index];
    }

    findAlphapositions(str: any, n: any) {
        const NUM = 31;

        return str[0].charCodeAt(0) & NUM;
    }
    clear(table: Table) {
      table.clear();
  }

  getSeverity(status: string) {
      switch (status.toLowerCase()) {
          case 'inactive':
              return 'danger';

          case 'active':
              return 'success';
      }
  }
  callStatusModel(url_name:any,data:any,actionName:string){
    this.statusApi=url_name;
    this.statusValues=data;
}

}




