// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
/*:host ::ng-deep .p-multiselect{*/
    /*width:230px;*/
/*}*/
:host ::ng-deep .p-inputnumber-input {
    min-width: 247px ;
    max-width: 100%;
}
.popup-headers{
  margin-top: -22px;
}
`, "",{"version":3,"sources":["webpack://./src/app/all-modules/employees/employment-details/employment-details.component.css"],"names":[],"mappings":";AACA,kCAAkC;IAC9B,eAAe;AACnB,IAAI;AACJ;IACI,iBAAiB;IACjB,eAAe;AACnB;AACA;EACE,iBAAiB;AACnB","sourcesContent":["\r\n/*:host ::ng-deep .p-multiselect{*/\r\n    /*width:230px;*/\r\n/*}*/\r\n:host ::ng-deep .p-inputnumber-input {\r\n    min-width: 247px ;\r\n    max-width: 100%;\r\n}\r\n.popup-headers{\r\n  margin-top: -22px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
