import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SpinnerService } from 'src/app/snipper/spinner.service';
import { ApiModulesService } from '../../api-modules.service';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { Table } from 'primeng/table';
import { documentservice } from '../../documents.sevice';
@Component({
  selector: 'app-company-documents',
  templateUrl: './company-documents.component.html',
  styleUrls: ['./company-documents.component.css']
})
export class CompanyDocumentsComponent {


  ClientDropDown = [];
  nameBgColors: any = ['bg-blue-dim', 'bg-blue-dim', 'bg-azure-dim', 'bg-indigo-dim', 'bg-info-dim', 'bg-purple-dim', 'bg-pink-dim', 'bg-orange-dim', 'bg-teal-dim', 'bg-primary-dim', 'bg-success-dim', 'bg-warning-dim', 'bg-danger-dim', 'bg-secondary-dim', 'bg-dark-dim', 'bg-gray-dim', 'bg-blue-dim', 'bg-azure-dim', 'bg-indigo-dim', 'bg-info-dim', 'bg-purple-dim', 'bg-pink-dim', 'bg-orange-dim', 'bg-teal-dim', 'bg-primary-dim', 'bg-success-dim', 'bg-warning-dim', 'bg-danger-dim', 'bg-secondary-dim', 'bg-dark-dim', 'bg-gray-dim'];
  nameBorderColors: any = ['bg-outline-primary', 'bg-outline-success', 'bg-outline-info', 'bg-outline-gray', 'bg-outline-warning', 'bg-outline-dark', 'bg-outline-light', 'bg-outline-secondary', 'bg-outline-danger'];
  CompanyDropDown = [];
  ClientID :any;
  CompanyID :any;
  
  
  dtElement: any;
   isDtInitialized: boolean = false;
  statusApi: any;
  statusValues: any;
  addCompanyDocumentForm: FormGroup;
  public documentsData: [];
  filedata: any;
  chosenFileName='No File Chosen';
  documentCategory: any;
  private user_type=localStorage.getItem('user_type');
  monthYearDropdown: any;
  month: any;
  year: any;
  statuses: { label: string; value: string; }[];

    constructor(private apiModulesService: ApiModulesService,
                private documentService: documentservice,
                private toastr: ToastrService,
                private formBuilder: FormBuilder,
                private spinnerService: SpinnerService) { }

    ngOnInit(): void {
      this.GetClientDropDown();
      this.addCompanyDocumentForm =this.formBuilder.group({
      document_type_id:['',[Validators.required]],
      document_value:['',[Validators.required]],
      document_name:['',[Validators.required]],
      document_path:[''],
      old_path:[''],
      file_name:[''],
      company_document_id:[''],
      month_year:['',[Validators.required]]
    })
    this.statuses = [
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ];

  }


  public GetClientDropDown() {
    this.apiModulesService.get('DropDown/client').subscribe((data) => {
    this.ClientDropDown = data.data.client!= undefined ? data.data.client : [];
    this.ClientID = data.data.client[0].client_id;
    if(this.ClientDropDown.length!==0){
        setTimeout(()=>{
        this.GetCompanyDropDown();
      },100)
    }
    else{
      this.ClientID=null;
      this.rerender();
    }

  })
}
  /* company DropDown codes */
  public GetCompanyDropDown() {
    const params: any = {};
    params.client_id = this.ClientID;
      this.apiModulesService.list('DropDown/company', params).subscribe((data) => {
      this.CompanyDropDown = data.data.company != undefined ? data.data.company : [];
      if (this.CompanyDropDown.length !== 0) {
        setTimeout(() => {
          this.CompanyID = this.CompanyDropDown[0].company_id;
          this.dropDown();
          this.getCompanyDocuments();
        }, 100)
      }
      else {
        this.CompanyID = null;
        this.rerender();
      }
    }
    )
  }

  public getCompanyDocuments() {
    // Show a loading spinner
    this.spinnerService.raiseDataEmitterEvent('on');

    // Set up parameters for the API request
    var params: any = {};
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;

    // Make an API request to get the list of company documents
    this.apiModulesService.list("admin/company-documents/get", params).subscribe(
      (data) => {
        // If data is received, update the documentsData variable with the company documents
        this.documentsData = (data.data.company_document != undefined) ? data.data.company_document : [];

        // Hide the loading spinner after a delay of 100 milliseconds
        setTimeout(() => {
          this.spinnerService.raiseDataEmitterEvent('off');
        }, 100);
      },
      (err) => {
        // If there's an error in the API request, handle it
        this.documentsData = [];
        this.spinnerService.raiseDataEmitterEvent('off');

        // Extract and display the error message
        let errors = err.error.data.company_document;
        errors = errors.replace('SQLSTATE[42000]: [Microsoft][ODBC Driver 17 for SQL Server][SQL Server]', 'Error: ')
        this.toastr.error(errors, 'TryAgain');
      }
    );
  }



public updateDocuments(){

  if (!this.addCompanyDocumentForm.valid) {
    this.addCompanyDocumentForm.markAllAsTouched();
    return;
}

   const myFormData = new FormData();

   myFormData.append('document_path', this.filedata != undefined ? this.filedata : null);
   myFormData.append('client_id',this.ClientID);
   myFormData.append('company_id',this.CompanyID);
   myFormData.append('document_type_id', this.addCompanyDocumentForm.value.document_type_id);
   myFormData.append('document_value', this.addCompanyDocumentForm.value.document_value);
   myFormData.append('document_name', this.addCompanyDocumentForm.value.document_name);
   myFormData.append('file_name', this.addCompanyDocumentForm.value.file_name);
   myFormData.append('old_path', this.addCompanyDocumentForm.value.old_path);
   myFormData.append('company_document_id', this.addCompanyDocumentForm.value.company_document_id);
   myFormData.append('month', this.month);
   myFormData.append('year', this.year);


   var company_document_id=this.addCompanyDocumentForm.get('company_document_id').value;

   if(company_document_id!='' && company_document_id  != undefined  && company_document_id  != null){
    this.documentService.post('api/admin/company-documents/update',myFormData).subscribe((data)=>{
   this.toastr.success('document updated Successfully !', 'Success');

     ($('#add_documents') as any).modal('hide');
     this.filedata = null;
     this.getCompanyDocuments();
     this.resetForm();
     this.chosenFileName='No File Chosen';
},(err)=>{
if (err.status === 437) {
this.toastr.error('Document Already Exists...!', 'Failed');
} else {
this.toastr.error('Something Went Wrong Please Try Again...!', 'Error');
}
});
 }
 else
 {
this.documentService.post("api/admin/company-documents/add",myFormData).subscribe((data)=>{
  this.getCompanyDocuments();
  this.resetForm();
  this.toastr.success('Document Added Sucessfully...!', 'Success');
  ($('#add_documents') as any).modal('hide');

},err=>{

  if (err.status === 437) {
    this.toastr.error('Document Already Exists...!', 'Failed');
    } else {
    this.toastr.error('Something Went Wrong Please Try Again...!', 'Error');
    }
})
  }
}

public monthYear(){
  let text: any = this.addCompanyDocumentForm.get('month_year').value;

  if(text !=='' && text!==null){
    const myArray = text.split("/");
    this.month = myArray[0] != undefined ? myArray[0] : '';
    this.year = myArray[1] != undefined ? myArray[1] : '';
  }

}

dropDown() {

  const params: any = {};
  params.client_id =  this.ClientID;
  params.company_id = this.CompanyID;
  this.apiModulesService.edit(params, 'admin/company-documents/dropDown').subscribe((data) => {
  this.documentCategory=data.data.company_document[0] !=undefined ? data.data.company_document[0] :[];
  this.monthYearDropdown = (data.data.company_document[1] != undefined) ? data.data.company_document[1] : [];
      }, (err) => {
          this.toastr.error('Something went wrong!', 'Try Again');
      }
  );

}

edit(data:any){
  const params: any = {};

  params.client_id =  this.ClientID;
  params.company_id = this.CompanyID;
  params.company_document_id = data.company_document_id;

  this.apiModulesService.edit(params,'admin/company-documents/edit').subscribe((data)=>{

    const editValue=data.data.company_document[0] !=undefined ? data.data.company_document[0] :[];
    let monthyear: any;
    if(editValue.month!==null && editValue.month!==''){
      monthyear = editValue.month.concat('/', editValue.year);
    }


    this.addCompanyDocumentForm.setValue({
      file_name:editValue.file_name,
      document_type_id:editValue.document_type_id,
      document_value:editValue.document_value,
      document_name:editValue.document_name,
      document_path:null,
      old_path:editValue.document_path,
      company_document_id:editValue.company_document_id,
      month_year: monthyear,
    })
  },(err)=>{
      this.toastr.error('Something went wrong!', 'Try Again');
  }
  );

}

public DownloadFile(companyDocumentID) {

  const params: any = {};
  params.client_id = this.ClientID;
  params.company_id = this.CompanyID;
  params.company_document_id =companyDocumentID;
  this.apiModulesService.add(params, 'admin/company-documents/download').subscribe((data) => {
    this.getCompanyDocuments();
    const docDetails=data.data.company_document;
          this.documentService.DownloadExcel(docDetails.doc_name,docDetails.doc_url);
          this.toastr.success('File Downloaded Sucessfully...!', 'Success');
      }, (err) => {
        let errors = err.error.data.company_document;
        errors = errors.replace('SQLSTATE[42000]: [Microsoft][ODBC Driver 17 for SQL Server][SQL Server]', 'Error: ')
        this.toastr.error(errors, 'TryAgain');
      }
  );
}

 /* File onchange event */
 fileEvent(e,index) {
  this.filedata = e.target.files[0];
}

resetForm(){
  this.addCompanyDocumentForm.reset();
}

changeStatus(status: any,data) {
  const params: any = {};
  params.client_id = this.ClientID;
  params.company_id = this.CompanyID;
  params.company_document_id = data.company_document_id;
  this.apiModulesService.edit(params, 'admin/company-documents/' + status).subscribe((data) => {
  this.getCompanyDocuments();
  this.toastr.success('Status  Changed Sucessfully...!', 'Success');
  ($('#companydocument-status') as any).modal('hide');
   }, (err) => {
        let errors = err.error.data.employee_document;
        errors = errors.replace('SQLSTATE[42000]: [Microsoft][ODBC Driver 17 for SQL Server][SQL Server]', 'Error: ')
        this.toastr.error(errors, 'TryAgain');
   }
  );
}

   rerender() {

  }
  getAcronym(str:any){
    if (str != null) {
    let matches = str.match(/\b(\w)/g); // ['J','S','O','N']
    matches= matches.slice(0,3);
    const acronym = matches.join(''); // JSON
    return acronym;
    }
}
getBgColors(str:any){
  if (str != null) {
    const min = 0;
    const max = 15;
    const index = this.findAlphapositions(str,1);
    return this.nameBgColors[index];
  }
}
findAlphapositions(str:any, n:any)
{
    const NUM = 31;
    return str[0].charCodeAt(0) & NUM;
}

clear(table: Table) {
  table.clear();
}

  getSeverity(status: string) {
  switch (status.toLowerCase()) {
          case 'inactive':
          return 'danger';

          case 'active':
          return 'success';
  }
}

  callStatusModel(url_name:any,data:any,actionName:string){
  this.statusApi=url_name;
  this.statusValues=data;
}
}
